import React from 'react';
import PropTypes from 'prop-types';
import { Form, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import PasswordField from '../../ui/components/fields/PasswordField';
import SubmitButton from '../../common/SubmitButton';

import ResetForm_en from './locales/ResetForm_en.json';
import ResetForm_fr from './locales/ResetForm_fr.json';

import './ResetForm.less';

class ResetForm extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'ResetForm', ResetForm_en);
    i18next.addResourceBundle('fr', 'ResetForm', ResetForm_fr);

    this.state = {
      passwordMatch: null,
    }

    this.handleSwitchToLogin = this.handleSwitchToLogin.bind(this);
    this.handleSwitchToRegister = this.handleSwitchToRegister.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if(!this.props.sid
    && !this.props.isResetSuccess
    && this.props.isCheckRecoveryKeyFail) {
      this.props.openRecoveryModal(true);
    }
  }

  handleSwitchToLogin(event) {
    event.preventDefault();
    this.props.openLoginModal();
  }

  handleSwitchToRegister(event) {
    event.preventDefault();
    this.props.openRegisterModal(true);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(values.password === values.confirm_password) {
          this.setState({
            passwordMatch: true,
          });
          this.props.reset(values.password, this.props.sid);
        } else {
          this.setState({
            passwordMatch: false,
          });
        }
      }
    });
  }

  render() {
    const { t, className } = this.props;
    const success = this.props.isResetSuccess === true ? (
      <Alert
        message={t('success.title')}
        description={(
          <span>
            {t('success.message')}<a href="/" className="ant-alert-description-link" onClick={this.handleSwitchToLogin}>{t('success.link')}</a>
          </span>
        )}
        type="success"
        showIcon
      />
    ) : null;

    const error = this.state.passwordMatch === false ? (
      <Alert
        message={t('error.title')}
        description={(
          <span>
            {t('error.message')}
          </span>
        )}
        type="error"
        showIcon
      />
    ) : null;

    const label = (
      this.props.isResetSuccess ? (
        <span>
          <FontAwesomeIcon icon={["fas", "check"]} />
          {t('done')}
        </span>
      ) : (
        <span>
          {t('action')}
        </span>
      )
    );

    return (
      <Form
        className={`ConnectionForm ConnectionForm__Recovery ${className}`}
        onSubmit={this.handleSubmit}
      >
        {success || error}
        <h1>{t('title')}</h1>
        <PasswordField
          className="ConnectionForm__Field"
          form={this.props.form}
          placeholder={t('placeholder1')}
          newPassword={true}
        />
        <PasswordField
          className="ConnectionForm__Field"
          form={this.props.form}
          fieldId="confirm_password"
          placeholder={t('placeholder2')}
          newPassword={true}
        />
        <SubmitButton className="ConnectionForm__Field" loading={this.props.isResetInProgress} disabled={this.props.isResetSuccess}>
          {label}
        </SubmitButton>
        <p className="ConnectionForm__EndNote">
          {t('no-account')}<a href="/" onClick={this.handleSwitchToRegister}>{t('create-account')}</a>
        </p>
      </Form>
    );
  }

}

ResetForm.defaultProps = {
  className: '',
}

ResetForm.propTypes = {
  className: PropTypes.string,
  openRegisterModal: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  isResetInProgress: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  connectionModalForced: PropTypes.bool.isRequired,
  connectionModalOrigin: PropTypes.string,
  platform: PropTypes.object
};

export default translate('ResetForm')(ResetForm);

import {
  FETCH_CAMPAIGN_SEARCH_START,
  FETCH_CAMPAIGN_SEARCH_SUCCESS,
  FETCH_CAMPAIGN_SEARCH_ERROR,

  FETCH_TEAM_SEARCH_START,
  FETCH_TEAM_SEARCH_SUCCESS,
  FETCH_TEAM_SEARCH_ERROR,

  FETCH_PARTICIPANT_SEARCH_START,
  FETCH_PARTICIPANT_SEARCH_SUCCESS,
  FETCH_PARTICIPANT_SEARCH_ERROR,

  FETCH_DONATION_SEARCH_START,
  FETCH_DONATION_SEARCH_SUCCESS,
  FETCH_DONATION_SEARCH_ERROR,

  FETCH_TRANSACTION_SEARCH_START,
  FETCH_TRANSACTION_SEARCH_SUCCESS,
  FETCH_TRANSACTION_SEARCH_ERROR,

  FETCH_PARTICIPATION_SEARCH_START,
  FETCH_PARTICIPATION_SEARCH_SUCCESS,
  FETCH_PARTICIPATION_SEARCH_ERROR,

  FETCH_MEMBER_TRANSACTIONS_SEARCH_START,
  FETCH_MEMBER_TRANSACTIONS_SEARCH_SUCCESS,
  FETCH_MEMBER_TRANSACTIONS_SEARCH_ERROR,

  FETCH_PLATFORM_MEMBERS_SEARCH_START,
  FETCH_PLATFORM_MEMBERS_SEARCH_SUCCESS,
  FETCH_PLATFORM_MEMBERS_SEARCH_ERROR,

  FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_START,
  FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_SUCCESS,
  FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_ERROR,

  FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_START,
  FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_SUCCESS,
  FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_ERROR,

  FETCH_PLUGIN_SEARCH_START,
  FETCH_PLUGIN_SEARCH_SUCCESS,
  FETCH_PLUGIN_SEARCH_ERROR,

  FETCH_SETTLEMENT_REPORTS_START,
  FETCH_SETTLEMENT_REPORTS_SUCCESS,
  FETCH_SETTLEMENT_REPORTS_ERROR,

  CLEAR_SEARCH,
} from './searchActions';

const initialState = {
  isSearchInProgress: false,
  campaigns: {},
  teams: {},
  participants: {},
  donations: {},
  transactions: {},
  participations: {},
  members: {},
  memberTransactions: {},
  memberParticipations: {},
  plugins: {},
  settlementReports: {}
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN_SEARCH_START:
    case FETCH_TEAM_SEARCH_START:
    case FETCH_PARTICIPANT_SEARCH_START:
    case FETCH_DONATION_SEARCH_START:
    case FETCH_TRANSACTION_SEARCH_START:
    case FETCH_PARTICIPATION_SEARCH_START:
    case FETCH_MEMBER_TRANSACTIONS_SEARCH_START:
    case FETCH_PLATFORM_MEMBERS_SEARCH_START:
    case FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_START:
    case FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_START:
    case FETCH_PLUGIN_SEARCH_START:
    case FETCH_SETTLEMENT_REPORTS_START:
    return {
      ...state,
      isSearchInProgress: true
    };

    case FETCH_CAMPAIGN_SEARCH_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      campaigns: action.payload
    };

    case FETCH_CAMPAIGN_SEARCH_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      campaigns: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.campaigns.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.campaigns.filters,
          ...action.filters
        }
      }
    };

    case FETCH_TEAM_SEARCH_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      teams: action.payload
    };

    case FETCH_TEAM_SEARCH_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      teams: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.teams.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.teams.filters,
          ...action.filters
        }
      }
    };

    case FETCH_PARTICIPANT_SEARCH_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      participants: action.payload
    };

    case FETCH_PARTICIPANT_SEARCH_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      participants: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.participants.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.participants.filters,
          ...action.filters
        }
      }
    };

    case FETCH_DONATION_SEARCH_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      donations: action.payload
    };

    case FETCH_DONATION_SEARCH_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      donations: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.donations.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.donations.filters,
          ...action.filters
        }
      }
    };

    case FETCH_TRANSACTION_SEARCH_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      transactions: action.payload
    };

    case FETCH_TRANSACTION_SEARCH_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      transactions: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.donations.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.donations.filters,
          ...action.filters
        }
      }
    };

    case FETCH_PARTICIPATION_SEARCH_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      participations: action.payload
    };

    case FETCH_PARTICIPATION_SEARCH_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      participations: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.participations.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.participations.filters,
          ...action.filters
        }
      }
    };

    case FETCH_MEMBER_TRANSACTIONS_SEARCH_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      transactions: action.payload
    };

    case FETCH_MEMBER_TRANSACTIONS_SEARCH_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      transactions: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.donations.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.donations.filters,
          ...action.filters
        }
      }
    };

    case FETCH_PLATFORM_MEMBERS_SEARCH_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      members: action.payload
    };

    case FETCH_PLATFORM_MEMBERS_SEARCH_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      members: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.members.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.members.filters,
          ...action.filters
        }
      }
    };

    case FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_SUCCESS:
      return {
        ...state,
        isSearchInProgress: false,
        memberParticipations: action.payload
      };
    case FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_ERROR:
      return {
        ...state,
        isSearchInProgress: false,
        memberParticipations: {
          search: action.search || null,
          results: [],
          pagination: {
            ...state.memberParticipations.pagination,
            pageNumber: 1,
            totalResults: 0
          },
          filters: {
            ...state.memberParticipations.filters,
            ...action.filters
          }
        }
      };

    case FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_SUCCESS:
      return {
        ...state,
        isSearchInProgress: false,
        memberTransactions: action.payload
      };
    case FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_ERROR:
      return {
        ...state,
        isSearchInProgress: false,
        memberTransactions: {
          search: action.search || null,
          results: [],
          pagination: {
            ...state.memberTransactions.pagination,
            pageNumber: 1,
            totalResults: 0
          },
          filters: {
            ...state.memberTransactions.filters,
            ...action.filters
          }
        }
      };

    case FETCH_PLUGIN_SEARCH_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      plugins: action.payload
    };

    case FETCH_PLUGIN_SEARCH_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      plugins: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.plugins.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.plugins.filters,
          ...action.filters
        }
      }
    };

    case FETCH_SETTLEMENT_REPORTS_SUCCESS:
    return {
      ...state,
      isSearchInProgress: false,
      settlementReports: action.payload
    };

    case FETCH_SETTLEMENT_REPORTS_ERROR:
    return {
      ...state,
      isSearchInProgress: false,
      settlementReports: {
        search: action.search || null,
        results: [],
        pagination: {
          ...state.settlementReports.pagination,
          pageNumber: 1,
          totalResults: 0
        },
        filters: {
          ...state.settlementReports.filters,
          ...action.filters
        }
      }
    };

    case CLEAR_SEARCH:
    if (!state.isSearchInProgress) {
      return {
        ...initialState
      };
    };


    default:
    return state;
  }
}

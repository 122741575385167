// import {

// } from './pluginActions';

const initialState = {
  plugins: {}
};

export default function pluginReducer(state = initialState, action) {
  switch (action.type) {

  }

  return state;
}

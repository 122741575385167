import React from 'react';
import { Form } from 'antd';
import { Collapse } from 'react-collapse';
import PropTypes from 'prop-types';
import { isEmpty, pickBy, get } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FundkyButton from 'Parts/ui/components/FundkyButton';

import FakeField from 'Parts/ui/components/fields/FakeField';
import MoneyField from 'Parts/ui/components/fields/MoneyField';
import SelectField from 'Parts/ui/components/fields/SelectField';
import TextAreaField from 'Parts/ui/components/fields/TextAreaField';
import PostalCodeField from 'Parts/ui/components/fields/PostalCodeField';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';

import TextField from 'Parts/ui/components/fields/TextField';
import CountryField from 'Parts/ui/components/fields/CountryFieldContainer';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import UpdateRecipientModalContent from './UpdateRecipientModalContentContainer';
import Can from 'Parts/session/components/CanContainer';
import Plugin from '../../../plugin/Plugin';

import {
  simpleFieldValidator,
  nameFieldValidator,
  emailFieldValidator
} from 'Parts/ui/validations/fieldValidations';
import { isPluginActive } from '../../../plugin/pluginActions';
import { moneyFormat } from 'Parts/common/moneyFormat';

import TransactionsPageForm_en from './locales/TransactionsPageForm_en.json';
import TransactionsPageForm_fr from './locales/TransactionsPageForm_fr.json';

import './TransactionsPageForm.less';

class TransactionsPageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCorpoInfo: get(props, 'transaction.donation.typeId') === 2,
      country: get(props, 'transaction.information.country', null),
      administrator: props.checkAuthorization({ platform: props.platform.id }, [
        ['roles.platform.OWNER'],
        ['roles.platform.SUPERADMIN']
      ]),
      owner: get(props, 'userId') === get(props, 'transaction.userId'),
      requestUpdateModalVisible: false,
      isMonthly: get(props, 'transaction.subscription') ? true : false,
    };

    this.refUpdateRecipientModal = React.createRef();
    this.refUpdateRecipientModalContent = React.createRef();

    i18next.addResourceBundle('en', 'TransactionsPageForm', TransactionsPageForm_en);
    i18next.addResourceBundle('fr', 'TransactionsPageForm', TransactionsPageForm_fr);

    this.honorific = {
      "MR": 1,
      "MRS": 2,
      "MISS": 3,
      "MX": 4
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.transaction !== prevProps.transaction) {
      this.setState({
        showCorpoInfo: get(this.props, 'transaction.donation.typeId') === 2,
        country: get(this.props, 'transaction.information.country', null),
        owner: get(this.props, 'userId') === get(this.props, 'transaction.userId'),
        isMonthly: get(this.props, 'transaction.subscription') ? true : false
      });
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    const { validateFields, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));
        const { request_update } = updatedValues; // removed request_update

        if (!isEmpty(updatedValues)) {
          let countryCP = null;

          if (updatedValues.hasOwnProperty('country') || updatedValues.hasOwnProperty('postalCode')) {
            countryCP = {
              postalCode: allValues.postalCode,
              country: allValues.country
            };
          }

          const values = {
            ...updatedValues,
            ...countryCP
          };

          this.props.updateTransaction(
            values,
            this.props.transaction.id,
            this.props.transaction.donation.id
          );
        }
      }
    });
  };

  handleChange = value => {
    if (this.state.showCorpoInfo && value !== 2) {
      this.setState({
        showCorpoInfo: false
      });
      this.props.form.setFieldsValue({
        organizationName: '',
        organizationTitle: ''
      });
    } else if (!this.state.showCorpoInfo && value === 2) {
      this.setState({
        showCorpoInfo: true
      });
    }
  };

  onCountryChange = value => {
    this.setState({
      country: value
    });
  };

  onClickCancel = () => {
    this.props.form.resetFields();
  };

  handleDisplayUpdateRecipientModal = e => {
    e.preventDefault();
    this.refUpdateRecipientModal.current.getWrappedInstance('FundkyModal').showModal();
  };

  onAcceptModal = () => {
    this.refUpdateRecipientModalContent.refs.wrappedInstance.handleSubmit();
  };

  onCancelModal = () => {
    this.refUpdateRecipientModalContent.refs.wrappedInstance.handleReset();
  };

  closeModal = () => {
    this.refUpdateRecipientModal.current.getWrappedInstance('FundkyModal').handleCancel(null);
  };

  toggleRequestUpdateModal = e => {
    e.preventDefault();
    this.setState({ requestUpdateModalVisible: true });
  };

  onAcceptRequestUpdateModal = () => {
    const { transaction, form } = this.props;
    const { getFieldValue, validateFields } = form;

    validateFields(['request_update'], { force: true }, (err, allValues) => {
      if (!err) {
        this.props
          .requestTransactionUpdate(transaction.userId, transaction.id, allValues.request_update)
          .then(this.setState({ requestUpdateModalVisible: false }));
      }
    });
  };

  onCancelRequestUpdateModal = () => {
    this.setState({ requestUpdateModalVisible: false });
  };

  render() {
    const { props, state } = this,
      { t, locale, transaction, form, location } = props,
      { showCorpoInfo } = state;

    const isAllTransactions = location.pathname.indexOf('/d/transactions/') !== -1;
    const isCurrentUser = this.props.userId === get(this.props, 'transaction.userId', null);

    const valueArray = {
      amount: this.state.isMonthly && get(transaction, 'subscription.amount', 0) || get(transaction, 'amount', 0),
      target: get(transaction, 'donation.target', {}),
      scope: get(transaction, 'scope', {}),
      message: get(transaction, 'donation.message', ''),
      anonymous: get(transaction, 'donation.anonymous', 0),
      paymentMethod: get(transaction, 'processor', null) || get(transaction, 'paymentType', ''),
      transactionNumber: get(transaction, 'referenceId', ''),
      transactionStatus: get(transaction, 'status', ''),
      transactionTime: get(transaction, 'addedOn', ''),
      typeId: get(transaction, 'donation.typeId', ''),
      organizationName: get(transaction, 'information.organization.name', ''),
      organizationTitle: get(transaction, 'information.organization.title', ''),
      honorificId: this.honorific[get(transaction, 'information.honorific', '')] || null,
      firstName: get(transaction, 'information.firstName', ''),
      lastName: get(transaction, 'information.lastName', ''),
      postalCode: get(transaction, 'information.postalCode', ''),
      country: get(transaction, 'information.country', ''),
      email: get(transaction, 'information.email', ''),
      languageId: get(transaction, 'donation.languageId', ''),
      ccName: get(transaction, 'ccName', ''),
      ccLastFour: get(transaction, 'ccLastFour', ''),
      frequency: this.state.isMonthly && get(transaction, 'subscription.currentPeriodEnd') ? moment(transaction.subscription.currentPeriodEnd).date() : null
    };

    const anonymousOptions = [
      { value: 0, text: t('No') },
      { value: 1, text: t('Yes') }
    ];

    const transactionTypeOptions = [
      { value: 1, text: t('Personal') },
      { value: 2, text: t('Corporate') },
      { value: 3, text: t('Combined') }
    ];

    const titleOptions = [
      { value: 1, text: t('Mr') },
      { value: 2, text: t('Ms') },
      { value: 3, text: t('Miss') },
      { value: 4, text: t('Mx') }
    ];

    const languageOptions = [
      { value: 1, text: t('English') },
      { value: 2, text: t('French') }
    ];

    const TaxReceiptInformationFields =
      (isPluginActive(this.props.platform, 'TaxReceipt') &&
        this.props.organization.typeId === 2 && (
          <Plugin
            name="TaxReceipt"
            component="AdminInformationFields"
            {...{
              // Props
              form: this.props.form,
              provinces: this.props.provinces,
              locale: this.props.locale,
              organization: showCorpoInfo,
              savedValues: {
                province: get(transaction, 'information.province'),
                address: get(transaction, 'information.address'),
                unit: get(transaction, 'information.unit'),
                city: get(transaction, 'information.city')
              }
            }}
          />
        )) ||
      null;

    const ccData =
      valueArray.ccName && valueArray.ccLastFour
        ? `${valueArray.ccName} (${valueArray.ccLastFour})`
        : null;

    return (
      <Form className="TransactionsPageForm" onSubmit={this.handleSubmit}>
        {transaction &&
        <div className="inputs">
          <h3>{t('Transaction details')}</h3>
          <FakeField data={moment(valueArray.transactionTime).format(t('timeParse'))} label={t('Transaction date')} className="transaction-time" />
          {this.state.administrator &&
            <React.Fragment>
              <FakeField data={valueArray.transactionNumber} label={t('Transaction number')} className="transaction-number" />
              <FakeField data={t(valueArray.transactionStatus)} label={t('Transaction status')} className="transaction-status" />
              <FakeField data={t(valueArray.paymentMethod)} label={t('Payment method')} className="payment-method" />
              {ccData &&
                <FakeField data={ccData} label={t('Credit card')} className="transaction-cc" />
              }
            </React.Fragment>
          }
          {this.state.administrator && transaction.processor === null
            ? <MoneyField
                form={form}
                fieldId="transaction_amount"
                label={t('Transaction Amount')}
                required={true}
                locale={locale}
                maximum={9999999}
                minimum={0.01}
                initialValue={valueArray.amount}
              />
            : <FakeField
                className="transaction-amount"
                label={t('Transaction Amount')}
                data={
                  `${moneyFormat(valueArray.amount, locale)}${this.state.isMonthly ? t("/m") : ""}`
                }
              />
          }
          {valueArray.frequency &&
            <FakeField data={t('frequency-data', {count: valueArray.frequency})} label={t('Frequency')} className="transaction-frequency" />
          }

          <h3>{t('Donation details')}</h3>
          <SelectField
            form={form}
            label={t('Anonymous donation')}
            fieldId={'anonymous_donation'}
            optionsArray={anonymousOptions}
            initialValue={valueArray.anonymous}
            style={{ width: 100 }}
            disabled={this.state.administrator || this.state.owner ? false : true}
          />
          <FakeField
            className="transaction-recipient"
            label={t('Recipient')}
            data={
              <span>
                {`${t(valueArray.scope.type)} ${valueArray.scope.name}`}
                {!this.state.isMonthly &&
                <Can rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}>
                  <a
                    className="transaction-recipient-edit-icon"
                    onClick={this.handleDisplayUpdateRecipientModal}
                  >
                    <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
                  </a>
                </Can>
                }
              </span>
            }
          />
          <FundkyModal
            ref={this.refUpdateRecipientModal}
            displayModal={false}
            titleIcon={['fal', 'user-edit']}
            title={t('update-recipient-modal-title')}
            acceptLabel={t('update-recipient-modal-confirm')}
            acceptIcon={['fal', 'save']}
            acceptAction={this.onAcceptModal}
            cancelAction={this.onCancelModal}
          >
            <UpdateRecipientModalContent
              wrappedComponentRef={inst => (this.refUpdateRecipientModalContent = inst)}
              target={valueArray.scope}
              closeModal={this.closeModal}
            />
          </FundkyModal>
          {true /*valueArray.message*/ && (
            <TextAreaField
              form={form}
              fieldId={'message'}
              initialValue={valueArray.message}
              className="donation-message"
              label="Message"
              showPlaceholder={false}
              maxlength={255}
              placeholder={false}
              disabled={this.state.administrator || this.state.owner ? false : true}
            />
          )}

          <h3>{t('Donor information')}</h3>
          <SelectField
            form={form}
            label={t('Donation type')}
            fieldId={'donation_type'}
            optionsArray={transactionTypeOptions}
            initialValue={valueArray.typeId}
            style={{ width: 200 }}
            onChange={this.handleChange}
            disabled={!this.state.administrator}
          />
          <SelectField
            form={form}
            label={t('Title')}
            fieldId={'title'}
            optionsArray={titleOptions}
            initialValue={valueArray.honorificId}
            style={{ width: 100 }}
            disabled={!this.state.administrator}
          />
          <TextField
            form={form}
            label={t('FIELDS:first-name')}
            fieldId={'first_name'}
            initialValue={valueArray.firstName}
            maxlength={75}
            counter={true}
            hasFeedback
            fieldOptions={{
              rules: [...nameFieldValidator(true, 75)]
            }}
            disabled={!this.state.administrator}
          />
          <TextField
            form={form}
            label={t('FIELDS:last-name')}
            fieldId={'last_name'}
            initialValue={valueArray.lastName}
            maxlength={75}
            counter={true}
            hasFeedback
            fieldOptions={{
              rules: [...nameFieldValidator(true, 75)]
            }}
            disabled={!this.state.administrator}
          />
          <TextField
            form={form}
            label={t('Email Address')}
            fieldId={'email_address'}
            initialValue={valueArray.email}
            hasFeedback
            fieldOptions={{
              rules: [...emailFieldValidator()]
            }}
            disabled={!this.state.administrator}
          />
          <SelectField
            form={form}
            label={t('Preferred language')}
            fieldId={'languageId'}
            optionsArray={languageOptions}
            initialValue={valueArray.languageId}
            style={{ width: 200 }}
            disabled={!this.state.administrator}
          />
          <Collapse isOpened={showCorpoInfo || false}>
            <TextField
              form={form}
              label={t('Business Name')}
              fieldId={'business_name'}
              initialValue={valueArray.organizationName}
              hasFeedback
              fieldOptions={{
                rules: [...simpleFieldValidator(showCorpoInfo)]
              }}
              disabled={!this.state.administrator}
            />
            <TextField
              form={form}
              label={t('Job title')}
              fieldId={'job_title'}
              initialValue={valueArray.organizationTitle}
              hasFeedback
              fieldOptions={{
                rules: [...simpleFieldValidator(showCorpoInfo)]
              }}
              disabled={!this.state.administrator}
            />
          </Collapse>
          {this.state.administrator && (
            <React.Fragment>
              <CountryField
                className="TransactionsPageForm__Field"
                form={form}
                initialValue={valueArray.country || undefined}
                label={true}
                placeholder={true}
                onChangeHandler={this.onCountryChange}
                required={true}
                identity={showCorpoInfo ? 'company' : 'user'}
              />
              {TaxReceiptInformationFields}
              <PostalCodeField
                className="CreateInformationForm__Field"
                form={form}
                initialValue={valueArray.postalCode || undefined}
                label={true}
                placeholder={false}
                required={true}
                country={this.state.country}
                identity={showCorpoInfo ? 'company' : 'user'}
              />
            </React.Fragment>
          )}
        </div>
        }
        <div className="buttons-wrapper">
          {!isAllTransactions && isCurrentUser && (
            <React.Fragment>
              <FundkyButton
                className="request-update-button"
                type="primary"
                text={t('request-update-title')}
                size="medium"
                onLightGrey
                action={{ func: this.toggleRequestUpdateModal }}
              />

              <FundkyModal
                displayModal={this.state.requestUpdateModalVisible}
                title={t('request-update-title')}
                acceptAction={this.onAcceptRequestUpdateModal}
                cancelAction={this.onCancelRequestUpdateModal}
                acceptLabel={t('request-update-accept-label')}
                destroyOnClose
              >
                <p>{t('request-update-description')}</p>
                <TextAreaField
                  form={form}
                  fieldId="request_update"
                  placeholder={false}
                  required
                  maxlength={255}
                />
              </FundkyModal>
            </React.Fragment>
          )}

          <CancelAndSaveButtons onClickCancel={this.onClickCancel} />
        </div>
      </Form>
    );
  }
}

TransactionsPageForm.defaultProps = {
  transaction: {
    amount: undefined,
    donation: {
      target: undefined,
      message: undefined,
      anonymous: undefined,
      typeId: undefined,
      organizationName: undefined,
      organizationTitle: undefined,
      honorificId: undefined,
      firstName: undefined,
      lastName: undefined,
      postalCode: undefined,
      country: undefined,
      email: undefined,
      languageId: undefined
    }
  }
};

TransactionsPageForm.propTypes = {
  form: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
};

export default translate('TransactionsPageForm')(TransactionsPageForm);

import React from 'react';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { Button } from 'antd';
import { get } from 'lodash';

import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Plugin from '../../../plugin/Plugin';

import { simpleColumn, dateColumn } from 'Parts/common/columnUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isPluginActive } from '../../../plugin/pluginActions';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';

import ParticipationsListEN from './locales/ParticipationsList_en.json';
import ParticipationsListFR from './locales/ParticipationsList_fr.json';

class ParticipationsList extends React.Component {
  constructor(props) {
    super(props);

    props.participations.total = props.pagination.totalResults;

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle('en', 'ParticipationsList', ParticipationsListEN);
    i18next.addResourceBundle('fr', 'ParticipationsList', ParticipationsListFR);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchPlatformMembersParticipations(
      this.props.platformId,
      this.props.memberId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      false
    );
  };

  view = entity => {
    return this.props.history.push(
      `/${this.props.locale}/d/campaigns/${entity.campaignId}/participants/${entity.id}`
    );
  };

  render() {
    const { t, participations, pagination, locale } = this.props;
    const { pager } = this.state;

    return (
      <div className="AdminList">
        <PaginationTop
          totalLabel={total => t('participationsTotal', { count: pagination.totalResults || 0 })}
          pager={pager}
          onChange={this.handlePaginationChange}
        />
        <ResultTable
          dataSource={participations}
          pagination={pager}
          onChange={this.handlePaginationChange}
          showHeader={true}
          columns={[
            simpleColumn(t('Recipient'), 'campaignName', null, true),
            dateColumn(t('Created on'), 'addedOn', null, null, false, locale),
            simpleColumn(t('Edit'), null, transactions => {
              let buttons = [
                <Button
                  key={`${transactions.id}-1`}
                  shape="circle"
                  onClick={() => {
                    this.view(transactions);
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'edit']} size="lg" />
                </Button>
              ];

              return <div className="buttons">{buttons}</div>;
            })
          ]}
        />
      </div>
    );
  }
}

ParticipationsList.defaultProps = {
  participations: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('ParticipationsList')(ParticipationsList);

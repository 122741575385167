import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import TeamPublicPage from '../team/pages/TeamPublicPageContainer';
import Error404Page from './Error404Page';
import LoadingPage from './LoadingPage';

class CheckTeamRoute extends React.Component {
  componentWillUnmount() {
    this.props.clearCampaign();
  }

  render() {
    const { team, isFetchTeamInProgress, isFetchCampaignInProgress, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          if (!isFetchTeamInProgress && !isFetchCampaignInProgress) {
            if (team.id) {
              return <TeamPublicPage {...props} />;
            } else {
              return <Error404Page />;
            }
          } else {
            return <LoadingPage />;
          }
        }}
      />
    );
  }
}

CheckTeamRoute.defaultProps = {
  team: null
};

CheckTeamRoute.propTypes = {
  team: PropTypes.object
};

export default CheckTeamRoute;

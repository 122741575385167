import { bindActionCreators, compose } from "redux";
import { Form } from 'antd';
import { connect } from "react-redux";

import { updateMemberProfile, deleteMemberWorkProfile } from './../../../member/memberActions';
import { updateSessionState } from 'Parts/session/sessionActions';
import { checkAuthorization } from 'Parts/common/authorizationUtils';

import AddressForm from './AddressForm';

const mapStateToProps = state => ({
  platformAlias: state.platform.platform.alias,
  platformId: state.platform.platform.id,
  userId: state.session.userId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateMemberProfile,
  deleteMemberWorkProfile,
  updateSessionState,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(AddressForm);

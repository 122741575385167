import {
  FETCH_PARTICIPANT_START,
  FETCH_PARTICIPANT_SUCCESS,
  FETCH_PARTICIPANT_ERROR,

  FETCH_PARTICIPANT_DESCRIPTION_START,
  FETCH_PARTICIPANT_DESCRIPTION_SUCCESS,
  FETCH_PARTICIPANT_DESCRIPTION_ERROR,

  FETCH_PARTICIPANT_SETTINGS_START,
  FETCH_PARTICIPANT_SETTINGS_SUCCESS,
  FETCH_PARTICIPANT_SETTINGS_ERROR,

  FETCH_PARTICIPANT_LOGS_START,
  FETCH_PARTICIPANT_LOGS_SUCCESS,
  FETCH_PARTICIPANT_LOGS_ERROR,

  FETCH_PARTICIPANTS_START,
  FETCH_PARTICIPANTS_SUCCESS,
  FETCH_PARTICIPANTS_ERROR,

  FETCH_MEMBER_PARTICIPATIONS_START,
  FETCH_MEMBER_PARTICIPATIONS_SUCCESS,
  FETCH_MEMBER_PARTICIPATIONS_ERROR,

  UPDATE_PARTICIPANT_START,
  UPDATE_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT_ERROR,

  SAVE_PARTICIPANT_CREATE_SUCCESS,
  UI_PARTICIPANT_CAMPAIGN_SELECT,
  UI_PARTICIPANT_CAMPAIGN_CLEAR,

  CLEAR_PARTICIPANT,
  PARTICIPANT_SAVE_SUCCESS
} from './participantActions';

import {
  FETCH_DONATION_LIST_BY_PARTICIPANT_START,
  FETCH_DONATION_LIST_BY_PARTICIPANT_SUCCESS,
  FETCH_DONATION_LIST_BY_PARTICIPANT_ERROR
} from "../donation/donationActions";

const initialState = {
  selectedCampaign: {},
  createdParticipant: null,
  participant: {
    description: {},
    settings: {}
  },
  participations: {},
  participation: {},
  participants: [],
  lastDonations: [],
  logs: {},
  participantDataSave: {},
  isFetchParticipantsInProgress: false,
  isFetchParticipantInProgress: false,
  isFetchParticipantDescriptionInProgress: false,
  isFetchParticipantSettingsInProgress: false,
  isFetchParticipantLogsInProgress: false,
  isFetchDonationListByParticipantInProgress: false,
  isFetchMemberParticipations: false,
  isUpdateParticipantInProgress: false,
};

export default function participantReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PARTICIPANT_START:
      return {
        ...state,
        isFetchParticipantInProgress: true
      };
    case FETCH_PARTICIPANT_SUCCESS:
      return {
        ...state,
        isFetchParticipantInProgress: false,
        participant: {
          ...(state.participant.description ? { description: state.participant.description } : {}),
          ...(state.participant.settings ? { settings: state.participant.settings } : {}),
          ...action.payload
        }
      };
    case FETCH_PARTICIPANT_ERROR:
      return {
        ...state,
        isFetchParticipantInProgress: false,
        participant: initialState.participant
      };

    case FETCH_PARTICIPANT_DESCRIPTION_START:
      return {
        ...state,
        isFetchParticipantDescriptionInProgress: true
      };
    case FETCH_PARTICIPANT_DESCRIPTION_SUCCESS:
      return {
        ...state,
        isFetchParticipantDescriptionInProgress: false,
        participant: {
          ...state.participant,
          description: {
            ...state.participant.description,
            ...action.payload
          }
        }
      };
    case FETCH_PARTICIPANT_DESCRIPTION_ERROR:
      return {
        ...state,
        isFetchParticipantDescriptionInProgress: false,
        participant: {
          ...state.participant,
          description: initialState.participant.description
        }
      };

    case FETCH_PARTICIPANT_SETTINGS_START:
      return {
        ...state,
        isFetchParticipantSettingsInProgress: true
      };
    case FETCH_PARTICIPANT_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetchParticipantSettingsInProgress: false,
        participant: {
          ...state.participant,
          settings: {
            ...state.participant.settings,
            ...action.payload
          }
        }
      };
    case FETCH_PARTICIPANT_SETTINGS_ERROR:
      return {
        ...state,
        isFetchParticipantSettingsInProgress: false,
        participant: {
          ...state.participant,
          settings: initialState.participant.settings
        }
      };

    case FETCH_PARTICIPANT_LOGS_START:
      return {
        ...state,
        isFetchParticipantLogsInProgress: true
      };
    case FETCH_PARTICIPANT_LOGS_SUCCESS:
      return {
        ...state,
        isFetchParticipantLogsInProgress: false,
        logs: action.payload
      };
    case FETCH_PARTICIPANT_LOGS_ERROR:
      return {
        ...state,
        isFetchParticipantLogsInProgress: false,
        logs: initialState.logs
      };

    case FETCH_PARTICIPANTS_START:
      return {
        ...state,
        isFetchParticipantsInProgress: true
      };
    case FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        isFetchParticipantsInProgress: false,
        participants: action.payload
      };
    case FETCH_PARTICIPANTS_ERROR:
      return {
        ...state,
        isFetchParticipantsInProgress: false,
        participants: initialState.participants
      };

    case FETCH_DONATION_LIST_BY_PARTICIPANT_START:
      return {
        ...state,
        isFetchDonationListByParticipantInProgress: true
      };
    case FETCH_DONATION_LIST_BY_PARTICIPANT_SUCCESS:
      return {
        ...state,
        isFetchDonationListByParticipantInProgress: false,
        lastDonations: action.payload
      };
    case FETCH_DONATION_LIST_BY_PARTICIPANT_ERROR:
      return {
        ...state,
        isFetchDonationListByParticipantInProgress: false,
        lastDonations: initialState.lastDonations
      }

    case FETCH_MEMBER_PARTICIPATIONS_START:
      return {
        ...state,
        isFetchMemberParticipations: true
      };
    case FETCH_MEMBER_PARTICIPATIONS_SUCCESS:
      return {
        ...state,
        isFetchMemberParticipations: false,
        participations: action.payload
      };
    case FETCH_MEMBER_PARTICIPATIONS_ERROR:
      return {
        ...state,
        isFetchMemberParticipations: false,
        participations: initialState.participations
      };

    case UPDATE_PARTICIPANT_START:
      return {
        ...state,
        isUpdateParticipantInProgress: true
      };
    case UPDATE_PARTICIPANT_SUCCESS:
    case UPDATE_PARTICIPANT_ERROR:
      return {
        ...state,
        isUpdateParticipantInProgress: false
      };

    case SAVE_PARTICIPANT_CREATE_SUCCESS:
      return {
        ...state,
        createdParticipant: action.payload
      };

    case UI_PARTICIPANT_CAMPAIGN_SELECT:
      return {
        ...state,
        selectedCampaign: action.payload
      };

    case UI_PARTICIPANT_CAMPAIGN_CLEAR:
      return {
        ...state,
        selectedCampaign: initialState.selectedCampaign
      };

    case CLEAR_PARTICIPANT:
      return {
        ...state,
        participant: initialState.participant
      };
    case PARTICIPANT_SAVE_SUCCESS:
      return {
        ...state,
        participantDataSave: action.payload
      }
    default:
      return state;
  }
}

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { createTeam } from '../teamActions';
import { changeTeamCreateWizardStep } from '../../ui/uiActions';

import CreateInformationForm from './CreateInformationForm';

const mapStateToProps = state => ({
  isCreateTeamInProgress: state.ui.isCreateTeamInProgress,
  selectedCampaign: state.team.selectedCampaign,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeTeamCreateWizardStep,
  createTeam
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(CreateInformationForm);

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { getLanguagesKeys } from '../../../common/langUtils';

import SelectField from './SelectField';

import './MultiLangField.less';

const languagesKeys = getLanguagesKeys();

class MultiLangField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang: props.showLangSelect ? i18next.language : props.contentLang,
      langOnLoad: i18next.language
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      // When changing language while validation messages are displayed, the messages will stay in the previous language so we force a new validation to show them in the good language.
      this.props.form.validateFields({ force: true });
    }

    if (this.props.contentLang && this.props.contentLang !== prevProps.contentLang) {
      this.setState({contentLang: this.props.contentLang});
    }
  }

  handleContentLangChange = value => {
    this.setState({contentLang: value});
  }

  // To be used while validating if required locale isn't filled and if using showLangSelect={true}
  handleValidationError = () => {
    this.handleContentLangChange(this.props.locale);
    this.props.form.setFieldsValue({'langSelect': this.props.locale});
  }

  render() {
    const { t, locale, form, requiredType, children, showLangSelect, className, initialValue } = this.props;
    const { contentLang, langOnLoad } = this.state;

    const langSelectOptions = showLangSelect ? languagesKeys.map(languageKey => {
      return {
        value: languageKey,
        text: t(`LANGUAGES:${languageKey}`)
      }
    }) : null;

    return (
      <div className="MultiLang">
        {showLangSelect &&
          <SelectField
            form={form}
            fieldId="langSelect"
            className="MultiLangSelect"
            optionsArray={langSelectOptions}
            initialValue={langOnLoad}
            style={{ width: 150 }}
            onChange={this.handleContentLangChange}
          />
        }

        {languagesKeys.map(languageKey => {

          let required = false;
          if (requiredType === 'all') {
            required = true;
          } else if (requiredType === 'locale') {
            required = languageKey === locale ? true : false;
          }

          return (children
            ? React.Children.only(
                React.cloneElement(
                  children,
                  {
                    key: `${children.props.fieldId}_${languageKey}`,
                    fieldId: `${children.props.fieldId}_${languageKey}`,
                    className: classnames(`MultiLangField`, `${className}`, {isInvisible: languageKey !== contentLang}),
                    required: required,
                    hasFeedback: required, //same rules
                    initialValue: initialValue && initialValue[languageKey] || null
                  }
                )
              )
            : null
          )
        })}
      </div>
    )
  }
}

MultiLangField.defaultProps = {
  showLangSelect: true,
  requiredType: 'locale',
  className: ''
}

MultiLangField.Proptypes = {
  form: PropTypes.object.isRequired,
  showLangSelect: PropTypes.bool,
  requiredType: PropTypes.oneOf(['all', 'locale', 'none']), // All will make all localized fields required, Locale will make only the current locale (of the page and not of the content lang select) required.
  locale: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default translate('MultiLangField', { withRef: true })(MultiLangField)

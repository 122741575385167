import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { get } from 'lodash';

import PublicCarousel from '../../common/PublicCarousel';
import { getCdnUrl } from 'Parts/common/environment';
import { getPlatformUrl } from 'Parts/common/environment';

import PublicParticipantTeamMembersPanel_en from './locales/PublicParticipantTeamMembersPanel_en.json';
import PublicParticipantTeamMembersPanel_fr from './locales/PublicParticipantTeamMembersPanel_fr.json';

import '../../assets/images/default_participant_picture_300x300.jpg';

import './PublicParticipantTeamMembersPanel.less';

class PublicParticipantTeamMembersPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle(
      'en',
      'PublicParticipantTeamMembersPanel-participant',
      PublicParticipantTeamMembersPanel_en
    );
    i18next.addResourceBundle(
      'fr',
      'PublicParticipantTeamMembersPanel-participant',
      PublicParticipantTeamMembersPanel_fr
    );
  }

  render() {
    const { t, locale, teamName, teamMembers, slidesToShow } = this.props;
    const cdnUrl = getCdnUrl();

    const participantSlide =
      teamMembers === null
        ? null
        : teamMembers
            .sort((a, b) =>
              a.member.firstName.toLowerCase().localeCompare(b.member.firstName.toLowerCase()) ||
              a.member.lastName.toLowerCase().localeCompare(b.member.lastName.toLowerCase())
            )
            .map(teamMember => {
              const avatar = teamMember.avatar ? (
                <img
                  className="PublicParticipantTeamMembersPanel_Carousel--avatar"
                  src={`${cdnUrl}${teamMember.avatar}`}
                  alt="Avatar - Team Member"
                />
              ) : teamMember.member &&
                teamMember.member.information &&
                teamMember.member.information.firstName &&
                teamMember.member.information.lastName ? (
                <div className="PublicParticipantTeamMembersPanel_Carousel--avatar">
                  {teamMember.member.information.firstName[0].toUpperCase() +
                    teamMember.member.information.lastName[0].toUpperCase()}
                </div>
              ) : (
                <img
                  className="PublicParticipantTeamMembersPanel_Carousel--avatar"
                  src="/assets/default_participant_picture_300x300.jpg"
                  alt="Avatar - Team Member"
                />
              );
              return (
                <a
                  key={teamMember.id}
                  href={`/${locale}/${teamMember.campaignAlias}/${teamMember.teamAlias}/${teamMember.member.alias}`}
                >
                  {avatar}
                </a>
              );
            });

    const teamLink =
      teamMembers !== null
        ? `/${locale}/${teamMembers[0].campaignAlias}/${teamMembers[0].teamAlias}`
        : null;

    const markup =
      teamMembers === null ? null : (
        <div className="PublicPanel PublicParticipantTeamMembersPanel">
          <div className="PublicParticipantTeamMembersPanel_Information">
            <div className="iconWrap">
              <FontAwesomeIcon icon={['fas', 'users']} />
            </div>
            <div>
              <p>{t('Team Members')}</p>
              <h4>
                <Link to={teamLink}>
                  <strong>{teamName}</strong>
                </Link>
              </h4>
            </div>
          </div>
          <PublicCarousel
            className="PublicParticipantTeamMembersPanel_Carousel"
            slidesToShow={slidesToShow}
            sideControls={true}
            initialSlideHeight={
              50 /* TODO define the height + the padding in JS, and inject these variables in LESS files */
            }
            heightMode="max"
            delayInit
          >
            {participantSlide}
          </PublicCarousel>
        </div>
      );

    return <React.Fragment>{markup}</React.Fragment>;
  }
}

PublicParticipantTeamMembersPanel.defaultProps = {
  teamMembers: null,
  teamName: null
};

PublicParticipantTeamMembersPanel.propTypes = {
  slidesToShow: PropTypes.number.isRequired
};

export default translate('PublicParticipantTeamMembersPanel-participant')(
  PublicParticipantTeamMembersPanel
);

import { compose } from "redux";
import { connect } from "react-redux";
import withSizes from "react-sizes";

import PublicParticipantPanel from './PublicParticipantPanel';

import { getAntdBreakpoint } from "../parts/common/responsiveUtils";

const mapStateToProps = state => ({
  team: state.team.team,
  participants: state.participant.participants,
  campaign: state.campaign.campaign,
  locale: state.session.language,
  platform: state.platform.platform
});

const slidesToShowValues = {
  xxl: 2,
  xl: 2,
  lg: 1,
  md: 1,
  sm: 1,
  xs: 1,
  xxs: 1,
};

const mapSizesToProps = ({ width }) => ({
  slidesToShow: slidesToShowValues[getAntdBreakpoint(width)]
});

export default compose(
  withSizes(mapSizesToProps),
  connect(mapStateToProps),
)(PublicParticipantPanel);

import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import './FundkyCard.less';

class FundkyCard extends React.Component {
  render() {
    const { className, title, icon, action, onCardClick } = this.props;
    const defaultClass = 'FundkyCard';
    const classes = classnames(defaultClass, className, {hasIcon: icon}, {hasAction: action}, {hasCardAction: onCardClick});

    const iconMarkup = icon ? (
      <div className={`${defaultClass}__Icon`}>
        {icon}
      </div>
    ) : null;

    const actionMarkup = action ? (
      <div className={`${defaultClass}__Action`}>
        {action}
      </div>
    ) : null;

    return (
      <div className={classes} onClick={onCardClick ? onCardClick : null}>
        {iconMarkup}

        <div className={`${defaultClass}__Body`}>
          {title ? <p className="title">{title}</p> : null}
          {this.props.children}
        </div>

        {actionMarkup}
      </div>
    );
  }
}

FundkyCard.defaultProps = {
  icon: undefined,
  title: '',
  action: undefined,
  onCardClick: undefined
};

FundkyCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  action: PropTypes.node,
  onCardClick: PropTypes.func,
  children: PropTypes.node,
};

export default FundkyCard;

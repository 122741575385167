import {
  bindActionCreators,
  compose
} from "redux";
import {
  Form
} from 'antd';
import {
  connect
} from "react-redux";
import {
  get
} from 'lodash';

import {
  updateCampaign
} from "../../../../campaign/campaignActions";
import {
  fetchPlatformMemberById
} from '../../../../member/memberActions';
import {
  checkAuthorization
} from 'Parts/common/authorizationUtils';

import CampaignAdminPageForm from './CampaignAdminPageForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  languages: state.platform.languages,
  locale: state.session.language,
  participants: state.participant.participants.results,
  creatorLanguageId: state.member.member && state.member.member.languageId,
  members: state.search.members,
  accentColors: state.platform.platform.settings.color,
  userId: state.session.userId,
  isUpdateCampaignInProgress: state.campaign.isUpdateCampaignInProgress,
  isUpdateEventInProgress: get(state, 'Event.isUpdateEventInProgress', false),
  event: get(state, 'Event.event', {}),
  userRoleId: get(state, 'session.user.roleId')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateCampaign,
  fetchPlatformMemberById,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(CampaignAdminPageForm);

import React from 'react';
import PropTypes from 'prop-types';
import { getPlatformUrl } from 'Parts/common/environment';
import moment from 'moment';

const SitemapIndex = ({ platform, campaigns }) => {
  const platformUrl = getPlatformUrl(platform.alias);
  const lastModPage = moment(platform.updatedOn).format('YYYY/MM/DD');

  const lastModCampaign = moment(
    new Date(
      Math.max.apply(
        null,
        campaigns.map(function(campaign) {
          return new Date(campaign.updatedOn || campaign.addedOn);
        })
      )
    )
  ).format('YYYY/MM/DD');

  const sitemapCampaign = campaigns.length && (
    <sitemap>
      <loc>{`${platformUrl}/campaign-sitemap.xml`}</loc>
      <lastmod>{lastModCampaign}</lastmod>
    </sitemap>
  );

  const sitemapIndexContent = (
    <sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      <sitemap>
        <loc>{`${platformUrl}/page-sitemap.xml`}</loc>
        <lastmod>{lastModPage}</lastmod>
      </sitemap>
      {sitemapCampaign}
    </sitemapindex>
  );

  return sitemapIndexContent;
};

SitemapIndex.defaultProps = {
  platform: {
    alias: ''
  }
};

SitemapIndex.propTypes = {
  platform: PropTypes.object.isRequired,
  campaigns: PropTypes.array.isRequired
};

export default SitemapIndex;

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { openRegisterModal } from 'Parts/ui/uiActions';
import { clearDonation } from '../donationActions';

import CreateConfirmationPanel from './CreateConfirmationPanel';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  organization: state.organization.organization,

  userTypeId: state.session.userTypeId,

  checkout: state.donation.checkoutInformation,
  information: state.donation.donationInformation,
  selectedOrganization: state.donation.selectedOrganization,
  selectedCampaign: state.donation.selectedCampaign,
  selectedTeam: state.donation.selectedTeam,
  selectedParticipant: state.donation.selectedParticipant,

  countries: state.geo.countries,

  locale: state.session.language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openRegisterModal,
  clearDonation
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CreateConfirmationPanel);

import React from 'react';
import classnames from 'classnames';
import FundkyTooltip from '../FundkyTooltip';
import './FakeField.less';

class FakeField extends React.Component {
  render() {
    const { className, data, label, tooltip, notice, required } = this.props;

    let labelProperties = tooltip ? (
      <span>
        {label}
        <FundkyTooltip title={tooltip} />
      </span>
    ) : (
      label
    );

    return (
      <>
        <div
          className={`${classnames(
            'fake-field ant-row ant-form-item',
            className
          )}`}
        >
          <div className="ant-form-item-label">
            <label className={`fake-field-label${required ? ' ant-form-item-required' : ''}`}>{labelProperties}</label>
          </div>
          <div className="ant-form-item-control-wrapper">
            <div className="ant-form-item-control">
              <span className="ant-form-item-children">{data}</span>
              {this.props.message && (
                <p>
                  <em>{this.props.message}</em>
                </p>
              )}
            </div>
          </div>
        </div>
        {notice && <p className="notice">{notice}</p>}
      </>
    );
  }
}

FakeField.defaultProps = {
  label: 'Label',
  data: '',
  className: '',
  message: null,
  required: false
};

export default FakeField;

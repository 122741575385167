import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import CardExpirationField_en from './locales/CardExpirationField_en.json';
import CardExpirationField_fr from './locales/CardExpirationField_fr.json';

class CardExpirationField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CardExpirationField', CardExpirationField_en);
    i18next.addResourceBundle('fr', 'CardExpirationField', CardExpirationField_fr);
  }

  handleInputMonth(event) {
    if(event.target.value.length > event.target.maxLength) {
      event.target.value = event.target.value.slice(0, event.target.maxLength);
      return false;
    }

    let val = parseInt(event.target.value);

    if(val < 0 || val > 12 || event.target.value === "00") {
      event.target.value = "";
      return false;
    }

    if(event.target.value.length == event.target.maxLength) {
      document.getElementById("cardExpirationYear").focus();
    }
  }

  handleInputYear(event) {
    if(event.target.value.length > event.target.maxLength) {
      event.target.value = event.target.value.slice(0, event.target.maxLength);
      return false;
    }

    let val = parseInt(event.target.value);

    if(val < 0 || val > 99) {
      event.target.value = "";
      return false;
    }

    if(event.target.value.length == event.target.maxLength) {
      document.getElementById("cardCvv").focus();
    }
  }

  render() {
    const { t, form, className } = this.props;
    const { getFieldDecorator } = form;

    const input1 = <Input id="cardExpirationMonth" placeholder={t('month.placeholder')} type="number" min="1" max="12" maxLength={2} pattern="[0-9]" onInput={this.handleInputMonth} />;
    const fieldId1 = 'cardExpirationMonth';
    const fieldOptions1 = {
      rules: [
          {
            required: true,
            message: t('month.require')
          },
          {
            pattern: /^\d{2}$/,
            message: t('month.invalid')
          },
          {
            validator: (rule, value, cb) => (value >= 1 && value <= 12 ? cb() : cb(true)),
            message: t('month.invalid')
          },
          // {
          //   validator: (rule, value, cb) => {
          //     let date = new Date()
          //     let year = date.getFullYear().toString().substr(-2);
          //     let month = ("0" + (date.getMonth() + 1)).slice(-2);
          //     let valY = form.getFieldsValue(['cardExpirationYear']).cardExpirationYear;
          //     let errorY = form.getFieldError('cardExpirationYear');
          //     let valM = value;

          //     return valM && valM < month && valY == year
          //       ? cb(true)
          //       : cb()
          //   },
          //   message: t('date.invalid')
          // },
        ],
    };

    const input2 = <Input id="cardExpirationYear" placeholder={t('year.placeholder')} type="number" min="0" max="99" maxLength={2} pattern="[0-9]" onInput={this.handleInputYear} />;
    const fieldId2 = 'cardExpirationYear';
    const fieldOptions2 = {
      rules: [
          {
            required: true,
            message: t('year.require')
          },
          {
            pattern: /^\d{2}$/,
            message: t('year.invalid')
          },
          {
            validator: (rule, value, cb) => (
              value >= 0 && value <= 99
                ? cb()
                : cb(true)
            ),
            message: t('year.invalid')
          },
          {
            validator: (rule, value, cb) => {
              let date = new Date()
              let year = date.getFullYear().toString().substr(-2);
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              let valM = form.getFieldsValue(['cardExpirationMonth']).cardExpirationMonth;
              let errorM = form.getFieldError('cardExpirationMonth');
              let valY = value;

              return valY < year //||
                //valM && valM < month && valY == year
                  ? cb(true)
                  : cb()
            },
            message: t('date.invalid')
          },

        ],
    };

    return (
      <div className={className}>
        <Form.Item className={`CreatePaymentForm__Field--month`} hasFeedback>
          {getFieldDecorator(fieldId1, fieldOptions1)(input1)}
        </Form.Item>
        <span>/</span>
        <Form.Item className={`CreatePaymentForm__Field--year`} hasFeedback>
          {getFieldDecorator(fieldId2, fieldOptions2)(input2)}
        </Form.Item>
      </div>
    );
  }

}

CardExpirationField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('CardExpirationField')(CardExpirationField);

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { connectPagination } from 'react-instantsearch-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './AlgoliaPageInfo.less';

import AlgoliaPageInfo_en from './locales/AlgoliaPageInfo_en.json';
import AlgoliaPageInfo_fr from './locales/AlgoliaPageInfo_fr.json';

i18next.addResourceBundle('en', 'AlgoliaPageInfo', AlgoliaPageInfo_en);
i18next.addResourceBundle('fr', 'AlgoliaPageInfo', AlgoliaPageInfo_fr);


const Pagination = ({ currentRefinement, nbPages, refine, createURL, t }) => {
  return (
    <div className="algPageInfo">
      <span className="wherenow">
        page {currentRefinement} {t('Of')} {nbPages}
      </span>
      <span className="cta">
        {currentRefinement > 1 &&
        <a
          href={createURL(currentRefinement-1)}
          onClick={event => {
            event.preventDefault();
            refine(currentRefinement-1);
          }}
        >
          <FontAwesomeIcon icon={["fas", "chevron-left"]} className="" /> {t('Previous')}
        </a>
        }
        {currentRefinement < nbPages &&
        <a
          href={createURL(currentRefinement+1)}
          onClick={event => {
            event.preventDefault();
            refine(currentRefinement+1);
          }}
        >
          {t('Next')} <FontAwesomeIcon icon={["fas", "chevron-right"]} className="" />
        </a>
        }
      </span>
    </div>
  );
};

export default translate('AlgoliaPageInfo')(connectPagination(Pagination));
import { bindActionCreators, compose } from "redux";
import { Form } from 'antd';
import { connect } from "react-redux";
import dataFetcher from "../../../parts/chore/dataFetcher";

import { createOfflineDonation } from "../../../donation/donationActions";
import { checkAuthorization } from 'Parts/common/authorizationUtils';

import AddTransactionsPageForm from './AddTransactionsPageForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  organization: state.organization.organization,
  locale: state.session.language,
  provinces: state.geo.provinces,
  isDonationInProgress: state.ui.isDonationInProgress,
  campaign: state.campaign.campaign,
  roles: state.session.roles,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createOfflineDonation,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create(),
  dataFetcher,
)(AddTransactionsPageForm);

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Form, InputNumber, Result } from 'antd';

import { moneyFormat } from '../../../common/moneyFormat';
import { getLanguageMoneyFormatByKey } from '../../../common/langUtils';

import MoneyField_en from './locales/MoneyField_en.json';
import MoneyField_fr from './locales/MoneyField_fr.json';

import './MoneyField.less';

class MoneyField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'MoneyField', MoneyField_en);
    i18next.addResourceBundle('fr', 'MoneyField', MoneyField_fr);
  }

  formatter = value => {
    const
      string = value.toString(),
      format = getLanguageMoneyFormatByKey(this.props.locale),
      thousandSeparator = format[1],
      decomposed = string.split("."),
      wholed = parseFloat(decomposed[0].split(' ').join('').split(',').join('')),
      whole = isNaN(wholed) ? '' : wholed.toString(),
      decimal = decomposed[1] || decomposed[1] === '' ? decomposed[1].substring(0, 2) : null,
      thousandSeparated = whole.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator),
      text = decimal || decimal === '' ? thousandSeparated + "." + decimal : thousandSeparated;

    return text;
  }

  parser = value => {
    const
      string = value.toString(),
      format = getLanguageMoneyFormatByKey(this.props.locale),
      thousandSeparator = format[1],
      decomposed = string.split('.'),
      wholed = parseFloat(decomposed[0].split(thousandSeparator).join('')),
      whole = isNaN(wholed) ? '' : wholed.toString(),
      decimal = decomposed[1] || decomposed[1] === '' ? decomposed[1].substring(0, 2) : null,
      number = decimal || decimal === '' ? whole + "." + decimal : whole;

    return number;
  }

  render() {
    const
      { props, formatter, parser } = this,
      {
        t, form, initialValue, locale, displayAsText,
        label, disabled, fieldId, width, validationInside,
        wholeNumber, required, minimum, maximum,
        wholeNumberMessage, requiredMessage, minimumMessage,
        maximumMessage, decimalMessage, invalidMessage, more,
        customValidation
      } = props,
      { getFieldDecorator } = form,
      pseudo = getLanguageMoneyFormatByKey(locale)[3] ? " after" : " before",
      inside = validationInside ? ' inside' : '',
      input = <InputNumber formatter={formatter} parser={parser} className={`MoneyField__Input${pseudo}${inside}`} style={{ width: width }} disabled={disabled} />,
      requiredValidator = required ? [{
        required: true,
        message: requiredMessage || t('required')
      }] : [],
      invalidValidator = [{
        pattern: /^-?\d*\.?\d*$/,
        message: invalidMessage || t('invalid')
      }],
      wholeNumberValidator = wholeNumber ? [{
        pattern: /^[0-9]+$/,
        message: wholeNumberMessage || t('whole-number')
      }] : [],
      decimalValidator = !wholeNumber ? [{
        validator: (rule, value, callback) => {
          return parseFloat(parseFloat(value).toFixed(2)) === parseFloat(value) ? callback() : callback(true);
        },
        message: decimalMessage || t('decimal')
      }] : [],
      minimumValidator = minimum || minimum === 0 ? [{
        validator: (rule, value, callback) => {
          const _value = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
          return _value >= minimum ? callback() : callback(true);
        },
        message: minimumMessage || t('min-amount', { min: (moneyFormat(minimum, locale)) })
      }] : [],
      maximumValidator = maximum || maximum === 0 ? [{
        validator: (rule, value, callback) => {
          const _value = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
          return _value <= maximum ? callback() : callback(true);
        },
        message: maximumMessage || t('max-amount', { max: (moneyFormat(maximum, locale)) })
      }] : [],
      fieldOptions = {
        rules: [
          ...requiredValidator,
          ...invalidValidator,
          ...wholeNumberValidator,
          ...decimalValidator,
          ...minimumValidator,
          ...maximumValidator,
          ...customValidation,
        ],
        initialValue: initialValue,
        onChange: this.props.onChange,
        onInput: this.props.onChange,
      };

    return (
      <Form.Item
        className={`MoneyField MoneyField--${fieldId} ${this.props.className}`}
        label={label === true ? t('label') : label || null}
        hasFeedback
      >
        {displayAsText ? (
          <span>{moneyFormat(initialValue, locale)}</span>
        ) : getFieldDecorator(fieldId, fieldOptions)(input)}
        {more}
      </Form.Item>
    );
  }
}

MoneyField.defaultProps = {
  fieldId: 'amount',
  displayAsText: false,
  label: null,
  initialValue: 0,
  wholeNumber: false,
  wholeNumberMessage: null,
  decimalMessage: null,
  required: false,
  requiredMessage: null,
  maximum: null,
  maximumMessage: null,
  minimum: null,
  minimumMessage: null,
  invalidMessage: null,
  disabled: false,
  width: 200,
  validationInside: false,
  more: null,
  customValidation: [],
  onChange: () => {}
};

export default translate('MoneyField')(MoneyField);

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';

import './RegistrationConfirmationPanel.less';

class RegistrationConfirmationPanel extends React.Component {

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.closeConnectionModal(this.props.connectionModalForced, this.props.sessionHistory[0], this.props.sessionHistory[1]);
  }

  render() {
    const { t, locale, error, className } = this.props;

    const icon = error ? ["fal", "times-circle"] : ["fal", "check-circle"] ;
    const iconClass = error ? "error" : "success" ;
    const title = error ? t('SESSION:REGISTRATION.CONFIRM.title-error') : t('SESSION:REGISTRATION.CONFIRM.title');
    const message = error ? error.code : t('SESSION:REGISTRATION.CONFIRM.msg');
    const action = error ? t('SESSION:REGISTRATION.CONFIRM.action-error') : t('SESSION:REGISTRATION.CONFIRM.action');

    return (
      <div className={`RegistrationConfirmationPanel ${className}`}>
        <div className="RegistrationConfirmationPanel__TitleWrapper">
          <FontAwesomeIcon icon={icon} className={`RegistrationConfirmationPanel__TitleWrapper--${iconClass}`} />
          <h1>{title}</h1>
        </div>
        <p>
          {message}
        </p>
        <Button size="large" type="primary" onClick={this.handleClose}>
          {action}
        </Button>
      </div>
    );
  }

}

RegistrationConfirmationPanel.defaultProps = {
  className: '',
}

RegistrationConfirmationPanel.propTypes = {
  className: PropTypes.string,
};

export default translate('RegistrationConfirmationPanel')(RegistrationConfirmationPanel);

import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, Button, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import Plugin from '../plugin/Plugin';
import { isPluginActive } from '../plugin/pluginActions';
import LinkButton from 'Parts/common/LinkButton';
import RegisterDropdown from './RegisterDropdownContainer';
import PublicThermometer from './PublicThermometer';

import './PublicPageMobileScripts.less';

import PublicPageMobileScripts_en from './locales/PublicPageMobileScripts_en.json';
import PublicPageMobileScripts_fr from './locales/PublicPageMobileScripts_fr.json';

class PublicPageMobileScripts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      targetOffset: 260,
      RegisterDropdownPlacement: 'bottomLeft',
      //TODO: Remove this temporary hardcoded campaign functionality
      hideDonation: props.campaign.id === 354 && props.platform.id === 77
    };

    i18next.addResourceBundle('en', 'PublicPageMobileScripts', PublicPageMobileScripts_en);
    i18next.addResourceBundle('fr', 'PublicPageMobileScripts', PublicPageMobileScripts_fr);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.manageMultipleStickyElements);
    //window.removeEventListener('scroll', this.limitSidebarHeight);
    window.removeEventListener('resize', () => {
      this.manageDesktopMobile();
    });
  }

  componentDidMount() {
    this.display = '';
    window.addEventListener('resize', () => {
      this.manageDesktopMobile();
    });
    this.manageDesktopMobile();
  }

  manageDesktopMobile = () => {
    if (window.innerWidth <= 800) {
      if (this.display != 'mobile') {
        //Mobile
        this.mobileSetup();
        this.display = 'mobile';
      }
    } else {
      if (this.display != 'desktop') {
        //Desktop
        this.removeMobileSetup();
        this.display = 'desktop';
      }
    }
  };

  /*limitSidebarHeight(){
    var scrollH = window.scrollY;
    var windowH = window.innerHeight;
    var documentH = document.getElementById("MainLayout").clientHeight;
    var footerH = document.getElementById("footer").clientHeight;
    var headerH = document.getElementById("header").clientHeight;

    if(scrollH + windowH >= documentH-footerH){
      var visibleFooter = (scrollH + windowH)-(documentH-footerH);
      var sidebarH = (windowH - headerH - visibleFooter);
    }else{
      var sidebarH = (windowH - headerH);
    }
    if(sidebarH < 0){sidebarH = 0;}
    document.getElementById("dropdown").style.height = sidebarH+"px";
    //document.getElementsByClassName("scrollbar-container")[0].setAttribute("style", "height:"+sidebarH+"px");
  }*/

  mobileSetup = () => {
    //create reference for dropdown
    document.getElementById('main').removeAttribute('class');
    const dropdownTitle_ref = document.createElement('div');
    dropdownTitle_ref.setAttribute('id', 'dropdownTitle_ref');
    const dropdownTitle = document.getElementById('titleDropdown');
    if(dropdownTitle) dropdownTitle.parentNode.insertBefore(dropdownTitle_ref, dropdownTitle);

    this.prevScrollPos = document.getElementById('MainLayout').getBoundingClientRect().bottom;

    window.addEventListener('scroll', this.manageMultipleStickyElements);

    this.manageMultipleStickyElements();
  };

  removeMobileSetup = () => {
    document.getElementById('main').removeAttribute('class');
    if (document.getElementById('dropdownTitle_ref')) {
      document.getElementById('dropdownTitle_ref').remove();
    }
  };

  handleAnchorClick = e => {
    const main = document.getElementById('main');
    if (!main.classList.contains('hideHeader')) {
      main.classList.add('hideHeader');
    }
    if (!main.classList.contains('scrolling')) {
      main.classList.add('scrolling');
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        main.classList.remove('scrolling');
      }, 1000);
    }
  };

  setOffset(offSetValue) {
    this.setState({
      targetOffset: offSetValue
    });
  }

  manageMultipleStickyElements = () => {
    if (document.getElementById('dropdownTitle_ref')) {
      const main = document.getElementById('main');
      const titleDropdown_pos = document.getElementById('dropdownTitle_ref').getBoundingClientRect()
        .top;
      const headerHeight = document.getElementById('header').clientHeight;

      if (
        this.prevScrollPos < document.getElementById('MainLayout').getBoundingClientRect().bottom
      ) {
        //Pop header if scrolling up + hide it again if scrolling down
        //scrollingDirection = "up";
        if (main.classList.contains('hideHeader') && !main.classList.contains('scrolling')) {
          main.classList.remove('hideHeader');
        }
      } else if (
        this.prevScrollPos > document.getElementById('MainLayout').getBoundingClientRect().bottom
      ) {
        //scrollingDirection = "down";
        if (!main.classList.contains('hideHeader')) {
          main.classList.add('hideHeader');
        }
      }

      if (titleDropdown_pos <= headerHeight) {
        //if dropdown touches header
        //
        if (!main.classList.contains('stickDropdownAndCallToActions')) {
          main.classList.add('stickDropdownAndCallToActions');
          this.setOffset(130);
          this.setState({
            RegisterDropdownPlacement: 'topLeft'
          });
        }
      } else {
        if (main.classList.contains('stickDropdownAndCallToActions')) {
          main.classList.remove('stickDropdownAndCallToActions');
          this.setOffset(260);
          this.setState({
            RegisterDropdownPlacement: 'bottomLeft'
          });
        }
      }
    }

    this.prevScrollPos = document.getElementById('MainLayout').getBoundingClientRect().bottom;
  };

  render() {
    const {
      t,
      donationURL,
      hideButtons,
      donationDisabled,
      registrationDisabled,
      isSinglePageCampaign,
      pageType,
      entity,
      locale,
      showThermometer,
      platform,
      campaign
    } = this.props;
    const { hideDonation } = this.state;

    return (
      <React.Fragment>
        <PublicThermometer
          className="mobileThermo globalMobileOnly"
          compact={true}
          locale={locale}
          entity={entity}
          pageType
          layout="horizontal"
          showThermometer={showThermometer}
          isBillingConfigured={this.props.isBillingConfigured}
        />

        <div className="PublicPageMobileScripts globalMobileOnly" id="callToActions">
          {!hideButtons ? (
            <div className="buttonWrapper">

              {this.props.isBillingConfigured &&
              !donationDisabled &&
              !hideDonation
                ? (
                  <LinkButton
                    to={{ pathname: donationURL }}
                    type="primary"
                    className="PublicThermometer__DonateBtn"
                  >
                    {t('donate')}
                  </LinkButton>
                )
                : null
              }
              { campaign &&
                campaign.behaviorId === 2 &&
                isPluginActive(platform, 'Event')
                  ? <Plugin
                      name="Event"
                      component="BuyTicketBtn"
                      // resolve={() =>
                      //   import(/* webpackChunkName: "Event" */ '../../plugin/plugins/Event/Event')
                      // }
                      size="2x"
                      margin="1em 0"
                      {...{
                        // Props
                        template: "button",
                        plural: false
                      }}
                    />
                  : !registrationDisabled
                    ? <RegisterDropdown
                        className="PublicPanel__CTA--register"
                        DropdownPlacement={this.state.RegisterDropdownPlacement}
                      />
                    : null
              }
            </div>
          ) : null}
        </div>
        <div id="anchorMenu" className={`globalMobileOnly position-${this.props.topPosition}`}>
          <Anchor targetOffset={this.state.targetOffset} onClick={this.handleAnchorClick}>
            {pageType !== 'team' && <Anchor.Link href="#about" title={t('about_menuItem')} />}
            {pageType !== 'participant' && !isSinglePageCampaign && (
              <Anchor.Link href="#participants" title={t('participants_menuItem')} />
            )}
            <Anchor.Link href="#donors" title={t('donors_menuItem')} />
          </Anchor>
          <div id="SocialSharing__Mobile" className="SocialSharing__Mobile">
            <Popover
              placement="bottomRight"
              title={null}
              content={this.props.socialShare}
              trigger="click"
              getPopupContainer={() => document.getElementById('SocialSharing__Mobile')}
            >
              <Button>
                <FontAwesomeIcon icon={['fas', 'share']} />
                <span>{t('Share')}</span>
              </Button>
            </Popover>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PublicPageMobileScripts.defaultProps = {
  donationURL: '',
  hideButtons: false,
  donationDisabled: false,
  registrationDisabled: false,
  isSinglePageCampaign: false,
  pageType: null,
  showThermometer: true,
  topPosition: "normal"
};

PublicPageMobileScripts.propTypes = {
  donationURL: PropTypes.string,
  hideButtons: PropTypes.bool,
  donationDisabled: PropTypes.bool,
  registrationDisabled: PropTypes.bool,
  isSinglePageCampaign: PropTypes.bool,
  pageType: PropTypes.string,
  socialShare: PropTypes.node.isRequired
};

export default translate('PublicPageMobileScripts')(PublicPageMobileScripts);

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { Form } from 'antd';

import dataFetcher from 'Parts/chore/dataFetcher';
import { searchPlugins, clearSearch } from '../../search/searchActions';
import SurveysListPage from './SurveysListPage';
import {
  fetchSurveysInformations,
  deleteSurveyInformations,
} from '../surveys/SurveysListPageActions';

const mapStateToProps = (state) => ({
  platform: get(state, 'platform.platform'),
  locale: get(state, 'session.language'),
  plugins: get(state, 'search.plugins.results', []),
  pagination: state.surveysInformation.surveysInformation.pagination,
  filters: state.surveysInformation.surveysInformation.filterBy,
  search: state.surveysInformation.surveysInformation.search,
  orderBy: get(state, 'search.plugins.orderBy'),
  surveysInformation: state.surveysInformation.surveysInformation.results,
  user: state.session.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchPlugins,
      clearSearch,
      fetchSurveysInformations,
      deleteSurveyInformations,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher,
  Form.create()
)(SurveysListPage);

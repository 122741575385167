import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout, Collapse, Icon } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import PlatformSocialMedia from '../../common/PlatformSocialMedia';
import { getTextLength } from '../../common/contentUtils';

import PublicFooter_en from './locales/PublicFooter_en.json';
import PublicFooter_fr from './locales/PublicFooter_fr.json';

import './PublicFooter.less';

import 'Parts/assets/images/logo_fundky_white.png';

const { Panel } = Collapse;

class PublicFooter extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicFooter', PublicFooter_en);
    i18next.addResourceBundle('fr', 'PublicFooter', PublicFooter_fr);
  }

  componentDidMount() {
    this.props.fetchPlatformContent(this.props.platform.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.props.fetchPlatformContent(this.props.platform.id);
    }
  }

  render() {
    const { t, locale, lastCampaigns, platform, organization, content } = this.props;
    const platformSocial = platform.settings.social;
    const campaignEnabled = Number(platform.settings.campaign.creation.enabled) || false;
    const newsletterOptInIcon = <FontAwesomeIcon icon={['far', 'arrow-right']} />;
    const date = new Date();
    const year = date.getFullYear();

    const lastCampaignItems = lastCampaigns.slice(0, 3).map(campaign => (
      <li key={campaign.id}>
        <Link to={`/${locale}/${campaign.alias}`}>{campaign.name}</Link>
      </li>
    ));

    const lastCampaignsMenu = lastCampaigns.length ? (
      <div>
        <h4>{t('latest-campaigns')}</h4>
        <ul>{lastCampaignItems}</ul>
      </div>
    ) : null;

    const thirdPartyGuidelines =
      (content && content.campaign && content.campaign.thirdPartyGuidelines) || null;
    const campaignCreationEnabled =
      (platform &&
        platform.settings &&
        platform.settings.campaign &&
        platform.settings.campaign.creation &&
        platform.settings.campaign.creation.enabled) ||
      null;

    return (
      <Layout.Footer className={`PublicFooter ${this.props.className}`} id="footer">
        <div className="PublicFooter__PlatformSection FundkyWrap">
          <Collapse
            accordion
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) => <Icon type="caret-down" rotate={isActive ? 180 : 0} />}
          >
            <Panel header={t('help')} key="1" forceRender={true}>
              <ul>
                {campaignEnabled && (
                  <li>
                    <Link to={`/${locale}/${t('URL:create-a-campaign')}`}>
                      {t('create-campaign')}
                    </Link>
                  </li>
                )}
                {this.props.isBillingConfigured &&
                  <li>
                    <Link to={`/${locale}/${t('URL:give')}`}>{t('make-donation')}</Link>
                  </li> ||
                  null
                }
              </ul>
            </Panel>
            <Panel header={t('latest-campaigns')} key="2" forceRender={true}>
              <ul>{lastCampaignItems}</ul>
            </Panel>
            <Panel header={t('about-us')} key="3" forceRender={true}>
              <ul>
                <li>
                  <Link to={`/${locale}/${t('URL:contact-us')}`}>{t('contact-us')}</Link>
                </li>
                {get(organization, 'profiles.general.website') && (
                  <li>
                    <a
                      href={get(organization, 'profiles.general.website')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('official-website')}
                    </a>
                  </li>
                )}
                <li>
                  <Link to={`/${locale}/${t('URL:refund-policy')}`}>{t('refund-policy')}</Link>
                </li>
                {campaignCreationEnabled &&
                  campaignCreationEnabled === '1' &&
                  thirdPartyGuidelines &&
                  getTextLength(content.campaign.thirdPartyGuidelines, true) > 0 && (
                    <li>
                      <Link to={`/${locale}/${t('URL:organization-guidelines')}`}>
                        {t('organization-guidelines')}
                      </Link>
                    </li>
                  )}
              </ul>
            </Panel>
            <PlatformSocialMedia
              platformSocial={platformSocial}
              size="2x"
              title={t('follow-us')}
              iconColor={true}
              className="PlatformSocialLinks"
            />
          </Collapse>
        </div>
        <div className="PublicFooter__WaveBorder" />
        <div className="PublicFooter__FundkySection">
          <div className="FundkyWrap">
            <div className="PublicFooter__Divider">
              <div className="PublicFooter__Newsletter">
                <span className="PublicFooter__GetFundky">Get Fundky!</span>
                {/* <p>{t('newsletter-text')}</p> */}
                {/* <Form>
                  <Input size="large" placeholder={t('newsletter-placeholder')} suffix={newsletterOptInIcon} />
                </Form> */}
              </div>
              <div className="PublicFooter__FundkySocialLinks">
                <a href="https://www.fb.me/Fundky" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} transform="shrink-6" fixedWidth />
                </a>
                <a
                  href="https://www.instagram.com/fundkyfam/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'instagram']} transform="shrink-6" fixedWidth />
                </a>
                <a
                  href="https://twitter.com/fundkysupport"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'twitter']} transform="shrink-6" fixedWidth />
                </a>
                <a
                  href="https://www.linkedin.com/company/fundky/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'linkedin-in']} transform="shrink-6" fixedWidth />
                </a>
                <a
                  href="https://www.slideshare.net/Fundky"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'slideshare']} transform="shrink-6" fixedWidth />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC85TLoaotOTYtqyRTwG5zag"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['fab', 'youtube']} transform="shrink-6" fixedWidth />
                </a>
              </div>
            </div>
            <div className="PublicFooter__Divider">
              <div className="PublicFooter__LegalLinks">
                <a href={t('URL:terms-of-use')} target="_blank" rel="noopener noreferrer">
                  {t('terms')}
                </a>
                <a href={t('URL:privacy-policy')} target="_blank" rel="noopener noreferrer">
                  {t('privacy-policy')}
                </a>
                <a href={t('URL:cookie-policy')} target="_blank" rel="noopener noreferrer">
                  {t('cookie-policy')}
                </a>
                <a href="https://fundky.tawk.help/" target="_blank" rel="noopener noreferrer">
                  {t('faq')}
                </a>
              </div>

              <div className="PublicFooter__CopyrightContainer">
                <span>{`${t('copyright')} ${year}`}</span>
              </div>

              <div className="PublicFooter__PoweredContainer">
                <img
                  className="PublicFooter__PoweredImg"
                  src="/assets/logo_fundky_white.png"
                  alt="Get Fundky!"
                />
                <span className="PublicFooter__PoweredTxt">{t('powered')}</span>
              </div>
            </div>
          </div>
        </div>
      </Layout.Footer>
    );
  }
}

PublicFooter.propTypes = {
  lastCampaigns: PropTypes.array.isRequired
};

export default translate('PublicFooter')(PublicFooter);

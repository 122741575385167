import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from "../../parts/chore/dataFetcher";

import { searchSettlementReports, clearSearch } from '../../search/searchActions';
import { downloadSettlementReport } from '../../organization/organizationActions';

import TransactionsSettlementPage from './TransactionsSettlementPage';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  organizationId: state.organization.organization.id,
  settlementReports: state.search.settlementReports.results,
  pagination: state.search.settlementReports.pagination,
  filters: state.search.settlementReports.filters,
  search: state.search.settlementReports.search,
  orderBy: state.search.settlementReports.orderBy,
  locale: state.session.language,
  billing: state.organization.organization.billing,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchSettlementReports,
  downloadSettlementReport,
  clearSearch
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(TransactionsSettlementPage);

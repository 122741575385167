import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from "../../parts/chore/dataFetcher";

import MyProfilePage from './MyProfilePage';

const mapStateToProps = state => ({
  member: state.member.member,
  currentUserId: state.session.userId,
  platformId: state.platform.platform.id,
  platformAlias: state.platform.platform.alias,
  locale: state.session.language,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  dataFetcher
)(MyProfilePage);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import GoalField_en from './locales/GoalField_en.json';
import GoalField_fr from './locales/GoalField_fr.json';

class GoalField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'GoalField', GoalField_en);
    i18next.addResourceBundle('fr', 'GoalField', GoalField_fr);
  }

  render() {
    const { props } = this,
      { t, form, className, locale, initialValue } = props,
      { getFieldDecorator } = form,
      before = locale == 'en' ? t('currency') : undefined,
      after = locale == 'fr' ? t('currency') : undefined,
      minGoal = 5,
      input = (
        <Input
          onChange={this.props.onChange}
          size="large"
          placeholder={t('placeholder')}
          addonBefore={before}
          addonAfter={after}
        />
      ),
      fieldId = 'goal',
      fieldOptions = {
        initialValue: initialValue,
        rules: [
          {
            required: true,
            message: t('require')
          },
          {
            pattern: /^(?=.*\d)[\d ]+$/,
            message: t('invalid')
          },
          {
            validator: (rule, value, callback) => {
              const _value = parseInt(value);
              return _value >= minGoal ? callback() : callback(true);
            },
            message: t('min-goal', { min: minGoal })
          }
        ]
      };

    return (
      <Form.Item className={className} hasFeedback>
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }
}

GoalField.defaultProps = {
  initialValue: null
};

GoalField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired
};

export default translate('GoalField')(GoalField);

import React from 'react';
import PropTypes from 'prop-types';

import LoadingPage from '../chore/LoadingPage';

class Plugin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      module: null
    };

    this.ref = React.createRef();
  }

  // after the initial render, wait for module to load
  async componentDidMount() {
    const { default: module } = await import(
      /* webpackChunkName: "[request]" */
      /* webpackMode: "lazy-once" */
      `./plugins/${this.props.name}/${this.props.name}`
    );

    const { name, reducers } = module;
    const { store } = this.context;

    if (name && store && reducers) {
      store.injectReducer(name, reducers);
    }

    this.setState({ module });
    const instance = this.ref && this.ref.current && this.ref.current.getWrappedInstance(this.props.name) || null;
    this.actions = instance && instance.actions || null;
  }

  // EXAMPLE:
  //  <Plugin
  //    name="TaxReceipt" //Required for ref
  //    component="TaxReceiptField" //To display Plugin component
  //    ref={this.pluginRef} //To access Plugin actions
  //    {...{
  //      //PROPS
  //      a: 1
  //      b: 2
  //    }}
  //  />

  render() {
    const { module } = this.state;
    const { alignment, size, margin } = this.props;

    if (!module) return <LoadingPage alignment={alignment} size={size} margin={margin} />;

    if (module.view) return React.createElement(module.view, { ...this.props, ref: this.ref });
  }
}

Plugin.contextTypes = {
  store: PropTypes.object
};

Plugin.defaultProps = {
  alignment: 'center',
  size: '6x',
  margin: '200px 0'
};

Plugin.propTypes = {
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  size: PropTypes.string,
  margin: PropTypes.string
};

export default Plugin;

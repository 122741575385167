import React from 'react';
import i18next from 'i18next';
import { translate } from 'react-i18next';

import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';

import MyContributionsListParticipationsEN from './locales/MyContributionsListParticipations_en.json';
import MyContributionsListParticipationsFR from './locales/MyContributionsListParticipations_fr.json';

class MyContributionsListParticipations extends React.Component {
  constructor(props) {
    super(props);

    props.participations.total = props.pagination.totalResults;

    i18next.addResourceBundle(
      'en',
      'MyContributionsListParticipations',
      MyContributionsListParticipationsEN
    );
    i18next.addResourceBundle(
      'fr',
      'MyContributionsListParticipations',
      MyContributionsListParticipationsFR
    );

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchParticipations(
      this.props.memberId,
      this.props.platformId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      false
    );
  };

  handleViewHandler = participation => {
    return this.props.history.push(
      `/${this.props.locale}/d/contributions/participations/${participation.id}`
    );
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchParticipations(
      this.props.memberId,
      this.props.platformId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      false
    );
  };

  render() {
    const { t, participations, pagination, locale } = this.props;
    const { pager } = this.state;

    return (
      <div className="AdminList">
        <PaginationTop
          totalLabel={total => t('contributionsTotal', { count: pagination.totalResults || 0 })}
          pager={pager}
          onChange={this.handlePaginationChange}
        />
        <ResultTable
          dataSource={participations}
          pagination={pager}
          onChange={this.handlePaginationChange}
          showHeader={true}
          columns={[
            simpleColumn(t('Campaign name'), 'campaignName', null, true),
            dateColumn(t('Registered on'), 'addedOn', null, null, false, locale),
            actionColumn(t('Edit'), ['fal', 'edit'], this.handleViewHandler)
          ]}
        />
      </div>
    );
  }
}

MyContributionsListParticipations.defaultProps = {
  participations: [],
  pagination: {
    totalResults: 0
  }
};

MyContributionsListParticipations.propTypes = {};

export default translate('MyContributionsListParticipations')(MyContributionsListParticipations);

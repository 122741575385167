import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import PublicTitleDropdown from '../../common/PublicTitleDropdownContainer';
import HelpText from '../../common/HelpText';
import platformSwitcherEN from './locales/PlatformSwitcher_en.json';
import platformSwitcherFR from './locales/PlatformSwitcher_fr.json';
import { getPlatformUrl } from 'Parts/common/environment';

import './PlatformSwitcher.less';

class PlatformSwitcher extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PlatformSwitcher', platformSwitcherEN);
    i18next.addResourceBundle('fr', 'PlatformSwitcher', platformSwitcherFR);
  }

  render() {
    const { t, currentPlatform } = this.props;

    return (
      <div className="PlatformSwitcher">
        <HelpText>{t('Platform')}</HelpText>
        <PublicTitleDropdown
          className="PlatformSwitcher__Dropdown"
          title={currentPlatform.name}
          entities={[]}
          helpLabel={t('Select')}
          getEntityLinkUrl={entity => getPlatformUrl(entity.alias)}
        />
      </div>
    );
  }
}

PlatformSwitcher.defaultProps = {
  currentPlatform: {}
};

PlatformSwitcher.propTypes = {
  currentPlatform: PropTypes.object.isRequired
};

export default translate('PlatformSwitcher')(PlatformSwitcher);

export const CAMPAIGN_CREATE_WIZARD_INFORMATION = 'CAMPAIGN_CREATE_WIZARD_INFORMATION';
export const CAMPAIGN_CREATE_WIZARD_PERSONALIZATION = 'CAMPAIGN_CREATE_WIZARD_PERSONALIZATION';
export const CAMPAIGN_CREATE_WIZARD_CONFIRMATION = 'CAMPAIGN_CREATE_WIZARD_CONFIRMATION';

export const TEAM_CREATE_WIZARD_CAMPAIGN = 'TEAM_CREATE_WIZARD_CAMPAIGN';
export const TEAM_CREATE_WIZARD_INFORMATION = 'TEAM_CREATE_WIZARD_INFORMATION';
export const TEAM_CREATE_WIZARD_CONFIRMATION = 'TEAM_CREATE_WIZARD_CONFIRMATION';

export const PARTICIPANT_CREATE_WIZARD_CAMPAIGN = 'PARTICIPANT_CREATE_WIZARD_CAMPAIGN';
export const PARTICIPANT_CREATE_WIZARD_INFORMATION = 'PARTICIPANT_CREATE_WIZARD_INFORMATION';
export const PARTICIPANT_CREATE_WIZARD_CONFIRMATION = 'PARTICIPANT_CREATE_WIZARD_CONFIRMATION';
export const PARTICIPANT_CREATE_SURVEY_ATTEMPT = 'PARTICIPANT_CREATE_SURVEY_ATTEMPT';

export const SEARCH_TAB_CAMPAIGNS = 'SEARCH_TAB_CAMPAIGNS';
export const SEARCH_TAB_TEAMS = 'SEARCH_TAB_TEAMS';
export const SEARCH_TAB_PARTICIPANTS = 'SEARCH_TAB_PARTICIPANTS';

export const DONATION_CREATE_WIZARD_DONATION = 'DONATION_CREATE_WIZARD_DONATION';
export const DONATION_CREATE_WIZARD_INFORMATION = 'DONATION_CREATE_WIZARD_INFORMATION';
export const DONATION_CREATE_SURVEY = 'DONATION_CREATE_SURVEY';
export const DONATION_CREATE_WIZARD_PAYMENT = 'DONATION_CREATE_WIZARD_PAYMENT';
export const DONATION_CREATE_WIZARD_CONFIRMATION = 'DONATION_CREATE_WIZARD_CONFIRMATION';
export const DONATION_CREATE_WIZARD_DECLINED = 'DONATION_CREATE_WIZARD_DECLINED';

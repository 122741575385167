import React from 'react';
import PropTypes from 'prop-types';
import { Form, Alert, message } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

import EmailField from '../../ui/components/fields/EmailField';
import PasswordField from '../../ui/components/fields/PasswordField';
import PlatformField from "./fields/PlatformField";
import SubmitButton from '../../common/SubmitButton';

import { CONNECTION_MODAL_LOGIN, CONNECTION_MODAL_CLOSED } from '../../ui/uiUtils';
import { saveTwitchConnectCode } from 'Parts/common/cookieUtils';

import LoginForm_en from './locales/LoginForm_en.json';
import LoginForm_fr from './locales/LoginForm_fr.json';

import './LoginForm.less';
import 'Parts/assets/images/twitch-icon-white.png';

const config = require(`../../../config/config.${ENVIRONMENT}.json`);
class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'LoginForm', LoginForm_en);
    i18next.addResourceBundle('fr', 'LoginForm', LoginForm_fr);

    this.token = null;

    // [NORESET]
    // - Show Twitch Connect btn
    this.exceptions_NORESET = [
      {environment: "development", organizationId: 40, platformId: 66}, //jade-charity
      {environment: "preproduction", organizationId: 26, platformId: 27}, //association-marianne
      {environment: "production", organizationId: 78, platformId: 134}, //noreset
    ];

    this.state = {
      isException_NORESET: this.checkExceptions(this.exceptions_NORESET, this.props.platform),
    };
  }

  componentDidMount() {
    this.setState({
      isException_NORESET: this.checkExceptions(this.exceptions_NORESET, this.props.platform),
    });
  }

  componentDidUpdate(prevProps) {
    const { location, t, connectionModalState} = this.props;

    if (
      prevProps.connectionModalState === CONNECTION_MODAL_LOGIN &&
      prevProps.connectionModalState !== connectionModalState &&
      connectionModalState === CONNECTION_MODAL_CLOSED
    ) {
      const badge = document.getElementsByClassName("grecaptcha-badge")[0];
      if (
        badge && badge.classList && badge.classList.contains("show") &&
        location.pathname.indexOf(`/${t('URL:give/payment')}`) === -1 &&
        location.pathname.indexOf(`/${t('URL:ticket-purchase/details')}`) === -1
      ) {
        badge.classList.remove("show");
      }
    }

    if (prevProps.platform.id !== this.props.platform.id) {
      setState({
        isException_NORESET: this.checkExceptions(this.exceptions_NORESET, this.props.platform),
      });
    }
  }

  checkExceptions = (exceptions, entity) => {
    let environment = ENVIRONMENT;
    let organizationId = entity.organization.id;
    let platformId = entity.id;

    return exceptions.filter(exc => {
        return exc.environment === environment &&
            exc.organizationId === organizationId &&
            exc.platformId === platformId;
    }).length ? true : false;
  };

  getReCaptchaToken = async action => {
    return this.token = await this.props.googleReCaptchaProps.executeRecaptcha(action)
  };

  handleSwitchToForgot = event => {
    event.preventDefault();
    this.props.openRecoveryModal();
  }

  handleSwitchToRegister = event => {
    event.preventDefault();
    this.props.openRegisterModal();
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // Set reCAPTCHA token
        this.getReCaptchaToken('connection').then(()=>{
          values.reCAPTCHA = this.token || null;
          this.props.login(values, this.props.organizationId, this.props.platformId)
            .then(msg => {
              if( msg.error ) {

              }
            });
        });
      }
    });
  };

  getAlertDescription = connectionModalOrigin => {
    const { t, locale, kickedOut } = this.props;
    if (kickedOut) {
      return t('SESSION:EXPIRATION.KICKEDOUT.message');
    } else
    if (connectionModalOrigin === `/${locale}/${t('URL:create-a-campaign')}`) {
      return t('alert-campaign');
    } else
    if (connectionModalOrigin === `/${locale}/${t('URL:create-a-team')}`) {
      return t('alert-team');
    } else
    if (connectionModalOrigin === `/${locale}/${t('URL:participant-registration')}`) {
      return t('alert-participant');
    }

    return "";
  };

  getTwitchUrl = () => {
    this.validation = this.generateString(60);
    let href  = 'https://id.twitch.tv/oauth2/authorize'
        href += '?response_type=code';
        href += '&client_id=' + config.twitch.clientId;
        href += '&redirect_uri=' + config.twitch.redirectURI;
        href += '&scope=user:read:email';
        href += '&state=' + (this.props.from || this.props.history.location.pathname) + '?validation=' + this.validation;

    return href;
  };

  generateString = length => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  handleTwitchConnect = (e) => {
    var href = e.target.getAttribute('href');
    e.preventDefault();
    saveTwitchConnectCode(this.validation);
    window.location = href;
  }

  render() {
    const { t, className, customAlert, locale, from } = this.props;
    const platformField = this.props.platform ? <PlatformField className="ConnectionForm__HiddenField" form={this.props.form} /> : '';
    const alert = this.props.connectionModalForced
      ? <Alert
          message={t('alert-title')}
          description={this.getAlertDescription(this.props.connectionModalOrigin)}
          type="info"
          showIcon
        />
      : null;

    const error = this.props.connectionStatus === "fail" ? (
      <Alert
        message={t('error-title')}
        description={(
          <span>
            {t('error-forgot')}<br/>
            <a href="/" className="ant-alert-description-link" onClick={this.handleSwitchToForgot}>{t('error-follow-link')}</a>
          </span>
        )}
        type="error"
        showIcon
      />
    ) : null;

    const customAlertMarkup = customAlert ? (
      <Alert
        message={customAlert.title}
        description={customAlert.description}
        type={customAlert.type || "info"}
        showIcon
      />
    ) : null;

    return (
      <Form
        className={`ConnectionForm ${className}`}
        onSubmit={this.handleSubmit}
      >
        {!get(customAlert, 'only') && error || !get(customAlert, 'only') && alert || null}
        {customAlertMarkup}
        <h1>{t('title')}</h1>
        {/*!this.state.isException_NORESET &&*/
        <>
          <EmailField
            className="ConnectionForm__Field"
            form={this.props.form}
            fieldId={'login_email'}
            label={false}
            placeholder={true}
          />
          <PasswordField
            className="ConnectionForm__Field ConnectionForm__Field--password"
            form={this.props.form}
            label={false}
            placeholder={true}
          />
          <p className="ConnectionForm__ForgotLink">
            <a href="/" onClick={this.handleSwitchToForgot}>{t('forgot')}</a>
          </p>
          {platformField}
          <SubmitButton className="ConnectionForm__Field" loading={this.props.isLoginInProgress}>
            {t('action')}
          </SubmitButton>
        </>
        }

        {this.state.isException_NORESET &&
        <Form.Item>
          <a href={this.getTwitchUrl()}
            className="twitch-btn"
            onClick={this.handleTwitchConnect}>{t('Se connecter avec Twitch')}
            <img src="/assets/twitch-icon-white.png" alt="Twitch" width="20" /></a>
        </Form.Item>
        }

        {!this.state.isException_NORESET &&
        <p className="ConnectionForm__EndNote">
          {t('no-account')}<br/>
          <a href="/" onClick={this.handleSwitchToRegister}>{t('create-account')}</a>
        </p>
        }
      </Form>
    );
  }

}

LoginForm.defaultProps = {
  className: '',
  customAlert: null
}

LoginForm.propTypes = {
  className: PropTypes.string,
  openRegisterModal: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  isLoginInProgress: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  connectionModalForced: PropTypes.bool.isRequired,
  customAlert: PropTypes.object,
  connectionModalOrigin: PropTypes.string,
  platform: PropTypes.object,
};

export default translate('LoginForm')(LoginForm);

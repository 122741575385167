import { compose } from 'redux';
import { connect } from 'react-redux';
import withSizes from "react-sizes";

import { getAntdBreakpoint } from "../../parts/common/responsiveUtils";

import PublicParticipantTeamMembersPanel from './PublicParticipantTeamMembersPanel';

const mapStateToProps = state => ({
  platformAlias: state.platform.platform.alias,
  locale: state.session.language,
  teamMembers: state.team.participants.results
});

const slidesToShowValues = {
  xxl: 5,
  xl: 5,
  lg: 4,
  md: 4,
  sm: 3,
  xs: 3
};

const mapSizesToProps = ({ width }) => ({
  slidesToShow: slidesToShowValues[getAntdBreakpoint(width)]
});

export default compose(
  withSizes(mapSizesToProps),
  connect(mapStateToProps)
)(PublicParticipantTeamMembersPanel);

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs, Menu, Dropdown } from 'antd';
import { Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ResponsiveTabs.less';

class ResponsiveTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parentSlug: this.getParentSlug(props.parentPage.location.pathname)
    };
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { parentPage, pages, defaultTabHasNoSlug } = this.props;
    //if the url doesnt specify a tab, select first tab.
    if (
      !defaultTabHasNoSlug &&
      parentPage.location.pathname === this.state.parentSlug
    ) {
      parentPage.history.push(this.parentSlug + '/' + pages[0]['slug']);
    }
  }

  componentDidUpdate() {
    this.getParentSlug(this.props.parentPage.location.pathname);
  }

  getParentSlug = pathname => {
    let parentSlug = pathname;
    const { pages, defaultTabHasNoSlug } = this.props;
    //get the real parent slug
    const lastSlug = parentSlug.substring(parentSlug.lastIndexOf('/') + 1);
    pages.forEach(page => {
      if (page.slug === lastSlug) {
        const lastIndex = parentSlug.lastIndexOf(`/${lastSlug}`);
        parentSlug = parentSlug.substring(0, lastIndex);
      }
    });

    if (!defaultTabHasNoSlug) {
      if (lastSlug === pages[0]['slug']) {
        parentSlug = parentSlug.replace(new RegExp('/' + lastSlug), '');
      }
    }

    if (!this.state || !this.state.parentSlug) {
      return parentSlug;
    } else if (parentSlug !== this.state.parentSlug) {
      this.setState({
        parentSlug: parentSlug
      });
    }
  };

  getActiveKey = () => {
    const { parentPage, defaultTabHasNoSlug, pages } = this.props;
    //get tab slug
    if (
      defaultTabHasNoSlug &&
      this.state.parentSlug === parentPage.location.pathname
    ) {
      return pages[0]['slug'];
    } else {
      return parentPage.location.pathname.substring(
        parentPage.location.pathname.lastIndexOf('/') + 1
      );
    }
  };

  handleTabChange = activeKey => {
    const { parentPage, pages, defaultTabHasNoSlug } = this.props;
    //update address bar
    if (defaultTabHasNoSlug && activeKey === pages[0]['slug']) {
      parentPage.history.push(this.state.parentSlug);
    } else {
      parentPage.history.push(this.state.parentSlug + '/' + activeKey);
    }
  };

  render() {
    const { pages, parentPage, defaultTabHasNoSlug, is_survey } = this.props;
    const { TabPane } = Tabs;
    const activeKey = this.getActiveKey();
    const homepageUrl = this.state.parentSlug;
    const filteredPages = pages.filter(page => page.visible != false);

    const menu = (
      <Menu>
        {filteredPages.map(function(page, index) {
          if (page.slug != activeKey) {
            let tabPath = homepageUrl + '/' + page.slug;
            if (defaultTabHasNoSlug && index === 0) {
              tabPath = homepageUrl;
            }
            return (
              <Menu.Item key={page.slug}>
                <Link to={tabPath}>
                  <FontAwesomeIcon
                    className="AdminHeader__PlatformSubMenuIcon"
                    icon={['far', 'arrow-right']}
                  />
                  {page.title}
                </Link>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );

    return (
      <div className="responsiveTabs">
        {filteredPages.length > 1 && (
          <Dropdown
            overlay={menu}
            overlayClassName="mobileTabsSubmenu"
            getPopupContainer={trigger => trigger.parentNode}
            trigger={['click']}
          >
            <a className="ant-dropdown-link" href="#">
              <FontAwesomeIcon
                className="AdminHeader__PlatformMenuIcon"
                icon={['far', 'ellipsis-h']}
              />
            </a>
          </Dropdown>
        )}
        <Tabs onChange={this.handleTabChange} activeKey={activeKey}>
          {filteredPages.map((page, index) => {
            let tabPath = homepageUrl + '/' + page.slug;

            if (defaultTabHasNoSlug && index === 0) {
              tabPath = homepageUrl;
            }

            // div added to fix rerender on Client from SSR
            return (
              <TabPane tab={page.title} key={page.slug} disabled={is_survey && !parentPage.match.params.id}>
                <div>
                  <Route exact path={tabPath} render={page.container} />
                </div>
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

ResponsiveTabs.defaultProps = {
  pages: [],
  defaultTabHasNoSlug: false
};

ResponsiveTabs.propTypes = {
  pages: PropTypes.array,
  defaultTabHasNoSlug: PropTypes.bool
};

export default ResponsiveTabs;

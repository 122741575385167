import {
  bindActionCreators,
  compose
} from 'redux';
import {
  connect
} from 'react-redux';
import {
  Form
} from 'antd';
import {
  get
} from 'lodash';

import {
  updatePlatformContent,
  deletePlatformContent
} from '../../../platform/platformActions';

import RefundPolicyForm from './PagesRefundPageForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  languages: state.platform.languages,
  content: get(state, 'platform.content.refundPolicy'),
  locale: state.session.language,
  isFetchPlatformContentInProgress: state.platform.isFetchPlatformContentInProgress,
  accentColors: state.platform.platform.settings.color,
  userRoleId: get(state, 'session.user.roleId')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformContent,
  deletePlatformContent
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(RefundPolicyForm);

import {
  bindActionCreators,
  compose
} from "redux";
import {
  connect
} from "react-redux";
import {
  withRouter
} from 'react-router-dom';
import { get } from "lodash";

import {
  fetchCampaign,
  clearCampaign
} from "../campaignActions";
import {
  getLastCampaigns
} from "../campaignSelectors";

import CampaignPublicPage from './CampaignPublicPage';

const mapStateToProps = state => ({
  platformAlias: state.platform.platform.alias,
  platformId: state.platform.platform.id,
  campaign: state.campaign.campaign,
  lastCampaigns: getLastCampaigns(state),
  locale: state.session.language,
  userId: state.session.userId,
  platform: state.platform.platform,
  isUserLoggedIn: state.session.userTypeId === 2,
  event: get(state, "Event.event", null),
  isBillingConfigured: get(state, 'organization.isBillingConfigured'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCampaign,
  clearCampaign,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(CampaignPublicPage);

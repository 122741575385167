import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import FundkyTooltip from 'Parts/ui/components/FundkyTooltip.jsx';

import CardCvvField_en from './locales/CardCvvField_en.json';
import CardCvvField_fr from './locales/CardCvvField_fr.json';

import './CardCvvField.less';

class CardCvvField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CardCvvField', CardCvvField_en);
    i18next.addResourceBundle('fr', 'CardCvvField', CardCvvField_fr);
  }

  render() {
    const { t, form, className } = this.props;
    const { getFieldDecorator } = form;

    const input = <Input id="CardCvv" placeholder={t('placeholder')} />;
    const fieldId = 'cardCvv';
    const fieldOptions = {
      rules: [
        {
          required: true,
          message: t('require')
        },
        {
          pattern: /^\d{3,4}$/,
          message: t('invalid')
        }
      ]
    };

    return (
      <Form.Item className={className} hasFeedback>
        {getFieldDecorator(fieldId, fieldOptions)(input)}
        <FundkyTooltip title={t('cvv-tooltip')} className="CardCvvTooltip" />
      </Form.Item>
    );
  }
}

CardCvvField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired
};

export default translate('CardCvvField')(CardCvvField);

import {
  bindActionCreators,
  compose
} from "redux";
import {
  Form
} from 'antd';
import {
  connect
} from "react-redux";

import {
  updateTeam
} from "../../../../team/teamActions";
import {
  checkAuthorization
} from 'Parts/common/authorizationUtils';

import CampaignsTeamsPageForm from './CampaignsTeamsPageForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  languages: state.platform.languages,
  team: state.team.team,
  platformAlias: state.platform.platform.alias,
  locale: state.session.language,
  participants: state.participant.participants,
  campaign: state.campaign.campaign,
  accentColors: state.platform.platform.settings.color,
  userId: state.session.userId,
  userRoleId: state.session.user.roleId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateTeam,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(CampaignsTeamsPageForm);

import * as geoJSON from './geo.json';

export const FETCH_COUNTRY_LIST_START = 'FETCH_COUNTRY_LIST_START';
export const FETCH_COUNTRY_LIST_SUCCESS = 'FETCH_COUNTRY_LIST_SUCCESS';
export const FETCH_COUNTRY_LIST_ERROR = 'FETCH_COUNTRY_LIST_ERROR';

export const FETCH_PROVINCE_LIST_START = 'FETCH_PROVINCE_LIST_START';
export const FETCH_PROVINCE_LIST_SUCCESS = 'FETCH_PROVINCE_LIST_SUCCESS';
export const FETCH_PROVINCE_LIST_ERROR = 'FETCH_PROVINCE_LIST_ERROR';

export function fetchCountries() {
  return (dispatch, getState) => {
    if (getState().geo.countries.length) {
      // Countries are loaded once and for all
      return;
    }

    dispatch({ type: FETCH_COUNTRY_LIST_START });

    const geo = geoJSON.geo;
    const countries = geo.filter((country) => {
      return country.type === 'country' ? true : false;
    })
    .map((country) => {
      return {
        id: country.country_code,
        name_en: country.en,
        name_fr: country.fr
      };
    });

    if (countries.length) {
      return dispatch({type: FETCH_COUNTRY_LIST_SUCCESS, payload: countries})
    } else {
      return dispatch({type: FETCH_COUNTRY_LIST_ERROR, error})
    }
  }
}


export function fetchProvinces() {
  return (dispatch, getState) => {
    if (getState().geo.provinces.length) {
      // Provinces are loaded once and for all
      return;
    }

    dispatch({ type: FETCH_PROVINCE_LIST_START });

    const geo = geoJSON.geo;
    const list = geo.filter((province) => {
      return province.type === 'state' ? true : false;
    })
    .map((province) => {
      return province;
    });

    let obj = {};
    for (let provinceKey in list) {
      let { country_code, fr, en } = list[provinceKey];
      if (!obj[country_code]) {
        obj[country_code] = {
          fr: [],
          en: []
        };
      }
      obj[country_code].fr.push(fr);
      obj[country_code].en.push(en);
    }
    const provinces = obj;

    if (Object.getOwnPropertyNames(obj).length > 0) {
      return dispatch({type: FETCH_PROVINCE_LIST_SUCCESS, payload: provinces})
    } else {
      return dispatch({type: FETCH_PROVINCE_LIST_ERROR, payload: "Provinces not found."})
    }
  }
}

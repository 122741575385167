import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import campaignThirdPartyFieldEN from './locales/CampaignThirdPartyField_en.json';
import campaignThirdPartyFieldFR from './locales/CampaignThirdPartyField_fr.json';
import { Form, Switch } from 'antd';

class CampaignThirdPartyField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CampaignThirdPartyField', campaignThirdPartyFieldEN);
    i18next.addResourceBundle('fr', 'CampaignThirdPartyField', campaignThirdPartyFieldFR);
  }

  render() {
    const { t, form, fieldId, className, checked } = this.props;
    const { getFieldDecorator } = form;
    const Input = <Switch />;
    const fieldOptions = {
      valuePropName: 'checked',
      initialValue: checked
    };

    return (
      <span className="wrap-field">
        <Form.Item
          className={className}
          label={(
              <span>
                {t('Field Label')}
              </span>
            )}
        >
          {getFieldDecorator(fieldId, fieldOptions)(Input)}
        </Form.Item>
        <p><em>{t('Field Description')}</em></p>
      </span>
    );
  }

}

CampaignThirdPartyField.defaultProps = {
  fieldId: 'thirdPartyCampaigns',
  checked: false,
  className: 'CampaignThirdParty__Field'
};

CampaignThirdPartyField.propTypes = {
  fieldId: PropTypes.string,
  form: PropTypes.object.isRequired,
  checked: PropTypes.bool
};

export default translate('CampaignThirdPartyField')(CampaignThirdPartyField);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";

// import dataFetcher from "../../parts/chore/dataFetcher";
import { getLastCampaigns } from "../../campaign/campaignSelectors";
import { fetchPublicInformation } from '../../common/publicActions';

import TeamPublicPage from './TeamPublicPage';

const mapStateToProps = state => ({
  team: state.team.team,
  teams: state.team.teams,
  campaign: state.campaign.campaign,
  lastCampaigns: getLastCampaigns(state),
  locale: state.session.language,
  userId: state.session.userId,
  platform: state.platform.platform,
  isBillingConfigured: get(state, 'organization.isBillingConfigured'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPublicInformation
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  //dataFetcher
)(TeamPublicPage);

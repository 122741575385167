import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Tabs } from 'antd';
import { get } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Can from 'Parts/session/components/CanContainer';
import { getDefaultLanguageKey } from 'Parts/common/langUtils';
import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, actionColumn } from 'Parts/common/columnUtils';
import SurveysListPage_en from './locales/SurveysListPage_en.json';
import SurveysListPage_fr from './locales/SurveysListPage_fr.json';
import moment from 'moment';
import './SurveysListPage.less';

class SurveysListPage extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'SurveysListPage', SurveysListPage_en);
    i18next.addResourceBundle('fr', 'SurveysListPage', SurveysListPage_fr);

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: 0,
        current: 1,
        pageSize: 10,
      },
      disableSubmit: true,
      contentLang: getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale,
      surveysInformation: this.props.surveysInformation,
      filters: {}
    };
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.surveysInformation !== prevProps.surveysInformation) {
      this.setState({ surveysInformation: this.props.surveysInformation });
    }
    if (this.props.pagination !== prevProps.pagination) {
      const pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10,
      };
      this.setState({ pager });
    }
    if (this.props.locale !== prevProps.locale) {
      const pagination = {
        pageResults: this.state.pager.pageSize,
        pageNumber: this.state.pager.current,
      };
      await this.props.fetchSurveysInformations(
        this.props.locale,
        this.props.user.id,
        this.props.platform.id,
        this.state.filters.search,
        this.state.filters,
        pagination,
        null,
        true,
      );
    }
  }

  componentDidMount = async () => {
    const pagination = {
      pageResults: this.state.pager.pageSize,
      pageNumber: this.state.pager.current,
    };
    await this.props.fetchSurveysInformations(
      this.props.locale,
      this.props.user.id,
      this.props.platform.id,
      this.state.filters.search,
      this.state.filters,
      pagination,
      null,
      true,
    );
    // await this.props.fetchSurveysInformations(this.props.user.id);
  };

  componentWillUnmount() {
    // TODO
  }

  handlePaginationChange = (pager) => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current,
    };
    this.props.fetchSurveysInformations(
      this.props.locale,
      this.props.user.id,
      this.props.platform.id,
      this.state.filters.search,
      this.state.filters,
      pagination,
      null,
      true,
    );
  };

  handleSearch = (filters) => {
    const pagination = {
      pageResults: this.state.pager.pageSize,
    };
    this.props.fetchSurveysInformations(
      this.props.locale,
      this.props.user.id,
      this.props.platform.id,
      filters.search,
      filters,
      pagination,
      null,
      true
    );
  };

  render() {
    const { props, state, handlePaginationChange } = this,
      { t, locale, pagination } = props,
      { pager } = state;
    const { surveysInformation } = this.state;
    return (
      <Layout className="SurveysListPage">
        <>
          <Helmet>
            <title>{t('Surveys')}</title>
          </Helmet>
          <SearchBar
            createButton={
              <Link key={1} to={`/${locale}/d/survey/information`} className="ant-btn ant-btn-primary">
                <span>{t('Create_survey')}</span> <FontAwesomeIcon icon={['fas', 'plus']} />
              </Link>
            }
            title={t('Surveys')}
            placeholder={t('Search')}
            onSearch={this.handleSearch}
          />
          <Can
            rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
            redirect={`/${locale}/d`}
          >
            <div className="AdminList">
              <PaginationTop
                totalLabel={() => t('Surveys', { count: pagination.totalResults })}
                pager={pager}
                onChange={handlePaginationChange}
              />
              <ResultTable
                dataSource={surveysInformation || []}
                pagination={pager}
                onChange={handlePaginationChange}
                showHeader={true}
                columns={[
                  simpleColumn(t('Survey_Name'), 'name', null, true),
                  {
                    title: t('Status'),
                    dataIndex: 'accessibility',
                    key: 'accessibility',
                    render: (accessibility) => {
                      if (accessibility === 1) {
                        return t('Published');
                      }else if(accessibility === 2){
                        return t('Archive');
                      }else{
                        return t('Draft')
                      }
                    },
                  },
                  {
                    title: t('Created_on'),
                    dataIndex: 'created_at',
                    key: 'created_at',
                    render: (created_at) => {
                      const isoDateString = created_at;
                      const formattedDate = moment(isoDateString).format('MMMM D, YYYY');
                      return formattedDate;
                    },
                  },
                  actionColumn(
                    t('Actions'),
                    null,
                    () => {},
                    (surveysInformation) => {
                      return (
                        <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Link
                            className="ant-btn ant-btn-circle"
                            to={`/${locale}/d/survey/${surveysInformation.id}/information`}
                          >
                            <FontAwesomeIcon icon={['fal', 'edit']} />
                          </Link>
                          <Link
                            className="ant-btn ant-btn-circle"
                            onClick={() => {
                              this.props.deleteSurveyInformations(surveysInformation.id ,this.props.user.id, this.props.platform.id, this.props.locale, {
                                pageResults: this.state.pager.pageSize,
                                pageNumber: this.state.pager.current,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                          </Link>
                        </span>
                      );
                    }
                  ),
                ]}
              />
            </div>
          </Can>
        </>
      </Layout>
    );
  }
}

SurveysListPage.defaultProps = {
  surveys: [],
  pagination: {
    totalResults: 0,
  },
};

export default translate('SurveysListPage')(SurveysListPage);

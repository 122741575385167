import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { fetchCampaign } from '../../../campaign/campaignActions';
import { fetchTeam } from '../../../team/teamActions';
import { fetchParticipant } from '../../../participant/participantActions';
import { fetchMemberById } from '../../../member/memberActions';
import { updateTransaction } from '../../../donation/donationActions';
import {
  searchCampaigns,
  searchParticipants,
  searchTeamsInCampaign,
  searchParticipantsInCampaign,
  searchParticipantsInTeam,
} from '../../../search/searchActions';

import UpdateRecipientModalContent from './UpdateRecipientModalContent';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  participant: state.participant.participant,
  participants: state.search.participants,
  team: state.team.team,
  teams: state.search.teams,
  campaign: state.campaign.campaign,
  campaigns: state.search.campaigns,
  locale: state.session.language,
  transaction: state.donation.transaction,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchCampaigns,
  searchParticipants,
  searchTeamsInCampaign,
  searchParticipantsInCampaign,
  searchParticipantsInTeam,
  fetchCampaign,
  fetchTeam,
  fetchParticipant,
  fetchMemberById,
  updateTransaction,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }),
  Form.create(),
)(UpdateRecipientModalContent);

import { compose } from "redux";
import { connect } from "react-redux";
import { getLastDonations } from "../donation/donationSelectors";

import PublicDonationPanel from './PublicDonationPanel';

const mapStateToProps = (state, ownProps) => ({
  donations: getLastDonations(state, ownProps),
  campaign: state.campaign.campaign,
  team: state.team.team,
  participant: state.participant.participant,
  locale: state.session.language,
  isBillingConfigured: state.organization.isBillingConfigured
});

export default compose(
  connect(mapStateToProps),
)(PublicDonationPanel);

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HelpText from './HelpText';
import AvatarThumbnail from './AvatarThumbnailContainer';

import './PublicTitleDropdown.less';

// We need a true React component to "absorb" the props injected by Ant Dropdown component
// React will print a warning if we declare HTML tag directly
const DummyMenu = props => props.children;

class PublicTitleDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = { visible: false };
  }

  handleEntityLinkClick = () => {
    // When clicking on a entity link, we stay on the same route so React reuse that component
    // and the menu stays open.
    // ---> We need to close the menu manually
    this.setState({ visible: false });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  render() {
    const entityLinks = this.props.entities.map((entity, index) => (
      <Link
        key={entity.id}
        to={this.props.getEntityLinkUrl(entity)}
        onClick={this.handleEntityLinkClick}
      >
        <AvatarThumbnail
          entity={entity}
          primaryContent={
            entity.name ||
            entity.member.name
          }
          rank={index + 1}
        />
      </Link>
    ));

    const menu = (
      <DummyMenu>
        <div
          className="PublicTitleDropdown__Menu"
          style={{
            '--primary-color': this.props.colors && this.props.colors.primary || '',
            '--link-color': this.props.colors && this.props.colors.secondary || ''
          }}
        >
          <HelpText>{this.props.helpLabel}</HelpText>
          <div className="PublicTitleDropdown__EntityLinks">{entityLinks}</div>
          {this.props.additionalLinks ? (
            <div className="PublicTitleDropdown__AdditionalLinks">{this.props.additionalLinks}</div>
          ) : null}
        </div>
      </DummyMenu>
    );

    const getPopupContainer = this.props.sticky
      ? () => document.getElementById('stickyArea')
      : () => document.body;
    return (
      <Dropdown
        className={this.props.className}
        overlay={menu}
        onVisibleChange={this.handleVisibleChange}
        visible={this.state.visible}
        getPopupContainer={trigger => trigger.parentNode}
        trigger={['click']}
        disabled={this.props.entities.length || this.props.additionalLinks ? false : true}
      >
        <div className="PublicTitleDropdown">
          <h1>{this.props.title}</h1>
          {this.props.entities.length || this.props.additionalLinks ? (
            <FontAwesomeIcon icon={['fas', 'caret-down']} size="lg" />
          ) : null}
        </div>
      </Dropdown>
    );
  }
}

PublicTitleDropdown.defaultProps = {
  className: null,
  additionalLinks: null
};

PublicTitleDropdown.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  entities: PropTypes.array.isRequired,
  getEntityLinkUrl: PropTypes.func.isRequired,
  additionalLinks: PropTypes.node,
  helpLabel: PropTypes.string.isRequired
};

export default PublicTitleDropdown;

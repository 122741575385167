import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import dataFetcher from 'Parts/chore/dataFetcher';
import { clearTransaction } from '../../donation/donationActions';

import MyContributionsDonationsPage from './MyContributionsDonationsPage';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  transaction: state.donation.transaction,
  locale: state.session.language,
  userId: state.session.userId,
  fetchTransactionFailed: state.donation.fetchTransactionFailed
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearTransaction
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(MyContributionsDonationsPage);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';

import campaignsTransactionsListPageEN from '../locales/CampaignsTransactionsListPage_en.json';
import campaignsTransactionsListPageFR from '../locales/CampaignsTransactionsListPage_fr.json';

class CampaignsTransactionsListDonationTab extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle(
      'en',
      'CampaignsTransactionsListPage',
      campaignsTransactionsListPageEN
    );
    i18next.addResourceBundle(
      'fr',
      'CampaignsTransactionsListPage',
      campaignsTransactionsListPageFR
    );
  }


  handleViewHandler = transaction => {
    let currentUrl = this.props.location.pathname;
    let donationUrl = currentUrl.replace('list/donations', transaction.id);
    return this.props.history.push(donationUrl);
  };

  render() {
    const {
      t,
      match,
      transactions,
      locale,
      pagination,
      pager,
      handlePaginationChange
    } = this.props;
    //const { pager } = this.state;

    return (
        <div className="AdminList">
        <PaginationTop
            totalLabel={total => t('donationsTotal', { count: pagination.totalResults })}
            pager={pager}
            onChange={handlePaginationChange}
        />
        <ResultTable
            dataSource={transactions || []}
            pagination={pager}
            onChange={handlePaginationChange}
            showHeader={true}
            columns={[
            simpleColumn(t('Transaction ID'), 'referenceId', null, true),
            simpleColumn(t('Donor name'), 'information', info => {
                return get(info, 'organization.name') ||
                get(info, 'firstName') && get(info, 'lastName')
                    ? info.firstName + " " + info.lastName
                    : '';
            }, false),
            simpleColumn(t('Email address'), 'information.email', null, false),
            simpleColumn(t('Recipient'), 'scope.name', null, true),
            simpleColumn(
                t('Contribution amount'),
                'amount',
                text => moneyFormat(text, locale),
                true
            ),
            simpleColumn(t('Status'), 'status', status => t(status), true),
            dateColumn(t('Created on'), 'addedOn', null, null, false, locale),
            actionColumn(t('Edit'), ['fal', 'edit'], this.handleViewHandler)
            ]}
        />
        </div>
    );
  }
}

CampaignsTransactionsListDonationTab.defaultProps = {
  donations: [],
  pagination: {
    totalResults: 0
  }
};

CampaignsTransactionsListDonationTab.propTypes = {
  searchTransactionsInCampaign: PropTypes.func.isRequired
};

export default translate('CampaignsTransactionsListPage')(CampaignsTransactionsListDonationTab);

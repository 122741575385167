import React from 'react';
import PropTypes from 'prop-types';
import { Form, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import EmailField from '../../ui/components/fields/EmailField';
import SubmitButton from '../../common/SubmitButton';

import RecoveryForm_en from './locales/RecoveryForm_en.json';
import RecoveryForm_fr from './locales/RecoveryForm_fr.json';

import './RecoveryForm.less';

class RecoveryForm extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'RecoveryForm', RecoveryForm_en);
    i18next.addResourceBundle('fr', 'RecoveryForm', RecoveryForm_fr);

    this.handleSwitchToRegister = this.handleSwitchToRegister.bind(this);
    this.handleSwitchToLogin = this.handleSwitchToLogin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSwitchToLogin(event) {
    event.preventDefault();
    this.props.openLoginModal();
  }

  handleSwitchToRegister(event) {
    event.preventDefault();
    this.props.openRegisterModal();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.recover(values.recovery_email);
      }
    });
  }

  render() {
    const { t, className } = this.props;
    const info = this.props.isRecoverySuccess ? (
      <Alert
        message={t('info.title')}
        description={(
          <span>
            {t('info.message')}
          </span>
        )}
        type="info"
        showIcon
      />
    ) : null;

    const label = (
      this.props.isRecoverySuccess ? (
        <span>
          <FontAwesomeIcon icon={["fas", "check"]} />
          {t('done')}
        </span>
      ) : (
        <span>
          {t('action')}
        </span>
      )
    );

    return (
      <Form
        className={`ConnectionForm ConnectionForm__Recovery ${className}`}
        onSubmit={this.handleSubmit}
      >
        {info}
        <h1>{t('title')}</h1>
        <EmailField
          className="ConnectionForm__Field ConnectionForm__Recovery--emailField"
          form={this.props.form}
          fieldId={'recovery_email'}
          label={false}
          placeholder={true}
        />
        <p className="ConnectionForm__ForgotLink">
          <a href="/" onClick={this.handleSwitchToLogin}>{t('log-in')}</a>
        </p>
        <SubmitButton className="ConnectionForm__Field" loading={this.props.isRecoveryInProgress} disabled={this.props.isRecoverySuccess}>
          {label}
        </SubmitButton>

        <p className="ConnectionForm__EndNote">
          {t('no-account')}<br/>
          <a href="/" onClick={this.handleSwitchToRegister}>{t('create-account')}</a>
        </p>
      </Form>
    );
  }

}

RecoveryForm.defaultProps = {
  className: '',
}

RecoveryForm.propTypes = {
  className: PropTypes.string,
  openRegisterModal: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  isRecoveryInProgress: PropTypes.bool.isRequired,
  recover: PropTypes.func.isRequired,
  connectionModalForced: PropTypes.bool.isRequired,
  connectionModalOrigin: PropTypes.string,
  platform: PropTypes.object
};

export default translate('RecoveryForm')(RecoveryForm);

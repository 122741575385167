import { connect } from "react-redux";

import CountryField from './CountryField';

const mapStateToProps = state => ({
  countries: state.geo.countries,
  locale: state.session.language
});

export default connect(mapStateToProps)(CountryField);

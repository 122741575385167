import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import OptInFundkyField_en from './locales/OptInFundkyField_en.json';
import OptInFundkyField_fr from './locales/OptInFundkyField_fr.json';

class OptInFundkyField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'OptInFundkyField', OptInFundkyField_en);
    i18next.addResourceBundle('fr', 'OptInFundkyField', OptInFundkyField_fr);
  }

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;

    const input = <Checkbox>{t('label')}</Checkbox>;
    const fieldId = 'fundkyOptin';
    const fieldOptions = {
      initialValue: false,
      valuePropName: 'checked'
    };

    return (
      <Form.Item className={this.props.className}>
        {getFieldDecorator(fieldId)(input, fieldOptions)}
      </Form.Item>
    );
  }

}

OptInFundkyField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('OptInFundkyField')(OptInFundkyField);

import { notification } from 'antd';
import { replace } from 'react-router-redux';
import qs from 'qs';
import { detect } from "detect-browser";
import i18next from 'i18next';
import isBrowser from 'is-in-browser';
import history from '../chore/history';
import { get } from 'lodash';

import {
  requestApiPost,
  requestApiGet,
  requestApiPut,
  getErrorMessage
} from '../common/requestUtils';
import { getSessionIp } from './sessionUtils';
import {
  readSessionId,
  readSessionDate,
  clearSessionId,
  clearSessionDate,
  clearResentActivationEmail
} from "../common/cookieUtils";
import {
  getLanguageNameByKey,
  getLanguageIdByKey,
  isLanguageSupportedByPlatform
} from '../common/langUtils';
import { getLocale } from './sessionSelectors';
import { Timer } from '../common/dateUtils';

export const AUTH_REGISTER_START = 'AUTH_REGISTER_START';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';

export const AUTH_RECOVERY_START = 'AUTH_RECOVERY_START';
export const AUTH_RECOVERY_SUCCESS = 'AUTH_RECOVERY_SUCCESS';
export const AUTH_RECOVERY_ERROR = 'AUTH_RECOVERY_ERROR';

export const AUTH_RESET_START = 'AUTH_RESET_START';
export const AUTH_RESET_SUCCESS = 'AUTH_RESET_SUCCESS';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';

export const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_LOGIN_CANCEL = 'AUTH_LOGIN_CANCEL';

export const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR';

export const AUTH_CONNECT_START = 'AUTH_CONNECT_START';
export const AUTH_CONNECT_SUCCESS = 'AUTH_CONNECT_SUCCESS';
export const AUTH_CONNECT_ERROR = 'AUTH_CONNECT_ERROR';

export const AUTH_TWITCH_CONNECT_START = 'AUTH_TWITCH_CONNECT_START';
export const AUTH_TWITCH_CONNECT_SUCCESS = 'AUTH_TWITCH_CONNECT_SUCCESS';
export const AUTH_TWITCH_CONNECT_ERROR = 'AUTH_TWITCH_CONNECT_ERROR';

export const AUTH_TWITCH_LINK_START = 'AUTH_TWITCH_LINK_START';
export const AUTH_TWITCH_LINK_SUCCESS = 'AUTH_TWITCH_LINK_SUCCESS';
export const AUTH_TWITCH_LINK_ERROR = 'AUTH_TWITCH_LINK_ERROR';

export const AUTH_CHECK_RECOVERY_KEY_START = 'AUTH_CHECK_RECOVERY_KEY_START';
export const AUTH_CHECK_RECOVERY_KEY_SUCCESS = 'AUTH_CHECK_RECOVERY_KEY_SUCCESS';
export const AUTH_CHECK_RECOVERY_KEY_ERROR = 'AUTH_CHECK_RECOVERY_KEY_ERROR';

export const SEND_REGISTRATION_EMAIL_START = 'SEND_REGISTRATION_EMAIL_START';
export const SEND_REGISTRATION_EMAIL_SUCCESS = 'SEND_REGISTRATION_EMAIL_SUCCESS';
export const SEND_REGISTRATION_EMAIL_ERROR = 'SEND_REGISTRATION_EMAIL_ERROR';

export const AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_START = 'AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_START';
export const AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_SUCCESS = 'AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_SUCCESS';
export const AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_ERROR = 'AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_ERROR';

export const SET_LOCALE_SESSION_LANGUAGE = 'SET_LOCALE_SESSION_LANGUAGE';
export const SET_SESSION_LANGUAGE_START = 'SET_SESSION_LANGUAGE_START';
export const SET_SESSION_LANGUAGE_SUCCESS = 'SET_SESSION_LANGUAGE_SUCCESS';
export const SET_SESSION_LANGUAGE_ERROR = 'SET_SESSION_LANGUAGE_ERROR';

export const UPDATE_SESSION_STATE_START = 'UPDATE_SESSION_STATE_START';
export const UPDATE_SESSION_STATE_SUCCESS = 'UPDATE_SESSION_STATE_SUCCESS';
export const UPDATE_SESSION_STATE_ERROR = 'UPDATE_SESSION_STATE_ERROR';

export const FETCH_MEMBER_PERMISSIONS_START = 'FETCH_MEMBER_PERMISSIONS_START';
export const FETCH_MEMBER_PERMISSIONS_SUCCESS = 'FETCH_MEMBER_PERMISSIONS_SUCCESS';
export const FETCH_MEMBER_PERMISSIONS_ERROR = 'FETCH_MEMBER_PERMISSIONS_ERROR';

export const FETCH_MEMBER_ORGANIZATION_PERMISSIONS_START = 'FETCH_MEMBER_ORGANIZATION_PERMISSIONS_START';
export const FETCH_MEMBER_ORGANIZATION_PERMISSIONS_SUCCESS = 'FETCH_MEMBER_ORGANIZATION_PERMISSIONS_SUCCESS';
export const FETCH_MEMBER_ORGANIZATION_PERMISSIONS_ERROR = 'FETCH_MEMBER_ORGANIZATION_PERMISSIONS_ERROR';

export const FETCH_MEMBER_PLATFORM_PERMISSIONS_START = 'FETCH_MEMBER_PLATFORM_PERMISSIONS_START';
export const FETCH_MEMBER_PLATFORM_PERMISSIONS_SUCCESS = 'FETCH_MEMBER_PLATFORM_PERMISSIONS_SUCCESS';
export const FETCH_MEMBER_PLATFORM_PERMISSIONS_ERROR = 'FETCH_MEMBER_PLATFORM_PERMISSIONS_ERROR';

export const FETCH_MEMBER_CAMPAIGN_PERMISSIONS_START = 'FETCH_MEMBER_CAMPAIGN_PERMISSIONS_START';
export const FETCH_MEMBER_CAMPAIGN_PERMISSIONS_SUCCESS = 'FETCH_MEMBER_CAMPAIGN_PERMISSIONS_SUCCESS';
export const FETCH_MEMBER_CAMPAIGN_PERMISSIONS_ERROR = 'FETCH_MEMBER_CAMPAIGN_PERMISSIONS_ERROR';

export const FETCH_MEMBER_TEAM_PERMISSIONS_START = 'FETCH_MEMBER_TEAM_PERMISSIONS_START';
export const FETCH_MEMBER_TEAM_PERMISSIONS_SUCCESS = 'FETCH_MEMBER_TEAM_PERMISSIONS_SUCCESS';
export const FETCH_MEMBER_TEAM_PERMISSIONS_ERROR = 'FETCH_MEMBER_TEAM_PERMISSIONS_ERROR';

export const CLEAR_MEMBER_PERMISSIONS = 'CLEAR_MEMBER_PERMISSIONS';
export const CLEAR_MEMBER_ADMIN_PERMISSIONS = 'CLEAR_MEMBER_ADMIN_PERMISSIONS';

export const FETCH_MEMBER_ROLES_START = 'FETCH_MEMBER_ROLES_START';
export const FETCH_MEMBER_ROLES_SUCCESS = 'FETCH_MEMBER_ROLES_SUCCESS';
export const FETCH_MEMBER_ROLES_ERROR = 'FETCH_MEMBER_ROLES_ERROR';

export const FETCH_MEMBER_ORGANIZATION_ROLES_START = 'FETCH_MEMBER_ORGANIZATION_ROLES_START';
export const FETCH_MEMBER_ORGANIZATION_ROLES_SUCCESS = 'FETCH_MEMBER_ORGANIZATION_ROLES_SUCCESS';
export const FETCH_MEMBER_ORGANIZATION_ROLES_ERROR = 'FETCH_MEMBER_ORGANIZATION_ROLES_ERROR';

export const FETCH_MEMBER_PLATFORM_ROLES_START = 'FETCH_MEMBER_PLATFORM_ROLES_START';
export const FETCH_MEMBER_PLATFORM_ROLES_SUCCESS = 'FETCH_MEMBER_PLATFORM_ROLES_SUCCESS';
export const FETCH_MEMBER_PLATFORM_ROLES_ERROR = 'FETCH_MEMBER_PLATFORM_ROLES_ERROR';

export const FETCH_MEMBER_CAMPAIGN_ROLES_START = 'FETCH_MEMBER_CAMPAIGN_ROLES_START';
export const FETCH_MEMBER_CAMPAIGN_ROLES_SUCCESS = 'FETCH_MEMBER_CAMPAIGN_ROLES_SUCCESS';
export const FETCH_MEMBER_CAMPAIGN_ROLES_ERROR = 'FETCH_MEMBER_CAMPAIGN_ROLES_ERROR';

export const FETCH_MEMBER_TEAM_ROLES_START = 'FETCH_MEMBER_TEAM_ROLES_START';
export const FETCH_MEMBER_TEAM_ROLES_SUCCESS = 'FETCH_MEMBER_TEAM_ROLES_SUCCESS';
export const FETCH_MEMBER_TEAM_ROLES_ERROR = 'FETCH_MEMBER_TEAM_ROLES_ERROR';

export const CLEAR_MEMBER_ROLES = 'CLEAR_MEMBER_ROLES';

export const UPDATE_LOCATION_HISTORY = 'UPDATE_LOCATION_HISTORY';

export const UPDATE_SESSION_TIMEOUT_START = 'UPDATE_SESSION_TIMEOUT_START';
export const UPDATE_SESSION_TIMEOUT_END = 'UPDATE_SESSION_TIMEOUT_END';
export const UPDATE_SESSION_TIMEOUT = 'UPDATE_SESSION_TIMEOUT';
export const CLEAR_SESSION_TIMEOUT = 'CLEAR_SESSION_TIMEOUT';
export const SHOW_SESSION_TIMEOUT_WARNING = 'SHOW_SESSION_TIMEOUT_WARNING';
export const HIDE_SESSION_TIMEOUT_WARNING = 'HIDE_SESSION_TIMEOUT_WARNING';
export const SHOW_SESSION_TIMEOUT_MESSAGE = 'SHOW_SESSION_TIMEOUT_MESSAGE';
export const HIDE_SESSION_TIMEOUT_MESSAGE = 'HIDE_SESSION_TIMEOUT_MESSAGE';

export const SAVE_IP = 'SAVE_IP';
export const CLEAR_IP = 'CLEAR_IP';
export const SAVE_ID = 'SAVE_ID';
export const DETECT_BROWSER = 'DETECT_BROWSER';

export const IS_CAMPAIGN_OWNER_START = 'IS_CAMPAIGN_OWNER_START';
export const IS_CAMPAIGN_OWNER_SUCCESS = 'IS_CAMPAIGN_OWNER_SUCCESS';
export const IS_CAMPAIGN_OWNER_ERROR = 'IS_CAMPAIGN_OWNER_ERROR';

export function detectBrowser() {
  const browser = detect() || {};

  return {
    type: DETECT_BROWSER,
    payload: {
      name: browser.name,
      version: browser.version // Note: also contains an "os" key, unsupported by the api
    }
  };
}

export function connect(kickedOut = false) {
  return (dispatch, getState) => {
    dispatch({ type: AUTH_CONNECT_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      '/session',
      null,
      headers
    )
      .then(session => {
        const state = getState();
        const locale = getLocale(state);
        const languageId = getLanguageIdByKey(locale);
        const languageName = getLanguageNameByKey(locale, true, true);
        const sessionLanguageId = session.languageId;
        const userId = session.userId;

        if(languageId !== sessionLanguageId) {
          if (state.platform && state.platform.languages && isLanguageSupportedByPlatform(languageId, state.platform.languages)) {
            return dispatch(setSessionLanguage(locale))
              .then(() => {
                session.language = languageName;
                session.languageId = languageId;
                if(session.userTypeId == 2) {
                  dispatch(fetchMemberPermissions(userId));
                } else
                if (kickedOut) {
                  dispatch(clearSessionTimeout());
                  dispatch({ type: UPDATE_SESSION_TIMEOUT_END });
                  dispatch({ type: SHOW_SESSION_TIMEOUT_MESSAGE })
                }
                return dispatch({ type: AUTH_CONNECT_SUCCESS, payload: session});
              })
          }
        } else {
          if(session.userTypeId == 2) {
            dispatch(fetchMemberPermissions(userId));
          } else
          if (kickedOut) {
            dispatch(clearSessionTimeout());
            dispatch({ type: UPDATE_SESSION_TIMEOUT_END });
            dispatch({ type: SHOW_SESSION_TIMEOUT_MESSAGE })
          }
          return dispatch({ type: AUTH_CONNECT_SUCCESS, payload: session});
        }
      })
      .catch((err) => {
        // Error when fetching the user: log the error but continue normally
        // The session may be expired
        console.warn(err);
        return dispatch({ type: AUTH_CONNECT_ERROR, error: err });
      });
  }
}

export function twitchConnect(code, scope, organizationId = null, platformId = null) {
  return (dispatch, getState) => {
    dispatch({ type: AUTH_TWITCH_CONNECT_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    const params = {
      code: code,
      scope: [scope],
    };

    return requestApiPost(
      '/session/login/twitch',
      params,
      headers
    )
      .then(user => {
        if (user.id) {
          dispatch(fetchMemberRoles(user.id));
          if (organizationId) {
            dispatch(fetchMemberOrganizationRoles(organizationId, user.id));
          }
          if (platformId) {
            dispatch(fetchMemberPlatformRoles(platformId, user.id));
          }

          dispatch(updateSessionState());

          return dispatch({
            type: AUTH_TWITCH_CONNECT_SUCCESS,
            payload: user
          });
        } else {
          return dispatch({
            type: "AUTH_TWITCH_CHECK_EMAIL",
            payload: user
          });
        }
      })
      .catch((err) => {
        console.error(err);

        return dispatch({
          type: AUTH_TWITCH_CONNECT_ERROR,
          error: err
        })
      });
  }
};

export function twitchLink(token, organizationId = null, platformId = null) {
  return (dispatch, getState) => {
    dispatch({ type: AUTH_TWITCH_LINK_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    const params = {
      token: token
    };

    return requestApiPost(
      '/members/connect/twitch/link',
      params,
      headers
    )
      .then(user => {
        if (user.id) {
          dispatch(fetchMemberRoles(user.id));
          if (organizationId) {
            dispatch(fetchMemberOrganizationRoles(organizationId, user.id));
          }
          if (platformId) {
            dispatch(fetchMemberPlatformRoles(platformId, user.id));
          }

          dispatch(updateSessionState());

          return dispatch({
            type: AUTH_TWITCH_LINK_SUCCESS,
            payload: user
          });
        } else {
          return dispatch({
            type: "AUTH_TWITCH_LINK_ERROR",
            payload: user
          });
        }
      })
      .catch((err) => {
        console.error(err);

        return dispatch({
          type: AUTH_TWITCH_LINK_ERROR,
          error: err
        })
      });
  }
};

export function updateSessionState() {
  return (dispatch, getState) => {
    dispatch({ type: AUTH_CONNECT_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      '/session',
      null,
      headers
    )
      .then(user => {
        const
          state = getState(),
          platformId = state.platform.platform.id,
          locale = getLocale(state),
          languageId = getLanguageIdByKey(locale),
          languageName = getLanguageNameByKey(locale, true, true),
          sessionLanguageId = user.languageId,
          userId = user.id;

        if (languageId !== sessionLanguageId) {
          dispatch(
            Promise.all([
              setSessionLanguage(locale)
            ]).then(() => {
              user.language = languageName;
              user.languageId = languageId;
              dispatch({
                type: AUTH_CONNECT_SUCCESS,
                payload: user
              });
              dispatch(fetchMemberPermissions(userId));
              dispatch(fetchMemberRoles(userId));
              dispatch(fetchMemberPlatformRoles(platformId, userId));
              dispatch(fetchMemberPlatformPermissions(platformId, userId));
            })
          );
        } else {
          dispatch({
            type: AUTH_CONNECT_SUCCESS,
            payload: user
          });
          dispatch(fetchMemberPermissions(userId));
          dispatch(fetchMemberRoles(userId));
          dispatch(fetchMemberPlatformRoles(platformId, userId));
          dispatch(fetchMemberPlatformPermissions(platformId, userId));
        }
      })
      .catch((err) => {
        // Error when fetching the user: log the error but continue normally
        // The session may be expired
        console.warn(err);
        dispatch({ type: UPDATE_SESSION_STATE_ERROR, error: err });
      });
  }
}

export function register(formValues, platformId = undefined) {
  return dispatch => {
    dispatch({ type: AUTH_REGISTER_START });

    const body = {
      reCAPTCHA: formValues.reCAPTCHA,
      platformId: platformId,
      email: formValues.register_email,
      password: formValues.password,
      firstName: formValues.firstname,
      lastName: formValues.lastname,
      terms: formValues.acceptedTermsConditions,
      newsletter: {
        general: (formValues.hasOwnProperty("fundkyOptin") ?
          (formValues.fundkyOptin ?
            formValues.fundkyOptin :
            false) :
          undefined),
        platform: (formValues.hasOwnProperty("platformOptin") ?
          (formValues.platformOptin ?
            formValues.platformOptin :
            false) :
          undefined)
      }
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      '/members',
      body,
      headers
    )
      .then(member => {
        dispatch({
          type: AUTH_REGISTER_SUCCESS,
          payload: member
        });
        dispatch(updateSessionState());
      })
      .catch((err) => {
        const description = err.code && getErrorMessage(err.code) || err.description || err;
        console.error(description);
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:registration-failed'),
          description: description
        });
        return dispatch({
          type: AUTH_REGISTER_ERROR,
          error: err
        })
      });
  };
}

export function login(formValues, organizationId = null, platformId = null) {
  return dispatch => {
    dispatch({ type: AUTH_LOGIN_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      '/session/login',
      {
        reCAPTCHA: formValues.reCAPTCHA,
        email: formValues.login_email,
        password: formValues.password
      },
      headers
    )
      .then(user => {
        dispatch(fetchMemberRoles(user.id));
        if (organizationId) {
          dispatch(fetchMemberOrganizationRoles(organizationId, user.id));
        }
        if (platformId) {
          dispatch(fetchMemberPlatformRoles(platformId, user.id));
        }

        const queryString = qs.parse(window.location.search);
        // qs v6.9.1 keep the ? for the first query. Updated to 6.9.4. Need a verification to know if it's still needed.
        if (queryString.from || queryString['?from']) {
          dispatch(replace({
            pathname: queryString.from || queryString['?from']
          }));
        }
        dispatch(updateSessionState());
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:login-success'),
          duration: 2
        });
        const badge = document.getElementsByClassName("grecaptcha-badge")[0];
        badge.classList.remove("show");
		    return dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: user
        });
      })
      .catch((err) => {
        console.error(err);
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:login-failed'),
          description: i18next.t('API_NOTIFICATIONS:check-email-password')
        });
        return dispatch({
          type: AUTH_LOGIN_ERROR,
          error: err
        })
      });
  };
}

export function logout(redirect, kickedOut = false) {
  return dispatch => {
    dispatch({
      type: AUTH_LOGOUT_START
    });

    const headers = {
      ...dispatch(getSessionIp())
    };

    // dispatch(clearSessionTimeout());
    // dispatch({ type: UPDATE_SESSION_TIMEOUT_END });

    return requestApiGet(
      '/session/logout',
      null,
      headers
    )
      .then(() => {
        clearSessionId();
        clearSessionDate();
        clearResentActivationEmail();
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:logout-success'),
          duration: 4
        });
      })
      .then(() => dispatch(connect(kickedOut)))
      .then(() => {
        if (redirect && redirect.pathname) {
          history.push(redirect.pathname);
        } else if (redirect) {
          history.push(redirect);
        }

        return dispatch({
          type: AUTH_LOGOUT_SUCCESS
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: AUTH_LOGOUT_ERROR, error: err });
      });
  };
}

export function recover(email) {
  return dispatch => {
    dispatch({ type: AUTH_RECOVERY_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      '/members/password/forgot',
      { email: email },
      headers
    )
      .then(() => {
        return dispatch({
          type: AUTH_RECOVERY_SUCCESS
        });
      })
      .catch((err) => {
        console.error(err);
        const description = err.description || err;
        notification.error({message: i18next.t('API_NOTIFICATIONS:request-failed'), description: i18next.t(`API_NOTIFICATIONS:recover-not-found`)});
        return dispatch({ type: AUTH_RECOVERY_ERROR, error: description });
      });
  };
}

export function reset(password, SID) {
  return dispatch => {
    dispatch({ type: AUTH_RESET_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      `/members/password/reset`,
      {
        token: SID,
        password: password
      },
      headers
    )
      .then((response) => {
        dispatch({
          type: AUTH_RESET_SUCCESS
        });
      })
      .catch((err) => {
        const description = err.description || err;
        const code = err.code;
        notification.error({message: i18next.t('API_NOTIFICATIONS:request-failed'), description: getErrorMessage(code, null, description)});
        dispatch({ type: AUTH_RESET_ERROR, error: err });
      });
  };
}

export function checkForgotPasswordKey(SID) {
  return dispatch => {
    dispatch({
      type: AUTH_CHECK_RECOVERY_KEY_START
    });
    dispatch({
      type: AUTH_CHECK_RECOVERY_KEY_SUCCESS,
      payload: SID
    });
  };
}

export function sendRegistrationEmail() {
  return dispatch => {
    dispatch({ type: SEND_REGISTRATION_EMAIL_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/members/registration/email`,
      null,
      headers
    )
      .then((success) => {
        dispatch({
          type: SEND_REGISTRATION_EMAIL_SUCCESS,
          payload: success
        });
      })
      .catch((err) => {
        dispatch({ type: SEND_REGISTRATION_EMAIL_ERROR, error: err });
      });
  };
}

export function checkRegistrationConfirmationKey(SID) {
  return dispatch => {
    dispatch({ type: AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      `/members/registration/confirm`,
      { token: SID },
      headers
    )
      .then((success) => {
        dispatch({
          type: AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_SUCCESS,
          payload: SID
        });
        dispatch(updateSessionState());
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_ERROR, error: err });
      });
  };
}

export function setSessionLanguage(locale = null) {
  locale = locale === null || locale == "undefined" ? "en" : locale;

  return dispatch => {
    dispatch({
      type: SET_SESSION_LANGUAGE_START
    });
    let languageId = getLanguageIdByKey(locale);

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/session`,
      { languageId: languageId },
      headers
    )
      .then(() => {
        return dispatch({
          type: SET_SESSION_LANGUAGE_SUCCESS,
          payload: {
            languageId,
            language: locale
          }
        })
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: SET_SESSION_LANGUAGE_ERROR, error: err });
      });
  };
}

export function fetchMemberPermissions(userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_PERMISSIONS_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/permissions`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_PERMISSIONS_SUCCESS,
          payload: success,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_PERMISSIONS_ERROR, error: err, userId });
      });
  };
}

export function fetchMemberOrganizationPermissions(organizationId, userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_ORGANIZATION_PERMISSIONS_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/permissions/organization/${organizationId}`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_ORGANIZATION_PERMISSIONS_SUCCESS,
          payload: success,
          id: organizationId,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_ORGANIZATION_PERMISSIONS_ERROR, error: err, id: organizationId, userId });
      });
  };
}

export function fetchMemberPlatformPermissions(platformId, userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_PLATFORM_PERMISSIONS_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/permissions/platform/${platformId}`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_PLATFORM_PERMISSIONS_SUCCESS,
          payload: success,
          id: platformId,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_PLATFORM_PERMISSIONS_ERROR, error: err, id: platformId, userId });
      });
  };
}

export function fetchMemberCampaignPermissions(campaignId, userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_CAMPAIGN_PERMISSIONS_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/permissions/campaign/${campaignId}`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_CAMPAIGN_PERMISSIONS_SUCCESS,
          payload: success,
          id: campaignId,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_CAMPAIGN_PERMISSIONS_ERROR, error: err, id: campaignId, userId });
      });
  };
}

export function fetchMemberTeamPermissions(teamId, userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_TEAM_PERMISSIONS_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/permissions/team/${teamId}`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_TEAM_PERMISSIONS_SUCCESS,
          payload: success,
          id: teamId,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_TEAM_PERMISSIONS_ERROR, error: err, id: teamId, userId });
      });
  };
}

export function clearMemberPermissions() {
  return dispatch => {
    return dispatch({
      type: CLEAR_MEMBER_PERMISSIONS
    });
  };
}

export function clearMemberAdminPermissions() {
  return dispatch => {
    return dispatch({
      type: CLEAR_MEMBER_ADMIN_PERMISSIONS
    });
  };
}

export function fetchMemberRoles(userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_ROLES_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/roles`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_ROLES_SUCCESS,
          payload: success,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_ROLES_ERROR, error: err, userId });
      });
  };
}

export function fetchMemberOrganizationRoles(organizationId, userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_ORGANIZATION_ROLES_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/roles/organization/${organizationId}`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_ORGANIZATION_ROLES_SUCCESS,
          payload: success,
          id: organizationId,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_ORGANIZATION_ROLES_ERROR, error: err, id: organizationId, userId });
      });
  };
}

export function fetchMemberPlatformRoles(platformId, userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_PLATFORM_ROLES_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/roles/platform/${platformId}`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_PLATFORM_ROLES_SUCCESS,
          payload: success,
          id: platformId,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_PLATFORM_ROLES_ERROR, error: err, id: platformId, userId });
      });
  };
}

export function fetchMemberCampaignRoles(campaignId, userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_CAMPAIGN_ROLES_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/roles/campaign/${campaignId}`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_CAMPAIGN_ROLES_SUCCESS,
          payload: success,
          id: campaignId,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_CAMPAIGN_ROLES_ERROR, error: err, id: campaignId, userId });
      });
  };
}

export function fetchMemberTeamRoles(teamId, userId = null) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_TEAM_ROLES_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/session/roles/team/${teamId}`,
      null,
      headers
    )
      .then((success) => {
        return dispatch({
          type: FETCH_MEMBER_TEAM_ROLES_SUCCESS,
          payload: success,
          id: teamId,
          userId
        });
      })
      .catch((err) => {
        console.error(err);
        return dispatch({ type: FETCH_MEMBER_TEAM_ROLES_ERROR, error: err, id: teamId, userId });
      });
  };
}

export function clearMemberRoles() {
  return dispatch => {
    return dispatch({
      type: CLEAR_MEMBER_ROLES
    });
  };
}

export function updateLocationHistory(location) {
  if (isBrowser) {
    return dispatch => dispatch({
      type: UPDATE_LOCATION_HISTORY,
      payload: [location]
    });
  }
}

export function updateSessionTimeout() {
  return (dispatch, getState) => {
    const {
      userTypeId,
      displaySessionTimeoutMessage,
      isUpdateSessionTimeOutInProgress
    } = getState().session;

    if (!isUpdateSessionTimeOutInProgress) {
      dispatch({ type: UPDATE_SESSION_TIMEOUT_START });

      if (displaySessionTimeoutMessage)
        dispatch({ type: HIDE_SESSION_TIMEOUT_MESSAGE });

      dispatch(clearSessionTimeout());

      let timeout1 = null;
      let timeout2 = null;
      const duration1 = 1000 * 60 * 30; // 30min
      const duration2 = duration1 - (1000 * 60 * 2); // 30min - 2min = 28min

      if (userTypeId === 2 && typeof window !== 'undefined') {
        timeout1 = new Timer(() => {
          const sessionDate = readSessionDate();
          const delay = Date.now() - sessionDate;

          if(!sessionDate || delay >= duration1) {
            dispatch(logout(null, true));
          } else {
            updateSessionTimeout();
          }
        }, duration1);
        timeout1.start();

        timeout2 = new Timer(() => {
          const sessionDate = readSessionDate();
          const delay = Date.now() - sessionDate;

          if(delay >= duration1) {
            dispatch(logout(null, true));
          } else
          if(delay >= duration2) {
            dispatch({ type: SHOW_SESSION_TIMEOUT_WARNING });
          } else {
            updateSessionTimeout();
          }
        }, duration2);
        timeout2.start();
      }

      let dispatched = dispatch({
        type: UPDATE_SESSION_TIMEOUT,
        payload: {
          lastApiCallTime: new Date().getTime(),
          sessionTimeout: timeout1,
          noticeTimeout: timeout2
        }
      });

      return dispatched;
    }

    return { type: "UPDATING" };
  };
}

export function clearSessionTimeout() {
  return (dispatch, getState) => {
    const {
      sessionTimeout,
      displaySessionTimeoutWarning,
      displaySessionTimeoutMessage
    } = getState().session;

    if (displaySessionTimeoutWarning)
      dispatch({ type: HIDE_SESSION_TIMEOUT_WARNING });

    if (sessionTimeout && sessionTimeout.sessionTimeout && sessionTimeout.sessionTimeout.id) {
      sessionTimeout.sessionTimeout.clear();
      sessionTimeout.sessionTimeout = null;
    }

    if (sessionTimeout && sessionTimeout.noticeTimeout && sessionTimeout.noticeTimeout.id) {
      sessionTimeout.noticeTimeout.clear();
      sessionTimeout.noticeTimeout = null;
    }

    dispatch({ type: CLEAR_SESSION_TIMEOUT });
  }
}

export function handleWindowVisibility(hidden = false) {
  return (dispatch, getState) => {
    const {
      userTypeId,
      sessionTimeout
    } = getState().session;

    if (userTypeId === 2) {
      if (hidden) {
        if (sessionTimeout && sessionTimeout.sessionTimeout && sessionTimeout.sessionTimeout.state === "resumed") {
          sessionTimeout.sessionTimeout.pause();
        }
        if (sessionTimeout && sessionTimeout.noticeTimeout && sessionTimeout.noticeTimeout.state === "resumed") {
          sessionTimeout.noticeTimeout.pause();
        }
      } else {
        const sessionDate = readSessionDate();
        if (sessionDate) {
          if (sessionTimeout && sessionTimeout.sessionTimeout && sessionTimeout.sessionTimeout.state === "paused") {
            sessionTimeout.sessionTimeout.resume();
          }
          if (sessionTimeout && sessionTimeout.noticeTimeout && sessionTimeout.noticeTimeout.state === "paused") {
            sessionTimeout.noticeTimeout.resume();
          }
        } else {
          dispatch(logout(null, true));
        }
      }
    }
  }
}

export function saveIP(ip) {
  return dispatch => {
    return dispatch({ type: SAVE_IP, payload: ip });
  };
}

export function clearServerIP() {
  return dispatch => {
    return dispatch({ type: CLEAR_IP });
  };
}

export function saveID(id) {
  return dispatch => {
    return dispatch({ type: SAVE_ID, payload: id });
  };
}

export function isCampaignOwner(platformId) {
  return dispatch => {
    dispatch({ type: IS_CAMPAIGN_OWNER_START })
    const params = {
      pageNumber: 1,
      pageResults: 1,
    }
    const headers = {
      authentication: 'true',
      ...dispatch(getSessionIp())
    };
    return requestApiGet(
      `/platforms/${platformId}/campaigns`,
      params,
      headers
    )
      .then(response => {
        let owner = false;
        if (get(response, 'results.length') > 0) {
          owner = true;
        }
        return dispatch({ type: IS_CAMPAIGN_OWNER_SUCCESS, payload: owner});
      })
      .catch(error => {
        return dispatch({ type: IS_CAMPAIGN_OWNER_SUCCESS, payload: false});
      });
  };
}

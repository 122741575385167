import React, { useState, useEffect } from 'react';
import { Affix } from 'antd';
import useWindowDimensions from '../../common/windowDimensions';

import './SettingsColumn.less';

export default function SettingsColumn({ children }) {
  const { width } = useWindowDimensions();
  const [isLoaded, setIsLoaded] = useState(false);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  });

  if (!isLoaded) return null;

  const SettingsBoxTag = width && width > 1057 ? Affix : 'div';
  const SettingsBoxProps = width && width > 1057 ? { offsetTop: 90 } : null;

  return (
    <SettingsBoxTag className="SettingsColumn" {...SettingsBoxProps}>
      <div>{children}</div>
    </SettingsBoxTag>
  );
}

import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import {
  CAMPAIGN_TEMPLATE_TEAMS,
  CAMPAIGN_TEMPLATE_PARTICIPANTS,
  CAMPAIGN_TEMPLATE_SOLO
} from '../../campaignUtils';

import TypeOptions_en from './locales/TypeOptions_en.json';
import TypeOptions_fr from './locales/TypeOptions_fr.json';

import './TypeOptions.less';

class TypeOptions extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'TypeOptions', TypeOptions_en);
    i18next.addResourceBundle('fr', 'TypeOptions', TypeOptions_fr);
  }

  handleOptionClick(value, event) {
    event.preventDefault();
    this.props.onChange(value);
  }

  render() {
    const { t, value } = this.props;

    return (
      <div className="TypeOptions">
        <button
          className={classnames('TypeOptions__Option', {
            'TypeOptions__Option--Selected': value === CAMPAIGN_TEMPLATE_SOLO
          })}
          onClick={this.handleOptionClick.bind(this, CAMPAIGN_TEMPLATE_SOLO)}
        >
          <FontAwesomeIcon icon={['fal', 'user-times']} className="TypeOptions__Icon" />
          <h3>{t('standalone.title')}</h3>
          <p>{t('standalone.examples')}</p>
          <p>{t('standalone.desc')}</p>
        </button>
        <button
          className={classnames('TypeOptions__Option', {
            'TypeOptions__Option--Selected': value === CAMPAIGN_TEMPLATE_PARTICIPANTS
          })}
          onClick={this.handleOptionClick.bind(this, CAMPAIGN_TEMPLATE_PARTICIPANTS)}
        >
          <FontAwesomeIcon icon={['fal', 'user-plus']} className="TypeOptions__Icon" />
          <h3>{t('solo.title')}</h3>
          <p>{t('solo.examples')}</p>
          <p>{t('solo.desc')}</p>
        </button>
        <button
          className={classnames('TypeOptions__Option', {
            'TypeOptions__Option--Selected': value === CAMPAIGN_TEMPLATE_TEAMS
          })}
          onClick={this.handleOptionClick.bind(this, CAMPAIGN_TEMPLATE_TEAMS)}
        >
          <span className="fa-layers TypeOptions__Icon">
            <FontAwesomeIcon icon={['fal', 'users']} transform="left-1" />
            <FontAwesomeIcon icon={['far', 'plus']} transform="shrink-10 right-13 up-1" />
          </span>
          <h3>{t('team.title')}</h3>
          <p>{t('team.examples')}</p>
          <p>{t('team.desc')}</p>
        </button>
      </div>
    );
  }
}

TypeOptions.defaultProps = {
  onChange: () => {}
};

TypeOptions.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf([
    CAMPAIGN_TEMPLATE_TEAMS,
    CAMPAIGN_TEMPLATE_PARTICIPANTS,
    CAMPAIGN_TEMPLATE_SOLO
  ])
};

export default translate('TypeOptions')(TypeOptions);

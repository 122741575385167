import React from 'react';
import PropTypes from 'prop-types';
import HeadMetas from '../../common/HeadMetasContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEmpty } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import PlatformSocialMedia from '../../common/PlatformSocialMedia';
import StaticTitle from '../components/StaticTitle';
import StaticBreadcrumbs from '../components/StaticBreadcrumbs';
import StaticEmbedMap from '../components/StaticEmbedMap';

import { getLanguagesKeys } from '../../parts/common/langUtils';

import ContactPage_en from './locales/ContactPage_en.json';
import ContactPage_fr from './locales/ContactPage_fr.json';

import './ContactPage.less';

class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    props.fetchProvinces()

    i18next.addResourceBundle('en', 'ContactPage', ContactPage_en);
    i18next.addResourceBundle('fr', 'ContactPage', ContactPage_fr);
  }


  getLocaleProvince = (country = 'CA', province = '') => {
    const { locale, provinces } = this.props;
    if (!isEmpty(provinces)) {
      const langs = getLanguagesKeys();
      for (let lang in langs) {
        for (let key in provinces[country][langs[lang]]) {
          if (provinces[country][langs[lang]][key] === province) {
            return get(provinces, `[${country}][${locale}][${key}]`, province);
          }
        }
      }
    }
    return province;
  };

  formatPhone = phone => {
    phone = phone
      .split('-')
      .join('')
      .split('.')
      .join('')
      .split(' ')
      .join('')
      .split('(')
      .join('')
      .split(')')
      .join('')
      .split('+')
      .join('');

    const
      reg = 3,
      pt1 = 3,
      pt2 = 4;

    if (phone.length < 10) {
      phone = [
        phone.substring(phone.length - pt1 - pt2, phone.length - pt2),
        phone.substring(phone.length - pt2)
      ].join('-');
    } else if (phone.length === 10) {
      phone = [
        phone.substring(phone.length - reg - pt1 - pt2, phone.length - pt1 - pt2),
        phone.substring(phone.length - pt1 - pt2, phone.length - pt2),
        phone.substring(phone.length - pt2)
      ].join('-');
    } else {
      phone = [
        phone.substring(0, phone.length - reg - pt1 - pt2),
        phone.substring(phone.length - reg - pt1 - pt2, phone.length - pt1 - pt2),
        phone.substring(phone.length - pt1 - pt2, phone.length - pt2),
        phone.substring(phone.length - pt2)
      ].join('-');
    }

    return phone;
  };

  render() {
    const { t, locale, organization, platformSocial } = this.props;

    const path = [
      {
        name: t('home'),
        url: `/${locale}`
      },
      {
        name: t('breadcrumb-page'),
        url: this.props.location
      }
    ];

    const latitude = get(organization, 'profiles.general.latitude', null);
    const longitude = get(organization, 'profiles.general.longitude', null);
    const name = get(organization, 'name', null);
    const address = get(organization, 'profiles.general.address', null);
    const city = get(organization, 'profiles.general.city', null);
    const country = get(organization, 'profiles.general.country', null);
    const province = this.getLocaleProvince(country, get(organization, 'profiles.general.province', null));
    const postalCode = get(organization, 'profiles.general.postalCode', null);
    const phoneNumber = get(organization, 'profiles.general.phone.number', null);
    const phoneExt = get(organization, 'profiles.general.phone.ext', null);
    const email = get(organization, 'profiles.general.email', null);
    const website = get(organization, 'profiles.general.website', null);

    return (
      <div className="ContactPage FundkyWrap">
        <HeadMetas title={t('contact-us')} />
        <StaticTitle title={t('contact-us')} />
        <StaticBreadcrumbs path={path} />
        <div className="ContactPage__Content">
          <StaticEmbedMap
            className="ContactPage__Map"
            map={`https://maps.google.com/maps?q=${`${latitude},${longitude}`}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
          />
          <div className="ContactPage__Infos">
            <h4>{name}</h4>
            <p>
              {address}
              <br />
              {city}, {province}
              <br />
              <span className="postal-code">{postalCode}</span>
            </p>
            <ul className="ContactPage__Phones">
              <li>
                <FontAwesomeIcon icon={['fal', 'phone']} />
                <a href={`tel:${phoneNumber}`}>
                  {phoneNumber && this.formatPhone(phoneNumber)}
                  {phoneExt && ` #${phoneExt}`}
                </a>
              </li>
            </ul>
            <ul className="ContactPage__Webs">
              <li>
                <FontAwesomeIcon icon={['fal', 'at']} />
                <a href={`mailto:${email}`}>{email}</a>
              </li>
              {website && (
                <li>
                  <FontAwesomeIcon icon={['fal', 'globe-americas']} />
                  <a href={website} target="_blank" rel="noopener noreferrer">
                    {website}
                  </a>
                </li>
              )}
            </ul>
            <PlatformSocialMedia
              platformSocial={platformSocial}
              title={t('follow-us')}
              icons={{
                facebook: 'facebook-f',
                linkedin: 'linkedin-in',
                twitter: 'twitter',
                pinterest: 'pinterest-p',
                instagram: 'instagram',
                square: 'square'
              }}
              iconBackground={true}
              from="ContactPage"
            />
          </div>
        </div>
      </div>
    );
  }
}

ContactPage.defaultProps = {
  map: null
};

ContactPage.propTypes = {
  map: PropTypes.string
};

export default translate('ContactPage')(ContactPage);

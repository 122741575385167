export const CONNECTION_MODAL_REGISTER = 'CONNECTION_MODAL_REGISTER';
export const CONNECTION_MODAL_RECOVERY = 'CONNECTION_MODAL_RECOVERY';
export const CONNECTION_MODAL_RESET = 'CONNECTION_MODAL_RESET';
export const CONNECTION_MODAL_LOGIN = 'CONNECTION_MODAL_LOGIN';
export const CONNECTION_MODAL_INFO = 'CONNECTION_MODAL_INFO';
export const CONNECTION_MODAL_CONFIRM = 'CONNECTION_MODAL_CONFIRM';
export const CONNECTION_MODAL_REGISTRATION_CONFIRMATION = 'CONNECTION_MODAL_REGISTRATION_CONFIRMATION';
export const CONNECTION_MODAL_CLOSED = 'CONNECTION_MODAL_CLOSED';
export const CREATION_MODE_ON = 'CREATION_MODE_ON';
export const CREATION_MODE_OFF = 'CREATION_MODE_OFF';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import RegisterDropdown from '../../common/RegisterDropdownContainer';
import PublicPageClosed from '../../common/PublicPageClosed';
import Plugin from '../../plugin/Plugin';
import { isPluginActive } from '../../plugin/pluginActions';

import PublicParticipantPanel_en from './locales/PublicParticipantPanel_en.json';
import PublicParticipantPanel_fr from './locales/PublicParticipantPanel_fr.json';

import './PublicParticipantPanel.less';

class PublicParticipantPanel extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicParticipantPanel', PublicParticipantPanel_en);
    i18next.addResourceBundle('fr', 'PublicParticipantPanel', PublicParticipantPanel_fr);
    // SetLanguage is to slow, so we set english by default...
    this.state = {
      langage : 'en',
      pathname : `/en/participant-registration`
    }
  }

  // And we change it at the first update.
  componentDidUpdate(prevProps) {
    if(this.state.langage !== this.props.i18n.language){
      this.setState({
        langage : this.props.i18n.language,
        pathname : `/${this.props.i18n.language}/${this.props.t('URL:participant-registration')}`
      });
    }

  }

  handleParticipateClick(event) {
    event.preventDefault();
  }

  render() {
    const { t, locale, count, limit, full, isCurrentUserPage, participant, title, isClosed, campaign, children, platform } = this.props;
    const className = classnames('PublicPanel PublicParticipantPanel', this.props.className);

    // const participateBtn = (
    //   (
    //     campaign &&
    //     campaign.participating
    //   ) ? (
    //     <Tooltip placement="top" title={t('already-in')}>
    //       <Link className="PublicPanel__CTA--participate ant-btn-lg ant-btn disabled" size="large" to={{pathname: this.state.pathname, state: {campaign: campaign}}} onClick={this.handleParticipateClick}>{t('participate')}</Link>
    //     </Tooltip>
    //   ) : (
    //     (
    //       limit === 0 ||
    //       limit - count > 0
    //     ) ? (
    //      <Link className="PublicPanel__CTA--participate ant-btn-lg ant-btn" size="large" to={{pathname: this.state.pathname, state: {campaign: campaign}}} >{t('participate')}</Link>
    //     ) : (
    //       <Tooltip placement="top" title={t(`full${full?`-${full}`:''}`)}>
    //         <Link className="PublicPanel__CTA--participate ant-btn-lg ant-btn disabled" size="large" to={{pathname: this.state.pathname, state: {campaign: campaign}}} onClick={this.handleParticipateClick}>{t('participate')}</Link>
    //       </Tooltip>
    //     )
    //   )
    // );

    const participantAdminUrl = isCurrentUserPage ? (
      `/${locale}/d/contributions/participations/${participant.id}`
    ) : null;

    const editProfileLink = isCurrentUserPage ? (
      <Link to={participantAdminUrl} className="edit-profile" target="_blank" rel="noopener noreferrer">{t('edit-profile')}</Link>
    ) : null;

    const footer = !isClosed ? (
      <div className="PublicParticipantPanel__CTA">
        {/* <Link className="PublicParticipantPanel__CTA--contact ant-btn-lg ant-btn" size="large" to="">{t('Contact')}</Link> */}
        {get(campaign, 'typeId') !== 4
          ? get(campaign, 'behaviorId') === 2 &&
            isPluginActive(platform, 'Event')
              ? <Plugin
                  name="Event"
                  component="BuyTicketBtn"
                  // resolve={() =>
                  //   import(/* webpackChunkName: "Event" */ '../../plugin/plugins/Event/Event')
                  // }
                  size="2x"
                  margin="1em 0"
                  {...{
                    // Props
                    template: "button",
                    plural: false
                  }}
                />
              : <RegisterDropdown />
          : null
        }
      </div>
    ) : null;

    const closedMessage = isClosed ? (

        <PublicPageClosed location="Panel" campaign={campaign}/>

    ) : null;

    return (
      <div className={className}>
        <div className="PublicParticipantPanel__Divider">
          <h2>{title}</h2>
          {editProfileLink}
        </div>
        <div className="PublicParticipantPanel__Content wysiwygStyle">
          {/* {this.props.children && (
            this.props.children.indexOf('<') === -1 ?
              <p>{this.props.children}</p>
              : ReactHtmlParser(this.props.children)
          )} */}
          {children}
        </div>
        <div className="PublicParticipantPanel__Footer">
          {footer}
          {closedMessage}
        </div>
      </div>
    );
  }

}

PublicParticipantPanel.defaultProps = {
  className: null,
  children: null,
  isClosed: false
};

PublicParticipantPanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  isClosed: PropTypes.bool
};

export default translate('PublicParticipantPanel')(PublicParticipantPanel);

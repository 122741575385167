import React from 'react';
import PropTypes from 'prop-types';
import { Radio, notification } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import FundkyButton from 'Parts/ui/components/FundkyButton';
import SelectField from 'Parts/ui/components/fields/SelectField';
import AvatarThumbnail from '../../../common/AvatarThumbnailContainer';
import HelpText from '../../../common/HelpText';

import { UPDATE_TRANSACTION_SUCCESS } from '../../../donation/donationActions';

import RecipientModalContent_en from './locales/RecipientModalContent_en.json';
import RecipientModalContent_fr from './locales/RecipientModalContent_fr.json';

import './RecipientModalContent.less';

class UpdateRecipientModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTargetAlias: null,
      currentTargetCampaignAlias: null,
      currentTargetCampaign: null,
      currentTarget: null,
      options: {
        organization: props.platform,
        campaign: null,
        team: null,
        participant: null
      },
      soloParticipants: []
    };

    this.isUpdatingState = false;
    this.updateState();
    this.toSelect = null;

    i18next.addResourceBundle('en', 'RecipientModalContent', RecipientModalContent_en);
    i18next.addResourceBundle('fr', 'RecipientModalContent', RecipientModalContent_fr);
  }

  componentDidUpdate(prevProps) {
    const currentTargetAlias = this.getTargetAlias(this.props.target),
      currentTargetCampaignAlias = get(this.props, 'target.path.campaign.alias') || null;

    if (
      (!this.isUpdatingState && this.props.target.type !== prevProps.target.type) ||
      currentTargetAlias !== this.state.currentTargetAlias
    ) {
      this.updateState(currentTargetAlias, currentTargetCampaignAlias);
    }
  }

  updateState = (currentTargetAlias = null, currentTargetCampaignAlias = null) => {
    if (!this.isUpdatingState) {
      this.isUpdatingState = true;

      const { props } = this,
        targetAlias = currentTargetAlias || this.getTargetAlias(props.target),
        campaignAlias = currentTargetCampaignAlias || get(props, 'target.path.campaign.alias') || null;

      let _campaign = null,
        _target = null;

      Promise.all([
        this.fetchCampaign(campaignAlias),
        props.searchCampaigns(
          props.platform.id,
          null,
          {},
          { pageNumber: 1, pageResults: 1000 },
          { orderBy: 'name[asc]' }
        )
      ])
        .then(promise => {
          _campaign = promise[0];
          return _campaign;
        })
        .then(campaign => {
          return this.fetchTargetData(props.target.type, targetAlias, campaign);
        })
        .then(response => {
          _target = response.payload || response;
          return _target;
        })
        .then(target => {
          this.setState(
            {
              currentTargetAlias: targetAlias,
              currentTargetCampaignAlias: campaignAlias,
              currentTargetCampaign: _campaign,
              currentTarget: _target
            },
            () => {
              this.isUpdatingState = false;
            }
          );
        })
        .catch(err => {
          this.isUpdatingState = false;
        });
    }
  };

  getTargetAlias = target => {
    const { path } = target;

    return get(path, 'participant.alias') || get(path, 'team.alias') || get(path, 'campaign.alias') || get(this.props, 'platform.id');
  };

  fetchCampaign = alias => {
    return alias
      ? this.props
          .fetchCampaign(alias, false, this.props.platform.id)
          .then(response => response.payload)
          .catch(() => null)
      : Promise.resolve(null);
  };

  fetchTargetData = (type, alias, campaign) => {
    switch (type) {
      case 'organization':
      case 'platform':
        return Promise.resolve(this.props.platform);
      case 'campaign':
        return Promise.resolve(campaign);
      case 'team':
        return this.props.fetchTeam(alias, campaign.id);
      case 'participant':
        return this.props.fetchParticipant(alias, false, campaign.id)
          .then(participant => {
            let memberId = get(participant, 'payload.memberId', null)
            return this.props.fetchMemberById(memberId)
              .then(member => {
                participant.payload.member = member.payload;
                return participant;
              });
          });
    }
  };

  compareName = (a, b) => {
    return a.name.localeCompare(b.name);
  };

  compareParticipantName = (a, b) => {
    const nameA = `${a.member.lastName} ${a.member.firstName}`;
    const nameB = `${b.member.lastName} ${b.member.firstName}`;
    return nameA.localeCompare(nameB);
  };

  filterOption = (input, option) =>
    option.props.children.props.primaryContent.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  handleSelect = value => {
    switch (this.toSelect) {
      case 'campaign':
        this.setState(
          {
            options: {
              ...this.state.options,
              campaign: this.props.campaigns.results.filter(e => e.id === value)[0]
            }
          },
          () => {
            this.props.form.setFieldsValue({ recipient: 'campaign' });
            this.props.form.resetFields(['SelectRecipient']);
            if (this.state.options.campaign.typeId !== 4) {
              this.props
                .searchParticipantsInCampaign(
                  this.state.options.campaign.id,
                  null,
                  {},
                  { pageNumber: 1, pageResults: 100000 },
                  { orderBy: 'name[asc]' }
                )
                .then(response => {
                  this.setState({
                    soloParticipants:
                      (response.payload &&
                        response.payload.results &&
                        response.payload.results
                          .filter(participant => participant.teamId === null)
                          .sort(this.compareParticipantName)
                          .map(participant => ({
                            value: participant.id,
                            text: (
                              <AvatarThumbnail
                                entity={participant}
                                primaryContent={participant.member.name}
                              />
                            )
                          }))) ||
                      []
                  });
                  if (
                    this.state.options.campaign.typeId === 2 ||
                    this.state.options.campaign.typeId === 3
                  )
                    this.props.searchTeamsInCampaign(
                      this.state.options.campaign.id,
                      null,
                      {},
                      { pageNumber: 1, pageResults: 100000 },
                      { orderBy: 'name[asc]' }
                    );
                });
            }
          }
        );
        break;
      case 'team':
        this.setState(
          {
            options: {
              ...this.state.options,
              team: this.props.teams.results.filter(e => e.id === value)[0] || value
            }
          },
          () => {
            if (value > -1) this.props.form.setFieldsValue({ recipient: 'team' });
            this.props.form.resetFields(['SelectRecipient']);
            if (this.state.options.team && this.state.options.team.id > -1)
              this.props.searchParticipantsInTeam(
                this.state.options.team.id,
                null,
                {},
                { pageNumber: 1, pageResults: 100000 },
                { orderBy: 'name[asc]' }
              );
          }
        );
        break;
      case 'participant':
        this.setState(
          {
            options: {
              ...this.state.options,
              participant: this.props.participants.results.filter(e => e.id === value)[0]
            }
          },
          () => {
            this.props.form.setFieldsValue({ recipient: 'participant' });
            this.props.form.resetFields(['SelectRecipient']);
          }
        );
        break;
    }
  };

  getGradeByType = type => {
    switch (type) {
      case 'organization':
        return 0;
      case 'campaign':
        return 1;
      case 'team':
        return 2;
      case 'participant':
        return 3;
    }
  };

  getTypeByGrade = grade => {
    switch (grade) {
      case 0:
        return 'organization';
      case 1:
        return 'campaign';
      case 2:
        return 'team';
      case 3:
        return 'participant';
    }
  };

  handleRemoveOptions = (e, type) => {
    e.preventDefault();

    const selected = this.props.form.getFieldsValue(['recipient']).recipient,
      selectedGrade = this.getGradeByType(selected),
      typeGrade = this.getGradeByType(type);

    // Select previous
    if (typeGrade <= selectedGrade) {
      if (typeGrade - 1 === 2 && this.state.options.team === -1) {
        this.props.form.setFieldsValue({ recipient: 'campaign' });
      } else {
        this.props.form.setFieldsValue({ recipient: this.getTypeByGrade(typeGrade - 1) });
      }
    }

    // Remove from State
    this.setState({
      options: {
        ...this.state.options,
        campaign: type === 'campaign' ? null : this.state.options.campaign,
        team: type === 'campaign' || type === 'team' ? null : this.state.options.team,
        participant:
          type === 'campaign' || type === 'team' || type === 'participant'
            ? null
            : this.state.options.participant
      },
      soloParticipants: typeGrade <= 1 ? [] : this.state.soloParticipants
    });
  };

  handleSubmit = () => {
    const { validateFields, getFieldsValue, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        if (allValues.recipient) {
          let updated = true;
          const values = {
            target: {
              platformId:
                allValues.recipient === 'organization'
                  ? this.state.options.organization.id
                  : undefined,
              campaignId:
                allValues.recipient === 'campaign' ? this.state.options.campaign.id : undefined,
              teamId: allValues.recipient === 'team' ? this.state.options.team.id : undefined,
              participantId:
                allValues.recipient === 'participant'
                  ? this.state.options.participant.id
                  : undefined
            }
          };

          switch (this.props.target.type) {
            case 'organization':
            case 'platform':
              if (
                values.target.platformId &&
                this.state.options.organization.id === this.state.currentTargetAlias
              )
                updated = false;
              break;
            case 'campaign':
              if (
                values.target.campaignId &&
                this.state.options.campaign.alias === this.state.currentTargetAlias
              )
                updated = false;
              break;
            case 'team':
              if (
                values.target.teamId &&
                this.state.options.team.alias === this.state.currentTargetAlias
              )
                updated = false;
              break;
            case 'participant':
              if (
                values.target.participantId &&
                this.state.options.participant.alias === this.state.currentTargetAlias
              )
                updated = false;
              break;
          }

          if (updated) {
            this.props
              .updateTransaction(
                values,
                this.props.transaction.id,
                this.props.transaction.donation.id
              )
              .then(response => {
                if (response.type === UPDATE_TRANSACTION_SUCCESS) {
                  this.props.closeModal();
                }
              });
          } else {
            notification.warning({ message: this.props.t('already-uptodate') });
          }
        }
      }
    });
  };

  handleReset = () => {
    this.toSelect = null;
    this.setState(
      {
        currentTargetAlias: null,
        currentTargetCampaignAlias: null,
        currentTargetCampaign: null,
        currentTarget: null,
        options: {
          organization: this.props.platform,
          campaign: null,
          team: null,
          participant: null
        },
        soloParticipants: []
      },
      () => {
        this.updateState();
        this.props.form.resetFields();
      }
    );
  };

  render() {
    const { props, state, compareName, compareParticipantName, filterOption } = this,
      { t, target, participants, teams, campaigns, form } = props,
      { currentTarget, options, soloParticipants } = state,
      { getFieldDecorator } = form;

    const mappedParticipants =
      participants && participants.results
        ? participants.results
            .filter(participant => participant.statusId === 1) //ACTIVE
            .sort(compareParticipantName)
            .map(participant => {
              return {
                value: participant.id,
                text: (
                  <AvatarThumbnail entity={participant} primaryContent={participant.member.name} />
                )
              };
            })
        : [];

    const mappedTeams =
      teams && teams.results
        ? teams.results
            .filter(team => team.statusId === 1) //ACTIVE
            .sort(compareName)
            .map(team => {
              return {
                value: team.id,
                text: <AvatarThumbnail entity={team} primaryContent={team.name} />
              };
            })
        : [];

    if (soloParticipants && soloParticipants.length > 0) {
      mappedTeams.push({
        value: -1,
        text: t('individual-participants')
      });
    }

    const mappedCampaigns =
      campaigns && campaigns.results
        ? campaigns.results
            .filter(campaign => campaign.statusId === 1 || campaign.statusId === 4) //ACTIVE || CLOSED
            .sort(compareName)
            .map(campaign => {
              return {
                value: campaign.id,
                text: <AvatarThumbnail entity={campaign} primaryContent={campaign.name} />
              };
            })
        : [];

    const or = <span className="RecipientModalContent__Or">{t('or')}</span>;

    this.toSelect =
      !options.campaign && campaigns && campaigns.results && campaigns.results.length > 0
        ? 'campaign'
        : !options.team &&
          options.campaign &&
          options.campaign.typeId !== 1 &&
          options.campaign.typeId !== 4 &&
          teams &&
          teams.results &&
          teams.results.length > 0
        ? 'team'
        : !options.participant &&
          options.campaign &&
          options.campaign.typeId !== 4 &&
          ((participants && participants.results && participants.results.length > 0) ||
            (soloParticipants && soloParticipants.length > 0))
        ? 'participant'
        : null;

    const SelectRecient = this.toSelect ? (
      <SelectField
        className="RecipientModalContent--select"
        form={this.props.form}
        fieldId="SelectRecipient"
        optionsArray={
          this.toSelect === 'participant' &&
          mappedParticipants &&
          mappedParticipants.length > 0 &&
          this.state.options.team !== -1
            ? mappedParticipants
            : this.toSelect === 'participant' &&
              soloParticipants &&
              soloParticipants.length > 0 &&
              this.state.options.team === -1
            ? soloParticipants
            : this.toSelect === 'team' && mappedTeams
            ? mappedTeams
            : this.toSelect === 'campaign' && mappedCampaigns
            ? mappedCampaigns
            : []
        }
        style={{ width: '100%' }}
        placeholder={t(`${this.toSelect}-placeholder`)}
        onSelect={this.handleSelect}
        showSearch
        filterOption={filterOption}
        dropdownClassName="overModal"
      />
    ) : null;

    return (
      <React.Fragment>
        <label className="RecipientModalContent--label">{t('actual-recipient')}</label>
        {currentTarget && (
          <AvatarThumbnail
            className="RecipientModalContent__AvatarThumbnail"
            entity={currentTarget}
            primaryContent={<h1>{currentTarget && currentTarget.name || currentTarget && currentTarget.member && currentTarget.member.name || undefined}</h1>}
            secondaryContent={
              <HelpText>{t(target.type === 'platform' ? 'organization' : target.type)}</HelpText>
            }
          />
        )}
        <label className="RecipientModalContent--label">{t('new-recipient')}</label>
        {getFieldDecorator('recipient')(
          <Radio.Group className="RecipientModalContent--radio">
            {target.type !== 'platform' && target.type !== 'organization' && (
              <React.Fragment>
                <Radio.Button className="organization-option" value="organization">
                  <AvatarThumbnail
                    className="RecipientModalContent__AvatarThumbnail"
                    entity={options.organization}
                    primaryContent={<h1>{options.organization.name}</h1>}
                    secondaryContent={<HelpText>{t('organization')}</HelpText>}
                  />
                </Radio.Button>
                {or}
              </React.Fragment>
            )}
            {options.campaign && (
              <Radio.Button className="campaign-option" value="campaign">
                <AvatarThumbnail
                  className="RecipientModalContent__AvatarThumbnail"
                  entity={options.campaign}
                  primaryContent={<h1>{options.campaign.name}</h1>}
                  secondaryContent={<HelpText>{t('campaign')}</HelpText>}
                />
                <FundkyButton
                  className="OrgOption"
                  icon={['fal', 'times']}
                  type="text-cancel"
                  action={{ func: this.handleRemoveOptions, params: ['campaign'] }}
                />
              </Radio.Button>
            )}
            {options.team && options.team === -1 ? (
              <label
                className="team-option ant-radio-button-wrapper not-option"
                onClick={e => e.preventDefault()}
              >
                <AvatarThumbnail
                  className="RecipientModalContent__AvatarThumbnail"
                  entity={{}}
                  primaryContent={<h1>{t('individual-participants')}</h1>}
                  avatar={false}
                />
                <FundkyButton
                  icon={['fal', 'times']}
                  type="text-cancel"
                  action={{ func: this.handleRemoveOptions, params: ['team'] }}
                />
              </label>
            ) : options.team ? (
              <Radio.Button className="team-option" value="team">
                <AvatarThumbnail
                  className="RecipientModalContent__AvatarThumbnail"
                  entity={options.team}
                  primaryContent={<h1>{options.team.name}</h1>}
                  secondaryContent={<HelpText>{t('team')}</HelpText>}
                />
                <FundkyButton
                  icon={['fal', 'times']}
                  type="text-cancel"
                  action={{ func: this.handleRemoveOptions, params: ['team'] }}
                />
              </Radio.Button>
            ) : null}
            {options.campaign && !options.participant && this.toSelect && or}
            {options.participant && (
              <Radio.Button className="participant-option" value="participant">
                <AvatarThumbnail
                  className="RecipientModalContent__AvatarThumbnail"
                  entity={options.participant}
                  primaryContent={<h1>{options.participant.member.name}</h1>}
                  secondaryContent={<HelpText>{t('participant')}</HelpText>}
                />
                <FundkyButton
                  icon={['fal', 'times']}
                  type="text-cancel"
                  action={{ func: this.handleRemoveOptions, params: ['participant'] }}
                />
              </Radio.Button>
            )}
          </Radio.Group>
        )}
        {this.toSelect && SelectRecient}
      </React.Fragment>
    );
  }
}

UpdateRecipientModalContent.defaultProps = {
  target: {},
  participants: null,
  teams: null,
  campaigns: null,
  team: null,
  campaign: null,
  visible: false,
  closeModal: () => {}
};

UpdateRecipientModalContent.propTypes = {
  target: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default translate('RecipientModalContent', { withRef: true })(UpdateRecipientModalContent);

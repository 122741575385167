import React from 'react';
import { Divider } from 'antd';
import { get } from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import RegisterDropdown from '../../common/RegisterDropdownContainer';
import LinkButton from 'Parts/common/LinkButton';
import FundkyCTA from 'Parts/ui/components/FundkyCTA';

import { getLanguageKeyByName } from 'Parts/common/langUtils';

import PresentationSection_en from './locales/PresentationSection_en.json';
import PresentationSection_fr from './locales/PresentationSection_fr.json';

import './PresentationSection.less';

class PresentationSection extends React.Component {
  constructor(props) {
    super(props);

    // [MOTIVACTION]
    // Bloc Joindre une campagne
    // - Modifier le texte du bouton pour Participer
    // - Display "Participate" instead of "Buy ticket"
    // - Personnaliser le court texte explicatif au-dessus du bouton dans le bloc pour inciter les gens à participer.
    // Bloc Donner
    // - Modifier la redirection du bouton : en cliquant, le bouton redirige plutôt directement vers le don à la campagne Défi 55 2022
    // - Personnaliser le court texte explicatif au-dessus du bouton pour inciter les gens à donner sans participer
    this.exceptions_MOTIVACTION = [
      //{environment: "development", organizationId: 40, platformId: 66, campaignId: 397},
      //{environment: "preproduction", organizationId: 26, platformId: 27, campaignId: 385},
      {environment: "production", organizationId: 59, platformId: 100, campaignId: 519},
    ];

    // [REMAX]
    // - Hide "How I can help?" section
    this.exceptions_REMAX = [
      //{environment: "development", organizationId: 40, platformId: 66, campaignId: 405},
      //{environment: "preproduction", organizationId: 26, platformId: 27, campaignId: 386},
      {environment: "production", organizationId: 75, platformId: 128, campaignId: 608},
      {environment: "production", organizationId: 75, platformId: 128, campaignId: 609},
      {environment: "production", organizationId: 75, platformId: 128, campaignId: 610},
    ];

    this.state = {
      isException_MOTIVACTION: this.checkExceptions(this.exceptions_MOTIVACTION, this.props.platform),
      isException_REMAX: this.checkExceptions(this.exceptions_REMAX, this.props.platform)
    };

    i18next.addResourceBundle('en', 'PresentationSection', PresentationSection_en);
    i18next.addResourceBundle('fr', 'PresentationSection', PresentationSection_fr);
  }

  componentDidMount() {
    this.setState({
      isException_MOTIVACTION: this.checkExceptions(this.exceptions_MOTIVACTION, this.props.platform),
      isException_REMAX: this.checkExceptions(this.exceptions_REMAX, this.props.platform)
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.platform.id !== this.props.platform.id) {
      this.setState({
        isException_MOTIVACTION: this.checkExceptions(this.exceptions_MOTIVACTION, this.props.platform),
        isException_REMAX: this.checkExceptions(this.exceptions_REMAX, this.props.platform)
      });
    }
  }

  checkExceptions = (exceptions, entity) => {
    let environment = ENVIRONMENT;
    let organizationId = entity.organization.id;
    let platformId = entity.id;

    return exceptions.filter(exc => {
      return exc.environment === environment &&
        exc.organizationId === organizationId &&
        exc.platformId === platformId
    }).length ? true : false;
  };

  render() {
    const
      { props } = this,
      { t, locale, platform, campaigns } = props;

    const title = get(platform, 'description.title', null);
    const description = get(platform, 'description.description', null)
      ? ReactHtmlParser(get(platform, 'description.description', ''))
      : null;
    const campaignEnabled = Number(platform.settings.campaign.creation.enabled) || false;

    const availableCampaigns = campaigns.filter(campaign =>
      get(campaign, 'typeId') === 1 && (
        get(campaign, 'settings.participant.limit', 0) === 0 ||
        get(campaign, 'childrenCount.participants', 0) < get(campaign, 'settings.participant.limit', 0)
      ) ||
      get(campaign, 'typeId') === 2 && (
        get(campaign, 'settings.team.limit', 0) === 0 ||
        get(campaign, 'settings.participant.limit', 0) === 0 ||
        get(campaign, 'childrenCount.participants', 0) < get(campaign, 'settings.team.limit', 0) * get(campaign, 'settings.participant.limit', 0)
      ) ||
      get(campaign, 'typeId') === 3
    );

    let formattedNumber = this.props.platform.collected.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });

    // Convert the number to a string to iterate through its digits
    let numberStr = `$${formattedNumber}`;

    let digitElements = numberStr.split('').map((digit, index) => (
      digit === ',' || digit === '.'?
        <h3 className='px-3 py-1 bg-white rounded-2' key={index}>{digit}</h3>
      :
        <h3 className='bg-white-gradiant px-3 py-1 rounded-2' key={index}>{digit}</h3>
    ));

    return (
      <div className="PresentationSection FundkyWrap">
        {
          this.props.platform.is_show_total_amount !== 0 && (
            <span className="bg-amount" style={{backgroundColor: this.props.platform.settings.color.secondary}}>
              <span className='title'>{t("amount_raised")}</span>{digitElements}
            </span>
          )
        }
        {(title || description) && (
          <div className="PresentationSection__Information">
            <h1>{title}</h1>
            <div className="wysiwygStyle">{description}</div>
          </div>
        )}
        {!this.state.isException_REMAX &&
        <>
          <Divider />
          <h2>{t('how-can-i-help')}</h2>
          <div className="PresentationSection__CallToActions">
            {availableCampaigns.length
              ? this.state.isException_MOTIVACTION
                ? <FundkyCTA
                    className="PresentationSection__CallToAction"
                    icon={["fal", "user-plus"]}
                    iconSize="2x"
                    title={t('fundraising.title')}
                    description={t('fundraising.desc')}
                    cta={<LinkButton to={availableCampaigns.length ? `/${locale}/${this.props.t('URL:search/campaigns')}` : `/${locale}/${t('URL:search')}`} type="primary" size="large">{t('fundraising.cta')}</LinkButton>}
                  />
                : <FundkyCTA
                    className="PresentationSection__CallToAction"
                    icon={["fal", "user-plus"]}
                    iconSize="2x"
                    title={t('become-a-participant')}
                    description={t('become-a-participant-description')}
                    cta={<RegisterDropdown label={t('register')} subLabel={[t('register-team'), t('register-participant')]}/>}
                  />
              : null
            }
            {this.props.isBillingConfigured
              ? this.state.isException_MOTIVACTION
                ? <FundkyCTA
                    className="PresentationSection__CallToAction PresentationSection__CallToAction--Primary"
                    icon={['fal', 'gift']}
                    iconSize="2x"
                    title={t('donating.title')}
                    description={t('donating.desc')}
                    cta={<LinkButton to={campaignEnabled || availableCampaigns.length ? `/${locale}/${t('URL:give')}` : `/${locale}/${t('URL:organization/give')}`} type="primary" size="large">{t('donating.cta')}</LinkButton>}
                  />
                : <FundkyCTA
                    className="PresentationSection__CallToAction PresentationSection__CallToAction--Primary"
                    icon={['fal', 'gift']}
                    iconSize="2x"
                    title={t('make-a-donation')}
                    description={t('make-a-donation-description')}
                    cta={<LinkButton to={campaignEnabled || availableCampaigns.length ? `/${locale}/${t('URL:give')}` : `/${locale}/${t('URL:organization/give')}`} type="primary" size="large">{t('donate')}</LinkButton>}
                />
              : null
            }
            {campaignEnabled && (
              <FundkyCTA
                className="PresentationSection__CallToAction"
                icon={['fal', 'flag']}
                iconSize="2x"
                title={t('create-a-campaign')}
                description={t('create-a-campaign-description')}
                cta={
                  <LinkButton to={`/${locale}/${t('URL:create-a-campaign')}`} size="large">
                    {t('start')}
                  </LinkButton>
                }
              />
            )
            // <div className="PresentationSection__CallToAction">
            //   <FontAwesomeIcon icon={["fal", "flag"]} size="2x" />
            //   <h3>{t('create-a-campaign')}</h3>
            //   <p>
            //   {t('create-a-campaign-description')}
            //   </p>
            //   <LinkButton to={`/${locale}/${t('URL:create-a-campaign')}`} size="large">{t('start')}</LinkButton>
            // </div>
            }
          </div>
        </> ||
        null
        }
      </div>
    );
  }
}

export default translate('PresentationSection')(PresentationSection);

import i18next from 'i18next';

export function simpleFieldValidator(required=true) {
  // Required validation
  const requiredValidation = required ? {
    required: required,
    message: i18next.t('FIELDS-VALIDATION:required-message')
  } : null;

  return ( requiredValidation ?
    [
      requiredValidation
    ] : []
  )
}

export function nameFieldValidator(required=true, maxlength=128, third=false, entity='name') {
  // third person message
  let _third = third ? '_3' : '';

  // Required validation
  const requiredValidation = required ? {
    required: required,
    message: i18next.t(`FIELDS-VALIDATION:${entity}:required${_third}`)
  } : null;

  // Characters validation
  const charactersValidation = {
    pattern: /^[a-zA-Z0-9\u00C0-\u017F ,-\\']+$/,
    message: i18next.t(`FIELDS-VALIDATION:${entity}:validation`)
  };

  // Length validation
  const lengthValidation = {
    pattern: new RegExp(`^.{2,${maxlength}}$`),
    message: i18next.t(`FIELDS-VALIDATION:${entity}:maximum`, {max: maxlength})
  };

  return (
    [
      requiredValidation,
      charactersValidation,
      lengthValidation
    ]
  )
}

export function emailFieldValidator(required=true, third=false) {
  // third person message
  let _third = third ? '_3' : '';

  // Required validation
  const requiredValidation = required ? {
    required: required,
    message: i18next.t(`FIELDS-VALIDATION:email:required${_third}`)
  } : null;

  // Characters validation
  const typeValidation = {
    type: 'email',
    message: i18next.t('FIELDS-VALIDATION:email:validation')
  };

  return (
    [
      requiredValidation,
      typeValidation,
    ]
  )
}

export function usernameFieldValidator(required=true, maxlength=128, third=false) {
  // third person message
  let _third = third ? '_3' : '';

  // Required validation
  const requiredValidation = required ? {
    required: required,
    message: i18next.t(`FIELDS-VALIDATION:username:required${_third}`)
  } : null;

  // Characters validation
  const charactersValidation = {
    pattern: /^[a-zA-Z0-9_-]+$/,
    message: i18next.t('FIELDS-VALIDATION:username:validation')
  };

  // Length validation
  const lengthValidation = {
    pattern: new RegExp(`^.{2,${maxlength}}$`),
    message: i18next.t('FIELDS-VALIDATION:username:maximum', {max: maxlength})
  };

  return (
    [
      requiredValidation,
      charactersValidation,
      lengthValidation
    ]
  )
}

export function customFieldValidator(
  required=null, //bool
  requiredMessage=null, //string
  validation=null, //regex
  validationMessage=null, //string
  minlength=null, //number
  maxlength=null, //number
  lenghtMessage=null //string
) {
  // Required validation
  const requiredValidation = required ? [{
    required: required,
    message: requiredMessage || i18next.t('FIELDS-VALIDATION:custom:required')
  }] : [];

  // Characters validation
  const charactersValidation = validation && validation instanceof RegExp ? [{
    pattern: validation,
    message: validationMessage || i18next.t('FIELDS-VALIDATION:custom:validation')
  }] : [];

  // Length validation
  if(!minlength) minlength = 0;
  if(!maxlength) maxlength = 0;

  const lengthValidation = (
    (maxlength && maxlength > 0) ||
    (minlength && minlength > 0)
  ) ? [{
    pattern: minlength === maxlength ? new RegExp(`^.{${maxlength}}$`) :
      minlength > maxlength ? new RegExp(`^.{${minlength},}$`) : new RegExp(`^.{${minlength},${maxlength}}$`),
    message: lenghtMessage ||
      (
        minlength &&
        minlength > 0 &&
        (!maxlength || maxlength <= 0)
      ) ? i18next.t('FIELDS-VALIDATION:custom:minimum', {min: minlength}) : null ||
      (
        minlength && maxlength &&
        minlength > 0 && maxlength > 0 &&
        minlength < maxlength
      ) ? i18next.t('FIELDS-VALIDATION:custom:minimum-maximum', {min: minlength, max: maxlength}) : null ||
      (
        minlength && maxlength &&
        minlength > 0 && maxlength > 0 &&
        minlength === maxlength
      ) ? i18next.t('FIELDS-VALIDATION:custom:equal', {equal: maxlength}) : null ||
      (
        maxlength &&
        maxlength > 0 &&
        (!minlength || minlength <= 0)
      ) ? i18next.t('FIELDS-VALIDATION:custom:maximum', {max: maxlength}) : null
  }] : [];

  return (
    [
      ...requiredValidation,
      ...charactersValidation,
      ...lengthValidation
    ]
  )
}

export function urlFieldValidator(required=true) {
  // Required validation
  const requiredValidation = required ? {
    required: required,
    message: i18next.t('FIELDS-VALIDATION:url:required')
  } : null;

  // Characters validation
  const typeValidation = {
    type: 'url',
    message: i18next.t('FIELDS-VALIDATION:url:validation')
  };

  return [
    requiredValidation,
    typeValidation,
  ].filter(el => {
    return el != null
  });
}

import {
  bindActionCreators
} from 'redux';
import {
  connect
} from 'react-redux';

import {
  checkAuthorization
} from 'Parts/common/authorizationUtils';

import PluginAdminPageWrapper from './PluginAdminPageWrapper';

const mapStateToProps = state => ({
  locale: state.session.language,
  platform: state.platform.platform,
  organization: state.organization.organization
});

const mapDispatchToProps = dispatch => bindActionCreators({
  checkAuthorization,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PluginAdminPageWrapper);

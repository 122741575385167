import {
  ADD_SURVEY_INFORMATION_START,
  ADD_SURVEY_INFORMATION_SUCCESS,
  ADD_SURVEY_INFORMATION_ERROR,
  UPDATE_SURVEY_INFORMATION_START,
  UPDATE_SURVEY_INFORMATION_SUCCESS,
  UPDATE_SURVEY_INFORMATION_ERROR,
  DELETE_SURVEY_INFORMATION_START,
  DELETE_SURVEY_INFORMATION_SUCCESS,
  DELETE_SURVEY_INFORMATION_ERROR,
  FETCH_SURVEY_DETAILS_START,
  FETCH_SURVEY_DETAILS_SUCCESS,
  FETCH_SURVEY_DETAILS_ERROR,
  ADD_SURVEY_FIELDS_START,
  ADD_SURVEY_FIELDS_SUCCESS,
  ADD_SURVEY_FIELDS_ERROR,
  UPDATE_SURVEY_FIELDS_START,
  UPDATE_SURVEY_FIELDS_SUCCESS,
  UPDATE_SURVEY_FIELDS_ERROR,
  FETCH_SURVEY_FIELDS_START,
  FETCH_SURVEY_FIELDS_SUCCESS,
  FETCH_SURVEY_FIELDS_ERROR,
  FETCH_FIELDS_START,
  FETCH_FIELDS_SUCCESS,
  FETCH_FIELDS_ERROR,
  DELETE_FIELDS_START,
  DELETE_FIELDS_SUCCESS,
  DELETE_FIELDS_ERROR,
  FETCH_CAMPAIGN_INFORMATION,
  FETCH_CAMPAIGN_TICKET_INFORMATION,
  FETCH_CAMPAIGN_INFORMATION_ERROR,
  FETCH_CAMPAIGN_TICKET_INFORMATION_ERROR,
  FETCH_SURVEY_RESULT_START,
  FETCH_SURVEY_RESULT_SUCCESS,
  FETCH_SURVEY_RESULT_ERROR,
} from '../SurveyPage/SurveyPageActions';

const initialState = {
  isAddSurveyInformationInProgress: false,
  isUpdateSurveyInformationInProgress: false,
  isDeleteSurveyInformationInProgress: false,
  isFetchSurveyInformationInProgress: false,
  isFetchSurveyFieldsInProgress: false,
  isFetchSurveyResultInProgress:false,
  isFetchFieldsInProgress: false,
  isDeleteFieldsInProgress: false,
  surveysFieldsUpdateError: null,
  addingSurveyInfo: {},
  updateSurveys: [],
  surveyDetails: [],
  surveysFields: [],
  fields: [],
  campaignInfo: [],
  campaignTicketInfo: [],
  resultDetails:[],
  isFetchSurveyCampaign: false,
  isFetchSurveyCampaignTicket: false,
};

export default function surveyPage(state = initialState, action) {
  switch (action.type) {
    case ADD_SURVEY_INFORMATION_START:
      return {
        ...state,
        isAddSurveyInformationInProgress: true,
      };
    case ADD_SURVEY_INFORMATION_SUCCESS:
      return {
        ...state,
        isAddSurveyInformationInProgress: false,
        addingSurveyInfo: action.payload,
      };
    case ADD_SURVEY_INFORMATION_ERROR:
      return {
        ...state,
        isAddSurveyInformationInProgress: false,
      };
    case UPDATE_SURVEY_INFORMATION_START:
      return {
        ...state,
        isUpdateSurveyInformationInProgress: true,
      };
    case UPDATE_SURVEY_INFORMATION_SUCCESS:
      return {
        ...state,
        isUpdateSurveyInformationInProgress: false,
        updateSurveys: action.payload,
      };
    case UPDATE_SURVEY_INFORMATION_ERROR:
      return {
        ...state,
        isUpdateSurveyInformationInProgress: false,
      };
    case DELETE_SURVEY_INFORMATION_START:
      return {
        ...state,
        isDeleteSurveyInformationInProgress: true,
      };
    case DELETE_SURVEY_INFORMATION_SUCCESS:
      return {
        ...state,
        isDeleteSurveyInformationInProgress: false,
      };
    case DELETE_SURVEY_INFORMATION_ERROR:
      return {
        ...state,
        isDeleteSurveyInformationInProgress: false,
      };
    case FETCH_SURVEY_DETAILS_START:
      return {
        ...state,
        isFetchSurveyInformationInProgress: true,
      };
    case FETCH_SURVEY_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchSurveyInformationInProgress: false,
        surveyDetails: action.payload,
      };
    case FETCH_SURVEY_DETAILS_ERROR:
      return {
        ...state,
        isFetchSurveyInformationInProgress: false,
      }; 
      case FETCH_SURVEY_RESULT_START:
      return {
        ...state,
        isFetchSurveyResultInProgress: true,
      };
    case FETCH_SURVEY_RESULT_SUCCESS:
      return {
        ...state,
        isFetchSurveyResultInProgress: false,
        resultDetails: action.payload,
      };
    case FETCH_SURVEY_RESULT_ERROR:
      return {
        ...state,
        isFetchSurveyResultInProgress: false,
      };
    case ADD_SURVEY_FIELDS_START:
      return {
        ...state,
        isAddSurveyFieldsInProgress: true,
      };
    case ADD_SURVEY_FIELDS_SUCCESS:
      return {
        ...state,
        isAddSurveyFieldsInProgress: false,
      };
    case ADD_SURVEY_FIELDS_ERROR:
      return {
        ...state,
        isAddSurveyFieldsInProgress: false,
      };
    case UPDATE_SURVEY_FIELDS_START:
      return {
        ...state,
        isUpdateSurveyFieldsInProgress: true,
      };
    case UPDATE_SURVEY_FIELDS_SUCCESS:
      return {
        ...state,
        isUpdateSurveyFieldsInProgress: false,
      };
    case UPDATE_SURVEY_FIELDS_ERROR:
      return {
        ...state,
        isUpdateSurveyFieldsInProgress: false,
        surveysFieldsUpdateError: action.payload,
      };
    case FETCH_SURVEY_FIELDS_START:
      return {
        ...state,
        isFetchSurveyFieldsInProgress: true,
      };
    case FETCH_SURVEY_FIELDS_SUCCESS:
      return {
        ...state,
        isFetchSurveyFieldsInProgress: false,
        surveysFields: action.payload,
      };
    case FETCH_SURVEY_FIELDS_ERROR:
      return {
        ...state,
        isFetchSurveyFieldsInProgress: false,
        surveysFields: [],
      };
    case FETCH_FIELDS_START:
      return {
        ...state,
        isFetchFieldsInProgress: true,
      };
    case FETCH_FIELDS_SUCCESS:
      return {
        ...state,
        isFetchFieldsInProgress: false,
        fields: action.payload,
      };
    case FETCH_FIELDS_ERROR:
      return {
        ...state,
        isFetchFieldsInProgress: false,
      };
    case DELETE_FIELDS_START:
      return {
        ...state,
        isDeleteFieldsInProgress: true,
      };
    case DELETE_FIELDS_SUCCESS:
      return {
        ...state,
        isDeleteFieldsInProgress: false,
      };
    case DELETE_FIELDS_ERROR:
      return {
        ...state,
        isDeleteFieldsInProgress: false,
      };
    case FETCH_CAMPAIGN_INFORMATION:
      return { ...state, campaignInfo: action.payload };
    case FETCH_CAMPAIGN_INFORMATION_ERROR:
      return { ...state, isFetchSurveyCampaign: true };
    case FETCH_CAMPAIGN_TICKET_INFORMATION:
      return { ...state, campaignTicketInfo: action.payload };
    case FETCH_CAMPAIGN_TICKET_INFORMATION_ERROR:
      return { ...state, isFetchSurveyCampaignTicket: action.payload };
    default:
      return state;
  }
}

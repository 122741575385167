import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from 'Parts/chore/dataFetcher';
import { searchTeamsInCampaign, clearSearch } from '../../../search/searchActions';
import CampaignsTeamsListPage from './CampaignsTeamsListPage';

const mapStateToProps = state => ({
  campaignId: state.campaign.campaign.id,
  teams: state.search.teams.results,
  pagination: state.search.teams.pagination,
  filters: state.search.teams.filters,
  search: state.search.teams.search,
  orderBy: state.search.teams.orderBy,
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchTeamsInCampaign,
  clearSearch
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(CampaignsTeamsListPage);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get, noop } from 'lodash';

import LinkButton from 'Parts/common/LinkButton';

import { getCdnUrl } from 'Parts/common/environment';
import { moneyFormat } from 'Parts/common/moneyFormat';

import PublicThermometer_en from './locales/PublicThermometer_en.json';
import PublicThermometer_fr from './locales/PublicThermometer_fr.json';

import '../assets/images/default_participant_picture_300x300.jpg';

import './PublicThermometer.less';

const cdnUrl = getCdnUrl();

class PublicThermometer extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicThermometer', PublicThermometer_en);
    i18next.addResourceBundle('fr', 'PublicThermometer', PublicThermometer_fr);
  }

  formatMoney(money, lang = 'en') {
    if (!money) {
      money = 0;
    }

    money = parseInt(money);

    switch (lang) {
      case 'fr':
        return (
          <span>
            <b>{money.toLocaleString(lang)}</b> $
          </span>
        );
      case 'en':
      default:
        return (
          <span>
            $<b>{money.toLocaleString(lang)}</b>
          </span>
        );
    }
  }

  render() {
    const
      { props } = this,
      { t, locale, compact, giveUrl, className, entity, additionalOutsideContent, isCurrentUserPage, participant, donationClosed, layout, showThermometer } = props,
      { id, name, avatar, settings, collected } = entity;

    const _collected = Number(collected.toString().replace('NaN', 0)) * get(settings, 'thermometer.multiplier', 1);
    const goal = get(settings, 'goal', 0);

    const computedClassName = classnames(
      'PublicThermometer',
      className,
      {'PublicThermometer--Compact': compact},
      {'PublicThermometer--Hide': !showThermometer},
      {'PublicThermometer--Horizontal': layout == "horizontal"}
    );

    const percentage = goal > 0 ? Math.floor((_collected / goal) * 100) : 0;

    const fullName = name || get(entity, 'member.name', null);

    const formattedName =
      fullName && fullName.length > 46 ? fullName.substr(0, 46) + '...' : fullName;

    const donationButton =
      this.props.isBillingConfigured && giveUrl && !donationClosed ? (
        <LinkButton to={giveUrl} type="primary" className="PublicThermometer__DonateBtn">
          {t('donate')}
        </LinkButton>
      ) : null;

    const avatarUrl = !compact
      ? avatar
        ? avatar.indexOf('http') == -1
          ? `${cdnUrl}${avatar}`
          : avatar
        : '/assets/default_participant_picture_300x300.jpg'
      : null;

    const isEditButton = !compact && isCurrentUserPage && avatarUrl ? true : false;

    const changeProfilePictureText =
      !compact && isCurrentUserPage
        ? avatar
          ? t('edit-profile-picture')
          : t('add-profile-picture')
        : null;

    const participantAdminUrl = isEditButton
      ? `/${locale}/d/contributions/participations/${id}`
      : null;

    const changeProfilePictureButton =
      changeProfilePictureText !== null && isEditButton ? (
        <Link
          to={participantAdminUrl}
          className={`ant-btn ant-btn-primary ${isEditButton ? 'edit-profile' : 'add-profile'}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {changeProfilePictureText}
        </Link>
      ) : null;

    const insideContent = !compact ? (
      <div
        className={`PublicThermometer__InsideContent ${isEditButton ? 'hasEditButton' : ''}`}
        style={{
          backgroundImage: `url('${avatarUrl}')`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
          {changeProfilePictureButton}
        </div>)
      : (<div className="PublicThermometer__InsideContent">
          {showThermometer &&
          <>
            <span className="PublicThermometer__Percentage">{`${percentage}%`}</span>
            <span className="PublicThermometer__Detail">
              {moneyFormat(_collected, locale, true)} {t('on')} {moneyFormat(goal, locale, true)}
            </span>
          </>
          }
          {donationButton}
        </div>);

    const additionalContent = additionalOutsideContent
      ? <React.Fragment>{additionalOutsideContent}</React.Fragment>
      : <h3>{formattedName}</h3>;

    const outsideContent = !compact
      ? (<div className="PublicThermometer__OutsideContent">
          {additionalContent}
          {showThermometer &&
          <>
            <span className="PublicThermometer__Percentage">{`${percentage}%`}</span>
            <span className="PublicThermometer__Detail">
              {moneyFormat(_collected, locale, true)} {t('on')} {moneyFormat(goal, locale, true)}
            </span>
          </>
          }
          {donationButton}
        </div>)
      : null;

    let thermo_markup;
    if (layout == 'circular') {
      thermo_markup = (
        <>
          {showThermometer &&
          <CircularProgressbar
            value={percentage}
          />
          }
          {insideContent}
        </>
      );
    } else if (layout == "horizontal") {
      thermo_markup = showThermometer && (
        <div className="horizontal-thermometer">
          <div className="horizontal-progress-bar">
            <div style={{ width: percentage + '%' }}></div>
          </div>
          <div className="numbers-wrapper">
            <span className="PublicThermometer__Percentage">{`${percentage}%`}</span>
            <span className="PublicThermometer__Detail">
              {moneyFormat(_collected, locale, true)} {t('on')} {moneyFormat(goal, locale, true)}
            </span>
          </div>
        </div>
      ) || null;
    }

    return (
      <div className={computedClassName}>
        <div
          className={`PublicThermometer__CircularWrapper${
            participant ? ' PublicThermometer__CircularParticipant' : ''
          }`}
        >
          {thermo_markup}
        </div>
        {outsideContent}
      </div>
    );
  }
}

PublicThermometer.defaultProps = {
  compact: false,
  className: null,
  giveUrl: null,
  participant: false,
  donationClosed: false,
  layout: 'circular', //other options: horizontal
  showThermometer: true
};

PublicThermometer.propTypes = {
  entity: PropTypes.shape({
    name: PropTypes.string,
    collected: PropTypes.number,
    goal: PropTypes.number
  }).isRequired,
  compact: PropTypes.bool,
  giveUrl: PropTypes.object,
  className: PropTypes.string,
  participant: PropTypes.bool, // Smaller circular display for participant
  donationClosed: PropTypes.bool.isRequired,
  layout: PropTypes.string
};

export default translate('PublicThermometer')(PublicThermometer);

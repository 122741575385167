import React from 'react';
import { Layout, Tooltip } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Can from 'Parts/session/components/CanContainer';
import CsvReportButton from '../../report/components/CsvReportButtonContainer';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';

import MembersListPageEN from './locales/MembersListPage_en.json';
import MembersListPageFR from './locales/MembersListPage_fr.json';

class MembersListPage extends React.Component {
  constructor(props) {
    super(props);

    props.members.total = props.pagination.totalResults;

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      },
      search: null
    };

    i18next.addResourceBundle('en', 'MembersListPage', MembersListPageEN);
    i18next.addResourceBundle('fr', 'MembersListPage', MembersListPageFR);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    // TODO Add filters
    this.props.searchMembers(
      this.props.platformId,
      this.state.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  handleViewHandler = members => {
    let currentUrl = this.props.location.pathname;
    let memberUrl = currentUrl.replace('list', members.id) + '/information';
    return this.props.history.push(memberUrl);
  };

  handleSearch = filters => {
    this.setState({
      search: filters.search
    });

    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    // TODO Add filters
    this.props.searchMembers(
      this.props.platformId,
      filters.search,
      filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  render() {
    const { t, pagination, locale, members, report, reportMembers, platformId, filters, platformAlias } = this.props;
    const { pager } = this.state;
    const CsvReportButtonDisabled = !members || members.length == 0;
    const tooltipVisibility = !CsvReportButtonDisabled ? { visible: false } : {};

    return (
      <Layout className="MembersListPage">
        <Helmet>
          <title>{t('Members')}</title>
        </Helmet>
        <SearchBar
          title={t('Members')}
          placeholder={t('Search')}
          onSearch={this.handleSearch}
          reportButton={
            <Tooltip title={t('no-list')} {...tooltipVisibility}>
              <div>
                <CsvReportButton
                  key={1}
                  data={report}
                  id={platformId}
                  pagination={pagination}
                  orderBy={{ orderBy: 'addedOn[desc]' }}
                  filters={filters}
                  fileName={`${platformAlias}.members`}
                  fetch={reportMembers}
                  disabled={CsvReportButtonDisabled ? true : null}
                />
              </div>
            </Tooltip>
          }
        />
        <Can
          rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
          redirect={`/${locale}/d`}
        >
          <div className="AdminList">
            <PaginationTop
              totalLabel={total => t('membersTotal', { count: pagination.totalResults })}
              pager={pager}
              onChange={this.handlePaginationChange}
            />
            <ResultTable
              dataSource={members}
              pagination={pager}
              onChange={this.handlePaginationChange}
              showHeader={true}
              columns={[
                simpleColumn(t('Name'), 'name', null, true),
                dateColumn(t('Registered on'), 'subscribedOn', null, null, false, locale), //, text => `${t('Registered on')} ${text}`),
                actionColumn(t('Edit'), ['fal', 'edit'], this.handleViewHandler)
              ]}
            />
          </div>
        </Can>
      </Layout>
    );
  }
}

MembersListPage.defaultProps = {
  members: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('MembersListPage')(MembersListPage);

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import MyContributionsDonationsPageForm from '../transactions/components/TransactionsPageFormContainer';
import TransactionsInvoiceList from '../transactions/components/TransactionsInvoiceListContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import AdminBackButtonBar from 'Parts/common/AdminBackButtonBar';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import MyContributionsDonationsPageEN from './locales/MyContributionsDonationsPage_en.json';
import MyContributionsDonationsPageFR from './locales/MyContributionsDonationsPage_fr.json';

class MyContributionsDonationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.from = props.location.state && props.location.state.from;

    i18next.addResourceBundle('en', 'MyContributionsDonationsPage', MyContributionsDonationsPageEN);
    i18next.addResourceBundle('fr', 'MyContributionsDonationsPage', MyContributionsDonationsPageFR);
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { state } = this.props.location;
    if (state && state.from) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }
  }

  componentWillUnmount() {
    this.props.clearTransaction();
  }

  render() {
    const { t, transaction, locale, userId, fetchTransactionFailed } = this.props;

    const contributionsListUrl = `/${locale}/d/contributions/list/donations`;
    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: props => (
          <Can
            me={true}
            memberId={
              transaction ? get(transaction, 'userId', -1) : fetchTransactionFailed ? -1 : null
            }
            userId={userId}
            redirect={`/${locale}/d`}
          >
            <MyContributionsDonationsPageForm {...this.props} />
          </Can>
        )
      },
      {
        title: t('Invoices'),
        slug: 'invoices',
        visible: get(transaction, 'subscription') ? true : false,
        container: props => (
          <Can
            me={true}
            memberId={
              transaction ? get(transaction, 'userId', -1) : fetchTransactionFailed ? -1 : null
            }
            userId={userId}
            redirect={`/${locale}/d`}
          >
            <TransactionsInvoiceList {...this.props} />
          </Can>
        )
      }
    ];

    return (
      <div className="MyContributionsDonationsPageForm">
        <Helmet>
          <title>{t('Donation')}</title>
        </Helmet>
        <AdminBackButtonBar href={contributionsListUrl} text={t('Back to search')} />
        <AdminTitleBar
          title={t('Donation')}
          subName={transaction ? t('Transaction number') + ' ' + transaction.referenceId : ''}
        />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} defaultTabHasNoSlug />
      </div>
    );
  }
}

MyContributionsDonationsPage.defaultProps = {
  transaction: {
    id: null
  }
};

MyContributionsDonationsPage.propTypes = {};

export default translate('MyContributionsDonationsPage')(MyContributionsDonationsPage);

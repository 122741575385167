import React from 'react';
import { Form, Button, Popconfirm, notification } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import SubmitButton from 'Parts/common/SubmitButton';
import CardNameField from '../components/fields/CardNameField';
import CardNumberField from '../components/fields/CardNumberField';
import CardExpirationField from '../components/fields/CardExpirationField';
import CardCvvField from './fields/CardCvvField';
import PostalCodeField from 'Parts/ui/components/fields/PostalCodeField';
import CountryField from 'Parts/ui/components/fields/CountryFieldContainer';
import {
  DONATION_CREATE_WIZARD_INFORMATION,
  DONATION_CREATE_WIZARD_CONFIRMATION,
  DONATION_CREATE_SURVEY
} from '../../ui/uiUtils';
//import WinkTooltip from '../../common/WinkTooltip';
import WinkNotification from '../../common/WinkNotification';
import StripePaymentForm from './StripePaymentFormContainer';

import CreatePaymentForm_en from './locales/CreatePaymentForm_en.json';
import CreatePaymentForm_fr from './locales/CreatePaymentForm_fr.json';

import '../../assets/images/wink@2x.png';

import './CreatePaymentForm.less';

class CreatePaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      country: null,
      disabled: false
    };

    this.token = null;

    this.monthly = props.information.frequency === 'monthly';

    this.isStripe = get(props, 'billing.stripe.chargesEnabled', false); // get(props, 'platform.actions.canReceiveSubscriptions', false)

    i18next.addResourceBundle('en', 'CreatePaymentForm', CreatePaymentForm_en);
    i18next.addResourceBundle('fr', 'CreatePaymentForm', CreatePaymentForm_fr);
  }

  componentDidMount() {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0];
    if (badge && badge.classList && !badge.classList.contains("show")) {
      badge.classList.add("show");
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isTransactionInProgress && this.props.isTransactionInProgress === null) {
      this.setState({
        disabled: false
      });
    }
  }

  componentWillUnmount() {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0];
    if (badge && badge.classList && badge.classList.contains("show")) {
      badge.classList.remove("show");
    }
  }

  getReCaptchaToken = async action => {
    return this.token = await this.props.googleReCaptchaProps.executeRecaptcha(action)
  };

  handleSubmit = event => {
    event.preventDefault();
    const { props, getReCaptchaToken } = this;
    const { t, form, information, target, processTransaction} = props;

    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        this.setState({
          disabled: true
        });

        // Set reCAPTCHA token
        getReCaptchaToken('donation').then(()=>{
          const _information = {
            ...information,
            reCAPTCHA: this.token
          };

          processTransaction(_information, values, target)
            .then(response => {
              if (get(response, 'error.code', null)) {
                this.setState({
                  disabled: false
                });
                notification.error({message: t(`ERROR:${get(response, 'error.code')}`)});
              } else if (this.props.surveyId) {
                this.props.surveyAttemptAction({surveySubmitData:this.props.surveySubmitData, transaction_id:response.payload.transaction.id}, this.props.locale);
              }
            });
        });

      }
    });
  };

  handleBackClick = event => {
    event.preventDefault();
    const { location, t} = this.props;
    const is_location = location.pathname.includes(t('URL:ticket-purchase/payment'));
    this.props.handleBackClick(this.props.surveyId ? is_location ? this.props.back_survey : DONATION_CREATE_SURVEY : is_location ? this.props.back_information : DONATION_CREATE_WIZARD_INFORMATION);
  };

  handleCancelClick = event => {
    event.preventDefault();
    this.props.handleCancelClick();
  };

  onChangeHandler = value => {
    this.setState({
      country: value
    });
  };

  dataLayer = event => {
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'custom_click',
        eventAction: 'click',
        eventCategory: 'button',
        eventLabel: 'donate',
        eventValue: 'submit'
      });
    }
  };

  render() {
    const { t, form, isTransactionInProgress, locale } = this.props;
    const infoDesc = (
      <>
        {t('enter-cc-detail')}
        <br />
        <br />
        <span className="WinkTooltip__Cc WinkTooltip__CTA">
          <FontAwesomeIcon className="cc-visa" icon={['fab', 'cc-visa']} />
          <FontAwesomeIcon className="cc-mastercard" icon={['fab', 'cc-mastercard']} />
          <FontAwesomeIcon className="cc-amex" icon={['fab', 'cc-amex']} />
          <FontAwesomeIcon className="cc-discover" icon={['fab', 'cc-discover']} />
        </span>
      </>
    );

    return (
      <div className="FundkyWrap">
        <Helmet>
          <script src="https://static.wepay.com/min/js/tokenization.3.1.3.js"></script>
        </Helmet>
        {!this.monthly && !this.isStripe &&
        <Form className="CreatePaymentForm" onSubmit={this.handleSubmit}>
          <div className="CreatePaymentForm__Form">
            <h2>{t('title')}</h2>
            <WinkNotification title={t('acceptedCards')} description={infoDesc} closable={false} />
            <p>{t('sub-title')}</p>
            <div className="CreatePaymentForm__Divider">
              <CardNameField
                className="CreatePaymentForm__Field CreatePaymentForm__Field--full"
                form={form}
              />
            </div>
            <div className="CreatePaymentForm__Divider CardNumberDateCvv">
              <CardNumberField
                className="CreatePaymentForm__Field CreatePaymentForm__Field--half"
                form={form}
              />
              <div className="CreatePaymentForm__Field CreatePaymentForm__Field--half">
                <CardExpirationField
                  className="CreatePaymentForm__Field CreatePaymentForm__Field--twoThird CreatePaymentForm__ExpirationFields"
                  form={form}
                />
                <CardCvvField
                  className="CreatePaymentForm__Field CreatePaymentForm__Field--cvv CreatePaymentForm__Field--third"
                  form={form}
                />
              </div>
            </div>
            <div className="CreatePaymentForm__Divider ZipCodeCountry">
              <CountryField
                className="CreatePaymentForm__Field CreatePaymentForm__Field--half"
                form={form}
                label={false}
                placeholder={true}
                onChangeHandler={this.onChangeHandler}
                initialValue={get(this.props, 'information.country') || get(this.props, 'information.transaction.information.country') || undefined}
              />
              <PostalCodeField
                className="CreatePaymentForm__Field CreatePaymentForm__Field--half"
                form={form}
                placeholder={true}
                requiredMessage={t('postalCodeRequire')}
                country={this.state.country}
                initialValue={get(this.props, 'information.postalCode') || get(this.props, 'information.transaction.information.postalCode') || undefined}
              />
            </div>
            <div className="CreatePaymentForm__SubmitWrapper">
              <Button className="Back-btn" onClick={this.handleBackClick} disabled={isTransactionInProgress ? true : false}>
                {t('back')}
              </Button>
              <SubmitButton loading={isTransactionInProgress !== null} dataLayer={this.dataLayer} disabled={this.state.disabled}>
                {isTransactionInProgress !== null &&
                  <CircularProgressbar value={isTransactionInProgress} />
                }{t('pay')}
              </SubmitButton>
            </div>
            <div className="CreatePaymentForm__CancelWrapper">
              <Popconfirm
              	placement="topLeft"
              	getPopupContainer={() => document.getElementById('popContainer3')}
              	title={t('TRANSACTION:cancelText')}
              	onConfirm={this.handleCancelClick}
              	okText={t('TRANSACTION:yes')}
              	cancelText={t('TRANSACTION:no')}
              >
                <span id="popContainer3">
                  {t('cancel')}
                </span>
              </Popconfirm>
            </div>
          </div>
          {/*<WinkTooltip
            title={t('acceptedCards')}
            details={t('enter-cc-detail')}
            elements={(
              <span className="WinkTooltip__Cc WinkTooltip__CTA">
                <FontAwesomeIcon className="cc-visa" icon={["fab", "cc-visa"]} />
                <FontAwesomeIcon className="cc-mastercard" icon={["fab", "cc-mastercard"]} />
                <FontAwesomeIcon className="cc-amex" icon={["fab", "cc-amex"]} />
                <FontAwesomeIcon className="cc-discover" icon={["fab", "cc-discover"]} />
              </span>
            )}
            display={true}
            />*/}
        </Form> ||
        <StripePaymentForm form={form} target={this.props.target} information={this.props.information} handleBackClick={this.handleBackClick} handleCancelClick={this.handleCancelClick} googleReCaptchaProps={this.props.googleReCaptchaProps} processTransaction={this.props.processTransaction} cancelTransaction={this.props.cancelTransaction}/>
        }
      </div>
    );
  }
}

CreatePaymentForm.propTypes = {
  form: PropTypes.object.isRequired,
  processTransaction: PropTypes.func.isRequired,
  information: PropTypes.object.isRequired,
  isTransactionInProgress: PropTypes.number,
  platformId: PropTypes.number.isRequired,
  target: PropTypes.shape({
    targetId: PropTypes.number.isRequired,
    targetType: PropTypes.oneOf(['organisation', 'campaign', 'team', 'participant']).isRequired
  }).isRequired,
  locale: PropTypes.oneOf(['en', 'fr']).isRequired,
  browser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired
  }).isRequired
};

export default translate('CreatePaymentForm')(CreatePaymentForm);

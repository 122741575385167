import {
  bindActionCreators,
  compose
} from "redux";
import {
  connect
} from "react-redux";
import {
  withRouter
} from 'react-router';

import dataFetcher from "../../../parts/chore/dataFetcher";
import {
  clearCampaign
} from '../../../campaign/campaignActions';
import {
  checkAuthorization
} from 'Parts/common/authorizationUtils';

import CampaignAdminPage from './CampaignAdminPage';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  campaign: state.campaign.campaign,
  campaigns: state.campaign.campaigns,
  locale: state.session.language,
  userId: state.session.userId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearCampaign,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(CampaignAdminPage);

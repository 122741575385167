export const PAGESIZE_OPTIONS = [10, 25, 50];
export const SEARCHPAGESIZE_OPTIONS = [12, 24, 48];

export const DEFAULT_PAGER = {
  current: 1,
  pageSize: PAGESIZE_OPTIONS[0]
};

export const DEFAULT_COMPACT_PAGER = {
  current: 1,
  pageSize: 5
};

export const DEFAULT_COMPACT_PAGER_NO_ADD = {
  current: 1,
  pageSize: 6
};

export const DEFAULT_COMPACT_PAGER_NO_ADD_SEARCH_PAGE = {
  current: 1,
  pageSize: 8
};

export const DEFAULT_PAGER_SEARCH_PAGE = {
  current: 1,
  pageSize: SEARCHPAGESIZE_OPTIONS[0]
};

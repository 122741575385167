import React from 'react';
import PropTypes from 'prop-types';

import './StaticEmbedMap.less';

class StaticEmbedMap extends React.Component {
  render() {
    return (
      <div className={`StaticEmbedMap ${this.props.className}`}>
        <div className="StaticEmbedMap__Container">
          <iframe
            id="iframeMap"
            ref="iframeMap"
            className="StaticEmbedMap__Iframe"
            src={this.props.map}
          ></iframe>
        </div>
      </div>
    );
  }
}

StaticEmbedMap.defaultProps = {
  map:
    'https://maps.google.com/maps?q=135-G%2C%20boul.de%20Mortagne%2C%20Boucherville%2C%20Qu%C3%A9bec%2C%20J4B%206G4&t=&z=15&ie=UTF8&iwloc=&output=embed'
};

StaticEmbedMap.propTypes = {
  map: PropTypes.string
};

export default StaticEmbedMap;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import PublicDonationQuote from '../../common/PublicDonationQuote';
import PublicCarousel from '../../common/PublicCarousel';

import DonationSection_en from './locales/DonationSection_en.json';
import DonationSection_fr from './locales/DonationSection_fr.json';

import './DonationSection.less';

class DonationSection extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'DonationSection', DonationSection_en);
    i18next.addResourceBundle('fr', 'DonationSection', DonationSection_fr);
  }
  render() {
    const { t, locale } = this.props;

    const donations = this.props.donations &&
      this.props.donations
        .map(donation => (
          <PublicDonationQuote
            key={donation.id}
            className="DonationSection__DonationQuote"
            donation={donation}
            bigQuote
            locale={locale}
          />
        ));

    const carousel = donations && donations.length > 0 ? (
      <PublicCarousel
        className="DonationSection__Carousel"
        slidesToShow={this.props.slidesToShow}
        sideControls
        delayInit
      >
        {donations}
      </PublicCarousel>
    ) : (
      <p>{t('give-now')}</p>
    );

    return (
      <div className="DonationSection">
        <div className="FundkyWrap">
          <div className="DonationSection__Information">
            <div>
              <h2>{t('last-donations-received')}</h2>
              <Divider />
            </div>
            {this.props.isBillingConfigured &&
              <Link
                to={
                  (
                    (
                      this.props.platform &&
                      this.props.platform.settings &&
                      this.props.platform.settings.campaign &&
                      Number(this.props.platform.settings.campaign.enabled)
                    ) || (
                      this.props.lastCampaigns &&
                      this.props.lastCampaigns.length > 0
                    )
                  ) ? (
                    `/${locale}/${t('URL:give')}`
                  ) : (
                    `/${locale}/${t('URL:organization/give')}`
                  )
                }
                className="DonationSection__DonationBtn ant-btn ant-btn-lg ant-btn-primary"
              >{t('make-a-donation')}</Link> ||
              null
            }
          </div>
          {carousel}
        </div>
      </div>
    );
  }
}

PropTypes.propTypes = {
  slidesToShow: PropTypes.number.isRequired,
  donations: PropTypes.array.isRequired
};

export default translate('DonationSection')(DonationSection);

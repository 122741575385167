import React, { Component } from 'react';
import { Button } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get, isEmpty } from 'lodash';

import LinkButton from 'Parts/common/LinkButton';
//import WinkTooltip from '../../common/WinkTooltip';
import WinkNotification from '../../common/WinkNotification';
import SocialSharing from '../../common/SocialSharingContainer';
import Plugin from '../../plugin/Plugin';

import { moneyFormat } from 'Parts/common/moneyFormat';
import { isPluginActive } from '../../plugin/pluginActions';

import CreateConfirmationPanel_en from './locales/CreateConfirmationPanel_en.json';
import CreateConfirmationPanel_fr from './locales/CreateConfirmationPanel_fr.json';

import './CreateConfirmationPanel.less';

class CreateConfirmationPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clearTaxReceiptDonation: () => {},
      clearTaxReceiptDonationImported: false
    };

    this.pluginRef = React.createRef();

    i18next.addResourceBundle('en', 'CreateConfirmationPanel', CreateConfirmationPanel_en);
    i18next.addResourceBundle('fr', 'CreateConfirmationPanel', CreateConfirmationPanel_fr);
  }

  componentDidMount() {
    if (
      isPluginActive(this.props.platform, 'TaxReceipt') &&
      this.pluginRef.current &&
      this.pluginRef.current.actions &&
      this.pluginRef.current.actions.clearTaxReceiptDonation
    ) {
      this.setState({
        clearTaxReceiptDonation: this.pluginRef.current.actions.clearTaxReceiptDonation,
        clearTaxReceiptDonationImported: true
      });
    }
  }

  componentDidUpdate() {
    if (
      !this.state.clearTaxReceiptDonationImported &&
      isPluginActive(this.props.platform, 'TaxReceipt') &&
      this.pluginRef.current &&
      this.pluginRef.current.actions &&
      this.pluginRef.current.actions.clearTaxReceiptDonation
    ) {
      this.setState({
        clearTaxReceiptDonation: this.pluginRef.current.actions.clearTaxReceiptDonation,
        clearTaxReceiptDonationImported: true
      });
    }
  }

  componentWillUnmount() {
    //this.props.clearDonation();
    if (this.state.clearTaxReceiptDonation) this.state.clearTaxReceiptDonation();

    const { history, location, i18n } = this.props;
    const nextLocation = (history && history.location && history.location.pathname) || null;

    if (nextLocation === location.pathname) {
      history.replace(`/${i18n.language}`);
    }
  }

  getCcIcon(cc) {
    switch (cc.toLowerCase()) {
      case 'visa':
        return ['fab', 'cc-visa'];
      case 'mastercard':
        return ['fab', 'cc-mastercard'];
      case 'american-express':
      case 'americanexpress':
        return ['fab', 'cc-amex'];
      case 'diners-club':
        return ['fab', 'cc-diners-club'];
      case 'discover':
        return ['fab', 'cc-discover'];
      case 'jcb':
        return ['fab', 'cc-jcb'];
      case 'unionpay':
      case 'maestro':
      case 'mir':
      default:
        return ['fas', 'credit-card-front'];
    }
  }

  getCountryName(id, countries, locale = 'en') {
    for (var i = 0; i < countries.length; i++) {
      if (countries[i].id === id) {
        return countries[i][`name_${locale}`];
      }
    }
  }

  formatTransactionType(transctionType, donationType, t) {
    if (transctionType === 3) {
      return t('type.tickets');
    } else {
      return t('type.' + donationType);
    }
  }

  formatDate(date) {
    if (!date) {
      return '';
    } else {
      return moment(date).format('LLL');
    }
  }

  printConfirmation = () => {
    const { t, organization } = this.props;
    let tempTitle = document.title; // Grab the current title
    document.title = `${organization.name} ${t('donation-confirmation')}.pdf`; // Set custom title for print
    window.print(); // Print
    document.title = tempTitle; // Put back original title
  };

  render() {
    const { t, platform, information, checkout, countries, locale, userTypeId, cancel, tickets, campaign } = this.props;

    let icon = [];
    let iconClass = '';
    let title = '';
    let desc = '';
    let transaction = null;
    let ticketsList = null;
    let checkoutUrl = null;
    let _checkout = true;

    if (
      isEmpty(get(checkout, 'checkout', {})) &&
      isEmpty(get(checkout, 'donation', {})) &&
      isEmpty(get(checkout, 'transaction', {}))
    ) {
      _checkout = false;
    }

    if (!cancel && _checkout) {
      const company = get(checkout, 'donation.organizationName', get(checkout, 'transaction.information.organizationName', get(information, 'transaction.information.organizationName')))
        ? <li>{get(checkout, 'donation.organizationName', get(checkout, 'transaction.information.organizationName', get(information, 'transaction.information.organizationName')))}</li>
        : null;
      const name = `${get(checkout, 'donation.firstName', get(checkout, 'transaction.information.firstName', ''))} ${get(checkout, 'donation.lastName', get(checkout, 'transaction.information.lastName', ''))}`;
      const country = this.getCountryName(get(checkout, 'donation.country', get(checkout, 'transaction.information.country', '')), countries) || '';
      const postalcode = get(checkout, 'donation.postalCode', get(checkout, 'transaction.information.postalCode', ''));
      const email = get(checkout, 'donation.email', get(checkout, 'transaction.information.email', ''));

      const transactionId = get(checkout, 'checkout.transactionId', get(checkout, 'transaction.referenceId'));
      const recipientName = get(checkout, 'donation.target.name', get(campaign, 'name', ''));
      const transactionType = this.formatTransactionType(get(checkout, 'transaction.typeId'), get(checkout, 'donation.typeId'), t) + (get(information, 'donationTypeId') ? " " +  this.formatTransactionType(null, get(information, 'donationTypeId'), t): "");
      const transactionDate = this.formatDate(get(checkout, 'checkout.transactionDate', get(checkout, 'transaction.addedOn')));

      const cc = get(checkout, 'checkout.creditCardName', null);
      const ccName = cc && cc.split(' xxx')[0] || '';
      const ccIcon = this.getCcIcon(ccName) || '';
      const ccNumber = cc && cc.slice(-4) || '';
      let amount = parseFloat(get(checkout, 'checkout.amount', 0)) || parseFloat(get(checkout, 'transaction.amount', 0)) || parseFloat(get(information, 'amount', 0));
      amount = moneyFormat(amount, locale, false, get(checkout, 'checkout.currency', get(checkout, 'transaction.currency'))) || '';

      const transactionStatus = get(checkout, 'checkout.transactionStatus', get(checkout, 'transaction.status'));

      // MONTHLY constants
      const isMonthly = get(information, 'frequency') === 'monthly';
      const when = get(information, 'when', 1);

      const TaxReceiptConfirmationMessage =(
        isPluginActive(platform, 'TaxReceipt') &&
        get(information, 'taxReceipt') && (
          <Plugin name="TaxReceipt" component="ConfirmationMessage" ref={this.pluginRef} />
        )) ||
        null;

      const TaxReceiptBillingInformation = (
        isPluginActive(platform, 'TaxReceipt') &&
        get(information, 'taxReceipt') && (
          <Plugin
            name="TaxReceipt"
            component="BillingInformation"
            {...{
              // Props
              address: get(information, 'address', get(checkout, 'transaction.information.address', '')),
              unit: get(information, 'unit', get(checkout, 'transaction.information.unit', '')),
              city: get(information, 'city', get(checkout, 'transaction.information.city', '')),
              province: get(information, 'province', get(checkout, 'transaction.information.province', ''))
            }}
          />
        )) ||
        null;

      icon = transactionStatus === 'APPROVED'
        ? ['fal', 'check-circle']
        : ['fal', 'times-circle'];
      iconClass = transactionStatus === 'APPROVED'
        ? 'CreateConfirmationPanel__SuccessIcon'
        : 'CreateConfirmationPanel__ErrorIcon';
      title = transactionStatus === 'APPROVED'
        ? t('title-success')
        : t('title-error');
      desc = transactionStatus === 'APPROVED'
        ? <>
            {t('thank-you', { name: name })}
            {TaxReceiptConfirmationMessage}
          </>
        : t('sorry');

      // checkout.donation.target.url always contains the english url
      checkoutUrl = get(checkout, 'donation.target.url')
        ? get(checkout, 'donation.target.url').replace('/en/', `/${locale}/`)
        : get(campaign, 'alias')
          ? `/${locale}/${get(campaign, 'alias')}`
          : "";

      const seeContributionButtonLink = get(checkout, 'donation.target.type') !== 'platform'
        ? checkoutUrl.split('.com').pop()
        : null;

      const seeContributionButton = seeContributionButtonLink
        ? <LinkButton
            to={seeContributionButtonLink}
            size="large"
            className="CreateConfirmationPanel__DarkButton"
          >
            {t('my-donation')}
          </LinkButton>
        : null;

      ticketsList = tickets && isPluginActive(platform, 'Event')
        ? <Plugin
            name="Event"
            component="ConfirmationTicketList"
            {...{
              // Props

            }}
          />
        : null;

      transaction = transactionStatus === 'APPROVED'
        ? <div>
            <div className="CreateConfirmationPanel__InformationBlocks">
              <div className="CreateConfirmationPanel__InformationBlock">
                <h3>{t('billing')}</h3>
                <ul>
                  {company}
                  <li>{name}</li>
                  {TaxReceiptBillingInformation}
                  <li>
                    {country}, {postalcode}
                  </li>
                  <li>{email}</li>
                </ul>
              </div>

              <div className="CreateConfirmationPanel__InformationBlock PaymentBlock">
                <h3>{t('payment')}</h3>
                <ul>
                  <li>
                    {cc &&
                    (<>
                      <FontAwesomeIcon icon={ccIcon} className="CreateConfirmationPanel__CcIcon" />
                      {` ${ccName} (${ccNumber}) ― ${amount}`}
                    </>) ||
                    isMonthly &&
                    `${t('Monthly')} ${amount} ${t('every', {count: when})}` ||
                    amount ||
                    null}
                  </li>
                </ul>
              </div>

              <div className="CreateConfirmationPanel__InformationBlock TransactionInformation">
                <h3>{t('transaction')}</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <label>{t('label.transaction')}</label>
                      </td>
                      <td>{transactionId}</td>
                    </tr>
                    <tr>
                      <td>
                        <label>{t('label.recipient')}</label>
                      </td>
                      <td>{recipientName}</td>
                    </tr>
                    <tr>
                      <td>
                        <label>{t('label.type')}</label>
                      </td>
                      <td>{transactionType}</td>
                    </tr>
                    <tr>
                      <td>
                        <label>{t('label.date')}</label>
                      </td>
                      <td>{transactionDate}</td>
                    </tr>
                  </tbody>
                  {ticketsList}
                </table>
              </div>
            </div>
            <p className="CreateConfirmationPanel__NoTaxes">{t('no-taxes')}</p>
            <div className="CreateConfirmationPanel__Divider CreateConfirmationPanel__Divider--CTA">
              {seeContributionButton}
              <Button size="large" type="primary" onClick={this.printConfirmation}>
                {t('print')}
              </Button>
            </div>
          </div>
        : null;
    } else {
      icon = ['fal', 'times-circle'];
      iconClass = 'CreateConfirmationPanel__ErrorIcon';
      if (cancel) {
        title = t('title-cancel');
        desc = t('cancel');
      } else {
        title = t('title-error');
        desc = t('error');
      }

    }

    const socialSharingIdentity = _checkout
      ? get(checkout, 'target.type') === 'platform'
        ? 'general-donation'
        : 'donation'
      : null;

    const createAccount = _checkout
      ? userTypeId === 1
        ? <div className="wink-create-account">
            <p>
              {t('create-account-description-1')} <strong>{t('create-account-description-2')}</strong>{' '}
              {t('create-account-description-3')}
            </p>
            <Button onClick={() => this.props.openRegisterModal()}>{t('create-account-cta')}</Button>
          </div>
        : null
      : null;

    const infoDesc = (
      <>
        <p>{t('share-donation')}</p>
        <SocialSharing
          identity={socialSharingIdentity}
          urlToShare={checkoutUrl}
          showTitle={false}
          checkoutTarget={get(checkout, 'donation.target')}
        />
        {createAccount}
      </>
    );

    let type = this.props.type;
    const entity = this.props.entity;
    let backButtonLink = null;
    if (type == "campaign" && locale && entity.alias) {
      backButtonLink = `/${locale}/${entity.alias}`;
      if (entity.behavior == "EVENT"){
        type = 'event'
      }
    } else
    if (type == "team" && locale && entity.campaignAlias && entity.alias) {
      backButtonLink = `/${locale}/${entity.campaignAlias}/${entity.alias}`;
    } else
    if (type == "participant" && locale && entity.campaignAlias && entity.teamAlias &&
      (entity.alias || entity.member && entity.member.alias)
    ) {
      backButtonLink = `/${locale}/${entity.campaignAlias}/${entity.teamAlias}/${entity.alias || entity.member && entity.member.alias}`;
    } else {
      backButtonLink = `/${locale}`;
      type = 'organization';
    }


    const backButton = backButtonLink && (
      <LinkButton
        to={backButtonLink}
        size="large"
        className="CreateConfirmationPanel__DarkButton"
      >
        {t('back-to-'+ type)}
      </LinkButton>
    ) || null;

    return (
      <div className="FundkyWrap">
        <div className="CreateConfirmationPanel">
          <FontAwesomeIcon icon={icon} className={iconClass} />
          <h2>{title}</h2>
          {get(checkout, 'checkout.transactionStatus', get(checkout, 'transaction.status')) === 'APPROVED' && (
            <WinkNotification title={t('what-now')} description={infoDesc} descriptionTag="div" closable={false} />
          )}
          <p>{desc}</p>
          {transaction}
          {backButton}
          {/*(get(checkout, 'checkout.transactionStatus', get(checkout, 'transaction.status')) === "APPROVED") &&
            <WinkTooltip
              title={t('what-now')}
              details={t('share-donation')}
              elements={(
                <>
                  <SocialSharing
                    identity={socialSharingIdentity}
                    urlToShare={checkoutUrl}
                    showTitle={false}
                    checkoutTarget={get(checkout, 'donation.target')}
                  />
                  {createAccount}
                </>
              )}
              display={true}
            />
          */}
        </div>
      </div>
    );
  }
}

export default translate('CreateConfirmationPanel')(CreateConfirmationPanel);

import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { get } from 'lodash';


import dataFetcher from "../../parts/chore/dataFetcher";
import { searchMembers, clearSearch } from '../../search/searchActions';
import { reportMembers } from '../../report/reportActions';

import MembersListPage from './MembersListPage';

const mapStateToProps = state => ({
  platformId: get(state, 'platform.platform.id'),
  platformAlias: get(state, 'platform.platform.alias'),
  members: get(state, 'search.members.results', []),
  pagination: get(state, 'search.members.pagination', {}),
  filters: get(state, 'search.members.filters'),
  orderBy: get(state, 'search.members.orderBy'),
  locale: get(state, 'session.language'),
  report: get(state, 'report.members')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchMembers,
  reportMembers,
  clearSearch
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(MembersListPage);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { get } from 'lodash';

import { getDefaultLanguageId } from 'Parts/common/langUtils';
import { fetchPlatform, updatePlatformSettings, updatePlatformMedia, deletePlatformMedia } from '../../../platform/platformActions';

import SocialMediasSettingsForm from './SocialMediasSettingsForm';

const mapStateToProps = state => ({
  socials: state.platform.platform.settings.social,
  platform: state.platform.platform,
  platformId: state.platform.platform.id,
  languages: state.platform.languages,
  isUpdatePlatformInProgress: state.ui.isUpdatePlatformInProgress,
  shareImage: get(state.platform.platform, 'media.thumbnail', undefined),
  locale: state.session.language,
  defaultLanguageId: getDefaultLanguageId(get(state, 'platform.languages', {}))
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPlatform,
  updatePlatformSettings,
  updatePlatformMedia,
  deletePlatformMedia
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(SocialMediasSettingsForm);

import React from 'react';
import { Form } from 'antd';
import { isEmpty, pickBy } from 'lodash';

import CampaignThirdPartyField from './fields/CampaignThirdPartyField';
import PreapprovedCampaignThirdPartyField from './fields/PreapprovedCampaignThirdPartyField';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';

import './CampaignSettingsForm.less';

class CampaignSettingsForm extends React.Component {

  handleSubmit = event => {
    event.preventDefault();

    const
      { props } = this,
      { form, platformId, updatePlatformSettings } = props,
      { validateFields, isFieldTouched } = form;

    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (!isEmpty(updatedValues)) {
          const formatedValues = {
            campaign: {
              creation: {
                enabled: updatedValues.thirdPartyCampaigns,
                preauth: updatedValues.preapprovedCampaign
              }
            }
          };

          updatePlatformSettings(platformId, formatedValues);
        }
      }
    });
  }

  render() {
    const
      { props, handleSubmit } = this,
      { form, thirdPartyCampaigns, preapprovedCampaign } = props;

    return (
      <Form className="CampaignSettingsForm" onSubmit={handleSubmit}>
        <div className="inputs">
          <CampaignThirdPartyField
            className="CampaignThirdPartys__Field"
            form={form}
            checked={thirdPartyCampaigns}
          />
          <PreapprovedCampaignThirdPartyField
            className="PreapprovedCampaignThirdPartys__Field"
            form={form}
            checked={preapprovedCampaign}
          />
        </div>
        <CancelAndSaveButtons />
      </Form>
    );
  }

}

export default CampaignSettingsForm;

import isBrowser from 'is-in-browser';
import { get } from 'lodash';

import { stores } from '../chore/configureStore';
import { logout, saveID, updateSessionState } from './sessionActions';
import { readSessionDate } from '../common/cookieUtils';

export function getRoleIdFromRoles(roles) {
  const roleKey = Object.keys(roles) || null;
  if (roleKey.length) {
    switch (roleKey[0]) {
      case 'SUPERADMIN':
        return 1;

      case 'MEMBER':
        return 2;

      case 'ADMINISTRATOR':
        return 3;

      case 'OWNER':
        return 4;

      case 'COLLABORATOR':
        return 5;

      case 'EDITOR':
        return 6;

      case 'ACCOUNTANT':
        return 7;

      case 'MODERATOR':
        return 8;

      default:
        return null;
    }
  }
}

export function getSessionId() {
  if (isBrowser) {
    const state = (stores && stores[0] && stores[0].getState) && stores[0].getState() || null;
    return get(state, 'session.token', null);
  }
  return null;
}

export function getSessionIdDate() {
  if (isBrowser) {
    const state = (stores && stores[0] && stores[0].getState) && stores[0].getState() || null;
    return get(state, 'session.tokenDate', null);
  }
  return null;
}

export function getSessionIp() {
  return (dispatch, getState) => {
    return { 'x-forwarded-for': getState().session.ip } || {};
  }
}

export function checkSessionExpiration() {
  if (isBrowser) {
    const session = stores[0].getState().session;
    const sessionTime = readSessionDate();// get(session, 'sessionTimeout.lastApiCallTime', null);
    const isLoggingOutInProgress = session.isLoggingOutInProgress;
    const userTypeId = session.userTypeId;
    const _30minutes = 1000 /* milliseconds */ * 60 /* seconds */ * 30 /* minutes */;
    const now = new Date().getTime();

    // If member (logged in), dispatch logout action since session is expired
    if (
      userTypeId === 2 &&
      sessionTime &&
      !isLoggingOutInProgress &&
      now - sessionTime > _30minutes
    ) {
      stores[0].dispatch(logout());
    }
  }
}

export function checkSessionId(apiSessionId) {
  if(isBrowser) {
    const session = stores[0].getState().session;
    const sessionId = session.token;
    const isConnectInProgress = session.isConnectInProgress;
    const isLoginInProgress = session.isLoginInProgress;

    // If guest member, connect() to generate new cookie token
    if (
      !isConnectInProgress &&
      //!isLoginInProgress &&
      sessionId !== apiSessionId
    ) {
      stores[0].dispatch(saveID(apiSessionId));
      stores[0].dispatch(updateSessionState());
    }
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import FundkyButton from 'Parts/ui/components/FundkyButton';
import AvatarThumbnail from '../../common/AvatarThumbnailContainer';

import PublicCaptainQuote_en from './locales/PublicCaptainQuote_en.json';
import PublicCaptainQuote_fr from './locales/PublicCaptainQuote_fr.json';

import './PublicCaptainQuote.less';

class PublicCaptainQuote extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicCaptainQuote', PublicCaptainQuote_en);
    i18next.addResourceBundle('fr', 'PublicCaptainQuote', PublicCaptainQuote_fr);
  }

  getFontSizeFitted = text => {
    // Remove TAG for count
    const regex = /(<([^>]+)>)/gi;
    const result = text.replace(regex, '');

    if (result.length < 25) {
      return ' smallQuote';
    } else if (result.length < 70) {
      return ' mediumQuote';
    } else {
      return ' largeQuote';
    }
  };

  render() {
    const { props, getFontSizeFitted } = this,
      { t, captain, pathname, description, locale, userId, campaign, teamId } = props;

    const captainIcon = <FontAwesomeIcon icon={['fas', 'star']} />;
    const name = captain ? <span>{get(captain, 'member.name', '')}</span> : <span />;
    const captainAvatar = captain ? (
      <AvatarThumbnail
        className="PublicCaptainQuote__Captain"
        entity={captain}
        primaryContent={
          <span>
            {t('captain')} {captainIcon}
          </span>
        }
        secondaryContent={name}
        captain
        popover
        url={`${pathname}/${get(captain, 'member.alias', '')}`}
      />
    ) : null;

    let text = null,
      arrow = null;

    const descriptionText = get(description, 'description') || null;

    if (descriptionText) {
      const inlineStyle = getFontSizeFitted(descriptionText);

      if (
        (Array.isArray(descriptionText) && typeof descriptionText[0] === 'string') ||
        (typeof descriptionText === 'string' && descriptionText.indexOf('<') === -1)
      ) {
        text = (
          <div className={`PublicCaptainQuote__Message wysiwygStyle${inlineStyle}`}>
            <div>
              <p>{descriptionText}</p>
            </div>
          </div>
        );
      } else {
        text = (
          <div className={`PublicCaptainQuote__Message wysiwygStyle${inlineStyle}`}>
            <div>{ReactHtmlParser(descriptionText)}</div>
          </div>
        );
      }
    } else {
      if (userId === captain.member.id) {
        text = (
          <div className={`PublicCaptainQuote__Message`}>
            <FundkyButton
              text={t('add-message')}
              type="primary"
              link={{
                url: `/${locale}/d/contributions/teams/${teamId}`,
                target: '_blank'
              }}
            />
          </div>
        );
      }
    }

    if (text) {
      arrow = (
        <svg className="PublicCaptainQuote__Arrow" viewBox="0 0 38 22">
          <path d="M38 22 L32 0 L0 0 Z" />
        </svg>
      );
    }

    return (
      <div className="PublicCaptainQuote">
        {text}
        <div className="PublicCaptainQuote__Info">
          {arrow}
          {captainAvatar}
        </div>
      </div>
    );
  }
}

PublicCaptainQuote.propTypes = {
  captain: PropTypes.object
};

export default translate('PublicCaptainQuote')(PublicCaptainQuote);

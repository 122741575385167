import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import NewsletterField_en from './locales/NewsletterField_en.json';
import NewsletterField_fr from './locales/NewsletterField_fr.json';

class NewsletterField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'NewsletterField', NewsletterField_en);
    i18next.addResourceBundle('fr', 'NewsletterField', NewsletterField_fr);
  }

  render() {
    const { t, form, className, savedValue, platform, organization } = this.props;
    const { getFieldDecorator } = form;

    const referent = (platform && platform.settings && platform.settings.communication) && platform.settings.communication.referent || '';
    let referentName = platform && platform.name || '';
    if(referent === 'organization') {
      referentName = organization && organization.name || '';
    }

    const input = <Checkbox>{t('label', {referent: referentName})}</Checkbox>;
    const fieldId = 'bulletinOptin';
    const fieldOptions = {
      normalize: value => (value || false), // Return false instead of undefined
      valuePropName: 'checked',
      initialValue: savedValue
    };

    return (
      <Form.Item className={className}>
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

NewsletterField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('NewsletterField')(NewsletterField);

import { connect } from "react-redux";

import QuickLinks from './QuickLinks';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  userId: state.session.userId
});

export default connect(mapStateToProps)(QuickLinks);

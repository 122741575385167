import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

//import { processTransaction, cancelTransaction } from "../donationActions";
import { DONATION_CREATE_WIZARD_INFORMATION } from '../../ui/uiUtils';
import {
  surveyAttemptAction,
  surveyAttemptSubmitAction,
  surveyAttemptStoreAction,
} from './SurveyAttemptAction';
import { getDonationTarget } from '../donationSelectors';

import StripePaymentForm from './StripePaymentForm';

const mapStateToProps = (state, ownProps) => ({
  information:
    ownProps.information ||
    get(state, 'donation.donationInformation') ||
    get(state, 'Event.purchaseInformation'),
  target: getDonationTarget(state) || ownProps.target,
  platformId: state.platform.platform.id,
  platform: state.platform.platform,
  locale: state.session.language,
  browser: state.session.browser,
  isTransactionInProgress: state.ui.isTransactionInProgress,
  countries: state.geo.countries,
  back: DONATION_CREATE_WIZARD_INFORMATION,
  stripeAccount: get(state, 'organization.organization.billing.stripe.accountId'),
  surveySubmitData: state.surveyAttempt.surveySubmitData,
  surveyId: state.donation.surveyId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      surveyAttemptAction,
      surveyAttemptSubmitAction,
      surveyAttemptStoreAction,
      //processTransaction,
      //cancelTransaction
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(StripePaymentForm);

export function getAntdBreakpoint(width) {
  if (width === null) {
    // Default breakpoint for SSR
    // TODO Maybe choose an more precise breakpoint based on the user-agent
    return 'xl';
  } else if (width >= 1600) {
    return 'xxl';
  } else if (width >= 1200) {
    return 'xl';
  } else if (width >= 992) {
    return 'lg';
  } else if (width >= 768) {
    return 'md';
  } else if (width >= 576) {
    return 'sm';
  } else if (width >= 480) {
    return 'xs';
  } else if (width < 480) {
    return 'xxs';
  } else {
    throw new Error(`"width" must be an number or null (SSR). Received: ${width}`)
  }
}

import { compose } from "redux";
import { connect } from "react-redux";

import PublicTitleDropdown from './PublicTitleDropdown';

const mapStateToProps = state => ({
  colors: state.platform.platform.settings.color
});

export default compose(
  connect(mapStateToProps),
)(PublicTitleDropdown);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import CardNumberField_en from './locales/CardNumberField_en.json';
import CardNumberField_fr from './locales/CardNumberField_fr.json';

class CardNumberField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CardNumberField', CardNumberField_en);
    i18next.addResourceBundle('fr', 'CardNumberField', CardNumberField_fr);
  }

  render() {
    const { t, form, className } = this.props;
    const { getFieldDecorator } = form;

    const input = <Input placeholder={t('placeholder')} />;
    const fieldId = 'cardNumber';
    const fieldOptions = {
      rules: [
          {
            required: true,
            message: t('require')
          },
          {
            validator: (rule, value, cb) => (value && value.length >= 13 ? cb() : cb(true)),
            message: t('tooShort')
          },
          {
            validator: (rule, value, cb) => (value && value.length <= 19 ? cb() : cb(true)),
            message: t('tooLong')
          }
        ],
    };

    return (
      <Form.Item className={className} hasFeedback>
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

CardNumberField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('CardNumberField')(CardNumberField);

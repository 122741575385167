import React from 'react';
import { Form } from 'antd';
import i18next from 'i18next';
import { pickBy } from 'lodash';
import { translate } from 'react-i18next';

import SelectField from 'Parts/ui/components/fields/SelectField';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';

import {
  getLanguagesKeys,
  getDefaultLanguageKey,
  getLanguageIdByKey
} from 'Parts/common/langUtils';

import LanguagesSettingsForm_en from './locales/LanguagesSettingsForm_en.json';
import LanguagesSettingsForm_fr from './locales/LanguagesSettingsForm_fr.json';

class LanguagesSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.defaultLangOptions = getLanguagesKeys().map(languageKey => ({
      value: languageKey,
      text: props.t(`LANGUAGES:${languageKey}`)
    }));

    i18next.addResourceBundle('en', 'LanguagesSettingsForm', LanguagesSettingsForm_en);
    i18next.addResourceBundle('fr', 'LanguagesSettingsForm', LanguagesSettingsForm_fr);
  }

  handleSubmit = event => {
    event.preventDefault();

    const { validateFields, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (updatedValues.defaultLang) {
          const formatedValues = this.defaultLangOptions.map(lang => ({
            languageId: getLanguageIdByKey(lang.value),
            default: lang.value === updatedValues.defaultLang
          }));

          this.props.updatePlatformLanguages(this.props.platform.id, formatedValues);
        }
      }
    });
  };

  handleCancel = () => {
    this.props.form.resetFields();
  };

  render() {
    const { t, form, locale, languages } = this.props;

    const defaultLang = getDefaultLanguageKey(languages) || locale;

    return (
      <Form className="LanguagesSettingsForm" onSubmit={this.handleSubmit}>
        <div className="inputs">
          <SelectField
            form={form}
            fieldId="defaultLang"
            label={t('defaultLang-label')}
            optionsArray={this.defaultLangOptions}
            initialValue={defaultLang}
          />
        </div>
        <CancelAndSaveButtons
          onClickCancel={this.handleCancel}
          isSavingInProgress={this.props.isUpdatePlatformInProgress}
        />
      </Form>
    );
  }
}

export default translate('LanguagesSettingsForm')(LanguagesSettingsForm);

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import PublicHeader from './PublicHeader';
import { logout, setSessionLanguage } from "../../parts/session/sessionActions";
import { openLoginModal, openRegisterModal } from "../../parts/ui/uiActions";

const mapStateToProps = state => ({
  organization: get(state, 'organization.organization'),
  user: get(state, 'session.user'),
  userTypeId: get(state, 'session.userTypeId'),
  platform: get(state, 'platform.platform'),
  locale: get(state, 'session.language'),
  platformSocial: get(state, 'platform.platform.settings.social'),
  campaignAlias: get(state, 'campaign.campaign.alias'),
  teamAlias: get(state, 'team.team.alias'),
  memberAlias: get(state, 'participant.participant.member.alias'),
  lastCampaigns: get(state, 'campaign.campaigns'),
  isEditingForm: get(state, 'ui.isEditingForm'),
  platformLogo: get(state, 'platform.platform.media.logo'),
  isBillingConfigured: get(state, 'organization.isBillingConfigured'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  openLoginModal,
  openRegisterModal,
  setSessionLanguage,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicHeader));

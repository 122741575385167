import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, Modal } from 'antd';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';

import FundkyButton from './FundkyButton';

import FundkyModal_en from './locales/FundkyModal_en.json';
import FundkyModal_fr from './locales/FundkyModal_fr.json';

import './FundkyModal.less';

class FundkyModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayModal: props.displayModal
    };

    i18next.addResourceBundle('en', 'FundkyModal', FundkyModal_en);
    i18next.addResourceBundle('fr', 'FundkyModal', FundkyModal_fr);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.displayModal !== this.props.displayModal) {
      this.props.displayModal ? this.showModal() : this.hideModal();
    }
  }

  showModal = () => {
    this.setState({
      displayModal: true
    });
    this.props.openAction();
  };

  hideModal = () => {
    this.setState({
      displayModal: false
    });
  };

  handleAccept = event => {
    this.props.acceptAction(event, this.props.form);
  };

  handleCancel = event => {
    this.props.cancelAction(event);
    this.hideModal();
  };

  render() {
    const { handleCancel, handleAccept } = this;

    const {
      t,
      titleIcon,
      title,
      content,
      destroyOnClose,
      displayActions,
      displayCancel,
      displayCloseButton,
      isCancelInProgress,
      otherActionButtons,
      isAcceptInProgress,
      acceptDisabled,
      cancelLabel,
      acceptLabel,
      acceptIcon,
      children,
      maskClosable,
      className,
      scrollContent
    } = this.props;

    const { displayModal } = this.state;

    return (
      <Modal
        className={classnames('FundkyModal', className, {
          scrollContent: scrollContent
        })}
        visible={displayModal}
        footer={null}
        onCancel={handleCancel}
        maskClosable={maskClosable}
        destroyOnClose={destroyOnClose}
        getContainer={() => document.getElementById('MainLayout')}
        width={this.props.width}
        destroyOnClose={this.props.destroyOnClose}
      >
        <FundkyButton
          className="FundkyModal--close"
          type="text"
          icon={['fas', 'times']}
          size="large"
          action={{ func: handleCancel }}
        />
        {(title || titleIcon) && (
          <h1 className="FundkyModal--title">
            {titleIcon && <FontAwesomeIcon icon={titleIcon} />}
            {title}
          </h1>
        )}
        <div className="FundkyModal--content">{children}</div>
        {displayActions && (
          <div className="FundkyModal--actions">
            {displayCancel &&
            <FundkyButton
              className="white"
              type="cancel"
              text={cancelLabel || t('cancel')}
              size="medium"
              loading={isCancelInProgress}
              action={{ func: handleCancel }}
              onLightGrey
            />
            }
            {otherActionButtons}
            <FundkyButton
              className=""
              type="primary"
              text={acceptLabel || `${t('accept')}`}
              icon={acceptIcon}
              size="medium"
              loading={isAcceptInProgress}
              action={{ func: handleAccept }}
              disabled={acceptDisabled}
              onLightGrey
            />
          </div>
        )}
        {displayCloseButton && (
          <div className="FundkyModal--actions">
            <FundkyButton
              className=""
              type="primary"
              text={t('close')}
              size="medium"
              loading={isCancelInProgress}
              action={{ func: handleCancel }}
              onLightGrey
            />
          </div>
        )}
      </Modal>
    );
  }
}

FundkyModal.defaultProps = {
  acceptAction: () => {},
  acceptDisabled: false,
  acceptLabel: null,
  acceptIcon: null,
  cancelAction: () => {},
  cancelLabel: null,
  children: null,
  className: null,
  destroyOnClose: false,
  displayActions: true,
  displayCancel: true,
  displayCloseButton: false,
  displayModal: false,
  isAcceptInProgress: false,
  isCancelInProgress: false,
  maskClosable: true,
  otherActionButtons: null,
  title: null,
  titleIcon: null,
  openAction: () => {},
  scrollContent: null,
  width: 520, //ANTD default width
  destroyOnClose: false
};

FundkyModal.propTypes = {
  acceptAction: PropTypes.func,
  acceptDisabled: PropTypes.bool,
  acceptLabel: PropTypes.string,
  acceptIcon: PropTypes.array,
  cancelAction: PropTypes.func,
  cancelLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  destroyOnClose: PropTypes.bool,
  displayActions: PropTypes.bool,
  displayCloseButton: PropTypes.bool,
  displayModal: PropTypes.bool,
  isAcceptInProgress: PropTypes.bool,
  isCancelInProgress: PropTypes.bool,
  otherActionButtons: PropTypes.array,
  title: PropTypes.string,
  titleIcon: PropTypes.array,
  openAction: PropTypes.func,
  scrollContent: PropTypes.bool,
  destroyOnClose: PropTypes.bool
};

export default translate('FundkyModal', { withRef: true })(FundkyModal);

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';

import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import { DEFAULT_COMPACT_PAGER } from 'Parts/search/searchUtils';
import ResultThumbnailList from 'Parts/search/ResultThumbnailList';
import LinkButton from 'Parts/common/LinkButton';
import AvatarThumbnail from '../../common/AvatarThumbnailContainer';

import SelectCampaignPanel_en from './locales/SelectCampaignPanel_en.json';
import SelectCampaignPanel_fr from './locales/SelectCampaignPanel_fr.json';

import './SelectCampaignPanel.less';

class SelectCampaignPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'SelectCampaignPanel-team', SelectCampaignPanel_en);
    i18next.addResourceBundle('fr', 'SelectCampaignPanel-team', SelectCampaignPanel_fr);

    this.state = {
      pager: {
        ...DEFAULT_COMPACT_PAGER,
        total: (props.pagination && props.pagination.totalResults) || 0,
        current: (props.pagination && props.pagination.pageNumber) || 1,
        pageSize: (props.pagination && props.pagination.pageResults) || 10
      }
    };

    this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.pagination && prevProps.pagination) {
      // Update the pager with the new total
      let pager;

      if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
        pager = {
          ...this.state.pager,
          total: (this.props.pagination && this.props.pagination.totalResults) || 0,
          current: (this.props.pagination && this.props.pagination.pageNumber) || 1,
          pageSize: (this.props.pagination && this.props.pagination.pageResults) || 10
        };

        this.setState({ pager });
      }
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageNumber: pager.current,
      pageResults: pager.pageSize
    };
    this.props.searchCampaigns(
      this.props.platformId,
      this.props.campaigns.search,
      this.props.campaigns.filters,
      pagination,
      { orderBy: 'addedOn[desc]' }
    );
  };

  handleThumbnailClick(campaign) {
    // campaign.participating
    const { statusId, typeId, settings, childrenCount, participating, behaviorId, alias } = campaign,
      teamLimit =
        (settings && settings.team && settings.team.limit && parseInt(settings.team.limit)) || null,
      teamsCount = (childrenCount && childrenCount.teams) || null;

    if (
      statusId === 4 ||
      typeId == 1 ||
      typeId == 4 ||
      participating ||
      (settings &&
        settings.registration &&
        isDateBeforeCurrentDate(settings.registration.disabledOn, true)) ||
      (teamLimit && teamsCount && teamsCount >= teamLimit)
    ) {
      return null;
    }

    const isEvent = behaviorId === 2;

    if (isEvent) {
      this.props.history.push(`/${this.props.locale}/${alias}/${this.props.t('URL:ticket-purchase/choose')}`);
      return null;
    }

    return this.props.selectCampaign(campaign);
  }

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchCampaigns(this.props.platformId, filters.search, filters, pagination, {
      orderBy: 'addedOn[desc]'
    });
  };

  isCampaignFull = campaign => {
    const { settings, childrenCount } = campaign,
      teamLimit =
        (settings && settings.team && settings.team.limit && parseInt(settings.team.limit)) || null,
      teamsCount = (childrenCount && childrenCount.teams) || null;

    if (teamLimit && teamsCount && teamsCount >= teamLimit) {
      return true;
    }

    return false;
  };

  render() {
    const { t, locale } = this.props;

    return (
      <div className="SelectCampaignPanel FundkyWrap">
        <h2>{t('title')}</h2>
        {/* <p>{t('desc')}</p> */}
        <SearchBar title={t('placeholder')} compact onSearch={this.handleSearch} />
        <PaginationTop
          totalLabel={total => t('campaigns', { count: total })}
          pager={this.state.pager}
          onChange={this.handlePaginationChange}
          showSizeChanger={false}
        />
        <ResultThumbnailList
          rowKey="id"
          dataSource={this.props.campaigns || []}
          onThumbnailClick={this.handleThumbnailClick}
          renderRecord={campaign =>
            /*CAMPAIGN CLOSED*/
            campaign.statusId === 4
            ? (
                <Tooltip placement="bottom" title={t('campaign-closed')}>
                  <Card bordered={false} className="card-disabled">
                    <AvatarThumbnail
                      entity={campaign}
                      primaryContent={campaign.name}
                      avatarSize={58}
                    />
                  </Card>
                </Tooltip>
              )
            : /* Registration closed */
              campaign.settings.registration &&
              isDateBeforeCurrentDate(campaign.settings.registration.disabledOn, true)
              ? (
                  <Tooltip placement="bottom" title={t('registrations-closed')}>
                    <Card bordered={false} className="card-disabled">
                      <AvatarThumbnail
                        entity={campaign}
                        primaryContent={campaign.name}
                        avatarSize={58}
                      />
                    </Card>
                  </Tooltip>
                )
              : /*NO TEAM ACCEPTED*/
                campaign.typeId === 4 ||
                campaign.typeId === 1
                ? (
                    <Tooltip placement="bottom" title={t('dont-accept')}>
                      <Card bordered={false} className="card-disabled">
                        <AvatarThumbnail
                          entity={campaign}
                          primaryContent={campaign.name}
                          avatarSize={58}
                        />
                      </Card>
                    </Tooltip>
                  )
                : /*ALREADY PARTICIPANT*/
                  campaign.participating
                  ? (
                      <Tooltip placement="bottom" title={t('already-in')}>
                        <Card bordered={false} className="card-disabled">
                          <AvatarThumbnail
                            entity={campaign}
                            primaryContent={campaign.name}
                            avatarSize={58}
                          />
                        </Card>
                      </Tooltip>
                    )
                  : /*MAX NUMBER OF TEAM REACHED*/
                    this.isCampaignFull(campaign)
                    ? (
                        <Tooltip placement="bottom" title={t('full')}>
                          <Card bordered={false} className="card-disabled">
                            <AvatarThumbnail
                              entity={campaign}
                              primaryContent={campaign.name}
                              avatarSize={58}
                            />
                          </Card>
                        </Tooltip>
                      )
                    : /*EVENT TITCKET*/
                      campaign.behaviorId === 2
                      ? (
                          <Tooltip placement="bottom" title={t('event')}>
                            <Card bordered={false}>
                              <AvatarThumbnail
                                entity={campaign}
                                primaryContent={campaign.name}
                                avatarSize={58}
                              />
                            </Card>
                          </Tooltip>
                        )
                      : /*CAMPAIGN CARD*/
                        (
                          <Card bordered={false}>
                            <AvatarThumbnail entity={campaign} primaryContent={campaign.name} avatarSize={58} />
                          </Card>
                        )
          }
          cta={
            <LinkButton
              type="primary"
              className="SelectCampaignPanel__CreateCampaignCTA"
              to={{ pathname: `/${locale}/${t('URL:create-a-campaign')}` }}
              size="large"
            >
              {t('create-a-campaign')}
            </LinkButton>
          }
        />
      </div>
    );
  }
}

SelectCampaignPanel.defaultProps = {
  campaigns: []
};

SelectCampaignPanel.propTypes = {
  campaigns: PropTypes.array.isRequired,
  selectCampaign: PropTypes.func.isRequired
};

export default translate('SelectCampaignPanel-team')(SelectCampaignPanel);

import {
  get
} from 'lodash';

export function getPlugin(platform = null, name = null) {
  if (platform && name) {
    const isActive = isPluginActive(platform, name);
    if (isActive) {
      // !!! Imposible to use a variable for bundle naming, so we use string in switch case.
      return require.ensure([], require => {
        return new require(`./plugins/${name}/${name}.jsx`).default;
      }, 'name');
    } else {
      return Promise.resolve(null);
    }
  } else {
    return Promise.resolve(null);
  }
}

export function isPluginActive(platform = null, name = null) {
  if (platform && name) {
    // Get Platform plugin list from the platform.
    const list = platform.plugins || {};
    // Make first letter lowercase to match camelCase from DB
    name = name.replace(/^\w/, c => c.toLowerCase());
    // Return if the requested plugin is in the platform plugin list.
    return get(list, `${name}.enabled`, 0) ? true : false;
  }
  return false;
}
import React from 'react';
import { Button } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import CampaignsTeamsPageForm from '../campaigns/teams/components/CampaignsTeamsPageFormContainer';
import CampaignTeamPageHistory from '../campaigns/teams/components/CampaignTeamPageHistoryContainer';
import CampaignsTeamsRegistrationPageTable from '../campaigns/teams/components/CampaignsTeamsRegistrationPageTableContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import AdminBackButtonBar from 'Parts/common/AdminBackButtonBar';
import Can from 'Parts/session/components/CanContainer';

import MyContributionsTeamsPageEN from './locales/MyContributionsTeamsPage_en.json';
import MyContributionsTeamsPageFR from './locales/MyContributionsTeamsPage_fr.json';

class MyContributionsTeamsPage extends React.Component {
  constructor(props) {
    super(props);

    this.from = props.location.state && props.location.state.from;

    i18next.addResourceBundle('en', 'MyContributionsTeamsPage', MyContributionsTeamsPageEN);
    i18next.addResourceBundle('fr', 'MyContributionsTeamsPage', MyContributionsTeamsPageFR);
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { state } = this.props.location;
    if (state && state.from) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }
  }

  render() {
    const { props } = this,
      {
        t,
        team,
        locale,
        participation,
        platformId,
        checkAuthorization,
        participantName
      } = this.props;

    const contributionsListUrl =
      participation && participation.id
        ? `/${locale}/d/contributions/participations/${participation.id}`
        : `/${locale}/d/contributions/list/participations`;
    const campaignUrlPart = get(team, 'campaignAlias', '');
    const teamUrlPart = get(team, 'alias', '');
    const currentTeamUrl = `/${locale}/${campaignUrlPart}/${teamUrlPart}`;
    const historyTabPermissions =
      checkAuthorization({ platform: platformId }, ['permissions.platform.team.logs']) || false;

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: () => (
          <Can me={true} memberId={get(team, 'leader.memberId', null)} redirect={`/${locale}/d`}>
            <CampaignsTeamsPageForm {...props} />
          </Can>
        )
      },
      {
        title: t('Registrations'),
        slug: 'registration',
        container: () => (
          <Can me={true} memberId={get(team, 'leader.memberId', null)} redirect={`/${locale}/d`}>
            <CampaignsTeamsRegistrationPageTable {...props} />
          </Can>
        )
      },
      {
        title: t('LOGS:History'),
        slug: 'history',
        visible: historyTabPermissions,
        container: () => (
          <Can me={true} memberId={get(team, 'leader.memberId', null)} redirect={`/${locale}/d`}>
            <CampaignTeamPageHistory {...props} />
          </Can>
        )
      }
    ];

    return (
      <div className="MyContributionsTeamsPage history-table">
        <Helmet>
          <title>{t('Team')}</title>
        </Helmet>
        <AdminBackButtonBar
          href={contributionsListUrl}
          text={participantName ? `${t('Back to')}${participantName}` : t('Back to search')}
        />
        <AdminTitleBar
          upperTitle={t('Team')}
          title={team.name}
          buttons={[
            <Button
              type="secondary"
              className="btn-view-page"
              href={currentTeamUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{t('View page')}</span> <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
            </Button>
          ]}
        />
        <ResponsiveTabs pages={tabPages} parentPage={props} defaultTabHasNoSlug />
      </div>
    );
  }
}

MyContributionsTeamsPage.defaultProps = {
  teams: [],
  participant: {
    member: {},
    teamAlias: null,
    campaignAlias: null
  }
};

export default translate('MyContributionsTeamsPage')(MyContributionsTeamsPage);

import React from 'react';
import { get } from 'lodash';
import { Layout } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import SearchBar from 'Parts/search/SearchBarContainer';
import MyContributionsListDonations from './components/MyContributionsListDonationsContainer';
import MyContributionsListParticipations from './components/MyContributionsListParticipationsContainer';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Plugin from '../../plugin/Plugin';
import { isPluginActive } from '../../plugin/pluginActions';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';

import myContributionsListPageEN from './locales/MyContributionsListPage_en.json';
import myContributionsListPageFR from './locales/MyContributionsListPage_fr.json';

class MyContributionsListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle('en', 'MyContributionsListPage', myContributionsListPageEN);
    i18next.addResourceBundle('fr', 'MyContributionsListPage', myContributionsListPageFR);
  }

  render() {
    const { props, handleSearch } = this,
      { t, platform, lastCampaigns } = props;

    const DisplayParticipantPane =
      get(platform, 'settings.campaign.enabled', 0) == 1 ||
      (lastCampaigns && lastCampaigns.length > 0);

    const tabPages = [
      {
        title: t('Donations'),
        slug: 'donations',
        container: () => <MyContributionsListDonations {...props} />
      },
      {
        title: t('Participations'),
        slug: 'participations',
        visible: DisplayParticipantPane,
        container: () => <MyContributionsListParticipations {...props} />
      },
      ...(isPluginActive(platform, 'Event')
        ? [{
            title: t('Tickets'),
            slug: 'tickets',
            container: () => <Plugin
              name="Event"
              component="EventAdminMyContributionsListTickets"
              // resolve={() =>
              //   import(/* webpackChunkName: "Event" */ '../../plugin/plugins/Event/Event')
              // }
              {...{
                // Props
                ...props
              }}
            />
          }]
        : [])
    ];

    return (
      <Layout className="MyContributionsListPage">
        <Helmet>
          <title>{t('Contributions')}</title>
        </Helmet>
        <SearchBar title={t('Contributions')} placeholder={t('Search')} onSearch={handleSearch} />
        <ResponsiveTabs pages={tabPages} parentPage={props} />
      </Layout>
    );
  }
}

MyContributionsListPage.defaultProps = {
  participations: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('MyContributionsListPage')(MyContributionsListPage);

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';

import AdminTitleBar from 'Parts/common/AdminTitleBar';
import CampaignSettingsAdvancedPageFormContainer from './components/CampaignSettingsAdvancedPageFormContainer';
import Can from 'Parts/session/components/CanContainer';

import CampaignSettingsAdvancedPageEN from './locales/CampaignSettingsAdvancedPage_en.json';
import CampaignSettingsAdvancedPageFR from './locales/CampaignSettingsAdvancedPage_fr.json';

class CampaignSettingsAdvancedPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CampaignSettingsAdvancedPage', CampaignSettingsAdvancedPageEN);
    i18next.addResourceBundle('fr', 'CampaignSettingsAdvancedPage', CampaignSettingsAdvancedPageFR);
  }

  render() {
    const { t, locale } = this.props;

    return (
      <Layout className="CampaignSettingsAdvancedPage">
        <Helmet>
          <title>{t('Advanced settings')}</title>
        </Helmet>
        <AdminTitleBar title={t('Advanced settings')} />
        <div className="AdminPanel">
          <Can rules={['permissions.campaign.campaign.update']} redirect={`/${locale}/d`}>
            <CampaignSettingsAdvancedPageFormContainer {...this.props} />
          </Can>
        </div>
      </Layout>
    );
  }
}

export default translate('CampaignSettingsAdvancedPage')(CampaignSettingsAdvancedPage);

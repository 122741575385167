import { compose } from "redux";
import { connect } from "react-redux";
import SitemapCampaigns from './SitemapCampaigns';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  campaigns: state.campaign.campaigns,
  teams: state.search.teams.results,
  participants: state.search.participants.results,
});

export default compose(
  connect(mapStateToProps)
)(SitemapCampaigns);

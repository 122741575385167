import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import teamCustomUrlFieldEN from './locales/TeamCustomUrlField_en.json';
import teamCustomUrlFieldFR from './locales/TeamCustomUrlField_fr.json';
import { Form, Input } from 'antd';
import FundkyTooltip from 'Parts/ui/components/FundkyTooltip';

class TeamCustomUrlField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TeamCustomUrlField', teamCustomUrlFieldEN);
    i18next.addResourceBundle('fr', 'TeamCustomUrlField', teamCustomUrlFieldFR);
  }

  render() {
    const { t, data, disabled } = this.props;
    const { getFieldDecorator } = this.props.form;

    const tooltip = (
      <p>
        {t('Tooltip line 1')}
        <br />
        <br />
        {t('Tooltip line 2')}
        <br />
        {t('Tooltip line 3')}
        <strong>{t('Tooltip line 4')}</strong>
      </p>
    );

    return (
      <Form.Item
        label={
          <span>
            {t('Custom URL address')}
            <FundkyTooltip title={tooltip} />
          </span>
        }
        colon={false}
      >
        {getFieldDecorator('team_url', { initialValue: data })(
          <Input addonBefore="/" disabled={disabled} />
        )}
      </Form.Item>
    );
  }
}

TeamCustomUrlField.defaultProps = {
  data: '',
  disabled: false
};

export default translate('TeamCustomUrlField')(TeamCustomUrlField);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './WinkNotification.less';

class WinkNotification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
  }

  closeButtonHandler = () => {
    this.setState({ visible: false });
  };

  render() {
    const { className, closable, title, description, buttons, alignButtons, showWink } = this.props;
    const { visible } = this.state;

    if (!visible) {
      return null;
    }

    const classes = classnames('WinkNotification', {
      [`WinkNotification__${className}`]: className
    });

    const CloseButton = closable ? (
      <a onClick={() => this.closeButtonHandler()} className="WinkNotification__CloseButton">
        <FontAwesomeIcon icon={['fal', 'times']} />
      </a>
    ) : null;

    const Title = title ? <h2>{title}</h2> : null;

    const DescriptionTag = this.props.descriptionTag;
    const Description = description ? <DescriptionTag>{description}</DescriptionTag> : null;

    const CallToActions = buttons
      ? buttons.map((button, index) => {
          return <span key={index}>{button}</span>;
        })
      : null;

    const CallToActionsWrap = CallToActions ? (
      <div className={`WinkNotification__Buttons align-${alignButtons}`}>{CallToActions}</div>
    ) : null;

    const Wink = showWink ? (
      <img src="/assets/wink@2x.png" className="logo-wink" alt="Wink Logo" />
    ) : null;

    const Notification = (
      <div className="WinkNotification__Inner">
        {Wink}
        <div
          className={classnames(
            'WinkNotification__Inner_Content',
            { hasWink: showWink },
            { closable: closable }
          )}
        >
          {Title}
          {Description}
          {CallToActionsWrap}
        </div>
        {CloseButton}
      </div>
    );

    return <div className={classes}>{Notification}</div>;
  }
}

WinkNotification.defaultProps = {
  className: null,
  closable: true,
  title: null,
  description: null,
  buttons: [],
  alignButtons: 'left',
  showWink: true,
  descriptionTag: 'p'
};

WinkNotification.propTypes = {
  className: PropTypes.string,
  closable: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttons: PropTypes.array,
  alignButtons: PropTypes.oneOf(['left', 'center', 'right']),
  showWink: PropTypes.bool,
};

export default WinkNotification;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connectRange } from 'react-instantsearch-dom';
import { InputNumber } from 'antd';
import { getLanguageMoneyFormatByKey } from 'Parts/common/langUtils';

const defaultMin = 5;
const defaultMax = 9999;

const AlgoliaPriceRange = ({ currentRefinement, precision, refine, locale }) => {
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  // Component mounted
  // Set default values
  useEffect(() => {
    setMinValue(currentRefinement.min || defaultMin);
    setMaxValue(currentRefinement.max || defaultMax);
  }, []);

  // Like componentDidUpdate
  // if minValue || maxValue changes, refine results
  useEffect(() => {
    refine({
      min: minValue && minValue >= defaultMin ? minValue : defaultMin,
      max: maxValue && maxValue <= defaultMax ? maxValue : defaultMax
    });
  }, [minValue, maxValue]);

  function formatter(value) {
    const string = value.toString(),
      format = getLanguageMoneyFormatByKey(locale),
      before = format[0],
      after = format[3],
      thousandSeparator = format[1],
      decomposed = string.split('.'),
      wholed = parseFloat(
        decomposed[0]
          .split(' ')
          .join('')
          .split(',')
          .join('')
      ),
      whole = isNaN(wholed) ? '' : wholed.toString(),
      decimal = decomposed[1] || decomposed[1] === '' ? decomposed[1].substring(0, 2) : null,
      thousandSeparated = whole.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

    let text = before ? before : '';
    text += decimal || decimal === '' ? thousandSeparated + '.' + decimal : thousandSeparated;
    text += after ? after : '';

    return text;
  }

  function parser(value) {
    const string = value.toString().replace('$', ''),
      format = getLanguageMoneyFormatByKey(locale),
      thousandSeparator = format[1],
      decomposed = string.split('.'),
      wholed = parseFloat(decomposed[0].split(thousandSeparator).join('')),
      whole = isNaN(wholed) ? '' : wholed.toString(),
      decimal = decomposed[1] || decomposed[1] === '' ? decomposed[1].substring(0, 2) : null,
      number = decimal || decimal === '' ? whole + '.' + decimal : whole;

    return number;
  }

  return (
    <div className="AlgoliaPriceRange">
      <InputNumber
        min={defaultMin}
        max={defaultMax}
        precision={precision}
        value={minValue}
        formatter={value => formatter(value)}
        parser={value => parser(value)}
        onChange={value => setMinValue(value)}
      />
      <InputNumber
        min={defaultMin}
        max={defaultMax}
        precision={precision}
        value={maxValue}
        formatter={value => formatter(value)}
        parser={value => parser(value)}
        onChange={value => setMaxValue(value)}
      />
    </div>
  );
};

AlgoliaPriceRange.defaultProps = {
  precision: 2
};

AlgoliaPriceRange.propTypes = {
  locale: PropTypes.string.isRequired
};

export default connectRange(AlgoliaPriceRange);

import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { translate } from 'react-i18next';
import { get } from 'lodash';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

import CampaignsTeamsPageForm from './components/CampaignsTeamsPageFormContainer';
import CampaignTeamPageHistory from './components/CampaignTeamPageHistoryContainer';
import CampaignsTeamsRegistrationPageTable from './components/CampaignsTeamsRegistrationPageTableContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import AdminBackButtonBar from 'Parts/common/AdminBackButtonBar';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import campaignsTeamsPageEN from './locales/CampaignsTeamsPage_en.json';
import campaignsTeamsPageFR from './locales/CampaignsTeamsPage_fr.json';

import './CampaignsTeamsPage.less';

class CampaignsTeamsPage extends React.Component {
  constructor(props) {
    super(props);

    this.from = props.location.state && props.location.state.from;

    i18next.addResourceBundle('en', 'CampaignsTeamsPage', campaignsTeamsPageEN);
    i18next.addResourceBundle('fr', 'CampaignsTeamsPage', campaignsTeamsPageFR);
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { state } = this.props.location;
    if (state && state.from) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }
  }

  componentDidUpdate(prevProps) {
    const { history, location } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      if (location.pathname.indexOf(':teamID')) {
        const teamsListUrl = location.pathname.replace('/teams/:teamID', '');
        history.replace(teamsListUrl);
      }
    }
  }

  render() {
    const { props } = this,
      { t, match, campaign, team, locale, platformId, checkAuthorization } = props;

    const currentTeamUrl = `/${locale}/${team.campaignAlias}/${team.alias}`;

    const teamsListUrl =
      props.location && props.location.state && props.location.state.originIsAll
        ? `/${locale}/d/teams/list`
        : `/${locale}/d/campaigns/${campaign.id}/teams/list`;

    const historyTabPermissions =
      checkAuthorization({ platform: platformId }, ['permissions.platform.team.logs']) || false;

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: props => (
          <Can
            rules={['permissions.team.team.update']}
            teamId={get(match, 'params.teamID', null)}
            redirect={`/${locale}/d`}
          >
            <CampaignsTeamsPageForm {...this.props} />
          </Can>
        )
      },
      {
        title: t('Registrations'),
        slug: 'registration',
        container: props => (
          <Can
            rules={['permissions.team.team.participants']}
            teamId={get(match, 'params.teamID', null)}
            redirect={`/${locale}/d`}
          >
            <CampaignsTeamsRegistrationPageTable {...this.props} />
          </Can>
        )
      },
      /*{title: t('Funds raised'), slug: 'donations', container: (props) => <CampaignsTeamsDonationPageForm locale={locale} />},*/
      {
        title: t('LOGS:History'),
        slug: 'history',
        visible: historyTabPermissions,
        container: props => (
          <Can
            rules={['permissions.team.team.logs']}
            teamId={get(match, 'params.teamID', null)}
            redirect={`/${locale}/d`}
          >
            <CampaignTeamPageHistory {...this.props} />
          </Can>
        )
      }
    ];

    const disabled =
      get(campaign, 'statusId', null) === 2 ||
      get(campaign, 'statusId', null) === 3 ||
      get(campaign, 'statusId', null) === 5;
    const tooltipVisibility = !disabled ? { visible: false } : {};
    const tooltipMessage =
      get(campaign, 'statusId', null) === 2 ? t('under-review') : t('inactive');
    const linkDisabled = disabled ? { disabled: true } : {};

    return (
      <div className="CampaignsTeamsPage">
        <Helmet>
          <title>{t('Team')}</title>
        </Helmet>
        <AdminBackButtonBar href={teamsListUrl} text={t('Back to search')} />
        <AdminTitleBar
          title={team.name}
          upperTitle={t('Team')}
          buttons={[
            <Tooltip placement="top" title={tooltipMessage} {...tooltipVisibility}>
              <div>
                <span style={{ visibility: 'hidden' }}>-</span>
                {/*Ugly fix so that tooltip shows up even if button is disabled*/}
                <Link
                  to={currentTeamUrl}
                  className="ant-btn btn-view-page ant-btn-default"
                  target="_blank"
                  rel="noopener noreferrer"
                  {...linkDisabled}
                >
                  <span>{t('View page')}</span>
                  <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                </Link>
              </div>
            </Tooltip>
          ]}
        />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} defaultTabHasNoSlug />
      </div>
    );
  }
}

CampaignsTeamsPage.defaultProps = {
  team: []
};

export default translate('CampaignsTeamsPage')(CampaignsTeamsPage);

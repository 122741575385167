import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { searchCampaigns, searchTeams, searchParticipants, clearSearch } from '../searchActions';

import SearchPage from './SearchPage';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  campaigns: state.search.campaigns,
  teams: state.search.teams,
  participants: state.search.participants,
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchCampaigns,
  searchTeams,
  searchParticipants,
  clearSearch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SearchPage);

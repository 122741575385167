import { bindActionCreators } from "redux";
import { logout, connect as _connect } from "../sessionActions";
import { connect } from "react-redux";

import SessionExpirationWarningModal from './SessionExpirationWarningModal';


const mapStateToProps = state => ({
  displaySessionTimeoutWarning: state.session.displaySessionTimeoutWarning
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  connect: _connect
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(SessionExpirationWarningModal);

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import NoScript from 'react-noscript';
// React Router no longer handles scroll :(
// @see https://reacttraining.com/react-router/web/guides/scroll-restoration
// TODO Refactor when https://github.com/ipatate/react-router-scroll-memory/issues/9

import SessionExpirationMessage from 'Parts/session/components/SessionExpirationMessageContainer';
import CssVariableApplicator from 'Parts/ui/components/CssVariableApplicator';
import HomePage from '../home/pages/HomePageContainer';
import PublicHeader from './components/PublicHeaderContainer';
import PublicFooter from './components/PublicFooterContainer';
import ConnectionModal from 'Parts/session/components/ConnectionModalContainer';
import TeamCreatePage from '../team/pages/TeamCreatePageContainer';
import ParticipantCreatePage from '../participant/pages/ParticipantCreatePageContainer';
import CampaignCreatePage from '../campaign/pages/CampaignCreatePageContainer';
import AuthRoute from 'Parts/chore/AuthRouteContainer';
import CheckCampaignRoute from '../chore/CheckCampaignRouteContainer';
import CheckTeamRoute from '../chore/CheckTeamRouteContainer';
import CheckParticipantRoute from '../chore/CheckParticipantRouteContainer';
import CheckIndividualParticipantRoute from '../chore/CheckIndividualParticipantRouteContainer';
import DonationChoicePage from '../donation/pages/DonationChoicePageContainer';
import DonationPage from '../donation/pages/DonationPageContainer';
import SearchPage from '../search/pages/SearchPageContainer';
import RefundPolicyPage from '../static/pages/RefundPolicyPageContainer';
import ThirdPartyGuidelinesPage from '../static/pages/ThirdPartyGuidelinesPageContainer';
import ContactPage from '../static/pages/ContactPageContainer';
import Plugin from '../plugin/Plugin';

import { isPluginActive } from '../plugin/pluginActions';
import { CONNECTION_MODAL_CLOSED } from '../parts/ui/uiUtils';

import PublicLayout_en from './locales/PublicLayout_en.json';
import PublicLayout_fr from './locales/PublicLayout_fr.json';

import './PublicLayout.less';

const { Content } = Layout;

class PublicLayout extends React.Component {
  constructor(props) {
    super(props);

    const { t, locale, location } = props;

    this.state = {
      formatedColors: {
        '--primary-color': props.colors && props.colors.primary || '',
        '--secondary-color': props.colors && props.colors.secondary || ''
      },
      auth:
        location.pathname !== `/${locale}/${t('URL:create-a-campaign')}` &&
        location.pathname !== `/${locale}/${t('URL:create-a-team')}` &&
        location.pathname !== `/${locale}/${t('URL:participant-registration')}`
          ? false
          : true
    };

    this.locale = props.locale;

    i18next.addResourceBundle('en', 'PublicLayout', PublicLayout_en);
    i18next.addResourceBundle('fr', 'PublicLayout', PublicLayout_fr);
  }

  componentDidMount() {
    this.props.fetchPlatform(this.props.platformId, false, { showSettings: true, showMedia: true });
  }

  componentDidUpdate() {
    const { t, locale, location, connectionModalState } = this.props;

    /* FORCE CONNECTION MODAL TO CLOSE AFTER REDIRECTION FROM MEMBER PAGE */
    if (
      location.pathname !== `/${locale}/${t('URL:create-a-campaign')}` &&
      location.pathname !== `/${locale}/${t('URL:create-a-team')}` &&
      location.pathname !== `/${locale}/${t('URL:participant-registration')}`
    ) {
      if (this.state.auth) {
        this.setState({
          auth: false
        });
        if (connectionModalState !== CONNECTION_MODAL_CLOSED) {
          this.props.forceCloseConnectionModal();
        }
      }
    } else {
      if (!this.state.auth) {
        this.setState({
          auth: true
        });
      }
    }

    // Fetch platform at language switch
    if (this.props.locale !== this.locale) {
      this.locale = this.props.locale;
      this.props.fetchPlatform(this.props.platformId, false, { showMedia: true });
    }
  }

  render() {
    const { t, locale, platformIndexation, location, platformGTM, platform } = this.props;

    const home = location.pathname === `/${locale}` ? true : false;
    const event = isPluginActive(platform, 'Event');

    return (
      <>
        {platformGTM &&
          <NoScript>
            <iframe
              sandbox="true"
              src={`//www.googletagmanager.com/ns.html?id=${platformGTM}`}
              height="0"
              width="0"
              style={{display:'none', visibility:'hidden'}}>
            </iframe>
          </NoScript>
        }
        <CssVariableApplicator variables={this.state.formatedColors}>
          {platformIndexation == '0' &&
            <Helmet>
              <meta name="robots" content="noindex, nofollow" />
            </Helmet>
          }
          <SessionExpirationMessage />
          <Layout id="MainLayout" className="PublicLayout">
            <PublicHeader />
            <Content>
              <Switch>
                {location.pathname.indexOf(`/${locale}`) !== 0 &&
                  <Redirect to={`/${locale}`} />
                }
                <Route
                  exact
                  path={`/${locale}`}
                  component={HomePage}
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:login')}`}
                  component={HomePage}
                  modal="login"
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:create-an-account')}`}
                  component={HomePage}
                  modal="register"
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:account-recovery')}`}
                  component={HomePage}
                  modal="recover"
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:reset-password')}/:key`}
                  component={HomePage}
                  modal="reset"
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:registration-confirm')}/:key`}
                  component={HomePage}
                  modal="confirmation"
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:refund-policy')}`}
                  component={RefundPolicyPage}
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:organization-guidelines')}`}
                  component={ThirdPartyGuidelinesPage}
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:contact-us')}`}
                  component={ContactPage}
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:create-a-campaign')}`}
                  component={CampaignCreatePage}
                />
                <Route
                  exact
                  path={`/${locale}/${t('URL:create-a-campaign/description')}`}
                  component={CampaignCreatePage}
                />
                <AuthRoute
                  exact
                  path={`/${locale}/${t('URL:create-a-campaign/confirmation')}`}
                  component={CampaignCreatePage}
                />
                <AuthRoute
                  exact
                  path={`/${locale}/${t('URL:create-a-team')}`}
                  component={TeamCreatePage}
                />
                <AuthRoute
                  exact
                  path={`/${locale}/${t('URL:participant-registration')}`}
                  component={ParticipantCreatePage}
                />

                <Redirect
                  exact
                  from={`/${locale}/${t('URL:search')}`}
                  to={`/${locale}/${t('URL:search/campaigns')}`}
                />
                <Route
                  path={`/${locale}/${t('URL:search')}`}
                  component={SearchPage}
                />

                {/* DONATION PROCESS */}

                  {/*Searching Participants*/}
                  <Route
                    exact
                    path={`/${locale}/${t('URL:give/participants')}/`}
                    render={props => <SearchPage {...props} from="donation" />}
                  />
                  {/*Searching Teams*/}
                  <Route
                    exact
                    path={`/${locale}/${t('URL:give/teams')}/`}
                    render={props => <SearchPage {...props} from="donation" />}
                  />
                  {/*Searching Campaigns*/}
                  <Route
                    exact
                    path={`/${locale}/${t('URL:give/campaigns')}/`}
                    render={props => <SearchPage {...props} from="donation" />}
                  />
                  <Route
                    exact
                    path={`/${locale}/${t('URL:give')}`}
                    component={DonationChoicePage}
                  />

                <Route
                  path={`/${locale}/${t('URL:organization')}/${t('URL:give')}`}
                  component={DonationPage}
                />
                <Route
                  path={`/${locale}/:campaignAlias/${t('URL:individual-participant')}/:participantAlias/${t('URL:give')}`}
                  component={DonationPage}
                  />
                <Route
                  path={`/${locale}/:campaignAlias/:teamAlias/:participantAlias/${t('URL:give')}`}
                  component={DonationPage}
                />
                <Route
                  path={`/${locale}/:campaignAlias/:teamAlias/${t('URL:give')}`}
                  component={DonationPage}
                />
                <Route
                  path={`/${locale}/:campaignAlias/${t('URL:give')}`}
                  component={DonationPage}
                />

              {/* SALE TICKETS PROCESS */}

                {event &&
                <Route
                  exact
                  path={`/${locale}/:campaignAlias/${t('URL:ticket-purchase/details')}`}
                  render={props => <Plugin {...props} name="Event" component="BuyTicketsPage" />}
                />}
                {event &&
                <Route
                  exact
                  path={`/${locale}/:campaignAlias/${t('URL:ticket-purchase/survey')}`}
                  render={props => <Plugin {...props} name="Event" component="BuyTicketsPage" />}
                  />}
                {event &&
                  <Route
                    exact
                    path={`/${locale}/:campaignAlias/${t('URL:ticket-purchase/payment')}`}
                    render={props => <Plugin {...props} name="Event" component="BuyTicketsPage" />}
                  />}
                {event &&
                <Route
                  exact
                  path={`/${locale}/:campaignAlias/${t('URL:ticket-purchase/confirmation')}/:status`}
                  render={props => <Plugin {...props} name="Event" component="BuyTicketsPage" />}
                />}
                {event &&
                <CheckCampaignRoute
                  exact
                  path={`/${locale}/:campaignAlias/${t('URL:ticket-purchase/choose')}`}
                />}
                {event &&
                <CheckCampaignRoute
                  exact
                  path={`/${locale}/:campaignAlias/${t('URL:ticket-purchase/promo-code')}`}
                />}

              {/* PUBLIC PAGES */}

                <CheckIndividualParticipantRoute
                  exact
                  path={`/${locale}/:campaignAlias/${t('URL:individual-participant')}/:participantAlias`}
                />
                <CheckParticipantRoute
                  exact
                  path={`/${locale}/:campaignAlias/:teamAlias/:participantAlias`}
                />
                <CheckTeamRoute
                  exact
                  path={`/${locale}/:campaignAlias/:teamAlias`}
                />
                <CheckCampaignRoute
                  exact
                  path={`/${locale}/:campaignAlias`}
                />

                <Redirect
                  exact
                  from="/" to={`/${locale}`}
                />
              </Switch>
            </Content>
            <PublicFooter className={home ? 'PublicFooter-home' : null} />
            <ConnectionModal />
          </Layout>
        </CssVariableApplicator>
      </>
    );
  }
}

PublicLayout.defaultProps = {
  locale: 'en'
};

PublicLayout.propTypes = {
  openLoginModal: PropTypes.func.isRequired,
  openRegisterModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  locale: PropTypes.oneOf(['fr', 'en']).isRequired
};

export default translate('PublicLayout')(PublicLayout);

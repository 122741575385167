import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Tooltip } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

import CsvReportButton from '../../report/components/CsvReportButtonContainer';
import SearchBar from 'Parts/search/SearchBarContainer';
import { Link } from 'react-router-dom';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Can from 'Parts/session/components/CanContainer';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';

import transactionsListPageEN from './locales/TransactionsListPage_en.json';
import transactionsListPageFR from './locales/TransactionsListPage_fr.json';

class TransactionsListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle('en', 'TransactionsListPage', transactionsListPageEN);
    i18next.addResourceBundle('fr', 'TransactionsListPage', transactionsListPageFR);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchTransactions(
      this.props.platformId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  handleViewHandler = transaction => {
    const { history, locale } = this.props;
    if (transaction.scope.type === "platform") {
      return history.push(`/${locale}/d/transactions/${transaction.id}`);
    } else {
      return history.push(
        `/${locale}/d/campaigns/${transaction.donation.target.path.campaign.id}/transactions/${transaction.id}`,
        { originIsAll: true }
      );
    }
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchTransactions(
      this.props.platformId,
      filters.search,
      filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  removeParentIdFilterForCSVReport = filters => {
    let _filters = {...filters};
    delete _filters.parentId;
    return _filters;
  }

  render() {
    const {
      t,
      transactions,
      locale,
      pagination,
      reportTransactions,
      platformId,
      checkAuthorization
    } = this.props;
    const { pager } = this.state;
    const CsvReportButtonDisabled = !transactions || transactions.length == 0;
    const tooltipVisibility = !CsvReportButtonDisabled ? { visible: false } : {};

    return (
      <Layout className="TransactionsListPage">
        <Helmet>
          <title>{t('Transactions')}</title>
        </Helmet>
        <SearchBar
          title={t('Transactions')}
          placeholder={t('Search')}
          createButton={
            (checkAuthorization({ platform: platformId }, [
              'permissions.platform.donation.offline'
            ]) && (
              <Link to={`/${locale}/d/transactions/add`} className="ant-btn ant-btn-primary">
                <span>{t('Add a transaction')}</span> <FontAwesomeIcon icon={['fas', 'plus']} />
              </Link>
            )) ||
            null
          }
          reportButton={
            <Tooltip title={t('no-transaction')} {...tooltipVisibility}>
              <div>
                <CsvReportButton
                  key={1}
                  data={this.props.report}
                  id={this.props.platformId}
                  pagination={this.props.pagination}
                  orderBy={{ orderBy: 'addedOn[desc]' }}
                  filters={this.removeParentIdFilterForCSVReport(this.props.filters)}
                  fileName={`${this.props.platformAlias}.transactions`}
                  fetch={reportTransactions}
                  disabled={CsvReportButtonDisabled ? true : null}
                />
              </div>
            </Tooltip>
          }
          onSearch={this.handleSearch}
        />
        <Can rules={['permissions.platform.transaction.get']} redirect={`/${locale}/d`}>
          <div className="AdminList">
            <PaginationTop
              totalLabel={total => t('donationsTotal', { count: pagination.totalResults })}
              pager={pager}
              onChange={this.handlePaginationChange}
            />
            <ResultTable
              dataSource={transactions || []}
              pagination={pager}
              onChange={this.handlePaginationChange}
              showHeader={true}
              scroll={{ x: true }}
              columns={[
                simpleColumn(t('Transaction ID'), 'referenceId', null, true),
                simpleColumn(t('Donor name'), 'information', info => {
                  return get(info, 'organization.name') ||
                    get(info, 'firstName') && get(info, 'lastName')
                      ? info.firstName + " " + info.lastName
                      : '';
                }, false),
                simpleColumn(t('Email address'), 'information.email', null, false),
                simpleColumn(t('Recipient'), 'scope.name', null, true),
                simpleColumn(
                  t('Contribution amount'),
                  null,
                  transaction => {
                    const sub = get(transaction, 'subscription.amount', null);
                    return moneyFormat(sub || transaction.amount, locale) + (sub ? '/m' : '');
                  },
                  true
                ),
                simpleColumn(t('Status'), 'status', status => t(status), true),
                dateColumn(t('Created on'), 'addedOn', null, null, false, locale),
                actionColumn(t('Edit'), ['fal', 'edit'], this.handleViewHandler)
              ]}
            />
          </div>
        </Can>
      </Layout>
    );
  }
}

TransactionsListPage.propTypes = {
  searchTransactions: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
  reportTransactions: PropTypes.func.isRequired
};

TransactionsListPage.defaultProps = {
  transactions: [],
  pagination: {
    totalResults: 0
  },
  reportTransactions: null
};

export default translate('TransactionsListPage')(TransactionsListPage);

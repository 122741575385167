import React from 'react';
import isBrowser from 'is-in-browser';
import PropTypes from 'prop-types';

const DEBUG = false;

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const dataFetcher = (WrappedComponent) => {
  class DataFetcher extends React.Component {

    componentDidMount() {
      // Don't fetch data on the server (the data is fetched before rendering)
      if (!isBrowser) {
        return;
      }

      // Skip the data fetching for the first render in case of SSR
      if (window.__PRELOADED_STATE__ && this.context.firstRender) {
        return;
      }

      this.fetchData(this.props);
    }

    componentDidUpdate(prevProps) {
      if (prevProps.location && prevProps.location.key !== this.props.location.key) {
        this.fetchData(this.props);
      }
    }

    fetchData(props) {
      let actionCreators = {
        sequential: [],
        parallel: []
      };
    
      // computedMatch takes precedence over match (it allows usage on a Route component, like CheckCampaignRoute)
      const matchObject = props.computedMatch || props.match;

      // Process fetchmap
      if(this.context.fetchMap && this.context.fetchMap.constructor === Array) {
        let state = this.context.store.getState();
    
        for(let route of this.context.fetchMap) {
          let matchResult = matchObject.path.startsWith(route.match.path) && route.match.exact === matchObject.isExact;

          // Register actions
          if(matchResult) {
            // Action creators
            if(route.actionCreators) {
              // Old action creators as sequential (Remove after changing all of them)
              if(route.actionCreators.constructor === Array) {
                route.actionCreators.forEach(sequentialActionCreator => {
                  if(sequentialActionCreator && sequentialActionCreator.constructor === Function) {
                    actionCreators.sequential.push(sequentialActionCreator(matchObject.params, state, {}, props.from));
                  }
                });
              }
    
              // Sequential action creators
              if(route.actionCreators.sequential && route.actionCreators.sequential.constructor === Array) {
                route.actionCreators.sequential.forEach(sequentialActionCreator => {
                  if(sequentialActionCreator && sequentialActionCreator.constructor === Function) {
                    actionCreators.sequential.push(sequentialActionCreator(matchObject.params, state, {}, props.from));
                  }
                });
              }
    
              // Parallel action creators
              if(route.actionCreators.parallel && route.actionCreators.parallel.constructor === Array) {
                route.actionCreators.parallel.forEach(parallelActionCreator => {
                  if(parallelActionCreator && parallelActionCreator.constructor === Function) {
                    actionCreators.parallel.push(parallelActionCreator(matchObject.params, state, {}, props.from));
                  }
                });
              }
            }
    
            // Exact match
            if(route.match && route.match.exact) {
              break;
            }
          }
        }
      }

      // Process sequential and parallel requests
      return new Promise((resolve, reject) => {
        // Sequential requests process
        return actionCreators.sequential.reduce((chainOfPromises, sequentialRequest) => {
          return chainOfPromises.then(() => {
            return this.context.store.dispatch(sequentialRequest);
          });
        }, Promise.resolve()).then(() => {
          resolve();
        });
      }).then(() => {
        // Parallel requests process
        return Promise.all(actionCreators.parallel.map((parallelRequest) => {
          return this.context.store.dispatch(parallelRequest);
        }));
      });
    }

    render() {
      return(
        <WrappedComponent {...this.props} />
      )
    }
  }

  DataFetcher.displayName = `DataFetcher(${getDisplayName(WrappedComponent)})`;

  DataFetcher.contextTypes = {
    store: PropTypes.object,
    fetchMap: PropTypes.array,
    firstRender: PropTypes.bool
  };

  DataFetcher.propTypes = {
    staticContext: PropTypes.shape({
      actionCreators: PropTypes.array
    })
  };

  return DataFetcher;
};

export default dataFetcher;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, Input } from 'antd';

import FundkyTooltip from '../FundkyTooltip';

import './TextField.less';

class TextField extends React.Component {
  constructor(props) {
    super(props);

    const length = props.initialValue && props.initialValue.length || 0;
    let lengthCount = null;
    if (length && props.maxlength) {
      lengthCount = props.maxlength - length;
    } else
      if (props.maxlength) {
        lengthCount = props.maxlength
      }

    this.state = {
      lengthCount: lengthCount,
    };
  }

  componentDidUpdate(prevProps) {
    // Reset form if initialValue/contentLang changes
    if (prevProps.initialValue !== this.props.initialValue || prevProps.contentLang !== this.props.contentLang) {
      this.props.form.resetFields([this.props.fieldId]);
      this.handleCounterReset();
    }
  }

  handleCounterReset() {
    if (this.props.counter && this.props.maxlength) {
      let fieldValue = this.props.form.getFieldValue(this.props.fieldId);
      let lengthCount = fieldValue ? fieldValue.length : null;
      this.setState({
        lengthCount
      });
    }
  }

  handlerCounter = (event) => {
    if (this.props.counter && this.props.maxlength) {
      this.setState({
        lengthCount: (this.props.maxlength - event.target.value.length)
      });
    }
  }

  normalizeUpperCase = (value) => {
    if (value && value.length)
      return value.toUpperCase();
  };

  resetField = () => {
    this.props.form.resetFields([this.props.fieldId]);
  }

  render() {
    const { form, fieldId, className, initialValue, fieldOptions, label, counter, maxlength, hasFeedback, placeholder, tooltip, uppercase, disabled, onInput, onChange, required, requiredMessage, maximumMessage, colon, ...rest } = this.props;
    const { getFieldDecorator } = form;

    const input = (
      <Input
        disabled={disabled}
        className={counter ? "counterOn" : null}
        placeholder={placeholder}
        maxLength={maxlength}
        onInput={(event) => {
          if (counter) this.handlerCounter(event);
          if (onInput) onInput(event);
        }}
        addonBefore={this.props.addonBefore || null}
        addonAfter={this.props.addonAfter || null}
        {...rest}
      />
    );

    const _fieldOptions = fieldOptions
      ? {
          ...fieldOptions
        }
      : null;

    if (required === true && _fieldOptions && _fieldOptions.rules) {
      _fieldOptions.rules.forEach((rule, index) => {
        if(rule.required  === false) {
          _fieldOptions.rules[index].required = true;
        }
      })
    }

    const options = {
      initialValue: initialValue,
      ...(uppercase ? { normalize: this.normalizeUpperCase } : null),
      onChange: (event) => {
        if (onChange) onChange(event);
        return;
      },
      // Adaptation of the fieldOptions for multilanguage context.
      ...(fieldOptions
        ? fieldOptions
        : {
            rules: [
              ...(required
                ? [{
                    required: true,
                    message: requiredMessage
                  }]
                : []
              ),
              ...(maxlength
                ? [{
                    pattern: new RegExp(`^.{0,${maxlength}}$`),
                    message: maximumMessage
                  }]
                : []
              )
            ]
          }
      )
    };

    let labelProperties = tooltip ? {
      label: (<span>{label + ":"}<FundkyTooltip title={tooltip} /></span>),
      colon: false,
    } : {
        label: label,
      };

    const counterMarkup = counter ? (
      <span className="TextField_Counter">{this.state.lengthCount}</span>
    ) : null;

    return (
      <Form.Item
        className={classnames('TextField', className)}
        hasFeedback={hasFeedback}
        {...labelProperties}
        colon={colon}
      >
        {getFieldDecorator(fieldId, options)(input)}
        {counterMarkup}
      </Form.Item>
    );
  }

}

TextField.defaultProps = {
  fieldId: 'textfield',
  fieldOptions: null,
  maxlength: 524288,
  disabled: false,
  initialValue: '',
  hasFeedback: false,
  tooltip: null,
  placeholder: null,
  counter: false,
  colon: true
};

TextField.propTypes = {
  form: PropTypes.object.isRequired,
  fieldId: PropTypes.string.isRequired,
  fieldOptions: PropTypes.object,
  className: PropTypes.string,
  maxlength: PropTypes.number,
  hasFeedback: PropTypes.bool,
  placeholder: PropTypes.string,
  counter: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TextField;

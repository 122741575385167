import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { get } from 'lodash';

import { updatePlatform, updatePlatformSettings, updatePlatformMedia, deletePlatformMedia } from '../../../platform/platformActions';
import { getDefaultLanguageId } from 'Parts/common/langUtils';

import DesignSettingsForm from './DesignSettingsForm';

const mapStateToProps = state => ({
  platformId: get(state, 'platform.platform.id', null),
  platform: get(state, 'platform.platform', null),
  platformLogo: get(state, 'platform.platform.media.logo', {}),
  languages: get(state, 'platform.languages'),
  colorPrimary: get(state, 'platform.platform.settings.color.primary', ''),
  colorSecondary: get(state, 'platform.platform.settings.color.secondary', ''),
  defaultLanguageId: getDefaultLanguageId(get(state, 'platform.languages', {})),
  locale: get(state, 'session.language', 'en')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformSettings,
  updatePlatformMedia,
  deletePlatformMedia,
  updatePlatform
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(DesignSettingsForm);

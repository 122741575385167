import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { storeUserInformation, cancelTransaction } from "../donationActions";
import { verifyEmail } from "../../parts/common/verificationUtils";
import { openLoginModal, openRegisterModal } from "../../parts/ui/uiActions";
import dataFetcher from "../../parts/chore/dataFetcher";

import CreateInformationForm from './CreateInformationForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  organization: state.organization.organization,
  countries: state.geo.countries,
  provinces: state.geo.provinces,
  infos: state.donation.donationInformation,
  user: state.session.user,
  userTypeId: state.session.userTypeId,
  locale: state.session.language,
  surveyId:state.donation.surveyId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  storeUserInformation,
  verifyEmail,
  openLoginModal,
  openRegisterModal,
  cancelTransaction
}, dispatch);


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create(),
  dataFetcher
)(CreateInformationForm);

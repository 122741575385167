import { compose } from "redux";
import { connect } from "react-redux";
import dataFetcher from 'Parts/chore/dataFetcher';
import AdminSidebarCampaignsSettings from './AdminSidebarCampaignsSettings';

const mapStateToProps = state => ({
  campaign: state.campaign.campaign,
  campaigns: state.search.campaigns.results,
  userCampaigns: state.participant.participations.results,
  locale: state.session.language
});

export default compose(
  connect(mapStateToProps),
  dataFetcher
)(AdminSidebarCampaignsSettings);

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateCampaign } from '../../../../campaign/campaignActions';

import AccessibilityBoxForm from './AccessibilityBoxForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  campaign: state.campaign.campaign
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateCampaign
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccessibilityBoxForm);

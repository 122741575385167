import { compose } from "redux";
import { connect } from "react-redux";
import SitemapPages from './SitemapPages';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  campaigns: state.campaign.campaigns,
});

export default compose(
  connect(mapStateToProps)
)(SitemapPages);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import dataFetcher from "../../parts/chore/dataFetcher";
import { selectCampaign, clearSelectedCampaign } from "../teamActions";
import { changeTeamCreateWizardStep } from "../../ui/uiActions";
import { activateCreationMode, deactivateCreationMode } from "../../parts/ui/uiActions";

import TeamCreatePage from './TeamCreatePage';

const mapStateToProps = state => ({
  currentStep: state.ui.teamCreateWizardState
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectCampaign,
  clearSelectedCampaign,
  changeTeamCreateWizardStep,
  activateCreationMode,
  deactivateCreationMode
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(TeamCreatePage);

import {
  FETCH_SURVEYS_LIST_START,
  FETCH_SURVEYS_LIST_SUCCESS,
  FETCH_SURVEYS_LIST_ERROR,
  DELETE_SURVEY_START,
  DELETE_SURVEYS_SUCCESS,
  DELETE_SURVEYS_ERROR,
} from './SurveysListPageActions';

const initialState = {
  isFetchSurveysInformationInProgress: false,
  isDeleteSurveyInProgress: false,
  surveysInformation: [],
};

export default function surveysInformation(state = initialState, action) {
  switch (action.type) {
    case FETCH_SURVEYS_LIST_START:
      return {
        ...state,
        isFetchSurveysInformationInProgress: true,
      };
    case FETCH_SURVEYS_LIST_SUCCESS:
      return {
        ...state,
        isFetchSurveysInformationInProgress: false,
        surveysInformation: action.payload,
      };
    case FETCH_SURVEYS_LIST_ERROR:
      return {
        ...state,
        isFetchSurveysInformationInProgress: false,
        surveysInformation: initialState.surveysInformation,
      };
    case DELETE_SURVEY_START:
      return {
        ...state,
        isDeleteSurveyInProgress: true,
      };
    case DELETE_SURVEYS_SUCCESS:
      return {
        ...state,
        isDeleteSurveyInProgress: false,
      };
    case DELETE_SURVEYS_ERROR:
      return {
        ...state,
        isDeleteSurveyInProgress: false,
      };
    default:
      return state;
  }
}

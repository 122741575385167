import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import dataFetcher from 'Parts/chore/dataFetcher';
import { get } from 'lodash';

import { checkAuthorization } from 'Parts/common/authorizationUtils';

import MyContributionsTeamsPage from './MyContributionsTeamsPage';

const mapStateToProps = state => ({
  team: state.team.team,
  locale: state.session.language,
  participation: state.participant.participation,
  participantName: get(state.participant.participant, 'member.name', ''),
  platformId: state.platform.id
});

const mapDispatchToProps = dispatch => bindActionCreators({
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(MyContributionsTeamsPage);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { get } from 'lodash';

import dataFetcher from 'Parts/chore/dataFetcher';
import { searchMemberTransactions, clearSearch } from '../../../search/searchActions';

import MyContributionsListDonations from './MyContributionsListDonations';

const mapStateToProps = state => ({
  platformId: get(state, 'platform.platform.id'),
  platform: get(state, 'platform.platform'),
  memberId: get(state, 'session.user.id'),
  transactions: get(state, 'search.transactions.results', []),
  pagination: get(state, 'search.transactions.pagination', {}),
  filters: get(state, 'search.transactions.filterBy'),
  search: get(state, 'search.transactions.search'),
  orderBy: get(state, 'search.transactions.orderBy'),
  locale: get(state, 'session.language'),
  organization: get(state, 'organization.organization')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchMemberTransactions,
  clearSearch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(MyContributionsListDonations);

import React from 'react';
import HeadMetas from '../../common/HeadMetasContainer';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';
import PublicTitleDropdown from '../../common/PublicTitleDropdownContainer';
import PublicThermometer from '../../common/PublicThermometer';
import PublicCaptainQuote from '../components/PublicCaptainQuoteContainer';
import HelpText from '../../common/HelpText';
import PublicDonationPanel from '../../common/PublicDonationPanelContainer';
import PublicParticipantPanel from '../../common/PublicParticipantPanelContainer';
import PublicCampaignBand from '../../common/PublicCampaignBand';
import SocialSharing from '../../common/SocialSharingContainer';
import PublicPageClosed from '../../common/PublicPageClosed';
import PublicPageMobileScripts from '../../common/PublicPageMobileScripts';
import { CAMPAIGN_TEMPLATE_SOLO } from '../../campaign/campaignUtils';

import TeamPublicPage_en from './locales/TeamPublicPage_en.json';
import TeamPublicPage_fr from './locales/TeamPublicPage_fr.json';

import './TeamPublicPage.less';
import { getPlatformUrl } from 'Parts/common/environment';

class TeamPublicPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TeamPublicPage', TeamPublicPage_en);
    i18next.addResourceBundle('fr', 'TeamPublicPage', TeamPublicPage_fr);
  }

  render() {
    const { t, locale, campaign, team, location, platform } = this.props;

    const settingsThermometer = get(campaign, 'settings.thermometer.behavior');
    const showThermometer = settingsThermometer === 'all' || settingsThermometer === 'children';

    const isCampaignClosed = campaign && campaign.statusId === 4 ? true : false;
    const isRegistrationDisabled =
      campaign &&
      campaign.settings &&
      campaign.settings.registration &&
      campaign.settings.registration.disabledOn
        ? isDateBeforeCurrentDate(campaign.settings.registration.disabledOn, true)
        : false;

    const isDonationsDisabled =
      campaign &&
      campaign.settings &&
      campaign.settings.donation &&
      campaign.settings.donation.disabledOn
        ? isDateBeforeCurrentDate(campaign.settings.donation.disabledOn, true)
        : false;

    const dummyBreadcrumbs = (
      <div className="TeamPublicPage__Breadcrumbs FundkyWrap globalDesktopOnly">
        <ul>
          <li>
            <Link to="/">{t('home')}</Link>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </li>
          <li>
            <Link to={`/${locale}/${campaign.alias}`}>{campaign.name}</Link>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </li>
          <li>{team.name}</li>
        </ul>
      </div>
    );

    const urlToShare = `${getPlatformUrl(platform.alias)}${location.pathname}`;

    return (
      <Layout className="TeamPublicPage ">
        <HeadMetas />
        <PublicCampaignBand
          campaign={campaign}
          lastCampaigns={this.props.lastCampaigns}
          locale={locale}
        />
        {dummyBreadcrumbs}
        <div className="TeamPublicPage__Container FundkyWrap">
          <div className="TeamPublicPage__Menu" id="titleDropdown">
            <div className="TeamPublicPage__TitleThermo">
              <HelpText>{t('team-label')}</HelpText>
              <PublicTitleDropdown
                helpLabel={t('team-competition-label')}
                title={team.name}
                entities={this.props.teams.filter(_team => _team.id !== team.id)}
                getEntityLinkUrl={entity => `/${locale}/${campaign.alias}/${entity.alias}`}
                additionalLinks={
                  <Link to={`/${locale}/${t('URL:create-a-team')}`}>
                    <FontAwesomeIcon icon={['fal', 'arrow-right']} />
                    {t('create-team')}
                  </Link>
                }
              />
              <div className="globalDesktopOnly">
                <PublicThermometer
                  className="TeamPublicPage__Thermometer"
                  locale={locale}
                  entity={team}
                  compact
                  giveUrl={{
                    pathname: `/${locale}/${team.campaignAlias}/${team.alias}/${t('URL:give')}`
                  }}
                  donationClosed={isDonationsDisabled || isCampaignClosed}
                  showThermometer={showThermometer}
                  isBillingConfigured={this.props.isBillingConfigured}
                />

                <PublicPageClosed location="Thermo" campaign={campaign} />
              </div>
            </div>
            <div className="globalDesktopOnly">
              <PublicCaptainQuote pathname={location.pathname} campaign={campaign} />

              <div className="TeamPublicPage__SocialSharing">
                <SocialSharing identity={'team'} urlToShare={urlToShare} />
              </div>
            </div>
          </div>
          <div className="TeamPublicPage__Content">
            <PublicPageMobileScripts
              donationURL={`/${locale}/${team.campaignAlias}/${team.alias}/${t('URL:give')}`}
              hideButtons={isCampaignClosed}
              donationDisabled={isDonationsDisabled}
              registrationDisabled={isRegistrationDisabled}
              isSinglePageCampaign={campaign.typeId === CAMPAIGN_TEMPLATE_SOLO}
              pageType="team"
              entity={team}
              locale={locale}
              socialShare={
                <SocialSharing identity={'team'} urlToShare={urlToShare} showTitle={false} />
              }
              showThermometer={showThermometer}
              platform={platform}
              campaign={campaign}
              isBillingConfigured={this.props.isBillingConfigured}
            />

            <PublicPageClosed location="Banner" campaign={campaign} />

            <div className="globalMobileOnly">
              <PublicPageClosed location={'Thermo'} campaign={campaign} />
            </div>
            <PublicParticipantPanel
              full="team"
              isClosed={isCampaignClosed || isRegistrationDisabled}
            />
            <PublicDonationPanel type="team" />
          </div>
        </div>
      </Layout>
    );
  }
}

TeamPublicPage.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  teams: PropTypes.array.isRequired,
  campaign: PropTypes.object.isRequired,
  locale: PropTypes.oneOf(['fr', 'en']).isRequired
};

export default translate('TeamPublicPage')(TeamPublicPage);

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";

import { getLastCampaigns } from "../../campaign/campaignSelectors";
import { fetchPublicInformation } from '../../common/publicActions';

import ParticipantPublicPage from './ParticipantPublicPage';

const mapStateToProps = state => ({
  platformAlias: state.platform.platform.alias,
  platformId: state.platform.platform.id,
  participant: state.participant.participant,
  participants: state.participant.participants,
  campaign: state.campaign.campaign,
  team: state.team.team,
  teams: state.team.teams,
  lastCampaigns: getLastCampaigns(state),
  locale: state.session.language,
  userId: state.session.userId,
  memberId: state.participant.participant.memberId,
  platform: state.platform.platform,
  isBillingConfigured: get(state, 'organization.isBillingConfigured'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPublicInformation
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantPublicPage);

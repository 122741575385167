import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import { isPluginActive } from '../../../../plugin/pluginActions';
import Plugin from '../../../../plugin/Plugin';

import DonationTypeField_en from './locales/DonationTypeField_en.json';
import DonationTypeField_fr from './locales/DonationTypeField_fr.json';

class DonationTypeField extends React.Component {
  constructor(props) {
    super(props);

    this.pluginRef = React.createRef();

    i18next.addResourceBundle('en', 'DonationTypeField', DonationTypeField_en);
    i18next.addResourceBundle('fr', 'DonationTypeField', DonationTypeField_fr);
  }

  handleChange = event => {
    this.props.onChange(event);
    let onChangeDonationTypeField = get(this.pluginRef, 'current.actions.onChangeDonationTypeField');
    if (isPluginActive(this.props.platform, 'TaxReceipt') && onChangeDonationTypeField)
      onChangeDonationTypeField(event, this.props.form);
  };

  render() {
    const { t, className } = this.props;
    const { getFieldDecorator } = this.props.form;
    const requiredMessage = this.props.requiredMessage || t('required');
    const input = (
      <Radio.Group
        onChange={this.handleChange}
      >
        <Radio value={1}>{t('personal')}</Radio>
        <Radio value={2}>{t('corporate')}</Radio>
        <Radio value={3}>{t('regroup')}</Radio>
      </Radio.Group>
    );

    const fieldId = 'type';
    const fieldOptions = {
      rules: [
        {
          required: true,
          message: requiredMessage
        }
      ],
      initialValue: this.props.initialValue
    };

    return (
      <>
        <Form.Item className={`${className} DonationTypeField`} hasFeedback>
          {getFieldDecorator(fieldId, fieldOptions)(input)}
        </Form.Item>
        {(
          isPluginActive(this.props.platform, 'TaxReceipt') &&
          this.props.plugin &&
          get(this.props, 'platform.statusId') === 1 &&
          get(this.props, 'organization.typeId') === 2
         ) &&
          <Plugin
            ref={this.pluginRef}
            name="TaxReceipt"
            component="TaxReceiptField"
            className={className}
            form={this.props.form}
            savedValue={this.props.pluginSavedValue}
            monthly={this.props.monthly || false}
          />
        }
      </>
    );
  }
}

DonationTypeField.defaultProps = {
  plugin: false,
  requiredMessage: null,
  initialValue: undefined,
  onChange: () => {}
};

DonationTypeField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  initialValue: PropTypes.number
};

export default translate('DonationTypeField')(DonationTypeField);

/**********************************************
 * List of imported FontAwesome icons.        *
 * If you add icons, sort them alphabeticaly. *
 **********************************************/

import {
  library as faLibrary
} from '@fortawesome/fontawesome-svg-core';

/*********
 * LIGHT *
 *********/
import {
  faAddressBook as falAddressBook,
  faArrowLeft as falArrowLeft,
  faArrowRight as falArrowRight,
  faArrowToBottom as falArrowToBottom,
  faAt as falAt,
  faAward as falAward,
  faBan as falBan,
  faBars as falBars,
  faBook as falBook,
  faBuilding as falBuilding,
  faCalendarAlt as falCalendarAlt,
  faChartPie as falChartPie,
  faCheckCircle as falCheckCircle,
  faClock as falClock,
  faCog as falCog,
  faCopy as falCopy,
  faCubes as falCubes,
  faDesktop as falDesktop,
  faCogs as falCogs,
  faDownload as falDownload,
  faEdit as falEdit,
  faEnvelope as falEnvelope,
  faExchange as falExchange,
  faExternalLink as falExternalLink,
  faEye as falEye,
  faFax as falFax,
  faFileDownload as falFileDownload,
  faFileInvoice as falFileInvoice,
  faFileInvoiceDollar as falFileInvoiceDollar,
  faFlag as falFlag,
  faGift as falGift,
  faGlobeAmericas as falGlobeAmericas,
  faHome as falHome,
  faIdCard as falIdCard,
  faImage as falImage,
  faInfoCircle as falInfoCircle,
  faLayerPlus as falLayerPlus,
  faListAlt as falListAlt,
  faLongArrowDown as falLongArrowDown,
  faLongArrowLeft as falLongArrowLeft,
  faLongArrowRight as falLongArrowRight,
  faMoneyCheckEditAlt as falMoneyCheckEditAlt,
  faPencilAlt as falPencilAlt,
  faPhone as falPhone,
  faPlus as falPlus,
  faReceipt as falReceipt,
  faSave as falSave,
  faShareSquare as falShareSquare,
  faSignature as falSignature,
  faSitemap as falSitemap,
  faSlidersH as falSlidersH,
  faSpinner as falSpinner,
  faTachometerAlt as falTachometerAlt,
  faTag as falTag,
  faTicketAlt as falTicketAlt,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faTrashAlt as falTrashAlt,
  faUsdCircle as falUsdCircle,
  faUser as falUser,
  faUserEdit as falUserEdit,
  faUserLock as falUserLock,
  faUserPlus as falUserPlus,
  faUsers as falUsers,
  faUsersCog as falUsersCog,
  faUserTimes as falUserTimes,
  faVideo as falVideo,
  faWrench as falWrench,

  // EDITORS
  faAlignCenter as falAlignCenter,
  faAlignJustify as falAlignJustify,
  faAlignLeft as falAlignLeft,
  faAlignRight as falAlignRight,
  faFont as falFont,
  faHeading as falHeading,
  faBold as falBold,
  faIndent as falIndent,
  faOutdent as falOutdent,
  faItalic as falItalic,
  faLink as falLink,
  faUnlink as falUnlink,
  faListOl as falListOl,
  faListUl as falListUl,
  faRemoveFormat as falRemoveFormat,
  faSubscript as falSubscript,
  faSuperscript as falSuperscript,
  faTextSize as falTextSize,
  faUnderline as falUnderline,
} from '@fortawesome/pro-light-svg-icons';

/***********
 * REGULAR *
 ***********/
import {
  faArrowRight as farArrowRight,
  faAward as farAward,
  faBell as farBell,
  faCalendarPlus as farCalendarPlus,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faEllipsisV as farEllipsisV,
  faEllipsisH as farEllipsisH,
  faEnvelope as farEnvelope,
  faExclamationCircle as farExclamationCircle,
  faH1 as farH1,
  faH2 as farH2,
  faH3 as farH3,
  faHeart as farHeart,
  faLink as farLink,
  faLongArrowRight as farLongArrowRight,
  faMapMarkerAlt as farMapMarkerAlt,
  faPlus as farPlus,
  faQuestionCircle as farQuestionCircle,
  faSearch as farSearch,
  faSignIn as farSignIn,
  faSlidersH as farSlidersH,
} from '@fortawesome/pro-regular-svg-icons';

/*********
 * SOLID *
 *********/
import {
  faAlarmClock as fasAlarmClock,
  faCaretDown as fasCaretDown,
  faCheck as fasCheck,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCreditCardFront as fasCreditCardFront,
  faExternalLinkAlt as fasExternalLinkAlt,
  faFilter as fasFilter,
  faInfoCircle as fasInfoCircle,
  faMapMarkerAlt as fasMapMerkerAlt,
  faPlus as fasPlus,
  faQuestionCircle as fasQuestionCircle,
  faSearch as fasSearch,
  faShare as fasShare,
  faSquare as fasSquare,
  faStar as fasStar,
  faThLarge as fasThLarge,
  faThList as fasThList,
  faTimes as fasTimes,
  faTrophy as fasTrophy,
  faTrophyAlt as fasTrophyAlt,
  faUsers as fasUsers,
  faUserCircle as fasUserCircle,
} from '@fortawesome/pro-solid-svg-icons';

/**********
 * BRANDS *
 **********/
import {
  faCcAmex as fabCcAmex,
  faCcDinersClub as fabCcDinersClub,
  faCcDiscover as fabCcDiscover,
  faCcJcb as fabCcJcb,
  faCcMastercard as fabCcMastercard,
  faCcVisa as fabCcVisa,
  faFacebook as fabFacebook,
  faFacebookF as fabFacebookF,
  faFacebookSquare as fabFacebookSquare,
  faInstagram as fabInstagram,
  faLinkedin as fabLinkedin,
  faLinkedinIn as fabLinkedinIn,
  faPinterest as fabPinterest,
  faPinterestP as fabPinterestP,
  faPinterestSquare as fabPinterestSquare,
  faSlideshare as fabSlideshare,
  faTwitter as fabTwitter,
  faTwitterSquare as fabTwitterSquare,
  faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons';

export default {
  init: () => faLibrary.add(
    // LIGHT
    falAddressBook,
    falArrowLeft,
    falArrowRight,
    falArrowToBottom,
    falAt,
    falAward,
    falBan,
    falBars,
    falBook,
    falBuilding,
    falCalendarAlt,
    falChartPie,
    falCheckCircle,
    falClock,
    falCog,
    falCogs,
    falCopy,
    falCubes,
    falDesktop,
    falDownload,
    falEdit,
    falEnvelope,
    falExchange,
    falExternalLink,
    falEye,
    falFax,
    falFileDownload,
    falFileInvoice,
    falFileInvoiceDollar,
    falFlag,
    falGift,
    falGlobeAmericas,
    falHome,
    falIdCard,
    falImage,
    falInfoCircle,
    falLayerPlus,
    falListAlt,
    falLongArrowDown,
    falLongArrowLeft,
    falLongArrowRight,
    falMoneyCheckEditAlt,
    falPencilAlt,
    falPhone,
    falPlus,
    falReceipt,
    falSave,
    falShareSquare,
    falSignature,
    falSitemap,
    falSlidersH,
    falSpinner,
    falTachometerAlt,
    falTag,
    falTicketAlt,
    falTimes,
    falTimesCircle,
    falTrashAlt,
    falUsdCircle,
    falUser,
    falUserEdit,
    falUserLock,
    falUserPlus,
    falUsers,
    falUsersCog,
    falUserTimes,
    falVideo,
    falWrench,

    // EDITORS LIGHT
    falAlignCenter,
    falAlignJustify,
    falAlignLeft,
    falAlignRight,
    falFont,
    falHeading,
    falBold,
    falIndent,
    falOutdent,
    falItalic,
    falLink,
    falUnlink,
    falListOl,
    falListUl,
    falRemoveFormat,
    falSubscript,
    falSuperscript,
    falTextSize,
    falUnderline,

    // REGULAR
    farArrowRight,
    farAward,
    farBell,
    farCalendarPlus,
    farChevronLeft,
    farChevronRight,
    farEllipsisV,
    farEllipsisH,
    farEnvelope,
    farExclamationCircle,
    farH1,
    farH2,
    farH3,
    farHeart,
    farLink,
    farLongArrowRight,
    farMapMarkerAlt,
    farPlus,
    farQuestionCircle,
    farSearch,
    farSignIn,
    farSlidersH,

    // SOLID
    fasAlarmClock,
    fasCaretDown,
    fasCheck,
    fasChevronLeft,
    fasChevronRight,
    fasCreditCardFront,
    fasExternalLinkAlt,
    fasFilter,
    fasInfoCircle,
    fasMapMerkerAlt,
    fasPlus,
    fasQuestionCircle,
    fasShare,
    fasSearch,
    fasShare,
    fasSquare,
    fasStar,
    fasThLarge,
    fasThList,
    fasTimes,
    fasTrophy,
    fasTrophyAlt,
    fasUsers,
    fasUserCircle,

    // BRANDS
    fabCcAmex,
    fabCcDinersClub,
    fabCcDiscover,
    fabCcJcb,
    fabCcMastercard,
    fabCcVisa,
    fabFacebook,
    fabFacebookF,
    fabFacebookSquare,
    fabInstagram,
    fabLinkedin,
    fabLinkedinIn,
    fabPinterest,
    fabPinterestP,
    fabPinterestSquare,
    fabSlideshare,
    fabTwitter,
    fabTwitterSquare,
    fabYoutube,
  )
};

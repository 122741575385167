import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import TitleField_en from './locales/TitleField_en.json';
import TitleField_fr from './locales/TitleField_fr.json';

class TitleField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TitleField', TitleField_en);
    i18next.addResourceBundle('fr', 'TitleField', TitleField_fr);
  }

  render() {
    const { t, form } = this.props;
    const { getFieldDecorator } = form;
    const Option = Select.Option;
    const placeholder = (
      this.props.placeholder === true ?
        t('title') :
        this.props.placeholder || null
    );
    const input = (
      this.props.fieldFormat === 'radio' ?
        (
          <Radio.Group>
            <Radio value={1}>{t('mr')}</Radio>
            <Radio value={2}>{t('mrs')}</Radio>
            <Radio value={3}>{t('miss')}</Radio>
            <Radio value={4}>{t('mx')}</Radio>
          </Radio.Group>
        ) :
        this.props.fieldFormat === 'select' ?
          (
            <Select
              className="TitleField-Select"
              suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
              placeholder={placeholder}
            >
              <Option value={1}>{t('mr')}</Option>
              <Option value={2}>{t('mrs')}</Option>
              <Option value={3}>{t('miss')}</Option>
              <Option value={4}>{t('mx')}</Option>
            </Select>
          ) : null
    );
    const fieldId = this.props.fieldId;
    const fieldOptions = {
      rules: [
        {
          required: this.props.required,
          message: this.props.requiredMessage || t('require')
        }
      ],
      initialValue: this.props.initialValue || undefined
    };
    const label = (
      this.props.label === true ?
        t('title') :
        this.props.label || null
    );

    return (
      <Form.Item
        className={`TitleField ${this.props.className}`}
        label={label}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

TitleField.defaultProps = {
  label: false,
  placeholder: false,
  fieldFormat: 'radio',
  fieldId: 'gender',
  initialValue: undefined,
  required: true,
  requiredMessage: null,
};

TitleField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  fieldFormat: PropTypes.oneOf(['radio', 'select']),
};

export default translate('TitleField')(TitleField);

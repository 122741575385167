import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Link } from "react-router-dom";
import { Form, Select, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEmpty }  from 'lodash';

import participantTeamFieldEN from './locales/ParticipantTeamField_en.json';
import participantTeamFieldFR from './locales/ParticipantTeamField_fr.json';

import './ParticipantTeamField.less';

const Option = Select.Option;

class ParticipantTeamField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      "selectedTeamId": get(props, 'data.id', 0)
    }

    this.updated = get(props, 'data.id', 0) ? true : false;

    i18next.addResourceBundle('en', 'ParticipantTeamField', participantTeamFieldEN);
    i18next.addResourceBundle('fr', 'ParticipantTeamField', participantTeamFieldFR);
  }

  componentDidUpdate() {
    if(this.props.campaignId && isEmpty(this.props.teams) && !this.search) {
      this.search = true
      this.props.searchTeamsInCampaign(this.props.campaignId, null, null, { pageResults: 99999}).
        then(() => {
          this.search = false
        });
    }
    if (
      get(this, 'props.data.id', 0) !== this.state.selectedTeamId &&
      !this.updated
    ) {
      this.setState({
        selectedTeamId: get(this, 'props.data.id', 0)
      });
      this.updated = true;
    }
  }

  handleChange = (value) => {
    this.setState({
      "selectedTeamId": value
    });
  }

  getTeamName = (id) => {
    let name = '';
    this.props.choices && this.props.choices.forEach(choice => {
      if(choice.id === id) {
        name = choice.name;
      }
    });
    return name;
  };

  render() {
    const
      { props, state, handleChange, getTeamName } = this,
      { t, form, choices, data, admin, locale, isCaptain, campaignId, disabled, match, campaignTypeId, checkAuthorization } = props,
      { selectedTeamId } = state,
      { getFieldDecorator } = form;

    const teamChoices = choices && choices.map(choice => {
      return (
        <Option key={choice.id} value={choice.id}>{choice.name}</Option>
      );
    });

    if (campaignTypeId === 3 && choices) {
      teamChoices.unshift(<Option key={0} value={0}>{t('No teams')}</Option>);
    }

    const minLength = campaignTypeId === 3 && choices ? 1 : 0;
    const selectedValue = (!data || data === '') ? 0 : data.id;
    let teamHref = '';
    if(selectedTeamId !== 0) {
      if(admin && campaignId) {
        teamHref = `/${locale}/d/campaigns/${campaignId}/teams/${selectedTeamId}`;
      } else {
        teamHref = `/${locale}/d/contributions/teams/${selectedTeamId}`;
      }
    }

    const actualLeaderId = choices && choices.filter(choice => choice.id === selectedValue).map(choice => choice.leaderId)[0] || null;
    const participantId = Number(match && match.params.participantID);

    const editTeamButton = checkAuthorization({team: selectedTeamId}, ['permissions.team.team.update', () => selectedTeamId !== 0 && participantId === actualLeaderId])
      ? <Link to={teamHref}><Button className="copy-url"><FontAwesomeIcon icon={["fal", "edit"]} /></Button></Link>
      : null;

    return (
      <Form.Item
        label={t('Team')}
      >
      {
        !disabled ? (
          getFieldDecorator('participant_team',
          { initialValue: selectedValue }
          )(<Select
            dropdownClassName="participant-team-field"
            showSearch
            style={{ width: 200 }}
            optionFilterProp="children"
            onChange={handleChange}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={isCaptain}
          >
            {teamChoices}
          </Select>)
        ) : (<span>{getTeamName(selectedValue)}</span>)
      }
      {editTeamButton}
      {(isCaptain && !disabled) &&
        <span className="cannot-change-captain">{t('cannot-change-captain')}</span>
      }
      </Form.Item>
    );
  }

}

ParticipantTeamField.defaultProps = {
  data: '',
  disabled: false,
  isCaptain: false,
  admin: false,
  campaignId: 0,
};

ParticipantTeamField.propTypes = {
  campaignTypeId: PropTypes.number.isRequired,
  isCaptain: PropTypes.bool,
  admin: PropTypes.bool,
  campaignId: PropTypes.number,
};

export default translate('ParticipantTeamField')(ParticipantTeamField);

import {
  compose,
  bindActionCreators
} from 'redux';
import {
  connect
} from "react-redux";
import dataFetcher from "../parts/chore/dataFetcher";

import {
  clearCampaign
} from '../campaign/campaignActions';

import CheckIndividualParticipantRoute from './CheckIndividualParticipantRoute';

const mapStateToProps = state => {
  return ({
    participant: state.participant.participant,
    isFetchParticipantInProgress: state.participant.isFetchParticipantInProgress,
    isFetchTeamInProgress: state.team.isFetchTeamInProgress,
    isFetchCampaignInProgress: state.campaign.isFetchCampaignInProgress
  });
};

const mapDispatchToProps = dispatch => bindActionCreators({
  clearCampaign
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(CheckIndividualParticipantRoute);

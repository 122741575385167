import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { CONNECTION_MODAL_CLOSED, CONNECTION_MODAL_REGISTER } from '../ui/uiUtils';

class AuthRoute extends React.Component {

  render() {
    const {redirectTo, userTypeId, forceLoginModal, openRegisterModal, component, ...rest} = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          if (userTypeId && userTypeId == 2) {
            return React.createElement(component, props);
          } else if (!redirectTo) {
            if(this.props.connectionModalState === CONNECTION_MODAL_CLOSED) {
              forceLoginModal(props.location.pathname, this.props.displaySessionTimeoutMessage);
            }

            return React.createElement(component, props);
          }

          if(this.props.connectionModalState === CONNECTION_MODAL_CLOSED && this.props.displaySessionTimeoutMessage) {
            forceLoginModal(redirectTo, this.props.displaySessionTimeoutMessage);
          }

          return (
            <Redirect
              to={{
                pathname: redirectTo,
                search: `?from=${props.location.pathname}${props.location.search}`
              }}
            />
          );
        }}
      />
    );
  }

}

AuthRoute.defaultProps = {
  redirectTo: null,
  user: null,
  forceLoginModal: null
};

AuthRoute.propTypes = {
  redirectTo: PropTypes.string,
  user: PropTypes.object,
  forceLoginModal: PropTypes.func
};

export default AuthRoute;

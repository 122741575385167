import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-remarkable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import InfoPanel_en from './locales/InfoPanel_en.json';
import InfoPanel_fr from './locales/InfoPanel_fr.json';

import './InfoPanel.less';

import '../../assets/images/Fundky_Logotype_2016._BGGeometric.jpg';
import '../../assets/images/FundkyLogotype2016_BlancSansPhrase.png';

class InfoPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'InfoPanel', InfoPanel_en);
    i18next.addResourceBundle('fr', 'InfoPanel', InfoPanel_fr);
  }

  render() {
    const { props } = this,
      { t, className } = props;

    return (
      <div className={`InfoPanel ${className}`}>
        <FontAwesomeIcon
          onClick={this.props.handleToggleInfo}
          className="InfoPanel--back"
          icon={['fal', 'arrow-left']}
        />
        <img
          src="/assets/FundkyLogotype2016_BlancSansPhrase.png"
          alt="Fundky Logo"
        />
        <h1>{t('whatis-title')}</h1>
        <Markdown>{t('whatis-desc')}</Markdown>
        <a href={t('whatis-url')} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fal', 'long-arrow-right']} />
          {t('whatis-link')}
        </a>
        <h1>
          {t('fundkyconnect-title')}
          <span>Fundky&nbsp;Connect</span>
        </h1>
        <Markdown>{t('fundkyconnect-desc')}</Markdown>
        <a
          href={t('fundkyconnect-url')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fal', 'long-arrow-right']} />
          {t('fundkyconnect-link')}
        </a>
      </div>
    );
  }
}

InfoPanel.defaultProps = {
  className: '',
  handleToggleInfo: () => {}
};

InfoPanel.propTypes = {
  className: PropTypes.string,
  handleToggleInfo: PropTypes.func
};

export default translate('InfoPanel')(InfoPanel);

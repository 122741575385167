import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import get from 'lodash/get';

import { openLoginModal, openRegisterModal, forceCloseConnectionModal } from "../parts/ui/uiActions";
import { setSessionLanguage } from "../parts/session/sessionActions";
import { fetchPlatform } from '../platform/platformActions';

import PublicLayout from './PublicLayout';

const mapStateToProps = state => ({
  locale: get(state, 'session.language'),
  connectionModalState: get(state, 'ui.connectionModalState'),
  colors: get(state, 'platform.platform.settings.color'),
  platformIndexation: get(state, 'platform.platform.settings.indexation'),
  platformId: get(state, 'platform.platform.id'),
  platform: get(state, 'platform.platform'),
  platformGTM: get(state, 'platform.platform.settings.tracking.gtm', null)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openLoginModal,
  openRegisterModal,
  setSessionLanguage,
  forceCloseConnectionModal,
  fetchPlatform
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PublicLayout);

import React from 'react';
import { Col, Row } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import SettingsColumn from '../../../components/SettingsColumn';
import SettingsBox from '../../../components/SettingsBox';
import SurveysPage_en from '../locales/SurveysPage_en.json';
import SurveysPage_fr from '../locales/SurveysPage_fr.json';
import '../../SurveysListPage.less';
import Papa from 'papaparse';

class ResultsTab extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'ResultsTab', SurveysPage_en);
    i18next.addResourceBundle('fr', 'ResultsTab', SurveysPage_fr);
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.match.params.id && (prevProps.locale !== this.props.locale)) {
      await this.props.fetchSurveyResultDetails(this.props.match.params.id, this.props.locale);
    }
  };

  handleExportCsv = () => {
    const data = this.props.resultDetails;
    if (data.length > 0) {
      // Define the header titles
      const headers = ['User Id', 'Survey Id', 'Survey Name', 'Email', 'Campaign Id', 'Ticket Ids'];

      // Flatten the data structure and prepare it for CSV conversion
      const flatData = [];
      var is_en_language = false;
      for (let i = 0; i < data.length; i++) {
        var csvArr = [
          data[i].questionAns[0].user_id,
          data[i].questionAns[0].survey_id,
          data[i].questionAns[0].survey_name,
          data[i].questionAns[0].email,
          data[i].questionAns[0].campaign,
          data[i].questionAns[0].ticket,
        ];

        for (let index = 0; index < data[i].questionAns.length; index++) {
          csvArr.push(data[i].questionAns[index].answer_text);
          // for language EN
          if (data[i].questionAns[index].language_id === 1) {
            is_en_language = true;
            const existingHeaderData_EN = headers.includes(`#${index + 1}. En - ${data[i].questionAns[index].question}`);
            if(!existingHeaderData_EN){
              headers.push(`#${index + 1}. En - ${data[i].questionAns[index].question}`);
            }
          }

          // for language FR
          if (data[i].questionAns[index].language_id === 2) {
            if (!is_en_language) {
              const existingHeaderData_FR = headers.includes(`#${index + 1}. Fr - ${data[i].questionAns[index].question}`);
              if(!existingHeaderData_FR)
                headers.push(`#${index + 1}. Fr - ${data[i].questionAns[index].question}`);
            } else {
              let inds = 0;
              const existingHeaderData_EN = headers.some(function (str, ind) {
                if (typeof str === 'string') {
                  if (str.includes(`#${index + 1}. En - `)) {
                    inds = ind
                    return true;
                  }
                }
              });
              if (existingHeaderData_EN) {
                if (!headers[inds].includes(` / Fr - ${data[i].questionAns[index].question}`)) {
                  headers[inds] += ` / Fr - ${data[i].questionAns[index].question}`
                }
              } else {
                const existingHeaderData_FR = headers.includes(`#${index + 1}. Fr - ${data[i].questionAns[index].question}`);
                if (!existingHeaderData_FR) {
                  headers.push(`#${index + 1}. Fr - ${data[i].questionAns[index].question}`);
                }
              }
            }
          }
        }
        flatData.push(csvArr);
      }

      // Add headers to the beginning of the flatData array
      flatData.unshift(headers);

      // Use papaparse to convert the data to a CSV string
      const csv = Papa.unparse(flatData);

      // Create a Blob, a download link, and trigger a click event to download the CSV
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${this.props.t("Results")}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };
  render() {
    // const Panel = Collapse.Panel;
    const { t } = this.props;
    return (
      <>
        <div className="SurveyInfoFormWrap">
          <div className="AdminTitleBar--title">
            <h2>{t('Results')}</h2>
          </div>
          <Row>
            <Col span={16}>
              {/* <div className="adminResultDetails" style={{ height: '90vh' }}>
                <Collapse bordered={false} defaultActiveKey={['1']}>
                  <Panel
                    header={
                      <>
                        <h2 className="adminResultCollapseTitle required">Lorem Ipsum</h2>
                        <p className='adminResultAnswerCount'>Lorem Ipsum simply printing</p>
                      </>
                    }
                    key="1"
                  >
                    <div className='adminResultBirthdayList'>
                      <div className='adminResultBirthdayItem'><p>simply printing</p></div>
                      <div className='adminResultBirthdayItem'><p>simply printing</p></div>
                      <div className='adminResultBirthdayItem'><p>simply printing</p></div>
                      <div className='adminResultBirthdayItem'><p>simply printing</p></div>
                    </div>
                  </Panel>
                  <Panel
                    header={
                      <>
                        <h2 className="adminResultCollapseTitle required">Lorem Ipsum</h2>
                        <p className="adminResultAnswerCount">Lorem Ipsum printing</p>
                      </>
                    }
                    key="2"
                  >
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                  </Panel>
                  <Panel
                    header={
                      <>
                        <h2 className="adminResultCollapseTitle">Lorem Ipsum is simply dummy?</h2>
                        <p className="adminResultAnswerCount">Lorem Ipsum is simply dummy</p>
                      </>
                    }
                    key="3"
                  >
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                  </Panel>
                  <Panel
                    header={
                      <>
                        <h2 className="adminResultCollapseTitle required">Lorem Ipsum is simply</h2>
                        <p className="adminResultAnswerCount">Lorem Ipsum is simply</p>
                      </>
                    }
                    key="4"
                  >
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                  </Panel>
                  <Panel
                    header={
                      <>
                        <h2 className="adminResultCollapseTitle required">Lorem Ipsum is simply</h2>
                        <p className="adminResultAnswerCount">Ipsum is simply</p>
                      </>
                    }
                    key="5"
                  >
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                  </Panel>
                </Collapse>
              </div> */}
            </Col>
            <Col span={8}>
              <SettingsColumn>
                <SettingsBox title={t('Results')} className="AccessibilityBoxForm">
                  <div className="AdminTitleBar--buttons">
                    <div className={`ant-btn ant-btn-secondary ${this.props.resultDetails.length !== 0 ? "":"disabled" }`} onClick={this.props.resultDetails.length !== 0 ? this.handleExportCsv: ""}>
                      {t('Export_CSV')}
                    </div>
                  </div>
                </SettingsBox>
              </SettingsColumn>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default translate('ResultsTab', { withRef: true })(ResultsTab);

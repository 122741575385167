import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from "../../parts/chore/dataFetcher";

import { checkAuthorization } from 'Parts/common/authorizationUtils';

import TransactionsPage from './TransactionsPage';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  permissions: state.session.permissions,
  transaction: state.donation.transaction,
  locale: state.session.language,
  organization: state.organization.organization,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  checkAuthorization,
  dispatch
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(TransactionsPage);

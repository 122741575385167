import React from 'react';
import HeadMetas from '../../common/HeadMetasContainer';
import ReactHtmlParser from 'react-html-parser';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import StaticTitle from '../components/StaticTitle';
import StaticBreadcrumbs from '../components/StaticBreadcrumbs';

import RefundPolicyPage_en from './locales/RefundPolicyPage_en.json';
import RefundPolicyPage_fr from './locales/RefundPolicyPage_fr.json';

import './RefundPolicyPage.less';

class RefundPolicyPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'RefundPolicyPage', RefundPolicyPage_en);
    i18next.addResourceBundle('fr', 'RefundPolicyPage', RefundPolicyPage_fr);
  }

  render() {
    const
      { props } = this,
      { t, locale, content, location, organization } = props;

    const path = [
      {
        name: t('home'),
        url : `/${locale}`
      },
      {
        name: t('refund-policy'),
        url: location
      }
    ];

    const refundPolicy = typeof get(content, 'refundPolicy.content') === 'string'
      ? ReactHtmlParser(get(content, 'refundPolicy.content').replace("{{organization}}", (organization.name || "[Organization]"))) || null
      : null;

    return (
      <div className="RefundPolicyPage FundkyWrap">
        <HeadMetas title={t('refund-policy')} />
        <StaticTitle title={t('refund-policy')} />
        <StaticBreadcrumbs path={path} />
        <div className="RefundPolicyPage__Content wysiwygStyle">
          { refundPolicy || (
            <React.Fragment>
              <p>{t('DEFAULT_REFUND_POLICY:no-refund', {organization: organization.name || "[Organization]"})} <br/></p>
              <p>{t('DEFAULT_REFUND_POLICY:more-info')}</p>
            </React.Fragment>
          ) }
        </div>
      </div>
    );
  }
}

export default translate('RefundPolicyPage')(RefundPolicyPage);

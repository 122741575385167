import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import partition from 'lodash/partition';
import includes from 'lodash/includes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import CountryField_en from './locales/CountryField_en.json';
import CountryField_fr from './locales/CountryField_fr.json';

import { FREQUENTLY_USED_COUNTRIES } from '../../../geo/geoUtils';

class CountryField extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    i18next.addResourceBundle('en', 'CountryField', CountryField_en);
    i18next.addResourceBundle('fr', 'CountryField', CountryField_fr);
  }

  handleChange(event) {
    this.props.onChangeHandler(event);
    this.props.form.setFieldsValue({
      province: undefined
    });
  }

  render() {
    const { t, locale, form, className } = this.props;
    const { Option, OptGroup } = Select;
    const { getFieldDecorator } = form;
    const [frequent, others] = partition(this.props.countries, country => includes(FREQUENTLY_USED_COUNTRIES, country.id));

    // We can only translate in EN or FR so we put english by default
    const lng = locale === 'en' || locale === 'fr' ? locale : 'en';

    const placeholder = (
      this.props.placeholder === true && this.props.label ?
        t('placeholder') :
        this.props.placeholder === true ?
          t('country') :
          this.props.placeholder || null
    );

    const requiredMessage = (
      this.props.requiredMessage ?
        this.props.requiredMessage :
        this.props.identity === "user" ?
          t('required') :
          this.props.identity === "company" ?
            t('required_company') :
            this.props.identity === "organization" ?
              t('required_organization') : null
    );

    const sortedCountries = others.sort((a, b) => {
      if(a[`name_${locale}`] < b[`name_${locale}`]) { return -1; }
      if(a[`name_${locale}`] > b[`name_${locale}`]) { return 1; }
      return 0;
    }).map(country => <Option key={country.id} value={country.id}>{country['name_' + lng]}</Option>);

    const input = (
      <Select
        showSearch
        optionFilterProp="children"
        suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
        onChange={this.handleChange}
        placeholder={placeholder}
        className="CountryField-Select"
        disabled={this.props.disabled}
        dropdownClassName={this.props.dropdownClassName}
        autocomplete="new-password"
        autoComplete="new-password"
        autoCorrect={false}
      >
        <OptGroup label={t('frequently_used')}>
          {frequent.map(country => <Option key={country.id} value={country.id}>{country['name_' + lng]}</Option>)}
        </OptGroup>
        <OptGroup label={t('others')}>
          {sortedCountries}
        </OptGroup>
      </Select>
    );
    const fieldId = 'country';
    const fieldOptions = {
      rules: [
          {
            required: this.props.required,
            message: requiredMessage
          }
        ],
      initialValue: this.props.initialValue || undefined
    };

    const label = (
      this.props.label === true ?
        t('country') :
        this.props.label || null
    );

    return (
      <Form.Item
        className={`CountryField ${className}`}
        label={label}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

CountryField.defaultProps = {
  className: '',
  label: true,
  placeholder: true,
  required: true,
  requiredMessage: null,
  locale: 'en',
  initialValue: undefined,
  identity: 'user',
  onChangeHandler: () => {},
  disabled: false,
  dropdownClassName: null,
};

CountryField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  countries: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func,
  identity: PropTypes.oneOf(['user', 'company', 'organization']),
};

export default translate('CountryField')(CountryField);

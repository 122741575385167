import { compose } from "redux";
import { connect } from "react-redux";
import withSizes from "react-sizes";

import PublicTeamPanel from './PublicTeamPanel';

import { getAntdBreakpoint } from "../../parts/common/responsiveUtils";
import { getParticipantsByTeam } from "../../participant/participantSelectors";

const mapStateToProps = state => ({
  teams: state.team.teams,
  participants: getParticipantsByTeam(state),
  campaign: state.campaign.campaign,
  locale: state.session.language,
  platform: state.platform.platform
});

const slidesToShowValues = {
  xxl: 3,
  xl: 2,
  lg: 1,
  md: 1,
  sm: 1,
  xs: 1
};

const mapSizesToProps = ({ width }) => ({
  slidesToShow: slidesToShowValues[getAntdBreakpoint(width)]
});

export default compose(
  withSizes(mapSizesToProps),
  connect(mapStateToProps),
)(PublicTeamPanel);

import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';

import CssVariableApplicator from 'Parts/ui/components/CssVariableApplicator';
import {
  CAMPAIGN_TEMPLATE_SOLO,
  CAMPAIGN_TEMPLATE_TEAMS,
  CAMPAIGN_TEMPLATE_PARTICIPANTS,
  CAMPAIGN_TEMPLATE_MIXED
} from '../campaign/campaignUtils';

import PublicCampaignBandMenu_en from './locales/PublicCampaignBandMenu_en.json';
import PublicCampaignBandMenu_fr from './locales/PublicCampaignBandMenu_fr.json';

i18next.addResourceBundle('en', 'PublicCampaignBandMenu', PublicCampaignBandMenu_en);
i18next.addResourceBundle('fr', 'PublicCampaignBandMenu', PublicCampaignBandMenu_fr);

import './PublicCampaignBandMenu.less';

class PublicCampaignBandMenu extends React.Component {
  constructor(props) {
    super(props);

    const { colors } = this.props;

    this.state = {
      formatedColors: {
        '--primary-color': colors && colors.primary || '',
        '--secondary-color': colors && colors.secondary || ''
      }
    };
  }

  render() {
    const { t, locale, campaign } = this.props;

    const isCampaignClosed = campaign && campaign.statusId === 4 ? true : false;

    const isRegistrationDisabled =
      campaign &&
      campaign.settings &&
      campaign.settings.registration &&
      campaign.settings.registration.disabledOn
        ? isDateBeforeCurrentDate(campaign.settings.registration.disabledOn, true)
        : false;

    const isDonationsDisabled =
      campaign &&
      campaign.settings &&
      campaign.settings.donation &&
      campaign.settings.donation.disabledOn
        ? isDateBeforeCurrentDate(campaign.settings.donation.disabledOn, true)
        : false;

    const participantsLimit =
      (campaign.settings && campaign.settings.participant && campaign.settings.participant.limit) ||
      0;
    const participantsCount = (campaign.childrenCount && campaign.childrenCount.participants) || 0;
    const teamsLimit =
      (campaign.settings && campaign.settings.team && campaign.settings.team.limit) || 0;
    const teamsCount = (campaign.childrenCount && campaign.childrenCount.teams) || 0;

    const participateLink =
      !isCampaignClosed && !isRegistrationDisabled && campaign.typeId !== CAMPAIGN_TEMPLATE_SOLO ? (
        campaign && campaign.participating ? (
          <Menu.Item>
            <Tooltip placement="top" title={t('already-in')}>
              <div>
                <Link
                  disabled
                  className="PublicCampaignBandMenu__Link"
                  to={{
                    pathname: `/${locale}/${t('URL:participant-registration')}`,
                    state: { campaign: campaign }
                  }}
                >
                  <FontAwesomeIcon
                    className="PublicCampaignBandMenu__Icon"
                    icon={['fal', 'user-plus']}
                    transform="right-2"
                  />
                  {t('participate')}
                </Link>
              </div>
            </Tooltip>
          </Menu.Item>
        ) : campaign.typeId === CAMPAIGN_TEMPLATE_MIXED ||
          participantsLimit == 0 ||
          (campaign.typeId === CAMPAIGN_TEMPLATE_PARTICIPANTS &&
            participantsLimit > participantsCount) ||
          (campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS &&
            (teamsLimit == 0 || participantsLimit * teamsLimit > participantsCount)) ? (
          <Menu.Item>
            <Link
              className="PublicCampaignBandMenu__Link"
              to={{
                pathname: `/${locale}/${t('URL:participant-registration')}`,
                state: { campaign: campaign }
              }}
            >
              <FontAwesomeIcon
                className="PublicCampaignBandMenu__Icon"
                icon={['fal', 'user-plus']}
                transform="right-2"
              />
              {t('participate')}
            </Link>
          </Menu.Item>
        ) : (
          <Menu.Item>
            <Tooltip placement="top" title={t('full')}>
              <div>
                <Link
                  disabled
                  className="PublicCampaignBandMenu__Link"
                  to={{
                    pathname: `/${locale}/${t('URL:participant-registration')}`,
                    state: { campaign: campaign }
                  }}
                >
                  <FontAwesomeIcon
                    className="PublicCampaignBandMenu__Icon"
                    icon={['fal', 'user-plus']}
                    transform="right-2"
                  />
                  {t('participate')}
                </Link>
              </div>
            </Tooltip>
          </Menu.Item>
        )
      ) : null;

    const createTeamLink =
      !isCampaignClosed &&
      !isRegistrationDisabled &&
      (campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS ||
        campaign.typeId === CAMPAIGN_TEMPLATE_MIXED) ? (
        campaign && campaign.participating ? (
          <Menu.Item>
            <Tooltip placement="top" title={t('already-in')}>
              <div>
                <Link
                  disabled
                  className="PublicCampaignBandMenu__Link"
                  to={{
                    pathname: `/${locale}/${t('URL:create-a-team')}`,
                    state: { campaign: campaign }
                  }}
                >
                  <span className="fa-layers PublicCampaignBandMenu__Icon">
                    <FontAwesomeIcon icon={['fal', 'users']} transform="left-1" />
                    <FontAwesomeIcon icon={['far', 'plus']} transform="shrink-10 right-11 up-1" />
                  </span>
                  {t('create-team')}
                </Link>
              </div>
            </Tooltip>
          </Menu.Item>
        ) : teamsLimit == 0 || teamsLimit > teamsCount ? (
          <Menu.Item>
            <Link
              className="PublicCampaignBandMenu__Link"
              to={{
                pathname: `/${locale}/${t('URL:create-a-team')}`,
                state: { campaign: campaign }
              }}
            >
              <span className="fa-layers PublicCampaignBandMenu__Icon">
                <FontAwesomeIcon icon={['fal', 'users']} transform="left-1" />
                <FontAwesomeIcon icon={['far', 'plus']} transform="shrink-10 right-11 up-1" />
              </span>
              {t('create-team')}
            </Link>
          </Menu.Item>
        ) : (
          <Menu.Item>
            <Tooltip placement="top" title={t('full')}>
              <div>
                <Link
                  disabled
                  className="PublicCampaignBandMenu__Link"
                  to={{
                    pathname: `/${locale}/${t('URL:create-a-team')}`,
                    state: { campaign: campaign }
                  }}
                >
                  <span className="fa-layers PublicCampaignBandMenu__Icon">
                    <FontAwesomeIcon icon={['fal', 'users']} transform="left-1" />
                    <FontAwesomeIcon icon={['far', 'plus']} transform="shrink-10 right-11 up-1" />
                  </span>
                  {t('create-team')}
                </Link>
              </div>
            </Tooltip>
          </Menu.Item>
        )
      ) : null;

    return (
      <CssVariableApplicator variables={this.state.formatedColors}>
        <Menu className="PublicCampaignBandMenu">
          <Menu.Item>
            <Link className="PublicCampaignBandMenu__Link" to={`/${locale}/${campaign.alias}`}>
              <FontAwesomeIcon className="PublicCampaignBandMenu__Icon" icon={['fal', 'home']} />
              {t('information')}
            </Link>
          </Menu.Item>
          {/* <Menu.Item>
            <Link className="PublicCampaignBandMenu__Link" to={`/${locale}`}>
              <FontAwesomeIcon className="PublicCampaignBandMenu__Icon" icon={["fal", "envelope"]} />
              {t('contact')}
            </Link>
          </Menu.Item> */}
          {!isCampaignClosed
            ? !isDonationsDisabled && (
                <Menu.Item>
                  <Link
                    className="PublicCampaignBandMenu__Link"
                    to={{ pathname: `/${locale}/${campaign.alias}/${t('URL:give')}` }}
                  >
                    <FontAwesomeIcon
                      className="PublicCampaignBandMenu__Icon"
                      icon={['fal', 'usd-circle']}
                    />
                    {t('donate')}
                  </Link>
                </Menu.Item>
              )
            : null}

          {participateLink}
          {createTeamLink}
        </Menu>
      </CssVariableApplicator>
    );
  }
}

PublicCampaignBandMenu.propTypes = {
  campaign: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  locale: PropTypes.oneOf(['fr', 'en']).isRequired
};

export default translate('PublicCampaignBandMenu')(PublicCampaignBandMenu);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import PasswordField_en from './locales/PasswordField_en.json';
import PasswordField_fr from './locales/PasswordField_fr.json';

class PasswordField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PasswordField', PasswordField_en);
    i18next.addResourceBundle('fr', 'PasswordField', PasswordField_fr);
  }

  render() {
    const { t, form, className } = this.props;
    const { getFieldDecorator } = form;

    const placeholder = (
      this.props.placeholder === true ?
        t('password') :
        this.props.placeholder || null
    );

    const input = (
      <Input
        type="password"
        placeholder={placeholder}
        autoComplete={this.props.newPassword ? 'new-password' : ''}
      />
    );
    const fieldId = (this.props.fieldId || 'password');
    const fieldOptions = {
      rules: [
          {
            required: this.props.required,
            message: this.props.requiredMessage || t('required')
          }
        ],
    };

    if (this.props.newPassword) {
      fieldOptions.rules.push({
        pattern: /^((?=.*\d)(?=.*[a-z])(?=.*[0-9])(?=.*[.?_+!$@#])(?=.*[a-zA-Z])).{8,}$/,
        /* pattern: /^((?=.*[a-z])(?=.*[0-9])(?=.*[\W])(?=.*[A-Z])).{8,}$/, */
        message: this.props.validationMessage || t('validation')
      });
    }

    const label = (
      this.props.label === true ?
        t('password') :
        this.props.label || null
    );

    return (
      <Form.Item
        className={className}
        label={label}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

PasswordField.defaultProps = {
  newPassword: false,
  label: true,
  placeholder: false,
  required: true,
  requiredMessage: null,
  validationMessage: null,
};

PasswordField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  newPassword: PropTypes.bool
};

export default translate('PasswordField')(PasswordField);

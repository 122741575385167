import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import { getLanguageNameByKey } from 'Parts/common/langUtils';
import { getCdnUrl } from 'Parts/common/environment';

import AvatarThumbnail_en from './locales/AvatarThumbnail_en.json';
import AvatarThumbnail_fr from './locales/AvatarThumbnail_fr.json';

import './AvatarThumbnail.less';

import '../assets/images/default_participant_picture_300x300.jpg';
import '../assets/images/fundky_admin_avatar.png';

function getOrdinal(locale, num) {
  if (locale === 'fr') {
    switch (num) {
      case 1:
        return 're';
      default:
        return 'e';
    }
  } else if (locale === 'en') {
    switch (num) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}

class AvatarThumbnail extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'AvatarThumbnail', AvatarThumbnail_en);
    i18next.addResourceBundle('fr', 'AvatarThumbnail', AvatarThumbnail_fr);
  }
  render() {
    const { props } = this,
      {
        t,
        locale,
        entity,
        className,
        primaryContent,
        secondaryContent,
        avatarSize,
        popover,
        captain,
        editable,
        action
      } = props;

    const computedClassName = classnames('AvatarThumbnail', className);
    const size = avatarSize ? avatarSize : 50;
    const cdnUrl = getCdnUrl();
    const lang = getLanguageNameByKey(locale, false);
    const avatarSelected = get(entity, 'avatar') ||
      get(entity, 'media.banner.banner') ||
      get(entity, 'banner.banner') ||
      get(entity, `logo[${lang}][0].logo`) ||
      get(entity, 'logo[0].logo') ||
      null;
    const avatarUrl = (avatarSelected)
      ? avatarSelected.indexOf("http") == -1
        ? `${cdnUrl}${avatarSelected}`
        : avatarSelected
      : null;

    let avatar = null;
    let avatar_class = null;

    if (avatarSelected) {
      avatar = null;
      avatar_class = {
        backgroundImage: `url('${avatarUrl}')`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'transparent'
      };
    } else if (
      (get(entity, 'member.profile.general') ||
        get(entity, 'member.name') ||
        get(entity, 'name')) &&
      !entity.leaderId
    ) {
      avatar = null;
      avatar_class = {
        backgroundImage: `url(/assets/default_participant_picture_300x300.jpg)`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundColor: 'transparent'
      };
    } else if (entity.admin) {
      avatar = null;
      avatar_class = {
        backgroundImage: `url(/assets/fundky_admin_avatar.png)`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundColor: 'transparent'
      };
    } else if (this.props.rank) {
      avatar = (
        <span>
          {this.props.rank}
          <span className="Global__Ordinal">{getOrdinal(this.props.locale, this.props.rank)}</span>
        </span>
      );
      avatar_class = null;
    } else if (entity.leaderId) {
      avatar = (
        <span>
          <FontAwesomeIcon icon={['fas', 'users']} className={``} />
        </span>
      );
      avatar_class = null;
    } else if (entity.firstName && entity.lastName && !entity.organizationName) {
      avatar = <span>{entity.firstName[0].toUpperCase() + entity.lastName[0].toUpperCase()}</span>;
      avatar_class = null;
    } else if (entity.organizationName) {
      avatar = <span>{entity.organizationName[0].toUpperCase()}</span>;
      avatar_class = null;
    }

    // REPLACED BY DEFAULT PICTURE
    // else
    // if (get(entity, 'member.profile.general') && get(entity, 'member.firstName') && get(entity, 'member.lastName')) {
    //   avatar = <span>{entity.member.firstName[0].toUpperCase() + entity.member.lastName[0].toUpperCase()}</span>;
    //   avatar_class = null;
    // } else
    // if (entity.name) {
    //   avatar = <span>{entity.name[0].toUpperCase()}</span>;
    //   avatar_class = null;
    // }

    const avatarContainer =
      avatar || avatar_class ? (
        <div className="AvatarThumbnail__Avatar" style={avatar_class}>
          {avatar}
        </div>
      ) : null;

    const avatarPopoverContainer =
      avatar || avatar_class ? (
        <div className="AvatarThumbnail__Popover__Content__Avatar" style={avatar_class}>
          {avatar}
        </div>
      ) : null;

    const captainIcon = captain ? <FontAwesomeIcon icon={['fas', 'star']} /> : null;
    const popoverContent = popover ? (
      <div className="AvatarThumbnail__Popover__Content">
        {avatarPopoverContainer}
        <div className="AvatarThumbnail__Popover__Content__Description">
          <p className="AvatarThumbnail__Popover__Content__Name">
            {get(entity, 'member.name') || get(entity, 'name', null)} {captainIcon}
          </p>
          <div className="AvatarThumbnail__Popover__Content__Actions">
            <Link
              to={this.props.url}
              className="ant-btn ant-btn-default"
              target={this.props.target}
            >
              {t('see-profile')}
            </Link>
          </div>
        </div>
      </div>
    ) : null;

    if (popover) {
      return (
        <Popover
          overlayClassName="AvatarThumbnail__Popover"
          content={popoverContent}
          placement="bottomLeft"
          getPopupContainer={() => document.getElementById('AvatarThumbnail')}
        >
          <div id="AvatarThumbnail" className={computedClassName}>
            {avatarContainer}
            <div className="AvatarThumbnail__Content">
              <span className="AvatarThumbnail__PrimaryContent">{primaryContent}</span>
              <span className="AvatarThumbnail__SecondaryContent">{secondaryContent}</span>
            </div>
          </div>
        </Popover>
      );
    }

    return (
      <div className={computedClassName}>
        {this.props.avatar && avatarContainer}
        <div className="AvatarThumbnail__Content">
          <span className="AvatarThumbnail__PrimaryContent">{primaryContent}</span>
          <span className="AvatarThumbnail__SecondaryContent">{secondaryContent}</span>
        </div>
        {action && (
          <a className="iconButton" onClick={action.onClick}>
            <FontAwesomeIcon icon={action.icon} />
          </a>
        )}
        {editable && (
          <Link className="iconButton" to={this.props.url} target={this.props.target}>
            <span className="fa-layers">
              <FontAwesomeIcon icon={['fal', 'user']} />
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
            </span>
          </Link>
        )}
      </div>
    );
  }
}

AvatarThumbnail.defaultProps = {
  className: null,
  secondaryContent: null,
  captain: null,
  url: null,
  avatar: true,
  popover: false,
  captain: false,
  editable: false
};

AvatarThumbnail.propTypes = {
  entity: PropTypes.object.isRequired,
  primaryContent: PropTypes.node.isRequired,
  secondaryContent: PropTypes.node,
  className: PropTypes.string,
  rank: PropTypes.number,
  locale: PropTypes.oneOf(['fr', 'en']),
  popover: PropTypes.bool,
  captain: PropTypes.bool,
  editable: PropTypes.bool
};

export default translate('AvatarThumbnail')(AvatarThumbnail);

import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import adminSidebarCampaignsSettingsEN from './locales/AdminSidebarCampaignsSettings_en.json';
import adminSidebarCampaignsSettingsFR from './locales/AdminSidebarCampaignsSettings_fr.json';
import CurrentCampaignDropdown from './components/CurrentCampaignDropdown';

class AdminSidebarCampaignsSettings extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AdminSidebarCampaignsSettings', adminSidebarCampaignsSettingsEN);
    i18next.addResourceBundle('fr', 'AdminSidebarCampaignsSettings', adminSidebarCampaignsSettingsFR);
  }

  render() {

    const { t, locale, campaign, campaigns } = this.props;
    const campaignID = this.props.match.params.campaignID;

    const _campaign = campaign || {};
    const _campaigns = campaigns && campaigns.filter(c => c.id !== campaignID) || [];

    // We need a combination of the react-router NavLink and the Antd Button... let's use NavLink and put the Antd classes on it.
    return (
      <div className="CampaignsSettingsSider">
        <CurrentCampaignDropdown
          campaign={_campaign}
          locale={locale}
          dropdownLabel={t('last-campaigns-label')}
          title={_campaign.name || ''}
          subtitle={t('Campaign')}
          entities={_campaigns}
          getEntityLinkUrl={entity => `/${locale}/${_campaign.alias || ''}`}
          path={this.props.match.path}
          additionalLinks={(
            <Link to={`/${locale}/${t('URL:create-a-campaign')}`}>
              <FontAwesomeIcon icon={["fal", "arrow-right"]} />{t('create-campaign')}
            </Link>
          )}
        />

        <Link
          to={`/${this.props.locale}/d/campaigns/${campaignID}`}
          className="AdminSider__GroupItem AdminSider__GroupTitle ant-btn ant-btn-lg"
        >
          <FontAwesomeIcon icon={["fal", "long-arrow-left"]} fixedWidth /> <span>{t('Settings')}</span>
        </Link>

        <NavLink
          exact
          to={`/${this.props.locale}/d/campaigns/${campaignID}/settings/registration`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
        <FontAwesomeIcon icon={["fal", "users-cog"]} fixedWidth /> {t('Registration')}
        </NavLink>
        <NavLink
          to={`/${this.props.locale}/d/campaigns/${campaignID}/settings/transactions`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
        <FontAwesomeIcon icon={["fal", "usd-circle"]} fixedWidth /> {t('Transactions')}
        </NavLink>
        <NavLink
          to={`/${this.props.locale}/d/campaigns/${campaignID}/settings/advanced`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
        <FontAwesomeIcon icon={["fal", "wrench"]} fixedWidth /> {t('Advanced')}
        </NavLink>
        {/* <NavLink
          to={`/${this.props.locale}/d/campaigns/${campaignID}/settings/emails`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
        <FontAwesomeIcon icon={["fal", "at"]} fixedWidth /> {t('Emails')}
        </NavLink> */}
      </div>
    );
  }
}

export default translate('AdminSidebarCampaignsSettings')(AdminSidebarCampaignsSettings);

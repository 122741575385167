import React from 'react';
import { Form } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { pickBy } from 'lodash';
import PropTypes from 'prop-types';

import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';
import TextField from 'Parts/ui/components/fields/TextField';
import FakeField from 'Parts/ui/components/fields/FakeField';
import SelectField from 'Parts/ui/components/fields/SelectField';
import InformationFormEN from './locales/InformationForm_en.json';
import InformationFormFR from './locales/InformationForm_fr.json';
import AddressForm from './AddressFormContainer';

import { nameFieldValidator, emailFieldValidator } from 'Parts/ui/validations/fieldValidations';

// import './InformationForm.less';

class InformationForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'InformationForm', InformationFormEN);
    i18next.addResourceBundle('fr', 'InformationForm', InformationFormFR);
  }

  handleSubmit = e => {
    e.preventDefault();

    const { validateFields, isFieldTouched } = this.props.form;

    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));
        const values = { ...updatedValues };

        this.props.updateMember(values, this.props.member.id, this.props.platformId).then(() => {
          this.props.updateSessionState();
        });
      }
    });
  };

  render() {
    const { t, member, userId, platformId, checkAuthorization } = this.props;

    const roleOptions = [
      //{ value: 1, text: t(`ROLES:${1}`), disabled: true },
      { value: 2, text: t(`ROLES:${2}`) },
      { value: 3, text: t(`ROLES:${3}`) },
      //{ value: 4, text: t(`ROLES:${4}`), disabled: true },
      { value: 5, text: t(`ROLES:${5}`) },
      { value: 6, text: t(`ROLES:${6}`) },
      { value: 7, text: t(`ROLES:${7}`) },
      { value: 8, text: t(`ROLES:${8}`) }
    ];

    const preferredLanguage = [
      { value: 1, text: t('english') },
      { value: 2, text: t('french') }
    ];

    // const optinChoices = [
    //   {value: 1, text: t('yes')},
    //   {value: 2, text: t('no')}
    // ];

    const titleOptions = [
      { value: 1, text: t('mr') },
      { value: 2, text: t('mrs') },
      { value: 3, text: t('ms') },
      { value: 4, text: t('mx') }
    ];

    // if current user is Owner or Admin, disable every fields.
    const isOwnerOrAdmin = checkAuthorization({ platform: platformId }, [
      ['roles.platform.SUPERADMIN'],
      // ['roles.platform.OWNER'],
      // ['roles.platform.ADMINISTRATOR']
    ]);

    const isTaxReceiptActive =
      this.props.plugins && this.props.plugins.length
        ? this.props.plugins.find(plugin => {
            return plugin.code === 'TAX_RECEIPT';
          })
        : false;

    return (
      <div className="InformationFormContainer">
        <h3>{t('basic-information')}</h3>
        <Form className="InformationForm" layout="horizontal">
          {member.roleId !== 1 && member.roleId !== 4 && isOwnerOrAdmin ? (
            <SelectField
              form={this.props.form}
              label={t('role')}
              fieldId={'roleId'}
              optionsArray={roleOptions}
              initialValue={member.roleId}
              style={{ width: 200 }}
            />
          ) : (
            <FakeField data={t(`ROLES:${member.roleId}`)} label={t('role')} />
          )}
          <SelectField
            disabled={userId !== member.id}
            form={this.props.form}
            label={t('title')}
            fieldId={'honorificId'}
            optionsArray={titleOptions}
            initialValue={member.honorificId || ''}
            style={{ width: 100 }}
          />
          <TextField
            disabled={userId !== member.id}
            form={this.props.form}
            label={t('FIELDS:first-name')}
            fieldId={'firstName'}
            initialValue={member.firstName}
            maxlength={75}
            counter={true}
            fieldOptions={{
              rules: [...nameFieldValidator(true, 75)]
            }}
          />
          <TextField
            disabled={userId !== member.id}
            form={this.props.form}
            label={t('FIELDS:last-name')}
            fieldId={'lastName'}
            initialValue={member.lastName}
            maxlength={75}
            counter={true}
            fieldOptions={{
              rules: [...nameFieldValidator(true, 75)]
            }}
          />
          <SelectField
            disabled={userId !== member.id}
            form={this.props.form}
            label={t('preferred-language')}
            fieldId={'languageId'}
            optionsArray={preferredLanguage}
            initialValue={member.languageId}
          />

          {/* NO OPTIN YET (AT LEAST NOT SAVED IN MEMBER) */}
          {/* <SelectField
            form={this.props.form}
            label={t('optin-platform')}
            fieldId={'optin_platform'}
            optionsArray={optinChoices}
            initialValue={member.languageId}
          />
          <SelectField
            form={this.props.form}
            label={t('optin-fundky')}
            fieldId={'optin_fundky'}
            optionsArray={optinChoices}
            initialValue={member.languageId}
          /> */}
          <TextField
            disabled={userId !== member.id}
            form={this.props.form}
            label={t('email')}
            fieldId={'email'}
            initialValue={member.email}
            fieldOptions={{
              rules: [...emailFieldValidator()]
            }}
          />

          <CancelAndSaveButtons onClickSave={this.handleSubmit} />
        </Form>

        <AddressForm user={member} origin="member" />
      </div>
    );
  }
}

InformationForm.propTypes = {
  form: PropTypes.object.isRequired
};

export default translate('InformationForm')(InformationForm);

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import { ConfigProvider, notification } from 'antd';
import fr_FR from 'antd/lib/locale-provider/fr_FR';
import en_US from 'antd/lib/locale-provider/en_US';
import moment from 'moment';
import { Provider, connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { I18nextProvider } from 'react-i18next';
import { translate } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import ScrollToTop from '../common/ScrollToTop';

import PublicLayout from '../layouts/PublicLayoutContainer';
import AdminLayout from '../layouts/AdminLayoutContainer';

import SitemapIndex from '../sitemap/SitemapIndexContainer';
import SitemapPages from '../sitemap/SitemapPagesContainer';
import SitemapCampaigns from '../sitemap/SitemapCampaignsContainer';

import AuthRoute from 'Parts/chore/AuthRouteContainer';
import DataFetchingProvider from 'Parts/chore/DataFetchingProvider';
import fetchMap from './fetchMap';
import { updateLocationHistory, handleWindowVisibility } from 'Parts/session/sessionActions';
import faLibrary from 'Parts/fontawesome/faLibrary';
import { dom, config as faConfig } from '@fortawesome/fontawesome-svg-core';
import { getReCaptchaKey } from 'Parts/common/environment';
import TwitchConnectRedirect from 'Parts/session/components/TwitchConnectRedirect';
import TwitchLinkRedirect from 'Parts/session/components/TwitchLinkRedirect';

faConfig.autoAddCss = false;
faLibrary.init();
dom.watch();

const Noscript = props => {
  const staticMarkup = ReactDOMServer.renderToStaticMarkup(props.children);
  return <noscript dangerouslySetInnerHTML={{ __html: staticMarkup }} />;
};
class App extends React.Component {
  constructor(props) {
    super(props);

    notification.config({
      placement: 'bottomRight'
    });

    if(typeof window !== 'undefined') {
      this.visibility = {};
      this.visibility.hidden = null;
      this.visibility.visibilityChange = null;
      if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        this.visibility.hidden = 'hidden';
        this.visibility.visibilityChange = 'visibilitychange';
      } else if (typeof document.msHidden !== 'undefined') {
        this.visibility.hidden = 'msHidden';
        this.visibility.visibilityChange = 'msvisibilitychange';
      } else if (typeof document.webkitHidden !== 'undefined') {
        this.visibility.hidden = 'webkitHidden';
        this.visibility.visibilityChange = 'webkitvisibilitychange';
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        window.dataLayer.push({
          event: 'page_view',
          page: {
            location: window.location.href,
            path: this.props.location.pathname,
            title: document.title,
            //from: prevProps.location.pathname,
            locale: this.props.locale
          }
        });
      }
    }, 1000);
    this.props.updateLocationHistory(this.props.location);

    if(typeof window !== 'undefined' && typeof document !== 'undefined') {
      document.addEventListener(this.visibility.visibilityChange, (e) => this.props.handleWindowVisibility(document[this.visibility.hidden]), false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener(this.visibilityChange, this.handleVisibilityChange);
  }

  componentDidUpdate(prevProps) {
    if (
      typeof window !== 'undefined' &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      setTimeout(() => {
        window.dataLayer.push({
          event: 'page_view',
          page: {
            location: window.location.href,
            path: this.props.location.pathname,
            title: document.title,
            from: prevProps.location.pathname,
            locale: this.props.locale
          }
        });
      }, 1000);
    }
    if (
      typeof window !== 'undefined' &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.props.updateLocationHistory(this.props.location);
    }
  }

  render() {
    const locale = this.props.store.getState().session.language;
    const { i18n, location } = this.props;
    const urlWithoutTrailingSlash =
      location.pathname.lastIndexOf('/', location.pathname.length - 1) ===
      location.pathname.length - 1
        ? location.pathname.substring(0, location.pathname.length - 1)
        : null;
    moment.locale(locale);

    const favUrl = '/assets/favicon-plateform.png';

    // This gives error saying the we cannot update state in render() - need to find other way to fix the problem
    // if(locale && locale !== i18n.language) {
    //   i18n.changeLanguage(locale);
    // }

    return (
      <I18nextProvider i18n={i18n}>
        <ConfigProvider locale={locale === 'fr' ? fr_FR : en_US}>
          <GoogleReCaptchaProvider
            reCaptchaKey={getReCaptchaKey()}
            language={locale}
            //useRecaptchaNet="[optional_boolean_value]"
          >
            <Provider store={this.props.store}>
              <DataFetchingProvider fetchMap={fetchMap}>
                <Noscript>
                  <div style={{
                    width:"100vw",
                    height:"100vh",
                    backgroundColor: "rgba(0,0,0,.5)",
                    position: "fixed",
                    "zIndex": "5000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <p style={{
                      padding: "30px",
                      backgroundColor: "#fff",
                      borderRadius: "4px",
                      margin: "20px",
                      textAlign: "center"
                    }}>{i18n.t('MESSAGE:noJS-text')} <a href={i18n.t('MESSAGE:noJS-url')} target="_blank">{i18n.t('MESSAGE:noJS-link')}</a></p>
                  </div>
                </Noscript>
                <Helmet>
                  <meta charset="utf-8" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                  />
                  <link rel="icon" type="image/x-icon" href={favUrl} />
                </Helmet>
                <Switch>
                  {urlWithoutTrailingSlash && (
                    <Redirect from={location.pathname + '/'} to={urlWithoutTrailingSlash} />
                  )}

                  <Route exact path="/login" component={TwitchConnectRedirect}/>
                  <Route exact path={`/${locale}/connect/twitch/:token`} component={TwitchLinkRedirect}/>

                  <AuthRoute
                    path={`/${locale}/d`}
                    component={AdminLayout}
                    redirectTo={`/${locale}/${i18n.t('URL:login')}`}
                  />
                  <Route exact path="/sitemap_index.xml" component={SitemapIndex} />
                  <Route exact path="/page-sitemap.xml" component={SitemapPages} />
                  <Route exact path="/campaign-sitemap.xml" component={SitemapCampaigns} />
                  <Route component={PublicLayout} />
                </Switch>

              </DataFetchingProvider>
            </Provider>
          </GoogleReCaptchaProvider>
        </ConfigProvider>
      </I18nextProvider>
    );
  }
}

App.propTypes = {
  locale: PropTypes.oneOf(['en', 'fr']).isRequired,
  i18n: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleWindowVisibility,
  updateLocationHistory
}, dispatch);

export default compose(connect(null, mapDispatchToProps), withRouter, translate())(App);

import React from 'react';
import { Button } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { isEmpty, get } from 'lodash';

import MyContributionsParticipationsPageForm from '../campaigns/participants/components/CampaignsParticipantsPageFormContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import AdminBackButtonBar from 'Parts/common/AdminBackButtonBar';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import MyContributionsParticipationsPageEN from './locales/MyContributionsParticipationsPage_en.json';
import MyContributionsParticipationsPageFR from './locales/MyContributionsParticipationsPage_fr.json';

class MyContributionsParticipationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.from = props.location.state && props.location.state.from;

    i18next.addResourceBundle(
      'en',
      'MyContributionsParticipationsPage',
      MyContributionsParticipationsPageEN
    );
    i18next.addResourceBundle(
      'fr',
      'MyContributionsParticipationsPage',
      MyContributionsParticipationsPageFR
    );
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { state } = this.props.location;
    if (state && state.from) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }
  }

  render() {
    const { t, participant, locale, participantName } = this.props;
    const contributionsListUrl = `/${locale}/d/contributions/list/participations`;
    const campaignUrlPart = get(participant, 'campaignAlias', '');
    const teamUrlPart = get(participant, 'teamAlias') || t('URL:individual-participant');
    const participantUrlPart = get(participant, 'member.alias', '');
    const currentParticipantUrl = `/${locale}/${campaignUrlPart}/${teamUrlPart}/${participantUrlPart}`;

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: props => (
          <Can me={true} redirect={`/${locale}/d`}>
            <MyContributionsParticipationsPageForm {...this.props} />
          </Can>
        )
      }
      //{title: t('Funds raised'), slug: 'donations', container: (props) => <CampaignsTeamsRegistrationPageTable {...this.props} />}
    ];

    return (
      <div className="MyContributionsParticipationsPage">
        <Helmet>
          <title>{t('Participant')}</title>
        </Helmet>
        <AdminBackButtonBar href={contributionsListUrl} text={t('Back to search')} />
        <AdminTitleBar
          upperTitle={t('Participant')}
          title={participantName}
          buttons={[
            <Button
              disabled={participant.statusId !== 1}
              type="secondary"
              className="btn-view-page"
              href={currentParticipantUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{t('View profile')}</span>{' '}
              <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
            </Button>
          ]}
        />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} defaultTabHasNoSlug />
      </div>
    );
  }
}

MyContributionsParticipationsPage.defaultProps = {
  teams: [],
  participant: {
    member: {},
    teamAlias: null,
    campaignAlias: null
  }
};

export default translate('MyContributionsParticipationsPage')(MyContributionsParticipationsPage);

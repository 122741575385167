import orderBy from 'lodash/orderBy';

import { CAMPAIGN_TEMPLATE_TEAMS, CAMPAIGN_TEMPLATE_PARTICIPANTS, CAMPAIGN_TEMPLATE_MIXED } from "./campaignUtils";

export function getLastCampaigns(state) {
  return orderBy(state.campaign.campaigns, 'addedOn', 'desc')
}

export function getCampaignsWithTeams(campaigns) {
  return campaigns.filter(campaign => campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS || campaign.typeId === CAMPAIGN_TEMPLATE_MIXED)
}

export function getCampaignsWithParticipants(campaigns) {
  return campaigns.filter(campaign => (campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS || campaign.typeId === CAMPAIGN_TEMPLATE_PARTICIPANTS || campaign.typeId === CAMPAIGN_TEMPLATE_MIXED))
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import Can from 'Parts/session/components/CanContainer';

import quickLinksEN from './locales/QuickLinks_en.json';
import quickLinksFR from './locales/QuickLinks_fr.json';

import './QuickLinks.less';

class QuickLinks extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'QuickLinks', quickLinksEN);
    i18next.addResourceBundle('fr', 'QuickLinks', quickLinksFR);
  }

  navLinkTemplate = items => {
    return items.map(item => (
      <NavLink
        key={item.label}
        to={item.link}
        className="QuickLinks__GroupItem"
        activeClassName="QuickLinks__GroupItem__GroupItem--active"
      >
        <FontAwesomeIcon icon={['far', 'long-arrow-right']} fixedWidth /> {item.label}
      </NavLink>
    ));
  };

  render() {
    const { props } = this,
      { t, locale, platformId } = props;

    // Add admin quicklinks here
    const adminMenuItems = [
      { label: t('CRM Transaction Search'), link: `/${locale}/d/transactions/filter` },
      { label: t('All Transactions'), link: `/${locale}/d/transactions/list` },
      { label: t('All Teams'), link: `/${locale}/d/teams/list` },
      { label: t('All Participants'), link: `/${locale}/d/participants/list` }
    ];

    // Add user quicklinks here
    const userMenuItems = [
      { label: t('My Profile'), link: `/${locale}/d/profile/${this.props.userId}/my-information` },
      { label: t('My Contributions'), link: `/${locale}/d/contributions/list/donations` }
    ];

    const adminMenu = (
      <Can
        platformId={platformId}
        rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
      >
        {this.navLinkTemplate(adminMenuItems)}
      </Can>
    );

    const userMenu = <>{this.navLinkTemplate(userMenuItems)}</>;

    return (
      <div className="QuickLinks">
        <span className="QuickLinks__title">{t('Quick Links')}</span>
        {/* ADMINISTRATOR / OWNER MENU */}
        {adminMenu}

        {/* USER */}
        {userMenu}
      </div>
    );
  }
}

export default translate('QuickLinks')(QuickLinks);

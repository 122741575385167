import { compose } from 'redux';
import { connect } from "react-redux";
import withSizes from 'react-sizes';

import FeaturedCampaignsSection from './FeaturedCampaignsSection';

function getAntdBreakpoint(width) {
  if (width >= 2168) {
    return 'xxl';
  } else if (width >= 1781) {
    return 'xl';
  } else if (width >= 1394) {
    return 'lg';
  } else if (width >= 768) {
    return 'md';
  } else if (width >= 576) {
    return 'sm';
  } else if (width >= 480) {
    return 'xs';
  }
}

const slidesToShowValues = {
  xxl: 4,
  xl: 3,
  lg: 2,
  md: 1,
  sm: 1,
  xs: 1
};

const mapSizesToProps = ({ width }) => ({
  slidesToShow: slidesToShowValues[getAntdBreakpoint(width)]
});

const mapStateToProps = state => ({
  campaigns: state.campaign.campaigns,
  featuredCampaigns: state.platform.featuredCampaigns,//getLastCampaigns(state),
  featuredTitle: state.platform.platformSections && state.platform.platformSections.featured && state.platform.platformSections.featured.campaigns && state.platform.platformSections.featured.campaigns.title,
  featuredDescription: state.platform.platformSections && state.platform.platformSections.featured && state.platform.platformSections.featured.campaigns && state.platform.platformSections.featured.campaigns.description,
  locale: state.session.language,
  platform: state.platform.platform,
});

export default compose(
  withSizes(mapSizesToProps),
  connect(mapStateToProps)
)(FeaturedCampaignsSection);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import dataFetcher from "../../parts/chore/dataFetcher";

import { searchCampaigns } from '../searchActions';

import SearchCampaignPanel from './SearchCampaignPanel';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  campaigns: state.search.campaigns.results,
  pagination: state.search.campaigns.pagination,
  filters: state.search.campaigns.filters,
  search: state.search.campaigns.search,
  orderBy: state.search.campaigns.orderBy,
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchCampaigns
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(SearchCampaignPanel)

import React from 'react';
import { Layout } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import AdminTitleBar from 'Parts/common/AdminTitleBar';
import PagesGuidelinesFormContainer from './components/PagesGuidelinesFormContainer';
import Can from 'Parts/session/components/CanContainer';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';

import PagesGuidelines_en from './locales/PagesGuidelines_en.json';
import PagesGuidelines_fr from './locales/PagesGuidelines_fr.json';

class PagesGuidelines extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PagesGuidelines', PagesGuidelines_en);
    i18next.addResourceBundle('fr', 'PagesGuidelines', PagesGuidelines_fr);
  }

  render() {
    const { t, locale } = this.props;

    const tabPages = [
      {
        title: t('guidelines'),
        slug: 'note',
        container: () => (
          <Can rules={[
            ['roles.platform.OWNER', 'permissions.platform.platform.update'],
            ['roles.platform.SUPERADMIN', 'permissions.platform.platform.update']
          ]} redirect={`/${locale}/d`}>
            <PagesGuidelinesFormContainer />
          </Can>
        )
      }
    ];

    return (
      <Layout className="PagesGuidelines">
        <Helmet>
          <title>{t('guidelines-title')}</title>
        </Helmet>
        <AdminTitleBar title={t('guidelines-title')} />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} defaultTabHasNoSlug/>
      </Layout>
    );
  }
}

export default translate('PagesGuidelines')(PagesGuidelines);

import {
  replace
} from 'react-router-redux';
import isBrowser from 'is-in-browser';
import i18next from 'i18next';
import {
  clearSessionId,
  clearSessionDate,
} from "../common/cookieUtils";
import {
  notification
} from "antd/lib/index";
import {
  AUTH_LOGOUT_SUCCESS
} from '../session/sessionActions';

const errorMiddleware = store => next => action => {
  const result = next(action);

  // Check error calls
  if (action.type && action.type.endsWith('_ERROR') && isBrowser) {
    // Session expired
    if (action.error && action.error.code && action.error.code === 'sessionExpired') {
      // Clean session and dispatch logout success
      clearSessionId();
      clearSessionDate();
      store.dispatch({
        type: AUTH_LOGOUT_SUCCESS
      });

      // Go to login page
      store.dispatch(replace({
        pathname: '/en/login'
      }));

      // Notification: Session expired
      notification.error({
        message: i18next.t('API_NOTIFICATIONS:session-expired'),
        description: i18next.t('API_NOTIFICATIONS:session-expired-description')
      });
    }
  }

  return result;
};

export default errorMiddleware;

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import RegisterForm from './RegisterForm';
import { openLoginModal } from "../../ui/uiActions";
import { register } from '../sessionActions';

const mapStateToProps = state => ({
  browser: state.session.browser,
  isRegisterInProgress: state.ui.isRegisterInProgress,
  platform: state.platform && state.platform.platform,
  organization: state.organization && state.organization.organization,
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openLoginModal,
  register
}, dispatch);

const mapPropsToFields = props => ({
  platformId: Form.createFormField({ value: props.platform && props.platform.id })
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }),
  withGoogleReCaptcha,
  Form.create({ mapPropsToFields })
)(RegisterForm);

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Button } from 'antd';
import SubmitButton from 'Parts/common/SubmitButton';
import { translate } from 'react-i18next';
import cancelAndSaveButtonsEN from './locales/CancelAndSaveButtons_en.json';
import cancelAndSaveButtonsFR from './locales/CancelAndSaveButtons_fr.json';

class CancelAndSaveButtons extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CancelAndSaveButtons', cancelAndSaveButtonsEN);
    i18next.addResourceBundle('fr', 'CancelAndSaveButtons', cancelAndSaveButtonsFR);
  }

  render() {
    const { t, className, onClickCancel, onClickSave, disabled, isSavingInProgress } = this.props;

    return (
      <div className={`${className} CancelAndSaveButtons__wrap`}>
        <Button type="danger" className="btn-cancel" onClick={onClickCancel} disabled={disabled}>
          {t('Cancel')}
        </Button>
        <SubmitButton
          className="btn-submit-wrap"
          icon="save"
          iconStyle="fal"
          loading={isSavingInProgress}
          overwriteSubmit={onClickSave}
          disabled={disabled}
        >
          {t('Save')}
        </SubmitButton>
      </div>
    );
  }
}

CancelAndSaveButtons.propTypes = {
  className: PropTypes.string,
  onClickCancel: PropTypes.func,
  onClickSave: PropTypes.func,
  isSavingInProgress: PropTypes.bool,
  disabled: PropTypes.bool
};

CancelAndSaveButtons.defaultProps = {
  className: '',
  onClickCancel: null,
  onClickSave: null,
  isSavingInProgress: false,
  disabled: false
};

export default translate('CancelAndSaveButtons')(CancelAndSaveButtons);

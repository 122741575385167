import {
  FETCH_PLATFORM_START,
  FETCH_PLATFORM_SUCCESS,
  FETCH_PLATFORM_ERROR,

  UPDATE_PLATFORM_START,
  UPDATE_PLATFORM_SUCCESS,
  UPDATE_PLATFORM_ERROR,

  FETCH_PLATFORM_MEDIA_START,
  FETCH_PLATFORM_MEDIA_SUCCESS,
  FETCH_PLATFORM_MEDIA_ERROR,

  UPDATE_PLATFORM_MEDIA_START,
  UPDATE_PLATFORM_MEDIA_SUCCESS,
  UPDATE_PLATFORM_MEDIA_ERROR,

  DELETE_PLATFORM_MEDIA_START,
  DELETE_PLATFORM_MEDIA_SUCCESS,
  DELETE_PLATFORM_MEDIA_ERROR,

  FETCH_PLATFORM_DESCRIPTION_START,
  FETCH_PLATFORM_DESCRIPTION_SUCCESS,
  FETCH_PLATFORM_DESCRIPTION_ERROR,

  UPDATE_PLATFORM_DESCRIPTION_START,
  UPDATE_PLATFORM_DESCRIPTION_SUCCESS,
  UPDATE_PLATFORM_DESCRIPTION_ERROR,

  DELETE_PLATFORM_DESCRIPTION_START,
  DELETE_PLATFORM_DESCRIPTION_SUCCESS,
  DELETE_PLATFORM_DESCRIPTION_ERROR,

  FETCH_PLATFORM_LANGUAGES_START,
  FETCH_PLATFORM_LANGUAGES_SUCCESS,
  FETCH_PLATFORM_LANGUAGES_ERROR,

  UPDATE_PLATFORM_LANGUAGES_START,
  UPDATE_PLATFORM_LANGUAGES_SUCCESS,
  UPDATE_PLATFORM_LANGUAGES_ERROR,

  DELETE_PLATFORM_LANGUAGES_START,
  DELETE_PLATFORM_LANGUAGES_SUCCESS,
  DELETE_PLATFORM_LANGUAGES_ERROR,

  FETCH_PLATFORM_SETTINGS_START,
  FETCH_PLATFORM_SETTINGS_SUCCESS,
  FETCH_PLATFORM_SETTINGS_ERROR,

  UPDATE_PLATFORM_SETTINGS_START,
  UPDATE_PLATFORM_SETTINGS_SUCCESS,
  UPDATE_PLATFORM_SETTINGS_ERROR,

  FETCH_PLATFORM_CONTENT_START,
  FETCH_PLATFORM_CONTENT_SUCCESS,
  FETCH_PLATFORM_CONTENT_ERROR,

  UPDATE_PLATFORM_CONTENT_START,
  UPDATE_PLATFORM_CONTENT_SUCCESS,
  UPDATE_PLATFORM_CONTENT_ERROR,

  DELETE_PLATFORM_CONTENT_START,
  DELETE_PLATFORM_CONTENT_SUCCESS,
  DELETE_PLATFORM_CONTENT_ERROR,

  FETCH_PLATFORM_NOTE_START,
  FETCH_PLATFORM_NOTE_SUCCESS,
  FETCH_PLATFORM_NOTE_ERROR,

  UPDATE_PLATFORM_NOTE_START,
  UPDATE_PLATFORM_NOTE_SUCCESS,
  UPDATE_PLATFORM_NOTE_ERROR,

  DELETE_PLATFORM_NOTE_START,
  DELETE_PLATFORM_NOTE_SUCCESS,
  DELETE_PLATFORM_NOTE_ERROR,

  FETCH_PLATFORM_FEATURED_CAMPAIGNS_START,
  FETCH_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS,
  FETCH_PLATFORM_FEATURED_CAMPAIGNS_ERROR,

  UPDATE_PLATFORM_FEATURED_CAMPAIGNS_START,
  UPDATE_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS,
  UPDATE_PLATFORM_FEATURED_CAMPAIGNS_ERROR,

  FETCH_PLATFORM_SECTION_START,
  FETCH_PLATFORM_SECTION_SUCCESS,
  FETCH_PLATFORM_SECTION_ERROR,

  UPDATE_PLATFORM_SECTION_START,
  UPDATE_PLATFORM_SECTION_SUCCESS,
  UPDATE_PLATFORM_SECTION_ERROR,

  DELETE_PLATFORM_SECTION_START,
  DELETE_PLATFORM_SECTION_SUCCESS,
  DELETE_PLATFORM_SECTION_ERROR
} from './platformActions';
import {
  FETCH_DONATION_LIST_BY_PLATFORM_SUCCESS
} from "../donation/donationActions";

const initialState = {
  platform: {
    media: null,
    settings: null,
    plugins: null,
    description: null
  },
  lastDonations: [],
  content: {},
  notes: {},
  platformSections: {},
  featuredCampaigns: {},

  isFetchPlatformInProgress: false,
  isUpdatePlatformInProgress: false,

  isFetchPlatformMediaInProgress: false,
  isUpdatePlatformMediaInProgress: false,
  isDeletePlatformMediaInProgress: false,

  isFetchPlatformDescriptionInProgress: false,
  isUpdatePlatformDescriptionInProgress: false,
  isDeletePlatformDescriptionInProgress: false,

  isFetchPlatformLanguagesInProgress: false,
  isUpdatePlatformLanguagesInProgress: false,
  isDeletePlatformLanguagesInProgress: false,

  isFetchPlatformSettingsInProgress: false,
  isUpdatePlatformSettingsInProgress: false,

  isFetchPlatformContentInProgress: false,
  isUpdatePlatformContentInProgress: false,
  isDeletePlatformContentInProgress: false,

  isFetchPlatformNoteInProgress: false,
  isUpdatePlatformNoteInProgress: false,
  isDeletePlatformNoteInProgress: false,

  isFetchPlatformFeaturedCampaignsInProgress: false,
  isUpdatePlatformFeaturedCampaignsInProgress: false,

  isFetchPlatformSectionInProgress: false,
  isUpdatePlatformSectionInProgress: false,
  isDeletePlatformSectionInProgress: false
};

export default function platformReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DONATION_LIST_BY_PLATFORM_SUCCESS:
      return {
        ...state,
        lastDonations: action.payload
      };

// PLAFORM //

    // FETCH PLATFORM
    case FETCH_PLATFORM_START:
      return {
        ...state,
        isFetchPlatformInProgress: true
      };

    case FETCH_PLATFORM_SUCCESS:
      return {
        ...state,
        platform: {
          ...state.platform,
          ...action.payload
        },
        isFetchPlatformInProgress: false
      };

    case FETCH_PLATFORM_ERROR:
      return {
        ...state,
        //KEEP OLD PLAFORM DATA TO PREVENT CRASH
        //platform: initialState.platform,
        isFetchPlatformInProgress: false
      };

    // UPDATE PLATFORM
    case UPDATE_PLATFORM_START:
      return {
        ...state,
        isUpdatePlatformInProgress: true
      };

    case UPDATE_PLATFORM_SUCCESS:
      return {
        ...state,
        isUpdatePlatformInProgress: false
      };

    case UPDATE_PLATFORM_ERROR:
      return {
        ...state,
        isUpdatePlatformInProgress: false
      };

// PLATFORM MEDIA //

    // FETCH PLATFORM MEDIA
    case FETCH_PLATFORM_MEDIA_START:
      return {
        ...state,
        isFetchPlatformMediaInProgress: true
      };

    case FETCH_PLATFORM_MEDIA_SUCCESS:
      return {
        ...state,
        platform: {
          ...state.platform,
          media: {
            ...state.platform.media,
            ...action.payload
          }
        },
        isFetchPlatformMediaInProgress: false
      };

    case FETCH_PLATFORM_MEDIA_ERROR:
      return {
        ...state,
        platform: {
          ...state.platform,
          media: initialState.platform.media
        },
        isFetchPlatformMediaInProgress: false
      };

    // UPDATE PLATFORM MEDIA
    case UPDATE_PLATFORM_MEDIA_START:
      return {
        ...state,
        isUpdatePlatformMediaInProgress: true
      };

    case UPDATE_PLATFORM_MEDIA_SUCCESS:
      return {
        ...state,
        isUpdatePlatformMediaInProgress: false
      };

    case UPDATE_PLATFORM_MEDIA_ERROR:
      return {
        ...state,
        isUpdatePlatformMediaInProgress: false
      };

    // DELETE PLATFORM MEDIA
    case DELETE_PLATFORM_MEDIA_START:
      return {
        ...state,
        isDeletePlatformMediaInProgress: true
      };

    case DELETE_PLATFORM_MEDIA_SUCCESS:
      return {
        ...state,
        isDeletePlatformMediaInProgress: false
      };

    case DELETE_PLATFORM_MEDIA_ERROR:
      return {
        ...state,
        isDeletePlatformMediaInProgress: false
      };

// PLATFORM DESCRIPTION //

    // FETCH PLATFORM DESCRIPTION
    case FETCH_PLATFORM_DESCRIPTION_START:
      return {
        ...state,
        isFetchPlatformDescriptionInProgress: true
      };

    case FETCH_PLATFORM_DESCRIPTION_SUCCESS:
      return {
        ...state,
        platform: {
          ...state.platform,
          description: {
            ...action.payload
          }
        },
        isFetchPlatformDescriptionInProgress: false
      };

    case FETCH_PLATFORM_DESCRIPTION_ERROR:
      return {
        ...state,
        platform: {
          ...state.platform,
          description: initialState.platform.description
        },
        isFetchPlatformDescriptionInProgress: false
      };

    // UPDATE PLATFORM DESCRIPTION
    case UPDATE_PLATFORM_DESCRIPTION_START:
      return {
        ...state,
        isUpdatePlatformDescriptionInProgress: true
      };

    case UPDATE_PLATFORM_DESCRIPTION_SUCCESS:
      return {
        ...state,
        isUpdatePlatformDescriptionInProgress: false
      };

    case UPDATE_PLATFORM_DESCRIPTION_ERROR:
      return {
        ...state,
        isUpdatePlatformDescriptionInProgress: false
      };

    // DELETE PLATFORM DESCRIPTION
    case DELETE_PLATFORM_DESCRIPTION_START:
      return {
        ...state,
        isDeletePlatformDescriptionInProgress: true
      };

    case DELETE_PLATFORM_DESCRIPTION_SUCCESS:
      return {
        ...state,
        isDeletePlatformDescriptionInProgress: false
      };

    case DELETE_PLATFORM_DESCRIPTION_ERROR:
      return {
        ...state,
        isDeletePlatformDescriptionInProgress: false
      };

// PLATFORM LANGUAGES //

    // FETCH PLATFORM LANGUAGES
    case FETCH_PLATFORM_LANGUAGES_START:
      return {
        ...state,
        isFetchPlatformLanguagesInProgress: true
      };

    case FETCH_PLATFORM_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: {
          ...state.languages,
          ...action.payload
        },
        isFetchPlatformLanguagesInProgress: false
      };

    case FETCH_PLATFORM_LANGUAGES_ERROR:
      return {
        ...state,
        languages: initialState.languages,
        isFetchPlatformLanguagesInProgress: false
      };

    // UPDATE PLATFORM LANGUAGES
    case UPDATE_PLATFORM_LANGUAGES_START:
      return {
        ...state,
        isUpdatePlatformLanguagesInProgress: true
      };

    case UPDATE_PLATFORM_LANGUAGES_SUCCESS:
      return {
        ...state,
        isUpdatePlatformLanguagesInProgress: false
      };

    case UPDATE_PLATFORM_LANGUAGES_ERROR:
      return {
        ...state,
        isUpdatePlatformLanguagesInProgress: false
      };

    // DELETE PLATFORM LANGUAGES
    case DELETE_PLATFORM_LANGUAGES_START:
      return {
        ...state,
        isDeletePlatformLanguagesInProgress: true
      };

    case DELETE_PLATFORM_LANGUAGES_SUCCESS:
      return {
        ...state,
        isDeletePlatformLanguagesInProgress: false
      };

    case DELETE_PLATFORM_LANGUAGES_ERROR:
      return {
        ...state,
        isDeletePlatformLanguagesInProgress: false
      };

// PLATFORM SETTINGS //

    // FETCH PLATFORM SETTINGS
    case FETCH_PLATFORM_SETTINGS_START:
      return {
        ...state,
        isFetchPlatformSettingsInProgress: true
      };

    case FETCH_PLATFORM_SETTINGS_SUCCESS:
      return {
        ...state,
        platform: {
          ...state.platform,
          settings: {
            ...state.platform.settings,
            ...action.payload
          }
        },
        isFetchPlatformSettingsInProgress: false
      };

    case FETCH_PLATFORM_SETTINGS_ERROR:
      return {
        ...state,
        platform: {
          ...state.platform,
          settings: initialState.platform.settings
        },
        isFetchPlatformSettingsInProgress: false
      };

    // UPDATE PLATFORM SETTINGS
    case UPDATE_PLATFORM_SETTINGS_START:
      return {
        ...state,
        isUpdatePlatformSettingsInProgress: true
      };

    case UPDATE_PLATFORM_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdatePlatformSettingsInProgress: false
      };

    case UPDATE_PLATFORM_SETTINGS_ERROR:
      return {
        ...state,
        isUpdatePlatformSettingsInProgress: false
      };

// PLATFORM CONTENT //

    // FETCH PLATFORM CONTENT
    case FETCH_PLATFORM_CONTENT_START:
      return {
        ...state,
        isFetchPlatformContentInProgress: true,
      };

    case FETCH_PLATFORM_CONTENT_SUCCESS:
      return {
        ...state,
        content: action.payload,
        isFetchPlatformContentInProgress: false,
      };

    case FETCH_PLATFORM_CONTENT_ERROR:
      return {
        ...state,
        content: initialState.content,
        isFetchPlatformContentInProgress: false,
      };

    // UPDATE PLATFORM CONTENT
    case UPDATE_PLATFORM_CONTENT_START:
      return {
        ...state,
        isUpdatePlatformContentInProgress: true,
      };

    case UPDATE_PLATFORM_CONTENT_SUCCESS:
      return {
        ...state,
        isUpdatePlatformContentInProgress: false,
      };

    case UPDATE_PLATFORM_CONTENT_ERROR:
      return {
        ...state,
        isUpdatePlatformContentInProgress: false,
      };

    // DELETE PLATFORM CONTENT
    case DELETE_PLATFORM_CONTENT_START:
      return {
        ...state,
        isDeletePlatformContentInProgress: true,
      };

    case DELETE_PLATFORM_CONTENT_SUCCESS:
      return {
        ...state,
        isDeletePlatformContentInProgress: false,
      };

    case DELETE_PLATFORM_CONTENT_ERROR:
      return {
        ...state,
        isDeletePlatformContentInProgress: false,
      };

  // PLATFORM NOTE //

    // FETCH PLATFORM NOTE
    case FETCH_PLATFORM_NOTE_START:
      return {
        ...state,
        isFetchPlatformNoteInProgress: true,
      };

    case FETCH_PLATFORM_NOTE_SUCCESS:
      return {
        ...state,
        notes: action.payload,
        isFetchPlatformNoteInProgress: false,
      };

    case FETCH_PLATFORM_NOTE_ERROR:
      return {
        ...state,
        notes: initialState.notes,
        isFetchPlatformNoteInProgress: false,
      };

    // UPDATE PLATFORM NOTE
    case UPDATE_PLATFORM_NOTE_START:
      return {
        ...state,
        isUpdatePlatformNoteInProgress: true,
      };

    case UPDATE_PLATFORM_NOTE_SUCCESS:
      return {
        ...state,
        isUpdatePlatformNoteInProgress: false,
      };

    case UPDATE_PLATFORM_NOTE_ERROR:
      return {
        ...state,
        isUpdatePlatformNoteInProgress: false,
      };

    // DELETE PLATFORM NOTE
    case DELETE_PLATFORM_NOTE_START:
      return {
        ...state,
        isDeletePlatformNoteInProgress: true,
      };

    case DELETE_PLATFORM_NOTE_SUCCESS:
      return {
        ...state,
        isDeletePlatformNoteInProgress: false,
      };

    case DELETE_PLATFORM_NOTE_ERROR:
      return {
        ...state,
        isDeletePlatformNoteInProgress: false,
      };

// PLATFORM FEATURED CAMPAIGNS

    // FETCH PLATFORM FEATURED CAMPAIGNS
    case FETCH_PLATFORM_FEATURED_CAMPAIGNS_START:
      return {
        ...state,
        isFetchPlatformFeaturedCampaignsInProgress: true
      };

    case FETCH_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        featuredCampaigns: action.payload,
        isFetchPlatformFeaturedCampaignsInProgress: false
      };

    case FETCH_PLATFORM_FEATURED_CAMPAIGNS_ERROR:
      return {
        ...state,
        featuredCampaigns: initialState.featuredCampaigns,
        isFetchPlatformFeaturedCampaignsInProgress: false
      };

    // UPDATE PLATFORM FEATURED CAMPAIGNS
    case UPDATE_PLATFORM_FEATURED_CAMPAIGNS_START:
      return {
        ...state,
        isUpdatePlatformFeaturedCampaignsInProgress: true
      };

    case UPDATE_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        isUpdatePlatformFeaturedCampaignsInProgress: false
      };

    case UPDATE_PLATFORM_FEATURED_CAMPAIGNS_ERROR:
      return {
        ...state,
        isUpdatePlatformFeaturedCampaignsInProgress: false
      };

// PLATFORM SECTION

    // FETCH PLATFORM SECTION
    case FETCH_PLATFORM_SECTION_START:
      return {
        ...state,
        isFetchPlatformSectionInProgress: true
      };

    case FETCH_PLATFORM_SECTION_SUCCESS:
      return {
        ...state,
        platformSections: action.payload,
        isFetchPlatformSectionInProgress: false
      };

    case FETCH_PLATFORM_SECTION_ERROR:
      return {
        ...state,
        platformSections: initialState.platformSections,
        isFetchPlatformSectionInProgress: false
      };

    // UPDATE PLATFORM SECTION
    case UPDATE_PLATFORM_SECTION_START:
      return {
        ...state,
        isUpdatePlatformSectionInProgress: true
      };

    case UPDATE_PLATFORM_SECTION_SUCCESS:
      return {
        ...state,
        isUpdatePlatformSectionInProgress: false
      };

    case UPDATE_PLATFORM_SECTION_ERROR:
      return {
        ...state,
        isUpdatePlatformSectionInProgress: false
      };

    // DELETE PLATFORM SECTION
    case DELETE_PLATFORM_SECTION_START:
      return {
        ...state,
        isDeletePlatformSectionInProgress: true
      };

    case DELETE_PLATFORM_SECTION_SUCCESS:
      return {
        ...state,
        isDeletePlatformSectionInProgress: false
      };

    case DELETE_PLATFORM_SECTION_ERROR:
      return {
        ...state,
        isDeletePlatformSectionInProgress: false
      };
  }

  return state;
}

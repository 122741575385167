import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

class PlatformField extends React.Component {

  render() {
    const { getFieldDecorator } = this.props.form;

    const input = <Input type="hidden" />;
    const fieldId = 'platformId';

    return (
      <Form.Item className={this.props.className}>
        {getFieldDecorator(fieldId)(input)}
      </Form.Item>
    );
  }

}

PlatformField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default PlatformField;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import { getPluginName, getPluginPathName, getPluginIcon } from '../../../plugin/pluginSelectors';

import AdminSidebarPlugins_en from './locales/AdminSidebarPlugins_en.json';
import AdminSidebarPlugins_fr from './locales/AdminSidebarPlugins_fr.json';

class AdminSidebarPlugins extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AdminSidebarPlugins', AdminSidebarPlugins_en);
    i18next.addResourceBundle('fr', 'AdminSidebarPlugins', AdminSidebarPlugins_fr);
  }

  render() {
    const { t, locale, plugins } = this.props;

    const matchPath = this.props.match.path;
    // const pathName = this.props.location.pathname || '';
    // const pluginPathname = pathName.replace(matchPath, '').split('/')[0];
    // const pluginName = getPluginNameByPathname(pluginPathname, locale);
    // const pluginIcon = getPluginIconByPathname(pluginPathname);
    const back = matchPath.indexOf('/plugins/') > -1 ? `/${locale}/d/plugins` : `/${locale}/d`;

    return (
      <div className="PluginsSider">
        <NavLink
          to={back}
          className="AdminSider__GroupItem AdminSider__GroupTitle ant-btn ant-btn-lg"
        >
          <FontAwesomeIcon icon={['fal', 'long-arrow-left']} fixedWidth />{' '}
          <span>{t('Plugins')}</span>
        </NavLink>

        {plugins &&

          Object.values(plugins)
          .map(plugin => {
            return (
              <NavLink
                to={`/${locale}/d/plugins/${getPluginPathName(plugin)}`}
                className="AdminSider__GroupItem ant-btn ant-btn-lg"
                activeClassName="AdminSider__GroupItem--active"
                key={plugin.id}
              >
                <FontAwesomeIcon icon={getPluginIcon(plugin)} fixedWidth /> {getPluginName(plugin.id, locale)}
              </NavLink>
            )
          })
        }
      </div>
    );
  }
}

export default translate('AdminSidebarPlugins')(AdminSidebarPlugins);

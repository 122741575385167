import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import dataFetcher from "../../parts/chore/dataFetcher";
import { selectCampaign, clearSelectedCampaign } from "../participantActions";
import { changeParticipantCreateWizardStep } from "../../ui/uiActions";
import { activateCreationMode, deactivateCreationMode } from "../../parts/ui/uiActions";
import { fetchPlatformIdBySurveyList } from "../../donation/donationActions";

import ParticipantCreatePage from './ParticipantCreatePage';

const mapStateToProps = state => ({
  currentStep: state.ui.participantCreateWizardState,
  surveyId: state.donation.surveyId,
  platformId: state.session.permissionsIds.platform,
  selectedCampaign: state.participant.selectedCampaign,
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPlatformIdBySurveyList,
  selectCampaign,
  clearSelectedCampaign,
  changeParticipantCreateWizardStep,
  activateCreationMode,
  deactivateCreationMode
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(ParticipantCreatePage);

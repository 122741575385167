//  Documentation and inspiration:
//  https://www.algolia.com/doc/guides/building-search-ui/getting-started/react/
//  https://github.com/algolia/react-instantsearch/blob/master/website/examples/e-commerce/App.js

import React from 'react';
import { Button, Layout, Form, Popconfirm, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import qs from 'qs';
import csv from 'async-csv';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';

import algoliasearch from 'algoliasearch';
import algoliaHelper from 'algoliasearch-helper';
import {
  InstantSearch,
  Hits,
  Pagination,
  Panel as AlgoPanel,
  Stats,
  ToggleRefinement,
  SortBy,
  HitsPerPage
} from 'react-instantsearch-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, isEqual, pickBy } from 'lodash';
import Markdown from 'react-remarkable';
import { Helmet } from 'react-helmet';

import { simpleColumn, actionColumn } from 'Parts/common/columnUtils';
import AlgoliaCustomSearchResults from './components/AlgoliaCustomSearchResults';
import AlgoliaCustomSearchBox from './components/AlgoliaCustomSearchBox';
import AlgoliaPageInfo from './components/AlgoliaPageInfo';
import AlgoliaMenuSelect from './components/AlgoliaMenuSelect';
import AlgoliaPriceRange from './components/AlgoliaPriceRange';
import AlgoliaDateRange from './components/AlgoliaDateRange';
import AlgoliaMultiRefinementList from './components/AlgoliaMultiRefinementList';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import ResultTable from 'Parts/search/ResultTable';
import TextField from 'Parts/ui/components/fields/TextField';
import CancelAndSaveButtons from '../components/CancelAndSaveButtons';
import Can from 'Parts/session/components/CanContainer';

import { getAlgoliaAppName, getAlgoliaDonationIndexName } from 'Parts/common/environment';
import { moneyFormat } from 'Parts/common/moneyFormat';

import transactionsFilterPageEN from './locales/TransactionsFilterPage_en.json';
import transactionsFilterPageFR from './locales/TransactionsFilterPage_fr.json';

import './TransactionsFilterPage.less';

const ALGOLIA_APP_NAME = getAlgoliaAppName();
const ALGOLIA_INDEX_NAME = getAlgoliaDonationIndexName();

const createURL = state => `?${qs.stringify(state)}`;
const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : '';
const urlToSearchState = location => qs.parse(location.search.slice(1));

class TransactionsFilterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilterBarOpen: false,
      searchClient: null,
      searchState: urlToSearchState(props.location),
      downloadingResults: false,
      disableDownload: true,
      searchResults: null,
      totalResults: null,
      displayModalSaveFilters: false,
      displayModalLoadFilters: false
    };

    i18next.addResourceBundle('en', 'TransactionsFilterPage', transactionsFilterPageEN);
    i18next.addResourceBundle('fr', 'TransactionsFilterPage', transactionsFilterPageFR);
  }

  componentDidMount() {
    if (isEmpty(this.props.algoliaToken)) {
      this.props.getAlgoliaToken(this.props.platformId, 'donations').then(result => {
        this.setState({
          searchClient: algoliasearch(ALGOLIA_APP_NAME, result.payload.securedAPIKey)
        });
      });
    } else {
      this.setState({
        searchClient: algoliasearch(ALGOLIA_APP_NAME, this.props.algoliaToken.securedAPIKey)
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) });
    }
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState);

    this.debouncedSetState = setTimeout(() => {
      this.props.history.replace(searchStateToUrl(this.props, searchState), searchState);
    }, 700);

    this.setState({ searchState });
  };

  handleFilterBarIconClick = () => {
    this.setState({ isFilterBarOpen: !this.state.isFilterBarOpen });
  };

  routeToEditPage = donation => {
    const { history, locale } = this.props;
    if (!donation.campaignId) {
      return history.push(
        `/${locale}/d/transactions/${donation.transactionId}`,
        { fromUrl: this.props.location.search }
      );
    } else {
      return history.push(
        `/${locale}/d/campaigns/${donation.campaignId}/transactions/${donation.transactionId}`,
        { fromUrl: this.props.location.search }
      );
    }
  }

  handleDownloadResults = () => {
    const { searchClient, searchResults } = this.state;
    this.setState({ downloadingResults: true });

    // Store searchResults._State
    const state = searchResults && searchResults._state;

    // Change hitsPerPage to 1000 (max) since it'll use the value of HitsPerPage component
    state.hitsPerPage = 1000;

    // Pass current searchClient and searchState to algoliaHelper
    const helper = algoliaHelper(searchClient, state.index, state);

    let allHits = null;

    // Listen to helper result (success)
    helper.on('result', ({ results }) => {
      allHits = results.hits;
      let longestHitIndex = 0;
      let longestKeysLength = 0;

      // Loop allHits to remove useless information and
      // get index of hit with longest key length to create csv columns
      allHits.forEach((hit, index) => {
        delete hit.tax_receipt_location;
        delete hit._geoloc;
        delete hit.objectID;
        delete hit._highlightResult;
        delete hit.categories;

        longestKeysLength =
          Object.keys(hit).length > longestKeysLength ? Object.keys(hit).length : longestKeysLength;
        longestHitIndex = Object.keys(hit).length >= longestKeysLength ? index : longestHitIndex;
      });

      // Generate csv asynchronously
      generateCsv(allHits, longestHitIndex).then(csv => {
        let now = moment(); // Get current date

        // Download the .csv by creating a link and removing it after
        let link = document.createElement('a');
        link.id = 'download-csv';
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', `crm-${this.props.platformAlias}-${now.format('L')}.csv`);
        document.body.appendChild(link);
        document.querySelector('#download-csv').click();
        document.body.removeChild(link);
      });
    });

    async function generateCsv(allHits, longestHitIndex) {
      return await csv.stringify(allHits, {
        columns: Object.keys(allHits[longestHitIndex]),
        quoted_string: true,
        header: true
      });
    }

    // Listen for helper errors
    helper.on('error', e => {
      this.setState({ downloadingResults: false });
    });

    // Trigger helper search
    helper.search();
  };

  // Get searchResults state from AlgoliaCustomSearchResults component and
  // enable download button if nbHits > 0
  handleGetSearchParameters = results => {
    if (!isEqual(this.state.searchResults, results)) {
      this.setState({
        searchResults: results,
        disableDownload: results.nbHits > 0 ? false : true
      });
    }
  };

  // Save filters
  handleSaveFilters = e => {
    e.preventDefault();

    const { validateFields, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (!isEmpty(updatedValues)) {
          let locationsSearch = this.props.location.search;

          locationsSearch = locationsSearch.replace(/&page=[0-9]+/g, '');
          const filter = {
            name: updatedValues.filter_name,
            filter: locationsSearch
          };

          this.props.saveAlgoliaPlatformFilter(filter, this.props.platformId).then(() => {
            this.setState({ displayModalSaveFilters: false });
          });
        }
      }
    });
  };

  handleOpenLoadFiltersModal = () => {
    this.setState({ displayModalLoadFilters: true });
    this.props.getAlgoliaSavedPlatformFilters(this.props.platformId);
  };

  handleConfirmDeleteFilter = filterId => {
    this.props.deleteAlgoliaPlatformFilter(this.props.platformId, filterId);
  };

  Hit = ({ hit }) => {
    const { t, locale } = this.props;

    const recipientName = hit.participant_first_name
      ? hit.participant_first_name + " " + hit.participant_last_name
      : hit.team_name
        ? hit.team_name
        : hit.campaign_name
          ? hit.campaign_name
          : hit.platform_name;

    return (
      <div className="donation-hit">
        <div className="donation-id">{t('Transaction_#', { id: hit.reference_id })}</div>
        <div className="hit-donator-layout">
          <div className="hit-donator-info">
            <div className="hit-donator-namee">
              <span className="subLabels">{t('Donator_Name')}:</span> {hit.firstName} {hit.lastName}
            </div>
            <div className="hit-donator-email">
              <span className="subLabels">{t('Donator_Email')}:</span> {hit.email}
            </div>
            <div className="hit-donator-address">
              <span className="subLabels">{t('Donator_Address')}:&nbsp;</span>
              {hit.address && <span>{hit.address}&nbsp;</span>}
              {hit.city && <span>{hit.city},&nbsp;</span>}
              {hit.country && <span>{hit.country}</span>}
            </div>

            <div className="hit-donator-code">{hit.code}</div>
          </div>

          <div className="hit-recipient-info">
            {hit.recipient && (
              <div className="hit-recipient">
                <span className="subLabels">{t('recipient-type')}: {t(hit.recipient)}</span>
              </div>
            )}

            {recipientName && (
              <div className="hit-recipient">
                <span className="subLabels">{t('recipient-name')}: {recipientName}</span>
              </div>
            )}
          </div>

          <div className="hit-receipt">{moneyFormat(hit.amount, locale)}</div>

          <div className="hit-cta">
            <a
              onClick={e => {
                e.preventDefault();
                this.routeToEditPage(hit);
              }}
              className="btnEditDonation ant-btn-circle"
            >
              <FontAwesomeIcon icon={['fal', 'edit']} />
            </a>
          </div>
        </div>
        <div className="bottom-info">
          <div className="">{moment.unix(hit.addedOn).format('LL')}</div>
          <div className="hit-status">{t(hit.status)}</div>
        </div>
      </div>
    );
  };

  render() {
    const { t, locale, form } = this.props;

    return (
      <Layout className="TransactionsFilterPage">
        <Helmet>
          <title>{t('Title')}</title>
        </Helmet>
        {this.state.searchClient && (
          <InstantSearch
            indexName={ALGOLIA_INDEX_NAME}
            searchClient={this.state.searchClient}
            searchState={this.state.searchState}
            onSearchStateChange={this.onSearchStateChange}
            createURL={createURL}
          >
            <AlgoliaCustomSearchResults getSearchParameters={this.handleGetSearchParameters} />
            <div className="SearchBar__Divider">
              <AdminTitleBar
                title={t('Title')}
                subName={''}
                buttons={[
                  <Button
                    onClick={this.handleDownloadResults}
                    loading={this.state.downloadingResults}
                    disabled={this.state.disableDownload}
                  >
                    {t('download-button')}
                  </Button>
                ]}
              />
            </div>

            <div className="betaMessage">
              <Markdown>{t('txt_beta')}</Markdown>
            </div>

            <div className="Alg_SearchZone">
              <AlgoliaCustomSearchBox
                handleFilterBarIconClick={this.handleFilterBarIconClick}
                filterBarLogoClass={this.state.isFilterBarOpen ? ' opened' : ' closed'}
              />
            </div>
            <div className="Alg_Container">
              <div
                className={`Alg_FilterZone${this.state.isFilterBarOpen ? ' opened' : ' closed'}`}
              >
                <div className="Alg_FilterZone__title-close">
                  <h2>{t('Advanced_Filters')} <FontAwesomeIcon icon={['fal', 'sliders-h']} onClick={this.handleOpenLoadFiltersModal} /></h2>
                  <Button
                    className="Alg_FilterZone__btn-close"
                    type="link"
                    onClick={() => this.setState({ isFilterBarOpen: false })}
                  >
                    <FontAwesomeIcon icon={['fal', 'times']} />
                  </Button>
                </div>
                <hr />

                {/* Je trouve ca filtre pertinent mais il n'est pas dans le design... alors commentaire!
                      <AlgoPanel header={t('Campaigns')} className="algPanelCol">
                        <AlgoliaMenuSelect
                          attribute={"categories.lvl2"}
                          translations={{
                            seeAllOption: t('All_Campaigns'),
                          }}
                          transformItems={items =>
                              items.map(item => ({
                                ...item,
                                label: item.label.substr( item.label.lastIndexOf('>')+1, item.label.length).trim()
                              }))
                            }
                        />
                      </AlgoPanel>
                      */}

                {/*Added on - Date Range */}
                <AlgoPanel header={t('Date')} className="algPanelCol">
                  <AlgoliaDateRange
                    attribute="addedOn"
                    placeholderStart={t('placeholder-date-start')}
                    placeholderEnd={t('placeholder-date-end')}
                  />
                </AlgoPanel>

                {/*Montant - Pricerange */}
                <AlgoPanel header={t('Amount')} className="algPanelCol">
                  <AlgoliaPriceRange attribute={'amount'} min={5} max={9999} locale={locale} />
                </AlgoPanel>

                {/*Transaction Type */}
                <AlgoPanel header={t('transactionType')} className="algPanelCol">
                  <AlgoliaMultiRefinementList
                    attribute="type"
                    transformItems={items =>
                      items.map(item => ({
                        ...item,
                        label: t(item.label.toUpperCase().trim())
                      }))
                    }
                  />
                </AlgoPanel>

                {/*Recipient Type */}
                <AlgoPanel header={t('recipient-type')} className="algPanelCol">
                  <AlgoliaMultiRefinementList
                    attribute="recipient"
                    transformItems={items =>
                      items.map(item => ({
                        ...item,
                        label: t(item.label.toUpperCase().trim())
                      }))
                    }
                    placeholder={t('recipient-placeholder')}
                  />
                </AlgoPanel>

                {/*Province */}
                <AlgoPanel header={t('Province')} className="algPanelCol">
                  <AlgoliaMenuSelect
                    attribute="province"
                    transformItems={items =>
                      items.map(item => ({
                        ...item,
                        label: t(item.label.trim())
                      }))
                    }
                    translations={{
                      seeAllOption: t('All provinces')
                    }}
                  />
                </AlgoPanel>

                {/* Type de donateurs (Membre ou guests) */}
                <AlgoPanel header={t('Membre')} className="algPanelCol">
                  <AlgoliaMenuSelect
                    attribute="member_type"
                    transformItems={items =>
                      items.map(item => ({
                        ...item,
                        label: t(item.label.toUpperCase().trim())
                      }))
                    }
                    translations={{
                      seeAllOption: t('All Donators')
                    }}
                  />
                </AlgoPanel>

                {/* Langage du donateur */}
                <AlgoPanel header={t('Donator Language')} className="algPanelCol">
                  <AlgoliaMenuSelect
                    attribute="language"
                    transformItems={items =>
                      items.map(item => ({
                        ...item,
                        label: t(item.label.toUpperCase().trim())
                      }))
                    }
                    translations={{
                      seeAllOption: t('All Languages')
                    }}
                  />
                </AlgoPanel>

                {/*Status */}
                <AlgoPanel header={t('Status')} className="algPanelCol">
                  <AlgoliaMenuSelect
                    attribute="status"
                    transformItems={items =>
                      items.map(item => ({
                        ...item,
                        label: t(item.label.toUpperCase().trim())
                      }))
                    }
                    translations={{
                      seeAllOption: t('All status')
                    }}
                  />
                </AlgoPanel>

                <div className="algPanelCol">
                  {/*Plusieurs dons à succès? */}
                  <AlgoPanel header="" className="">
                    <ToggleRefinement
                      attribute={'donator_multiple'}
                      label={t('donator_multiple')}
                      value={true}
                    />
                  </AlgoPanel>

                  {/*Reçu impot? */}
                  <AlgoPanel header="" className="">
                    <ToggleRefinement attribute={'has_receipt'} label={t('Receipt')} value={true} />
                  </AlgoPanel>

                  {/*Anonyme? */}
                  <AlgoPanel header="" className="">
                    <ToggleRefinement attribute={'anonymous'} label={t('Anonymous')} value={true} />
                  </AlgoPanel>
                </div>

                <div className="Alg_FilterZone-actions">
                  <Button onClick={() => this.setState({ displayModalSaveFilters: true })} disabled={this.props.location.search ? false : true}>
                    {t('save-filters-button')}
                  </Button>
                  <FundkyButton
                    className="white"
                    type="cancel"
                    text={t('reset-filters-button')}
                    action={{
                      func: () => this.props.history.replace(`${this.props.location.pathname}`)
                    }}
                  />
                </div>
              </div>

              <Can rules={['permissions.platform.transaction.get']} redirect={`/${locale}/d`}>
                <div className={`Alg_Results${this.state.isFilterBarOpen ? ' opened' : ' closed'}`}>
                  {/* nbResults - pager 1/8 - sort - results per page */}
                  <div className="hitActions">
                    <Stats
                      translations={{
                        stats(nbHits, timeSpentMS) {
                          return `${nbHits} ` + t('Transactions');
                        }
                      }}
                    />

                    <div className="hitControls">
                      <AlgoliaPageInfo />

                      <SortBy
                        defaultRefinement={ALGOLIA_INDEX_NAME}
                        items={[
                          { value: ALGOLIA_INDEX_NAME, label: t('Recent') },
                          { value: ALGOLIA_INDEX_NAME + '_amount_desc', label: t('Amount_Desc') },
                          { value: ALGOLIA_INDEX_NAME + '_amount_asc', label: t('Amount_Asc') }
                        ]}
                      />

                      <HitsPerPage
                        defaultRefinement={10}
                        items={[
                          { value: 5, label: t('Results_5') },
                          { value: 10, label: t('Results_10') },
                          { value: 20, label: t('Results_20') },
                          { value: 50, label: t('Results_50') }
                        ]}
                      />
                    </div>
                  </div>
                  <Hits hitComponent={this.Hit} t={t} />
                  <Pagination />
                </div>
              </Can>
            </div>
          </InstantSearch>
        )}
        <FundkyModal
          title={t('save-filters-title')}
          displayModal={this.state.displayModalSaveFilters}
          destroyOnClose={true}
          displayActions={false}
          cancelAction={() => {
            this.setState({ displayModalSaveFilters: false });
          }}
        >
          <Form onSubmit={this.handleSaveFilters} layout="vertical">
            <TextField
              form={form}
              label={t('save-filters-filter-name')}
              fieldId="filter_name"
              fieldOptions={{
                rules: [
                  {
                    required: true,
                    message: t('require')
                  },
                  {
                    pattern: /^.{5,128}$/,
                    message: t('save-filters-filter-name-message')
                  }
                ]
              }}
            />
            <CancelAndSaveButtons
              onClickCancel={() => {
                this.setState({ displayModalSaveFilters: false });
              }}
            />
          </Form>
        </FundkyModal>

        <FundkyModal
          title={t('load-filters-title')}
          displayModal={this.state.displayModalLoadFilters}
          displayActions={false}
          cancelAction={() => {
            this.setState({ displayModalLoadFilters: false });
          }}
        >
          <ResultTable
            dataSource={this.props.algoliaFilters}
            columns={[
              simpleColumn('Filter name', 'name', null, true),
              actionColumn('Load', ['fal', 'arrow-to-bottom'], filter => {
                this.setState({ displayModalLoadFilters: false });
                this.props.history.replace(`${this.props.location.pathname}${filter.filter}`);
              }),
              actionColumn('Delete', null, null, filter => {
                return (
                  <div id="delete-wrap">
                    <Popconfirm
                      placement="bottomRight"
                      title={t('confirm-delete-title')}
                      getPopupContainer={() => document.getElementById('delete-wrap')}
                      onConfirm={() => this.handleConfirmDeleteFilter(filter.id)}
                      okText={t('confirm-delete-yes')}
                    >
                      <Button shape="circle">
                        <FontAwesomeIcon icon={['fal', 'trash-alt']} size="lg" />
                      </Button>
                    </Popconfirm>
                  </div>
                );
              })
            ]}
            loading={this.props.isFetchingAlgoliaFilters}
          />
        </FundkyModal>
      </Layout>
    );
  }
}

export default translate('TransactionsFilterPage')(TransactionsFilterPage);

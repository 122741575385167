import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { withRouter } from 'react-router';

import TeamField_en from './locales/TeamField_en.json';
import TeamField_fr from './locales/TeamField_fr.json';

const { Option } = Select;

import './TeamField.less';

class TeamField extends React.Component {

  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'TeamField', TeamField_en);
    i18next.addResourceBundle('fr', 'TeamField', TeamField_fr);
  }

  handleChange = event => {
    if (event === "addTeam") {
      this.props.history.replace({
        pathname: `/${this.props.locale}/${this.props.t('URL:create-a-team')}`,
        state: {
          campaign: this.props.campaign
        }
      });
    }
  }

  render() {
    const { t, form, className, campaign } = this.props;
    const { getFieldDecorator } = form;

    // Limits & Counts
    const limitParticipants = campaign.settings && campaign.settings.participant && parseInt(campaign.settings.participant.limit) || 0;
    const limitTeams = campaign.settings && campaign.settings.team && parseInt(campaign.settings.team.limit) || 0;
    const countTeams = campaign.childrenCount && campaign.childrenCount.teams || 0;

    //Participation Type
    const participationType = this.props.form.getFieldValue('participationType');

    // Field Options
    const filteredOptions = this.props.teams.filter(team => limitParticipants == 0 || team && team.childrenCount && team.childrenCount.participants < limitParticipants);
    const mappedOptions = filteredOptions.map(team => <Option key={team.id} value={team.id}>{team.name}</Option>);

    // Field settings
    const initialValue = filteredOptions.find(o => this.props.preSelectedTeam && o.id === this.props.preSelectedTeam.id) && this.props.preSelectedTeam.id || undefined;
    const input = (
      <Select
        showSearch
        placeholder={t('placeholder')}
        optionFilterProp="children"
        onChange={this.handleChange}
        disabled={this.props.disabled}
        suffixIcon={<FontAwesomeIcon className="TeamField-fa-icon" icon={['fas', 'caret-down']} />}
        className="TeamField-Select"
        notFoundContent={t('not-found')}
      >
        {mappedOptions}
        {(limitTeams == 0 || countTeams < limitTeams) &&
          <Option key="addTeam" value="addTeam">
            <span className="fa-layers TeamField__TeamIcon">
              <FontAwesomeIcon icon={["fal", "users"]} transform="left-1"  />
              <FontAwesomeIcon icon={["far", "plus"]} transform="shrink-10 right-15" />
            </span>
            {t('create-a-team')}
          </Option>
        }
      </Select>
    );
    const fieldId = 'teamId';
    const fieldOptions = {
      rules: [
          { required: participationType !== 'solo', message: t('require') }
        ],
      initialValue: initialValue
    };

    return (
      <Form.Item
        className={`TeamField ${className}`}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

TeamField.defaultProps = {
  disabled: false
};

TeamField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  teams: PropTypes.array.isRequired,
  disabled: PropTypes.bool
};

export default withRouter(translate('TeamField')(TeamField));

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import PagesHomepageSliderPanel from './components/PagesHomepageSliderPanelContainer';
import PagesHomepageIntroTextForm from './components/PagesHomepageIntroTextFormContainer';
import PagesHomepageFeaturedCampaignForm from './components/PagesHomepageFeaturedCampaignFormContainer';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import Can from 'Parts/session/components/CanContainer';

import PagesHomepage_en from './locales/PagesHomepage_en.json';
import PagesHomepage_fr from './locales/PagesHomepage_fr.json';

import './PagesHomepage.less';

class PagesHomepage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PagesHomepage', PagesHomepage_en);
    i18next.addResourceBundle('fr', 'PagesHomepage', PagesHomepage_fr);
  }

  render() {
    const { t, locale } = this.props;

    var tabPages = [
      {
        title: t('Slideshow'),
        slug: 'main-slider',
        container: props => (
          <Can
            rules={[
              ['roles.platform.OWNER', 'permissions.platform.platform.update'],
              ['roles.platform.SUPERADMIN', 'permissions.platform.platform.update']
            ]}
            redirect={`/${locale}/d`}
          >
            <PagesHomepageSliderPanel locale={locale} />
          </Can>
        )
      },
      {
        title: t('Introductory text'),
        slug: 'introduction-text',
        container: props => (
          <Can
            rules={[
              ['roles.platform.OWNER', 'permissions.platform.platform.update'],
              ['roles.platform.SUPERADMIN', 'permissions.platform.platform.update']
            ]}
            redirect={`/${locale}/d`}
          >
            <PagesHomepageIntroTextForm />
          </Can>
        )
      },
      {
        title: t('Campaigns'),
        slug: 'campaign-featured',
        container: props => (
          <Can
            rules={[
              ['roles.platform.OWNER', 'permissions.platform.platform.update'],
              ['roles.platform.SUPERADMIN', 'permissions.platform.platform.update']
            ]}
            redirect={`/${locale}/d`}
          >
            <PagesHomepageFeaturedCampaignForm />
          </Can>
        )
      }
    ];

    return (
      <div className="PagesHomepagePage">
        <Helmet>
          <title>{t('Homepage')}</title>
        </Helmet>
        <AdminTitleBar title={t('Homepage')} />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} />
      </div>
    );
  }
}

export default translate('PagesHomepage')(PagesHomepage);

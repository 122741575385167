import { has, isArray } from 'lodash';

import {
  requestApiGet,
  requestApiPost
} from '../parts/common/requestUtils';
import { getSessionIp } from '../parts/session/sessionUtils';

export const FETCH_CAMPAIGN_SEARCH_START = 'FETCH_CAMPAIGN_SEARCH_START';
export const FETCH_CAMPAIGN_SEARCH_SUCCESS = 'FETCH_CAMPAIGN_SEARCH_SUCCESS';
export const FETCH_CAMPAIGN_SEARCH_ERROR = 'FETCH_CAMPAIGN_SEARCH_ERROR';

export const FETCH_TEAM_SEARCH_START = 'FETCH_TEAM_SEARCH_START';
export const FETCH_TEAM_SEARCH_SUCCESS = 'FETCH_TEAM_SEARCH_SUCCESS';
export const FETCH_TEAM_SEARCH_ERROR = 'FETCH_TEAM_SEARCH_ERROR';

export const FETCH_PARTICIPANT_SEARCH_START = 'FETCH_PARTICIPANT_SEARCH_START';
export const FETCH_PARTICIPANT_SEARCH_SUCCESS = 'FETCH_PARTICIPANT_SEARCH_SUCCESS';
export const FETCH_PARTICIPANT_SEARCH_ERROR = 'FETCH_PARTICIPANT_SEARCH_ERROR';

export const FETCH_DONATION_SEARCH_START = 'FETCH_DONATION_SEARCH_START';
export const FETCH_DONATION_SEARCH_SUCCESS = 'FETCH_DONATION_SEARCH_SUCCESS';
export const FETCH_DONATION_SEARCH_ERROR = 'FETCH_DONATION_SEARCH_ERROR';

export const FETCH_TRANSACTION_SEARCH_START = 'FETCH_TRANSACTION_SEARCH_START';
export const FETCH_TRANSACTION_SEARCH_SUCCESS = 'FETCH_TRANSACTION_SEARCH_SUCCESS';
export const FETCH_TRANSACTION_SEARCH_ERROR = 'FETCH_TRANSACTION_SEARCH_ERROR';

export const FETCH_PARTICIPATION_SEARCH_START = 'FETCH_PARTICIPATION_SEARCH_START';
export const FETCH_PARTICIPATION_SEARCH_SUCCESS = 'FETCH_PARTICIPATION_SEARCH_SUCCESS';
export const FETCH_PARTICIPATION_SEARCH_ERROR = 'FETCH_PARTICIPATION_SEARCH_ERROR';

export const FETCH_MEMBER_TRANSACTIONS_SEARCH_START = 'FETCH_MEMBER_TRANSACTIONS_SEARCH_START';
export const FETCH_MEMBER_TRANSACTIONS_SEARCH_SUCCESS = 'FETCH_MEMBER_TRANSACTIONS_SEARCH_SUCCESS';
export const FETCH_MEMBER_TRANSACTIONS_SEARCH_ERROR = 'FETCH_MEMBER_TRANSACTIONS_SEARCH_ERROR';

export const FETCH_PLATFORM_MEMBERS_SEARCH_START = 'FETCH_PLATFORM_MEMBERS_SEARCH_START';
export const FETCH_PLATFORM_MEMBERS_SEARCH_SUCCESS = 'FETCH_PLATFORM_MEMBERS_SEARCH_SUCCESS';
export const FETCH_PLATFORM_MEMBERS_SEARCH_ERROR = 'FETCH_PLATFORM_MEMBERS_SEARCH_ERROR';

export const FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_START = 'FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_START';
export const FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_SUCCESS = 'FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_SUCCESS';
export const FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_ERROR = 'FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_ERROR';

export const FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_START = 'FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_START';
export const FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_SUCCESS = 'FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_SUCCESS';
export const FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_ERROR = 'FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_ERROR';

export const FETCH_PLUGIN_SEARCH_START = 'FETCH_PLUGIN_SEARCH_START';
export const FETCH_PLUGIN_SEARCH_SUCCESS = 'FETCH_PLUGIN_SEARCH_SUCCESS';
export const FETCH_PLUGIN_SEARCH_ERROR = 'FETCH_PLUGIN_SEARCH_ERROR';

export const FETCH_SETTLEMENT_REPORTS_START = 'FETCH_SETTLEMENT_REPORTS_START';
export const FETCH_SETTLEMENT_REPORTS_SUCCESS = 'FETCH_SETTLEMENT_REPORTS_SUCCESS';
export const FETCH_SETTLEMENT_REPORTS_ERROR = 'FETCH_SETTLEMENT_REPORTS_ERROR';

export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export function searchCampaigns(
  platformId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false,
  showChildrenCount = false,
  showActions = false
) {
  return dispatch => {
    dispatch({ type: FETCH_CAMPAIGN_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      ...(showChildrenCount ? { showChildrenCount: true } : {}),
      ...(showActions ? { showActions: true } : {})
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/campaigns`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_CAMPAIGN_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_CAMPAIGN_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchTeams(
  platformId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false,
  showChildrenCount = false,
  showActions = false
) {
  return dispatch => {
    dispatch({ type: FETCH_TEAM_SEARCH_START });
    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      ...(showChildrenCount ? { showChildrenCount: true } : {}),
      ...(showActions ? { showActions: true } : {})
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/teams`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_TEAM_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TEAM_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchTeamsInCampaign(
  campaignId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false,
  showChildrenCount = false
) {
  return dispatch => {
    dispatch({ type: FETCH_TEAM_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      ...(showChildrenCount ? { showChildrenCount: true } : {})
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/teams`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_TEAM_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TEAM_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchParticipants(
  platformId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false,
  showChildrenCount = false,
  showActions = false
) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANT_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      ...(showChildrenCount ? { showChildrenCount: true } : {}),
      ...(showActions ? { showActions: true } : {})
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/participants`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_PARTICIPANT_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PARTICIPANT_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchParticipantsInCampaign(
  campaignId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANT_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/participants`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_PARTICIPANT_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PARTICIPANT_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchParticipantsInTeam(
  teamId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANT_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/teams/${teamId}/participants`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_PARTICIPANT_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PARTICIPANT_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchDonations(
  platformId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_DONATION_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/donations`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_DONATION_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_DONATION_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchDonationsInCampaign(
  campaignId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_DONATION_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/donations`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_DONATION_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_DONATION_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchTransactions(
  platformId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_TRANSACTION_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy = orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
      ? typeof orderBy === 'string'
        ? { orderBy: orderBy }
        : orderBy
      : { orderBy: 'addedOn[desc]' };

    /* Only APPROVED for now*/
    const status = 'APPROVED|REFUNDED';
    if (filters) {
      filters.status = status;
    } else {
      filters = { status: status };
    }

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    if (filters.hasOwnProperty('parentId') && isArray(filters.parentId) && filters.parentId[0] === null) filters.parentId = 'null';

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/transactions`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_TRANSACTION_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TRANSACTION_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchTransactionsInCampaign(
  campaignId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_TRANSACTION_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    /* Only APPROVED and REFUNDED for now*/
    const status = 'APPROVED|REFUNDED';
    if (filters) {
      filters.status = status;
    } else {
      filters = { status: status };
    }

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    // filterBy: type[typeName]
    // typeName: {
    //   DONATION: 1,
    //   PLUGIN: 2,
    //   CAMPAIGN_EVENT_TICKET: 3,
    //   CAMPAIGN_STREAM_POLL_VOTE: 4,
    //   CAMPAIGN_STREAM_ATTENDEE: 5
    // }

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/transactions`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_TRANSACTION_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TRANSACTION_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchParticipations(
  memberId,
  platformId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPATION_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let _filters = {
      ...filters,
      platformId: platformId
    };

    let formatedFilters = formatFilters(_filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/members/${memberId}/participations`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_PARTICIPATION_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PARTICIPATION_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchMemberTransactions(
  memberId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_TRANSACTIONS_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    if (filters.hasOwnProperty('parentId') && isArray(filters.parentId) && filters.parentId[0] === null) filters.parentId = 'null';

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/members/${memberId}/transactions`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_MEMBER_TRANSACTIONS_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_MEMBER_TRANSACTIONS_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchMembers(
  platformId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null
) {
  return dispatch => {
    dispatch({ type: FETCH_PLATFORM_MEMBERS_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/members`,
      params,
      headers
    )
      .then(result => {
        return dispatch({
          type: FETCH_PLATFORM_MEMBERS_SEARCH_SUCCESS,
          payload: result
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_MEMBERS_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchPlatformMembersParticipations(
  platformId,
  memberId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null
) {
  return dispatch => {
    dispatch({ type: FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/members/${memberId}/participations`,
      params,
      headers
    )
      .then(result => {
        return dispatch({
          type: FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_SUCCESS,
          payload: result
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_MEMBERS_PARTICIPATIONS_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchPlatformMembersTransactions(
  platformId,
  memberId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null
) {
  return dispatch => {
    dispatch({ type: FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    if (filters.hasOwnProperty('parentId') && isArray(filters.parentId) && filters.parentId[0] === null) filters.parentId = 'null';

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/members/${memberId}/transactions`,
      params,
      headers
    )
      .then(result => {
        return dispatch({
          type: FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_SUCCESS,
          payload: result
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_MEMBERS_TRANSACTIONS_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchPlugins(
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_PLUGIN_SEARCH_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/plugins`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_PLUGIN_SEARCH_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLUGIN_SEARCH_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

export function searchSettlementReports(
  organizationId,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false
) {
  return dispatch => {
    dispatch({ type: FETCH_SETTLEMENT_REPORTS_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || null;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : { orderBy: 'addedOn[desc]' };

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/organizations/${organizationId}/reports/withdrawals`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_SETTLEMENT_REPORTS_SUCCESS, payload: result });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_SETTLEMENT_REPORTS_ERROR,
          payload: error,
          filters: filters,
          search: search
        });
      });
  };
}

// ALGOLIA TOKEN
export function getAlgoliaToken(platformId, index) {
  return dispatch => {
    dispatch({ type: FETCH_ALGOLIA_TOKEN_START });

    const params = {
      'platformId':platformId,
      'index':index
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      `/tools/algolia/securedKey`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_ALGOLIA_TOKEN_SUCCESS, payload: result })
      })
      .catch(error => {
        return dispatch({type: FETCH_SETTLEMENT_REPORTS_ERROR, payload: error})
      });
  }
}


export function clearSearch() {
  return dispatch => {
    return dispatch({ type: CLEAR_SEARCH });
  };
}

const formatFilters = filters => {
  let formatedFilters = [];
  for (let key in filters) {
    if (filters[key] === null || filters[key] === "NULL" || filters[key]  === undefined) continue;

    let filter = Array.isArray(filters[key]) ? filters[key].join('|') : filters[key];

    formatedFilters.push(`${key}[${filter}]`);
  }
  return formatedFilters.length > 0 ? formatedFilters.join(',') : undefined;
};

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import '../../donation/components/SurveyAttempt.less';
import { Button, Checkbox, Input, Radio, Select, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreateSurveyAttemptForm_fr from "../../donation/components/locales/CreateSurveyAttemptForm_fr.json";
import CreateSurveyAttemptForm_en from "../../donation/components/locales/CreateSurveyAttemptForm_en.json";
import { PARTICIPANT_CREATE_WIZARD_INFORMATION } from '../../ui/uiUtils';
import { get } from 'lodash';
const { TextArea } = Input;

class SurveyAttempt extends React.Component {
    constructor(props) {
        super(props);

        i18next.addResourceBundle('fr', 'SurveyAttempt', CreateSurveyAttemptForm_fr);
        i18next.addResourceBundle('en', 'SurveyAttempt', CreateSurveyAttemptForm_en);

        this.state = {
            surveysPageDateils: this.props.surveysPageDateils,
            surveysFields: [],
            surveyAnswer: this.props.surveySubmitData.fields || [],// this.props.storeSurveyAttempt || [],
            fieldRequired: false,
        }
    }

    componentDidUpdate(prevProps) {
        // API data has changed, update the component's state
        if (this.props.surveysPageDateils !== prevProps.surveysPageDateils) {
            this.setState({ surveysPageDateils: this.props.surveysPageDateils });
        }
      if (this.props.surveysFields !== prevProps.surveysFields) {
        const newSurveyFields = this.mapppingSurveyFields(this.props.surveysFields);
        this.setState({ surveysFields: newSurveyFields });
        const defaultSurveyAnswer = [];
        newSurveyFields.map((item, index) => {
          defaultSurveyAnswer.push({
            survey_id: this.props.surveyId.id,//this.state.surveysPageDateils && this.state.surveysPageDateils.id,
            survey_field_id: item.id,
            answer_option: this.props.storeSurveyAttempt.length > 0 ? this.props.storeSurveyAttempt[index].answer_option : [],
            answer_text: this.props.storeSurveyAttempt.length > 0 ? this.props.storeSurveyAttempt[index].answer_text : null,
          });
        });
        this.setState({ surveyAnswer: defaultSurveyAnswer });
      }
    }

    mapppingSurveyFields = (surveysFields) => {
        return surveysFields.map((d) => {
          const getAnswerTextValue = this.state.surveyAnswer.filter((i) => i.survey_field_id === d.id)
          const value = getAnswerTextValue && getAnswerTextValue.length > 0 ? getAnswerTextValue[0].answer_text : "";
          const getAnswerOptionValue = this.state.surveyAnswer.filter((i) => i.survey_field_id === d.id && i.answer_option.length > 0)
    
          if (d.field_type_id === 1 || d.field_type_id === 3 || d.field_type_id === 6) {
            return { ...d, value: value};
          } else if (d.field_type_id === 5 || d.field_type_id === 2 || d.field_type_id === 4) {
            return {
              ...d,
              options: d.options && d.options.map((k) => {
                return { title: k.title ? k.title : k, isChecked: getAnswerOptionValue.length > 0 ? getAnswerOptionValue[0].answer_option.includes(k.title ? k.title : k) : false };
              }),
            };
          }
          return d;
        });
    };

    componentDidMount() {
        this.props.surveysDetailAction(this.props.surveyId.id, this.props.locale);
        this.props.fetchSurveyFields(this.props.surveyId.id, this.props.locale);
    }

    getPageType = () => {
        const { match } = this.props;
        const { params } = match;
        return params.teamAlias
          ? 'team'
          : params.campaignAlias
            ? 'campaign'
            : null;
    };

    getBaseUrl = (withLocale = true) => {
        const pageType = this.getPageType();
        const { locale, match } = this.props;
        const { params } = match;
    
        const localeUrl = withLocale ? `/${locale}` : '';
        switch (pageType) {
          case 'team':
            return `${localeUrl}/${params.campaignAlias}/${params.teamAlias}`;
          case 'campaign':
          default:
            return `${localeUrl}/${params.campaignAlias}`;
        }
    };

    handleChange(survey_field_id, value, field_type, ind, optionIndex) {
        if (field_type === "checkbox") {
            // for checkbox
            this.state.surveysFields[ind].options[optionIndex].isChecked =
              !this.state.surveysFields[ind].options[optionIndex].isChecked;
            this.setState({ surveysFields: this.state.surveysFields });
            const index = this.state.surveyAnswer.findIndex((o) => o.survey_field_id === survey_field_id);
            if (index === -1) {
                this.setState({
                    surveyAnswer: [
                        ...this.state.surveyAnswer,
                        {
                            survey_id: this.state.surveysPageDateils.id,
                            survey_field_id: survey_field_id,
                            answer_option: [value], // To do need to add id because id required for filter
                            answer_text: '',
                        },
                    ],
                });
            } else {
                if (this.state.surveyAnswer[index].answer_option.includes(value)) {
                    this.state.surveyAnswer[index].answer_option.filter((d) => d.value !== value);
                } else {
                    this.state.surveyAnswer[index].answer_option = [
                        ...this.state.surveyAnswer[index].answer_option,
                        value,
                    ];
                }
            }
        } else if (["radio","dropdown"].includes(field_type)) {
            // for Radio, dropdown
            const radioOptions = this.state.surveysFields[ind].options.map((d) => {
              return { ...d, isChecked: d.title === value };
            });
            const newSurveysArray = this.state.surveysFields.map((k, inx) => {
              return { ...k, options: inx === ind ? radioOptions : k.options };
            });
      
            this.setState({ surveysFields: newSurveysArray });
      
            const index = this.state.surveyAnswer.findIndex((o) => o.survey_field_id === survey_field_id);
            if (index === -1) {
                this.setState({
                    surveyAnswer: [
                        ...this.state.surveyAnswer,
                        {
                            survey_id: this.state.surveysPageDateils.id,
                            survey_field_id: survey_field_id,
                            answer_option: [value], // To do value should be in text not in array
                            answer_text: '',
                        },
                    ],
                });
            } else {
                this.state.surveyAnswer[index].answer_option = [value];
            }
        } else {
            this.state.surveysFields[ind].value = value;
            this.setState({ surveysFields: this.state.surveysFields });
            let index = this.state.surveyAnswer.findIndex((o) => o.survey_field_id === survey_field_id);
            if (index === -1) {
                this.setState({
                    surveyAnswer: [
                        ...this.state.surveyAnswer,
                        {
                            survey_id: this.state.surveysPageDateils.id,
                            survey_field_id: survey_field_id,
                            answer_text: value,
                            answer_option: '',
                        },
                    ],
                });
                this.setState({
                    surveyFieldValidation: [],
                });
            } else {
              this.state.surveyAnswer[index].answer_text = value;
            }
        }
    }

    handleSubmit() {
      let ticketsIds = this.props.ticketSelection && this.props.ticketSelection.tickets && this.props.ticketSelection.tickets.map(s=>s.ticket.id);
      let validation = [];
        const data = {
          survey_id: this.props.surveyId.id,
          email: this.props.user.email,
          user_id: this.props.user.id,
          fields: this.state.surveyAnswer,
          campaign: this.props.campaign && this.props.campaign.id !== undefined ? this.props.campaign.id : this.props.selectedCampaign ? this.props.selectedCampaign.id : null,
          ticket: ticketsIds ? JSON.stringify(ticketsIds) : null 
        };
        this.state.surveysFields.forEach((item, index) => {
          if (item.is_required === 1) {
            if (["radio","checkbox", "dropdown"].includes(item.field_type)){
              const isKeyTrue = item.options && item.options.some((item) => item['isChecked'] === true);
              if (!isKeyTrue) {
                validation.push(item);
              }
            } else if (!item.value) {
              validation.push(item);
            }
          }
        });
        this.setState({ surveyFieldValidation: validation });
        if (validation.length === 0) {
          if (this.props.location.pathname.includes(this.props.t('URL:ticket-purchase/survey'))) {
            this.props.surveyAttemptStoreAction(this.state.surveyAnswer);
            this.props.surveyAttemptSubmitAction(data);
            const baseUrl = this.getBaseUrl();
            if (this.props.location.state && this.props.location.state.total === 0) {
              this.props.purchaseTickets(this.props.campaign.id, this.props.location.state.information)
                .then(response => {
                  if (get(response, 'error.code', null)) {
                    notification.error({ message: this.props.t(`ERROR:${get(response, 'error.code')}`) });
                  } else {
                    this.props.surveyAttemptAction({ surveySubmitData: data, transaction_id: response.payload.transaction.id }, this.props.locale);
                  }
                });
            } else {
              this.props.history.push(`${baseUrl}/${this.props.t('URL:ticket-purchase/payment')}`);
            }
          } else {
            this.props.createParticipant(this.props.participantDataSave.formValues, this.props.participantDataSave.campaignId)
            this.props.surveyAttemptAction(data, this.props.locale);
          }
        } else {
          this.setState({ fieldRequired: true });
        }
    }

    handleBackClick = (event) => {
      event.preventDefault();
      this.props.surveyAttemptStoreAction(this.state.surveyAnswer);
      if (this.props.location.pathname.includes(this.props.t('URL:ticket-purchase/survey'))) {
        this.props.handleBackClick(this.props.back);
      }
      this.props.changeParticipantCreateWizardStep(PARTICIPANT_CREATE_WIZARD_INFORMATION);
    };

    getRadioDropdownValue = (options) => {
      const selectedOption = options.find((option) => option.isChecked);
      return selectedOption ? selectedOption.title : undefined;
    };

    render() {
      const { t } = this.props;
      const { surveysFields } = this.state;

      return (
        <div className="SurveyAttempt" style={{maxWidth: "1200px", width: "90%", margin: "0 auto"}}>
          <div className='SurevyAttemptMain'>
            <h2 className='SurevyAttemptRadio'>{this.props.surveyId.name}</h2>
            {
            surveysFields.map((item, index) => {
              const isKeyTrue =  item.options && item.options.some((item) => item['isChecked'] === true);
              return item.field_type === 'radio' ? (
                <div className="SurevyAttemptRadio">
                  <span>{item.label}</span>
                  <span>
                    {item.is_required === 1 ? (
                      <span style={{ color: '#EB3052', marginLeft: '0.5rem' }}>*</span>
                    ) : null}
                  </span>
                  <br />
                  <Radio.Group
                    name="radiogroup"
                    value={item.options && this.getRadioDropdownValue(item.options)}
                    onChange={(e) =>
                      this.handleChange(item.id, e.target.value, item.field_type, index)
                    }
                  >
                    {item.options && item.options.map((val, i) => {
                      return <Radio className="surveyDropdown" value={val.title}>{val.title}</Radio>;
                    })}
                  </Radio.Group>
                  <span style={{ color: '#EB3052' }}>
                    {item.is_required === 1 && this.state.fieldRequired && !isKeyTrue
                      ? t('error')
                      : ''}
                  </span>
                </div>
              ) : item.field_type === 'date' ? (
                <div className="SurevyAttemptDate">
                  <span>{item.label}</span>
                  <span>
                    {item.is_required === 1 ? <span style={{ color: '#EB3052' }}>*</span> : null}
                  </span>
                  <br />
                  <Input
                    type="date"
                    value={item.value}
                    onChange={(e) => {
                      this.handleChange(item.id, e.target.value, item.field_type, index);
                    }}
                  />
                  <span style={{ color: '#EB3052' }}>
                    {item.is_required === 1 && this.state.fieldRequired && !item.value ? t('error') : ''}
                  </span>
                </div>
              ) : item.field_type === 'dropdown' ? (
                <div className="SurevyAttemptDropdown">
                  <span>{item.label}</span>
                  <span>
                    {item.is_required === 1 ? <span style={{ color: '#EB3052' }}>*</span> : null}
                  </span>
                  <br />
                  <div>
                    <Select
                      suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
                      style={{ width: '100%' }}
                      value={item.options && this.getRadioDropdownValue(item.options)}
                      onSelect={(e) => this.handleChange(item.id, e, item.field_type, index)}
                      placeholder=""
                    >
                      {item.options && item.options.map((val, i) => {
                        return (
                          <Option
                            key={val}
                            value={val.title}
                            className="surveyDropdown"
                          >
                            {val.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <span style={{ color: '#EB3052' }}>
                    {item.is_required === 1 && this.state.fieldRequired && !isKeyTrue
                      ? t('error')
                      : ''}
                  </span>
                </div>
              ) : item.field_type === 'text' ? (
                <div className="SurevyAttemptText">
                  <span>{item.label}</span>
                  <span>
                    {item.is_required === 1 ? <span style={{ color: '#EB3052' }}>*</span> : null}
                  </span>
                  <br />
                  <Input
                    type="text"
                    placeholder=""
                    value={item.value}
                    onChange={(e) => {
                      this.handleChange(item.id, e.target.value, item.field_type, index);
                    }}
                  />
                  <span style={{ color: '#EB3052' }}>
                    {item.is_required === 1 &&
                    this.state.fieldRequired && this.state.fieldRequired  && !item.value 
                      ? t('error')
                      : ''}
                  </span>
                </div>
              ) : item.field_type === 'checkbox' ? (
                <div className="SurevyAttemptCheckbox">
                  <span>{item.label}</span>
                  <span>
                    {item.is_required === 1 ? (
                      <span style={{ color: '#EB3052', marginLeft: '0.5rem' }}>*</span>
                    ) : null}
                  </span>
                  <br />
                  <div className="ant-checkbox-group">
                    { item.options && item.options.map((val, i) => {
                      return (
                        <Checkbox
                          className="surveyDropdown"
                          style={{ width: 'auto' }}
                          checked={val.isChecked}
                          onClick={() =>
                            this.handleChange(item.id, val.title, item.field_type, index, i)
                          }
                        >
                          {val.title}
                        </Checkbox>
                      );
                    })}
                  </div>
                  <br />
                  <span style={{ color: '#EB3052' }}>
                    {item.is_required === 1 && this.state.fieldRequired && !isKeyTrue
                      ? t('error')
                      : ''}
                  </span>
                </div>
              ) : item.field_type === 'textarea' ? (
                <div className="SurevyAttemptTextarea">
                  <span>{item.label}</span>
                  <span>
                    {item.is_required === 1 ? <span style={{ color: '#EB3052' }}>*</span> : null}
                  </span>
                  <br />
                  <TextArea
                    autosize={true}
                    rows={4}
                    value={item.value}
                    onChange={(e) => {
                      this.handleChange(item.id, e.target.value, item.field_type, index);
                    }}
                  />
                  <span style={{ color: '#EB3052' }}>
                    { item.is_required === 1 && this.state.fieldRequired && !item.value ? t('error') : '' }
                  </span>
                </div>
              ) : (
                ''
              );
            })}
            <div className="CreateInformationForm__SubmitWrapper" style={{paddingTop: "20px"}}>
              <Button className="Back-btn" onClick={this.handleBackClick}>
                {t('previous')}
              </Button>
              <Button
                className="btn-submit"
                type="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        </div>
      );
    }
}
export default translate('SurveyAttempt')(SurveyAttempt);
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import dataFetcher from "../../parts/chore/dataFetcher";

import { searchParticipants } from '../searchActions';

import SearchParticipantPanel from './SearchParticipantPanel';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  participants: state.search.participants.results,
  pagination: state.search.participants.pagination,
  filters: state.search.participants.filters,
  search: state.search.participants.search,
  orderBy: state.search.participants.orderBy,
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchParticipants
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(SearchParticipantPanel)

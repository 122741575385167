import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import CampaignAdminPageForm from './components/CampaignAdminPageFormContainer';
import CampaignAdminPageHistory from './components/CampaignAdminPageHistoryContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import { isPluginActive } from '../../../plugin/pluginActions';

import campaignAdminPageEN from './locales/CampaignAdminPage_en.json';
import campaignAdminPageFR from './locales/CampaignAdminPage_fr.json';

import './CampaignAdminPage.less';
import Plugin from '../../../plugin/Plugin';

class CampaignAdminPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { historyTabPermissions: null };

    i18next.addResourceBundle('en', 'CampaignAdminPage', campaignAdminPageEN);
    i18next.addResourceBundle('fr', 'CampaignAdminPage', campaignAdminPageFR);
  }

  componentDidMount() {
    //Force main menu to be open always
    if (document.querySelector('.AdminSider').classList.contains('ant-layout-sider-collapsed')) {
      document.getElementById('mainmenu_trigger').click();
    }

    const authorization = this.props.checkAuthorization({ campaign: this.props.campaign.id }, [
      'permissions.campaign.campaign.logs'
    ]);
    if (typeof authorization === 'boolean') {
      this.setState({
        historyTabPermissions: authorization
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.historyTabPermissions === null) {
      const authorization = this.props.checkAuthorization({ campaign: this.props.campaign.id }, [
        'permissions.campaign.campaign.logs'
      ]);
      if (typeof authorization === 'boolean') {
        this.setState({
          historyTabPermissions: authorization
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.props.history.location.pathname.indexOf(`/d/campaigns/${this.props.campaign.id}/`) < 0)
      this.props.clearCampaign();
  }

  render() {
    const { t, locale, match, campaign } = this.props;
    const { historyTabPermissions } = this.state;

    const campaignUrl = campaign ? `/${locale}/${campaign.alias}` : '';

    const disabled = campaign.statusId !== 1 && campaign.statusId !== 4 ? true : false;
    const tooltipVisibility = !disabled ? { visible: false } : {};
    const tooltipMessage = campaign.statusId === 2 ? t('under-review') : t('inactive');

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: props => (
          <Can
            rules={['permissions.campaign.campaign.update']}
            campaignId={get(match, 'params.campaignID', null)}
            redirect={`/${locale}/d`}
          >
            <CampaignAdminPageForm {...this.props} />
          </Can>
        )
      },
      ...(campaign.behaviorId === 2
        ? [{
            title: t('tickets'),
            slug: 'tickets',
            // TODO verify if campaign behavior is event
            visible: isPluginActive(this.props.platform, 'Event'),
            container: props => <Plugin name="Event" component="EventAdminCampaignTicketList" />
          }]
        : []
      ),
      {
        title: t('LOGS:History'),
        slug: 'history',
        visible: historyTabPermissions,
        container: props => (
          <Can
            rules={['permissions.campaign.campaign.logs']}
            campaignId={get(match, 'params.campaignID', null)}
            redirect={`/${locale}/d`}
          >
            <CampaignAdminPageHistory {...this.props} />
          </Can>
        )
      }
    ];

    return (
      <div className="CampaignAdminPage history-table">
        <Helmet>
          <title>{t('About')}</title>
        </Helmet>
        <AdminTitleBar
          title={t('About')}
          buttons={[
            <Tooltip placement="top" title={tooltipMessage} {...tooltipVisibility}>
              <div>
                <span style={{ visibility: 'hidden' }}>-</span>
                {/*Ugly fix so that tooltip shows up even if button is disabled*/}
                <Link
                  to={campaignUrl}
                  className="ant-btn btn-view-page ant-btn-default"
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={disabled}
                >
                  <span>{t('View page')}</span>
                  <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                </Link>
              </div>
            </Tooltip>
          ]}
        />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} defaultTabHasNoSlug />
      </div>
    );
  }
}

export default translate('CampaignAdminPage')(CampaignAdminPage);

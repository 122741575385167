import { connect } from "react-redux";

import PublicCampaignBandMenu from './PublicCampaignBandMenu';

const mapStateToProps = state => {
  return ({
    colors: state.platform.platform.settings.color,
  });
};

export default connect(mapStateToProps)(PublicCampaignBandMenu);

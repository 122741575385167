import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import LanguageField_en from './locales/LanguageField_en.json';
import LanguageField_fr from './locales/LanguageField_fr.json';

class LanguageField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'LanguageField', LanguageField_en);
    i18next.addResourceBundle('fr', 'LanguageField', LanguageField_fr);
  }

  render() {
    const { t, form, savedValue, dropdownClassName } = this.props;
    const { getFieldDecorator } = form;
    const { Option, OptGroup } = Select;

    // Convert Saved Value NULL to undefined
    const initialValue = savedValue === null ? undefined : savedValue;

    const input = (
      <Select
        showSearch
        optionFilterProp="children"
        placeholder={t('language')}
        size="large"
        suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
        className="LanguageField-Select"
        dropdownClassName={dropdownClassName}
      >
        <Option key="English" value={1}>{t('en')}</Option>
        <Option key="Français" value={2}>{t('fr')}</Option>
      </Select>
    );
    const fieldId = 'locale';
    const fieldOptions = {
      rules: [
          {
            required: true,
            message: t('require')
          }
        ],
        initialValue: initialValue
    };

    return (
      <Form.Item
        className={`LanguageField ${this.props.className}`}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

LanguageField.defaultProps = {
  savedValue: undefined,
  dropdownClassName: ''
};

LanguageField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('LanguageField')(LanguageField);

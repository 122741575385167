import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import ConnectionModal from './ConnectionModal';
import { closeConnectionModal, forceCloseConnectionModal } from "../../ui/uiActions";

const mapStateToProps = state => ({
  connectionModalState: state.ui.connectionModalState,
  connectionModalForced: state.ui.connectionModalForced,
  customAlert: state.ui.customAlert,
  from: state.ui.from,
  locale: state.session.language,
  creationMode: state.ui.creationMode,
  sessionHistory: state.session.history
});

const mapDispatchToProps = dispatch => bindActionCreators({
  closeConnectionModal,
  forceCloseConnectionModal
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ConnectionModal);

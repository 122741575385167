import React from 'react';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './FeaturedCampaigns.less';

import FeaturedCampaignsEN from './locales/FeaturedCampaigns_en.json';
import FeaturedCampaignsFR from './locales/FeaturedCampaigns_fr.json';
import { getCdnUrl } from 'Parts/common/environment';

const cdnUrl = getCdnUrl();

class FeaturedCampaigns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: this.props.selectedItems
    };
    this.onDragEnd = this.onDragEnd.bind(this);

    i18next.addResourceBundle('en', 'FeaturedCampaigns', FeaturedCampaignsEN);
    i18next.addResourceBundle('fr', 'FeaturedCampaigns', FeaturedCampaignsFR);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedItems.length !== this.props.selectedItems.length) {
      this.setState({ selectedItems: this.props.selectedItems });
    }
  }

  // Triggers when clicking on an option
  handleChange = selectedItems => {
    this.setState({ selectedItems });
  };

  // Triggers when releasing a dragged item and used to reorder selectedItems state
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const selectedItems = this.reorder(
      this.state.selectedItems,
      result.source.index,
      result.destination.index
    );

    // Force setFieldsValue
    this.setState(
      {
        selectedItems
      },
      () => {
        this.props.form.setFieldsValue({
          [this.props.fieldId]: selectedItems
        });

        this.props.handleFeaturedOnChange();
      }
    );
  }

  // Fn to reorder the selectedItems state
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Get markup for campaign name and banner
  getCampaignNameBanner = campaignId => {
    const { campaigns } = this.props;
    let gotInfo = false;
    for (let i = 0; i < campaigns.length; i++) {
      if (gotInfo) break;

      if (campaigns[i].id === campaignId) {
        gotInfo = true;
        const campaignBannerUrl = campaigns[i].banner.banner
          ? `${campaigns[i].banner.banner}`
          : '/assets/default_participant_picture_300x300.jpg';
        return (
          <React.Fragment>
            <img
              className="Draggable_Item--Banner"
              src={`${cdnUrl + campaignBannerUrl}`}
              alt="Draggable Image"
            />
            <h3 className="Draggable_Item--Title">{campaigns[i].name}</h3>
          </React.Fragment>
        );
      }
    }
  };

  // Func that triggers handleFeaturedOnChange to enable/disable cancel/save buttons
  handleSelectDeselect = () => {
    this.props.handleFeaturedOnChange();
  };

  render() {
    const { t, form, fieldId, campaigns, disabled } = this.props;
    const { selectedItems } = this.state;
    const { getFieldDecorator } = form;

    // Get only the campaign name and id for the select options
    const OPTIONS = campaigns
      ? campaigns.map(campaign => {
          const { id, name } = campaign;
          return { id, name };
        })
      : [];

    const fieldOptions = {
      initialValue: selectedItems || null
    };

    const classes = classnames('featuredCampaignItem', { featureCampaigns_disabled: disabled });

    const fieldInput = (
      <Select
        className="FeaturedCampaigns--Select"
        mode="multiple"
        onChange={this.handleChange}
        onSelect={this.handleSelectDeselect}
        onDeselect={this.handleSelectDeselect}
        showArrow={true}
        optionFilterProp="children"
        filterOption={true}
      >
        {OPTIONS.map(item => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    );

    return (
      <Form.Item label={t('featured-label')} className={classes}>
        {getFieldDecorator(fieldId, fieldOptions)(fieldInput)}
        {selectedItems.length && campaigns ? (
          <React.Fragment>
            <p className="FeatureCampaign__Tip">{t('dnd-tip')}</p>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {provided => (
                  <div
                    className="Draggable_Wrap"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {selectedItems.map((item, index) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {provided => (
                          <div
                            className="Draggable_Item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <span className="Draggable_Item--Order">{`#${index + 1}`}</span>
                            {this.getCampaignNameBanner(item)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </React.Fragment>
        ) : null}
      </Form.Item>
    );
  }
}

FeaturedCampaigns.defaultProps = {
  selectedItems: []
};

FeaturedCampaigns.propTypes = {
  handleFeaturedOnChange: PropTypes.func.isRequired
};

export default translate('FeaturedCampaigns')(FeaturedCampaigns);

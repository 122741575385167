import { requestApiPost } from './requestUtils';
import { getSessionIp } from '../session/sessionUtils';

export const VERIFY_EMAIL_START = 'VERIFY_EMAIL_START';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';


export function verifyEmail(email) {
  return dispatch => {
    dispatch({ type: VERIFY_EMAIL_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      `/members/available/email`,
      { email },
      headers
    )
      .then(result => {
        const payload = result;
        dispatch({ type: VERIFY_EMAIL_SUCCESS, payload })
      })
      .catch(error => {
        dispatch({ type: VERIFY_EMAIL_ERROR, error })
      });
  }
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch(e) {
    return false;
  }
  return true;
}

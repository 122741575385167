import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import HeadMetas from './HeadMetas';

const mapStateToProps = state => ({
  orgazination: state.organization.organization,
  platform: state.platform.platform,
  team: state.team.team,
  participant: state.participant.participant,
  campaign: state.campaign.campaign,
  locale: state.session.language,
  platformShareImage: get(state.platform.platform, 'media.thumbnail.thumbnail', null),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
)(HeadMetas);

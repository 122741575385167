import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import RegisterDropdown from '../common/RegisterDropdownContainer';
import PublicPageClosed from '../common/PublicPageClosed';
import Plugin from '../plugin/Plugin';
import { isPluginActive } from '../plugin/pluginActions';
import { CAMPAIGN_TEMPLATE_SOLO } from '../campaign/campaignUtils';

import PublicPanel_en from './locales/PublicPanel_en.json';
import PublicPanel_fr from './locales/PublicPanel_fr.json';

import './PublicPanel.less';

class PublicPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicPanel', PublicPanel_en);
    i18next.addResourceBundle('fr', 'PublicPanel', PublicPanel_fr);
  }

  render() {
    const { t, searchPlaceholder, count, limit, full, campaign, id, platform } = this.props;
    const className = classnames('PublicPanel', this.props.className);

    const searchField = this.props.searchable ? (
      <Input placeholder={searchPlaceholder} suffix={<FontAwesomeIcon icon={["far", "search"]} />}/>
    ): null;

    const counter = count !== null ? (
      limit > 0 ? (
        <div className="PublicPanel__AvailablePlacesCounter">
          <span>{t('available')}</span>
          <div className="PublicPanel__Counter">
            <span>{Math.max(0, limit-count)}</span>
          </div>
        </div>
      ) : (
        <div className="PublicPanel__AvailablePlacesCounter">
          <span>{t('available')}</span>
          <div className="PublicPanel__Counter">
            <span>∞</span>
          </div>
        </div>
      )
    ) : null;

    const footerClass = count !== null ? (
        "PublicPanel__Footer"
    ) : "PublicPanel__Footer PublicPanel__Footer--right";

    const buyTicketBtn = campaign &&
      campaign.behaviorId === 2 &&
      campaign.typeId !== CAMPAIGN_TEMPLATE_SOLO &&
      platform &&
      isPluginActive(platform, 'Event')
        ? <Plugin
            name="Event"
            component="BuyTicketBtn"
            // resolve={() =>
            //   import(/* webpackChunkName: "Event" */ '../../plugin/plugins/Event/Event')
            // }
            size="2x"
            margin="1em 0"
            {...{
              // Props
              template: "button",
              plural: false
            }}
          />
        : null;

    const footer = this.props.footer ? (
      <div className={footerClass}>
        {counter}
        <div className="PublicPanel__CTA">
          {/* <Link className="PublicPanel__CTA--invite ant-btn-lg ant-btn" size="large" to="">{t('invite')}</Link> */}
          {buyTicketBtn || <RegisterDropdown className="PublicPanel__CTA--register" />}
        </div>
      </div>
    ) : null;

    return (
      <div className={className} id={id}>
        <div className="PublicPanel__Divider">
          <h2 >{this.props.title}</h2>
          {/* searchField */}
          {this.props.editAction || null}
        </div>
        <div className="PublicPanel__Content wysiwygStyle">
          {this.props.children}
        </div>
        {footer}
        {campaign &&
          <PublicPageClosed location="Panel"  campaign={campaign}/>
        }
      </div>
    );
  }

}

PublicPanel.defaultProps = {
  className: null,
  children: null,
  searchable: false,
  onSearch: noop,
  searchPlaceholder: '',
  count: null,
  limit: 0,
  full: '',
  id: 'publicPanel'
};

PublicPanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  searchable: PropTypes.bool,
  onSearch: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  count: PropTypes.number,
  full: PropTypes.string,
  //limit: PropTypes.number
};

export default translate('PublicPanel')(PublicPanel);

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import TransactionsPageForm from './components/TransactionsPageFormContainer';
import TransactionsInvoiceList from './components/TransactionsInvoiceListContainer';
import TransactionsHistoryPage from './components/TransactionsHistoryPageContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import AdminBackButtonBar from 'Parts/common/AdminBackButtonBar';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';
import Plugin from '../../plugin/Plugin';

import { isPluginActive } from '../../plugin/pluginActions';

import transactionsPageEN from './locales/TransactionsPage_en.json';
import transactionsPageFR from './locales/TransactionsPage_fr.json';

class TransactionsPage extends React.Component {
  constructor(props) {
    super(props);

    this.fromUrl = props.location.state && props.location.state.fromUrl;

    this.state = {
      historyTabPermissions: null,
      taxReceiptPermissions: null
    };

    i18next.addResourceBundle('en', 'TransactionsPage', transactionsPageEN);
    i18next.addResourceBundle('fr', 'TransactionsPage', transactionsPageFR);
  }

  componentDidMount() {
    const { props } = this,
      { platform, organization, checkAuthorization } = props;

    // Erase the location state (the info has been saved in the constructor).
    const { state: locationState } = this.props.location;
    if (locationState && locationState.fromUrl) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }

    this.setState({
      historyTabPermissions: checkAuthorization({ platform: platform.id }, [
        'roles.platform.SUPERADMIN'
      ]),
      taxReceiptPermissions: checkAuthorization({ platform: platform.id }, [
        () => organization.typeId === 2 && isPluginActive(this.props.platform, 'TaxReceipt'),
        'permissions.platform.plugin.taxReceipt.create',
        'permissions.platform.plugin.taxReceipt.download',
        'permissions.platform.plugin.taxReceipt.send',
        'permissions.platform.plugin.taxReceipt.cancel'
      ])
    });
  }

  componentDidUpdate() {
    const { props } = this,
      { organization, platform, checkAuthorization } = props;

    if (this.state.historyTabPermissions === null) {
      const authorization = checkAuthorization({ platform: platform.id }, [
        'roles.platform.SUPERADMIN'
      ]);
      if (typeof authorization === 'boolean') {
        this.setState({
          historyTabPermissions: authorization
        });
      }
    }

    if (this.state.taxReceiptPermissions === null) {
      const authorization = checkAuthorization({ platform: platform.id }, [
        () => organization.typeId === 2 && isPluginActive(this.props.platform, 'TaxReceipt'),
        'permissions.platform.plugin.taxReceipt.create',
        'permissions.platform.plugin.taxReceipt.download',
        'permissions.platform.plugin.taxReceipt.send',
        'permissions.platform.plugin.taxReceipt.cancel'
      ]);

      if (typeof authorization === 'boolean') {
        this.setState({
          taxReceiptPermissions: authorization
        });
      }
    }
  }

  render() {
    const { props } = this,
      { t, transaction, locale, permissions, match, platform, organization, dispatch } = props;

    const transactionsListUrl = this.fromUrl
      ? `/${locale}/d/transactions/filter${this.fromUrl}`
      : `/${locale}/d/transactions/list`;
    const transactionID = get(transaction, 'referenceId', '');

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: () => (
          <Can rules={['permissions.platform.transaction.get']} redirect={`/${locale}/d`}>
            <TransactionsPageForm {...props} />
          </Can>
        )
      },
      {
        title: t('Invoices'),
        slug: 'invoices',
        visible: get(transaction, 'subscription') ? true : false,
        container: () => (
          <Can rules={['permissions.platform.transaction.get']} redirect={`/${locale}/d`}>
            <TransactionsInvoiceList {...props} />
          </Can>
        )
      },
      {
        title: t('TaxReceipt:tab-title') === 'tab-title' ? '' : t('TaxReceipt:tab-title'),
        slug: 'donationreceipt',
        visible: this.state.taxReceiptPermissions,
        container: () => (
          <Can
            rules={[
              () => organization.typeId === 2 && isPluginActive(platform, 'TaxReceipt'),
              'permissions.platform.plugin.taxReceipt.create',
              'permissions.platform.plugin.taxReceipt.download',
              'permissions.platform.plugin.taxReceipt.send',
              'permissions.platform.plugin.taxReceipt.cancel'
            ]}
            redirect={`/${locale}/d`}
          >
            <Plugin
              name="TaxReceipt"
              component="TaxReceiptTabRoute"
              {...{
                // Props
                locale: locale,
                transaction: transaction,
                permissions: permissions,
                match: match,
                dispatch: dispatch
              }}
            />
          </Can>
        )
      },
      {
        title: t('LOGS:History'),
        slug: 'history',
        visible: this.state.historyTabPermissions,
        container: () => (
          <Can rules={['permissions.platform.transaction.logs']} redirect={`/${locale}/d`}>
            <TransactionsHistoryPage {...props} />
          </Can>
        )
      }
    ];

    return (
      <div className="TransactionsPage">
        <Helmet>
          <title>{t('page-title')}</title>
        </Helmet>
        <AdminBackButtonBar href={transactionsListUrl} text={t('Back to search')} />
        <AdminTitleBar
          title={t('Transactions')}
          subName={`${t('Transaction ID')} ${transactionID}`}
        />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} defaultTabHasNoSlug />
      </div>
    );
  }
}

TransactionsPage.defaultProps = {
  permissions: {
    platform: {
      plugin: {
        taxReceipt: {
          cancel: false,
          create: false,
          download: false,
          logs: false,
          send: false
        }
      },
      transaction: {
        get: false,
        logs: false
      }
    }
  },
  transaction: {}
};

export default translate('TransactionsPage')(TransactionsPage);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'antd';

import {
  searchCampaigns,
  searchParticipants,
  searchTeamsInCampaign,
  searchParticipantsInCampaign,
  searchParticipantsInTeam,
} from '../../search/searchActions';

import {
  fetchCampaign,
} from '../../campaign/campaignActions';

import {
  fetchTeam,
} from '../../team/teamActions';

import {
  fetchParticipant,
} from '../../participant/participantActions';

import {
  selectCampaign,
  selectTeam,
  selectParticipant,
} from '../../donation/donationActions';

import ChangeRecipientModalContent from './ChangeRecipientModalContent';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  participant: state.participant.participant,
  participants: state.search.participants,
  team: state.team.team,
  teams: state.search.teams,
  campaign: state.campaign.campaign,
  campaigns: state.search.campaigns,
  locale: state.session.language,
  donationCreateWizardState: state.ui.donationCreateWizardState,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchCampaigns,
  searchParticipants,
  searchTeamsInCampaign,
  searchParticipantsInCampaign,
  searchParticipantsInTeam,
  fetchCampaign,
  fetchTeam,
  fetchParticipant,
  selectCampaign,
  selectTeam,
  selectParticipant,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create(),
  withRouter,
)(ChangeRecipientModalContent);

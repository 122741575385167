import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Layout, Menu, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import adminHeaderEN from './locales/AdminHeader_en.json';
import adminHeaderFR from './locales/AdminHeader_fr.json';
import PlatformSwitcher from './PlatformSwitcher.jsx';

import './AdminHeader.less';

class AdminHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formEditingNotice: false
    };

    i18next.addResourceBundle('en', 'AdminHeader', adminHeaderEN);
    i18next.addResourceBundle('fr', 'AdminHeader', adminHeaderFR);

    this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
  }

  handleUserMenuClick({ key }) {
    switch (key) {
      case 'home':
        break;

      case 'profile':
        break;

      case 'logout':
        this.props.logout({ pathname: `/${this.props.locale}` });
        break;

      case 'locale':
        if (this.props.isEditingForm) {
          this.setState({ formEditingNotice: true });
        } else {
          this.changeLang();
        }
        break;

      case 'faq':
        break;
    }
  }

  changeLang = () => {
    const { i18n, locale, history, setSessionLanguage, location } = this.props;
    let lngToSwitch = locale === 'en' ? 'fr' : 'en';
    let path = location.pathname.replace(`/${locale}/`, `/${lngToSwitch}/`);
    if (location.search) {
      path = path + location.search;
    }

    i18n.changeLanguage(lngToSwitch);
    setSessionLanguage(lngToSwitch).then(() => {
      history.push(path);
      document.getElementsByTagName('html')[0].lang = lngToSwitch;
    });
  };

  handleFormEditingNoticeOk = () => {
    this.setState({ formEditingNotice: false });
    this.changeUrl();
  };

  handleFormEditingNoticeCancel = () => {
    this.setState({ formEditingNotice: false });
  };

  render() {
    const { t, platform, locale, user } = this.props;

    const userMenuItem = (
      <Menu.SubMenu
        className="AdminHeader__UserSubMenu"
        title={
          <span>
            {this.props.user.firstName} <FontAwesomeIcon icon={['fas', 'caret-down']} />
          </span>
        }
      >
        <Menu.Item key="profile">
          <Link to={`/${locale}/d/profile/${user.id}/my-information`}>
            <FontAwesomeIcon
              className="AdminHeader__PlatformSubMenuIcon"
              icon={['far', 'arrow-right']}
            />
            {t('Profile')}
          </Link>
        </Menu.Item>
        <Menu.Item key="logout">
          <FontAwesomeIcon
            className="AdminHeader__PlatformSubMenuIcon"
            icon={['far', 'arrow-right']}
          />
          {t('Logout')}
        </Menu.Item>
      </Menu.SubMenu>
    );

    return (
      <Layout.Header className="AdminHeader">
        <PlatformSwitcher currentPlatform={platform} />
        <div id="menuDesktop" className="desktopOnly">
          <Menu
            className="AdminHeader__UserMenu"
            onClick={this.handleUserMenuClick}
            mode="horizontal"
            selectable={false}
          >
            <Menu.Item key="home">
              <a href={`/${locale}`} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className="AdminHeader__PlatformMenuIcon" icon={['fal', 'home']} />
              </a>
            </Menu.Item>
            {userMenuItem}
            <Menu.Item key="faq">
              <a href={t('faq-url')} target="_blank" rel="noopener noreferrer">
                FAQ
              </a>
            </Menu.Item>
            <Menu.Item key="locale">{t('lng')}</Menu.Item>
          </Menu>
        </div>
        {/* /menuDesktop */}
        <div id="menuMobile" className="mobileOnly">
          <a href={`/${locale}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="AdminHeader__PlatformMenuIcon" icon={['fal', 'home']} />
          </a>
          <Menu
            className="AdminHeader__UserMenu"
            onClick={this.handleUserMenuClick}
            mode="horizontal"
            selectable={false}
          >
            <Menu.SubMenu
              className="AdminHeader__UserSubMenu"
              title={
                <FontAwesomeIcon
                  className="AdminHeader__PlatformMenuIcon"
                  icon={['far', 'ellipsis-v']}
                />
              }
            >
              {userMenuItem}
              <Menu.Item key="faq">
                <a href={t('faq-url')} target="_blank" rel="noopener noreferrer">
                  FAQ
                </a>
              </Menu.Item>
              <Menu.Item key="locale">{t('lng')}</Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </div>
        {/* /menuMobile */}
        <Modal
          title={t('unsaved-changes-notice-title')}
          visible={this.state.formEditingNotice}
          onOk={this.handleFormEditingNoticeOk}
          onCancel={this.handleFormEditingNoticeCancel}
        >
          <b>
            <p>{t('unsaved-changes-notice-description')}</p>
          </b>
          <p>{t('unsaved-changes-notice-question')}</p>
        </Modal>
      </Layout.Header>
    );
  }
}

AdminHeader.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired
  }).isRequired,
  locale: PropTypes.oneOf(['en', 'fr']).isRequired,
  logout: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(translate('AdminHeader')(AdminHeader));

import React from 'react';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { Button } from 'antd';
import { get } from 'lodash';

import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Plugin from '../../../plugin/Plugin';

import { simpleColumn, dateColumn } from 'Parts/common/columnUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isPluginActive } from '../../../plugin/pluginActions';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';

import MyContributionsListDonationsEN from './locales/MyContributionsListDonations_en.json';
import MyContributionsListDonationsFR from './locales/MyContributionsListDonations_fr.json';

class MyContributionsListDonations extends React.Component {
  constructor(props) {
    super(props);

    props.transactions.total = props.pagination.totalResults;

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle('en', 'MyContributionsListDonations', MyContributionsListDonationsEN);
    i18next.addResourceBundle('fr', 'MyContributionsListDonations', MyContributionsListDonationsFR);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchMemberTransactions(
      this.props.memberId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      false
    );
  };

  viewDonation = donation => {
    return this.props.history.push(
      `/${this.props.locale}/d/contributions/donations/${donation.id}`
    );
  };

  render() {
    const { t, transactions, pagination, locale } = this.props;
    const { pager } = this.state;

    return (
      <div className="AdminList">
        <PaginationTop
          totalLabel={total => t('contributionsTotal', { count: pagination.totalResults || 0 })}
          pager={pager}
          onChange={this.handlePaginationChange}
        />
        <ResultTable
          dataSource={transactions}
          pagination={pager}
          onChange={this.handlePaginationChange}
          showHeader={true}
          columns={[
            simpleColumn(t('Recipient'), 'donation.target.name', null, true),
            simpleColumn(
              t('Amount'),
              null,
              transaction => {
                const sub = get(transaction, 'subscription.amount', null);
                return moneyFormat(sub || transaction.amount, locale) + (sub ? '/m' : '');
              },
              true
            ),
            //simpleColumn(t('Amount'), 'amount', text => moneyFormat(text, locale)),
            //simpleColumn(t('Type'), 'type', type => t(type), null),
            dateColumn(t('Created on'), 'addedOn', null, null, false, locale),
            simpleColumn(t('Status'), 'status', status => t(status), null),
            simpleColumn(t('Actions'), null, transactions => {
              let buttons = [
                <Button
                  key={`${transactions.id}-1`}
                  shape="circle"
                  onClick={() => {
                    this.viewDonation(transactions);
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'eye']} size="lg" />
                </Button>
              ];

              const taxReceiptStatusId = get(transactions, 'plugins.taxReceipt[0].statusId', 1);

              if (
                // CREATED || CANCELLED
                (taxReceiptStatusId === 3 || taxReceiptStatusId === 4) &&
                isPluginActive(this.props.platform, 'TaxReceipt') &&
                this.props.organization.typeId === 2
              ) {
                buttons.push(
                  <span key={`${transactions.id}-2`}>
                    <Plugin
                      name="TaxReceipt"
                      component="TaxReceiptButton"
                      {...{
                        // Props
                        transaction: transactions,
                        margin: "0"
                      }}
                    />
                  </span>
                );
              }

              return <div className="buttons">{buttons}</div>;
            })
          ]}
        />
      </div>
    );
  }
}

MyContributionsListDonations.defaultProps = {
  transactions: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('MyContributionsListDonations')(MyContributionsListDonations);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { storeDonationInformation, cancelTransaction } from '../donationActions';

import CreateDonationForm from './CreateDonationForm';

const mapStateToProps = state => ({
  infos: state.donation.donationInformation,
  platform: state.platform.platform,
  organization: state.organization.organization,
  countries: state.geo.countries,
  campaign: state.campaign.campaign,
  selectedCampaign: state.donation.selectedCampaign,
  selectedParticipant: state.donation.selectedParticipant,
  selectedTeam: state.donation.selectedTeam,
  locale: state.session.language,
  surveyId:state.donation.surveyId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  storeDonationInformation,
  cancelTransaction
}, dispatch);


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(CreateDonationForm);

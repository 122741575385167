// Import the main style once and for all, all import in .less files should use the "(reference)" option
// Otherwise Antd will be outputed multiple times in the final bundle
import './assets/stylesheets/main.less';

//----- REACT MODULE ------//
import React from 'react';
import {
  render,
  hydrate
} from 'react-dom';
import {
  Router
} from 'react-router-dom';
import i18n from './chore/i18n-client';
import isBrowser from 'is-in-browser';

//------- FUNDKY MODULE -----//
import * as cookieUtils from './parts/common/cookieUtils';
import configureStore from './parts/chore/configureStore';
import {
  AUTH_LOGOUT_SUCCESS,
  connect,
  detectBrowser
} from './parts/session/sessionActions';
import {
  getLocale
} from './parts/session/sessionSelectors';
import rootReducer from './chore/rootReducer';
import history from './parts/chore/history';
import App from './chore/App';

let locale;
cookieUtils.init();

/* Server generated error with window */
const preloadState = isBrowser && window.__PRELOADED_STATE__ || undefined;

const store = configureStore(
  rootReducer,
  preloadState,
  history,
  {
    logoutActionType: AUTH_LOGOUT_SUCCESS,
    whiteList: [
      'platform.platform',
      'platform.languages',
      'session.browser',
      'session.ip',
      'session.token',
      'session.sessionTimeout',
      'session.language',
      'session.languageId',
      'session.history',
      'session.displaySessionTimeoutMessage',
      'organization.organization',
      'organization.isBillingConfigured',
      'campaign',
      'team',
      'participant',
      'search.campaigns',
      'search.teams',
      'search.participants',
      'donation',
      'ui'
    ]
  }
);

Promise.all([
  store.dispatch(connect()),
  store.dispatch(detectBrowser()),
  locale = getLocale(store.getState())
])
  .then(() => {
    const rootComponent = React.createElement(Router, {
        history
      },
      React.createElement(App, {
        store,
        i18n,
        locale
      })
    );

    if (isBrowser) {
      const doReactStuff = preloadState ? hydrate : render;
      doReactStuff(rootComponent, document.getElementById('main'));
    }
  });

import React from 'react';
import { translate } from 'react-i18next';

import FundkyModal from '../../ui/components/FundkyModal';

class SessionExpirationWarningModal extends React.Component {
  render() {
    const { t, displaySessionTimeoutWarning, logout, connect } = this.props;

    return (
      <FundkyModal
        className="SessionExpirationWarningModal"
        displayModal={displaySessionTimeoutWarning}
        acceptAction={connect}
        cancelAction={logout}
        acceptLabel={t('SESSION:EXPIRATION.WARNING.connect')}
        cancelLabel={t('SESSION:EXPIRATION.WARNING.logout')}
      >
        {t('SESSION:EXPIRATION.WARNING.message')}
      </FundkyModal>
    );
  }

}

SessionExpirationWarningModal.defaultProps = {
  displaySessionTimeoutWarning: false
};

export default translate('SessionExpirationWarningModal')(SessionExpirationWarningModal);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from 'Parts/chore/dataFetcher';

import { searchCampaigns, clearSearch } from '../../search/searchActions';
import { checkAuthorization } from 'Parts/common/authorizationUtils';

import CampaignListPage from './CampaignListPage';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  campaignEnabled: state.platform.platform.settings.campaign.creation.enabled,
  campaigns: state.search.campaigns.results,
  pagination: state.search.campaigns.pagination,
  filters: state.search.campaigns.filters,
  search: state.search.campaigns.search,
  orderBy: state.search.campaigns.orderBy,
  locale: state.session.language,
  isCampaignOwner: state.session.isCampaignOwner
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchCampaigns,
  clearSearch,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(CampaignListPage);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import CampaignSettingsPage from './CampaignSettingsPage';

const mapStateToProps = state => ({
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(CampaignSettingsPage);

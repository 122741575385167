import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import dataFetcher from 'Parts/chore/dataFetcher';
import {
  sendRegistrationEmail
} from 'Parts/session/sessionActions';

import { checkAuthorization } from 'Parts/common/authorizationUtils'

import DashboardPage from './DashboardPage';

const mapStateToProps = state => ({
  user: state.session.user,
  platform: state.platform.platform,
  organization: state.organization.organization,
  locale: state.session.language,
  lastCampaigns: state.campaign.campaigns,
  isBillingConfigured: get(state, 'organization.isBillingConfigured'),
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
      sendRegistrationEmail,
      checkAuthorization
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps), dataFetcher)(DashboardPage);

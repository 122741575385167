import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import ParticipationTypeOptions from './ParticipationTypeOptionsContainer';

import ParticipationTypeField_en from './locales/ParticipationTypeField_en.json';
import ParticipationTypeField_fr from './locales/ParticipationTypeField_fr.json';

class ParticipationTypeField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'ParticipationTypeField', ParticipationTypeField_en);
    i18next.addResourceBundle('fr', 'ParticipationTypeField', ParticipationTypeField_fr);

    this.state = {
      selected: null
    };
  }

  isTeamsFull = campaign => {
    const
      { settings, childrenCount } = campaign,
      teamLimit = settings && settings.team && parseInt(settings.team.limit) || 0,
      teamsCount = childrenCount && childrenCount.teams || 0,
      participantLimit = settings && settings.participant && parseInt(settings.participant.limit) || 0,
      participantsCount = childrenCount && childrenCount.participants || 0;

    if(
      teamLimit && teamsCount && teamsCount >= teamLimit &&
      participantLimit && participantsCount && participantsCount >= participantLimit * teamLimit
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { t, form, className, value, campaign } = this.props;
    const { getFieldDecorator } = form;

    const input = (
      <ParticipationTypeOptions />
    );
    const fieldId = 'participationType';
    const fieldOptions = {
      rules: [
        { required: true, message: t('require') }
      ],
      initialValue: !this.isTeamsFull(campaign) ? value : null,
    };

    return (
      <Form.Item
        className={className}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

ParticipationTypeField.defaultProps = {
  value: null
}

ParticipationTypeField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('ParticipationTypeField')(ParticipationTypeField);

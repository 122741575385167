import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { facebookScrape } from 'Parts/common/requestUtils';

import LinkButton from 'Parts/common/LinkButton';
import FundkyButton from 'Parts/ui/components/FundkyButton';

import CreateConfirmationPanel_en from './locales/CreateConfirmationPanel_en.json';
import CreateConfirmationPanel_fr from './locales/CreateConfirmationPanel_fr.json';

import './CreateConfirmationPanel.less';
import { getPlatformUrl } from 'Parts/common/environment';

class CreateConfirmationPanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleFbShare = this.handleFbShare.bind(this);

    i18next.addResourceBundle('en', 'CreateConfirmationPanel-team', CreateConfirmationPanel_en);
    i18next.addResourceBundle('fr', 'CreateConfirmationPanel-team', CreateConfirmationPanel_fr);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.teamCreateWizardState !== this.props.teamCreateWizardState) {
      document.getElementById('main').scrollTo(0, 0);
    }

    if (prevProps.isUserLogged !== this.props.isUserLogged && !this.props.isUserLogged) {
      this.props.history.push(`/${this.props.locale}`);
    }
  }

  handleFbShare = (event, url) => {
    FB.ui(
      {
        method: 'share',
        href: url,
        hashtag: this.props.t('facebook.hashtags')
      },
      function(response) {}
    );
  };

  componentDidMount() {
    const { locale, selectedCampaign, platformAlias, createdTeam } = this.props;
    const platformUrl = getPlatformUrl(platformAlias);
    facebookScrape([`${platformUrl}/${locale}/${selectedCampaign.alias}/${createdTeam.alias}`]);
  }

  render() {
    const { t, locale, selectedCampaign, createdTeam, platformAlias } = this.props;

    const platformUrl = getPlatformUrl(platformAlias);

    if (!this.props.createdTeam) {
      return null;
    } else {
      return (
        <div className="FundkyWrap">
          <div className="CreateConfirmationPanel">
            <FontAwesomeIcon
              icon={['fal', 'check-circle']}
              className="CreateConfirmationPanel__SuccessIcon"
            />
            <h2>{t('title')}</h2>
            <p>{t('desc')}</p>
            <h3>{t('subtitle')}</h3>
            <ul>
              <li>{t('li1')}</li>
              <li>{t('li2')}</li>
            </ul>
            <div className="CreateConfirmationPanel__Divider CreateConfirmationPanel__Divider--CTA">
              <LinkButton
                to={`/${locale}/${selectedCampaign.alias}/${createdTeam.alias}`}
                size="large"
                type="primary"
              >
                {t('my-team')}
              </LinkButton>
              <FundkyButton
                className="CreateConfirmationPanel--inviteBtn"
                icon={['fab', 'facebook-f']}
                iconPosition="before"
                text={t('invite')}
                action={{
                  func: this.handleFbShare,
                  params: [
                    `${platformUrl}/${locale}/${selectedCampaign.alias}/${createdTeam.alias}`
                  ]
                }}
                size="large"
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

CreateConfirmationPanel.defaultProps = {
  selectedcampaign: {
    alias: ''
  },
  createdTeam: {
    alias: ''
  }
};

CreateConfirmationPanel.propTypes = {
  selectedCampaign: PropTypes.shape({
    alias: PropTypes.string.isRequired
  }).isRequired,
  createdTeam: PropTypes.shape({
    alias: PropTypes.string.isRequired
  }).isRequired
};

export default translate('CreateConfirmationPanel-team')(CreateConfirmationPanel);

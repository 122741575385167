import React from 'react';
import PropTypes from 'prop-types';
import { facebookScrape } from 'Parts/common/requestUtils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { has, get, isEmpty } from 'lodash';

import CreateConfirmationPanel_en from './locales/CreateConfirmationPanel_en.json';
import CreateConfirmationPanel_fr from './locales/CreateConfirmationPanel_fr.json';

import LinkButton from 'Parts/common/LinkButton';
import FundkyButton from 'Parts/ui/components/FundkyButton';

import './CreateConfirmationPanel.less';
import { getPlatformUrl } from 'Parts/common/environment.js';

class CreateConfirmationPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle(
      'en',
      'CreateConfirmationPanel-participant',
      CreateConfirmationPanel_en
    );
    i18next.addResourceBundle(
      'fr',
      'CreateConfirmationPanel-participant',
      CreateConfirmationPanel_fr
    );
  }

  componentDidMount() {
    const { platformAlias } = this.props;
    const platformUrl = getPlatformUrl(platformAlias);
    const publicPageUrl = this.getParticipantUrl();
    facebookScrape([`${platformUrl}${publicPageUrl}`]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isUserLogged !== this.props.isUserLogged && !this.props.isUserLogged) {
      this.props.history.push(`/${this.props.locale}`);
    }
  }

  isWaitingApproval = () => {
    if (
      has(this.props, 'createdParticipant.teamId') &&
      get(this.props, 'createdParticipant.statusId', null) === 2
    ) {
      return true;
    }
    return false;
  };

  getParticipantUrl = (admin = false) => {
    const {
      t,
      locale,
      createdParticipant,
      selectedCampaign,
      selectedTeam,
      currentUser
    } = this.props;
    if (!admin) {
      if (createdParticipant.teamId) {
        return `/${locale}/${selectedCampaign.alias}/${selectedTeam.alias}/${currentUser.alias}`;
      } else {
        return `/${locale}/${selectedCampaign.alias}/${t('individual-participant')}/${
          currentUser.alias
        }`;
      }
    } else {
      return `/${locale}/d/contributions/participations/${createdParticipant.id}`;
    }
  };

  handleFbShare = (event, url) => {
    FB.ui(
      {
        method: 'share',
        href: url,
        hashtag: this.props.t('facebook.hashtags')
      },
      function(response) {}
    );
  };

  render() {
    // if (!this.props.isUserLogged) {
    //   this.props.history.push(`/${this.props.locale}`);
    // }

    if (isEmpty(this.props.selectedCampaign)) {
      return null;
    } else {
      const { props, isWaitingApproval, getParticipantUrl } = this,
        { t, platformAlias } = props,
        platformUrl = getPlatformUrl(platformAlias),
        publicPageUrl = getParticipantUrl(),
        adminPageUrl = getParticipantUrl(true);

      return (
        <div className="FundkyWrap">
          <div className="CreateConfirmationPanel">
            <FontAwesomeIcon
              icon={['fal', 'check-circle']}
              className="CreateConfirmationPanel__SuccessIcon"
            />
            <h2>{t('title')}</h2>
            {!isWaitingApproval() ? (
              <React.Fragment>
                <p>{t('desc')}</p>
                <h3>{t('subtitle')}</h3>
                <ul>
                  <li>{t('li1')}</li>
                  <li>{t('li2')}</li>
                </ul>
                <div className="CreateConfirmationPanel__Divider CreateConfirmationPanel__Divider--CTA">
                  <LinkButton to={publicPageUrl} size="large" type="primary">
                    {t('my-page')}
                  </LinkButton>
                  <FundkyButton
                    className="CreateConfirmationPanel--inviteBtn"
                    icon={['fab', 'facebook-f']}
                    iconPosition="before"
                    text={t('invite')}
                    action={{
                      func: this.handleFbShare,
                      params: [`${platformUrl}${publicPageUrl}`]
                    }}
                    size="large"
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p>
                  <strong>{t('need-approval.desc1')}</strong> {t('need-approval.desc2')}
                </p>
                <h3>{t('need-approval.subtitle')}</h3>
                <ul>
                  <li>{t('need-approval.li1')}</li>
                  <li>{t('need-approval.li2')}</li>
                </ul>
                <div className="CreateConfirmationPanel__Divider CreateConfirmationPanel__Divider--CTA">
                  <LinkButton to={adminPageUrl} size="large" type="primary">
                    {t('need-approval.add-details')}
                  </LinkButton>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    }
  }
}

CreateConfirmationPanel.propTypes = {
  selectedCampaign: PropTypes.shape({
    alias: PropTypes.string.isRequired
  }).isRequired,
  createdTeam: PropTypes.shape({
    alias: PropTypes.string.isRequired
  }),
  createdParticipant: PropTypes.shape({
    teamId: PropTypes.number
  }).isRequired,
  currentUser: PropTypes.shape({
    alias: PropTypes.string.isRequired
  })
};

export default translate('CreateConfirmationPanel-participant')(CreateConfirmationPanel);

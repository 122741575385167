import React from 'react';
import PropTypes from 'prop-types';
import HeadMetas from '../../common/HeadMetasContainer';
import { Layout, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import {
  PARTICIPANT_CREATE_WIZARD_CAMPAIGN,
  PARTICIPANT_CREATE_WIZARD_INFORMATION,
  PARTICIPANT_CREATE_WIZARD_CONFIRMATION,
  PARTICIPANT_CREATE_SURVEY_ATTEMPT
} from '../../ui/uiUtils';

import SelectCampaignPanel from '../components/SelectCampaignPanelContainer';
import CreateSurveyAttempt from '../components/CreateSurveyAttemptFormContainer';
import CreateInformationForm from '../components/CreateInformationFormContainer';
import CreateConfirmationPanel from '../components/CreateConfirmationPanelContainer';

import ParticipantCreatePage_en from './locales/ParticipantCreatePage_en.json';
import ParticipantCreatePage_fr from './locales/ParticipantCreatePage_fr.json';

import './ParticipantCreatePage.less';

const { TabPane } = Tabs;

class ParticipantCreatePage extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'ParticipantCreatePage', ParticipantCreatePage_en);
    i18next.addResourceBundle('fr', 'ParticipantCreatePage', ParticipantCreatePage_fr);

    this.state = {
      disableCampaignTab: !!(props.location.state && props.location.state.campaign),
      preSelectedTeam: props.location.state && props.location.state.team,
    }
  }

  componentDidMount = async() => {
    await this.props.fetchPlatformIdBySurveyList(this.props.platformId, this.props.selectedCampaign.id, [], "participant-form", this.props.locale)
    this.props.activateCreationMode();

    const { state } = this.props.location;
    if (state && state.campaign) {
      this.props.selectCampaign(state.campaign);
      this.props.history.replace({ ...this.props.location, state: {} });
    }

    if(state && state.team !== this.state.preSelectedTeam) {
      this.setState({
        preSelectedTeam: state.team
      });
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.selectedCampaign !== this.props.selectedCampaign && this.props.selectedCampaign.id !== undefined) {
      await this.props.fetchPlatformIdBySurveyList(this.props.platformId, this.props.selectedCampaign.id, [], "participant-form", this.props.locale)
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedCampaign();
    this.props.deactivateCreationMode();
  }

  render() {
    const { t } = this.props;
    let currentStep;
    if (this.props.currentStep === PARTICIPANT_CREATE_WIZARD_CAMPAIGN && this.state.disableCampaignTab) {
      currentStep = PARTICIPANT_CREATE_WIZARD_INFORMATION;
    } else {
      currentStep = this.props.currentStep;
    }
    let hideSurveyTab = this.props.surveyId ? false : true;
    return (
      <Layout className="ParticipantCreatePage">
        <HeadMetas title={t('participate.title')} />
        <div className="ParticipantCreatePage__TitleDivider FundkyWrap">
          <FontAwesomeIcon icon={["fal", "user-plus"]} className="ParticipantCreatePage__TitleIcon" />
          <h1>{t('title')}</h1>
        </div>
        <Tabs className="FillActive" activeKey={currentStep} onTabClick={key => this.props.changeParticipantCreateWizardStep(key)}>
          <TabPane
            tab={<span>{t('tab1')} {currentStep !== PARTICIPANT_CREATE_WIZARD_CAMPAIGN && <FontAwesomeIcon icon={["fas", "check"]} />}</span>}
            disabled={currentStep !== PARTICIPANT_CREATE_WIZARD_CONFIRMATION}
            key={PARTICIPANT_CREATE_WIZARD_CAMPAIGN}
          >
            <SelectCampaignPanel />
          </TabPane>
          <TabPane
            tab={<span>{t('tab2')} {(currentStep === PARTICIPANT_CREATE_SURVEY_ATTEMPT || currentStep === PARTICIPANT_CREATE_WIZARD_CONFIRMATION) && <FontAwesomeIcon icon={["fas", "check"]} />}</span>}
            disabled={currentStep !== PARTICIPANT_CREATE_WIZARD_INFORMATION}
            key={PARTICIPANT_CREATE_WIZARD_INFORMATION}
          >
            <CreateInformationForm disableCampaignTab={this.state.disableCampaignTab} preSelectedTeam={this.state.preSelectedTeam} surveyId={ this.props.surveyId } />
          </TabPane>
          {
            !hideSurveyTab && (
              <TabPane
                tab={<span>{t('tab3')} {currentStep === PARTICIPANT_CREATE_WIZARD_CONFIRMATION && <FontAwesomeIcon icon={["fas", "check"]} />}</span>}
                disabled={currentStep !== PARTICIPANT_CREATE_SURVEY_ATTEMPT}
                key={PARTICIPANT_CREATE_SURVEY_ATTEMPT}
              >
                <CreateSurveyAttempt />
              </TabPane>
            )
          }
          <TabPane
            tab={<span>{t('tab4')}</span>}
            disabled={currentStep !== PARTICIPANT_CREATE_WIZARD_CONFIRMATION}
            key={PARTICIPANT_CREATE_WIZARD_CONFIRMATION}
          >
            <CreateConfirmationPanel />
          </TabPane>
        </Tabs>
      </Layout>
    );
  }

}

ParticipantCreatePage.propTypes = {
  currentStep: PropTypes.oneOf([
    PARTICIPANT_CREATE_WIZARD_CAMPAIGN,
    PARTICIPANT_CREATE_WIZARD_INFORMATION,
    PARTICIPANT_CREATE_WIZARD_CONFIRMATION
  ]).isRequired,
  changeParticipantCreateWizardStep: PropTypes.func.isRequired,
  selectCampaign: PropTypes.func.isRequired
};

export default translate('ParticipantCreatePage')(ParticipantCreatePage);

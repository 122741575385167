import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import adminSidebarSettingsEN from './locales/AdminSidebarSettings_en.json';
import adminSidebarSettingsFR from './locales/AdminSidebarSettings_fr.json';

class AdminSidebarSettings extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AdminSidebarSettings', adminSidebarSettingsEN);
    i18next.addResourceBundle('fr', 'AdminSidebarSettings', adminSidebarSettingsFR);
  }

  render() {

    const { t } = this.props;

    // We need a combination of the react-router NavLink and the Antd Button... let's use NavLink and put the Antd classes on it.
    return (
      <div className="SettingsSider">
        <NavLink
          to={`/${this.props.locale}/d`}
          className="AdminSider__GroupItem AdminSider__GroupTitle ant-btn ant-btn-lg"
        >
        <FontAwesomeIcon icon={["fal", "long-arrow-left"]} fixedWidth /> <span>{t('Settings')}</span>
        </NavLink>

        <NavLink
          to={`/${this.props.locale}/d/settings/general`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
        <FontAwesomeIcon icon={["fal", "desktop"]} fixedWidth /> {t('General')}
        </NavLink>
        <NavLink
          to={`/${this.props.locale}/d/settings/campaigns`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
        <FontAwesomeIcon icon={["fal", "flag"]} fixedWidth /> {t('Campaigns')}
        </NavLink>
        {/*
        <NavLink
          to={`/${this.props.locale}/d/settings/transactions`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
        <FontAwesomeIcon icon={["fal", "usd-circle"]} fixedWidth /> {t('Transactions')}
        </NavLink>
        <NavLink
          to={`/${this.props.locale}/d/settings/custom-emails`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
        <FontAwesomeIcon icon={["fal", "at"]} fixedWidth /> {t('Custom Emails')}
        </NavLink>
        */}
        <NavLink
          to={`/${this.props.locale}/d/settings/design`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
        <FontAwesomeIcon icon={["far", "sliders-h"]} fixedWidth /> {t('Design')}
        </NavLink>
      </div>
    );
  }
}

export default translate('AdminSidebarSettings')(AdminSidebarSettings);

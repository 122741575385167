import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';

import TransactionsInvoiceList_en from './locales/TransactionsInvoiceList_en.json';
import TransactionsInvoiceList_fr from './locales/TransactionsInvoiceList_fr.json';

// import './TransactionsInvoiceList.less';

class TransactionsInvoiceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle('en', 'TransactionsInvoiceList', TransactionsInvoiceList_en);
    i18next.addResourceBundle('fr', 'TransactionsInvoiceList', TransactionsInvoiceList_fr);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchTransactions(
      this.props.platform.id,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  render() {
    const { props, state } = this,
      { t, locale, transaction, invoices, pagination } = props,
      { pager } = state;

    return (
      <>
        <PaginationTop
          totalLabel={total => t('invoicesTotal', { count: pagination.totalResults })}
          pager={pager}
          onChange={this.handlePaginationChange}
        />
        <ResultTable
          dataSource={invoices || []}
          pagination={pager}
          onChange={this.handlePaginationChange}
          showHeader={true}
          scroll={{ x: true }}
          columns={[
            simpleColumn(t('Transaction ID'), 'referenceId', null, true),
            simpleColumn(t('Donor name'), null, () => get(transaction, 'information.organization.name') || get(transaction, 'information.firstName') && get(transaction, 'information.lastName') ? transaction.information.firstName + " " + transaction.information.lastName : '', false),
            simpleColumn(t('Email address'), null, () => transaction.information.email, false),
            simpleColumn(t('Recipient'), 'scope.name', null, true),
            simpleColumn(t('Contribution amount'), 'amount', amount => moneyFormat(amount, locale), true),
            simpleColumn(t('Status'), 'status', status => t(status), true),
            dateColumn(t('Created on'), 'addedOn', null, null, false, locale),
          ]}
        />
      </>
    );
  }
}

TransactionsInvoiceList.defaultProps = {

};

TransactionsInvoiceList.propTypes = {
  locale: PropTypes.string.isRequired
};

export default translate('TransactionsInvoiceList')(TransactionsInvoiceList);

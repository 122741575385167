import React from 'react';
import PropTypes from 'prop-types';

class CssVariableApplicator extends React.Component {
  constructor(props) {
    super(props);

    this.colors = props.variables;
    this.colors["height"] = "100%";

    this.getLigthenDarken();
    this.setPrimaryColorElements();
    this.setSecondaryColorElements();
  }

  getLigthenDarken = () => {
    //Primary
    this.colors["--primary-color-lighten"] = this.shadeBlendConvert(.1, this.colors["--primary-color"]);
    this.colors["--primary-color-darken"] = this.shadeBlendConvert(-.1, this.colors["--primary-color"]);

    //Secondary
    this.colors["--secondary-color-lighten"] = this.shadeBlendConvert(.1, this.colors["--secondary-color"]);
    this.colors["--secondary-color-darken"] = this.shadeBlendConvert(-.1, this.colors["--secondary-color"]);
  }

  setPrimaryColorElements = () => {
    this.colors["--primary-shadow-color"] = this.getShadow(this.colors["--primary-color"]);

    //Darken
    this.colors["--primary-hover-color"] = this.colors["--primary-color-darken"];

    //Lighten
  }

  setSecondaryColorElements = () => {
    this.colors["--secondary-shadow-color"] = this.getShadow(this.colors["--secondary-color"]);

    this.colors["--default-color"] = this.colors["--secondary-color"];
    this.colors["--link-color"] = this.colors["--secondary-color"];
    this.colors["--input-hover-border-color"] = this.colors["--secondary-color"];
    this.colors["--input-active-border-color"] = this.colors["--secondary-color"];
    this.colors["--tabs-ink-bar-bg-color"] = this.colors["--secondary-color"];
    this.colors["--tab-hover-color"] = this.colors["--secondary-color"];

    //Darken
    this.colors["--default-hover-color"] = this.colors["--secondary-color-darken"];
    this.colors["--info-color"] = this.colors["--secondary-color-darken"];
    this.colors["--link-hover-color"] = this.colors["--secondary-color-darken"];

    //Lighten
  }

  shadeBlendConvert = (p, from, to) => {
    if(typeof(p)!="number"||p<-1||p>1||typeof(from)!="string"||(from[0]!='r'&&from[0]!='#')||(to&&typeof(to)!="string"))return null; //ErrorCheck
    if(!this.sbcRip)this.sbcRip=(d) => {
      let l=d.length,RGB={};
      if(l>9){
        d=d.split(",");
        if(d.length<3||d.length>4)return null;//ErrorCheck
        RGB[0]=i(d[0].split("(")[1]),RGB[1]=i(d[1]),RGB[2]=i(d[2]),RGB[3]=d[3]?parseFloat(d[3]):-1;
      }else{
        if(l==8||l==6||l<4)return null; //ErrorCheck
        if(l<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(l>4?d[4]+""+d[4]:""); //3 or 4 digit
        d=i(d.slice(1),16),RGB[0]=d>>16&255,RGB[1]=d>>8&255,RGB[2]=d&255,RGB[3]=-1;
      if (l==9||l==5)RGB[3]=r((RGB[2]/255)*10000)/10000,RGB[2]=RGB[1],RGB[1]=RGB[0],RGB[0]=d>>24&255;
      }
      return RGB;}
    var i=parseInt,r=Math.round,h=from.length>9,h=typeof(to)=="string"?to.length>9?true:to=="c"?!h:false:h,b=p<0,p=b?p*-1:p,to=to&&to!="c"?to:b?"#000000":"#FFFFFF",f=this.sbcRip(from),t=this.sbcRip(to);
    if(!f||!t)return null; //ErrorCheck
    if(h)return "rgb"+(f[3]>-1||t[3]>-1?"a(":"(")+r((t[0]-f[0])*p+f[0])+","+r((t[1]-f[1])*p+f[1])+","+r((t[2]-f[2])*p+f[2])+(f[3]<0&&t[3]<0?")":","+(f[3]>-1&&t[3]>-1?r(((t[3]-f[3])*p+f[3])*10000)/10000:t[3]<0?f[3]:t[3])+")");
    else return "#"+(0x100000000+r((t[0]-f[0])*p+f[0])*0x1000000+r((t[1]-f[1])*p+f[1])*0x10000+r((t[2]-f[2])*p+f[2])*0x100+(f[3]>-1&&t[3]>-1?r(((t[3]-f[3])*p+f[3])*255):t[3]>-1?r(t[3]*255):f[3]>-1?r(f[3]*255):255)).toString(16).slice(1,f[3]>-1||t[3]>-1?undefined:-2);
  }

  getShadow = h => {return `rgba(${this.hexToR(h)},${this.hexToG(h)},${this.hexToB(h)},.2)`}
  hexToR = h => {return parseInt((this.cutHex(h)).substring(0,2),16)}
  hexToG = h => {return parseInt((this.cutHex(h)).substring(2,4),16)}
  hexToB = h => {return parseInt((this.cutHex(h)).substring(4,6),16)}
  cutHex = h => {return (h.charAt(0)=="#") ? h.substring(1,7):h}

  /*componentDidUpdate(prevProps) {
    if (this.props.variables !== prevProps.variables) {
      this.colors = props.variables;
      this.getLigthenDarken();
      this.updateCssVariables(this.colors);
    }
  }

  updateCssVariables(variables) {
    for(let prop in variables){
      document.documentElement.style.setProperty(prop, variables[prop]);
    };
  }*/

  render() {
    return (
      <div
        className="CssVariableApplicator"
        style={
          this.colors
        }
      >
        {this.props.children}
      </div>
    );
  }
}

CssVariableApplicator.defaultProps = {
  variables: {}
};

CssVariableApplicator.propTypes = {
  variables: PropTypes.object.isRequired
};

export default CssVariableApplicator;

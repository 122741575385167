import {
  bindActionCreators,
  compose
} from "redux";
import {
  Form
} from 'antd';
import {
  connect
} from "react-redux";
import dataFetcher from 'Parts/chore/dataFetcher';

import {
  updateParticipant
} from "../../../../participant/participantActions";
import {
  fetchCampaign,
  clearCampaign
} from '../../../../campaign/campaignActions';
import {
  clearSearch,
  searchTeamsInCampaign
} from '../../../../search/searchActions';
import {
  checkAuthorization
} from 'Parts/common/authorizationUtils';

import CampaignsParticipantsPageForm from './CampaignsParticipantsPageForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  platformAlias: state.platform.platform.alias,
  languages: state.platform.languages,
  participant: state.participant.participant,
  locale: state.session.language,
  accentColors: state.platform.platform.settings.color,
  campaign: state.campaign.campaign,
  teams: state.search.teams,
  userRoleId: state.session.user.roleId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateParticipant,
  fetchCampaign,
  searchTeamsInCampaign,
  clearCampaign,
  clearSearch,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create(),
  dataFetcher
)(CampaignsParticipantsPageForm);

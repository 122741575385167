import React from 'react';
import { Modal, Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import FundkyTooltip from 'Parts/ui/components/FundkyTooltip';
import UploadCropField from 'Parts/ui/components/fields/UploadCropField';

import sliderCreateFormEN from './locales/SliderCreateForm_en.json';
import sliderCreateFormFR from './locales/SliderCreateForm_fr.json';

const FormItem = Form.Item;

class SliderCreateForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'SliderCreateForm', sliderCreateFormEN);
    i18next.addResourceBundle('fr', 'SliderCreateForm', sliderCreateFormFR);

    this.newUploadCropField = React.createRef();
    this.updateUploadCropField = React.createRef();
  }

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.value;
  };

  handleCropComplete = image => {
    this.setState({
      data: image
    });
    const slide = this.props.form.getFieldsValue(['slide_image']);
    slide.slide_image.data = image;
    this.props.form.setFieldsValue({ slide_image: slide.slide_image });
  };

  render() {
    const { t, visible, onCancel, onCreate, onUpdate, form, modalFormData } = this.props;
    const { getFieldDecorator } = form;

    // PROPS IF ADDING A SLIDE
    let modalProps = {
      visible: visible,
      title: t('Add a slide'),
      okText: t('Add'),
      cancelText: t('Cancel'),
      destroyOnClose: true,
      onCancel: onCancel,
      onOk: onCreate
    };

    // FORM IF ADDING A SLIDE
    let formHtml = (
      <Form className="form-add-slide" layout="vertical">
        <UploadCropField
          ref={this.newUploadCropField}
          form={form}
          fieldId="slide_image"
          label={t('Slide image')}
          required={true}
          icon={['fal', 'image']}
          uploadTitle={t('new-slide')}
          aspectRatio={1920 / 500}
          minWidth={1920}
          recommendedWidth={1920}
          required={true}
          requiredMessage={t('image-required')}
          handleCropConfirm={this.handleCropComplete}
        />
        <FormItem
          label={
            <span>
              {t('Alt Text')}
              <FundkyTooltip title={t('altTooltip')} />
            </span>
          }
        >
          {getFieldDecorator('alt', {
            rules: [
              {
                required: true,
                message: t('Please add an alt text for the image')
              },
              {
                pattern: /^.{0,255}$/,
                message: t('too long')
              }
            ]
          })(<Input />)}
        </FormItem>
        <FormItem label={t('Image Link')}>
          {getFieldDecorator('link', {
            rules: [
              {
                pattern: /^(https?):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
                message: t('invalid')
              }
            ]
          })(<Input />)}
        </FormItem>
      </Form>
    );

    // IF UPDATING A SLIDE
    if (modalFormData !== null) {
      formHtml = (
        <Form className="form-add-slide" layout="vertical">
          <UploadCropField
            ref={this.updateUploadCropField}
            form={form}
            fieldId="slide_image"
            initialValue={modalFormData.image.url}
            label={t('Slide image')}
            required={true}
            icon={['fal', 'image']}
            uploadTitle={t('new-slide')}
            aspectRatio={1920 / 500}
            minWidth={1920}
            recommendedWidth={1920}
            required={true}
            requiredMessage={t('image-required')}
            handleCropConfirm={this.handleCropComplete}
          />
          <FormItem
            label={
              <span>
                {t('Alt Text')}
                <FundkyTooltip title={t('altTooltip')} />
              </span>
            }
          >
            {getFieldDecorator('alt', {
              initialValue: modalFormData.alt,
              rules: [
                {
                  required: true,
                  message: t('Please add an alt text for the image')
                },
                {
                  pattern: /^.{0,255}$/,
                  message: t('too long')
                }
              ]
            })(<Input />)}
          </FormItem>
          <FormItem label={t('Image Link')}>
            {getFieldDecorator('link', {
              initialValue: modalFormData.link,
              rules: [
                {
                  pattern: /^(https?):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
                  message: t('invalid')
                }
              ]
            })(<Input />)}
          </FormItem>
        </Form>
      );

      modalProps = {
        visible: visible,
        title: t('Edit current slide'),
        okText: t('Update'),
        cancelText: t('Cancel'),
        destroyOnClose: true,
        onCancel: onCancel,
        onOk: onUpdate
      };
    }

    return <Modal {...modalProps}>{formHtml}</Modal>;
  }
}

export default translate('SliderCreateForm')(SliderCreateForm);

import React from 'react';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { twitchLink } from 'Parts/session/sessionActions'

class TwitchLinkRedirect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        token: props.match.params.token || null
    };
    this.state.login = props.locale === 'fr'
        ? '/fr/connexion'
        : '/en/login';
  }

  componentDidMount() {

    if (this.state.token) {
        this.state.link = this.props.twitchLink(this.state.token, this.props.organizationId, this.props.platformId)
            .then((res) => {
                if (res.type && res.type == 'AUTH_TWITCH_LINK_ERROR') {
                    this.props.history.replace(this.state.login + "?error=" + (res.payload && res.payload.code || res.error && res.error.code || res.type));
                } else {
                    this.props.history.replace('/' + this.props.locale);
                }
            })
            .catch((error) => {
                console.error(error);
                this.props.history.replace('/' + this.props.locale);
            });
    } else {
        this.props.history.replace('/' + this.props.locale);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
    platformId: state.platform.platform.id,
    organizationId: state.organization.organization.id,
    locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
    twitchLink
}, dispatch);


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(TwitchLinkRedirect);


import { notification } from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import { isEmpty, get } from 'lodash';
import { moneyToNumber } from 'Parts/common/moneyFormat';

import {
  requestApiGet,
  requestApiPost,
  requestApiPut,
  requestApiDelete
} from 'Parts/common/requestUtils';
import {
  CAMPAIGN_TEMPLATE_TEAMS,
  CAMPAIGN_TEMPLATE_MIXED
} from './campaignUtils';
import { getCdnUrl } from 'Parts/common/environment';
import { getLanguageIdByKey } from 'Parts/common/langUtils';
import { getDateValue } from 'Parts/common/dateUtils';
import { getSessionIp } from 'Parts/session/sessionUtils';

export const FETCH_CAMPAIGN_LIST_ONGOING_START = 'FETCH_CAMPAIGN_LIST_ONGOING_START';
export const FETCH_CAMPAIGN_LIST_ONGOING_SUCCESS = 'FETCH_CAMPAIGN_LIST_ONGOING_SUCCESS';
export const FETCH_CAMPAIGN_LIST_ONGOING_ERROR = 'FETCH_CAMPAIGN_LIST_ONGOING_ERROR';

export const FETCH_CAMPAIGN_START = 'FETCH_CAMPAIGN_START';
export const FETCH_CAMPAIGN_SUCCESS = 'FETCH_CAMPAIGN_SUCCESS';
export const FETCH_CAMPAIGN_ERROR = 'FETCH_CAMPAIGN_ERROR';

export const FETCH_CAMPAIGN_DESCRIPTION_START = 'FETCH_CAMPAIGN_DESCRIPTION_START';
export const FETCH_CAMPAIGN_DESCRIPTION_SUCCESS = 'FETCH_CAMPAIGN_DESCRIPTION_SUCCESS';
export const FETCH_CAMPAIGN_DESCRIPTION_ERROR = 'FETCH_CAMPAIGN_DESCRIPTION_ERROR';

export const FETCH_CAMPAIGN_MEDIA_START = 'FETCH_CAMPAIGN_MEDIA_START';
export const FETCH_CAMPAIGN_MEDIA_SUCCESS = 'FETCH_CAMPAIGN_MEDIA_SUCCESS';
export const FETCH_CAMPAIGN_MEDIA_ERROR = 'FETCH_CAMPAIGN_MEDIA_ERROR';

export const FETCH_CAMPAIGN_SETTINGS_START = 'FETCH_CAMPAIGN_SETTINGS_START';
export const FETCH_CAMPAIGN_SETTINGS_SUCCESS = 'FETCH_CAMPAIGN_SETTINGS_SUCCESS';
export const FETCH_CAMPAIGN_SETTINGS_ERROR = 'FETCH_CAMPAIGN_SETTINGS_ERROR';

export const FETCH_CAMPAIGN_LOGS_START = 'FETCH_CAMPAIGN_LOGS_START';
export const FETCH_CAMPAIGN_LOGS_SUCCESS = 'FETCH_CAMPAIGN_LOGS_SUCCESS';
export const FETCH_CAMPAIGN_LOGS_ERROR = 'FETCH_CAMPAIGN_LOGS_ERROR';

export const SAVE_CAMPAIGN_CREATE_START = 'SAVE_CAMPAIGN_CREATE_START';
export const SAVE_CAMPAIGN_CREATE_SUCCESS = 'SAVE_CAMPAIGN_CREATE_SUCCESS';
export const SAVE_CAMPAIGN_CREATE_ERROR = 'SAVE_CAMPAIGN_CREATE_ERROR';

export const UPDATE_CAMPAIGN_START = 'UPDATE_CAMPAIGN_START';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_ERROR = 'UPDATE_CAMPAIGN_ERROR';

export const PUBLISH_CAMPAIGN_START = 'PUBLISH_CAMPAIGN_START';
export const PUBLISH_CAMPAIGN_SUCCESS = 'PUBLISH_CAMPAIGN_SUCCESS';
export const PUBLISH_CAMPAIGN_ERROR = 'PUBLISH_CAMPAIGN_ERROR';

export const UI_STORE_CAMPAIGN_INFORMATION = 'UI_STORE_CAMPAIGN_INFORMATION';

export const CLEAR_CAMPAIGN = 'CLEAR_CAMPAIGN';

export const FETCH_CAMPAIGN_NOTE_START = 'FETCH_CAMPAIGN_NOTE_START';
export const FETCH_CAMPAIGN_NOTE_SUCCESS = 'FETCH_CAMPAIGN_NOTE_SUCCESS';
export const FETCH_CAMPAIGN_NOTE_ERROR = 'FETCH_CAMPAIGN_NOTE_ERROR';

export const UPDATE_CAMPAIGN_NOTE_START = 'UPDATE_CAMPAIGN_NOTE_START';
export const UPDATE_CAMPAIGN_NOTE_SUCCESS = 'UPDATE_CAMPAIGN_NOTE_SUCCESS';
export const UPDATE_CAMPAIGN_NOTE_ERROR = 'UPDATE_CAMPAIGN_NOTE_ERROR';

export const DELETE_CAMPAIGN_NOTE_START = 'DELETE_CAMPAIGN_NOTE_START';
export const DELETE_CAMPAIGN_NOTE_SUCCESS = 'DELETE_CAMPAIGN_NOTE_SUCCESS';
export const DELETE_CAMPAIGN_NOTE_ERROR = 'DELETE_CAMPAIGN_NOTE_ERROR';

const cdnUrl = getCdnUrl();

export function fetchCampaignList(platformId, authentication = false) {
  return dispatch => {
    dispatch({
      type: FETCH_CAMPAIGN_LIST_ONGOING_START
    });

    const params = {
      orderBy: 'addedOn[desc]',
      showChildrenCount: true,
      pageResults: 99999
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/campaigns`,
      params,
      headers
    )
      .then(result => {
        // TODO the cast to number in the API (also in search)
        // TODO Remove the avatar someday
        const payload = result.results.map(campaign => ({
          ...campaign,
          goal: Number(get(campaign, 'settings.goal', 0).toString().replace('NaN', 0)),
          collected: Number(get(campaign, 'collected', 0).toString().replace('NaN', 0)),
          avatar: `${cdnUrl}${get(campaign, 'banner.banner', '')}`
        }));

        return dispatch({
          type: FETCH_CAMPAIGN_LIST_ONGOING_SUCCESS,
          payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_CAMPAIGN_LIST_ONGOING_ERROR,
          error
        });
      });
  };
}

export function fetchCampaign(idOrAlias, authentication = false, platformId = null, params = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_CAMPAIGN_START
    });

    // PARAMS
    // showDescription = true
    // showMedia = true
    // showSettings = true
    // showChildrenCount = true

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${idOrAlias}${platformId ? `/${platformId}` : ''}`,
      params,
      headers
    )
      .then(campaign => {
        // TODO the cast to Number in the API
        const payload = {
          ...campaign,
          collected: Number(get(campaign, 'collected', 0).toString().replace('NaN', 0)),
          ...(get(params, 'showSettings') ?
            {
              goal: Number(get(campaign, 'settings.goal', 0).toString().replace('NaN', 0))
            } :
            {}),
          ...(get(params, 'showSettings') ?
            {
              settings: {
                ...campaign.settings,
                goal: Number(get(campaign, 'settings.goal', 0).toString().replace('NaN', 0))
              }
            } :
            {})
        };

        return dispatch({
          type: FETCH_CAMPAIGN_SUCCESS,
          payload: payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_CAMPAIGN_ERROR,
          error
        });
      });
  };
}

export function fetchCampaignDescription(id, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_CAMPAIGN_DESCRIPTION_START });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${id}/description`,
      {},
      headers
    )
      .then(description => {
        return dispatch({
          type: FETCH_CAMPAIGN_DESCRIPTION_SUCCESS,
          payload: description
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_CAMPAIGN_DESCRIPTION_ERROR,
          error
        });
      });
  };
}

export function fetchCampaignMedia(id, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_CAMPAIGN_MEDIA_START });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${id}/media`,
      {},
      headers
    )
      .then(media => {
        return dispatch({
          type: FETCH_CAMPAIGN_MEDIA_SUCCESS,
          payload: media
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_CAMPAIGN_MEDIA_ERROR,
          error
        });
      });
  };
}

export function fetchCampaignSettings(id, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_CAMPAIGN_SETTINGS_START });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${id}/settings`,
      {},
      headers
    )
      .then(settings => {
        const payload = {
          ...settings,
          goal: Number(get(settings, 'goal', 0))
        };

        return dispatch({
          type: FETCH_CAMPAIGN_SETTINGS_SUCCESS,
          payload: payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_CAMPAIGN_SETTINGS_ERROR,
          error
        });
      });
  };
}

export function fetchCampaignLogs(id) {
  return dispatch => {
    dispatch({ type: FETCH_CAMPAIGN_LOGS_START });

    const headers = {
      authentication: 'true',
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${id}/logs`,
      { pageResults: 100 },
      headers
    )
      .then(logs => {
        return dispatch({
          type: FETCH_CAMPAIGN_LOGS_SUCCESS,
          payload: logs
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_CAMPAIGN_LOGS_ERROR,
          error
        });
      });
  };
}

export function storeCampaignInformation(formValues) {
  return {
    type: UI_STORE_CAMPAIGN_INFORMATION,
    payload: formValues
  };
}

export function createCampaign(formValues, platformId) {
  return dispatch => {
    dispatch({
      type: SAVE_CAMPAIGN_CREATE_START
    });

    const isEvent = formValues.campaign_behavior ? true : false;
    const isDraft = formValues.draft ? true : false;

    const values = {
      draft: isDraft,
      name: formValues.name,
      typeId: formValues.template === CAMPAIGN_TEMPLATE_TEAMS && formValues
        .individualParticipant ?
        CAMPAIGN_TEMPLATE_MIXED :
        formValues.template,
      descriptions: formValues.descriptions ? [...formValues.descriptions] : undefined,
      banners: formValues.banners ? [...formValues.banners] : undefined,
      settings: formValues.goal ? {
        goal: moneyToNumber(formValues.goal.replace(/\s/g, ''))
      } : undefined,
      platformId: platformId,
      behaviorId: isEvent ? 2 : 1
      // plugins: {
      //   event: formValues.campaign_behavior ? true : false
      // }
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      '/campaigns',
      values,
      headers
    )
      .then(campaign => Promise.all([
        campaign,
        ...(isEvent
          ? [requestApiPut(
                '/campaigns/' + campaign.id + '/event',
                {
                  "startOn": null,
                  "endOn": null
                },
                headers
              )
                .then(event => {
                  if (!isDraft) {
                    return dispatch(publishCampaign(campaign.id));
                  }
                  return event;
                })
            ]
          : [])
      ]))
      .then(campaign => {
        return dispatch({
          type: SAVE_CAMPAIGN_CREATE_SUCCESS,
          payload: campaign[0]
        });
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:creation-failed'),
          description: i18next.t('API_NOTIFICATIONS:campaign-creation-failed')
        });

        return dispatch({
          type: SAVE_CAMPAIGN_CREATE_ERROR,
          error: error
        });
      });
  };
}

export function updateCampaign(formValues, campaignId, locale = 'en') {
  return dispatch => {
    dispatch({
      type: UPDATE_CAMPAIGN_START
    });

    const languageId = getLanguageIdByKey(locale);

    const values = {
      name: formValues.campaign_name || undefined,
      alias: formValues.campaign_url || undefined,
      visibility: formValues.visibility >= 0
        ? formValues.visibility
          ? true
          : false
        : undefined,
      statusId: formValues.statusId || undefined,
      rejectReason: formValues.rejectReason || undefined
    };

    const description = formValues.campaign_description ?
      [{
        description: formValues.campaign_description === '<p><br></p>' ?
          null :
          formValues.campaign_description,
        languageId: languageId
      }] :
      undefined;

    const banners = formValues.file ?
      {
        banners: [{
          banner: formValues.file,
          languageId: languageId
        }]
      } :
      undefined;

    const settings = {
      // GOAL
      goal: formValues.campaign_goal && moneyToNumber(formValues.campaign_goal) || undefined,
      // PARTICIPANTS
      ...(formValues.participants_limit !== undefined ||
        formValues.participants_preauth !== undefined ||
        formValues.participants_goal !== undefined ||
        formValues.hasOwnProperty('chk_participants_change_goal') ?
        {
          participant: {
            limit: formValues.participants_limit && formValues.participants_limit !== null ?
              formValues.participants_limit :
              formValues.participants_limit !== undefined ?
              0 :
              undefined,
            creation: {
              preauth: formValues.participants_preauth !== undefined ?
                formValues.participants_preauth :
                undefined
            },
            goal: formValues.campaign_goal && moneyToNumber(formValues.campaign_goal) || undefined,
            custom: formValues.hasOwnProperty('chk_participants_change_goal')
              ? {
                  goal: formValues.hasOwnProperty('chk_participants_change_goal')
                    ? formValues.chk_participants_change_goal
                      ? formValues.chk_participants_change_goal
                      : false
                    : undefined
                }
              : undefined
          },
          participants_goal: formValues.participants_goal && moneyToNumber(formValues.participants_goal) || undefined,
        } :
        {}),
      // TEAMS
      ...(formValues.teams_limit !== undefined ?
        {
          team: {
            limit: formValues.teams_limit && formValues.teams_limit !== null ?
              formValues.teams_limit :
              formValues.teams_limit !== undefined ?
              0 :
              undefined
          }
        } :
        {}),
      // REGISTRATION
      ...(formValues.disable_registration !== undefined ?
        {
          registration: {
            disabledOn: getDateValue(formValues.disable_registration)
          }
        } :
        {}),
      // DONATION
      ...(formValues.suggested_amount_0 ||
        formValues.suggested_amount_1 ||
        formValues.suggested_amount_2 ||
        formValues.suggested_amount_3 ||
        formValues.hasOwnProperty('chk_donors_custom_amount') ||
        formValues.disable_donations === null ||
        formValues.disable_donations ?
        {
          donation: {
            ...(formValues.suggested_amount_0 ||
              formValues.suggested_amount_1 ||
              formValues.suggested_amount_2 ||
              formValues.suggested_amount_3 ||
              formValues.hasOwnProperty('chk_donors_custom_amount') ?
              {
                preset: {
                  A: formValues.suggested_amount_0 || undefined,
                  B: formValues.suggested_amount_1 || undefined,
                  C: formValues.suggested_amount_2 || undefined,
                  D: formValues.suggested_amount_3 || undefined,
                  custom: formValues.hasOwnProperty('chk_donors_custom_amount') ?
                    formValues.chk_donors_custom_amount ?
                    formValues.chk_donors_custom_amount :
                    false :
                    undefined
                }
              } :
              {}),
            disabledOn: formValues.disable_donations === null ?
              null :
              formValues.disable_donations ?
              getDateValue(formValues.disable_donations) :
              undefined
          }
        } :
        {}),
      // CLOSUREON
      closureOn:
        formValues.close_campaign === null
          ? null
          : formValues.close_campaign
          	? getDateValue(formValues.close_campaign)
          	: undefined,
      // THERMOMETER
      ...(formValues.show_thermometer ||
        formValues.multiplier
          ? {
              thermometer: {
                behavior: formValues.show_thermometer || undefined,
                multiplier: formValues.multiplier || undefined,
              }
            }
          : {}
      )
    };

    let key;

    for (key in values) {
      if (values[key] === undefined) delete values[key];
    }

    for (key in description) {
      if (description[key] === undefined) delete description[key];
    }

    for (key in banners) {
      if (banners[key] === undefined) delete banners[key];
    }

    for (key in settings) {
      if (settings[key] === undefined) delete settings[key];
    }

    if (
      !isEmpty(values) ||
      !isEmpty(description) ||
      !isEmpty(settings) ||
      !isEmpty(banners) ||
      formValues.memberId
    ) {
      const headers = {
        ...dispatch(getSessionIp())
      };

      return Promise.all([
        ...(!isEmpty(values)
          ? [requestApiPut(
              `/campaigns/${campaignId}`,
              values,
              headers
            )]
          : []
        ),
        ...(get(description, '[0].description')
          ? [requestApiPut(
              `/campaigns/${campaignId}/description`,
              description,
              headers
            )]
          : []
        ),
        ...(formValues.campaign_description === '<p><br></p>' &&
          get(description, '[0].description') === null
            ? [requestApiDelete(
                `/campaigns/${campaignId}/description`,
                { languageId: languageId },
                headers
              )]
            : []
        ),
        ...(!isEmpty(banners)
          ? [requestApiPut(
              `/campaigns/${campaignId}/media`,
              banners,
              headers
            )]
          : []
        ),
        ...(formValues.file === null
          ? [requestApiDelete(
              `/campaigns/${campaignId}/media`,
              { banners: { languageId: languageId } },
              headers
            )]
          : []
        ),
        ...(!isEmpty(settings) && !settings.goal
          // GOAL is required for the API call, so fetch the actual goal when goal don't need to be modified.
          ? [dispatch(fetchCampaignSettings(campaignId, true))
              .then(actualSettings => {
                settings.goal = actualSettings.payload.goal;
                return [requestApiPut(
                  `/campaigns/${campaignId}/settings`,
                  settings,
                  headers
                )];
              }
            )]
          : !isEmpty(settings)
            ? [requestApiPut(
                `/campaigns/${campaignId}/settings`,
                settings,
                headers
              )]
            : []
        ),
        ...(formValues.memberId
          ? [requestApiPost(
              `/campaigns/${campaignId}/invitations`,
              { memberId: formValues.memberId },
              headers
            )]
          : []
        )
      ])
        .then(updated => { console.log(updated);
          return formValues.publish ? dispatch(publishCampaign(campaignId)) : updated;
        })
        .then(updated => {
          return Promise.all([
            dispatch(fetchCampaign(campaignId, true)),
            dispatch(fetchCampaignDescription(campaignId, true)),
            dispatch(fetchCampaignMedia(campaignId, true)),
            dispatch(fetchCampaignSettings(campaignId, true))
          ]);
        })
        .then(fetched => {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved')
          });

          return dispatch({
            type: UPDATE_CAMPAIGN_SUCCESS
          });
        })
        .catch(error => {
          const description = error.description || error;

          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: description
          });

          return dispatch({
            type: UPDATE_CAMPAIGN_ERROR,
            error: error
          });
        });
    } else if (formValues.publish) {
      return dispatch(publishCampaign(campaignId))
        .then(response =>
          Promise.all([
            dispatch(fetchCampaign(campaignId, true)),
            dispatch(fetchCampaignDescription(campaignId, true)),
            dispatch(fetchCampaignMedia(campaignId, true)),
            dispatch(fetchCampaignSettings(campaignId, true))
          ])
        )
        .then(fetched => {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved')
          });
          return dispatch({
            type: UPDATE_CAMPAIGN_SUCCESS
          });
        });
    } else {
      return dispatch({
        type: UPDATE_CAMPAIGN_ERROR,
        error: 'Nothing to update.'
      });
    }
  };
}

export function publishCampaign(campaignId) {
  return dispatch => {
    dispatch({
      type: PUBLISH_CAMPAIGN_START
    });

    return requestApiPut(`/campaigns/${campaignId}/publish`, {})
      .then(campaign => {
        return Promise.all([
          dispatch(fetchCampaign(campaignId, true)),
          dispatch(fetchCampaignDescription(campaignId, true)),
          dispatch(fetchCampaignMedia(campaignId, true)),
          dispatch(fetchCampaignSettings(campaignId, true))
        ]);
      })
      .then(fetched => {
        // notification.success({
        //   message: i18next.t('API_NOTIFICATIONS:success'),
        //   description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        // });

        return dispatch({
          type: PUBLISH_CAMPAIGN_SUCCESS
        });
      })
      .catch(error => {
        const description = error.description || error;

        notification.error({
          message: i18next.t('API_NOTIFICATIONS:creation-failed'),
          description: description
        });

        return dispatch({
          type: PUBLISH_CAMPAIGN_ERROR,
          error: error
        });
      });
  };
}

export function clearCampaign() {
  return dispatch => {
    dispatch({
      type: CLEAR_CAMPAIGN
    });
  };
}

export function fetchCampaignNote(campaignId, authentication = false) {
  return dispatch => {
    dispatch({
      type: FETCH_CAMPAIGN_NOTE_START
    });

    // Note Type ID
    // 1 = DONATION
    // 2 = TEAM
    // 3 = PARTICIPANT
    // 4 = EVENT_TICKET

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/notes`,
      null,
      headers
    )
      .then(notes => {
        return dispatch({
          type: FETCH_CAMPAIGN_NOTE_SUCCESS,
          payload: notes
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_CAMPAIGN_NOTE_ERROR,
          error
        });
      });
  };
}

export function updateCampaignNote(campaignId, note, languageId = 1, noteTypeId = 1) {
  return dispatch => {
    dispatch({ type: UPDATE_CAMPAIGN_NOTE_START });

    // Note Type ID
    // 1 = DONATION
    // 2 = TEAM
    // 3 = PARTICIPANT
    // 4 = EVENT_TICKET

    const values = {
      note,
      languageId: typeof(languageId) === 'string' ? getLanguageIdByKey(languageId) : languageId,
      noteTypeId,
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/campaigns/${campaignId}/notes`,
      values ? [{ ...values }] : [],
      headers
    )
      .then(note => {
        notification.success({ message: i18next.t('API_NOTIFICATIONS:success'), description: i18next.t('API_NOTIFICATIONS:modifications-saved') });
        return dispatch({ type: UPDATE_CAMPAIGN_NOTE_SUCCESS, payload: note })
      }).then(() => {
        return dispatch(fetchCampaignNote(campaignId, true));
      }).catch(error => {
        notification.error({ message: i18next.t('API_NOTIFICATIONS:modifications-failed'), description: error.description || error });
        return dispatch({type: UPDATE_CAMPAIGN_NOTE_ERROR, error: error});
      });
  }
}

export function deleteCampaignNote(campaignId, noteTypeId = 1, languageId = 1 ) {
  return dispatch => {
    dispatch({ type: DELETE_CAMPAIGN_NOTE_START });

    // Note Type ID
    // 1 = DONATION
    // 2 = TEAM
    // 3 = PARTICIPANT
    // 4 = EVENT_TICKET

    const values = {
      noteTypeId: noteTypeId,
      languageId: languageId
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiDelete(
      `/campaigns/${campaignId}/notes`,
      values ? values : {},
      headers
    )
      .then(note => {
        notification.success({ message: i18next.t('API_NOTIFICATIONS:success'), description: i18next.t('API_NOTIFICATIONS:modifications-saved') });
        return dispatch({ type: DELETE_CAMPAIGN_NOTE_SUCCESS })
      }).then(() => {
        return dispatch(fetchCampaignNote(campaignId, true));
      }).catch(error => {
        notification.error({ message: i18next.t('API_NOTIFICATIONS:modifications-failed'), description: error.description || error });
        return dispatch({type: DELETE_CAMPAIGN_NOTE_ERROR, error: error});
      });
  };
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import QuickLinks from './components/QuickLinksContainer';

import AdminSidebarTransactions_en from './locales/AdminSidebarTransactions_en.json';
import AdminSidebarTransactions_fr from './locales/AdminSidebarTransactions_fr.json';

class AdminSidebarTransactions extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AdminSidebarTransactions', AdminSidebarTransactions_en);
    i18next.addResourceBundle('fr', 'AdminSidebarTransactions', AdminSidebarTransactions_fr);
  }

  render() {
    const
      { props } = this,
      { t, locale, match } = props,
      d = `/${locale}/d`,
      transactionActive = match.params.transactionID && match.params.transactionID !== 'filter' ? true : false,
      addTransactionActive = !transactionActive && match.path.indexOf('/d/transactions/add') > -1 ? true : false;

    return (
      <div className="TransactionsSider">
        <NavLink
          to={d}
          className="AdminSider__GroupItem AdminSider__GroupTitle ant-btn ant-btn-lg"
        >
          <FontAwesomeIcon icon={["fal", "long-arrow-left"]} fixedWidth /> <span>{t('transactions')}</span>
        </NavLink>
        <NavLink
          to={`${d}/transactions/list`}
          className={`AdminSider__GroupItem ant-btn ant-btn-lg ${transactionActive || addTransactionActive ? 'AdminSider__GroupItem--active' : ''}`}
          activeClassName="AdminSider__GroupItem--active"
        >
          <FontAwesomeIcon icon={['fal', 'list-alt']} fixedWidth /> {t('all-transactions')}
        </NavLink>
        <NavLink
          to={`${d}/transactions/filter`}
          className={`AdminSider__GroupItem ant-btn ant-btn-lg`}
          activeClassName="AdminSider__GroupItem--active"
        >
          <FontAwesomeIcon icon={['far', 'search']} fixedWidth /> {t('search-transactions')}
        </NavLink>
        <NavLink
          to={`${d}/transactions/withdrawal-report`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
          <FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} fixedWidth /> {t('settlement-reports')}
        </NavLink>
        {/* <NavLink
          to={`${d}/transactions/reconciliation`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
          <FontAwesomeIcon icon={['fal', 'exchange']} fixedWidth /> {t('reconciliation')}
        </NavLink> */}

        {/* Shortcuts */}
        <QuickLinks locale={locale} />
      </div>
    );
  }
}

export default translate('AdminSidebarTransactions')(AdminSidebarTransactions);

import {
  compose,
  bindActionCreators
} from "redux";
import {
  connect
} from "react-redux";
import {
  withRouter
} from 'react-router';
import {
  Form
} from 'antd';

import {
  getAlgoliaToken,
  saveAlgoliaPlatformFilter,
  deleteAlgoliaPlatformFilter,
  getAlgoliaSavedPlatformFilters,
} from '../../algolia/algoliaActions';

import TransactionsFilterPage from './TransactionsFilterPage';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  platformAlias: state.platform.platform.alias,
  locale: state.session.language,
  algoliaToken: state.algolia.algoliaToken,
  algoliaFilters: state.algolia.algoliaFilters.results || [],
  isFetchingAlgoliaFilters: state.algolia.isSearchInProgress
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAlgoliaToken,
  saveAlgoliaPlatformFilter,
  deleteAlgoliaPlatformFilter,
  getAlgoliaSavedPlatformFilters
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  Form.create(),
)(TransactionsFilterPage);

import React from 'react';
import { translate } from 'react-i18next';
import { has, get } from 'lodash';
import i18next from 'i18next';
import { Button, Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SettingsColumn from '../../components/SettingsColumn';
import LanguageBoxForm from '../../components/LanguageBoxFormContainer';
import SlidesWallField from './fields/SlidesWallField';
import SliderCreateFormContainer from './SliderCreateFormContainer';
import SubmitButton from 'Parts/common/SubmitButton';

import { getCdnUrl } from 'Parts/common/environment';
import { getDefaultLanguageKey, getLanguageNameByKey } from 'Parts/common/langUtils';

import PagesHomepageSliderPanel_en from './locales/PagesHomepageSliderPanel_en.json';
import PagesHomepageSliderPanel_fr from './locales/PagesHomepageSliderPanel_fr.json';

// NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB
// NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB
// I REALLY NEED TO COMMENT THIS PAGE BECAUSE THIS COMPONENT IS PRETTY COMPLICATED
// I REALLY NEED TO COMMENT THIS PAGE BECAUSE THIS COMPONENT IS PRETTY COMPLICATED
// I REALLY NEED TO COMMENT THIS PAGE BECAUSE THIS COMPONENT IS PRETTY COMPLICATED
// NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB
// NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB NB

import './PagesHomepageSliderPanel.less';

const cdnUrl = getCdnUrl();

class PagesHomepageSliderPanel extends React.Component {
  constructor(props) {
    super(props);

    const banners = get(
      props,
      `platform.media.banners[${getDefaultLanguageKey(get(this.props, 'languages'))}`
    );
    this.slidesFromDb = [];
    let mappedSlides = [];

    if (banners || this.props.locale) {
      this.slidesFromDb = this.getSlidesFromDb(
        getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale
      );
      mappedSlides = this.slidesFromDb.map((slide, index) => ({
        uid: index,
        name: slide.name,
        status: 'done',
        url: slide.src
      }));
    }

    this.state = {
      visible: false,
      slides: this.slidesFromDb ? this.slidesFromDb.slice() : this.slidesFromDb,
      fileList: mappedSlides,
      modalFormData: null,
      contentLanguage: getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale,
      editingSlideIndex: null
    };

    this.changeHasHappened = false;

    i18next.addResourceBundle('en', 'PagesHomepageSliderPanel', PagesHomepageSliderPanel_en);
    i18next.addResourceBundle('fr', 'PagesHomepageSliderPanel', PagesHomepageSliderPanel_fr);
  }

  componentDidUpdate(prevProps) {
    const lang = getLanguageNameByKey(this.state.contentLang, false);
    if (has(prevProps.platform, `media.banners[${lang}]`)) {
      if (
        prevProps.platform.media.banners[lang].length !==
        this.props.platform.media.banners[lang].length
      ) {
        this.slidesFromDb = this.getSlidesFromDb(this.state.contentLanguage);

        const mappedSlides = this.slidesFromDb.map((slide, index) => ({
          uid: index,
          name: slide.name,
          status: 'done',
          url: slide.src
        }));

        this.setState({
          slides: this.slidesFromDb.slice(),
          fileList: mappedSlides
        });
      }
    }

    if (this.state.slides === null) {
      if (
        this.props.platform.media &&
        this.props.platform.media.banners &&
        this.props.platform.media.banners[lang]
      ) {
        this.slidesFromDb = this.getSlidesFromDb(this.state.contentLanguage);

        const mappedSlides = this.slidesFromDb.map((slide, index) => ({
          uid: index,
          name: slide.name,
          status: 'done',
          url: slide.src
        }));

        this.setState({
          slides: this.slidesFromDb.slice(),
          fileList: mappedSlides
        });
      }
    }
  }

  getSlidesFromDb = contentLang => {
    const lng = getLanguageNameByKey(contentLang, false);
    const banners = get(this.props, 'platform.media.banners', {});

    const slides = {
      fr: [],
      en: []
    };

    if (banners[lng]) {
      banners[lng].forEach((banner, index) => {
        const file = banner.banner.split('/')[3];
        //const name = banner.banner.split('/')[3].split('.png')[0].split('.jpg')[0].split('.gif')[0];

        slides[contentLang][index] = {
          bannerId: banner.bannerId,
          languageId: banner.languageId,
          weight: banner.weight || banners[lang].length - index,
          name: file,
          src: cdnUrl + banner.banner,
          alt: banner.alt,
          link: banner.link,
          status: 'saved'
        };
      });
    }

    return slides[contentLang];
  };

  // Updates order after deleting slide
  updateSlideOrder = slides => {
    let updatedSlides = slides.map((slide, index) => ({
      bannerId: slide.bannerId,
      languageId: slide.languageId,
      weight: slides.length - index,
      name: slide.name,
      src: slide.src,
      alt: slide.alt,
      link: slide.link,
      status: slide.order === index ? slide.status : 'updated'
    }));

    // Map the slides to work with upload fileList format
    let mappedSlides = updatedSlides.map((updatedSlide, index) => ({
      uid: index,
      name: updatedSlide.name || updatedSlide.file.name,
      status: 'done',
      url: updatedSlide.src || updatedSlide.data
    }));

    this.setState({
      slides: updatedSlides,
      fileList: mappedSlides
    });

    if (!this.changeHasHappened) {
      this.changeHasHappened = true;
    }
  };

  // Syncs slide state with fileList state
  syncFileListWithSlides = slides => {
    // Map the slides to work with upload fileList format
    let mappedSlides = slides.map((slide, index) => ({
      uid: index,
      name: slide.name || slide.file.name,
      status: 'done',
      url: slide.src || slide.data
    }));

    this.setState({
      fileList: mappedSlides
    });
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      modalFormData: null
    });
    const form = this.formRef.props.form;
    form.resetFields();
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    let slidesLength = (this.state.fileList && this.state.fileList.length) || 0;
    let newfileList = this.state.fileList;
    if (!this.changeHasHappened) {
      this.changeHasHappened = true;
    }

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.setState(
        {
          visible: false,
          slides: (this.state.slides &&
            this.state.slides.concat({
              languageId: this.state.contentLanguage == 'fr' ? 2 : 1,
              weight: 0,
              name: values.slide_image.file.name,
              src: values.slide_image.data,
              alt: values.alt,
              link: values.link,
              status: 'added'
            })) || [
            {
              languageId: this.state.contentLanguage == 'fr' ? 2 : 1,
              weight: 0,
              name: values.slide_image.file.name,
              src: values.slide_image.data,
              alt: values.alt,
              link: values.link,
              status: 'added'
            }
          ],
          fileList: (newfileList &&
            newfileList.concat({
              uid: slidesLength,
              name: values.slide_image.file.name,
              status: 'done',
              url: values.slide_image.data
            })) || [
            {
              uid: slidesLength,
              name: values.slide_image[0]['name'],
              status: 'done',
              url: values.slide_image.data
            }
          ],
          modalFormData: null
        },
        () => {
          this.updateSlideOrder(this.state.slides);
        }
      );

      form.resetFields();
    });
  };

  onUpdate = () => {
    const form = this.formRef.props.form;
    let slides = this.state.slides;

    if (this.changeHasHappened) {
      this.changeHasHappened = true;
    }

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      // This will depend on what we save from the slide images in the database
      if (values.slide_image.file) {
        slides[this.state.editingSlideIndex] = {
          bannerId: slides[this.state.editingSlideIndex].bannerId,
          languageId: this.state.contentLanguage == 'fr' ? 2 : 1,
          weight: slides[this.state.editingSlideIndex].weight,
          name: values.slide_image.file.name,
          src: values.slide_image.data,
          alt: values.alt,
          link: values.link,
          status: 'updated'
        };
      } else {
        slides[this.state.editingSlideIndex] = {
          bannerId: slides[this.state.editingSlideIndex].bannerId,
          languageId: this.state.contentLanguage == 'fr' ? 2 : 1,
          weight: slides[this.state.editingSlideIndex].weight,
          name: values.slide_image.name,
          src: values.slide_image.url,
          alt: values.alt,
          link: values.link,
          status: 'updated'
        };
      }

      this.setState({
        visible: false,
        slides: slides,
        modalFormData: null,
        editingSlideIndex: null
      });

      this.syncFileListWithSlides(slides);

      form.resetFields();
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  // This toggles the modal with the form that will be populated with the slide we clicked on
  handlePreview = file => {
    let slides = this.state.slides;
    let slide = slides[file.uid || (file.file && file.file.uid) || 0];

    this.setState({
      visible: true,
      editingSlideIndex: file.uid || (file.file && file.file.uid) || 0,
      modalFormData: {
        image: {
          uid: file.uid || file.uid === 0 ? file.uid.toString() : file.file.uid.toString(),
          name: slide['name'],
          status: 'done',
          url: file.url || file.data || ''
        },
        alt: slide['alt'],
        link: slide['link']
      }
    });
  };

  // When deleting slide from wall
  onRemove = file => {
    const fileList = this.state.fileList;
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    const newSlideList = this.state.slides;
    newSlideList.splice(index, 1);
    newFileList.splice(index, 1);

    const lang = getLanguageNameByKey(this.state.contentLanguage, false);
    const banners = get(this.props.platform, `media.banners.[${lang}]`, null);
    const deleteBanner = banners.filter(banner => cdnUrl + banner.banner === file.url);

    if (banners && deleteBanner.length) {
      this.props.deletePlatformMedia(
        this.props.platform.id,
        'banners',
        deleteBanner[0].languageId,
        deleteBanner[0].bannerId
      );
    }

    this.updateSlideOrder(newSlideList);
  };

  // Handle content language switcher change
  handleContentLanguageChange = value => {
    this.slidesFromDb = this.getSlidesFromDb(value);

    let mappedSlides = this.slidesFromDb.map((slide, index) => ({
      uid: index,
      name: slide.name,
      status: 'done',
      url: slide.src
    }));
    const defaultLangKey = getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale;
    this.setState({
      slides: this.slidesFromDb.slice(),
      fileList: mappedSlides,
      contentLanguage: value,
      disabledUnilangualFields: defaultLangKey != value
    });

    this.changeHasHappened = false;
  };

  handleCancelSlides = event => {
    event.preventDefault();
    this.slidesFromDb = this.getSlidesFromDb(this.state.contentLanguage || this.props.locale);
    const mappedSlides = this.slidesFromDb.map((slide, index) => ({
      uid: index,
      name: slide.name,
      status: 'done',
      url: slide.src
    }));
    this.setState({
      slides: this.slidesFromDb.slice(),
      fileList: mappedSlides
    });
  };

  submitSlides = event => {
    event.preventDefault();
    this.changeHasHappened = false;
    const updatedSlides =
      (this.state.slides && this.state.slides.filter(slide => slide.status != 'saved')) || null;
    const banners = [];

    // const logo = [{
    //   languageId: getLanguageIdByKey(this.state.contentLang),
    //   logo: platformLogo || undefined,
    //   link: platform_logo_url === '' ? null : platform_logo_url,
    //   logoId: get(this.props, `platformLogo.[${languageName}].logoId`, undefined),
    // }];

    if (updatedSlides) {
      updatedSlides.forEach(slide => {
        let image =
          slide.src && slide.src.indexOf('data:image/') > -1 ? { banner: slide.src } : null;
        let banner = {
          bannerId: slide.bannerId,
          languageId: slide.languageId || undefined,
          ...image,
          weight: slide.weight,
          alt: slide.alt || undefined,
          link: slide.link && slide.link !== "" && slide.link || null
        };
        banners.push(banner);
      });
    }

    if (updatedSlides && this.slidesFromDb) {
      this.slidesFromDb.forEach(slideFromDb => {
        let found = false;
        this.state.slides.forEach(slide => {
          if (slideFromDb.bannerId === slide.bannerId) {
            found = true;
          }
        });
        if (!found) {
          let banner = {
            bannerId: slideFromDb.bannerId,
            languageId: slideFromDb.languageId || undefined,
            banner: null
          };
          banners.push(banner);
        }
      });
    }

    if (banners.length > 0) {
      this.props
        .updatePlatformMedia(this.props.platform.id, 'banners', banners)
        .then(() => {
          const lang = getLanguageNameByKey(this.state.contentLanguage, false);
          this.slidesFromDb = this.props.platform.media.banners[lang]
            ? this.getSlidesFromDb(this.state.contentLanguage || props.locale)
            : null;

          const mappedSlides = this.props.platform.media.banners[lang]
            ? this.slidesFromDb.map((slide, index) => ({
                uid: index,
                name: slide.name,
                status: 'done',
                url: slide.src
              }))
            : null;

          this.setState({
            slides: this.slidesFromDb ? this.slidesFromDb.slice() : this.slidesFromDb,
            fileList: mappedSlides
          });
        })
        .catch(error => console.warn(error));
    }
  };

  render() {
    const {
        props,
        state,
        handleContentLanguageChange,
        handlePreview,
        onRemove,
        showModal,
        handleCancel,
        handleCreate,
        onUpdate,
        normFile,
        handleCancelSlides,
        submitSlides
      } = this,
      { t, locale } = props,
      { fileList, visible, modalFormData } = state;

    {
      /* Button to add slides */
    }
    const addSlideButton = (
      <div className="add-slide" onClick={showModal}>
        <FontAwesomeIcon icon={['fal', 'plus']} />
        <h3>{t('New slide')}</h3>
        <span>{t('minimal size')}</span>
        <span>{t('format')}</span>
      </div>
    );

    return (
      <div className="PagesHomepageSliderPanel AdminFormWrap">
        <Form>
          <h3 className="panel-title">{t('Slides')}</h3>
          {/* Displays added slides */}
          <SlidesWallField
            slides={fileList}
            handlePreview={handlePreview}
            onRemove={onRemove}
            action=""
            uploadButton={addSlideButton}
          />

          {/* Model that opens when .add-slide is clicked */}
          <SliderCreateFormContainer
            wrappedComponentRef={this.saveFormRef}
            visible={visible}
            onCancel={handleCancel}
            onCreate={handleCreate}
            onUpdate={onUpdate}
            modalFormData={modalFormData}
            normFileFn={normFile}
          />

          <div className="buttons">
            <Button type="danger" className="btn-cancel" onClick={handleCancelSlides}>
              {t('Cancel')}
            </Button>
            <SubmitButton
              className="SettingsForm__Submit"
              icon="save"
              iconStyle="fal"
              overwriteSubmit={submitSlides}
              loading={this.props.isUpdatePlatformMediaInProgress}
            >
              {t('Save')}
            </SubmitButton>
          </div>
        </Form>
        <SettingsColumn>
          <LanguageBoxForm
            handleContentLanguageChange={handleContentLanguageChange}
            changeHasHappened={this.changeHasHappened}
            defaultLanguage={
              getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale
            }
          />
        </SettingsColumn>
      </div>
    );
  }
}

export default translate('PagesHomepageSliderPanel')(PagesHomepageSliderPanel);

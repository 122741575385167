import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { Collapse } from 'react-collapse';
import { get, pickBy, isEqual, isEmpty } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';

import SettingsColumn from '../../../components/SettingsColumn';
import LanguageBoxForm from '../../../components/LanguageBoxFormContainer';
import AccessibilityBoxForm from './AccessibilityBoxFormContainer';
import CampaignNameField from 'Parts/ui/components/fields/NameField';
import TextField from 'Parts/ui/components/fields/TextField';
import TextAreaField from 'Parts/ui/components/fields/TextAreaField';
import MoneyField from 'Parts/ui/components/fields/MoneyField';
import Wysiwyg, { wrapText } from 'Parts/ui/components/fields/Wysiwyg';
import CancelAndSaveButtons from '../../../components/CancelAndSaveButtons';
import UploadCropField from 'Parts/ui/components/fields/UploadCropField';
import FakeField from 'Parts/ui/components/fields/FakeField';
import AvatarThumbnail from '../../../../common/AvatarThumbnailContainer';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import SelectField from 'Parts/ui/components/fields/SelectField';
import Can from 'Parts/session/components/CanContainer';
import Plugin from '../../../../plugin/Plugin';

import { isPluginActive } from '../../../../plugin/pluginActions';
import {
  getLanguageNameByKey,
  getLanguageKeyById,
  getDefaultLanguageKey
} from 'Parts/common/langUtils';
import { facebookScrape } from 'Parts/common/requestUtils';
import { getPlatformUrl, getCdnUrl } from 'Parts/common/environment';
import { getDateValue } from 'Parts/common/dateUtils';

import CampaignAdminPageForm_en from './locales/CampaignAdminPageForm_en.json';
import CampaignAdminPageForm_fr from './locales/CampaignAdminPageForm_fr.json';

import './CampaignAdminPageForm.less';

class CampaignAdminPageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang:
        getLanguageKeyById(get(props, 'campaign.languageId')) ||
        getDefaultLanguageKey(get(props, 'campaign.description')) ||
        getDefaultLanguageKey(get(props, 'languages')) ||
        props.locale,
      initialValues: {
        campaign_name: props.campaign.name,
        campaign_url: props.campaign.alias,
        campaign_goal: (props.campaign.settings && props.campaign.settings.goal) || 0,
        campaign_description: props.campaign.description,
        visibility: props.campaign.visibility,
        file: []
      },
      disabledUnilangualFields: false,
      declineModalDisplayed: false,
      displayChangeManager: false,
      ownerOrHigher: false
    };

    this.changeHasHappened = false;

    this.UploadCropField = React.createRef();
    this.WysiwygRef = React.createRef();
    this.approveModalRef = React.createRef();
    this.declineModalRef = React.createRef();
    this.pluginRef = React.createRef();

    this.cdnUrl = getCdnUrl();

    i18next.addResourceBundle('en', 'CampaignAdminPageForm', CampaignAdminPageForm_en);
    i18next.addResourceBundle('fr', 'CampaignAdminPageForm', CampaignAdminPageForm_fr);
  }

  componentDidMount() {
    if (
      this.props.checkAuthorization({ platform: this.props.platform.id }, [
        ['roles.platform.OWNER'],
        ['roles.platform.SUPERADMIN']
      ])
    ) {
      this.setState({
        ownerOrHigher: true
      });
    } else {
      this.setState({
        ownerOrHigher: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.props.form.validateFields({ force: true });
    }

    if (
      isEmpty(get(prevProps, 'campaign.description')) &&
      !isEqual(get(prevProps, 'campaign.description'), get(this.props, 'campaign.description'))
    ) {
      this.handleContentLanguageChange(
        getLanguageKeyById(get(this.props, 'campaign.languageId')) ||
        getDefaultLanguageKey(get(this.props, 'campaign.description')) ||
        getDefaultLanguageKey(get(this.props, 'languages')) ||
        this.props.locale
      );
      this.props.form.resetFields();
    } else if (this.props.campaign.id !== prevProps.campaign.id) {
      this.props.form.resetFields();
    }

    if (prevProps.userId !== this.props.userId) {
      if (
        this.props.checkAuthorization({ platform: this.props.platform.id }, [
          ['roles.platform.OWNER'],
          ['roles.platform.SUPERADMIN']
        ])
      ) {
        this.setState({
          ownerOrHigher: true
        });
      } else {
        this.setState({
          ownerOrHigher: false
        });
      }
    }
  }

  handleSubmit = (event, draft = false, publish = false) => {
    event.preventDefault();
    this.changeHasHappened = false;

    const { validateFields, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const file = this.state.file ? this.state.file : null;
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        /*remove unchanged value of touched fields*/
        for (let k in updatedValues) {
          if (updatedValues[k] == this.state.initialValues[k]) {
            delete updatedValues[k];
          }
        }

        /* START - EVENT UPDATE VALUES FORMATING */
        delete updatedValues.event_starts_date;
        delete updatedValues.event_starts_time;
        delete updatedValues.event_ends_date;
        delete updatedValues.event_ends_time;

        let initialEventStartOn = get(this.props, 'event.startOn', null);
        let initialEventEndOn = get(this.props, 'event.endOn', null);

        if (initialEventStartOn) initialEventStartOn = moment(initialEventStartOn);

        if (initialEventEndOn) initialEventEndOn = moment(initialEventEndOn);

        const updateEvent = get(this.pluginRef, 'current.actions.updateEvent', null);
        const eventUpdated = {
          ...((allValues.event_starts && allValues.event_starts.diff(initialEventStartOn) !== 0) ||
          (allValues.event_ends && allValues.event_ends.diff(initialEventEndOn) !== 0)
            ? {
                startOn: getDateValue(allValues.event_starts),
                endOn: getDateValue(allValues.event_ends)
              }
            : {})
        };
        /* END - EVENT UPDATE VALUES FORMATING */

        if (!isEmpty(updatedValues) || !isEmpty(eventUpdated) || file || publish) {
          const values = {
            ...updatedValues,
            file
          };

          values.draft = draft;
          values.publish = publish;

          Promise.all([
            ...(!isEmpty(updatedValues) || file || publish
              ? [this.props.updateCampaign(values, this.props.campaign.id, this.state.contentLang)]
              : []),
            ...(!isEmpty(eventUpdated) ? [updateEvent(this.props.campaign.id, eventUpdated)] : [])
          ]).then(() => {
            if (updatedValues.campaign_name || updatedValues.campaign_url || file !== null) {
              const platformUrl = getPlatformUrl(this.props.platform.alias);
              const campaignUrl = updatedValues.campaign_url || this.props.campaign.alias;
              var beginTeamAlias;
              var endTeamAlias;

              const urlsToScrape = [
                `${platformUrl}/en/${campaignUrl}`,
                `${platformUrl}/fr/${campaignUrl}`
              ];

              if (this.props.participants) {
                this.props.participants.forEach(participant => {
                  if (participant.teamAlias) {
                    beginTeamAlias = participant.teamAlias;

                    if (beginTeamAlias !== endTeamAlias) {
                      urlsToScrape.push(
                        `${platformUrl}/en/${campaignUrl}/${participant.teamAlias}`,
                        `${platformUrl}/fr/${campaignUrl}/${participant.teamAlias}`
                      );
                    }

                    urlsToScrape.push(
                      `${platformUrl}/en/${campaignUrl}/${participant.teamAlias}/${participant.member.alias}`,
                      `${platformUrl}/fr/${campaignUrl}/${participant.teamAlias}/${participant.member.alias}`
                    );

                    endTeamAlias = participant.teamAlias;
                  } else {
                    urlsToScrape.push(
                      `${platformUrl}/en/${campaignUrl}/individual-participant/${participant.member.alias}`,
                      `${platformUrl}/fr/${campaignUrl}/participant-individuel/${participant.member.alias}`
                    );
                  }
                });
              }

              facebookScrape(urlsToScrape);
            }
          });
        }
      }
    });
  };

  handleContentLanguageChange = value => {
    const defaultLangKey =
      getLanguageKeyById(get(this.props, 'campaign.languageId')) ||
      getDefaultLanguageKey(get(this.props, 'campaign.description')) ||
      getDefaultLanguageKey(get(this.props, 'languages')) ||
      this.props.locale;
    this.setState(
      {
        contentLang: value,
        disabledUnilangualFields: defaultLangKey != value
      },
      () => {
        this.forceBanner();
        this.changeHasHappened = false;
      }
    );
  };

  checkForChange = value => {
    if (this.props.form.isFieldTouched('campaign_description')) this.changeHasHappened = true;
  };

  forceBanner = () => {
    this.UploadCropField.current.getWrappedInstance('UploadCropField').resetField();
  };

  getDescription = lang => {
    const lng = getLanguageNameByKey(lang, false);
    return get(this.props, `campaign.description[${lng}].description`, '');
  };

  getBanner = lang => {
    const lng = getLanguageNameByKey(lang, false);
    return get(this.props, `campaign.media.banner[${lng}].banner`, null);
  };

  handleCancel = () => {
    this.changeHasHappened = false;
    this.props.form.resetFields();
    this.UploadCropField.current.getWrappedInstance('UploadCropField').resetField();
    this.WysiwygRef.current.getWrappedInstance('Wysiwyg').resetEditorState();
  };

  handleCropComplete = image => {
    this.setState({
      file: image
    });
    if (!this.changeHasHappened) {
      this.changeHasHappened = true;
    }
  };

  handleOpenApproveModal = e => {
    e.preventDefault();
    this.approveModalRef.current.getWrappedInstance('FundkyModal').showModal();
  };

  handleConfirmApproveModal = e => {
    e.preventDefault();
    this.props.updateCampaign({ statusId: 1 }, this.props.campaign.id).then(() => {
      this.resetDeclineModal();
      this.approveModalRef.current.getWrappedInstance('FundkyModal').hideModal();
    });
  };

  handleOpenDeclineModal = e => {
    e.preventDefault();
    this.declineModalRef.current.getWrappedInstance('FundkyModal').showModal();
    this.setState({ declineModalDisplayed: true });
    this.props.fetchPlatformMemberById(this.props.platform.id, this.props.campaign.creator.id);
  };

  resetDeclineModal = () => {
    this.setState({ declineModalDisplayed: false });
    this.props.form.resetFields(['reasonSelect', 'reasonField']);
  };

  handleConfirmDeclineModal = e => {
    e.preventDefault();
    const { validateFields, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields(['reasonSelect', 'reasonField'], { force: true }, (err, allValues) => {
      if (!err) {
        const creatorLang = this.props.creatorLanguageId
          ? getLanguageKeyById(this.props.creatorLanguageId)
          : 'en';

        const reason =
          allValues.reasonField ||
          this.props.i18n.options.resources[creatorLang].CampaignAdminPageForm.declining[
            allValues.reasonSelect
          ];

        this.props
          .updateCampaign({ statusId: 3, rejectReason: reason }, this.props.campaign.id)
          .then(() => {
            this.resetDeclineModal();
            this.declineModalRef.current.getWrappedInstance('FundkyModal').hideModal();
            this.setState({ declineModalDisplayed: false });
          });
      }
    });
  };

  render() {
    const {
      WysiwygRef,
      getDescription,
      getBanner,
      handleSubmit,
      handleCancel,
      handleContentLanguageChange
    } = this;
    const {
      t,
      campaign,
      form,
      locale,
      platform,
      accentColors,
      userRoleId,
      isUpdateCampaignInProgress,
      isUpdateEventInProgress
    } = this.props;
    const { contentLang, displayChangeManager, ownerOrHigher } = this.state;

    // Campaign content
    const goal = (campaign.settings && campaign.settings.goal) || 0;
    const description = getDescription(contentLang);
    const banner = getBanner(contentLang);

    const creator =
      campaign && campaign.owners && campaign.owners[0] ? (
        <AvatarThumbnail
          className="CampaignsTeamsPageForm__Captain"
          entity={campaign.owners[0]}
          primaryContent={t('manager')}
          secondaryContent={campaign.owners[0].name || null}
          editable={ownerOrHigher}
          url={`/${locale}/d/members/${campaign.owners[0].id}/information`}
          target="_blank"
          action={
            (ownerOrHigher && {
              icon: ['fal', 'user-edit'],
              onClick: e => {
                e.preventDefault;
                this.setState({
                  displayChangeManager: !displayChangeManager
                });
              }
            }) ||
            null
          }
        />
      ) : (
        <AvatarThumbnail
          className="CampaignsTeamsPageForm__Captain"
          entity={{ admin: true }}
          primaryContent={t('manager')}
          secondaryContent={t('administration')}
        />
      );

    const approveModal = (
      <FundkyModal
        ref={this.approveModalRef}
        displayModal={false}
        title={t('approving.title')}
        acceptAction={this.handleConfirmApproveModal}
      >
        <p>{t('approving.desc')}</p>
      </FundkyModal>
    );

    const declineModal = (
      <FundkyModal
        ref={this.declineModalRef}
        displayModal={false}
        title={t('declining.title')}
        maskClosable={false}
        acceptAction={this.handleConfirmDeclineModal}
        cancelAction={this.resetDeclineModal}
      >
        <p>
          <em>{t('declining.notice1')}</em>
        </p>
        <p>
          <em>{t('declining.notice2')}</em>
        </p>
        <SelectField
          form={form}
          fieldId="reasonSelect"
          initialValue="reason1"
          optionsArray={[
            { value: 'reason1', text: t('declining.reason1') },
            { value: 'reason2', text: t('declining.reason2') },
            { value: 'reason3', text: t('declining.reason3') }
          ]}
          style={{ width: '100%' }}
          dropdownClassName="overModal"
          disabled={!this.state.declineModalDisplayed}
        />
        {form.getFieldValue('reasonSelect') === 'reason3' && (
          <TextAreaField
            form={form}
            fieldId="reasonField"
            label={null}
            required={true}
            placeholder={null}
            disabled={!this.state.declineModalDisplayed}
            maxlength={255}
          />
        )}
      </FundkyModal>
    );

    let statusData = null;
    switch (campaign.statusId) {
      case 1:
        if (platform.settings.campaign.creation.preauth === '1') {
          statusData = t('pre-approved');
        } else {
          statusData = t('approved');
        }
        break;
      case 2:
        statusData = (
          <div className="CampaignAdminPageForm__Approval">
            <span>{t('pending')}</span>
            <Can rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}>
              <div>
                <FundkyButton
                  type="primary"
                  text={t('approve')}
                  action={{ func: this.handleOpenApproveModal }}
                />
                {approveModal}
                <FundkyButton
                  type="cancel"
                  text={t('decline')}
                  action={{ func: this.handleOpenDeclineModal }}
                />
                {declineModal}
              </div>
            </Can>
          </div>
        );
        break;
      case 3:
        statusData = t('declined');
        break;
      case 4:
        statusData = t('completed');
      case 5:
        statusData = t('inactive');
      case 6:
        statusData = t('draft');
    }

    const isCampaignInactive =
      campaign && (campaign.statusId === 3 || campaign.statusId === 4 || campaign.statusId === 5);

    const memberOptions =
      (this.props.members &&
        this.props.members.results &&
        this.props.members.results.map(member => {
          return { value: member.id, text: `${member.name} (${member.email})` };
        })) ||
      [];

    return (
      <Form className="AdminFormWrap" onSubmit={handleSubmit}>
        <div className="CampaignAdminPageForm">
          {creator ? (
            <React.Fragment>
              <FakeField data={creator} label={t('creator')} />
              {ownerOrHigher && (
                <Collapse isOpened={displayChangeManager}>
                  <SelectField
                    form={form}
                    fieldId="memberId"
                    label={t('change-campaign-manager-label')}
                    initialValue={
                      (campaign &&
                        campaign.owners &&
                        campaign.owners[0] &&
                        campaign.owners[0].id) ||
                      null
                    }
                    optionsArray={memberOptions}
                    style={{ width: '100%' }}
                    disabled={isCampaignInactive || this.state.disabledUnilangualFields}
                    showSearch
                    filterOption={(input, option) => {
                      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                  />
                </Collapse>
              )}
            </React.Fragment>
          ) : null}
          <CampaignNameField
            className=""
            form={form}
            fieldId="campaign_name"
            initialValue={campaign.name}
            label={true}
            placeholder={false}
            identity="campaign"
            counter={true}
            maxlength={75}
            disabled={isCampaignInactive || this.state.disabledUnilangualFields}
          />
          <TextField
            form={form}
            fieldId={'campaign_url'}
            addonBefore="/"
            label={t('Custom URL address')}
            initialValue={campaign.alias}
            fieldOptions={{
              rules: [
                {
                  required: true,
                  message: t('require')
                },
                {
                  pattern: /^[a-zA-Z0-9\-]+$/,
                  message: t('invalid')
                }
              ]
            }}
            disabled={isCampaignInactive || this.state.disabledUnilangualFields}
          />
          <MoneyField
            form={form}
            fieldId="campaign_goal"
            initialValue={goal}
            locale={locale}
            width={150}
            label={t('goal-label')}
            required={true}
            requiredMessage={t('goal-required')}
            wholeNumber={true}
            wholeNumberMessage={t('goal-whole-number')}
            minimum={5}
            minimumMessage={t('goal-minimum', { min: 5 })}
            invalidMessage={t('goal-invalid')}
            disabled={isCampaignInactive || this.state.disabledUnilangualFields}
          />
          {campaign.behaviorId === 2 && isPluginActive(this.props.platform, 'Event') && (
            <Plugin
              name="Event"
              component="EventAdminCampaignFields"
              ref={this.pluginRef}
              //alignment="left"
              //size="2x"
              //margin="0px 0px 24px 0"
              {...{
                // Props
                form: form,
                campaign: campaign,
                savedValues: null
              }}
            />
          )}
          <UploadCropField
            ref={this.UploadCropField}
            form={form}
            fieldId="banner"
            initialValue={banner ? `${this.cdnUrl}${banner}` : null}
            label={t('banner')}
            tooltip={t('banner-tooltip')}
            required={true}
            icon={['fal', 'image']}
            handleCropConfirm={this.handleCropComplete}
            disabled={isCampaignInactive}
          />
          <Wysiwyg
            ref={WysiwygRef}
            platform={platform}
            fieldId="campaign_description"
            label={t('description')}
            form={form}
            initialValue={description}
            counterWithTag={true}
            required={false}
            disabled={isCampaignInactive}
            accentColors={accentColors}
            onChange={this.checkForChange}
            allowSizes={false}
            allowHTML={userRoleId === 1}
            contentLang={contentLang}
          />
          {/*!isCampaignInactive && <CancelAndSaveButtons onClickCancel={handleCancel} />*/}
        </div>

        <SettingsColumn>
          <LanguageBoxForm
            form={form}
            handleContentLanguageChange={handleContentLanguageChange}
            changeHasHappened={this.changeHasHappened}
            defaultLanguage={
              getLanguageKeyById(get(this.props, 'campaign.languageId')) ||
              getDefaultLanguageKey(get(this.props, 'campaign.description')) ||
              getDefaultLanguageKey(get(this.props, 'languages')) ||
              this.props.locale
            }
            disabled={isCampaignInactive}
          />
          <AccessibilityBoxForm
            form={form}
            isDraft={campaign.statusId === 6}
            status={statusData}
            visibility={campaign.visibility}
            disabled={isCampaignInactive}
            handleSubmit={handleSubmit}
            loading={isUpdateCampaignInProgress || isUpdateEventInProgress}
          />
        </SettingsColumn>
      </Form>
    );
  }
}

CampaignAdminPageForm.propTypes = {
  form: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
};

export default translate('CampaignAdminPageForm')(CampaignAdminPageForm);

const test = null; // 'preproduction'; // 'production'; //
const environment = test ? test : ENVIRONMENT;
const config = require(`../../config/config.${environment}.json`);

export function getApiUrl() {
  return config.api;
}

export function getCdnUrl() {
  return config.cdn;
}

export function getEnv() {
  return config.env;
}

export function getProtocol() {
  return config.protocol;
}

export function getAlgoliaAppName() {
  return config.algolia;
}

export function getWepayKey() {
  return config.wepay;
}

export function getReCaptchaKey() {
  return config.reCAPTCHA;
}

export function getAlgoliaDonationIndexName() {
  return 'fundky_donations_' + environment;
}

export function getPlatformAlias(url = "") {
  const protocol = getProtocol();
  const env = getEnv();
  return url.split('.fundkyapp.com')[0].replace(protocol, '').replace(env, '');
}

export function getPlatformUrl(alias = "") {
  const protocol = getProtocol();
  const env = getEnv();
  return `${protocol}${alias}${env}.fundkyapp.com`;
}

export function getFees() {
  return config.fees;
}

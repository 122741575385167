export const UI_CAMPAIGN_WIZARD_STEP_CHANGE = 'UI_CAMPAIGN_WIZARD_STEP_CHANGE';
export const UI_CAMPAIGN_DETAIL_CLEAR = 'UI_CAMPAIGN_DETAIL_CLEAR';
export const UI_TEAM_WIZARD_STEP_CHANGE = 'UI_TEAM_WIZARD_STEP_CHANGE';
export const UI_PARTICIPANT_WIZARD_STEP_CHANGE = 'UI_PARTICIPANT_WIZARD_STEP_CHANGE';
export const UI_DONATION_WIZARD_STEP_CHANGE = 'UI_DONATION_WIZARD_STEP_CHANGE';

export const UI_START_EDITING_FORM = 'UI_START_EDITING_FORM';
export const UI_STOP_EDITING_FORM = 'UI_STOP_EDITING_FORM';

export function changeCampaignCreateWizardStep(step) {
  return {
    type: UI_CAMPAIGN_WIZARD_STEP_CHANGE,
    payload: step
  }
}

export function clearCampaign() {
  return {
    type: UI_CAMPAIGN_DETAIL_CLEAR
  };
}

export function changeTeamCreateWizardStep(step) {
  return {
    type: UI_TEAM_WIZARD_STEP_CHANGE,
    payload: step
  }
}

export function changeParticipantCreateWizardStep(step) {
  return {
    type: UI_PARTICIPANT_WIZARD_STEP_CHANGE,
    payload: step
  }
}

export function changeDonationCreateWizardStep(step) {
  return {
    type: UI_DONATION_WIZARD_STEP_CHANGE,
    payload: step
  }
}

export function startEditingForm() {
  return {
    type: UI_START_EDITING_FORM,
  }
}

export function stopEditingForm() {
  return {
    type: UI_STOP_EDITING_FORM,
  }
}

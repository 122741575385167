import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import { CAMPAIGN_TEMPLATE_TEAMS } from '../../campaignUtils';

import IndividualParticipantField_en from './locales/IndividualParticipantField_en.json';
import IndividualParticipantField_fr from './locales/IndividualParticipantField_fr.json';

class IndividualParticipantField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'IndividualParticipantField', IndividualParticipantField_en);
    i18next.addResourceBundle('fr', 'IndividualParticipantField', IndividualParticipantField_fr);
  }

  render() {
    const { t, form, className, initialValue } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    const template = getFieldValue('template');
    const input = <Checkbox>{t('label')}</Checkbox>;
    const fieldId = 'individualParticipant';
    const fieldOptions = {
      initialValue: initialValue,
      normalize: value => {
        if (template !== CAMPAIGN_TEMPLATE_TEAMS) {
          // Return null regardless the value of the field if the template is not team
          return false;
        }

        return value || false; // Return false instead of undefined
      },
      valuePropName: 'checked'
    };

    return (
      <Form.Item className={className}>{getFieldDecorator(fieldId, fieldOptions)(input)}</Form.Item>
    );
  }
}

IndividualParticipantField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired
};

export default translate('IndividualParticipantField')(IndividualParticipantField);

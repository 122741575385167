import Cookies from 'universal-cookie';
import isBrowser from 'is-in-browser';

import { stores } from '../chore/configureStore';
import { updateSessionTimeout } from '../session/sessionActions';

let cookies;

export function init(serverCookie) {
  if (serverCookie) {
    cookies = new Cookies(serverCookie);
  } else {
    cookies = new Cookies();
  }
}

/* Set an Array with the SessionId and a timestamp. The timestamp is used to prevent Admin Idle disconnection while using multiple windows. */
export function saveSessionId(sessionId) {
  cookies.set('session-id', sessionId, { path: '/', maxAge: 1800, secure: !LOCAL }); // 1800 = 30min
  // // Save cookie set date in the store.
  // if(isBrowser) stores[0].dispatch(updateSessionTimeout());
}

export function readSessionId() {
  return cookies.get('session-id');
}

export function clearSessionId() {
  return cookies.remove('session-id', { path: '/' });
}

export function saveSessionDate() {
  cookies.set('session-date', Date.now(), { path: '/', maxAge: 1800, secure: !LOCAL }); // 1800 = 30min
  // Save cookie set date in the store.
  if(isBrowser) stores[0].dispatch(updateSessionTimeout());
}

export function readSessionDate() {
  return cookies.get('session-date');
}

export function clearSessionDate() {
  return cookies.remove('session-date', { path: '/' });
}

export function saveResentActivationEmail() {
  cookies.set('hasResentActivationEmail', true, { path: '/', maxAge: 43200, secure: !LOCAL }) // 43200 = 24h
}

export function readResentActivationEmail() {
  return cookies.get('hasResentActivationEmail')
}

export function clearResentActivationEmail() {
  return cookies.remove('hasResentActivationEmail', { path: '/' })
}

export function saveTwitchConnectCode(code) {
  cookies.set('twitchConnectCode', code, { path: '/', maxAge: 1800, secure: !LOCAL }) // 1800 = 1h
}

export function readTwitchConnectCode() {
  return cookies.get('twitchConnectCode')
}

export function clearTwitchConnectCode() {
  return cookies.remove('twitchConnectCode', { path: '/' })
}

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import dataFetcher from 'Parts/chore/dataFetcher';

import RefundPolicyPage from './RefundPolicyPage';

const mapStateToProps = state => ({
  locale: state.session.language,
  organization: state.organization.organization,
  content: state.platform.content,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  dataFetcher,
)(RefundPolicyPage);

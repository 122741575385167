import {
  FETCH_ALGOLIA_TOKEN_SUCCESS,
  FETCH_ALGOLIA_TOKEN_ERROR,
  
  FETCH_ALGOLIA_SAVED_FILTERS_START,
  FETCH_ALGOLIA_SAVED_FILTERS_SUCCESS,
  FETCH_ALGOLIA_SAVED_FILTERS_ERROR,
  
  SAVE_ALGOLIA_FILTER_START,
  SAVE_ALGOLIA_FILTER_SUCCESS,
  SAVE_ALGOLIA_FILTER_ERROR,
  
  DELETE_ALGOLIA_FILTER_START,
  DELETE_ALGOLIA_FILTER_SUCCESS,
  DELETE_ALGOLIA_FILTER_ERROR,
} from './algoliaActions';

const initialState = {
  isSearchInProgress: false,
  algoliaToken: {},
  algoliaFilters: {}
};

export default function algoliaReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALGOLIA_SAVED_FILTERS_START:
    return {
      ...state,
      isSearchInProgress: true
    };
    
    // TOKEN
    case FETCH_ALGOLIA_TOKEN_SUCCESS:
    return {
      ...state,
      algoliaToken: action.payload,
    };
    
    case FETCH_ALGOLIA_TOKEN_ERROR:
    return {
      ...state,
      algoliaToken: false
    };
    ///////////
    
    // FILTERS
    case FETCH_ALGOLIA_SAVED_FILTERS_SUCCESS:
    return {
      ...state,
      algoliaFilters: action.payload,
      isSearchInProgress: false
    };
    
    case FETCH_ALGOLIA_SAVED_FILTERS_ERROR:
    return {
      ...state,
      algoliaFilters: initialState.algoliaFilters,
      isSearchInProgress: false
    };
    
    case SAVE_ALGOLIA_FILTER_SUCCESS:
    return {
      ...state,
      algoliaFilters: action.payload
    };
    
    case SAVE_ALGOLIA_FILTER_ERROR:
    return {
      ...state,
      algoliaFilters: initialState.algoliaFilters
    };
    
    case DELETE_ALGOLIA_FILTER_SUCCESS:
    return {
      ...state,
      algoliaFilters: action.payload
    };
    
    case DELETE_ALGOLIA_FILTER_ERROR:
    return {
      ...state,
      algoliaFilters: initialState.algoliaFilters
    };
    
    default:
    return state;
  }
}

export const fr_ca = {
  LOGS: {
    "History": "Historique",
    "Before": "Avant",
    "After": "Après",
    "has-created": " a créé ",
    "has-updated": " a mis à jour ",
    "has-donated": " a donné ",
    "has-deleted": " a supprimé ",
    "has-invited": " a invité ",
    "has-cancelled": " a annulé ",
    "has-downloaded": " a téléchargé ",
    "has-sent": " a envoyé ",
    "has-accepted": " a accepté ",
    "has-rejected": " a refusé ",
    "been-updated-ms": " a été mis à jour.",
    "been-updated-mp": " ont été mis à jour.",
    "been-updated-fs": " a été mise à jour.",
    "been-updated-fp": " ont été mises à jour.",
    "organization": "l'organisation",
    "platform": "la plateforme",
    "campaign": "la campagne",
    "team": "l'équipe",
    "participant": "le participant",
    "donation": "le don",
    "Member#": "Le membre #",
    "member": "le membre",
    "donation-receipt": "le reçu de don",
    "donation-receipt-settings": "les configurations de reçu de don",
    "settings": "les configurations",
    "description": "la description",
    "languages": "une langue",
    "content": "le contenu",
    "section": "la section",
    "media": "un média",
    "featured-campaigns": "les campagnes vedettes",
    "information": "les informations",
    "notes": "une note",
    "profile": "le profil",
    "invitation": "l'invitation",
    "transaction-review": "la révision de la transaction",
    "in": " dans ",
    "of": " de ",
    "to": " à ",
    "en$": "",
    "fr$": "$",
    "sent-to": " envoyée à ",
    "be-an-owner": " à être un propriétaire de ",
    "be-a-manager": " à être un directeur de ",
    "be-a-captain": " à être un capitaine de ",
    "added": "ajouté",
    "removed": "supprimé",
    "modified": "modifié",
    "deleted": "supprimé",
    "configurations-updated": "Configurations mises à jour.",
    "configurations-updated-by": "Configurations mises à jour par {{name}}.",
    "offline": "hors-ligne",
    "logged-in": " s'est connecté ",
    "facebook-scrape-call": "une demande de récupération de données à Facebook",
  }
};

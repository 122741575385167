import pick from 'lodash/pick';

export default function (rootReducer, logoutActionType, whiteList = []) {
  return (state, action) => {
    if (action.type === logoutActionType) {
      state = pick(state, whiteList);
    }
    
    return rootReducer(state, action)
  }
}

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Form } from "antd";

import ResetForm from './ResetForm';
import { openLoginModal, openRegisterModal, openRecoveryModal } from "../../ui/uiActions";
import { reset } from '../sessionActions';

const mapStateToProps = state => ({
  browser: state.session.browser,
  isResetInProgress: state.ui.isResetInProgress,
  isResetSuccess: state.ui.isResetSuccess,
  isCheckRecoveryKeyFail: state.session.isCheckRecoveryKeyFail,
  connectionModalForced: state.ui.connectionModalForced,
  connectionModalOrigin: state.ui.connectionModalOrigin,
  platform: state.platform && state.platform.platform,
  sid: state.session.recoveryKey,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openLoginModal,
  openRegisterModal,
  openRecoveryModal,
  reset
}, dispatch);

const mapPropsToFields = props => ({
  platformId: Form.createFormField({ value: props.platform && props.platform.id })
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create({ mapPropsToFields })
)(ResetForm);

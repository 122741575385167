import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import CardNameField_en from './locales/CardNameField_en.json';
import CardNameField_fr from './locales/CardNameField_fr.json';

class CardNameField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CardNameField', CardNameField_en);
    i18next.addResourceBundle('fr', 'CardNameField', CardNameField_fr);
  }

  render() {
    const { t, form, className } = this.props;
    const { getFieldDecorator } = form;

    const input = <Input placeholder={t('placeholder')} />;
    const fieldId = 'cardName';
    const fieldOptions = {
      rules: [
          {
            required: true,
            message: t('require')
          },
          {
            pattern: /^[a-zA-Z\u00C0-\u017F\u2019\ ,.'\-]+$/,
            message: t('invalid')
          }
        ],
    };

    return (
      <Form.Item className={className} hasFeedback>
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

CardNameField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('CardNameField')(CardNameField);

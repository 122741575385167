import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import dataFetcher from 'Parts/chore/dataFetcher';
import { fetchProvinces } from 'Parts/geo/geoActions';

import ContactPage from './ContactPage';

const mapStateToProps = state => ({
  //TODO GET MAP OR ADDRESS
  map: null,
  locale: state.session.language,
  organization: state.organization.organization,
  platformSocial: state.platform.platform.settings.social,
  provinces: state.geo.provinces
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchProvinces
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(ContactPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import UnitField_en from './locales/UnitField_en.json';
import UnitField_fr from './locales/UnitField_fr.json';

class UnitField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'UnitField', UnitField_en);
    i18next.addResourceBundle('fr', 'UnitField', UnitField_fr);
  }

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;

    const placeholder = (
      this.props.placeholder === true ?
        t("unit") :
        this.props.placeholder || null
    );

    const input = <Input placeholder={placeholder} />;
    const fieldId = 'unit';
    const fieldOptions = {
      rules: [
          {
            pattern: /^[a-zA-Z0-9\u00C0-\u017F ,-\\']+$/,
            message: this.props.validationMessage || t('validation')
          },
          {
            pattern: /^.{0,128}$/,
            message: t('maximum')
          }
        ],
      initialValue: this.props.initialValue || undefined
    };

    const label = (
      this.props.label == true ?
        t("unit") :
        this.props.label || null
    );

    return (
      <Form.Item
        className={this.props.className}
        hasFeedback
        label={label}
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

UnitField.defaultProps = {
  label: true,
  placeholder: false,
  validationMessage: null,
  initialValue: undefined,
}

UnitField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('UnitField')(UnitField);

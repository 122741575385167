import { bindActionCreators, compose } from "redux";
import { Form } from 'antd';
import { connect } from "react-redux";
import { updateMember } from './../../../member/memberActions';
import { updateSessionState } from 'Parts/session/sessionActions';

import InformationForm from './InformationForm';

const mapStateToProps = state => ({
  user: state.session.user,
  platformId: state.platform.platform.id,
  platformAlias: state.platform.platform.alias,
  memberRoleId: state.member.member.roleId,
  roles: state.session.roles,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateMember,
  updateSessionState
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(InformationForm);

import React from 'react';
import { Form } from 'antd';
import { pickBy, get, isEmpty } from 'lodash';
import i18next from 'i18next';
import { translate } from 'react-i18next';

import {
  getLanguageNameByKey,
  getLanguageIdByKey,
  getDefaultLanguageKey
} from 'Parts/common/langUtils';
import { getPlatformUrl } from 'Parts/common/environment';
import { facebookScrape } from 'Parts/common/requestUtils';

import LangSwitcher from '../../components/AdminContentLangSwitcher';
import SocialMediaField from './fields/SocialMediaField';
import SocialMediasField from './fields/SocialMediasField';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';
import UploadCropField from 'Parts/ui/components/fields/UploadCropField';

import SocialMediaSettingsFormEN from './locales/SocialMediaSettingsForm_en.json';
import SocialMediaSettingsFormFR from './locales/SocialMediaSettingsForm_fr.json';

class SocialMediasSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang: getDefaultLanguageKey(get(props, 'languages')) || props.locale,
      ...props.socials,
      socials: ['facebook', 'instagram', 'linkedin', 'pinterest', 'twitter'],
      labels: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
        pinterest: 'Pinterest',
        twitter: 'Twitter'
      },
      icons: {
        facebook: 'facebook-f',
        linkedin: 'linkedin-in',
        twitter: 'twitter',
        pinterest: 'pinterest',
        instagram: 'instagram'
      },
      refs: {
        facebook: React.createRef(),
        instagram: React.createRef(),
        linkedin: React.createRef(),
        pinterest: React.createRef(),
        twitter: React.createRef()
      },
      shareImage: undefined
    };

    this.changeHasHappened = false;
    this.UploadCropField = React.createRef();

    i18next.addResourceBundle('en', 'SocialMediasSettingsForm', SocialMediaSettingsFormEN);
    i18next.addResourceBundle('fr', 'SocialMediasSettingsForm', SocialMediaSettingsFormFR);
  }

  handleSubmit = event => {
    event.preventDefault();
    this.changeHasHappened = false;

    const { props } = this,
      {
        form,
        platformId,
        updatePlatformSettings,
        updatePlatformMedia,
        deletePlatformMedia
      } = props,
      { validateFields, isFieldTouched } = form;

    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));
        const { shared_image } = updatedValues; //Remove shared_image since we are using state instead
        const { shareImage } = this.state;

        if (updatedValues.shared_image) {
          delete updatedValues.shared_image;
        }

        if (updatedValues.LangSwitcher) {
          delete updatedValues.LangSwitcher;
        }

        if (!isEmpty(updatedValues) || shareImage !== undefined) {
          Object.keys(updatedValues).map(key => {
            updatedValues[key] = updatedValues[key] === '' ? null : updatedValues[key];
          });

          const settingsValues = {
            social: {
              ...updatedValues
            }
          };

          const mediaArray = [
            {
              thumbnail: shareImage === null ? null : shareImage,
              languageId: getLanguageIdByKey(this.state.contentLang)
            }
          ];

          if (!isEmpty(updatedValues) || shareImage || shareImage === null) {
            Promise.all([
              ...(!isEmpty(updatedValues)
                ? [updatePlatformSettings(platformId, settingsValues)]
                : []),
              ...(shareImage
                ? [updatePlatformMedia(platformId, 'thumbnail', mediaArray)]
                : shareImage === null
                ? [deletePlatformMedia(platformId, 'thumbnail', mediaArray[0].languageId)]
                : [])
            ]).catch(error => {
              console.warn(error);
              false;
            });
          }

          if (shareImage === null || shareImage) {
            const { platform } = this.props;
            const platformUrl = getPlatformUrl(platform.alias);
            facebookScrape([`${platformUrl}/${this.state.contentLang}`]);
          }
        }
      }
    });
  };

  handleCancel = event => {
    const before = this.props.form.getFieldsValue();
    this.props.form.resetFields();
    const after = this.props.form.getFieldsValue();
    for (var ref in this.state.refs) {
      if (before[ref] !== after[ref]) {
        this.state.refs[ref].current.getWrappedInstance().updateIconColor(this.state[ref]);
      }
    }
  };

  handleCropComplete = shareImage => {
    this.setState({
      shareImage: shareImage
    });
    this.changeHasHappened = true;
  };

  handleContentLanguageChange = value => {
    this.setState({ contentLang: value }, () => {
      this.forceShareImage();
    });
  };

  forceShareImage = () => {
    this.UploadCropField.current.getWrappedInstance('UploadCropField').resetField();
  };

  handleShareImageDelete = () => {
    this.setState({
      shareImage: null
    });
    this.changeHasHappened = true;
  };

  getShareImage = lang => {
    const lng = getLanguageNameByKey(lang, false);
    return get(this.props, `shareImage[${lng}].thumbnail`, null);
  };

  render() {
    const { t, form } = this.props;
    const { socials, labels, icons, refs, contentLang } = this.state;

    const shareImage = this.getShareImage(contentLang);

    const inputs = socials.map(social => (
      <SocialMediaField
        key={social}
        className={social}
        label={labels[social]}
        icon={icons[social]}
        value={this.state[social]}
        form={form}
        ref={refs[social]}
      />
    ));

    return (
      <Form className="SocialMediasSettingsForm" onSubmit={this.handleSubmit}>
        <div className="inputs">
          <SocialMediasField inputs={inputs} />

          <LangSwitcher
            form={form}
            defaultLanguage={
              getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale
            }
            changeHasHappened={this.changeHasHappened}
            handleChange={this.handleContentLanguageChange}
          />
          <UploadCropField
            ref={this.UploadCropField}
            form={form}
            fieldId={'shared_image'}
            initialValue={shareImage || this.state.shareImage || null}
            label={t('share-image-label')}
            tooltip={t('share-image-tooltip')}
            aspectRatio={1.904761904761905}
            minWidth={1200}
            recommendedWidth={1200}
            hasFeedback
            erasable
            handleCropConfirm={this.handleCropComplete}
            handleDelete={this.handleShareImageDelete}
          />
        </div>

        <CancelAndSaveButtons
          onClickCancel={this.handleCancel}
          isSavingInProgress={this.props.isUpdatePlatformInProgress}
        />
      </Form>
    );
  }
}

export default translate('SocialMediasSettingsForm')(SocialMediasSettingsForm);

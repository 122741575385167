import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import { DEFAULT_COMPACT_PAGER } from 'Parts/search/searchUtils';
import ResultThumbnailList from 'Parts/search/ResultThumbnailList';
import LinkButton from 'Parts/common/LinkButton';
import AvatarThumbnail from '../../common/AvatarThumbnailContainer';
import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';

import SelectCampaignPanel_en from './locales/SelectCampaignPanel_en.json';
import SelectCampaignPanel_fr from './locales/SelectCampaignPanel_fr.json';

import './SelectCampaignPanel.less';

class SelectCampaignPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'SelectCampaignPanel', SelectCampaignPanel_en);
    i18next.addResourceBundle('fr', 'SelectCampaignPanel', SelectCampaignPanel_fr);

    this.state = {
      pager: {
        ...DEFAULT_COMPACT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (
      this.props.pagination &&
      this.props.pagination.pageNumber &&
      (!prevProps.pagination ||
        !prevProps.pagination.pageNumber ||
        prevProps.pagination.pageNumber !== this.props.pagination.pageNumber)
    ) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }

    if (this.props.memberId !== prevProps.memberId) {
      this.props.searchCampaigns(
        this.props.platformId,
        this.props.search,
        this.props.filters,
        this.props.pagination,
        { orderBy: 'addedOn[desc]' }
      );
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageNumber: pager.current,
      pageResults: pager.pageSize
    };
    this.props.searchCampaigns(
      this.props.platformId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' }
    );
  };

  handleThumbnailClick = campaign => {
    const { statusId, typeId, settings, childrenCount, participating, behaviorId, alias } = campaign;

    // Team limit / count
    const teamLimit =
      (settings && settings.team && settings.team.limit && parseInt(settings.team.limit)) || null;
    const teamsCount = (childrenCount && childrenCount.teams) || null;

    // Participant limit / count
    const paticipantLimit =
      (settings &&
        settings.participant &&
        settings.participant.limit &&
        parseInt(settings.participant.limit)) ||
      null;
    const participantsCount = (childrenCount && childrenCount.participants) || null;

    // Other campaign settings
    const isClosed = statusId === 4;
    const isRegistrationDisabled =
      settings && settings.registration
        ? isDateBeforeCurrentDate(settings.registration.disabledOn, true)
        : false;
    const isStandalone = typeId === 4;
    // Don't calculate is full if campaign type MIXED
    const isFull =
      typeId === 3
        ? false
        : teamLimit &&
          teamsCount &&
          paticipantLimit &&
          participantsCount &&
          teamsCount >= teamLimit &&
          participantsCount >= teamLimit * paticipantLimit;

    const isEvent = behaviorId === 2;

    if (isClosed || participating || isRegistrationDisabled || isStandalone || isFull) {
      return null;
    }

    if (isEvent) {
      this.props.history.push(`/${this.props.locale}/${alias}/${this.props.t('URL:ticket-purchase/choose')}`);
      return null;
    }

    return this.props.selectCampaign(campaign);
  };

  handleSearch = () => {
    const pager = {
      ...this.state.pager,
      current: 1
    };
    this.setState({ pager });
    //TODO search client side //this.props.fetchOrganizations(pager, filters);
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchCampaigns(this.props.platformId, filters.search, filters, pagination, {
      orderBy: 'addedOn[desc]'
    });
  };

  isCampaignFull = campaign => {
    const { typeId, settings, childrenCount } = campaign;
    const participantLimit =
      (settings &&
        settings.participant &&
        settings.participant.limit &&
        parseInt(settings.participant.limit)) ||
      null;
    const participantCount = (childrenCount && childrenCount.participants) || null;
    const teamLimit =
      (settings && settings.team && settings.team.limit && parseInt(settings.team.limit)) || null;
    const teamCount = (childrenCount && childrenCount.teams) || null;

    switch (typeId) {
      case 1: //SOLO
        if (participantCount && participantLimit && participantCount >= participantLimit) {
          return true;
        }
        break;
      case 2: //TEAM
        if (
          teamLimit &&
          teamCount &&
          teamCount >= teamLimit &&
          participantLimit &&
          participantCount &&
          participantCount >= participantLimit * teamLimit
        ) {
          return true;
        }
        break;
    }

    return false;
  };

  render() {
    const { t, locale } = this.props;

    return (
      <div className="SelectCampaignPanel FundkyWrap">
        <h2>{t('title')}</h2>
        {/* <p>{t('desc')}</p> */}
        <SearchBar title={t('placeholder')} compact onSearch={this.handleSearch} />
        <PaginationTop
          totalLabel={total => `${total}${t('campaigns', { s: total > 1 ? 's' : '' })}`}
          pager={this.state.pager}
          onChange={this.handlePaginationChange}
          showSizeChanger={false}
        />
        <ResultThumbnailList
          rowKey="id"
          dataSource={this.props.campaigns || []}
          onThumbnailClick={this.handleThumbnailClick}
          renderRecord={campaign =>
            /*CAMPAIGN CLOSED*/
            campaign.statusId === 4
            ? (
                <Tooltip placement="bottom" title={t('campaign-closed')}>
                  <Card bordered={false} className="card-disabled">
                    <AvatarThumbnail
                      entity={campaign}
                      primaryContent={campaign.name}
                      avatarSize={58}
                    />
                  </Card>
                </Tooltip>
              )
            : /*REGISTRATION CLOSED*/
              campaign.settings.registration &&
              isDateBeforeCurrentDate(campaign.settings.registration.disabledOn, true)
              ? (
                  <Tooltip placement="bottom" title={t('registrations-closed')}>
                    <Card bordered={false} className="card-disabled">
                      <AvatarThumbnail
                        entity={campaign}
                        primaryContent={campaign.name}
                        avatarSize={58}
                      />
                    </Card>
                  </Tooltip>
                )
              : /*STANDALONE CAMPAIGN*/
                campaign.typeId === 4
                ? (
                    <Tooltip placement="bottom" title={t('dont-accept')}>
                      <Card bordered={false} className="card-disabled">
                        <AvatarThumbnail
                          entity={campaign}
                          primaryContent={campaign.name}
                          avatarSize={58}
                        />
                      </Card>
                    </Tooltip>
                  )
                : /*ALREADY PARTICIPANT*/
                  campaign.participating
                  ? (
                      <Tooltip placement="bottom" title={t('already-in')}>
                        <Card bordered={false} className="card-disabled">
                          <AvatarThumbnail
                            entity={campaign}
                            primaryContent={campaign.name}
                            avatarSize={58}
                          />
                        </Card>
                      </Tooltip>
                    )
                  : /*CAMPAIGN FULL*/
                    this.isCampaignFull(campaign)
                    ? (
                        <Tooltip placement="bottom" title={t('full')}>
                          <Card bordered={false} className="card-disabled">
                            <AvatarThumbnail
                              entity={campaign}
                              primaryContent={campaign.name}
                              avatarSize={58}
                            />
                          </Card>
                        </Tooltip>
                      )
                    : /*EVENT TITCKET*/
                      campaign.behaviorId === 2
                      ? (
                          <Tooltip placement="bottom" title={t('event')}>
                            <Card bordered={false}>
                              <AvatarThumbnail
                                entity={campaign}
                                primaryContent={campaign.name}
                                avatarSize={58}
                              />
                            </Card>
                          </Tooltip>
                        )
                      : /*CAMPAIGN CARD*/
                        (
                          <Card bordered={false}>
                            <AvatarThumbnail entity={campaign} primaryContent={campaign.name} avatarSize={58} />
                          </Card>
                        )
          }
          cta={
            <LinkButton
              type="primary"
              className="SelectCampaignPanel__CreateCampaignCTA"
              to={{ pathname: `/${locale}/${t('URL:create-a-campaign')}` }}
              size="large"
            >
              {t('create-a-campaign')}
            </LinkButton>
          }
        />
      </div>
    );
  }
}

SelectCampaignPanel.defaultProps = {
  campaigns: [],
  pagination: {
    totalResults: 0
  }
};

SelectCampaignPanel.propTypes = {
  campaigns: PropTypes.array.isRequired,
  selectCampaign: PropTypes.func.isRequired
};

export default translate('SelectCampaignPanel')(SelectCampaignPanel);

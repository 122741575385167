import { compose } from "redux";
import { withRouter } from 'react-router';
import dataFetcher from 'Parts/chore/dataFetcher';
import { connect } from "react-redux";

import CampaignsSettingsPage from './CampaignsSettingsPage';

const mapStateToProps = state => ({
  campaign: state.campaign.campaign,
  locale: state.session.language
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  dataFetcher
)(CampaignsSettingsPage);

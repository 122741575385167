import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { get } from 'lodash';

import HomePage from './HomePage';
import dataFetcher from "../../parts/chore/dataFetcher";
import { getLastCampaigns } from "../../campaign/campaignSelectors";
import { openLoginModal, openRegisterModal, openRecoveryModal, openResetModal, openConfirmationModal } from "../../parts/ui/uiActions";

const mapStateToProps = state => ({
  lastCampaigns: getLastCampaigns(state),
  featuredCampaigns: state.platform.featuredCampaigns,
  platform: state.platform.platform,
  locale: state.session.language,
  connectionModalState: state.ui.connectionModalState,
  userTypeId: state.session.userTypeId,
  campaigns: state.campaign.campaigns,
  isBillingConfigured: get(state, 'organization.isBillingConfigured'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openLoginModal,
  openRegisterModal,
  openResetModal,
  openRecoveryModal,
  openConfirmationModal,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(HomePage);

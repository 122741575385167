import {
  getLanguageMoneyFormatByKey
} from './langUtils';

export function moneyFormat(amount, lng, round = false, currency = null, forceNumber = false) {
  // ***
  // forceNumber: no Thousand separator and force decimal point (.)
  // ***

  if (!amount || isNaN(amount)) amount = 0;

  const
    format = getLanguageMoneyFormatByKey(lng),
    before = format[0],
    thousandSeparator = forceNumber ? '' : format[1],
    decimalSeparator = forceNumber ? '.' : format[2],
    after = format[3],
    thousandRegex = /\B(?=(\d{3})+(?!\d))/g,
    rounded = Math.round(amount).toString().replace(thousandRegex, thousandSeparator),
    integer = Math.trunc(amount).toString().replace(thousandRegex, thousandSeparator),
    decimal = forceNumber ? Math.round(amount % 1 * 100) : Math.round(amount % 1 * 100).toString()
    .padStart(2, '0'),
    formattedAmount = `${
      // Display currency before
        before &&
        currency ?
          `${currency.toUpperCase()} `
          : ''
      }${
      // Display sign before
        before ?
          before
          : ''
      }${
      // Display amount rounded or not
        round ?
          rounded
          : `${integer}${decimalSeparator}${decimal}`
      }${
      // Display sign after
        after ?
          after
          : ''
      }${
      // Display currency after
        after &&
        currency ?
          ` ${currency.toUpperCase()}`
          : ''
      }`;

  return formattedAmount;
}

export function moneyToNumber(amount) {
  if (!amount) amount = 0;
  return parseFloat(parseFloat(amount).toFixed(2));
}

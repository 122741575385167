import React from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import AdminTitleBar from 'Parts/common/AdminTitleBar';
import InformationForm from './components/InformationFormContainer';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
// import ContributionsForm from './components/ContributionsFormContainer';
import Can from 'Parts/session/components/CanContainer';

import MyProfilePageEN from './locales/MyProfilePage_en.json';
import MyProfilePageFR from './locales/MyProfilePage_fr.json';

class MyProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.from = props.location.state && props.location.state.from;

    i18next.addResourceBundle('en', 'MyProfilePage', MyProfilePageEN);
    i18next.addResourceBundle('fr', 'MyProfilePage', MyProfilePageFR);
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { state } = this.props.location;
    if (state && state.from) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }

    // Redirect user to his profile if he tries to cheat (coquin)
    if (this.props.match.params.memberID !== this.props.currentUserId) {
      this.props.history.push(
        `/${this.props.locale}/d/profile/${this.props.currentUserId}/my-information`
      );
    }
  }

  render() {
    const { t, member } = this.props;
    const subname = t('member-since') + ' ' + moment(member.addedOn).format('LL');

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: props => <InformationForm {...this.props} />
      }
      // TODO
      // { title: t('Information'), slug: 'information', container: (props) => (<Can me={true} memberId={member.id || null}><InformationForm {...this.props} /></Can>) } // need to fix <Can> because it breaks when coming from edit a member
      //{title: t('Contributions'), slug: 'donations', container: (props) => <ContributionsForm {...this.props} />}
    ];

    return (
      <div className="MyProfilePage">
        <Helmet>
          <title>{t('page-title')}</title>
        </Helmet>
        <AdminTitleBar title={member.name || ''} subName={subname} />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} defaultTabHasNoSlug />
      </div>
    );
  }
}

export default translate('MyProfilePage')(MyProfilePage);

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';
import { Layout, Collapse } from 'antd';
import { Helmet } from 'react-helmet';

import FundkyTooltip from 'Parts/ui/components/FundkyTooltip';
import CampaignsSettingsTransactionsDonationForm from './components/CampaignsSettingsTransactionsDonationFormContainer';
import CampaignsSettingsTransactionsGoalForm from './components/CampaignsSettingsTransactionsGoalFormContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import { isPluginActive } from '../../../plugin/pluginActions';
import Plugin from '../../../plugin/Plugin';

import campaignsSettingsTransactionsPageEN from './locales/CampaignsSettingsTransactionsPage_en.json';
import campaignsSettingsTransactionsPageFR from './locales/CampaignsSettingsTransactionsPage_fr.json';

import './CampaignsSettingsTransactionsPage.less';

const { Panel } = Collapse;

class CampaignsSettingsTransactionsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      donationReceiptTabAuth: null
    };

    i18next.addResourceBundle(
      'en',
      'CampaignsSettingsTransactionsPage',
      campaignsSettingsTransactionsPageEN
    );
    i18next.addResourceBundle(
      'fr',
      'CampaignsSettingsTransactionsPage',
      campaignsSettingsTransactionsPageFR
    );
  }

  componentDidMount() {
    this.setState({
      donationReceiptTabAuth: this.props.checkAuthorization({ platform: this.props.platform.id }, [
        ['roles.platform.OWNER'],
        ['roles.platform.SUPERADMIN']
      ])
    });
  }

  componentDidUpdate() {
    if (this.state.donationReceiptTabAuth === null) {
      const auth = this.props.checkAuthorization({ platform: this.props.platform.id }, [
        ['roles.platform.OWNER'],
        ['roles.platform.SUPERADMIN']
      ]);
      if (auth === true || auth === false) {
        this.setState({
          donationReceiptTabAuth: auth
        });
      }
    }
  }

  render() {
    const { props } = this,
      { t, locale, history, location, permissions, campaign, platform, checkAuthorization } = props;

    // Donation Form values
    const suggestedAmounts = [
      get(campaign, 'settings.donation.preset.A', 0),
      get(campaign, 'settings.donation.preset.B', 0),
      get(campaign, 'settings.donation.preset.C', 0),
      get(campaign, 'settings.donation.preset.D', 0)
    ];

    const allowCustomAmount = get(campaign, 'settings.donation.preset.custom', 0) == 1;

    const participantsCanChangeGoal = get(campaign, 'settings.participant.custom.goal', 0) == 1;

    // Goals Form values
    const overallGoal = get(campaign, 'settings.participant.goal', 1000);

    const DonationTabPaneContent = (
      <Collapse accordion bordered={false} defaultActiveKey={['1']}>
        <Panel
          header={
            <span>
              {t('Donations')}
              <FundkyTooltip title={t('Suggested Amount Tooltip')} />
            </span>
          }
          key="1"
        >
          <CampaignsSettingsTransactionsDonationForm
            suggestedAmounts={suggestedAmounts}
            locale={locale}
            allowCustomAmount={allowCustomAmount}
            location={location}
          />
        </Panel>
        {campaign.typeId !== 4 && (
          <Panel
            header={
              <span>
                {t('Goals')}
                <FundkyTooltip title={t('Goals Tooltip')} />
              </span>
            }
            key="2"
          >
            <CampaignsSettingsTransactionsGoalForm
              overallGoal={overallGoal}
              participantsCanChangeGoal={participantsCanChangeGoal}
              location={location}
            />
          </Panel>
        )}
      </Collapse>
    );

    const TaxReceiptCampaignSettingsTabRoute = (
      <Plugin
        name="TaxReceipt"
        component="TaxReceiptCampaignSettingsTabRoute"
        {...{
          // Props
          locale: locale,
          permissions: permissions,
          campaignId: campaign.id,
          campaign: campaign
        }}
      />
    );

    const EventAdminCampaignSettingsTransactionsTabRoute = (
      <Plugin
        name="Event"
        component="EventAdminCampaignSettingsTransactionsTabRoute"
        {...{
          // Props
          locale: locale,
          permissions: permissions,
          campaignId: campaign.id,
          campaign: campaign
        }}
      />
    );

    const tabPages = [
      {
        title: t('Donations'),
        slug: 'donations',
        container: props => (
          <Can rules={['permissions.campaign.campaign.update']} redirect={`/${locale}/d`}>
            {DonationTabPaneContent}
          </Can>
        )
      },
      {
        title: t('DonationReceipt'),
        slug: 'donationreceipt',
        visible:
          this.props.organization.typeId === 2 &&
          isPluginActive(this.props.platform, 'TaxReceipt') &&
          this.state.donationReceiptTabAuth &&
          TaxReceiptCampaignSettingsTabRoute
            ? true
            : false,
        container: props => TaxReceiptCampaignSettingsTabRoute
      },
      {
        title: t('Tickets'),
        slug: 'tickets',
        visible:
          isPluginActive(this.props.platform, 'Event') &&
          EventAdminCampaignSettingsTransactionsTabRoute
            ? true
            : false,
        container: props => EventAdminCampaignSettingsTransactionsTabRoute
      }
    ];

    if (location.pathname.indexOf('/settings/transactions/donationreceipt') >= 0) {
      checkAuthorization(
        { campaign: campaign.id },
        [
          [
            'permissions.campaign.plugin.taxReceipt.config.update',
            'roles.platform.OWNER',
            () =>
              isPluginActive(this.props.platform, 'TaxReceipt') &&
              this.props.organization.typeId === 2
                ? true
                : false
          ], // ||
          [
            'permissions.campaign.plugin.taxReceipt.config.update',
            'roles.platform.SUPERADMIN',
            () =>
              isPluginActive(this.props.platform, 'TaxReceipt') &&
              this.props.organization.typeId === 2
                ? true
                : false
          ]
        ],
        null,
        `/${locale}/d`,
        history
      );
    }

    return (
      <Layout className="CampaignsSettingsTransactionsPage">
        <Helmet>
          <title>{t('Transactions')}</title>
        </Helmet>
        <AdminTitleBar title={t('Transactions')} />
        <ResponsiveTabs pages={tabPages} parentPage={props} defaultTabHasNoSlug />
      </Layout>
    );
  }
}

export default translate('CampaignsSettingsTransactionsPage')(CampaignsSettingsTransactionsPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import noop from 'lodash/noop';

import './ResultThumbnailList.less';

class ResultThumbnailList extends React.Component {

  render() {
    const cards = this.props.dataSource.map(record => (
      <li
        key={record[this.props.rowKey]}
        onClick={this.props.onThumbnailClick.bind(this, record)}
      >
        {this.props.renderRecord(record)}
      </li>
    ));

    const newRecord = this.props.newRecord ? (
      <li className="ResultThumbnailList__NewRecord" onClick={this.props.onNewRecordClick}>
        {this.props.newRecord}
      </li>
    ) : null;

    const noResult = !this.props.newRecord && !this.props.dataSource.length && this.props.cta ? (
      this.props.cta
    ) : null;

    return (
      <div className="ResultThumbnailList">
        <ul className="ResultThumbnailList__List">
          {cards}
          {newRecord}
          {noResult}
        </ul>
        {(this.props.pagination && this.props.onChange && this.props.pagination.pageSize < this.props.pagination.total) &&
          <Pagination className="ResultThumbnailList__Pagination" onChange={this.props.onChange} current={this.props.pagination.current} pageSize={this.props.pagination.pageSize} total={this.props.pagination.total}  />
        }
      </div>
    );
  }

}

ResultThumbnailList.defaultProps = {
  onThumbnailClick: noop,
  onNewRecordClick: noop,
  newRecord: null
};

ResultThumbnailList.propTypes = {
  rowKey: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  renderRecord: PropTypes.func.isRequired,
  newRecord: PropTypes.element,
  onThumbnailClick: PropTypes.func,
  onNewRecordClick: PropTypes.func
};

export default ResultThumbnailList;

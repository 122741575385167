import {
  requestApiGet,
  requestApiPost,
  requestApiPut,
  requestApiDelete
} from 'Parts/common/requestUtils';

import {
  notification
} from 'antd';

import i18next from 'i18next';

export const FETCH_ALGOLIA_TOKEN_START = 'FETCH_ALGOLIA_TOKEN_START';
export const FETCH_ALGOLIA_TOKEN_SUCCESS = 'FETCH_ALGOLIA_TOKEN_SUCCESS';
export const FETCH_ALGOLIA_TOKEN_ERROR = 'FETCH_ALGOLIA_TOKEN_ERROR';

export const FETCH_ALGOLIA_SAVED_FILTERS_START = 'FETCH_ALGOLIA_SAVED_FILTERS_START';
export const FETCH_ALGOLIA_SAVED_FILTERS_SUCCESS = 'FETCH_ALGOLIA_SAVED_FILTERS_SUCCESS';
export const FETCH_ALGOLIA_SAVED_FILTERS_ERROR = 'FETCH_ALGOLIA_SAVED_FILTERS_ERROR';

export const SAVE_ALGOLIA_FILTER_START = 'SAVE_ALGOLIA_FILTER_START';
export const SAVE_ALGOLIA_FILTER_SUCCESS = 'SAVE_ALGOLIA_FILTER_SUCCESS';
export const SAVE_ALGOLIA_FILTER_ERROR = 'SAVE_ALGOLIA_FILTER_ERROR';

export const DELETE_ALGOLIA_FILTER_START = 'DELETE_ALGOLIA_FILTER_START';
export const DELETE_ALGOLIA_FILTER_SUCCESS = 'DELETE_ALGOLIA_FILTER_SUCCESS';
export const DELETE_ALGOLIA_FILTER_ERROR = 'DELETE_ALGOLIA_FILTER_ERROR';

const algoliaFiltersUrl = '/tools/algolia/platforms/';

// ALGOLIA TOKEN
export function getAlgoliaToken(platformId, index) {
  return dispatch => {
    dispatch({
      type: FETCH_ALGOLIA_TOKEN_START
    });

    let params = {
      'platformId': platformId,
      'index': index
    };

    return requestApiPost(`/tools/algolia/securedKey`, params)
      .then(result => {
        return dispatch({
          type: FETCH_ALGOLIA_TOKEN_SUCCESS,
          payload: result
        })
      })
      .catch(error => {
        return dispatch({
          type: FETCH_SETTLEMENT_REPORTS_ERROR,
          payload: error
        })
      });
  }
}

// GET SAVED ALGOLIA FILTERS
export function getAlgoliaSavedPlatformFilters(platformId) {
  return dispatch => {
    dispatch({
      type: FETCH_ALGOLIA_SAVED_FILTERS_START
    });

    return requestApiGet(`${algoliaFiltersUrl}${platformId}/filters`)
      .then(result => {
        return dispatch({
          type: FETCH_ALGOLIA_SAVED_FILTERS_SUCCESS,
          payload: result
        })
      })
      .catch(error => {
        return dispatch({
          type: FETCH_ALGOLIA_SAVED_FILTERS_ERROR,
          payload: error
        })
      });
  }
}

// SAVE ALGOLIA FILTER
export function saveAlgoliaPlatformFilter(values, platformId) {
  return dispatch => {
    dispatch({
      type: SAVE_ALGOLIA_FILTER_START
    });

    return requestApiPost(`${algoliaFiltersUrl}${platformId}/filters`, values)
      .then(result => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
            type: SAVE_ALGOLIA_FILTER_SUCCESS,
            payload: result
          })
          .then(
            dispatch(getAlgoliaSavedPlatformFilters(platformId))
          )
      })
      .catch(error => {
        return dispatch({
          type: SAVE_ALGOLIA_FILTER_ERROR,
          payload: error
        })
      })
  }
}

// DELETE ALGOLIA FILTER
export function deleteAlgoliaPlatformFilter(platformId, filterId) {
  return dispatch => {
    dispatch({
      type: DELETE_ALGOLIA_FILTER_START
    });

    return requestApiDelete(`${algoliaFiltersUrl}${platformId}/filters/${filterId}`)
      .then(result => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
            type: DELETE_ALGOLIA_FILTER_SUCCESS,
            payload: result
          })
          .then(
            dispatch(getAlgoliaSavedPlatformFilters(platformId))
          )
      })
      .catch(error => {
        return dispatch({
          type: DELETE_ALGOLIA_FILTER_ERROR,
          payload: error
        })
      })
  }
}

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from "../../../parts/chore/dataFetcher";
import CampaignsTeamsPage from './CampaignsTeamsPage';

import { checkAuthorization } from 'Parts/common/authorizationUtils';

const mapStateToProps = state => ({
  campaign: state.campaign.campaign,
  team: state.team.team,
  locale: state.session.language,
  participants: state.participant.participants.results,
  pagination: state.participant.participants.pagination,
  platformId: state.platform.platform.id
});

const mapDispatchToProps = dispatch => bindActionCreators({
  checkAuthorization
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(CampaignsTeamsPage);

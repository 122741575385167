import React from 'react';
import { translate } from 'react-i18next';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SessionExpirationMessage extends React.Component {

  constructor(props) {
    super(props);

    this.message = null;
  }

  componentDidUpdate(previousProps) {
    if (previousProps.displaySessionTimeoutMessage !== this.props.displaySessionTimeoutMessage) {
      if (this.props.displaySessionTimeoutMessage) {
        //this.message = message.info(this.props.t('SESSION:EXPIRATION.KICKEDOUT.message'), 0);
        this.message = message.info({
          content: this.props.t('SESSION:EXPIRATION.KICKEDOUT.message'),
          duration: 0,
          icon: (<FontAwesomeIcon icon={['fal', 'info-circle']} style={{ marginRight: "10px" }} />),
          className: "kickedout-message",
          prefixCls: "kickedout-message",
        });
      } else
      if (this.message) {
        this.message();
      }
    }
  }

  render() {
    return <></>;
  }
}

SessionExpirationMessage.defaultProps = {
  displaySessionTimeoutMessage: false
};

export default translate('SessionExpirationMessage')(SessionExpirationMessage);

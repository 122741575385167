import {
  has,
  get,
  isEmpty
} from 'lodash';

import {
  fetchMemberPermissions,
  fetchMemberOrganizationPermissions,
  fetchMemberPlatformPermissions,
  fetchMemberCampaignPermissions,
  fetchMemberTeamPermissions,
  fetchMemberRoles,
  fetchMemberOrganizationRoles,
  fetchMemberPlatformRoles,
  fetchMemberCampaignRoles,
  fetchMemberTeamRoles,
} from '../session/sessionActions';

export function checkAuthorization(ids = {}, rules = [], me = null, redirect = null, history = null) {
  // ----->
  // ids = (object) {
  //   organization: (int) # || null,
  //   platform: (int) # || null,
  //   campaign: (int) # || null,
  //   team: (int) # || null,
  //   member: (int) # || null,
  //   user: (int) # || null,
  // }
  // rules = (array) [
  //   (array) [(string) "", (string) ""],
  //   (array) [(string) "", (string) ""]
  // ]
  // me = (bool) true || false
  // redirect = (string) ""
  // history = (object) {replace: (func) () => {...}} (this.props.history.replace provided by withRouter)

  return (dispatch, getState) => {
    if (typeof window === 'undefined') return null;

    let authorized = null;
    let check = null;

    // ----->
    // me =
    // true : Only the user can see his own information.
    // false : The user can't see his own information.
    // <-----
    if (authorized !== false && me !== null) {
      check = checkMe(ids, me);
      authorized = check !== null ? check : authorized;
    }

    // ----->
    // rules =
    // [["",""],[["","",""],["","",""]]] : array of arrays of strings (rules)
    // -> [[],[]] : If one of the array in the arrays is true, the array is true (||).
    // -> ["", ""] : If one of the string (rule) is false, the array is false (&&).
    // ***NEW*** -> ["", "", () => true || false] : function can be sent as rule.
    // <-----
    if (authorized !== false && rules.length) {
      check = checkLevel(ids, rules, dispatch, getState);
      authorized = check !== null ? check : authorized;
    }

    // --> Protection against no conditions parameters
    if (
      authorized === null &&
      rules.length === 0 &&
      me === null
    ) {
      authorized = true;
    }

    if (redirect && history && authorized === false) {
      history.replace(redirect);
    }

    return authorized;
  }
}

function getId(ids, category) {
  return ids[category] || null;
}

function checkMe(ids, me) {
  // ----->
  // me =
  // => true : Only the user can see his own information.
  // => false : The user can't see his own information.
  // ids = {participantMember, member, user} : available ids to check
  // <-----

  let authorized = null;
  if (
    getId(ids, 'member') && getId(ids, 'user') && (
      me === true && getId(ids, 'member') === getId(ids, 'user') ||
      me === false && getId(ids, 'member') !== getId(ids, 'user')
    )
  ) {
    authorized = true;
  } else
  if (
    getId(ids, 'member') && getId(ids, 'user') && (
      me === true && getId(ids, 'member') !== getId(ids, 'user') ||
      me === false && getId(ids, 'member') === getId(ids, 'user')
    )
  ) {
    authorized = false;
  }

  return authorized;
}

function checkLevel(ids, rules, dispatch, getState) {
  // ----->
  // rules =
  // [["",""],[["","",""],["","",""]]] : array of arrays of strings (rules)
  // -> [[],[]] : If one of the array in the arrays is true, the array is true (||).
  // -> ["", ""] : If one of the string (rule) is false, the array is false (&&).
  // (&&)
  // [null,  null] = null
  // [null,  true] = null
  // [null,  false] = false
  // [true,  false] = false
  // [false, false] = false
  // [true,  true] = true
  // (||)
  // [[null],  [null]] = null
  // [[null],  [false]] = null
  // [[null],  [true]] = true
  // [[true],  [true]] = true
  // [[true],  [false]] = true
  // [[false], [false]] = false
  // <-----

  let authorized, nulled;

  if (Array.isArray(rules[0])) { // || (or/ou)
    authorized = false;
    nulled = false;

    for (let rule of rules) {
      authorized = checkLevel(ids, rule, dispatch, getState);

      if (authorized === null)
        nulled = true;

      if (authorized === true)
        break;
    }

    if (authorized === false && nulled)
      authorized = null;
  } else { // && (and/et)
    authorized = true;
    nulled = false;

    for (let rule of rules) {
      authorized = checkRule(ids, rule, dispatch, getState);

      if (authorized === null)
        nulled = true;

      if (authorized === false)
        break;
    }

    if (authorized === true && nulled)
      authorized = null;
  }

  if (authorized !== true && authorized !== false) return null;
  return authorized;
}

function checkRule(ids, rule, dispatch, getState) {
  if (typeof rule === 'function') {
    // *** If the rule is a function. Make sure to return null if values are not loaded yet. *** //
    return rule();
  }

  const session = getState().session;
  const permissions = get(session, 'permissions', {});
  const permissionsIds = get(session, 'permissionsIds', {});
  const roles = get(session, 'roles', {});
  const rolesIds = get(session, 'rolesIds', {});
  const userTypeId = get(session, 'userTypeId');

  ids.user = get(session, 'userId', null);

  const statements = rule && rule.split('.') || [];

  let hasRule = [...statements];
  hasRule.shift();
  hasRule.pop();
  hasRule = hasRule.join('.');

  let getRule = [...statements];
  getRule.shift();
  getRule = getRule.join('.');

  let authorized = null;

  if (statements[0] === 'permissions') {
    if (
      // The permissions category exist
      get(permissions, statements[1], null) &&
      // The permissions object isn't empty
      !isEmpty(get(permissions, statements[1])) &&
      // The user is the same as the previous check
      getId(ids, 'user') == getId(permissionsIds, 'user') &&
      (
        // There is no ID (global)
        getId(ids, statements[1]) === null || // * OR *
        // The category ID is the as the previous check
        getId(ids, statements[1]) == get(permissionsIds, statements[1], null)
      )
    ) {
      authorized = get(permissions, getRule, false);

      // In PLUGIN case, get will return an object. ex: permissions.platform.plugin = {taxReceipt:{...}}
      if (authorized !== false && authorized !== true && typeof authorized === 'object') {
        // authorized is true if the object is not empty
        authorized = !isEmpty(authorized);
      }
    } else
    if (!getInProgress(statements, session)) {
      if (userTypeId === 1) return false;
      return fetchPermissions(ids, dispatch, statements[1], getRule);
    }
  } else
  if (statements[0] === 'roles') {
    if (
      // The roles category exist
      get(roles, hasRule, null) &&
      // The role object isn't empty
      !isEmpty(get(roles, hasRule)) &&
      // The user is the same as the previous check
      getId(ids, 'user') == getId(rolesIds, 'user') &&
      (
        // There is no ID (global)
        getId(ids, statements[1]) === null || // * OR *
        // The role object isn't empty and user is member
        getId(ids, statements[1]) == get(rolesIds, statements[1], null)
      )
    ) {
      authorized = get(roles, getRule, false);
    } else
    if (!getInProgress(statements, session)) {
      if (userTypeId === 1) return false;
      return fetchRoles(ids, dispatch, statements[1], getRule);
    }
  }

  return authorized;
}

function fetchPermissions(ids, dispatch, category, rule) {
  let fetch;

  switch (category) {
    case 'global':
      fetch = fetchMemberPermissions;
      break;
    case 'organization':
      fetch = fetchMemberOrganizationPermissions;
      break;
    case 'platform':
      fetch = fetchMemberPlatformPermissions;
      break;
    case 'campaign':
      fetch = fetchMemberCampaignPermissions;
      break;
    case 'team':
      fetch = fetchMemberTeamPermissions;
      break;
  }

  const id = getId(ids, category);
  const userId = getId(ids, 'user', null);

  if (fetch && id) {
    rule = rule.split(category + '.').join('');
    dispatch(fetch(id, userId))
      .then(permissions => get(permissions.payload, rule, false))
      .catch(error => false);
  } else
  if (fetch && category === 'global') {
    rule = rule.split(category + '.').join('');
    dispatch(fetch(userId))
      .then(permissions => get(permissions.payload, rule, false))
      .catch(error => false);
  }

  return null;
}

function fetchRoles(ids, dispatch, category, rule) {
  let fetch;

  switch (category) {
    case 'global':
      fetch = fetchMemberRoles;
      break;
    case 'organization':
      fetch = fetchMemberOrganizationRoles;
      break;
    case 'platform':
      fetch = fetchMemberPlatformRoles;
      break;
    case 'campaign':
      fetch = fetchMemberCampaignRoles;
      break;
    case 'team':
      fetch = fetchMemberTeamRoles;
      break;
  }

  const id = getId(ids, category);
  const userId = getId(ids, 'user', null);

  if (fetch && id) {
    rule = rule.split(category + '.').join('');
    dispatch(fetch(id, userId))
      .then(roles => get(roles.payload, rule, false))
      .catch(error => false);
  } else
  if (fetch && category === 'global') {
    rule = rule.split(category + '.').join('');
    dispatch(fetch(userId))
      .then(roles => get(roles.payload, rule, false))
      .catch(error => false);
  }

  return null;
}

function getInProgress(statements, session) {
  switch (statements[0]) {
    case 'permissions':
      switch (statements[1]) {
        case 'global':
          return session.isFetchMemberPermissionsInProgress;
        case 'organization':
          return session.isFetchMemberOrganizationPermissionsInProgress;
        case 'platform':
          return session.isFetchMemberPlatformPermissionsInProgress;
        case 'campaign':
          return session.isFetchMemberCampaignPermissionsInProgress;
        case 'team':
          return session.isFetchMemberTeamPermissionsInProgress;
      }
      case 'roles':
        switch (statements[1]) {
          case 'global':
            return session.isFetchMemberRolesInProgress;
          case 'organization':
            return session.isFetchMemberOrganizationRolesInProgress;
          case 'platform':
            return session.isFetchMemberPlatformRolesInProgress;
          case 'campaign':
            return session.isFetchMemberCampaignRolesInProgress;
          case 'team':
            return session.isFetchMemberTeamRolesInProgress;
        }
        default:
          return false;
  }
}

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import SliderCreateForm from './SliderCreateForm';

const mapStateToProps = state => ({
 locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default compose(
 connect(mapStateToProps, mapDispatchToProps),
 Form.create()
)(SliderCreateForm);
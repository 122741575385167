import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { updateCampaign } from '../../campaign/campaignActions';

import LanguageBoxForm from './LanguageBoxForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  campaign: state.campaign.campaign
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateCampaign
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(LanguageBoxForm);

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { Form, message } from 'antd';
import sanitizeHTML from 'sanitize-html';
import isBrowser from 'is-in-browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FundkyTooltip from '../FundkyTooltip';

import Wysiwyg_en from './locales/Wysiwyg_en.json';
import Wysiwyg_fr from './locales/Wysiwyg_fr.json';

import './Wysiwyg.less';

export function wrapText(text) {
  if (text && typeof text === 'string') {
    const badEnding = ['<p></p>', '<p><br></p>', '<p><br/></p>'];

    // Remove trailing carriage return and/or nexline generated by the OS
    text = text.replace(/\n$|\r$|\n\r$|\r\n$/, '');

    if (!text.startsWith('<') && !text.endsWith('>'))
      text = '<p>' + text.trim() + '</p>';

    badEnding.forEach(end => {
      while (text.endsWith(end)) {
        text = text.substring(0, text.length - end.length);
      }
    });

    const lastSpace = [
      '</p>',
      '</h1>',
      '</h2>',
      '</h3>',
      '</h4>',
      '</h5>',
      '</h6>',
      '</ul>',
      '</ol>',
      '</span>'
    ];

    lastSpace.forEach(tag => {
      while (text.endsWith(' ' + tag)) {
        text = text.substring(0, text.length - (tag.length + 1)) + tag;
      }
    });
  }

  return text || '';
}

function testImage(url, timeoutT) {
  return new Promise(function (resolve, reject) {
    var timeout = timeoutT || 5000;
    var timer, img = new Image();
    img.onerror = img.onabort = function () {
        clearTimeout(timer);
        reject("error");
    };
    img.onload = function () {
        clearTimeout(timer);
        resolve("success");
    };
    timer = setTimeout(function () {
        // reset .src to invalid URL so it stops previous
        // loading, but doesn't trigger new load
        img.src = "//!!!!/test.jpg";
        reject("timeout");
    }, timeout);
    img.src = url;
});
}

class Wysiwyg extends React.Component {
  constructor(props) {
    super(props);

    const length = this.getTextLength(props.initialValue);
    const lengthPercent =
      length && props.maxlength ? (length / props.maxlength) * 100 : 0;

    this.state = {
      length: length,
      lengthPercent: lengthPercent,
      radius:
        lengthPercent >= 100
          ? 'radius2'
          : lengthPercent > 0
            ? 'radius3'
            : 'radius4'
    };

    this.format = [
      'header',
      'size',
      'bold',
      'italic',
      'underline',
      'list',
      'bullet',
      'indent',
      'link',
      'color'
    ];

    // Are headings allowed in toolbar
    this.headings = props.allowHeadings
      ? [{ header: 1 }, { header: 2 }, { header: 3 }]
      : null;

    // Are sizes allowed in toolbar
    this.sizes = props.allowSizes ? [{ size: [] }] : null;

    // Are colors allowed
    this.colors = props.allowColors
      ? [
          {
            color: [
              props.accentColors && props.accentColors.primary || '',
              props.accentColors && props.accentColors.secondary || '',
              'rgb(  0,   0,   0)',
              'rgb(230,   0,   0)',
              'rgb(255, 153,   0)',
              'rgb(255, 255,   0)',
              'rgb(  0, 138,   0)',
              'rgb(  0, 102, 204)',
              'rgb(153,  51, 255)',
              'rgb(255, 255, 255)',
              'rgb(250, 204, 204)',
              'rgb(255, 235, 204)',
              'rgb(255, 255, 204)',
              'rgb(204, 232, 204)',
              'rgb(204, 224, 245)',
              'rgb(235, 214, 255)',
              'rgb(187, 187, 187)',
              'rgb(240, 102, 102)',
              'rgb(255, 194, 102)',
              'rgb(255, 255, 102)',
              'rgb(102, 185, 102)',
              'rgb(102, 163, 224)',
              'rgb(194, 133, 255)',
              'rgb(136, 136, 136)',
              'rgb(161,   0,   0)',
              'rgb(178, 107,   0)',
              'rgb(178, 178,   0)',
              'rgb(  0,  97,   0)',
              'rgb(  0,  71, 178)',
              'rgb(107,  36, 178)',
              'rgb( 68,  68,  68)',
              'rgb( 92,   0,   0)',
              'rgb(102,  61,   0)',
              'rgb(102, 102,   0)',
              'rgb(  0,  55,   0)',
              'rgb(  0,  41, 102)',
              'rgb( 61,  20,  10)'
            ]
          }
        ]
      : null;

    // Are lists allowed in toolbar
    this.lists = props.allowLists ? [{ list: 'ordered' }, { list: 'bullet' }] : null;

    // Is alignment allowed in toolbar
    this.alignment = props.allowAlignments ? [{ align: [] }] : null;

    // Are links allowed in toolbar
    this.links = props.allowLinks ? ['link'] : [];

    // Are imagbes allowed in toolbar
    this.images = props.allowImages ? ['image'] : [];

    // Are videos allowed in toolbar
    this.videos = props.allowVideos ? ['video'] : [];

    this.container = [
      this.headings,
      ['bold', 'italic', 'underline'],
      [{ script: 'sub' }, { script: 'super' }],
      this.sizes,
      this.colors,
      this.lists,
      this.alignment,
      this.links || this.images || this.videos
        ? [
            ...this.links,
            ...this.images,
            ...this.videos
          ]
        : null,
      ['clean']
    ].filter(obj => obj);

    this.modules = {
      toolbar: {
        container: this.container,
        handlers: {
          image: function() {
            var range = this.quill.getSelection();
            var value = prompt(props.t('image-url'));
            if (
              value && (
                value.endsWith('.jpg') ||
                value.endsWith('.jpeg') ||
                value.endsWith('.gif') ||
                value.endsWith('.png') ||
                value.endsWith('.svg')
              )
            ) {
              testImage(value)
                .then(response => {
                  if (response === 'success') {
                    this.quill.insertEmbed(range.index, 'image', value);
                  }
                })
                .catch(error => {
                  console.error(error);
                });
            }
          }
        }
      },
      ...(isBrowser && this.props.allowHTML
        ? { htmlEditButton: {} }
        : {})
    };

    this.format = [
      'header',
      'size',
      'bold',
      'italic',
      'underline',
      'list',
      'bullet',
      'indent',
      'link',
      'color'
    ];

    // Are headings allowed in toolbar
    this.headings = props.allowHeadings
      ? [{ header: 1 }, { header: 2 }, { header: 3 }]
      : null;

    // Are sizes allowed in toolbar
    this.sizes = props.allowSizes ? [{ size: [] }] : null;

    // Are colors allowed
    this.colors = props.allowColors
      ? [
          {
            color: [
              props.accentColors.primary,
              props.accentColors.secondary,
              'rgb(  0,   0,   0)',
              'rgb(230,   0,   0)',
              'rgb(255, 153,   0)',
              'rgb(255, 255,   0)',
              'rgb(  0, 138,   0)',
              'rgb(  0, 102, 204)',
              'rgb(153,  51, 255)',
              'rgb(255, 255, 255)',
              'rgb(250, 204, 204)',
              'rgb(255, 235, 204)',
              'rgb(255, 255, 204)',
              'rgb(204, 232, 204)',
              'rgb(204, 224, 245)',
              'rgb(235, 214, 255)',
              'rgb(187, 187, 187)',
              'rgb(240, 102, 102)',
              'rgb(255, 194, 102)',
              'rgb(255, 255, 102)',
              'rgb(102, 185, 102)',
              'rgb(102, 163, 224)',
              'rgb(194, 133, 255)',
              'rgb(136, 136, 136)',
              'rgb(161,   0,   0)',
              'rgb(178, 107,   0)',
              'rgb(178, 178,   0)',
              'rgb(  0,  97,   0)',
              'rgb(  0,  71, 178)',
              'rgb(107,  36, 178)',
              'rgb( 68,  68,  68)',
              'rgb( 92,   0,   0)',
              'rgb(102,  61,   0)',
              'rgb(102, 102,   0)',
              'rgb(  0,  55,   0)',
              'rgb(  0,  41, 102)',
              'rgb( 61,  20,  10)'
            ]
          }
        ]
      : null;

    // Are lists allowed in toolbar
    this.lists = props.allowLists ? [{ list: 'ordered' }, { list: 'bullet' }] : null;

    // Is alignment allowed in toolbar
    this.alignment = props.allowAlignments ? [{ align: [] }] : null;

    // Are links allowed in toolbar
    this.links = props.allowLinks ? ['link'] : [];

    // Are imagbes allowed in toolbar
    this.images = props.allowImages ? ['image'] : [];

    // Are videos allowed in toolbar
    this.videos = props.allowVideos ? ['video'] : [];

    this.container = [
      this.headings,
      ['bold', 'italic', 'underline'],
      [{ script: 'sub' }, { script: 'super' }],
      this.sizes,
      this.colors,
      this.lists,
      this.alignment,
      this.links || this.images || this.videos
        ? [
            ...this.links,
            ...this.images,
            ...this.videos
          ]
        : null,
      ['clean']
    ].filter(obj => obj);

    this.modules = {
      toolbar: {
        container: this.container,
        handlers: {
          image: function() {
            var range = this.quill.getSelection();
            var value = prompt(props.t('image-url'));
            if (
              value && (
                value.endsWith('.jpg') ||
                value.endsWith('.jpeg') ||
                value.endsWith('.gif') ||
                value.endsWith('.png') ||
                value.endsWith('.svg')
              )
            ) {
              testImage(value)
                .then(response => {
                  if (response === 'success') {
                    this.quill.insertEmbed(range.index, 'image', value);
                  }
                })
                .catch(error => {
                  console.error(error);
                });
            }
          }
        }
      }
    };

    // If is browser, -> Init Quill
    if (isBrowser) {
      this.initQuill();
    }

    i18next.addResourceBundle('en', 'Wysiwyg', Wysiwyg_en);
    i18next.addResourceBundle('fr', 'Wysiwyg', Wysiwyg_fr);
  }

  /* LIFECYCLE EVENTS ************************************************************************************************** */

  componentDidMount() {
    // Init Quill if it isn't
    if (!this.ReactQuill) {
      this.initQuill();
    }

    if (this.props.initialValue) {
      this.props.form.validateFields([this.props.fieldId]);
    }
  }

  componentDidUpdate(prevProps) {
    // Reset form if initialValue/contentLang changes
    if (prevProps.initialValue !== this.props.initialValue || prevProps.contentLang !== this.props.contentLang) {
      this.props.form.resetFields([this.props.fieldId]);
      this.handleTextLength(this.getTextLength(this.props.initialValue));
    }
  }

  /* METHODS ***************************************************************************************************** */

  initQuill = () => {
    this.ReactQuill = require('react-quill');

    const _self = this;

    const Quill = this.ReactQuill.Quill;

    if (this.props.allowHTML) {
      this.QuillHtmlEditButton = require('quill-html-edit-button');
      const htmlEditButton = this.QuillHtmlEditButton.htmlEditButton
      Quill.register({
        "modules/htmlEditButton": htmlEditButton
      });
    }

    // ICONS
    var icons = Quill.import('ui/icons');

    // HEADING ICONS
    icons['header']['1'] = '<i class="far fa-h1" aria-hidden="true"></i>';
    icons['header']['2'] = '<i class="far fa-h2" aria-hidden="true"></i>';
    icons['header']['3'] = '<i class="far fa-h3" aria-hidden="true"></i>';

    const BlockEmbed = Quill.import("blots/block/embed");

    class VideoBlot extends BlockEmbed {
      static create(url) {
        if (
          typeof url === 'string' && (
          url.indexOf('https://www.youtube.com/embed/') === 0 ||
          url.indexOf('https://player.vimeo.com/video/') === 0)
        ) {
          let node = super.create(url);
          let iframe = document.createElement('iframe');
          // Set styles for wrapper
          node.setAttribute('class', 'ql-video-wrapper');
          // Set styles for iframe
          iframe.setAttribute('class', 'ql-video');
          iframe.setAttribute('frameborder', '0');
          iframe.setAttribute('allowfullscreen', true);
          iframe.setAttribute('src', url);
          // Append iframe as child to wrapper
          node.appendChild(iframe);
          return node;
        } else {
          if (url && typeof url === 'string' && url !== '') {
            message.error({
              content: (
                <>
                  {_self.props.t('invalid-video')}
                  <div>{_self.props.t('youtube-only')}</div>
                </>
              ),
              icon: (<FontAwesomeIcon icon={['fal', 'times-circle']} className="anticon"/>),
            });
          }
          return super.create();
        }
      }

      static value(domNode) {
        return (domNode && domNode.firstChild && domNode.firstChild.getAttribute) &&
          domNode.firstChild.getAttribute('src') || '';
      }
    }
    VideoBlot.blotName = 'video';
    VideoBlot.tagName = 'div';

    Quill.register(VideoBlot, true);

    // QUILL DEBUG
    // this.ReactQuill.Quill.debug(true);
  };

  // Return text length with/without tags
  getTextLength = text => {
    if (!text || text === '<p><br></p>') return 0;

    if (!this.props.counterWithTag)
      return text.replace(/(<([^>]+)>)/gi, '').length;

    return text.length;
  };

  // Update length, lengthPercent and radius states from text length
  handleTextLength = chars => {
    const { props } = this;
    const { maxlength } = props;

    if (maxlength) {
      const length = chars;
      const lengthPercent =
        length && maxlength ? (length / maxlength) * 100 : 0;

      this.setState({
        length: length,
        lengthPercent: lengthPercent,
        radius:
          lengthPercent >= 100
            ? 'radius2'
            : lengthPercent > 0
              ? 'radius3'
              : 'radius4'
      });
    }
  };

  // Reset state when called (ex: changing content lang)
  resetEditorState = () => {
    this.handleTextLength(this.getTextLength(this.props.initialValue));
  };

  // Return only non-authorized characters in a String.
  getFailedChars = value => {
    // Fetch value directly in the form values
    if (!value) value = this.props.form.getFieldValue(this.props.fieldId);
    if (value && typeof value === 'string') {
      // Remove tags
      value = value.replace(/(<([^>]+)>)/gi, '');
      // Replace encoded tag character by the character
      value = value.replace(/&lt;/g, '<');
      value = value.replace(/&gt;/g, '>');
      return value.replace(
        /[a-zA-Z\u0020-\u0039\u00C0-\u024F\u2018\u2019\u2013\u00ab\u00bb\u00a9\u00b0\u00b1\u00ae\u2015\u221e\u00a1\u00bf\u2014\u201c\u201d\u00ab\u00bb\u2014\u2122\u00a2\u2026~:;_='@?{}\r\n]+/g,
        ''
      );
    }

    return '';
  };

  // Format the non-authorized characters String by removing duplicate and separating by ', '.
  formatFailedChars = chars => {
    const arr = chars.split('');
    chars = arr.filter((char, index) => arr.indexOf(char) === index).join(' ');
    return chars;
  };


  /* RENDER   ***************************************************************************************************** */

  render() {
    const { props, ReactQuill, getTextLength } = this,
      {
        t,
        form,
        className,
        fieldId,
        initialValue,
        required,
        label,
        tooltip,
        disabled,
        maxlengthMessage,
        maxlength,
        requiredMessage,
        onChange
      } = props,
      { getFieldDecorator, validateFields } = form;

    const fieldOptions = {
      initialValue: wrapText(initialValue),
      rules: [
        // *** Tag validation***//
        {
          validator: (rule, value, callback) => {
            if (value) {
              let allTags = sanitizeHTML(value, {
                allowedTags: false,
                allowedAttributes: false
              });
              let wysiwygTags = sanitizeHTML(value, {
                allowedTags: [
                  'h1',
                  'h2',
                  'h3',
                  'em',
                  'p',
                  'strong',
                  'ins',
                  'sup',
                  'sub',
                  'span',
                  'a',
                  'ul',
                  'ol',
                  'li',
                  'br',
                  'u',
                  'iframe',
                  'div',
                  'img'
                ],
                allowedAttributes: {
                  '*': ['style', 'class'],
                  //prettier-ignore
                  'a': ['href', 'target', 'rel', 'style', 'class', 'data-cmp-ab'],
                  'iframe': ['class', 'src', 'frameborder', 'style', 'allowfullscreen', 'data-lf-yt-playback-inspected-lynor8xn0nq4wqjz', 'data-lf-vimeo-playback-inspected-lynor8xn0nq4wqjz', 'data-cmp-ab', 'data-cmp-info'],
                  'img': ['src', 'class', 'style']
                },
                allowedStyles: {
                  '*': {
                    // prettier-ignore
                    'color': [
                      /^\#(0x)?[0-9a-f]+$/i,
                      /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})(,\s*(1|0|(0?\.\d{1,2})))?\s*\)$/,
                      /^var\(.*\)$/
                    ],
                    'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/]
                  },
                  // prettier-ignore
                  'span': {
                    'font-size': [/^[0-9.-]+(?:px|em|%|rem)$/]
                  },
                  'iframe': {
                    'font-size': [/^[0-9.-]+(?:px|em|%|rem)$/],
                    'font-family': [/^[a-zA-Z0-9\-, ]{0,100}$/]
                  },
                  'img': {
                    'font-size': [/^[0-9.-]+(?:px|em|%|rem)$/],
                    'font-family': [/^[a-zA-Z0-9\-, ]{0,100}$/]
                  }
                }
              });
              return allTags === wysiwygTags ? callback() : callback(true);
            }
            return callback();
          },
          message: t('tags')
        },
        //*** Only youtube video ***//
        {
          validator: (rule, value, callback) => {
            const slittedValue = value.split('<iframe');
            let go = true;
            if (slittedValue.length > 1) {
              slittedValue.forEach(_value => {
                if (
                  _value.indexOf('</iframe>') > -1 &&
                  _value.indexOf(' src=') > -1 &&
                  _value.indexOf(' src=') < _value.indexOf('</iframe>') &&
                  _value.indexOf('https://www.youtube.com/embed/') === -1 &&
                  _value.indexOf('https://player.vimeo.com/video/') === -1
                ) {
                  go = false;
                }
              });
            }
            if (value.indexOf('ql-video-invalid') > -1) {
              go = false;
            }
            return go ? callback() : callback(true);
          },
          message: t('youtube-only')
        },
        //*** Maximum characters validation ***//
        {
          validator: (rule, value, callback) => {
            var chars =
              !value || value === '<p><br></p>' ? 0 : getTextLength(value);
            this.handleTextLength(chars);
            return chars <= maxlength ? callback() : callback(true);
          },
          message: maxlengthMessage || t('maxLength', { max: maxlength })
        },
        //*** Required validation ***//
        {
          validator: (rule, value, callback) => {
            if (!required) return callback();
            let chars = 0;
            if (value) {
              chars += value.length;
            }
            return chars > 0 ? callback() : callback(true);
          },
          message: requiredMessage || t('required')
        },
        {
          validator: (rule, value, callback) =>
            this.getFailedChars(value).length ? callback(true) : callback(),
          message: t('validation', {
            chars: this.formatFailedChars(this.getFailedChars())
          })
        }
      ]
    };

    const counterBar = maxlength ? (
      <div className="Wysiwyg__CounterBarContainer">
        <div
          className="Wysiwyg__CounterBar"
          style={{ width: this.state.lengthPercent + '%' }}
        ></div>
      </div>
    ) : null;

    return (
      <Form.Item
        className={`Wysiwyg ${this.state.radius} ${className || ''}`}
        hasFeedback
        label={
          label && (
            <span>
              {label}
              {tooltip && ' :'}
              {tooltip && <FundkyTooltip title={tooltip} />}
            </span>
          )
        }
        colon={tooltip ? false : true}
      >
        {isBrowser && ReactQuill ? (
          getFieldDecorator(
            fieldId,
            fieldOptions
          )(
            <ReactQuill
              className={`wysiwygStyle ${disabled ? 'disabled' : ''}`}
              modules={this.modules}
              format={this.format}
              readOnly={disabled}
              onChange={onChange}
              onKeyUp={() => {
                validateFields([fieldId], { force: true }, () => { });
              }}
            />
          )
        ) : (
            <FontAwesomeIcon icon={['fal', 'spinner']} spin size="2x" />
          )}
        {isBrowser && ReactQuill && counterBar}
      </Form.Item>
    );
  }
}

Wysiwyg.defaultProps = {
  initialValue: null,
  className: null,
  fieldId: 'Wysiwyg',
  label: null,
  tooltip: null,
  required: true,
  requiredMessage: null,
  maxlength: 65535,
  counterWithTag: true,
  maxlengthMessage: null,
  taglessMessage: null,
  allowHeadings: true,
  allowColors: true,
  allowSizes: true,
  allowLists: true,
  allowAlignments: true,
  allowLinks: true,
  allowImages: true,
  allowVideos: true,
  allowHTML: false,
  disabled: false,
  onChange: () => { }
};

Wysiwyg.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  fieldId: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  toolbar: PropTypes.object,
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  maxlength: PropTypes.number,
  counterWithTag: PropTypes.bool,
  maxlengthMessage: PropTypes.string,
  taglessMessage: PropTypes.string,
  allowHeadings: PropTypes.bool,
  allowColors: PropTypes.bool,
  allowSizes: PropTypes.bool,
  allowLists: PropTypes.bool,
  allowAlignments: PropTypes.bool,
  allowLinks: PropTypes.bool,
  allowVideos: PropTypes.bool,
  allowHTML: PropTypes.bool,
  disabled: PropTypes.bool,
  accentColors: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default translate('Wysiwyg', { withRef: true })(Wysiwyg);

import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { get } from 'lodash';

import dataFetcher from "../../parts/chore/dataFetcher";
import { searchTransactions, clearSearch } from '../../search/searchActions';
import { reportTransactions } from '../../report/reportActions';
import { checkAuthorization } from 'Parts/common/authorizationUtils';

import TransactionsListPage from './TransactionsListPage';

const mapStateToProps = state => ({
  platformId: get(state, 'platform.platform.id'),
  platformAlias: get(state, 'platform.platform.alias', ''),
  transactions: get(state, 'search.transactions.results', []),
  pagination: get(state, 'search.transactions.pagination', {}),
  filters: get(state, 'search.transactions.filterBy', {}),
  search: get(state, 'search.transactions.search', null),
  orderBy: get(state, 'search.transactions.orderBy', {}),
  locale: get(state, 'session.language'),
  report: get(state, 'report.transactions')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchTransactions,
  reportTransactions,
  clearSearch,
  checkAuthorization
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(TransactionsListPage);

import React from 'react';
import { Layout, Icon } from 'antd';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import AdminSidebarDashboard from './Sidebars/AdminSidebarDashboard';
import AdminSidebarCampaigns from './Sidebars/AdminSidebarCampaignsContainer';
import AdminSidebarCampaignsSettings from './Sidebars/AdminSidebarCampaignsSettingsContainer';
// import AdminSidebarMembers from './Sidebars/AdminSidebarMembers';
import AdminSidebarPages from './Sidebars/AdminSidebarPages';
import AdminSidebarSettings from './Sidebars/AdminSidebarSettings';
import AdminSidebarPlugins from './Sidebars/AdminSidebarPluginsContainer';
import AdminSidebarTransactions from './Sidebars/AdminSidebarTransactions';
import Plugin from '../../plugin/Plugin';

import { isPluginActive } from '../../plugin/pluginActions';

import './AdminSider.less';

import 'Parts/assets/images/logo_fundky_white@2x.png';

class AdminSider extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { path, platform } = this.props;

    const routes = [
      {
        path: path,
        component: AdminSidebarDashboard,
        exact: true,
      },
      { path: path + '/pages', component: AdminSidebarPages, exact: false },
      { path: path + '/settings/', component: AdminSidebarSettings, exact: false },
      { path: path + '/campaigns/list', component: AdminSidebarDashboard, exact: true },
      {
        path: path + '/campaigns/:campaignID/settings/registration',
        component: AdminSidebarCampaignsSettings,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/settings/transactions',
        component: AdminSidebarCampaignsSettings,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/settings/advanced',
        component: AdminSidebarCampaignsSettings,
        exact: true,
      },
      /* { path: path+'/campaigns/:campaignID/settings/emails',
        component: AdminSidebarCampaignsSettings,
        exact: true
      }, */
      {
        path: path + '/campaigns/:campaignID/settings/transactions/donationreceipt',
        component: AdminSidebarCampaignsSettings,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/settings/transactions/tickets',
        component: AdminSidebarCampaignsSettings,
        exact: true,
      },
      { path: path + '/campaigns/:campaignID', component: AdminSidebarCampaigns, exact: true },
      {
        path: path + '/campaigns/:campaignID/tickets',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/history',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/teams/list',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/teams/:teamID',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/teams/:teamID/registration',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/teams/:teamID/history',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/participants/list',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/participants/:participantID/history',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/participants/:participantID',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/transactions/list/donations',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/transactions/list/tickets',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/transactions/:transactionID/history',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/transactions/:transactionID/donationreceipt',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      {
        path: path + '/campaigns/:campaignID/transactions/:transactionID',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      { path: path + '/members/list', component: AdminSidebarDashboard, exact: true },
      {
        path: path + '/members/:memberID/information',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/members/:memberID/donations',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/members/:memberID/participations',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/members/:memberID/contributions',
        component: AdminSidebarDashboard,
        exact: true,
      },
      { path: path + '/plugins', component: AdminSidebarPlugins, exact: true },
      { path: path + '/plugins/', component: AdminSidebarPlugins, exact: false },
      { path: path + '/transactions/list', component: AdminSidebarTransactions, exact: true },
      { path: path + '/transactions/add', component: AdminSidebarTransactions, exact: true },
      {
        path: path + '/transactions/withdrawal-report',
        component: AdminSidebarTransactions,
        exact: true,
      },
      {
        path: path + '/transactions/reconciliation',
        component: AdminSidebarTransactions,
        exact: true,
      },
      {
        path: path + '/transactions/:transactionID/donationreceipt',
        component: AdminSidebarTransactions,
        exact: true,
      },
      {
        path: path + '/transactions/:transactionID/invoices',
        component: AdminSidebarTransactions,
        exact: true,
      },
      {
        path: path + '/transactions/:transactionID/history',
        component: AdminSidebarTransactions,
        exact: true,
      },
      {
        path: path + '/transactions/:transactionID',
        component: AdminSidebarTransactions,
        exact: true,
      },
      { path: path + '/teams/list', component: AdminSidebarDashboard, exact: true },
      { path: path + '/teams/:teamID/registration', component: AdminSidebarDashboard, exact: true },
      { path: path + '/teams/:teamID/history', component: AdminSidebarDashboard, exact: true },
      { path: path + '/teams/:teamID/donations', component: AdminSidebarDashboard, exact: true },
      { path: path + '/teams/:teamID', component: AdminSidebarDashboard, exact: true },
      { path: path + '/participants/list', component: AdminSidebarDashboard, exact: true },
      {
        path: path + '/participants/:teamID/history',
        component: AdminSidebarDashboard,
        exact: true,
      },
      { path: path + '/participants/:teamID', component: AdminSidebarDashboard, exact: true },
      {
        path: path + '/contributions/list/donations',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/contributions/list/participations',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/contributions/list/tickets',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/contributions/donations/:donationID/invoices',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/contributions/donations/:donationID',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/contributions/participations/:participantID',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/contributions/list/tickets/:ticketID',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/contributions/teams/:teamID',
        component: AdminSidebarDashboard,
        exact: false,
      },
      {
        path: path + '/profile/:memberID/my-information',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/profile/:memberID/my-contributions',
        component: AdminSidebarDashboard,
        exact: true,
      },
      // PLUGIN EVENT COMMUNICATIONS PAGE
      {
        path: path + '/campaigns/:campaignID/communications',
        component: AdminSidebarCampaigns,
        exact: true,
      },
      // SIDEBAR FROM PLUGINS : Use string as component and add plugin name in "plugin" propertie
      {
        path: path + '/campaigns/:campaignID/attendees/checkin',
        component: 'EventAdminSidebarAttendees',
        exact: true,
        plugin: 'Event',
      },
      {
        path: path + '/campaigns/:campaignID/attendees/:ticketID',
        component: 'EventAdminSidebarAttendees',
        exact: true,
        plugin: 'Event',
      },
      {
        path: path + '/campaigns/:campaignID/attendees/teams/',
        component: 'EventAdminSidebarAttendees',
        exact: false,
        plugin: 'Event',
      },
      {
        path: path + '/campaigns/:campaignID/attendees/participants/',
        component: 'EventAdminSidebarAttendees',
        exact: false,
        plugin: 'Event',
      },
      {
        path: path + '/surveys',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/survey/information',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/survey/:id/information',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/survey/:id/field',
        component: AdminSidebarDashboard,
        exact: true,
      },
      {
        path: path + '/survey/:id/result',
        component: AdminSidebarDashboard,
        exact: true,
      },
    ];

    // We need a combination of the react-router NavLink and the Antd Button... let's use NavLink and put the Antd classes on it.
    return (
      <>
        <Layout.Sider
          className="AdminSider"
          width={290}
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsed={this.state.collapsed}
        >
          <PerfectScrollbar suppressScrollX={true} swipeEasing={true}>
            <NavLink id="navLogo" to={`/${this.props.locale}/d`}>
              <img src="/assets/logo_fundky_white@2x.png" alt="Logo Fundky" width="153" />
            </NavLink>

            <div className="WrapSiders">
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={(props) =>
                      typeof route.component === 'string' ? (
                        isPluginActive(platform, route.plugin) ? (
                          <Plugin
                            {...props}
                            {...this.props}
                            name={route.plugin}
                            component={route.component}
                          />
                        ) : (
                          <Redirect to={path} />
                        )
                      ) : (
                        <route.component {...props} {...this.props} />
                      )
                    }
                  />
                ))}
              </Switch>
            </div>
          </PerfectScrollbar>
        </Layout.Sider>

        <Icon
          className="trigger"
          id="mainmenu_trigger"
          type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={this.toggle}
        />
      </>
    );
  }
}

export default AdminSider;

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEnv } from "../../../../parts/common/environment";

import participantPageUrlFieldEN from './locales/ParticipantPageUrlField_en.json';
import participantPageUrlFieldFR from './locales/ParticipantPageUrlField_fr.json';

import './ParticipantPageUrlField.less';

class ParticipantPageUrlField extends React.Component {

  constructor(props) {
    super(props);

    this.hiddenInput = React.createRef();

    i18next.addResourceBundle('en', 'ParticipantPageUrlField', participantPageUrlFieldEN);
    i18next.addResourceBundle('fr', 'ParticipantPageUrlField', participantPageUrlFieldFR);
  }

  copyUrl = () => {
    const { t } = this.props;
    var $inputValue = this.hiddenInput.current;
    $inputValue.select();
    document.execCommand('copy');
    let messageText = t('URL Copied!');
    message.success({
      content: messageText,
      icon: (<FontAwesomeIcon icon={['fal', 'check-circle']} className="anticon"/>),
      duration: 1
    });
  }

  render() {
    const { t, data, platformAlias, campaignAlias, teamAlias, locale } = this.props;

    const teamUrlPart = (teamAlias !== null) ? teamAlias : t("URL:individual-participant");

    const environment = getEnv();

    // Adding hidden attribute will make it not selectable
    const hiddenInput = (campaignAlias && teamUrlPart && data && locale) ? (
      <input
        className="hiddenInput"
        ref={this.hiddenInput}
        readOnly
        value={`${platformAlias}${environment}.fundkyapp.com/${locale}/${campaignAlias}/${teamUrlPart}/${data}`}
      />
    ) : null;

    return (
      <div className="participant-url ant-row ant-form-item">
        <div className="ant-form-item-label">
          <label className="participant-url-label">{t('Page URL')}</label>
        </div>
        <div className="ant-form-item-control-wrapper">
          <div className="ant-form-item-control">
            <span className="ant-form-item-children">
              {data}
              {hiddenInput}
              <Button className="copy-url" onClick={this.copyUrl}>
                <FontAwesomeIcon icon={["fal", "copy"]} />
              </Button>
            </span>
          </div>
        </div>
      </div>
    );
  }

}

ParticipantPageUrlField.defaultProps = {
  data: 'data',
  campaignAlias: 'campaignAlias',
  teamAlias: null,
  locale: 'en',
};

export default translate('ParticipantPageUrlField')(ParticipantPageUrlField);

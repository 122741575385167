import React from 'react';
import { Form, Popconfirm, Button } from 'antd';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactHtmlParser from 'react-html-parser';
import { pickBy, debounce } from 'lodash';

import SelectField from 'Parts/ui/components/fields/SelectField';
import UploadCropField from 'Parts/ui/components/fields/UploadCropField';
import MultiLangField from 'Parts/ui/components/fields/MultiLangField';
import DescriptionField from 'Parts/ui/components/fields/TextAreaField';
import CheckboxField from 'Parts/ui/components/fields/CheckboxField';
import SubmitButton from 'Parts/common/SubmitButton';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import { getTextLength } from '../../common/contentUtils';
import {
  getLanguagesKeys,
  getLanguageIdByKey,
  getDefaultLanguageKey
} from 'Parts/common/langUtils';
import { CAMPAIGN_CREATE_WIZARD_INFORMATION } from '../../ui/uiUtils';

import CreatePersonalizationForm_en from './locales/CreatePersonalizationForm_en.json';
import CreatePersonalizationForm_fr from './locales/CreatePersonalizationForm_fr.json';

import './CreatePersonalizationForm.less';

class CreatePersonalizationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang: i18next.language,
      langOnLoad: i18next.language,
      isLoggedIn: props.userTypeId === 1 ? false : true,
      canDraft: true,
      lastAction: null
    };

    this.modalRef = React.createRef();

    i18next.addResourceBundle(
      'en',
      'CreatePersonalizationForm-campaign',
      CreatePersonalizationForm_en
    );
    i18next.addResourceBundle(
      'fr',
      'CreatePersonalizationForm-campaign',
      CreatePersonalizationForm_fr
    );

    this.emitChangeDebounced = debounce(this.emitChange, 250);
  }

  componentWillUnmount() {
    this.props.stopEditingForm();
  }

  componentDidUpdate(prevProps, prevState) {
    // If user logs in
    if (this.props.userTypeId !== prevProps.userTypeId) {
      this.setState({ isLoggedIn: this.props.userTypeId === 2 ? true : false });
    }

    // After user logs in - trigger submit dynamically
    if (this.state.isLoggedIn !== prevState.isLoggedIn && this.state.isLoggedIn) {
      if (this.state.lastAction === 'submit') {
        this.props.form.validateFields({ force: true }, (err, formValues) => {
          if (!err) {
            // Delay by 1 second so we don't get a 503 ( 2 POST under a second (( login & campaign creation )) )
            setTimeout(function() {
              document.querySelector('.btn-submit').click();
            }, 1000);
          }
        });
      } else if (this.state.lastAction === 'draft') {
        setTimeout(function() {
          document.querySelector('.btn-draft').click();
        }, 1000);
      }
    }

    // If locale changes, validate fields
    if (this.props.locale !== prevProps.locale) {
      this.props.form.validateFields({ force: true });
    }
  }

  handleCropComplete = bannerImage => {
    this.setState(
      {
        [`banner_${this.state.contentLang}`]: bannerImage
      },
      this.handleStartEditing()
    );
  };

  handleBackClick = e => {
    e.preventDefault();
    const { locale, t } = this.props;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    // this.props.form.validateFields({ force: true }, (err, values) => {
    //   if (!err) {
    //     this.props.storeCampaignInformation(values);
        this.props.changeCampaignCreateWizardStep(CAMPAIGN_CREATE_WIZARD_INFORMATION);
        this.props.history.push(`/${locale}/${t('URL:create-a-campaign')}`);
    //   }
    // });
  };

  handleCancelClick = e => {
    e.preventDefault();
    this.props.history.push(`/${this.props.locale}`);
  };

  handleGuestSubmit = () => {
    this.props.form.validateFields({ force: true }, (err, formValues) => {
      if (!err) {
        const { t } = this.props;
        const customAlert = {
          title: t('notification-not-logged-in-title'),
          description: t('notification-not-logged-in-description')
        };

        this.setState({ lastAction: 'submit' });

        this.props.openLoginModal(false, customAlert);
      }
    });
  };

  handleGuestDraft = e => {
    e.preventDefault();
    const { t } = this.props;
    const customAlert = {
      title: t('notification-not-logged-in-title'),
      description: t('notification-not-logged-in-description')
    };

    this.setState({ lastAction: 'draft' });

    this.props.openLoginModal(false, customAlert);
  };

  handleSaveDraft = e => {
    e.preventDefault();

    const { form } = this.props;
    const { isFieldTouched } = form;
    const values = form.getFieldsValue();
    const updatedValues = pickBy(values, (value, key) => isFieldTouched(key));

    const formEntries = Object.entries(updatedValues);
    const stateEntries = Object.entries(this.state);

    const descriptions = formEntries
      .filter(formEntry => {
        if (formEntry[0].includes('explain_') && formEntry[1]) return true;
        else return false;
      })
      .map(formEntry => {
        let key = formEntry[0];
        let formEntryLang = key.split('_')[1];

        return {
          description: formEntry[1],
          languageId: getLanguageIdByKey(formEntryLang)
        };
      });

    let banners = stateEntries
      .filter(stateEntry => {
        if (stateEntry[0].includes('banner_') && stateEntry[1] !== null) return true;
        else return false;
      })
      .map(stateEntry => {
        let key = stateEntry[0];
        let bannerEntryLang = key.split('_')[1];

        return {
          banner: stateEntry[1],
          languageId: getLanguageIdByKey(bannerEntryLang)
        };
      });

    const descriptionValues = descriptions.length
      ? {
          descriptions: [...descriptions]
        }
      : null;

    const bannerValues = banners.length
      ? {
          banners: [...banners]
        }
      : null;

    const guidelines = updatedValues.guidelines ? updatedValues.guidelines : false;

    const draftValues = {
      draft: true,
      guidelines,
      ...this.props.campaignInformation,
      ...descriptionValues,
      ...bannerValues
    };

    this.props.createCampaign(draftValues, this.props.platformId).then(result => {
      if (!result.error) {
        this.props.clearCampaign();
        this.props.deactivateCreationMode();
        this.props.history.push(`/${this.props.locale}/d/campaigns/${result.payload.id}`);
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { locale, t } = this.props;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    this.props.form.validateFields({ force: true }, (err, formValues) => {
      if (!err) {
        const formEntries = Object.entries(formValues);
        const stateEntries = Object.entries(this.state);

        const descriptions = formEntries
          .filter(formEntry => {
            if (formEntry[0].includes('explain_') && formEntry[1]) return true;
            else return false;
          })
          .map(formEntry => {
            let key = formEntry[0];
            let formEntryLang = key.split('_')[1];

            return {
              description: formEntry[1],
              languageId: getLanguageIdByKey(formEntryLang)
            };
          });

        let banners = stateEntries
          .filter(stateEntry => {
            if (stateEntry[0].includes('banner_') && stateEntry[1] !== null) return true;
            else return false;
          })
          .map(stateEntry => {
            let key = stateEntry[0];
            let bannerEntryLang = key.split('_')[1];

            return {
              banner: stateEntry[1],
              languageId: getLanguageIdByKey(bannerEntryLang)
            };
          });

        const descriptionValues = descriptions
          ? {
              descriptions: [...descriptions]
            }
          : null;

        const bannerValues = banners
          ? {
              banners: [...banners]
            }
          : null;

        const guidelines = formValues.guidelines ? formValues.guidelines : false;

        const values = {
          guidelines,
          ...this.props.campaignInformation,
          ...descriptionValues,
          ...bannerValues
        };

        this.props.createCampaign(values, this.props.platformId).then(result => {
          if (!result.error) {
            this.props.history.push(`/${locale}/${t('URL:create-a-campaign/confirmation')}`);
          }
        });
      } else {
        const fieldsValues = this.props.form.getFieldsValue();

        if (
          fieldsValues.hasOwnProperty(`banner_${locale}`) ||
          fieldsValues.hasOwnProperty(`explain_${locale}`)
        ) {
          this.props.form.setFieldsValue({ langSelect: locale });
          this.handleContentLangChange(locale);
        }
      }
    });
  };

  dataLayer = () => {
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'custom_click',
        eventAction: 'click',
        eventCategory: 'button',
        eventLabel: 'create_campaign',
        eventValue: 'submit'
      });
    }
  };

  handleContentLangChange = value => {
    this.setState({ contentLang: value });
  };

  handleShowGuideline = e => {
    e.preventDefault();
    this.modalRef.current.getWrappedInstance('FundkyModal').showModal();
  };

  handleStartEditing = () => {
    if (!this.props.isEditingForm) this.props.startEditingForm();
  };

  handleOnChange = ({ target }) => {
    this.emitChangeDebounced(target);
  };

  emitChange = target => {
    const value = target.value;
    if (value) {
      const errors = this.props.form.getFieldError(target.id);
      this.setState({
        canDraft: !errors ? true : false
      });
    }
  };

  render() {
    const { t, form, contentGuidelines, locale, languages, isMember, campaignInformation } = this.props;
    const { isLoggedIn, contentLang, langOnLoad } = this.state;

    const defaultLang = getDefaultLanguageKey(languages);

    const langSelectOptions = getLanguagesKeys()
      .sort((a, b) => t('LANGUAGES:' + a).localeCompare(t('LANGUAGES:' + b)))
      .map(key => ({
        value: key,
        text: `${t(`LANGUAGES:${key}`)}${
          key === locale /*defaultLang*/
          	? t('LANGUAGES:default')
          	: ''
        }`
      }));

    return (
      <div className="FundkyWrap">
        <Form className="CreatePersonalizationForm" onSubmit={this.handleSubmit}>
          <h2>{t('title')}</h2>

          <div className="CreatePersonalizationForm__ContentLangSwitcher">
            <FontAwesomeIcon icon={['far', 'exclamation-circle']} />
            <div className="CreatePersonalizationForm__ContentLangSwitcher--Content">
              <SelectField
                form={form}
                fieldId="langSelect"
                label={t('langSelect-label')}
                notice={t('langSelect-notice')}
                className="TextAreaMultiLangSelect"
                optionsArray={langSelectOptions}
                //initialValue={langOnLoad}
                initialValue={locale}
                onChange={this.handleContentLangChange}
                dropdownClassName="CreatePersonalizationForm__Dropdown"
              />
            </div>
          </div>

          <p>{t('download')}</p>
          <MultiLangField
            form={form}
            showLangSelect={false}
            contentLang={contentLang}
            locale={locale}
          >
            <UploadCropField
              form={form}
              fieldId="banner"
              icon={['fal', 'image']}
              handleCropConfirm={this.handleCropComplete}
              onChange={this.handleOnChange}
            />
          </MultiLangField>

          <p>{t('describe')}</p>
          <MultiLangField
            form={form}
            showLangSelect={false}
            contentLang={contentLang}
            locale={locale}
          >
            <DescriptionField
              fieldId="explain"
              className="CreatePersonalizationForm__Field"
              form={form}
              maxlength={65535}
              placeholder={false}
              requiredMessage={t('description.require')}
              hasFeedback={true}
              onChange={e => {
                this.handleStartEditing;
                this.handleOnChange(e);
              }}
            />
          </MultiLangField>
          <p>{t('accept-communications')}</p>

          {contentGuidelines && getTextLength(contentGuidelines, true) > 0 && (
            <React.Fragment>
              <CheckboxField
                fieldId="guidelines"
                required={true}
                hasFeedback={true}
                form={form}
                checkboxText={
                  <span>
                    {t('checkbox-guideline-label')}
                    <a onClick={this.handleShowGuideline}>{t('checkbox-guideline-label-link')}</a>
                  </span>
                }
              />
              <FundkyModal
                ref={this.modalRef}
                displayModal={false}
                displayActions={false}
                displayCloseButton={true}
                scrollContent={true}
              >
                {ReactHtmlParser(contentGuidelines)}
              </FundkyModal>
            </React.Fragment>
          )}

          <div className="CreatePersonalizationForm__SubmitWrapper SubmitWrapper">
            <Button className="Back-btn" onClick={this.handleBackClick}>
              {t('back')}
            </Button>
            <SubmitButton
              overwriteSubmit={!isLoggedIn ? this.handleGuestSubmit : this.handleSubmit}
              htmlType={!isLoggedIn ? 'button' : 'submit'}
              loading={this.props.isCreateCampaignInProgress}
              dataLayer={this.dataLayer}
            >
              {t('publish')}
            </SubmitButton>
            <FundkyButton
              className="btn-draft"
              type="secondary"
              size="large"
              text={
                this.props.campaignInformation && this.props.campaignInformation.campaign_behavior
                  ? t('save-draft-tickets')
                  : t('save-draft')
              }
              action={
                !isLoggedIn ? { func: this.handleGuestDraft } : { func: this.handleSaveDraft }
              }
              disabled={!this.state.canDraft}
            />
          </div>
          <div className="CreateInformationForm__CancelWrapper">
            <Popconfirm
              placement="topLeft"
              getPopupContainer={() => document.getElementById('popContainer2')}
              title={t('CampaignCreatePage:cancelText')}
              onConfirm={this.handleCancelClick}
              okText={t('CampaignCreatePage:yes')}
              cancelText={t('CampaignCreatePage:no')}
            >
              <span id="popContainer2">{t('cancel')}</span>
            </Popconfirm>
          </div>
        </Form>
      </div>
    );
  }
}

CreatePersonalizationForm.propTypes = {
  form: PropTypes.object.isRequired,
  createCampaign: PropTypes.func.isRequired,
  platformId: PropTypes.number.isRequired
  //organizationId: PropTypes.number.isRequired
};

export default translate('CreatePersonalizationForm-campaign')(CreatePersonalizationForm);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import RegistrationConfirmationPanel from './RegistrationConfirmationPanel';
import { closeConnectionModal } from "../../ui/uiActions";


const mapStateToProps = state => ({
  locale: state.session.language,
  error: state.session.checkRegistrationKeyFail,
  sessionHistory: state.session.history,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  closeConnectionModal,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(RegistrationConfirmationPanel);

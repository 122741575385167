import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import TermsAndConditionsField_en from './locales/TermsAndConditionsField_en.json';
import TermsAndConditionsField_fr from './locales/TermsAndConditionsField_fr.json';

class TermsAndConditionsField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TermsAndConditionsField', TermsAndConditionsField_en);
    i18next.addResourceBundle('fr', 'TermsAndConditionsField', TermsAndConditionsField_fr);
  }

  render() {
    const { t, form, className } = this.props;
    const { getFieldDecorator } = form;

    const input = <Checkbox>{t('label')}<a href={t('URL:terms-of-use')} target="_blank" rel="noopener noreferrer">{t('link')}</a></Checkbox>;
    const fieldId = 'acceptedTermsConditions';
    const fieldOptions = {
      valuePropName: "checked",
      rules: [
          // @see https://github.com/ant-design/ant-design/issues/5155
          { validator: (rule, value, cb) => (value === true ? cb() : cb(true)), message: t('validator') }
        ],
    };

    return (
      <Form.Item className={className} hasFeedback>
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

TermsAndConditionsField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('TermsAndConditionsField')(TermsAndConditionsField);

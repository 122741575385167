import {
  bindActionCreators,
  compose
} from 'redux';
import {
  connect
} from 'react-redux';
import {
  Form
} from 'antd';
import {
  get
} from 'lodash';
import {
  withRouter
} from 'react-router'
import dataFetcher from 'Parts/chore/dataFetcher';

import {
  storeCampaignInformation,
  createCampaign,
  clearCampaign,
} from '../campaignActions';
import {
  openLoginModal
} from "../../parts/ui/uiActions";
import {
  startEditingForm,
  stopEditingForm
} from '../../ui/uiActions';

import {
  deactivateCreationMode
} from 'Parts/ui/uiActions';

import CreatePersonalizationForm from './CreatePersonalizationForm';

const mapStateToProps = state => ({
  isCreateCampaignInProgress: state.ui.isCreateCampaignInProgress,
  campaignInformation: state.campaign.campaignInformation,
  organizationId: state.platform.platform.organisationId,
  platformId: state.platform.platform.id,
  locale: state.session.language,
  userTypeId: state.session.userTypeId,
  contentGuidelines: get(state.platform, 'content.campaign.thirdPartyGuidelines.content', null),
  isEditingForm: state.ui.isEditingForm,
  languages: state.platform.languages
});

const mapDispatchToProps = dispatch => bindActionCreators({
  storeCampaignInformation,
  createCampaign,
  openLoginModal,
  startEditingForm,
  stopEditingForm,
  clearCampaign,
  deactivateCreationMode
}, dispatch);


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher,
  Form.create()
)(CreatePersonalizationForm);

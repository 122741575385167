import React from 'react';
import { Form } from 'antd';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pickBy, has, get } from 'lodash';

import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';
import TransactionsSuggestedAmountsFields from '../../components/fields/TransactionsSuggestedAmountsFields';
import DatePickerField from 'Parts/ui/components/fields/DatePickerField';
import SelectField from 'Parts/ui/components/fields/SelectField';
import NumberField from 'Parts/ui/components/fields/NumberField';

import CancelAndSaveButtons from '../../../components/CancelAndSaveButtons';

import CampaignsSettingsTransactionsDonationFormEN from './locales/CampaignsSettingsTransactionsDonationForm_en.json';
import CampaignsSettingsTransactionsDonationFormFR from './locales/CampaignsSettingsTransactionsDonationForm_fr.json';

import './CampaignsSettingsTransactionsDonationForm.less';
class CampaignsSettingsTransactionsDonationForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle(
      'en',
      'CampaignsSettingsTransactionsDonationForm',
      CampaignsSettingsTransactionsDonationFormEN
    );
    i18next.addResourceBundle(
      'fr',
      'CampaignsSettingsTransactionsDonationForm',
      CampaignsSettingsTransactionsDonationFormFR
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.form.resetFields();
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    const { validateFields, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));
        // Add campaign goal because it is required for the update call
        if(Object.keys(updatedValues).length) {
          const campaign_goal = get(this.props.campaign, 'settings.goal', null);
          updatedValues.campaign_goal = campaign_goal;
        }
        this.props.updateCampaign(updatedValues, this.props.campaign.id);
      }
    });
  };

  render() {
    const { t, locale, form, campaign, campaignSettings, allowCustomAmount, suggestedAmounts } = this.props;

    const disableDonations = has(campaign, 'settings.donation.disabledOn')
      ? moment(campaign.settings.donation.disabledOn, 'YYYY-MM-DD HH:mm:ss')
      : null;
    const isCampaignInactive = campaign && (
      campaign.statusId === 3 ||
      campaign.statusId === 4 ||
      campaign.statusId === 5 || (
        (
          campaignSettings &&
          campaignSettings.closureOn
        ) &&
        isDateBeforeCurrentDate(campaignSettings.closureOn, true)
      )
    );
    const thermometerBehavior = get(campaign, 'settings.thermometer.behavior', 'all');
    const thermometerMultiplier = get(campaign, 'settings.thermometer.multiplier', 1);

    return (
      <Form className="CampaignsSettingsTransactionsDonationForm" onSubmit={this.handleSubmit}>
        <div className="inputs">
          <TransactionsSuggestedAmountsFields
            form={form}
            className="transactions-suggestions"
            data={suggestedAmounts}
            allowCustomAmount={allowCustomAmount}
            locale={locale}
            disabled={isCampaignInactive}
          />

          <DatePickerField
            form={form}
            fieldId="disable_donations"
            initialValue={disableDonations}
            showTime={true}
            label={t('disable-donations-label')}
            allowClear={true}
            placeholder={t('disable-donations-placeholder')}
            notice={t('disable-donations-notice')}
            disabled={isCampaignInactive}
          />

          <NumberField
            form={form}
            fieldId="multiplier"
            initialValue={thermometerMultiplier}
            label={t('multiplier.label')}
            fieldOptions={{
              rules: [
                  {
                      validator: (rule, value, callback) => {
                      const _value = parseFloat(value);
                      return _value > 0 || isNaN(_value) ? callback() : callback(true);
                      },
                      message: t('greater-than', { greater: 0 })
                  }
              ]
            }}
            formatter={value => value.replaceAll(/[^\d\.]/g, '')}
            notice={t('multiplier.notice')}
            colon={false}
          />

          <SelectField
            form={form}
            className="show_thermometer"
            fieldId="show_thermometer"
            initialValue={thermometerBehavior}
            label={t('thermometer.label')}
            optionsArray={[
              {value: 'all' , text: t('thermometer.all')},
              {value: 'children' , text: t('thermometer.children')},
              {value: 'parent' , text: t('thermometer.parent')},
              {value: 'hidden' , text: t('thermometer.hidden')},
            ]}
          />
        </div>

        {!isCampaignInactive && <CancelAndSaveButtons />}
      </Form>
    );
  }
}

CampaignsSettingsTransactionsDonationForm.propTypes = {
  form: PropTypes.object.isRequired,
  suggestedAmounts: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  allowCustomAmount: PropTypes.bool.isRequired
};

export default translate('CampaignsSettingsTransactionsDonationForm')(
  CampaignsSettingsTransactionsDonationForm
);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { updatePlatformLanguages } from '../../../platform/platformActions';

import LanguagesSettingsForm from './LanguagesSettingsForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  isUpdatePlatformInProgress: state.ui.isUpdatePlatformInProgress,
  locale: state.session.language,
  languages: state.platform.languages
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformLanguages
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(LanguagesSettingsForm);

import React from 'react';

import './Error404Page.less';

const Error404Page = () => {
  return (
    <div className="Error404Page__Content">
      <div className="Error404Page__En">
        <h1 className="Error404Page__Title">Oink!</h1>
        <h2 className="Error404Page__Subtitle">(translation: are you lost?)</h2>
        <p className="Error404Page__Text">The page you’re looking for doesn’t exist. Paddle away!</p>
      </div>
      <div className="Error404Page__Fr">
        <h1 className="Error404Page__Title">Oink!</h1>
        <h2 className="Error404Page__Subtitle">(traduction: êtes-vous perdu?)</h2>
        <p className="Error404Page__Text">La page que vous cherchez n’existe pas. À vos pagaies!</p>
      </div>
    </div>
  );
}

export default Error404Page;

import { notification } from 'antd';
import i18next from 'i18next';
import { get, isEmpty } from 'lodash';

import {
  requestApiGet,
  requestApiPut,
  requestApiDelete
} from 'Parts/common/requestUtils';
import {
  getRoleIdFromRoles,
  getSessionIp
} from 'Parts/session/sessionUtils'

export const FETCH_MEMBER_ID_START = 'FETCH_MEMBER_ID_START';
export const FETCH_MEMBER_ID_SUCCESS = 'FETCH_MEMBER_ID_SUCCESS';
export const FETCH_MEMBER_ID_ERROR = 'FETCH_MEMBER_ID_ERROR';

export const UPDATE_MEMBER_START = 'UPDATE_MEMBER_START';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_ERROR = 'UPDATE_MEMBER_ERROR';

export const DELETE_MEMBER_CORPORATE_START = 'DELETE_MEMBER_CORPORATE_START';
export const DELETE_MEMBER_CORPORATE_SUCCESS = 'DELETE_MEMBER_CORPORATE_SUCCESS';
export const DELETE_MEMBER_CORPORATE_ERROR = 'DELETE_MEMBER_CORPORATE_ERROR';

export const UI_MEMBER_SELECT = 'UI_MEMBER_SELECT';
export const UI_MEMBER_CLEAR = 'UI_MEMBER_CLEAR';

export const CLEAR_MEMBER = 'CLEAR_MEMBER';

export function fetchPlatformMemberById(platformId, memberId) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_ID_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/members/${memberId}`,
      null,
      headers
    )
      .then(member => {
        // TODO memoize getRoleIdFromRoles()
        const roleId = getRoleIdFromRoles(member.roles);

        const payload = {
          ...member,
          roleId: roleId
        };

        return dispatch({ type: FETCH_MEMBER_ID_SUCCESS, payload });
      })
      .catch(error => {
        return dispatch({ type: FETCH_MEMBER_ID_ERROR, error });
      });
  };
}

export function fetchMemberById(memberId) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_ID_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/members/${memberId}`,
      { showInformation: true },
      headers
    )
      .then(member => {
        const payload = {
          ...member
        };

        return dispatch({ type: FETCH_MEMBER_ID_SUCCESS, payload });
      })
      .catch(error => {
        return dispatch({ type: FETCH_MEMBER_ID_ERROR, error });
      });
  };
}

export function updateMember(formValues, memberId, platformId = null) {
  return dispatch => {
    dispatch({ type: UPDATE_MEMBER_START });

    const values = {
      email: formValues.email || undefined,
      honorificId: formValues.honorificId || undefined,
      roleId: formValues.roleId || undefined,
      languageId: formValues.languageId || undefined,
      firstName: formValues.firstName || undefined,
      lastName: formValues.lastName || undefined
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/members/${memberId}`,
      values,
      headers
    )
      .then(updated => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        dispatch({ type: UPDATE_MEMBER_SUCCESS });
        if (platformId) {
          dispatch(fetchPlatformMemberById(platformId, memberId));
        } else {
          dispatch(fetchMemberById(memberId));
        }
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: i18next.t('ERROR:' + error.code)
        });
        dispatch({ type: UPDATE_MEMBER_ERROR, error });
      });
  };
}

export function updateMemberProfile(formValues, memberId, profileType = 1, platformId = null) {
  return dispatch => {
    dispatch({ type: UPDATE_MEMBER_START });

    const phone = formValues.number || formValues.number === null || formValues.ext || formValues.ext === null
  	  ? {
      	  phone: {
            number: formValues.number === null
              ? null
              : formValues.number.toString() || undefined,
            ext: formValues.ext === null
              ? null
              : formValues.ext || undefined,
      	  }
        }
      : null;

    // TODO add the business name and title
    const businessInformation =
      profileType === 2 &&
      (formValues.organizationName ||
        formValues.organizationName === null ||
        formValues.organizationTitle ||
        formValues.organizationTitle === null)
        ? {
            organization: {
              name:
                formValues.organizationName === null
                  ? null
                  : formValues.organizationName || undefined,
              title:
                formValues.organizationTitle === null
                  ? null
                  : formValues.organizationTitle || undefined
            }
          }
        : null;

    const information =
      formValues.address ||
      formValues.address === null ||
      formValues.unit ||
      formValues.unit === null ||
      formValues.city ||
      formValues.city === null ||
      formValues.country ||
      formValues.country === null ||
      formValues.province ||
      formValues.province === null ||
      formValues.postalCode ||
      formValues.postalCode === null ||
      phone ||
      businessInformation
        ? {
            profileTypeId: profileType || 1,
            address: formValues.address === null ? null : formValues.address || undefined,
            unit: formValues.unit === null ? null : formValues.unit || undefined,
            city: formValues.city === null ? null : formValues.city || undefined,
            country: formValues.country === null ? null : formValues.country || undefined,
            province: formValues.province === null ? null : formValues.province || undefined,
            postalCode: formValues.postalCode === null ? null : formValues.postalCode || undefined,
            ...phone,
            ...businessInformation
          }
        : null;

    const values = {
      ...information
    };

    if(!isEmpty(values)) {
      const headers = {
        ...dispatch(getSessionIp())
      };

      return requestApiPut(
        `/members/${memberId}/profiles`,
        values,
        headers
      )
        .then(updated => {
          dispatch({ type: UPDATE_MEMBER_SUCCESS });
          if (platformId) {
            dispatch(fetchPlatformMemberById(platformId, memberId));
          } else {
            dispatch(fetchMemberById(memberId));
          }
        })
        .catch(error => {
          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: error.code
          });
          dispatch({ type: UPDATE_MEMBER_ERROR, error });
        });
    }
  };
}

export function deleteMemberWorkProfile(memberId, platformId = null) {
  return dispatch => {
    dispatch({ type: DELETE_MEMBER_CORPORATE_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiDelete(
      `/members/${memberId}/profiles`,
      { profileTypeId: 2 },
      headers
    )
      .then(updated => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        dispatch({ type: DELETE_MEMBER_CORPORATE_SUCCESS });
        if (platformId) {
          dispatch(fetchPlatformMemberById(platformId, memberId));
        } else {
          dispatch(fetchMemberById(memberId));
        }
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.code
        });
        dispatch({ type: DELETE_MEMBER_CORPORATE_ERROR, error });
      });
  };
}

export function clearSelectedMember() {
  return dispatch => {
    dispatch({ type: UI_MEMBER_CLEAR });
  };
}

export function clearMember() {
  return dispatch => {
    dispatch({ type: CLEAR_MEMBER });
  };
}

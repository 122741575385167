import i18next from 'i18next';
import {
  requestApiGet,
  requestApiPost,
  requestApiPut,
  requestApiDelete,
} from 'Parts/common/requestUtils';
import { notification } from 'antd';

export const ADD_SURVEY_INFORMATION_START = 'ADD_SURVEY_INFORMATION_START';
export const ADD_SURVEY_INFORMATION_SUCCESS = 'ADD_SURVEY_INFORMATION_SUCCESS';
export const ADD_SURVEY_INFORMATION_ERROR = 'ADD_SURVEY_INFORMATION_ERROR';

export const UPDATE_SURVEY_INFORMATION_START = 'UPDATE_SURVEY_INFORMATION_START';
export const UPDATE_SURVEY_INFORMATION_SUCCESS = 'UPDATE_SURVEY_INFORMATION_SUCCESS';
export const UPDATE_SURVEY_INFORMATION_ERROR = 'UPDATE_SURVEY_INFORMATION_ERROR';

export const DELETE_SURVEY_INFORMATION_START = 'DELETE_SURVEY_INFORMATION_START';
export const DELETE_SURVEY_INFORMATION_SUCCESS = 'DELETE_SURVEY_INFORMATION_SUCCESS';
export const DELETE_SURVEY_INFORMATION_ERROR = 'DELETE_SURVEY_INFORMATION_ERROR';

export const FETCH_SURVEY_DETAILS_START = 'FETCH_SURVEY_DETAILS_START';
export const FETCH_SURVEY_DETAILS_SUCCESS = 'FETCH_SURVEY_DETAILS_SUCCESS';
export const FETCH_SURVEY_DETAILS_ERROR = 'FETCH_SURVEY_DETAILS_ERROR';

export const ADD_SURVEY_FIELDS_START = 'ADD_SURVEY_FIELDS_START';
export const ADD_SURVEY_FIELDS_SUCCESS = 'ADD_SURVEY_FIELDS_SUCCESS';
export const ADD_SURVEY_FIELDS_ERROR = 'ADD_SURVEY_FIELDS_ERROR';

export const UPDATE_SURVEY_FIELDS_START = 'UPDATE_SURVEY_FIELDS_START';
export const UPDATE_SURVEY_FIELDS_SUCCESS = 'UPDATE_SURVEY_FIELDS_SUCCESS';
export const UPDATE_SURVEY_FIELDS_ERROR = 'UPDATE_SURVEY_FIELDS_ERROR';

export const FETCH_SURVEY_FIELDS_START = 'FETCH_SURVEY_FIELDS_START';
export const FETCH_SURVEY_FIELDS_SUCCESS = 'FETCH_SURVEY_FIELDS_SUCCESS';
export const FETCH_SURVEY_FIELDS_ERROR = 'FETCH_SURVEY_FIELDS_ERROR';

export const FETCH_FIELDS_START = 'FETCH_FIELDS_START';
export const FETCH_FIELDS_SUCCESS = 'FETCH_FIELDS_SUCCESS';
export const FETCH_FIELDS_ERROR = 'FETCH_FIELDS_ERROR';

export const DELETE_FIELDS_START = 'DELETE_FIELDS_START';
export const DELETE_FIELDS_SUCCESS = 'DELETE_FIELDS_SUCCESS';
export const DELETE_FIELDS_ERROR = 'DELETE_FIELDS_ERROR';

export const FETCH_SURVEY_RESULT_START = 'FETCH_SURVEY_RESULT_START';
export const FETCH_SURVEY_RESULT_SUCCESS = 'FETCH_SURVEY_RESULT_SUCCESS';
export const FETCH_SURVEY_RESULT_ERROR = 'FETCH_SURVEY_RESULT_ERROR';

export const FETCH_CAMPAIGN_INFORMATION = 'FETCH_CAMPAIGN_INFORMATION';
export const FETCH_CAMPAIGN_TICKET_INFORMATION = 'FETCH_CAMPAIGN_TICKET_INFORMATION';
export const FETCH_CAMPAIGN_INFORMATION_ERROR = 'FETCH_CAMPAIGN_INFORMATION_ERROR';
export const FETCH_CAMPAIGN_TICKET_INFORMATION_ERROR = 'FETCH_CAMPAIGN_TICKET_INFORMATION_ERROR';

export const addSurveyInformations = (data) => {
  return (dispatch) => {
    dispatch({ type: ADD_SURVEY_INFORMATION_START });
    requestApiPost('/survey', data, null)
      .then((response) => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:survey-success'),
        });
        dispatch({ type: ADD_SURVEY_INFORMATION_SUCCESS, payload: response });
      })

      .catch((error) => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:creation-failed'),
          description: i18next.t('API_NOTIFICATIONS:survey-failed'),
        });

        return dispatch({
          type: ADD_SURVEY_INFORMATION_ERROR,
          error: error,
        });
      });
  };
};

export const updateSurveyInformations = (id, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SURVEY_INFORMATION_START });
    requestApiPut(`/survey/${id}`, data)
      .then((response) => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:survey_update-success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved'),
        });
        dispatch({ type: UPDATE_SURVEY_INFORMATION_SUCCESS, payload: response });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_SURVEY_INFORMATION_ERROR,
          error: error,
        });
      });
  };
};

export const deleteSurveyInformations = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_SURVEY_INFORMATION_START });
    requestApiDelete(`/survey/${id}`)
      .then((response) => {
        dispatch({ type: DELETE_SURVEY_INFORMATION_SUCCESS, payload: response });
      })
      .catch((error) => {
        return dispatch({
          type: DELETE_SURVEY_INFORMATION_ERROR,
          error: error,
        });
      });
  };
};

export const fetchSurveyDetails = (id, local) => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_SURVEY_DETAILS_START });
    try {
      requestApiGet(`/survey/${id}?lang_id=${local}`).then((response) => {
        return dispatch({ type: FETCH_SURVEY_DETAILS_SUCCESS, payload: response.data });
      });
    } catch (error) {
      return dispatch({
        type: FETCH_SURVEY_DETAILS_ERROR,
        error: error,
      });
    }
  };
};

export const updateTempSurveyFields = (data) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SURVEY_FIELDS_START });
    try {
      return dispatch({ type: FETCH_SURVEY_FIELDS_SUCCESS, payload: data });
    } catch (error) {
      return dispatch({
        type: FETCH_SURVEY_FIELDS_ERROR,
        error: error,
      });
    }
  };
};

export const fetchSurveyResultDetails = (id, local) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SURVEY_RESULT_START });
    try {
      requestApiGet(`/survey/export/${id}?lang_id=${local}`).then((response) => {
        return dispatch({ type: FETCH_SURVEY_RESULT_SUCCESS, payload: response.data });
      });
    } catch (error) {
      return dispatch({
        type: FETCH_SURVEY_RESULT_ERROR,
        error: error,
      });
    }
  };
};

export const addSurveyFields = (data, local) => {
  return (dispatch) => {
    dispatch({ type: ADD_SURVEY_FIELDS_START });
    requestApiPost(`/survey/field`, data)
      .then((response) => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:field-success'),
        });
        dispatch({ type: ADD_SURVEY_FIELDS_SUCCESS, payload: response });
        dispatch(fetchSurveyFields(data[0].survey_id, local));
      })
      .catch((error) => {
        return dispatch({
          type: ADD_SURVEY_FIELDS_ERROR,
          error: error,
        });
      });
  };
};

export const updateSurveyFields = (survey_id, data, local, status=true) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_SURVEY_FIELDS_START });
    const newSurveysFields = data.map((d, i) => {
      return {
        ...d,
        options: d.options ? d.options.filter((d) => d !== '') : d.options,
      };
    });
    requestApiPut(`/survey/field/${survey_id}?lang_id=${local}`, newSurveysFields)
      .then((response) => {
        if (status) {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:publish-success'),
          });
        }
        dispatch({ type: UPDATE_SURVEY_FIELDS_SUCCESS, payload: response });
        dispatch(fetchSurveyFields(survey_id, local));
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_SURVEY_FIELDS_ERROR,
          error: error,
        });
      });
  };
};

export const fetchSurveyFields = (survey_id, local) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SURVEY_FIELDS_START });
    requestApiGet(`/survey/field/${survey_id}?lang_id=${local}`)
      .then((response) => {
        dispatch({ type: FETCH_SURVEY_FIELDS_SUCCESS, payload: response.surveyField });
      })
      .catch((error) => {
        return dispatch({
          type: FETCH_SURVEY_FIELDS_ERROR,
          error: error,
        });
      });
  };
};

export const fetchFields = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_FIELDS_START });
    requestApiGet(`/field`)
      .then((response) => {
        dispatch({ type: FETCH_FIELDS_SUCCESS, payload: response });
      })
      .catch((error) => {
        return dispatch({
          type: FETCH_FIELDS_ERROR,
          error: error,
        });
      });
  };
};

export const deleteFieldsInformations = (id, survey_id, local) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FIELDS_START });

    requestApiDelete(`/survey/field/${id}`)
      .then((response) => {
        dispatch({ type: DELETE_FIELDS_SUCCESS, payload: response });
        dispatch(fetchSurveyFields(survey_id, local));
      })
      .catch((error) => {
        return dispatch({
          type: DELETE_FIELDS_ERROR,
          error,
        });
      });
  };
};

export const fetchCampaignInformations = (id, local) => {
  return async (dispatch) => {
    try {
      requestApiGet(`/campaigns/survey/${id}?lang_id=${local}`)
        .then((response) => {
          dispatch({ type: FETCH_CAMPAIGN_INFORMATION, payload: response });
        })
        .catch((error) => {
          return dispatch({
            type: FETCH_CAMPAIGN_INFORMATION_ERROR,
            error: error,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchCampaignTicketInformations = (ids, local) => {
  return async (dispatch) => {
    try {
      if (ids) {
        requestApiGet(`/campaigns/event/tickets/survey?campaigns=${ids}&lang_id=${local}`)
        .then((response) => {
          dispatch({ type: FETCH_CAMPAIGN_TICKET_INFORMATION, payload: response });
        }) 
        .catch((error) => {
          return dispatch({
            type: FETCH_CAMPAIGN_TICKET_INFORMATION_ERROR,
            error: error,
          });
        });
      }else{
        dispatch({ type: FETCH_CAMPAIGN_TICKET_INFORMATION, payload: [] });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeCampaignTicketInformations = (id) => {
  return async (dispatch) => {
    return dispatch({ type: FETCH_CAMPAIGN_TICKET_INFORMATION, payload: [] });
  };
};

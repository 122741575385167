import React from 'react';
import { Tabs } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import HeadMetas from '../../common/HeadMetasContainer';

import SearchBar from 'Parts/search/SearchBarContainer';
import { SEARCH_TAB_CAMPAIGNS, SEARCH_TAB_PARTICIPANTS, SEARCH_TAB_TEAMS } from '../../ui/uiUtils';
import SearchCampaignPanel from '../components/SearchCampaignPanelContainer';
import SearchTeamPanel from '../components/SearchTeamPanelContainer';
import SearchParticipantPanel from '../components/SearchParticipantPanelContainer';

import BehaviorFilter from 'Parts/search/filters/BehaviorFilter';

import SearchPage_en from './locales/SearchPage_en.json';
import SearchPage_fr from './locales/SearchPage_fr.json';

import './SearchPage.less';

const { TabPane } = Tabs;

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.matchUrl = match.url;

    i18next.addResourceBundle('en', 'SearchPage', SearchPage_en);
    i18next.addResourceBundle('fr', 'SearchPage', SearchPage_fr);

    // TODO Don't use location.state to know where we come from: when navigate back and come back to this page, the info is lost --> use react-router-last-location instead
    // No need to put it in the state because it is immutable
    this.from = '';

    // this.state = {
    //   from: props.location.state && props.location.state.from
    // }
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { state } = this.props.location;
    /*if (state && state.from) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }*/
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  getActiveKey = () => {
    const { location, match, t } = this.props;

    if (location.pathname === `${this.matchUrl}/${t('URL.campaigns')}`) {
      return SEARCH_TAB_CAMPAIGNS;
    } else if (location.pathname === `${this.matchUrl}/${t('URL.teams')}`) {
      return SEARCH_TAB_TEAMS;
    } else if (location.pathname === `${this.matchUrl}/${t('URL.participants')}`) {
      return SEARCH_TAB_PARTICIPANTS;
    }
  };

  handleTabChange = activeKey => {
    const { history, match, t } = this.props;
    if (activeKey === SEARCH_TAB_CAMPAIGNS) {
      history.push(`${this.matchUrl}/${t('URL.campaigns')}`);
    } else if (activeKey === SEARCH_TAB_TEAMS) {
      history.push(`${this.matchUrl}/${t('URL.teams')}`);
    } else if (activeKey === SEARCH_TAB_PARTICIPANTS) {
      history.push(`${this.matchUrl}/${t('URL.participants')}`);
    }
  };

  handleSearch = filters => {
    const search = filters.search || null;
    this.props.searchCampaigns(this.props.platformId, search, filters, null, {
      orderBy: 'addedOn[desc]'
    }, false, false, this.from === 'donation');
    this.props.searchTeams(this.props.platformId, search, filters, null, {
      orderBy: 'addedOn[desc]'
    }, false, false, this.from === 'donation');
    this.props.searchParticipants(this.props.platformId, search, filters, null, {
      orderBy: 'addedOn[desc]'
    }, false, false, this.from === 'donation');
  };

  render() {
    const { t, match } = this.props;

    //Manage search in donation process
    if (match.url.includes(t('URL.give'))) {
      const str = match.url.substr(match.url.lastIndexOf('/'));
      this.matchUrl = match.url.replace(str, '');
      this.from = 'donation';
    } else {
      this.from = '';
      this.matchUrl = match.url;
    }
    const activeKey = this.getActiveKey();

    const filters = activeKey === SEARCH_TAB_CAMPAIGNS
      ? [BehaviorFilter]
      : activeKey === SEARCH_TAB_TEAMS
        ? []
        : activeKey === SEARCH_TAB_PARTICIPANTS
          ? []
          : [];

    return (
      <div className="SearchPage FundkyWrap">
        <HeadMetas title={t('searchpage.title')} />
        <h1>{t('who-do-you-want-to-encourage')}</h1>
        <p>{t('subtitle')}</p>
        <SearchBar
          placeholder={t('search-placeholder')}
          onSearch={this.handleSearch}
          filters={filters}
        />
        <Tabs onChange={this.handleTabChange} activeKey={activeKey}>
          <TabPane
            tab={`${t('campaigns')} (${(this.props.campaigns.pagination &&
              this.props.campaigns.pagination.totalResults) ||
              0})`}
            key={SEARCH_TAB_CAMPAIGNS}
          >
            <Route
              exact
              path={`${this.matchUrl}/${t('URL.campaigns')}`}
              render={props => <SearchCampaignPanel {...props} from={this.from} />}
            />
          </TabPane>
          <TabPane
            tab={`${t('teams')} (${(this.props.teams.pagination &&
              this.props.teams.pagination.totalResults) ||
              0})`}
            key={SEARCH_TAB_TEAMS}
          >
            <Route
              exact
              path={`${this.matchUrl}/${t('URL.teams')}`}
              render={props => <SearchTeamPanel {...props} from={this.from} />}
            />
          </TabPane>
          <TabPane
            tab={`${t('participants')} (${(this.props.participants.pagination &&
              this.props.participants.pagination.totalResults) ||
              0})`}
            key={SEARCH_TAB_PARTICIPANTS}
          >
            <Route
              exact
              path={`${this.matchUrl}/${t('URL.participants')}`}
              render={props => <SearchParticipantPanel {...props} from={this.from} />}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default translate('SearchPage')(SearchPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import CampaignPublicPage from '../campaign/pages/CampaignPublicPageContainer';
import Error404Page from './Error404Page';
import LoadingPage from './LoadingPage';

class CheckCampaignRoute extends React.Component {

  componentWillUnmount() {
    this.props.clearCampaign();
  }

  render() {
    const {campaign, isFetchCampaignInProgress, ...rest} = this.props;
    const splitedPath = this.props.location.pathname.split('/');
    const modal = splitedPath[3] || null;
    const pathAlias = splitedPath[2];
    const campaignAlias = campaign.alias;

    return (
      <Route
        {...rest}
        render={props => {
          if (!isFetchCampaignInProgress || pathAlias === campaignAlias) {
            if (campaign.id) {
              return <CampaignPublicPage {...props} modal={modal} />;
            } else {
              return <Error404Page />;
            }
          } else {
            return <LoadingPage />;
          }
        }}
      />
    );
  }

}

CheckCampaignRoute.defaultProps = {
  campaign: null
};

CheckCampaignRoute.propTypes = {
  campaign: PropTypes.object
};

export default CheckCampaignRoute;

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import dataFetcher from 'Parts/chore/dataFetcher';
import SurveyAttempt from './CreateSurveyAttemptForm';
import { surveysDetailAction, surveyAttemptAction, surveyAttemptStoreAction, surveyAttemptSubmitAction } from './SurveyAttemptAction';
import { fetchSurveyFields } from '../../dashboard/surveys/SurveyPage/SurveyPageActions';
import { cancelTransaction } from '../donationActions'

const mapStateToProps = (state) => ({
  surveysPageDateils: state.surveyAttempt.surveysPageDateils,
  surveysPageDateilsError: state.surveyAttempt.surveysPageDateilsError,
  surveysAttempt: state.surveyAttempt.surveysAttempt,
  surveysAttemptError: state.surveyAttempt.surveysAttemptError,
  surveysFields: state.surveyPage.surveysFields,
  surveyId: state.donation.surveyId,
  user: state.session.user,
  donationInformation: state.donation.donationInformation,
  campaign: state.campaign.campaign,
  storeSurveyAttempt: state.surveyAttempt.storeSurveyAttempt,
  surveySubmitData: state.surveyAttempt.surveySubmitData,
  locale: state.session.language,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      surveysDetailAction,
      surveyAttemptAction,
      fetchSurveyFields,
      cancelTransaction,
      surveyAttemptStoreAction,
      surveyAttemptSubmitAction
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(SurveyAttempt);

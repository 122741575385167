import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router'
import { Form } from 'antd';
import { get } from 'lodash';

import dataFetcher from '../../../parts/chore/dataFetcher';
import {
  updatePlatformNote,
  deletePlatformNote
} from '../../../platform/platformActions';

import PagesDonationConfirmationEmailForm from './PagesDonationConfirmationEmailForm';

const mapStateToProps = state => ({
  locale: get(state, 'session.language'),
  platform: get(state, 'platform.platform'),
  languages: get(state, 'platform.languages'),
  notes: get(state, 'platform.notes'),
  accentColors: get(state, 'platform.platform.settings.color'),
  userRoleId: get(state, 'session.user.roleId')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformNote,
  deletePlatformNote
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher,
  Form.create(),
)(PagesDonationConfirmationEmailForm);

import { bindActionCreators, compose } from "redux";
import { Form } from 'antd';
import { connect } from "react-redux";
import { get } from 'lodash';

import dataFetcher from "../../../parts/chore/dataFetcher";

import { searchTransactions, clearSearch } from '../../../search/searchActions'

import TransactionsInvoiceList from './TransactionsInvoiceList';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  organization: state.organization.organization,
  transaction: state.donation.transaction,
  invoices: get(state, 'search.transactions.results', []),
  pagination: get(state, 'search.transactions.pagination', {}),
  filters: get(state, 'search.transactions.filterBy', {}),
  search: get(state, 'search.transactions.search', null),
  orderBy: get(state, 'search.transactions.orderBy', {}),
  locale: state.session.language,
  userId: state.session.userId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchTransactions,
  clearSearch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create(),
  dataFetcher
)(TransactionsInvoiceList);

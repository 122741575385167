import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, has } from 'lodash';
import { Layout, Menu, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import PlatformSocialMedia from '../../common/PlatformSocialMedia';
import { getCdnUrl } from 'Parts/common/environment';

import PublicHeader_en from './locales/PublicHeader_en.json';
import PublicHeader_fr from './locales/PublicHeader_fr.json';

import './PublicHeader.less';

import 'Parts/assets/images/logo_fundky.png';
import '../../assets/favicon-plateform.png'; // Cheat, couldnt import favicon in app.jsx

class PublicHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMenuVisible: '',
      langButtonLoading: false,
      scrolling: false,
      formEditingNotice: false
    };

    i18next.addResourceBundle('en', 'PublicHeader', PublicHeader_en);
    i18next.addResourceBundle('fr', 'PublicHeader', PublicHeader_fr);

    this.publicHeaderRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideHeader);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideHeader);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  };

  handleUserMenuClick = ({ item, key }) => {
    this.forceCloseMenu();

    if (!item.props.disabled) {
      switch (key) {
        case 'logout':
          this.props.logout();
          break;

        case 'login':
          this.props.openLoginModal();
          break;

        case 'locale':
          this.switchLanguage();
          break;
      }
    }
  };

  handlePlatformMenuClick = ({ key }) => {
    switch (key) {
      case 'register':
        this.props.openRegisterModal();
        break;
    }
  };

  handlePlatformMenuMobileClick = ({ item, key }) => {
    this.forceCloseMenu();

    if (!item.props.disabled) {
      switch (key) {
        case 'logout':
          this.props.logout();
          break;

        case 'login':
          this.props.openLoginModal();
          break;

        case 'register':
          this.props.openRegisterModal();
          break;

        case 'locale':
          this.switchLanguage();
          break;
      }
    }
  };

  switchLanguage = () => {
    // Check if a form is beeing edited to display notice or switch lang
    if (this.props.isEditingForm) {
      this.setState({ formEditingNotice: true });
    } else {
      this.changeUrl();
    }
  };

  // If agrees notice, change language
  handleFormEditingNoticeOk = () => {
    this.setState({ formEditingNotice: false });
    this.changeUrl();
  };

  // If cancel notice, do nothing
  handleFormEditingNoticeCancel = () => {
    this.setState({ formEditingNotice: false });
  };

  changeUrl = () => {
    const { t, i18n, location, campaignAlias, teamAlias, memberAlias, locale } = this.props;

    this.setState({
      langButtonLoading: true
    });

    const lng = t('lng').toLowerCase(); // Get new language
    const initialLng = locale; // Get initial language
    var pathWithoutLocale = location.pathname.replace(`/${initialLng}/`, ''); // Remove initial language from path
    var hasCampaignParam,
      hasTeamParam,
      hasMemberParam = false;

    // If path contains at least campaignAlias, teamAlias or memberAlias
    if (
      (campaignAlias && pathWithoutLocale.indexOf(campaignAlias) !== -1) ||
      (teamAlias && pathWithoutLocale.indexOf(teamAlias) !== -1) ||
      (memberAlias && pathWithoutLocale.indexOf(memberAlias) !== -1)
    ) {
      if (campaignAlias && pathWithoutLocale.indexOf(campaignAlias) !== -1) {
        hasCampaignParam = true;
        pathWithoutLocale = pathWithoutLocale.replace(campaignAlias, 'skip');
      }

      if (teamAlias && pathWithoutLocale.indexOf(teamAlias) !== -1) {
        hasTeamParam = true;
        pathWithoutLocale = pathWithoutLocale.replace(teamAlias, 'skip');
      }

      if (memberAlias && pathWithoutLocale.indexOf(memberAlias) !== -1) {
        hasMemberParam = true;
        pathWithoutLocale = pathWithoutLocale.replace(memberAlias, 'skip');
      }
    }

    var pathWithParamsReplacedArr = pathWithoutLocale
      .split('skip')
      .filter(x => x !== undefined && x !== null && x !== '')
      .map(currentArrayValue => {
        var tempValue = currentArrayValue;
        if (tempValue.startsWith('/')) tempValue = tempValue.slice(1);

        if (tempValue.endsWith('/')) tempValue = tempValue.slice(0, tempValue.length - 1);

        return tempValue;
      })
      .filter(x => x !== undefined && x !== null && x !== '');

    const newResources = get(i18n, `options.resources[${lng}].URL`, {});
    const currentResources = get(i18n, `options.resources[${initialLng}].URL`, {});

    var newUrlArr = pathWithParamsReplacedArr
      .map(currentArrayValue => {
        let currentLangKey = this.getKeyByValue(currentResources, currentArrayValue);

        if (newResources.hasOwnProperty(currentLangKey))
          return newResources[currentLangKey];
      });

    var newUrl = this.buildNewUrl(lng, hasCampaignParam, hasTeamParam, hasMemberParam, newUrlArr);

    if (newUrl.endsWith('/')) newUrl = newUrl.slice(0, newUrl.length - 1);

    /* Set session language */
    this.props.setSessionLanguage(lng)
      .then(() => {
        this.setState({
          langButtonLoading: false
        });

        i18n.changeLanguage(lng);
        this.props.history.push('/' + newUrl);
        document.getElementsByTagName('html')[0].lang = lng;
      });
  };

  getKeyByValue = (object, value) => Object
    .keys(object)
    .find(key => object[key] === value);

  buildNewUrl = (lng, hasCampaign, hasTeam, hasMember, urlArr) => {
    const { campaignAlias, teamAlias, memberAlias } = this.props;
    var returnedUrl = [];

    returnedUrl.push(lng);

    if (hasCampaign) {
      returnedUrl.push(campaignAlias);
    }

    if (hasTeam) {
      returnedUrl.push(teamAlias);
    }

    if (hasMember && !hasTeam) {
      returnedUrl.push(urlArr[0] + '/' + memberAlias);
      urlArr.shift();
    }

    if (hasMember && hasTeam) {
      returnedUrl.push(memberAlias);
    }

    for (let i = 0; i < urlArr.length; i++) {
      returnedUrl.push(urlArr[i]);
    }

    return returnedUrl.join('/');
  };

  toggleMobileMenu = () => {
    this.setState({
      mobileMenuVisible: this.state.mobileMenuVisible === '' ? 'isVisible' : ''
    });
  };

  forceCloseMenu = () => {
    if (this.state.mobileMenuVisible === 'isVisible') {
      this.setState({
        mobileMenuVisible: this.state.mobileMenuVisible === '' ? 'isVisible' : ''
      });
    }
  };

  /**
   * Alert if clicked outside of element
   */
  handleClickOutsideHeader = event => {
    const publicHeaderNode = this.publicHeaderRef.current;
    if (publicHeaderNode && !publicHeaderNode.contains(event.target)) {
      this.forceCloseMenu();
    }
  };

  render() {
    const { t, platform, platformSocial, platformLogo, locale } = this.props;
    const { mobileMenuVisible } = this.state;
    const campaignEnabled = Number(platform.settings.campaign.creation.enabled) || false;
    const cdnUrl = getCdnUrl();
    const logoSrc =
      platformLogo && platformLogo.logo ? cdnUrl + platformLogo.logo : '/assets/logo_fundky.png';
    const logoLink = (platformLogo && platformLogo.link) || null;
    const userMenuItem =
      this.props.userTypeId && this.props.userTypeId > 1 ? (
        <Menu.SubMenu
          className="PublicHeader__UserSubMenu"
          popupClassName="overHeader"
          title={
            <span>
              <FontAwesomeIcon className="hide-desktop" icon={['fas', 'user-circle']} size="lg" />{' '}
              {this.props.user.firstName} <FontAwesomeIcon icon={['fas', 'caret-down']} />
            </span>
          }
        >
          <Menu.Item
            key="dashboard"
            style={{ '--secondary-color': platform.settings.color.secondary }}
          >
            <Link to={`/${locale}/d`}>
              <FontAwesomeIcon
                className="PublicHeader__PlatformSubMenuIcon"
                icon={['far', 'arrow-right']}
              />
              {t('dashboard')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="profile"
            style={{ '--secondary-color': platform.settings.color.secondary }}
          >
            <Link to={`/${locale}/d/profile/${this.props.user.id}/my-information`}>
              <FontAwesomeIcon
                className="PublicHeader__PlatformSubMenuIcon"
                icon={['far', 'arrow-right']}
              />
              {t('profile')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="logout"
            style={{ '--secondary-color': platform.settings.color.secondary }}
          >
            <FontAwesomeIcon
              className="PublicHeader__PlatformSubMenuIcon"
              icon={['far', 'arrow-right']}
            />
            {t('logout')}
          </Menu.Item>
        </Menu.SubMenu>
      ) : (
        <Menu.Item key="login">
          <FontAwesomeIcon
            className="PublicHeader__PlatformMenuIcon login-icon"
            icon={['far', 'sign-in']}
            size="lg"
          />
          {t('login')}
        </Menu.Item>
      );

    const officialWebsiteMenuItem =
      this.props.organization &&
      this.props.organization.information &&
      this.props.organization.information.website ? (
        <Menu.Item className="PublicHeader__ShareIcon" key="share-link">
          <a
            href={
              this.props.organization &&
              this.props.organization.information &&
              this.props.organization.information.website
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={['far', 'link']}
              mask={['fas', 'square']}
              transform="shrink-7"
              size="lg"
            />
          </a>
        </Menu.Item>
      ) : null;

    const menuDesktop = (
      <React.Fragment>
        <Menu
          className="PublicHeader__UserMenu"
          onClick={this.handleUserMenuClick}
          mode="horizontal"
          selectable={false}
        >
          {userMenuItem}
          <Menu.Item key="faq">
            <a href="https://fundky.tawk.help/" target="_blank" rel="noopener noreferrer">
              {t('faq')}
            </a>
          </Menu.Item>
          <Menu.Item key="locale" disabled={this.state.langButtonLoading}>
            {t('lng')}
          </Menu.Item>
        </Menu>
        <Menu
          className="PublicHeader__PlatformMenu"
          onClick={this.handlePlatformMenuClick}
          mode="horizontal"
          selectable={false}
        >
          {this.props.isBillingConfigured ||
          this.props.userTypeId === 1 ||
          campaignEnabled ? (
            <Menu.SubMenu
              className="PublicHeader__PlatformSubMenu"
              popupClassName="overHeader"
              title={
                <span>
                  <FontAwesomeIcon
                    className="PublicHeader__PlatformMenuIcon"
                    icon={['far', 'heart']}
                    size="lg"
                  />
                  {t('contribute')}
                </span>
              }
            >
              {this.props.isBillingConfigured ? (
                <Menu.Item
                  key="donation"
                  style={{ '--secondary-color': platform.settings.color.secondary }}
                >
                  <Link
                    to={
                      (this.props.platform &&
                        this.props.platform.settings &&
                        this.props.platform.settings.campaign &&
                        Number(this.props.platform.settings.campaign.enabled)) ||
                      (this.props.lastCampaigns && this.props.lastCampaigns.length > 0)
                        ? `/${locale}/${t('URL:give')}`
                        : `/${locale}/${t('URL:organization/give')}`
                    }
                  >
                    <FontAwesomeIcon
                      className="PublicHeader__PlatformSubMenuIcon"
                      icon={['far', 'arrow-right']}
                    />
                    {t('give')}
                  </Link>
                </Menu.Item>
              ) : null}
              {this.props.userTypeId === 1 ? (
                <Menu.Item
                  key="register"
                  style={{ '--secondary-color': platform.settings.color.secondary }}
                >
                  <FontAwesomeIcon
                    className="PublicHeader__PlatformSubMenuIcon"
                    icon={['far', 'arrow-right']}
                  />
                  {t('createanaccount')}
                </Menu.Item>
              ) : null}
              {campaignEnabled ? (
                <Menu.Item
                  key="create-campaign"
                  style={{ '--secondary-color': platform.settings.color.secondary }}
                >
                  <Link to={`/${locale}/${t('URL:create-a-campaign')}`}>
                    <FontAwesomeIcon
                      className="PublicHeader__PlatformSubMenuIcon"
                      icon={['far', 'arrow-right']}
                    />
                    {t('createacampaign')}
                  </Link>
                </Menu.Item>
              ) : null}
            </Menu.SubMenu>
          ) : null}
          {(this.props.platform &&
            this.props.platform.settings &&
            this.props.platform.settings.campaign &&
            Number(this.props.platform.settings.campaign.enabled)) ||
          (this.props.lastCampaigns && this.props.lastCampaigns.length > 0) ? (
            <Menu.Item key="search">
              <Link to={`/${locale}/${t('URL:search/campaigns')}`}>
                <FontAwesomeIcon
                  className="PublicHeader__PlatformMenuIcon"
                  icon={['far', 'search']}
                  size="lg"
                />
                {t('search')}
              </Link>
            </Menu.Item>
          ) : null}
          <Menu.Item key="contact">
            <Link to={`/${locale}/${t('URL:contact-us')}`}>
              <FontAwesomeIcon
                className="PublicHeader__PlatformMenuIcon"
                icon={['far', 'map-marker-alt']}
                size="lg"
              />
              {t('contactus')}
            </Link>
          </Menu.Item>

          {officialWebsiteMenuItem}
        </Menu>

        <PlatformSocialMedia platformSocial={platformSocial} iconSize="lg" iconColor={true} />
      </React.Fragment>
    );

    const menuMobile = (
      <React.Fragment>
        <Menu
          className="PublicHeader__UserMenuMobile"
          onClick={this.handleUserMenuClick}
          mode="horizontal"
          selectable={false}
        >
          <Menu.Item key="locale" disabled={this.state.langButtonLoading}>
            {t('lng')}
          </Menu.Item>
        </Menu>
        <button
          className={`PublicHeader__ToggleMenu ${mobileMenuVisible}`}
          onClick={this.toggleMobileMenu}
        >
          <FontAwesomeIcon icon={['fal', 'bars']} />
          <FontAwesomeIcon icon={['fal', 'times-circle']} />
        </button>
        <Menu
          className={`PublicHeader__PlatformMenuMobile ${mobileMenuVisible}`}
          onClick={this.handlePlatformMenuMobileClick}
          mode="inline"
          selectable={false}
          defaultOpenKeys={['sub-contribute']}
        >
          {userMenuItem}
          {this.props.isBillingConfigured ||
          this.props.userTypeId === 1 ||
          campaignEnabled ? (
            <Menu.SubMenu
              key="sub-contribute"
              className="PublicHeader__PlatformSubMenuMobile"
              popupClassName="overHeader"
              title={
                <span>
                  <FontAwesomeIcon
                    className="PublicHeader__PlatformMenuIcon"
                    icon={['far', 'heart']}
                    size="lg"
                  />
                  {t('contribute')}
                </span>
              }
            >
              {this.props.isBillingConfigured ? (
                <Menu.Item
                  key="donation"
                  style={{ '--secondary-color': platform.settings.color.secondary }}
                >
                  <Link
                    to={
                      (this.props.platform &&
                        this.props.platform.settings &&
                        this.props.platform.settings.campaign &&
                        Number(this.props.platform.settings.campaign.enabled)) ||
                      (this.props.lastCampaigns && this.props.lastCampaigns.length > 0)
                        ? `/${locale}/${t('URL:give')}`
                        : `/${locale}/${t('URL:organization/give')}`
                    }
                  >
                    <FontAwesomeIcon
                      className="PublicHeader__PlatformSubMenuIcon"
                      icon={['far', 'arrow-right']}
                    />
                    {t('give')}
                  </Link>
                </Menu.Item>
              ) : null}
              {this.props.userTypeId === 1 ? (
                <Menu.Item
                  key="register"
                  style={{ '--secondary-color': platform.settings.color.secondary }}
                >
                  <FontAwesomeIcon
                    className="PublicHeader__PlatformSubMenuIcon"
                    icon={['far', 'arrow-right']}
                  />
                  {t('createanaccount')}
                </Menu.Item>
              ) : null}
              {campaignEnabled ? (
                <Menu.Item
                  key="create-campaign"
                  style={{ '--secondary-color': platform.settings.color.secondary }}
                >
                  <Link to={`/${locale}/${t('URL:create-a-campaign')}`}>
                    <FontAwesomeIcon
                      className="PublicHeader__PlatformSubMenuIcon"
                      icon={['far', 'arrow-right']}
                    />
                    {t('createacampaign')}
                  </Link>
                </Menu.Item>
              ) : null}
            </Menu.SubMenu>
          ) : null}
          {(this.props.platform &&
            this.props.platform.settings &&
            this.props.platform.settings.campaign &&
            Number(this.props.platform.settings.campaign.enabled)) ||
          (this.props.lastCampaigns && this.props.lastCampaigns.length > 0) ? (
            <Menu.Item key="search">
              <Link to={`/${locale}/${t('URL:search/campaigns')}`}>
                <FontAwesomeIcon
                  className="PublicHeader__PlatformMenuIcon"
                  icon={['far', 'search']}
                  size="lg"
                />
                {t('search')}
              </Link>
            </Menu.Item>
          ) : null}
          <Menu.Item key="contact">
            <Link to={`/${locale}/${t('URL:contact-us')}`}>
              <FontAwesomeIcon
                className="PublicHeader__PlatformMenuIcon"
                icon={['far', 'map-marker-alt']}
                size="lg"
              />
              {t('contactus')}
            </Link>
          </Menu.Item>
          <Menu.Item key="faq">
            <a href="https://fundky.tawk.help/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                className="PublicHeader__PlatformMenuIcon"
                icon={['far', 'question-circle']}
                size="lg"
              />
              {t('faq')}
            </a>
          </Menu.Item>

          {officialWebsiteMenuItem}

          <PlatformSocialMedia
            containerTag="li"
            platformSocial={platformSocial}
            iconSize="lg"
            iconColor={true}
          />
        </Menu>
      </React.Fragment>
    );

    const headerClasses = classnames('PublicHeaderRef', {
      'PublicHeaderRef--Scrolling': this.state.scrolling
    });

    const logo = <img src={logoSrc} style={{ maxHeight: '60px' }} alt={platform.name} />;
    const logoMarkup = logoLink ? (
      <a href={logoLink} rel="noopener noreferrer">
        {logo}
      </a>
    ) : (
      <Link to={`/${locale}`} onClick={this.forceCloseMenu}>
        {logo}
      </Link>
    );

    return (
      <div ref={this.publicHeaderRef} className={headerClasses} id="header">
        <Layout.Header className="PublicHeader">
          <div className="FundkyWrap">
            <div className="PublicHeader__Logo">{logoMarkup}</div>
            <div className="PublicHeader__Divider">
              {menuDesktop}
              {menuMobile}
            </div>
          </div>
        </Layout.Header>

        <Modal
          title={t('unsaved-changes-notice-title')}
          visible={this.state.formEditingNotice}
          onOk={this.handleFormEditingNoticeOk}
          onCancel={this.handleFormEditingNoticeCancel}
        >
          <b>
            <p>{t('unsaved-changes-notice-description')}</p>
          </b>
          <p>{t('unsaved-changes-notice-question')}</p>
        </Modal>
      </div>
    );
  }
}

PublicHeader.propTypes = {
  /*user: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),*/
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  openRegisterModal: PropTypes.func.isRequired
};

export default translate('PublicHeader')(PublicHeader);

import {
  FETCH_CAMPAIGN_REPORT_SUCCESS,
  FETCH_CAMPAIGN_REPORT_ERROR,
  FETCH_TEAM_REPORT_SUCCESS,
  FETCH_TEAM_REPORT_ERROR,
  FETCH_PARTICIPANT_REPORT_SUCCESS,
  FETCH_PARTICIPANT_REPORT_ERROR,
  FETCH_DONATION_REPORT_SUCCESS,
  FETCH_DONATION_REPORT_ERROR,
  FETCH_TRANSACTION_REPORT_SUCCESS,
  FETCH_TRANSACTION_REPORT_ERROR,
  FETCH_PARTICIPATION_REPORT_SUCCESS,
  FETCH_PARTICIPATION_REPORT_ERROR,
  FETCH_ATTENDEE_REPORT_SUCCESS,
  FETCH_ATTENDEE_REPORT_ERROR,
  FETCH_MEMBER_REPORT_SUCCESS,
  FETCH_MEMBER_REPORT_ERROR,
  CLEAR_REPORT
} from './reportActions';

const initialState = {
  campaigns: {},
  teams: {},
  participants: {},
  donations: {},
  transactions: {},
  participations: {},
  attendees: {},
  members: {}
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {

    case FETCH_PARTICIPANT_REPORT_SUCCESS:
      return {
        ...state,
        participants: action.payload
      };

    case FETCH_PARTICIPANT_REPORT_ERROR:
      return {
        ...state,
        participants: ""
      };

    case FETCH_TRANSACTION_REPORT_SUCCESS:
      return {
        ...state,
        transactions: action.payload
      };

    case FETCH_TRANSACTION_REPORT_ERROR:
      return {
        ...state,
        transactions: ""
      };

    case FETCH_ATTENDEE_REPORT_SUCCESS:
      return {
        ...state,
        attendees: action.payload
      };

    case FETCH_ATTENDEE_REPORT_ERROR:
      return {
        ...state,
        attendees: ""
      };

    case FETCH_MEMBER_REPORT_SUCCESS:
      return {
        ...state,
        members: action.payload
      };

    case FETCH_MEMBER_REPORT_ERROR:
      return {
        ...state,
        members: ""
      };

    case CLEAR_REPORT:
      return {
        ...initialState
      }

    default:
      return state;
  }
}

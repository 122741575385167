export const en_ca = {
  LOGS: {
    "History": "History",
    "Before": "Before",
    "After": "After",
    "has-created": " has created the ",
    "has-updated": " has updated the ",
    "has-donated": " has donated ",
    "has-deleted": " has deleted ",
    "has-invited": " has invited ",
    "has-cancelled": " has cancelled ",
    "has-downloaded": " has downloaded ",
    "has-sent": " has sent ",
    "has-accepted": " has accepted ",
    "has-rejected": " has rejected ",
    "been-updated-ms": " has been updated.",
    "been-updated-mp": " have been updated.",
    "been-updated-fs": " has been updated.",
    "been-updated-fp": " have been updated.",
    "organization": "organization",
    "platform": "platform",
    "campaign": "campaign",
    "team": "team",
    "participant": "participant",
    "donation": "donation",
    "Member#": "Member #",
    "member": "member",
    "donation-receipt": "donation receipt",
    "donation-receipt-settings": "donation receipt settings",
    "settings": "settings",
    "description": "description",
    "languages": "a language",
    "content": "content",
    "section": "section",
    "media": "media",
    "featured-campaigns": "featured campaigns",
    "information": "information",
    "notes": "note",
    "profile": "profile",
    "invitation": "invitation",
    "transaction-review": "transaction review",
    "in": " in ",
    "of": " of ",
    "to": " to ",
    "en$": "$",
    "fr$": "",
    "be-an-owner": " to be an owner of ",
    "be-a-manager": " to be a manager of ",
    "be-a-captain": " to be a captain of ",
    "sent-to": " sent to ",
    "added": "added",
    "removed": "removed",
    "modified": "modified",
    "deleted": "deleted",
    "configurations-updated": "Configurations updated.",
    "configurations-updated-by": "Configurations updated by {{name}}.",
    "offline": "offline",
    "logged-in": " logged in ",
    "facebook-scrape-call": "a scrape call to Facebook",
  }
};

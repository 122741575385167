import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import EmailField_en from './locales/EmailField_en.json';
import EmailField_fr from './locales/EmailField_fr.json';

class EmailField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'EmailField', EmailField_en);
    i18next.addResourceBundle('fr', 'EmailField', EmailField_fr);
  }

  render() {
    const { t, form, fieldId, className } = this.props;
    const { getFieldDecorator } = form;

    const placeholder = (
      this.props.placeholder === true ?
        t('email') :
        this.props.placeholder || null
    );

    const input = <Input placeholder={placeholder} onBlur={this.props.onBlur} />;
    const fieldOptions = {
      rules: [
        {
          required: this.props.required,
          message: this.props.requiredMessage || t('required')
        },
        {
          type: 'email',
          message: this.props.validationMessage || t('validation')
        }
      ],
      initialValue: this.props.initialValue
    };

    const label = (
      this.props.label === true ?
        t('email') :
        this.props.label || null
    );

    return (
      <Form.Item
        className={className}
        label={label}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

EmailField.defaultProps = {
  required: true,
  fieldId: 'email',
  label: true,
  placeholder: false,
  initialValue: undefined,
};

EmailField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('EmailField')(EmailField);

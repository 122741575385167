import React from 'react';
import { get } from 'lodash';

import Can from 'Parts/session/components/CanContainer';
import Plugin from '../../plugin/Plugin';

import {
  getPluginComponentNameByPluginPathName,
  getPluginPermissionsnameByPathname,
  getPluginKeyByPluginPathName
} from '../../plugin/pluginSelectors';
import { getPlugin, isPluginActive } from '../../plugin/pluginActions';

class PluginAdminPageWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.pathname = props.location.pathname;
    this.path = props.match.path;
    this.pluginPathName = this.pathname.replace(this.path, '').replace('/history', '');
    this.pluginComponentName = getPluginComponentNameByPluginPathName(this.pluginPathName);
    this.pluginPermissionsName = getPluginPermissionsnameByPathname(this.pluginPathName);
    this.pluginKey = getPluginKeyByPluginPathName(this.pluginPathName);
  }

  componentDidUpdate(prevProps) {
    if (
      get(this.props, 'location.pathname') &&
      get(this.props, 'location.pathname') !== get(prevProps, 'location.pathname')
    ) {
      this.pathname = this.props.location.pathname;
      this.path = this.props.match.path;
      this.pluginPathName = this.pathname.replace(this.path, '').replace('/history', '');
      this.pluginComponentName = getPluginComponentNameByPluginPathName(this.pluginPathName);
      this.pluginPermissionsName = getPluginPermissionsnameByPathname(this.pluginPathName);
      this.pluginKey = getPluginKeyByPluginPathName(this.pluginPathName);
    }
  }

  render() {
    const { props, pluginComponentName, pluginPermissionsName, pluginKey, importer } = this,
      { locale, platform } = props;

    // For the dynamic webpackChunkName, see here: https://webpack.js.org/api/module-methods/#magic-comments
    const Page = isPluginActive(platform, pluginKey) ? (
      <Plugin
        key={pluginComponentName}
        name={pluginComponentName}
        component="AdminPage"
        resolve={() =>
          import(
            /* webpackChunkName: "[request]" */ `../../plugin/plugins/${pluginComponentName}/${pluginComponentName}.jsx`
          )
        }
      />
    ) : null;

    return (
      // <Can rules={[`permissions.platform.plugin.${pluginPermissionsName}.config.get`, `permissions.platform.plugin.${pluginPermissionsName}.config.update`]} redirect={`/${locale}/d`}>
      <>{Page}</>
      // </Can>
    );
  }
}

export default PluginAdminPageWrapper;

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'

import dataFetcher from 'Parts/chore/dataFetcher';
import { updatePlatformMedia, deletePlatformMedia } from '../../../platform/platformActions';

import PagesHomepageSliderPanel from './PagesHomepageSliderPanel';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  languages: state.platform.languages,
  locale: state.session.language,
  isUpdatePlatformMediaInProgress: state.platform.isUpdatePlatformMediaInProgress
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformMedia,
  deletePlatformMedia
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(PagesHomepageSliderPanel);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Form } from "antd";
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { withRouter } from 'react-router';

import LoginForm from './LoginForm';
import { openRegisterModal, openRecoveryModal } from "../../ui/uiActions";
import { login } from '../sessionActions';

const mapStateToProps = state => ({
  browser: state.session.browser,
  isLoginInProgress: state.ui.isLoginInProgress,
  connectionModalForced: state.ui.connectionModalForced,
  connectionModalOrigin: state.ui.connectionModalOrigin,
  connectionModalState: state.ui.connectionModalState,
  platform: state.platform && state.platform.platform,
  organizationId: state.organization && state.organization.organization.id,
  platformId: state.platform && state.platform.platform && state.platform.platform.id,
  connectionStatus: state.session.connectionStatus,
  locale: state.session.language,
  kickedOut: state.ui.kickedOut
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openRegisterModal,
  openRecoveryModal,
  login
}, dispatch);

const mapPropsToFields = props => ({
  platformId: Form.createFormField({ value: props.platform && props.platform.id })
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withGoogleReCaptcha,
  Form.create({ mapPropsToFields })
)(LoginForm);

import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Tooltip } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

import { Link } from 'react-router-dom';
import CsvReportButton from '../../../report/components/CsvReportButtonContainer';
import SearchBar from 'Parts/search/SearchBarContainer';
import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import Can from 'Parts/session/components/CanContainer';
import Plugin from '../../../plugin/Plugin';
import { isPluginActive } from '../../../plugin/pluginActions';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import CampaignsTransactionsListDonationTab from './components/CampaignsTransactionsListDonationTab';

import campaignsTransactionsListPageEN from './locales/CampaignsTransactionsListPage_en.json';
import campaignsTransactionsListPageFR from './locales/CampaignsTransactionsListPage_fr.json';

import './CampaignsTransactionsListPage.less';
class CampaignsTransactionsListPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle(
      'en',
      'CampaignsTransactionsListPage',
      campaignsTransactionsListPageEN
    );
    i18next.addResourceBundle(
      'fr',
      'CampaignsTransactionsListPage',
      campaignsTransactionsListPageFR
    );

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (
      prevProps.pagination.totalResults !== this.props.pagination.totalResults ||
      prevProps.pagination.pageNumber !== this.props.pagination.pageNumber ||
      prevProps.pagination.pageResults !== this.props.pagination.pageResults
    ) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchTransactionsInCampaign(
      this.props.campaignId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchTransactionsInCampaign(
      this.props.campaignId,
      filters.search,
      filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  render() {
    const {
      t,
      match,
      transactions,
      locale,
      pagination,
      reportTransactionsInCampaign,
      campaignId,
      campaignStatusId,
      checkAuthorization,
      location,
      campaign,
      history
    } = this.props;
    const { pager } = this.state;
    const CsvReportButtonDisabled = !transactions || transactions.length == 0;
    const tooltipVisibility = !CsvReportButtonDisabled ? { visible: false } : {};


    const slug  = this.props.location.pathname.substring(
      this.props.location.pathname.lastIndexOf('/') + 1
    );

    const tabPages = [
      {
        title: t('Donations'),
        slug: 'donations',
        container: props => (
          <Can
            rules={['permissions.campaign.transaction.get']}
            campaignId={get(match, 'params.campaignID', null)}
            redirect={`/${locale}/d`}
          >
            <CampaignsTransactionsListDonationTab
              match={match}
              locale={locale}
              pagination={pagination}
              transactions={transactions}
              location={location}
              pager={pager}
              history={history}
              handlePaginationChange={this.handlePaginationChange}
            />
          </Can>
        )
      },
      ...(campaign.behaviorId === 2
        ? [{
            title: t('Tickets'),
            slug: 'tickets',
            // TODO verify if campaign behavior is event
            visible: isPluginActive(this.props.platform, 'Event'),
            container: props => <Plugin name="Event"
              component="EventAdminCampaignTransactionsListTicket"
              t={t}
              atch={match}
              locale={locale}
              pagination={pagination}
              transactions={transactions}
              location={location}
              pager={pager}
              history={history}
              handlePaginationChange={this.handlePaginationChange}
            />
          }]
        : []
      )
    ];

    const createButton = campaign.behaviorId === 2 && slug === 'tickets'
      ? (campaignStatusId === 1 || campaignStatusId === 4) &&
        checkAuthorization({ platform: this.props.platformId }, [
          ['roles.platform.OWNER'],
          ['roles.platform.SUPERADMIN']
        ])
          ? <Link
              to={`/${locale}/d/campaigns/${campaignId}/transactions/addticket`}
              className="ant-btn ant-btn-primary"
            >
              <span>{t('Add an offline ticket')}</span> <FontAwesomeIcon icon={['fas', 'plus']} />
            </Link>
          : null
      : (campaignStatusId === 1 || campaignStatusId === 4) &&
        checkAuthorization({ platform: this.props.platformId }, [
          ['roles.platform.OWNER'],
          ['roles.platform.SUPERADMIN']
        ])
          ? <Link
                to={`/${locale}/d/campaigns/${campaignId}/transactions/add`}
                className="ant-btn ant-btn-primary"
            >
              <span>{t('Add a transaction')}</span> <FontAwesomeIcon icon={['fas', 'plus']} />
            </Link>
          : null;

    return (
      <Layout className="CampaignsTransactionsListPage">
        <Helmet>
          <title>{t('Transactions')}</title>
        </Helmet>
        <SearchBar
          title={t('Transactions')}
          placeholder={t('Search')}
          createButton={createButton}
          reportButton={
            <Tooltip title={t('no-transaction')} {...tooltipVisibility}>
              <div>
                <CsvReportButton
                  key={1}
                  data={this.props.report}
                  id={this.props.campaignId}
                  pagination={this.props.pagination}
                  orderBy={{ orderBy: 'addedOn[desc]' }}
                  filters={this.props.filters}
                  fileName={`${this.props.platformAlias}.${this.props.campaignAlias}.transactions`}
                  fetch={reportTransactionsInCampaign}
                  disabled={CsvReportButtonDisabled ? true : null}
                />
              </div>
            </Tooltip>
          }
          onSearch={this.handleSearch}
        />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} />
      </Layout>
    );
  }
}

CampaignsTransactionsListPage.defaultProps = {
  donations: [],
  pagination: {
    totalResults: 0
  }
};

CampaignsTransactionsListPage.propTypes = {
  searchTransactionsInCampaign: PropTypes.func.isRequired
};

export default translate('CampaignsTransactionsListPage')(CampaignsTransactionsListPage);

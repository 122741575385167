import React from 'react';
import { Layout, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import { Link } from 'react-router-dom';
import Can from 'Parts/session/components/CanContainer';
import BehaviorFilter from 'Parts/search/filters/BehaviorFilter';

import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';
import { DEFAULT_PAGER } from 'Parts/search/searchUtils';

import campaignListpageEN from './locales/CampaignListPage_en.json';
import campaignListpageFR from './locales/CampaignListPage_fr.json';

class CampaignListPage extends React.Component {
  constructor(props) {
    super(props);

    props.campaigns.total = props.pagination.totalResults;

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle('en', 'CampaignListPage', campaignListpageEN);
    i18next.addResourceBundle('fr', 'CampaignListPage', campaignListpageFR);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (this.props.pagination.pageNumber !== prevProps.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchCampaigns(
      this.props.platformId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  handleViewHandler = campaign => {
    let currentUrl = this.props.location.pathname;
    let campaignUrl = currentUrl.replace('list', campaign.id);
    return this.props.history.push(campaignUrl);
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchCampaigns(
      this.props.platformId,
      filters.search,
      filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  getPublicCampaignUrl = campaign => {
    return this.props.location.pathname.replace('d/campaigns/list', campaign.alias);
  };

  render() {
    const {
      t,
      campaigns,
      pagination,
      locale,
      campaignEnabled,
      platformId,
      checkAuthorization,
      isCampaignOwner,
      location
    } = this.props;
    const { pager } = this.state;

    const tooltipMessage = <span>{t('campaign-disabled')}</span>;// <a href={t('campaign-disabled-link')} target="_blank" rel="noopener noreferrer">{t('campaign-disabled-more')}</a></span>;
    const createCampaignButtonLink = locale
      ? `/${locale}/${t('URL:create-a-campaign')}`
      : '';
    const createCampaignButton = checkAuthorization({ platform: platformId }, [
      [() => (Number(campaignEnabled) ? true : false)],
      ['roles.platform.OWNER'],
      ['roles.platform.SUPERADMIN']
    ])
      ? <Link key={1} to={createCampaignButtonLink} className="ant-btn ant-btn-primary">
          <span>{t('Create a campaign')}</span> <FontAwesomeIcon icon={['fas', 'plus']} />
        </Link>
      : <Tooltip placement="top" title={tooltipMessage} >
          <div>
            <Link key={1} to={createCampaignButtonLink} className="ant-btn ant-btn-primary" disabled>
              <span>{t('Create a campaign')}</span> <FontAwesomeIcon icon={['fas', 'plus']} />
            </Link>
          </div>
        </Tooltip>;

    return (
      <Layout className="CampaignListPage">
        <Helmet>
          <title>{t('Campaigns')}</title>
        </Helmet>
        <SearchBar
          title={t('Campaigns')}
          placeholder={t('Search')}
          createButton={createCampaignButton}
          onSearch={this.handleSearch}
          filters={[
            BehaviorFilter
          ]}
        />
        <Can
          rules={[
            [
              'permissions.platform.campaign.create',
              () => (Number(campaignEnabled) || isCampaignOwner ? true : false)
            ],
            ['roles.platform.OWNER'],
            ['roles.platform.SUPERADMIN']
          ]}
          redirect={`/${locale}/d`}
        >
          <div className="AdminList">
            <PaginationTop
              totalLabel={total => t('campaignsTotal', { count: pagination.totalResults })}
              pager={pager}
              onChange={this.handlePaginationChange}
            />
            <ResultTable
              dataSource={campaigns}
              pagination={pager}
              onChange={this.handlePaginationChange}
              showHeader={true}
              columns={[
                simpleColumn(t('Name'), 'name', null, true),
                simpleColumn(t('filters-behavior-type'), null, campaign => t(
                  campaign.behaviorId === 1
                    ? "filters-behavior-standard"
                    : campaign.behaviorId === 2
                      ? "filters-behavior-event"
                      : campaign.behaviorId === 3
                        ? "filters-behavior-stream"
                        : ""
                ), true),
                dateColumn(t('Created on'), 'addedOn', null, null, false, locale),
                simpleColumn(t('Status'), null, campaign => t(campaign.status), true),
                actionColumn(
                  t('Actions'),
                  null,
                  () => {},
                  campaign => {
                    const disabled =
                      campaign.statusId == 2 ||
                      campaign.statusId === 3 ||
                      campaign.statusId === 5 ||
                      campaign.statusId === 6
                        ? { disabled: true }
                        : {};
                    return (
                      <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                      {campaign.behaviorId === 2 &&
                      <Link
                        className="ant-btn ant-btn-circle"
                        to={location.pathname.replace('list', campaign.id + '/tickets')}
                      >
                        <FontAwesomeIcon icon={['fal', 'ticket-alt']} />
                      </Link>
                      }
                      <a
                        className="ant-btn ant-btn-circle"
                        href={this.getPublicCampaignUrl(campaign)}
                        target="_blank"
                        {...disabled}
                      >
                        <FontAwesomeIcon icon={['fal', 'external-link']} />
                      </a>
                      <Link
                        className="ant-btn ant-btn-circle"
                        to={location.pathname.replace('list', campaign.id)}
                      >
                        <FontAwesomeIcon icon={['fal', 'edit']} />
                      </Link>
                      </span>
                    );
                  }
                ),
              ]}
            />
          </div>
        </Can>
      </Layout>
    );
  }
}

CampaignListPage.defaultProps = {
  campaigns: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('CampaignListPage')(CampaignListPage);

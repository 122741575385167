import withSizes from 'react-sizes';
import { compose } from 'redux';
import { connect } from 'react-redux';

import DonationSection from './DonationSection';
import { getAntdBreakpoint } from 'Parts/common/responsiveUtils';
// import { getLastDonations } from "../../donation/donationSelectors";

const slidesToShowValues = {
  xxl: 3,
  xl: 2,
  lg: 1,
  md: 1,
  sm: 1,
  xs: 1
};

const mapSizesToProps = ({ width }) => ({
  slidesToShow: slidesToShowValues[getAntdBreakpoint(width)]
});

const mapStateToProps = (state, ownProps) => ({
  donations: state.platform.lastDonations,//getLastDonations(state, ownProps),
  locale: state.session.language,
  platform: state.platform.platform,
  lastCampaigns: state.campaign.campaigns,
});

export default compose(
  withSizes(mapSizesToProps),
  connect(mapStateToProps)
)(DonationSection);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { withRouter } from 'react-router';

import { updatePlatformSettings } from '../../../platform/platformActions';
import CampaignSettingsForm from './CampaignSettingsForm';
import dataFetcher from 'Parts/chore/dataFetcher';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  thirdPartyCampaigns: state.platform.platform.settings.campaign.creation.enabled === '1' ? true : false,
  preapprovedCampaign: state.platform.platform.settings.campaign.creation.preauth === '1' ? true : false,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformSettings
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher,
  Form.create()
)(CampaignSettingsForm);

import {
  bindActionCreators,
  compose
} from "redux";
import {
  connect
} from "react-redux";
import {
  Form
} from 'antd';
import dataFetcher from "../../../../parts/chore/dataFetcher";
import {
  searchParticipantsInTeam
} from '../../../../search/searchActions';
import {
  fetchCampaign
} from '../../../../campaign/campaignActions';
import {
  fetchTeam
} from '../../../../team/teamActions';
import {
  updateParticipant,
  fetchParticipant
} from '../../../../participant/participantActions';

import CampaignsTeamsRegistrationPage from './CampaignsTeamsRegistrationPageTable';

const mapStateToProps = state => ({
  teamId: state.team.team.id,
  participants: state.search.participants.results,
  pagination: state.search.participants.pagination,
  filters: state.search.participants.filters,
  search: state.search.participants.search,
  orderBy: state.search.participants.orderBy,
  locale: state.session.language,
  campaign: state.campaign.campaign,
  declinedParticipantLang: state.participant && state.participant.participant && state
    .participant.participant.member && state.participant.participant.member[0] && state
    .participant.participant.member[0].languageId,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchParticipantsInTeam,
  fetchCampaign,
  updateParticipant,
  fetchParticipant,
  fetchTeam
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher,
  Form.create()
)(CampaignsTeamsRegistrationPage);

import React from 'react';
import HeadMetas from '../../common/HeadMetasContainer';
import ReactHtmlParser from 'react-html-parser';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import StaticTitle from '../components/StaticTitle';
import StaticBreadcrumbs from '../components/StaticBreadcrumbs';
import { getTextLength } from '../../common/contentUtils';

import ThirdPartyGuidelinesPage_en from './locales/ThirdPartyGuidelinesPage_en.json';
import ThirdPartyGuidelinesPage_fr from './locales/ThirdPartyGuidelinesPage_fr.json';

import './ThirdPartyGuidelinesPage.less';

class ThirdPartyGuidelinesPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'ThirdPartyGuidelinesPage', ThirdPartyGuidelinesPage_en);
    i18next.addResourceBundle('fr', 'ThirdPartyGuidelinesPage', ThirdPartyGuidelinesPage_fr);
  }

  componentDidMount() {
    this.redirectWhenEmpty();
  }

  componentDidUpdate() {
    this.redirectWhenEmpty();
  };

  redirectWhenEmpty = () => {
    if(!this.props.contentGuidelines || getTextLength(this.props.contentGuidelines, true) <= 0) {
      this.props.history.push(`/${this.props.locale}`);
    }
  }

  render() {
    const
      { props } = this,
      { t, locale, location, contentGuidelines } = props,
      path = [
        {
          name: t('home'),
          url : `/${locale}`
        },
        {
          name: t('organization-guidelines'),
          url: location
        }
      ],
      guidelines = contentGuidelines ? ReactHtmlParser(contentGuidelines) || null : null;

    return (
      <div className="ThirdPartyGuidelinesPage FundkyWrap">
        <HeadMetas title={t('organization-guidelines')} />
        <StaticTitle title={t('organization-guidelines')} />
        <StaticBreadcrumbs path={path} />
        <div className="ThirdPartyGuidelinesPage__Content wysiwygStyle">
          {guidelines}
        </div>
      </div>
    );
  }
}

export default translate('ThirdPartyGuidelinesPage')(ThirdPartyGuidelinesPage);

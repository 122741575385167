import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import { moneyFormat } from 'Parts/common/moneyFormat';
import AvatarThumbnail from './AvatarThumbnailContainer';

import PublicDonationQuote_en from './locales/PublicDonationQuote_en.json';
import PublicDonationQuote_fr from './locales/PublicDonationQuote_fr.json';

import './PublicDonationQuote.less';

function getFontSizeClass(text) {
  if (!text) return '';
  // Responsive text size for screen width <= 520px
  // Lateral padding set to 48px
  // Responsive lateral padding set to 20px
  if (text.length < 50) {
    return 'large'; //36 | 24;
  } else if (text.length < 140) {
    return 'medium'; //24 | 18;
  } else {
    return 'small'; //18 | 14;
  }
}

class PublicDonationQuote extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicDonationQuote', PublicDonationQuote_en);
    i18next.addResourceBundle('fr', 'PublicDonationQuote', PublicDonationQuote_fr);
  }

  render() {
    const { t, locale } = this.props;

    const { donation, bigQuote, className, type } = this.props;

    const computedClassName = classnames(
      'PublicDonationQuote',
      className,
      { 'PublicDonationQuote--BigQuote': bigQuote },
      getFontSizeClass(donation.message)
    );

    // TODO This part below will need modification according to new donation infos structure.
    let href = null;
    const { participantAlias, teamAlias, campaignAlias } = donation.target.path;
    if (participantAlias) {
      if (teamAlias) {
        href = `/${locale}/${campaignAlias}/${teamAlias}/${participantAlias}`;
      } else {
        href = `/${locale}/${campaignAlias}/${t('URL:individual-participant')}/${participantAlias}`;
      }
    } else if (teamAlias) {
      href = `/${locale}/${campaignAlias}/${teamAlias}`;
    } else if (campaignAlias) {
      href = `/${locale}/${campaignAlias}`;
    }

    const toLink = href || `/${locale}`;
    const donationLink =
      href !== null ? (
        <span>
          {' '}
          {t('to')} <Link to={toLink}>{donation.target.name}</Link>
        </span>
      ) : null;

    const arrow = this.props.bigQuote ? (
      <svg className="PublicDonationQuote__Arrow" viewBox="0 0 38 22">
        <path d="M38 22 L32 0 L0 0 Z" />
      </svg>
    ) : null;

    const secondaryContent = donation.typeId === 2
      ? get(donation, 'transaction.information.organization.name')
      : get(donation, 'transaction.information.firstName') + ' ' + get(donation, 'transaction.information.lastName');

    return (
      <div className={computedClassName}>
        <div className="PublicDonationQuote__Info">
          {arrow}
          <AvatarThumbnail
            className="PublicDonationQuote__Donor"
            entity={{
              firstName: get(donation, 'transaction.information.firstName', ''),
              lastName: get(donation, 'transaction.information.lastName', ''),
              avatar: get(donation, 'transaction.information.avatar'),
              organizationName: get(donation, 'transaction.information.organization.name')
            }}
            primaryContent={moneyFormat(donation.amount, locale)}
            secondaryContent={
              <span>
                {secondaryContent}
                {type !== 'participant' && donationLink}
              </span>
            }
          />
        </div>
        {donation.message && <p>{donation.message}</p>}
      </div>
    );
  }
}

PublicDonationQuote.defaultProps = {
  bigQuote: false,
  className: null,
  type: ''
};

PublicDonationQuote.propTypes = {
  donation: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    text: PropTypes.string,
    //firstName: PropTypes.string.isRequired,
    //lastName: PropTypes.string.isRequired
  }).isRequired,
  bigQuote: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string
};

export default translate('PublicDonationQuote')(PublicDonationQuote);

import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from "../../parts/chore/dataFetcher";
import { searchParticipants, clearSearch } from '../../search/searchActions';

import ParticipantsListPage from './ParticipantsListPage';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  platformAlias: state.platform.platform.alias,
  participants: state.search.participants.results,
  pagination: state.search.participants.pagination,
  filters: state.search.participants.filters,
  search: state.search.participants.search,
  orderBy: state.search.participants.orderBy,
  locale: state.session.language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchParticipants,
  clearSearch
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(ParticipantsListPage);

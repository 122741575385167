import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import ScrollMemory from 'react-router-scroll-memory';

import SessionExpirationWarningModal from '../parts/session/components/SessionExpirationWarningModalContainer';

import DashboardPage from '../dashboard/DashboardPageContainer';
import CampaignsListPage from '../dashboard/campaigns/CampaignListPageContainer';
import CampaignsPage from '../dashboard/campaigns/about/CampaignAdminPageContainer';
import CampaignsTeamsPage from '../dashboard/campaigns/teams/CampaignsTeamsPageContainer';
import CampaignsTeamsListPage from '../dashboard/campaigns/teams/CampaignsTeamsListPageContainer';
import CampaignsSettingsPage from '../dashboard/campaigns/settings/CampaignsSettingsPageContainer';
import CampaignsSettingsTransactionsPage from '../dashboard/campaigns/settings/CampaignsSettingsTransactionsPageContainer';
import CampaignsSettingsAdvancedPage from '../dashboard/campaigns/settings/CampaignsSettingsAdvancedPageContainer';
/* import CampaignsSettingsEmailsPage from '../dashboard/campaigns/settings/CampaignsSettingsEmailsPageContainer'; */
import CampaignsParticipantsListPage from '../dashboard/campaigns/participants/CampaignsParticipantsListPageContainer';
import CampaignsParticipantsPage from '../dashboard/campaigns/participants/CampaignsParticipantsPageContainer';
import CampaignsTransactionsListPage from '../dashboard/campaigns/transactions/CampaignsTransactionsListPageContainer';
import CampaignsTransactionsPage from '../dashboard/campaigns/transactions/CampaignsTransactionsPageContainer';

import MembersPage from '../dashboard/members/MembersPageContainer';
import MembersListPage from '../dashboard/members/MembersListPageContainer';

import PagesHomepage from '../dashboard/pages/PagesHomepageContainer';
import PagesGuidelines from '../dashboard/pages/PagesGuidelines';
import PagesRefund from '../dashboard/pages/PagesRefundContainer';
import PagesDonationConfirmationEmail from '../dashboard/pages/PagesDonationConfirmationEmail';
import SettingsPage from '../dashboard/settings/GeneralSettingsPageContainer';
import SettingsCampaignsPage from '../dashboard/settings/CampaignSettingsPageContainer';
// import SettingsTransactionsPage from '../dashboard/settings/TransactionsSettingsPageContainer';
// import SettingsCustomEmailsPage from '../dashboard/settings/CustomEmailsSettingsPageContainer';
import SettingsDesignPage from '../dashboard/settings/DesignSettingsPageContainer';

import PluginsListPage from '../dashboard/plugins/PluginsListPageContainer';
import PluginAdminPageWrapper from '../dashboard/plugins/PluginAdminPageWrapperContainer';

import SurveysListPage from '../dashboard/surveys/SurveysListPageContainer';
import SurveyPage from '../dashboard/surveys/SurveyPage/SurveyPageContainer';

import TransactionsListPage from '../dashboard/transactions/TransactionsListPageContainer';
import AddTransactionsPage from '../dashboard/transactions/AddTransactionsPageContainer';
import TransactionsPage from '../dashboard/transactions/TransactionsPageContainer';

import TransactionsSettlementPage from '../dashboard/transactions/TransactionsSettlementPageContainer';
import TeamsListPage from '../dashboard/teams/TeamsListPageContainer';
import TeamsPage from '../dashboard/teams/TeamsPageContainer';
import ParticipantsListPage from '../dashboard/participants/ParticipantsListPageContainer';
import ParticipantsPage from '../dashboard/participants/ParticipantsPageContainer';

import TransactionsFilterPage from '../dashboard/transactions/TransactionsFilterContainer';

import MyContributionsListPage from '../dashboard/contributions/MyContributionsListPageContainer';
import MyContributionsDonationsPage from '../dashboard/contributions/MyContributionsDonationsPageContainer';
import MyContributionsParticipationsPage from '../dashboard/contributions/MyContributionsParticipationsPageContainer';
import MyContributionsTeamsPage from '../dashboard/contributions/MyContributionsTeamsPageContainer';
import MyProfilePage from '../dashboard/profile/MyProfilePageContainer';

import AdminHeader from './components/AdminHeaderContainer';
import AdminSider from './components/AdminSider';

import Plugin from '../plugin/Plugin';
import { isPluginActive } from '../plugin/pluginActions';

import './AdminLayout.less';

const { Content } = Layout;

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.forceCloseConnectionModal();
    this.props.fetchPlatform(this.props.platform.id, true, {
      showSettings: true,
      showPlugins: true,
    });
    this.props.fetchPlatformMedia(this.props.platform.id, true);
    this.props.fetchMemberOrganizationRoles(this.props.organizationId, this.props.userId);
    this.props.fetchMemberPlatformRoles(this.props.platform.id, this.props.userId);
    this.props.isCampaignOwner(this.props.platform.id);
  }

  componentWillUnmount() {
    this.props.clearMemberAdminPermissions();
  }

  render() {
    const { locale, user, permissions, roles, match, campaignEnabled, platform, _isCampaignOwner } =
      this.props;
    const { path } = match;

    const event = isPluginActive(platform, 'Event');

    return (
      <React.Fragment>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <SessionExpirationWarningModal />
        <Layout id="MainLayout" className="AdminLayout" hasSider>
          <AdminSider
            locale={locale}
            path={path}
            user={user}
            permissions={permissions}
            roles={roles}
            campaignEnabled={campaignEnabled}
            platform={platform}
            isCampaignOwner={_isCampaignOwner}
          />
          <Layout id="scrollable-content">
            <AdminHeader />
            <Content>
              <Switch>
                {/* DASHBOARD */}
                <Route exact path={path} component={DashboardPage} />

                {/* QUICKLINKS */}
                <Route exact path={`${path}/transactions/list`} component={TransactionsListPage} />

                <Route path={`${path}/transactions/filter`} component={TransactionsFilterPage} />
                <Route exact path={`${path}/transactions/add`} component={AddTransactionsPage} />
                <Route
                  exact
                  path={`${path}/transactions/withdrawal-report`}
                  component={TransactionsSettlementPage}
                />
                <Route
                  exact
                  path={`${path}/transactions/:transactionID/history`}
                  component={TransactionsPage}
                />
                <Route
                  exact
                  path={`${path}/transactions/:transactionID/donationreceipt`}
                  component={TransactionsPage}
                />
                <Route
                  exact
                  path={`${path}/transactions/:transactionID/invoices`}
                  component={TransactionsPage}
                />
                <Route
                  exact
                  path={`${path}/transactions/:transactionID`}
                  component={TransactionsPage}
                />

                <Route exact path={`${path}/teams/list`} component={TeamsListPage} />
                <Route exact path={`${path}/teams/:teamID/history`} component={TeamsPage} />
                <Route exact path={`${path}/teams/:teamID/registration`} component={TeamsPage} />
                {/* <Route exact path={`${path}/teams/:teamID/donations`} component={TeamsPage} /> */}
                <Route exact path={`${path}/teams/:teamID`} component={TeamsPage} />
                <Route exact path={`${path}/participants/list`} component={ParticipantsListPage} />
                <Route
                  exact
                  path={`${path}/participants/:participantID/history`}
                  component={ParticipantsPage}
                />
                <Route
                  exact
                  path={`${path}/participants/:participantID`}
                  component={ParticipantsPage}
                />

                <Route
                  exact
                  path={`${path}/contributions/list/donations`}
                  component={MyContributionsListPage}
                />
                <Route
                  exact
                  path={`${path}/contributions/list/participations`}
                  component={MyContributionsListPage}
                />
                <Route
                  exact
                  path={`${path}/contributions/list/tickets`}
                  component={MyContributionsListPage}
                />
                <Route
                  exact
                  path={`${path}/contributions/donations/:donationID/invoices`}
                  component={MyContributionsDonationsPage}
                />
                <Route
                  exact
                  path={`${path}/contributions/donations/:donationID`}
                  component={MyContributionsDonationsPage}
                />
                <Route
                  exact
                  path={`${path}/contributions/participations/:participantID`}
                  component={MyContributionsParticipationsPage}
                />
                <Route
                  exact
                  path={`${path}/contributions/list/tickets/:ticketID`}
                  component={MyContributionsListPage}
                />
                <Route
                  exact
                  path={`${path}/contributions/teams/:teamID/history`}
                  component={MyContributionsTeamsPage}
                />
                <Route
                  exact
                  path={`${path}/contributions/teams/:teamID/registration`}
                  component={MyContributionsTeamsPage}
                />
                <Route
                  exact
                  path={`${path}/contributions/teams/:teamID`}
                  component={MyContributionsTeamsPage}
                />
                <Route
                  exact
                  path={`${path}/profile/:memberID/my-information`}
                  component={MyProfilePage}
                />

                {/* CAMPAIGNS */}
                <Route exact path={`${path}/campaigns/list`} component={CampaignsListPage} />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/settings/registration`}
                  component={CampaignsSettingsPage}
                />
                <Route
                  exact={false}
                  path={`${path}/campaigns/:campaignID/settings/transactions`}
                  component={CampaignsSettingsTransactionsPage}
                />
                <Route
                  path={`${path}/campaigns/:campaignID/settings/advanced`}
                  component={CampaignsSettingsAdvancedPage}
                />
                {/* <Route path={`${path}/campaigns/:campaignID/settings/emails`} component={CampaignsSettingsEmailsPage} /> */}
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/teams/list`}
                  component={CampaignsTeamsListPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/teams/:teamID`}
                  component={CampaignsTeamsPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/teams/:teamID/registration`}
                  component={CampaignsTeamsPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/teams/:teamID/history`}
                  component={CampaignsTeamsPage}
                />

                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/participants/list`}
                  component={CampaignsParticipantsListPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/participants/:participantID/history`}
                  component={CampaignsParticipantsPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/participants/:participantID`}
                  component={CampaignsParticipantsPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/transactions/list/donations`}
                  component={CampaignsTransactionsListPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/transactions/list/tickets`}
                  component={CampaignsTransactionsListPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/transactions/add`}
                  component={AddTransactionsPage}
                />
                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/transactions/addticket`}
                    render={(props) => (
                      <Plugin {...props} name="Event" component="EventAdminAddOfflineTicketPage" />
                    )}
                  />
                )}
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/transactions/:transactionID/history`}
                  component={CampaignsTransactionsPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/transactions/:transactionID/donationreceipt`}
                  component={CampaignsTransactionsPage}
                />
                <Route
                  exact
                  path={`${path}/campaigns/:campaignID/transactions/:transactionID`}
                  component={CampaignsTransactionsPage}
                />

                {/* PLUGIN EVENT DASHBOARD ATTENDEES PAGES */}
                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/attendees/checkin`}
                    render={(props) => (
                      <Plugin {...props} name="Event" component="EventAdminAttendeesCheckinPage" />
                    )}
                  />
                )}

                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/attendees/teams/list`}
                    render={(props) => (
                      <Plugin
                        {...props}
                        name="Event"
                        component="EventAdminAttendeesTeamsListPage"
                      />
                    )}
                  />
                )}

                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/attendees/teams/:teamID`}
                    render={(props) => (
                      <Plugin {...props} name="Event" component="EventAdminAttendeesTeamPage" />
                    )}
                  />
                )}
                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/attendees/teams/:teamID/registration`}
                    render={(props) => (
                      <Plugin {...props} name="Event" component="EventAdminAttendeesTeamPage" />
                    )}
                  />
                )}

                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/attendees/teams/:teamID/history`}
                    render={(props) => (
                      <Plugin {...props} name="Event" component="EventAdminAttendeesTeamPage" />
                    )}
                  />
                )}

                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/attendees/participants/list`}
                    render={(props) => (
                      <Plugin
                        {...props}
                        name="Event"
                        component="EventAdminAttendeesParticipantsListPage"
                      />
                    )}
                  />
                )}

                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/attendees/participants/:participantID/history`}
                    render={(props) => (
                      <Plugin
                        {...props}
                        name="Event"
                        component="EventAdminAttendeesParticipantPage"
                      />
                    )}
                  />
                )}

                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/attendees/participants/:participantID`}
                    render={(props) => (
                      <Plugin
                        {...props}
                        name="Event"
                        component="EventAdminAttendeesParticipantPage"
                      />
                    )}
                  />
                )}

                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/attendees/:ticketID`}
                    render={(props) => (
                      <Plugin {...props} name="Event" component="EventAdminAttendeesTicketPage" />
                    )}
                  />
                )}

                {/* PLUGIN EVENT DASHBOARD COMMUNICATIONS PAGES */}
                {event && (
                  <Route
                    exact
                    path={`${path}/campaigns/:campaignID/communications`}
                    render={(props) => (
                      <Plugin {...props} name="Event" component="EventAdminCommunicationsPage" />
                    )}
                  />
                )}

                <Route
                  exact={false}
                  path={`${path}/campaigns/:campaignID`}
                  component={CampaignsPage}
                />

                {/* MEMBERS */}
                <Route exact path={`${path}/members/list`} component={MembersListPage} />
                <Route
                  exact
                  path={`${path}/members/:memberID/information`}
                  component={MembersPage}
                />
                <Route exact path={`${path}/members/:memberID/donations`} component={MembersPage} />
                <Route
                  exact
                  path={`${path}/members/:memberID/participations`}
                  component={MembersPage}
                />
                <Route
                  exact
                  path={`${path}/members/:memberID/contributions`}
                  component={MembersPage}
                />

                {/* PAGES */}
                <Route exact path={`${path}/pages/refund-policy`} component={PagesRefund} />
                <Route path={`${path}/pages/homepage`} component={PagesHomepage} />
                <Route
                  path={`${path}/pages/guidelines`}
                  render={(props) => <PagesGuidelines {...props} locale={locale} />}
                />
                <Route
                  exact
                  path={`${path}/pages/donation-confirmation-email`}
                  render={(props) => <PagesDonationConfirmationEmail {...props} locale={locale} />}
                />

                {/* SETTINGS */}
                <Route exact path={`${path}/settings/general`} component={SettingsPage} />
                <Route
                  exact
                  path={`${path}/settings/campaigns`}
                  component={SettingsCampaignsPage}
                />
                {/*
                <Route exact path={`${path}/settings/transactions`} component={SettingsTransactionsPage} />
                <Route exact path={`${path}/settings/custom-emails`} component={SettingsCustomEmailsPage} />*/}
                <Route exact path={`${path}/settings/design`} component={SettingsDesignPage} />

                {/* SURVEYS */}
                <Route exact path={`${path}/surveys`} component={SurveysListPage} />
                <Route exact path={`${path}/survey/information`} component={SurveyPage} />
                <Route exact path={`${path}/survey/:id/information`} component={SurveyPage} />
                <Route exact path={`${path}/survey/:id/field`} component={SurveyPage} />
                <Route exact path={`${path}/survey/:id/result`} component={SurveyPage} />

                {/* PLUGINS */}
                <Route exact path={`${path}/plugins`} component={PluginsListPage} />
                <Route exact={false} path={`${path}/plugins/`} component={PluginAdminPageWrapper} />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    );
  }
}

AdminLayout.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AdminLayout;

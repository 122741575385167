import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';

import CancelAndSaveButtons from '../../../components/CancelAndSaveButtons';
import CheckboxField from 'Parts/ui/components/fields/CheckboxField';
import MoneyField from 'Parts/ui/components/fields/MoneyField';

import CampaignsSettingsTransactionsGoalFormEN from './locales/CampaignsSettingsTransactionsGoalForm_en.json';
import CampaignsSettingsTransactionsGoalFormFR from './locales/CampaignsSettingsTransactionsGoalForm_fr.json';

class CampaignsSettingsTransactionsGoalForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle(
      'en',
      'CampaignsSettingsTransactionsGoalForm',
      CampaignsSettingsTransactionsGoalFormEN
    );
    i18next.addResourceBundle(
      'fr',
      'CampaignsSettingsTransactionsGoalForm',
      CampaignsSettingsTransactionsGoalFormFR
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.form.resetFields();
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    const { validateFields, getFieldsValue, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));
        this.props.updateCampaign(updatedValues, this.props.campaign.id);
      }
    });
  };

  render() {
    const {
      t,
      form,
      overallGoal,
      locale,
      participantsCanChangeGoal,
      campaign,
      campaignSettings
    } = this.props;

    const isCampaignInactive =
      campaign &&
      (campaign.statusId === 3 ||
        campaign.statusId === 4 ||
        campaign.statusId === 5 ||
        (campaignSettings &&
          campaignSettings.closureOn &&
          isDateBeforeCurrentDate(campaignSettings.closureOn, true)));

    return (
      <Form className="CampaignsSettingsTransactionsGoalForm" onSubmit={this.handleSubmit}>
        <div className="inputs">
          <MoneyField
            form={form}
            fieldId="participants_goal"
            initialValue={overallGoal}
            locale={locale}
            width={150}
            label={t('goal-label')}
            required={true}
            requiredMessage={t('goal-required')}
            wholeNumber={true}
            wholeNumberMessage={t('goal-whole-number')}
            minimum={5}
            minimumMessage={t('goal-minimum', { min: 5 })}
            invalidMessage={t('goal-invalid')}
            disabled={isCampaignInactive}
          />

          <CheckboxField
            fieldId={'chk_participants_change_goal'}
            form={form}
            initialValue={participantsCanChangeGoal}
            checkboxText={t('participants-change-goal-label')}
            notice={t('participants-change-goal-notice')}
            disabled={isCampaignInactive}
          />
        </div>

        {!isCampaignInactive && <CancelAndSaveButtons />}
      </Form>
    );
  }
}

CampaignsSettingsTransactionsGoalForm.propTypes = {
  form: PropTypes.object.isRequired,
  overallGoal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  participantsCanChangeGoal: PropTypes.bool.isRequired
};

export default translate('CampaignsSettingsTransactionsGoalForm')(
  CampaignsSettingsTransactionsGoalForm
);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import classnames from 'classnames';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPlatformUrl } from 'Parts/common/environment';

import SocialSharing_en from './locales/SocialSharing_en.json';
import SocialSharing_fr from './locales/SocialSharing_fr.json';

import './SocialSharing.less';

class SocialSharing extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'SocialSharing', SocialSharing_en);
    i18next.addResourceBundle('fr', 'SocialSharing', SocialSharing_fr);

    this.hiddenInput = React.createRef();
  }

  replaceVars = (string, uname = null, oname = null) => {
    if (uname !== null) string = string.replace('UNAME', uname);
    if (oname !== null) string = string.replace('ONAME', oname);
    return string;
  };

  handleFbShare = (url, quote) => {
    FB.ui(
      {
        method: 'share',
        href: url,
        quote,
        hashtag: this.props.t('facebook.hashtags')
      },
      function(response) {}
    );
  };

  copyUrl = () => {
    const { t } = this.props;
    var $inputValue = this.hiddenInput.current;
    $inputValue.select();
    document.execCommand('copy');
    let messageText = t('url-copied');
    message.success({
      content: messageText,
      icon: (<FontAwesomeIcon icon={['fal', 'check-circle']} className="anticon"/>),
      duration: 1
    });
  };

  render() {
    const {
      t,
      identity,
      urlToShare,
      showTitle,
      checkoutTarget,
      platformAlias,
      organizationName,
      campaignName,
      teamName,
      participantName
    } = this.props;

    const className = classnames('SocialSharing', this.props.className);
    const platformUrl = getPlatformUrl(platformAlias);
    var shareText = t(`${identity}.text`);
    var encodedShareText = shareText;
    shareText = shareText.replace('#', ''); //Remove hashtags in text from facebook

    switch (identity) {
      case 'participant':
        shareText = this.replaceVars(shareText, participantName, organizationName);
        encodedShareText = this.replaceVars(encodedShareText, participantName, organizationName);
        break;

      case 'team':
        shareText = this.replaceVars(shareText, teamName, organizationName);
        encodedShareText = this.replaceVars(encodedShareText, teamName, organizationName);
        break;

      case 'campaign':
        shareText = this.replaceVars(shareText, campaignName, organizationName);
        encodedShareText = this.replaceVars(encodedShareText, campaignName, organizationName);
        break;

      case 'general-donation':
        shareText = this.replaceVars(shareText, null, checkoutTarget.name);
        encodedShareText = this.replaceVars(encodedShareText, null, checkoutTarget.name);
        break;

      case 'donation':
        shareText = this.replaceVars(shareText, checkoutTarget.name, organizationName);
        encodedShareText = this.replaceVars(
          encodedShareText,
          checkoutTarget.name,
          organizationName
        );
        break;

      default:
        break;
    }

    encodedShareText = encodeURIComponent(encodedShareText);

    // Adding hidden attribute will make it not selectable
    const hiddenInput = (
      <input className="hiddenInput" ref={this.hiddenInput} readOnly value={urlToShare} />
    );

    const title = showTitle ? <p className="SocialSharing__Title">{t('share')}</p> : null;

    return (
      <div className={className}>
        {title}
        <div className="SocialSharing__Wrap">
          <a
            className="SocialSharing__Facebook facebook-bg"
            onClick={() => this.handleFbShare(urlToShare, shareText)}
          >
            <FontAwesomeIcon icon={['fab', 'facebook-f']} />
          </a>
          <a
            className="SocialSharing__Twitter twitter-bg"
            rel="me"
            href={`https://twitter.com/intent/tweet?text=${encodedShareText}&url=${urlToShare}&hashtags=fundkyfam`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </a>
          <a
            className="SocialSharing__Linkedin linkedin-bg"
            href={`http://www.linkedin.com/shareArticle?mini=true&url=${urlToShare}&source=${platformUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
          </a>
          <a className="SocialSharing__Copy" onClick={this.copyUrl}>
            <FontAwesomeIcon icon={['fal', 'copy']} />
          </a>
          {hiddenInput}
        </div>
      </div>
    );
  }
}

SocialSharing.defaultProps = {
  showTitle: true,
  checkoutTarget: {}
};

SocialSharing.propTypes = {
  identity: PropTypes.string.isRequired,
  urlToShare: PropTypes.string.isRequired,
  showTitle: PropTypes.bool
};

export default translate('SocialSharing')(SocialSharing);

import React from 'react';
import { Form } from 'antd';
import pickBy from 'lodash/pickBy';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import {
  getLanguageNameByKey,
  getDefaultLanguageKey,
  getLanguageIdByKey
} from '../../../parts/common/langUtils';
import { get } from 'lodash';

import SettingsColumn from '../../components/SettingsColumn';
import LanguageBoxForm from '../../components/LanguageBoxFormContainer';
import Wysiwyg, { wrapText } from '../../../parts/ui/components/fields/Wysiwyg';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';

import PagesDonationConfirmationEmailForm_en from './locales/PagesDonationConfirmationEmailForm_en.json';
import PagesDonationConfirmationEmailForm_fr from './locales/PagesDonationConfirmationEmailForm_fr.json';

const PLATFORM_NOTE_TYPE = 1;

class PagesDonationConfirmationEmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang: getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale
    };

    this.changeHasHappened = false;

    this.WysiwygRef = React.createRef();

    i18next.addResourceBundle('en', 'PagesDonationConfirmationEmailForm', PagesDonationConfirmationEmailForm_en);
    i18next.addResourceBundle('fr', 'PagesDonationConfirmationEmailForm', PagesDonationConfirmationEmailForm_fr);
  }

  handleContentLangChange = lang => {
    this.setState(
      {
        contentLang: lang
      },
      () => (this.changeHasHappened = false)
    );
  };

  checkForChange = value => {
    if (this.props.form.isFieldTouched('note')) this.changeHasHappened = true;
  };

  getNotes = (lang = null) => {
    const lng = lang
      ? getLanguageNameByKey(lang, false)
      : getLanguageNameByKey(this.state.contentLang, false);

    return get(this.props, `notes.donation[${lng}].note`, '');
  };

  handleSubmit = e => {
    e.preventDefault();
    this.changeHasHappened = false;
    const { validateFields, isFieldTouched } = this.props.form;

    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (updatedValues.note === this.props.note) {
          delete updatedValues.note;
        }

        const values = updatedValues.note === '<p><br></p>' ? null : updatedValues.note;

        // Delete platform note
        if (values === null) {
          this.props.deletePlatformNote(
            this.props.platform.id,
            PLATFORM_NOTE_TYPE,
            getLanguageIdByKey(this.state.contentLang)
          );
        } else {
          // update platform note
          this.props.updatePlatformNote(
            this.props.platform.id,
            values,
            this.state.contentLang,
            PLATFORM_NOTE_TYPE
          );
        }
      }
    });
  };

  handleCancel = () => {
    this.changeHasHappened = false;
    this.props.form.resetFields();
    this.WysiwygRef.current.getWrappedInstance('Wysiwyg').resetEditorState();
  };

  render() {
    const { props, handleSubmit, handleCancel, handleContentLangChange, getNotes } = this,
      { t, form, locale, accentColors, userRoleId } = props;

    return (
      <div className="panel-content AdminFormWrap">
        <Form className="PagesDonationConfirmationEmailForm" layout="horizontal" onSubmit={handleSubmit}>
          <p>{t('notes-description')}</p>
          <div className="inputs">
            <Wysiwyg
              ref={this.WysiwygRef}
              form={form}
              fieldId={'note'}
              initialValue={getNotes()}
              label={t('notes-label')}
              counterWithTag={true}
              accentColors={accentColors}
              required={false}
              allowHeadings={true}
              allowColors={true}
              allowAlignments={true}
              allowLinks={false}
              allowLists={true}
              allowSizes={true}
              allowImages={true}
              allowVideos={false}
              allowHTML={userRoleId === 1}
              onChange={this.checkForChange}
              contentLang={this.state.contentLang}
            />
            <CancelAndSaveButtons onClickCancel={handleCancel} />
          </div>
        </Form>
        <SettingsColumn>
          <LanguageBoxForm
            handleContentLanguageChange={handleContentLangChange}
            changeHasHappened={this.changeHasHappened}
            defaultLanguage={getDefaultLanguageKey(get(this.props, 'languages')) || locale}
          />
        </SettingsColumn>
      </div>
    );
  }
}

export default translate('PagesDonationConfirmationEmailForm')(PagesDonationConfirmationEmailForm);

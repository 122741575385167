import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import SelectField from 'Parts/ui/components/fields/SelectField';

class BehaviorFilter extends React.Component {
    render() {
        const { t, form } = this.props;

        return <SelectField
            form={form}
            className="advancedFilters_select"
            fieldId="behavior"
            initialValue="NULL"
            label={t('filters.behavior.type')}
            optionsArray={[
                { value: "NULL" , text: t('filters.behavior.all') },
                { value: "DEFAULT" , text: t('filters.behavior.standard') },
                { value: "EVENT" , text: t('filters.behavior.event') },
                // { value: 3 , text: t('filters.behavior.stream') },
            ]}
        />;
    }
};

BehaviorFilter.defaultProps = {
    form: PropTypes.object.isRequired
};

export default translate('SearchBar')(BehaviorFilter);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { get } from 'lodash';

import { updatePlatformSettings } from '../../../platform/platformActions';

import PlatformSettingsForm from './PlatformSettingsForm';

const mapStateToProps = state => ({
  platform: get(state, 'platform.platform'),
  organization: get(state, 'organization.organization')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformSettings
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(PlatformSettingsForm);

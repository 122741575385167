import moment from 'moment';

export function isDateBeforeCurrentDate(dateToCompare, withTime = false) {
  var today = new Date();
  var date = today.getFullYear() + '-' +
    (("0" + (today.getMonth() + 1)).slice(-2)) + '-' +
     ("0" + (today.getDate())).slice(-2);
  var currentDate = date;
  dateToCompare = dateToCompare.replace(' ', 'T');

  if (withTime) {
    var time = ("0" + today.getHours()).slice(-2) + ":" +
               ("0" + today.getMinutes()).slice(-2) + ":" +
               ("0" + today.getSeconds()).slice(-2);
    currentDate += 'T'+time;
  }

  return moment(dateToCompare).isBefore(currentDate);
}

export function isDateAfterCurrentDate(dateToCompare, withTime = false) {
  var today = new Date();
  var date = today.getFullYear() + '-' +
    (("0" + (today.getMonth() + 1)).slice(-2)) + '-' +
     ("0" + (today.getDate())).slice(-2);
  var currentDate = date;
  dateToCompare = dateToCompare.replace(' ', 'T');

  if (withTime) {
    var time = ("0" + today.getHours()).slice(-2) + ":" +
               ("0" + today.getMinutes()).slice(-2) + ":" +
               ("0" + today.getSeconds()).slice(-2);
    currentDate += 'T'+time;
  }

  return moment(dateToCompare).isAfter(currentDate);
}

export function getDateValue(dateValue, format = 'YYYY-MM-DD HH:mm:ss') {
  if (dateValue) {
    return moment(dateValue).format(format)
  } else if (dateValue === null) {
    return null;
  } else {
    return undefined;
  }
}

export class Timer {
  constructor(callback, delay) {
    this.id;
    this.start;
    this.state = null;
    this.delay = delay;
    this.remaining = delay;
    this.callback = callback;
  }

  start(delay = null) {
    if (delay || this.delay) {
      this.resume(delay || this.delay);
    }
  }

  pause() {
    if (this.id) {
      window.clearTimeout(this.id);
      this.remaining -= Date.now() - this.start;
      this.state = "paused";
    }
  }

  resume(newDelay = null) {
    this.remaining = newDelay || this.remaining;
    this.start = Date.now();
    window.clearTimeout(this.id);
    this.id = window.setTimeout(this.callback, this.remaining);
    this.state = "resumed";
  }

  clear() {
    window.clearTimeout(this.id);
    this.id = null;
    this.delay = null;
    this.remaining = null;
    this.state = "cleared";
  }
};

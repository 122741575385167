import {
  fetchCampaign,
  clearCampaign,
} from "../campaign/campaignActions";
import { CAMPAIGN_TEMPLATE_TEAMS, CAMPAIGN_TEMPLATE_MIXED } from "../campaign/campaignUtils";
import {
  fetchTeam,
  fetchTeams,
  fetchTeamMembers,
  clearTeam,
} from "../team/teamActions";
import {
  fetchTeamParticipants,
  fetchCampaignParticipants,
  fetchParticipant,
  clearParticipant,
} from "../participant/participantActions";
import {
  fetchLastDonationsByCampaignId,
  fetchLastDonationsByTeamId,
  fetchLastDonationsByParticipantId,
} from "../donation/donationActions";
import { get } from "lodash";

export const FETCH_PUBLIC_INFORMATION_START = 'FETCH_PUBLIC_INFORMATION_START';
export const FETCH_PUBLIC_INFORMATION_SUCCESS = 'FETCH_PUBLIC_INFORMATION_SUCCESS';
export const FETCH_PUBLIC_INFORMATION_ERROR = 'FETCH_PUBLIC_INFORMATION_ERROR';

export function fetchPublicInformation(platformId, campaignAlias, teamAlias, participantAlias) {
  return dispatch => {
    // Remove foreign emoji %F0%9F%9B%91 from alias
    if(campaignAlias && typeof campaignAlias == 'string')
      campaignAlias = campaignAlias.split('%')[0];

    if(teamAlias && typeof teamAlias == 'string')
      teamAlias = teamAlias.split('%')[0];

    if(participantAlias && typeof participantAlias == 'string')
      participantAlias = participantAlias.split('%')[0];

    if(teamAlias == "individual-participant" || teamAlias == "participant-individuel")
      teamAlias = false;

    if(teamAlias == "ticket-purchase" || teamAlias == "achat-de-billet") {
      teamAlias = false;
      participantAlias = false;
    }

    dispatch({ type: FETCH_PUBLIC_INFORMATION_START, payload: { campaignAlias, teamAlias, participantAlias } });

    const isCampaignPage = campaignAlias && !teamAlias && !participantAlias;
    const isTeamPage = campaignAlias && teamAlias  && !participantAlias;
    const isParticipantPage = campaignAlias && teamAlias && participantAlias;
    const isSoloParticipantPage = campaignAlias && !teamAlias && participantAlias;

    let campaign,   team,   participant,
        campaignId, teamId, participantId;

    // Clear old data
    if(isCampaignPage) {
      dispatch(clearTeam());
      dispatch(clearParticipant());
    } else
    if(isTeamPage) {
      dispatch(clearCampaign());
      dispatch(clearParticipant());
    } else
    if(isParticipantPage || isSoloParticipantPage) {
      dispatch(clearCampaign());
      dispatch(clearTeam());
    }

    // 1. Campaign
    return dispatch(fetchCampaign(campaignAlias, false, platformId, { showChildrenCount: true, showDescription: true, showMedia: true, showSettings: true }))
      .then(action => {
        campaign = get(action, 'payload', {});
        campaignId = campaign.id || null;

        // 2. Team
        if (isCampaignPage) {
          return Promise.all([
            dispatch(fetchLastDonationsByCampaignId(campaignId)),
            campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS || campaign.typeId === CAMPAIGN_TEMPLATE_MIXED
            ? dispatch(fetchTeams(campaignId, false, { showChildrenCount: true, pageResults: 999999 }))
            : Promise.resolve()
          ])
        } else
        if (isTeamPage) {
          return Promise.all([
            dispatch(fetchTeam(teamAlias, campaignId, false, { showDescription: true, showSettings: true, showLeader: true })),
            dispatch(fetchTeams(campaignId, false, { showChildrenCount: true, pageResults: 999999 }))
          ]).then(array => array[0])
        } else
        if (isParticipantPage) {
          return dispatch(fetchTeam(teamAlias, campaignId, false, { showDescription: true, showSettings: true, showLeader: true }))
        } else
        if (isSoloParticipantPage) {
          return Promise.resolve();
        }
      })
      .then(action => {
        team = get(action, 'payload', {});
        teamId = team.id || null;

        // 3. Participant
        if (isCampaignPage) {
          return dispatch(fetchCampaignParticipants(campaignId, { pageResults: 999999 }))
        } else
        if (isTeamPage) {
          return Promise.all([
            dispatch(fetchLastDonationsByTeamId(teamId)),
            dispatch(fetchTeamParticipants(teamId))
          ])
        } else
        if (isParticipantPage) {
          return Promise.all([
            dispatch(fetchParticipant(participantAlias, false, campaignId, { showMember: true, showDescription: true, showSettings: true })),
            dispatch(fetchCampaignParticipants(campaignId)),
            dispatch(fetchTeamMembers(teamId))
          ])
        } else
        if (isSoloParticipantPage) {
          return dispatch(fetchParticipant(participantAlias, false, campaignId, { showMember: true, showDescription: true, showSettings: true }))
        }
      })
      .then(action => {
        // Get participant donations
        if (isParticipantPage) {
          participant = action && get(action[0], 'payload') || {};
        } else
        if (isSoloParticipantPage) {
          participant = get(action, 'payload', {});
        }

        participantId = participant && participant.id;

        if (participantId)
          return dispatch(fetchLastDonationsByParticipantId(platformId, participantId));
      })
      .then(() => dispatch({ type: FETCH_PUBLIC_INFORMATION_SUCCESS }))
      .catch(error => {
        console.error(error);
        dispatch({ type: FETCH_PUBLIC_INFORMATION_ERROR, error })
      });
  }
}

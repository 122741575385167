import React from 'react';
import { Layout } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Can from 'Parts/session/components/CanContainer';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';

import campaignsTeamsListPageEN from './locales/CampaignsTeamsListPage_en.json';
import campaignsTeamsListPageFR from './locales/CampaignsTeamsListPage_fr.json';

class CampaignsTeamsListPage extends React.Component {
  constructor(props) {
    super(props);

    props.teams.total = props.pagination.totalResults;

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle('en', 'CampaignsTeamsListPage', campaignsTeamsListPageEN);
    i18next.addResourceBundle('fr', 'CampaignsTeamsListPage', campaignsTeamsListPageFR);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchTeamsInCampaign(
      this.props.campaignId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  handleViewHandler = teams => {
    let currentUrl = this.props.location.pathname;
    let teamUrl = currentUrl.replace('list', teams.id);
    return this.props.history.push(teamUrl);
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchTeamsInCampaign(
      this.props.campaignId,
      filters.search,
      filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  render() {
    const { t, teams, pagination, locale } = this.props;
    const { pager } = this.state;

    return (
      <Layout className="CampaignsTeamsListPage">
        <Helmet>
          <title>{t('Teams')}</title>
        </Helmet>
        <SearchBar
          title={t('Teams')}
          placeholder={t('Search')}
          //createButton={<LinkButton to="#" type="primary">{t('Add a team')} <FontAwesomeIcon icon={["fas", "plus"]} /></LinkButton>}
          onSearch={this.handleSearch}
        />
        <Can rules={['permissions.campaign.team.get']} redirect={`/${locale}/d`}>
          <div className="AdminList">
            <PaginationTop
              totalLabel={total => t('teamsTotal', { count: pagination.totalResults })}
              pager={pager}
              onChange={this.handlePaginationChange}
            />
            <ResultTable
              dataSource={teams}
              pagination={pager}
              onChange={this.handlePaginationChange}
              showHeader={true}
              columns={[
                //simpleColumn(t('Team ID'), 'id', null, true),
                simpleColumn(t('Name'), 'name', null, true),
                dateColumn(t('Created on'), 'created', null, null, false, locale),
                actionColumn(t('Edit'), ['fal', 'edit'], this.handleViewHandler)
              ]}
            />
          </div>
        </Can>
      </Layout>
    );
  }
}

CampaignsTeamsListPage.defaultProps = {
  teams: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('CampaignsTeamsListPage')(CampaignsTeamsListPage);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import ParticipationTypeOptions_en from './locales/ParticipationTypeOptions_en.json';
import ParticipationTypeOptions_fr from './locales/ParticipationTypeOptions_fr.json';

import './ParticipationTypeOptions.less';

class ParticipationTypeOptions extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'ParticipationTypeOptions', ParticipationTypeOptions_en);
    i18next.addResourceBundle('fr', 'ParticipationTypeOptions', ParticipationTypeOptions_fr);
  }

  handleTeamClick = event => {
    event.preventDefault();
    this.props.onChange('team');
  }

  handleSoloClick = event => {
    event.preventDefault();
    this.props.onChange('solo');
  }

  isTeamsFull = campaign => {
    const
      { settings, childrenCount } = campaign,
      teamLimit = settings && settings.team && parseInt(settings.team.limit) || 0,
      teamsCount = childrenCount && childrenCount.teams || 0,
      participantLimit = settings && settings.participant && parseInt(settings.participant.limit) || 0,
      participantsCount = childrenCount && childrenCount.participants || 0;

    if(
      teamLimit && teamsCount && teamsCount >= teamLimit &&
      participantLimit && participantsCount && participantsCount >= participantLimit * teamLimit
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { t, campaign } = this.props;
    return (
      <div className="ParticipationTypeOptions">
        {!this.isTeamsFull(campaign) ? (
          <button
            className={classnames('ParticipationTypeOptions__Option', { 'ParticipationTypeOptions__Option--Selected': this.props.value === 'team' })}
            onClick={this.handleTeamClick}
          >
            <span className="fa-layers ParticipationTypeOptions__Icon">
              <FontAwesomeIcon icon={["fal", "users"]} transform="left-1"  />
              <FontAwesomeIcon icon={["far", "plus"]} transform="shrink-10 right-12 up-1" />
            </span>
            <h3>{t('team.title')}</h3>
            <p>{t('team.desc')}</p>
          </button>
        ) : (
          <Tooltip placement="top" title={t("team.full")}>
            <button disabled
              className={classnames('ParticipationTypeOptions__Option', { 'ParticipationTypeOptions__Option--Selected': this.props.value === 'team' })}
              onClick={this.handleTeamClick}
            >
              <span className="fa-layers ParticipationTypeOptions__Icon">
                <FontAwesomeIcon icon={["fal", "users"]} transform="left-1"  />
                <FontAwesomeIcon icon={["far", "plus"]} transform="shrink-10 right-12 up-1" />
              </span>
              <h3>{t('team.title')}</h3>
              <p>{t('team.desc')}</p>
            </button>
          </Tooltip>
        )}
        <button
          className={classnames('ParticipationTypeOptions__Option', { 'ParticipationTypeOptions__Option--Selected': this.props.value === 'solo' })}
          onClick={this.handleSoloClick}
        >
          <FontAwesomeIcon className="ParticipationTypeOptions__Icon" icon={["fal", "user-plus"]} />
          <h3>{t('solo.title')}</h3>
          <p>{t('solo.desc')}</p>
        </button>
        <div className="ParticipationTypeOptions__Empty" />
      </div>
    );
  }
}

ParticipationTypeOptions.defaultProps = {
  onChange: () => {}
};

ParticipationTypeOptions.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default translate('ParticipationTypeOptions')(ParticipationTypeOptions);

import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import pickBy from 'lodash/pickBy';
import i18next from 'i18next';
import { translate } from 'react-i18next';

import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';
import DatePickerField from 'Parts/ui/components/fields/DatePickerField';
import CancelAndSaveButtons from '../../../components/CancelAndSaveButtons';

import CampaignSettingsAdvancedPageFormEN from './locales/CampaignSettingsAdvancedPageForm_en.json';
import CampaignSettingsAdvancedPageFormFR from './locales/CampaignSettingsAdvancedPageForm_fr.json';

import './CampaignSettingsAdvancedPageForm.less';
import FundkyModal from 'Parts/ui/components/FundkyModal';

class CampaignSettingsAdvancedPageForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle(
      'en',
      'CampaignSettingsAdvancedPageForm',
      CampaignSettingsAdvancedPageFormEN
    );
    i18next.addResourceBundle(
      'fr',
      'CampaignSettingsAdvancedPageForm',
      CampaignSettingsAdvancedPageFormFR
    );

    this.state = {
      approvedDisable: false
    };

    this.approveDisableModalRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.form.resetFields();
    }
  }

  handleSubmit = e => {
    if (e) e.preventDefault();

    const { validateFields, isFieldTouched } = this.props.form;

    // WE NEED TO LOOK IF participants_limit and teams_limit ARE EMPTY AND CHANGE IT TO 0 (UNLIMITED)
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (
          updatedValues.close_campaign === null ||
          (updatedValues.close_campaign && this.state.approvedDisable)
        ) {
          this.props.updateCampaign(updatedValues, this.props.campaign.id);
        } else {
          this.approveDisableModalRef.current.getWrappedInstance('FundkyModal').showModal();
        }
      }
    });
  };

  handleModalAccept = () => {
    this.setState(
      {
        approvedDisable: true
      },
      () => {
        this.approveDisableModalRef.current.getWrappedInstance('FundkyModal').hideModal();
        this.handleSubmit();
      }
    );
  };

  render() {
    const { t, campaignSettings, form, campaign, isUpdateCampaignInProgress } = this.props;

    const campaignClosure =
      campaignSettings && campaignSettings.closureOn
        ? moment(campaignSettings.closureOn, 'YYYY-MM-DD HH:mm:ss')
        : null;

    const isCampaignInactive =
      campaign &&
      (campaign.statusId === 3 ||
        campaign.statusId === 4 ||
        campaign.statusId === 5 ||
        (campaignSettings &&
          campaignSettings.closureOn &&
          isDateBeforeCurrentDate(campaignSettings.closureOn, true)));

    return (
      <Form className="CampaignSettingsAdvancedPageForm" onSubmit={this.handleSubmit}>
        <h3>{t('close-campaign-h3')}</h3>
        <DatePickerField
          form={form}
          fieldId="close_campaign"
          initialValue={campaignClosure}
          showTime={true}
          label={t('close-campaign-label')}
          allowClear={true}
          placeholder={t('close-campaign-placeholder')}
          notice={t('close-campaign-notice')}
          disabled={isCampaignInactive}
        />

        <FundkyModal
          ref={this.approveDisableModalRef}
          title={t('deactivate.title')}
          acceptAction={this.handleModalAccept}
        >
          <p>{t('deactivate.description')}</p>
        </FundkyModal>

        {/* <h3>{t('close-campaign-preview-h3')}</h3> */}

        {!isCampaignInactive && (
          <CancelAndSaveButtons isSavingInProgress={isUpdateCampaignInProgress} />
        )}
      </Form>
    );
  }
}

CampaignSettingsAdvancedPageForm.propTypes = {
  form: PropTypes.object.isRequired
};

export default translate('CampaignSettingsAdvancedPageForm')(CampaignSettingsAdvancedPageForm);

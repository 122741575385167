import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Carousel from 'nuka-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './PublicCarousel.less';

class PublicCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoaded: true });
  }

  render() {
    const { className, sideControls, centerControls, slidesToShow, initialSlideHeight, delayInit, children, ...otherProps } = this.props;
    const computedClassName = classnames('PublicCarousel', className, {'PublicCarousel--SideControls': sideControls });

    if (delayInit && !this.state.isLoaded) {
      return <div className="spinner-wrap"><FontAwesomeIcon icon={["fal", "spinner"]} spin size="2x" /></div>;
    }

    const renderCenterLeftControls = sideControls ? ({ previousSlide, currentSlide }) => (
      <a onClick={previousSlide} className={(currentSlide === 0) ? 'disabled' : null}>
        <FontAwesomeIcon icon={["far", "chevron-left"]} />
      </a>
    ) : null;

    const renderCenterRightControls = sideControls ? ({ nextSlide, slideCount, currentSlide }) => (
      <a onClick={nextSlide} className={(slideCount === 0 || currentSlide >= slideCount - slidesToShow) ? 'disabled' : null}>
        <FontAwesomeIcon icon={["far", "chevron-right"]} />
      </a>
    ) : null;

    const renderBottomCenterControls = centerControls ? undefined : null; // undefined will activate the default nuka-carousel controls.

    // Ugly fix #1 Hardcoded height of 100px if no child
    // @see https://github.com/FormidableLabs/nuka-carousel/issues/105#issuecomment-389224883
    const slides = children.length ? children : <div style={{ height: initialSlideHeight }} />;

    // Ugly fix #2 the initialSlideHeight is multiplied by slidesToShow in nuka-carousel source code
    // @see https://github.com/FormidableLabs/nuka-carousel/issues/348
    let fixedInitialSlideHeight = initialSlideHeight ? initialSlideHeight / slidesToShow : initialSlideHeight;

    return (
      <div className={computedClassName}>
        <Carousel
          heightMode="max"
          slidesToShow={slidesToShow}
          initialSlideHeight={fixedInitialSlideHeight}
          renderBottomCenterControls={renderBottomCenterControls}
          renderCenterLeftControls={renderCenterLeftControls}
          renderCenterRightControls={renderCenterRightControls}
          {...otherProps}
        >
          {slides}
        </Carousel>
      </div>
    );
  }

}

PublicCarousel.defaultProps = {
  children: '',
  className: null,
  sideControls: false,
  centerControls: false,
  slidesToShow: 1,
  delayInit: false,
};

PublicCarousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sideControls: PropTypes.bool,
  centerControls: PropTypes.bool,
  slidesToShow: PropTypes.number,
  delayInit: PropTypes.bool,
};

export default PublicCarousel;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './AdminBackButtonBar.less';

class AdminBackButtonBar extends React.Component {

  render() {
    return (
      <div className="AdminBackButtonBar">
        <Link className="lnk-back" to={this.props.href}>
          <FontAwesomeIcon className="AdminHeader__PlatformSubMenuIcon" icon={["fal", "arrow-left"]} /> {this.props.text}
        </Link>
      </div>
    );
  }
}

AdminBackButtonBar.defaultProps = {
  href: "",
  text: ""
}

AdminBackButtonBar.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default AdminBackButtonBar;

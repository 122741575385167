import {
  FETCH_SURVEYS_PAGE_DETAILS_START,
  FETCH_SURVEYS_PAGE_DETAILS_SUCCESS,
  FETCH_SURVEYS_PAGE_DETAILS_ERROR,
  SUBMIT_SURVEY_START,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_ERROR,
  FETCH_SURVEY_START,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_ERROR,
  SUBMIT_SURVEY_STORE_SUCCESS,
  SUBMIT_SURVEY_SUBMIT_SUCCESS,
} from './SurveyAttemptAction';

const initialState = {
  surveysPageDateils: {},
  surveysPageDateilsError: {},
  surveysAttempt: {},
  surveysAttemptError: {},
  allSurveys: [],
  storeSurveyAttempt: [],
  surveySubmitData: [],
};

export default function surveysAttempt(state = initialState, action) {
  switch (action.type) {
    case FETCH_SURVEYS_PAGE_DETAILS_START:
    case FETCH_SURVEYS_PAGE_DETAILS_SUCCESS:
      return { ...state, surveysPageDateils: action.payload };
    case FETCH_SURVEYS_PAGE_DETAILS_ERROR:
      return {
        ...state,
        surveysPageDateilsError: action.payload,
      };
    case SUBMIT_SURVEY_START:
    case SUBMIT_SURVEY_SUCCESS:
      return {
        ...state,
        surveysAttempt: action.payload,
      };
    case SUBMIT_SURVEY_ERROR:
      return {
        ...state,
        surveysAttemptError: action.payload,
      };
    case FETCH_SURVEY_START:
    case FETCH_SURVEY_SUCCESS:
      return{
        ...state,
        allSurveys: action.payload,
      };
    case FETCH_SURVEY_ERROR:
    case SUBMIT_SURVEY_STORE_SUCCESS:
      return {
        ...state,
        storeSurveyAttempt: action.payload,
      }
    case SUBMIT_SURVEY_SUBMIT_SUCCESS:
      return {
        ...state,
        surveySubmitData: action.payload,
      }
    default:
      return state;
  }
}

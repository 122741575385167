import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connectRange } from 'react-instantsearch-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from 'antd';

const AlgoliaDateRange = ({
  currentRefinement,
  refine,
  placeholderStart,
  placeholderEnd
}) => {

  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);

  // Set default values if has currentRefinement from search query (URL)
  useEffect(() => {
    if (currentRefinement.min) setStartValue(unixToDateString(currentRefinement.min));
    if (currentRefinement.max) setEndValue(unixToDateString(currentRefinement.max));
  }, []);

  // START DATE - Disable after end date
  function disabledStartDate(_startValue) {
    if (!_startValue || !endValue) return false;
    return _startValue.valueOf() > endValue.valueOf();
  };

  // END DATE - Disable before start date
  function disabledEndDate(_endValue) {
    if (!_endValue || !startValue) return false;
    return _endValue.valueOf() <= startValue.valueOf();
  };

  // Format unix to date string
  function unixToDateString(unix) {
    return moment.unix(unix);
  }

  // Format date string to unix
  function dateStringToUnix(dateString) {
    return dateString ? moment(dateString).format('X') : '';
  }

  return (
    <div className="AlgoliaDateRange">
      <DatePicker
        placeholder={placeholderStart}
        disabledDate={disabledStartDate}
        suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
        value={startValue}
        onChange={(date, dateString) => {
          setStartValue(date);
          refine({
            ...currentRefinement,
            min: dateStringToUnix(dateString)
          })
        }}
      />
      <DatePicker
        placeholder={placeholderEnd}
        disabledDate={disabledEndDate}
        suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
        value={endValue}
        onChange={(date, dateString) => {
          setEndValue(date);
          refine({
            ...currentRefinement,
            max: dateStringToUnix(dateString)
          })
        }}
      />
    </div>
  );
};

AlgoliaDateRange.propTypes = {
  placeholderStart: PropTypes.string.isRequired,
  placeholderEnd: PropTypes.string.isRequired
}

export default connectRange(AlgoliaDateRange);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import AnonymousField_en from './locales/AnonymousField_en.json';
import AnonymousField_fr from './locales/AnonymousField_fr.json';

class AnonymousField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AnonymousField', AnonymousField_en);
    i18next.addResourceBundle('fr', 'AnonymousField', AnonymousField_fr);
  }

  render() {
    const { t, form, className } = this.props;
    const { getFieldDecorator } = form;
    const Option = Select.Option;

    const placeholder = this.props.fieldFormat === 'select' ?
      (
        this.props.placeholder === true ?
          t('anonymous') :
          this.props.placeholder || null
      ) : null;

    const input = this.props.fieldFormat === 'checkbox' ?
      (
        <Checkbox>{t('private')}</Checkbox>
      ) :
      this.props.fieldFormat === 'select' ?
        (
          <Select
            className="AnonymousField-Select"
            placeholder={placeholder}
            suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
          >
            <Option value={0}>{t('no')}</Option>
            <Option value={1}>{t('yes')}</Option>
          </Select>
        ) : null;

    const fieldId = 'anonymous';
    const fieldOptions = this.props.fieldFormat === 'checkbox' ?
      {
        normalize: value => (value || false), // Return false instead of undefined
        valuePropName: 'checked',
        initialValue: this.props.initialValue
      } :
      this.props.fieldFormat === 'select' ?
        {
          initialValue: this.props.initialValue ? 1 : 0
        } : null;

    const label = this.props.fieldFormat === 'select' ?
      (
        this.props.label === true ?
          t('anonymous') :
          this.props.label || null
      ) : null;

    return (
      <Form.Item
        className={`AnonymousField ${className}`}
        label={label}
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

AnonymousField.defaultProps = {
  fieldFormat: 'checkbox',
  label: true,
  placeholder: false,
  initialValue: undefined,
};

AnonymousField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  fieldFormat: PropTypes.oneOf(['checkbox', 'select'])
};

export default translate('AnonymousField')(AnonymousField);

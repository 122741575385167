import {
  UI_TEAM_CAMPAIGN_SELECT,
  SAVE_TEAM_CREATE_SUCCESS,

  FETCH_TEAMS_START,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_ERROR,

  FETCH_TEAM_PARTICIPANTS_START,
  FETCH_TEAM_PARTICIPANTS_SUCCESS,
  FETCH_TEAM_PARTICIPANTS_ERROR,

  FETCH_TEAM_START,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_ERROR,

  FETCH_TEAM_DESCRIPTION_START,
  FETCH_TEAM_DESCRIPTION_SUCCESS,
  FETCH_TEAM_DESCRIPTION_ERROR,

  FETCH_TEAM_SETTINGS_START,
  FETCH_TEAM_SETTINGS_SUCCESS,
  FETCH_TEAM_SETTINGS_ERROR,

  FETCH_TEAM_LOGS_START,
  FETCH_TEAM_LOGS_SUCCESS,
  FETCH_TEAM_LOGS_ERROR,

  FETCH_TEAM_CAPTAIN_START,
  FETCH_TEAM_CAPTAIN_SUCCESS,
  FETCH_TEAM_CAPTAIN_ERROR,

  CLEAR_TEAM,
} from './teamActions';

import {
  FETCH_DONATION_LIST_BY_TEAM_START,
  FETCH_DONATION_LIST_BY_TEAM_SUCCESS,
  FETCH_DONATION_LIST_BY_TEAM_ERROR
} from "../donation/donationActions";

const initialState = {
  selectedCampaign: null, // Selected campaign at the 1st step of the team creation form
  createdTeam: null, // Newly created team (used to display a team to the public page on the confirmation panel)
  teams: [], // List of the teams (campaign public page, participation subscription, ...)
  team: {
    description: {},
    settings: {}
  }, // Team asked by the public page (team page or participant page)
  captain: {}, // Team asked by the public page (team page or participant page)
  lastDonations: [],
  participants: {},
  logs: {},
  isFetchTeamsInProgress: false,
  isFetchTeamParticipantsInProgress: false,
  isFetchTeamInProgress: false,
  isFetchTeamDescriptionInProgress: false,
  isFetchTeamSettingsInProgress: false,
  isFetchTeamLogsInProgress: false,
  isFetchTeamCaptainInProgress: false,
  isFetchDonationListByTeamInProgress: false
};

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case UI_TEAM_CAMPAIGN_SELECT:
      return {
        ...state,
        selectedCampaign: action.payload
      };

    case SAVE_TEAM_CREATE_SUCCESS:
      return {
        ...state,
        createdTeam: action.payload
      };

    case FETCH_TEAMS_START:
      return {
        ...state,
        isFetchTeamsInProgress: true
      };
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        isFetchTeamsInProgress: false,
        teams: action.payload
      };
    case FETCH_TEAMS_ERROR:
      return {
        ...state,
        isFetchTeamsInProgress: false,
        teams: initialState.teams
      };

    case FETCH_TEAM_PARTICIPANTS_START:
      return {
        ...state,
        isFetchTeamParticipantsInProgress: true
      };
    case FETCH_TEAM_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        isFetchTeamParticipantsInProgress: false,
        participants: action.payload
      };
    case FETCH_TEAM_PARTICIPANTS_ERROR:
      return {
        ...state,
        isFetchTeamParticipantsInProgress: false,
        participants: initialState.participants
      };

    case FETCH_TEAM_START:
      return {
        ...state,
        isFetchTeamInProgress: true
      };
    case FETCH_TEAM_SUCCESS:
      return {
        ...state,
        isFetchTeamInProgress: false,
        team: {
          ...state.team,
          ...action.payload
        }
      };
    case FETCH_TEAM_ERROR:
      return {
        ...state,
        isFetchTeamInProgress: false,
        team: initialState.team
      };

    case FETCH_TEAM_DESCRIPTION_START:
      return {
        ...state,
        isFetchTeamDescriptionInProgress: true
      };
    case FETCH_TEAM_DESCRIPTION_SUCCESS:
      return {
        ...state,
        isFetchTeamDescriptionInProgress: false,
        team: {
          ...state.team,
          description: action.payload
        }
      };
    case FETCH_TEAM_DESCRIPTION_ERROR:
      return {
        ...state,
        isFetchTeamDescriptionInProgress: false,
        team: {
          ...state.team,
          description: initialState.team.description
        }
      };

    case FETCH_TEAM_SETTINGS_START:
      return {
        ...state,
        isFetchTeamSettingsInProgress: true
      };
    case FETCH_TEAM_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetchTeamSettingsInProgress: false,
        team: {
          ...state.team,
          settings: action.payload
        }
      };
    case FETCH_TEAM_SETTINGS_ERROR:
      return {
        ...state,
        isFetchTeamSettingsInProgress: false,
        team: {
          ...state.team,
          settings: initialState.team.settings
        }
      };

    case FETCH_TEAM_LOGS_START:
      return {
        ...state,
        isFetchTeamLogsInProgress: true
      };
    case FETCH_TEAM_LOGS_SUCCESS:
      return {
        ...state,
        isFetchTeamLogsInProgress: false,
        logs: action.payload
      };
    case FETCH_TEAM_LOGS_ERROR:
      return {
        ...state,
        isFetchTeamLogsInProgress: false,
        logs: initialState.logs
      };

    case FETCH_TEAM_CAPTAIN_START:
      return {
        ...state,
        isFetchTeamCaptainInProgress: true
      };
    case FETCH_TEAM_CAPTAIN_SUCCESS:
      return {
        ...state,
        isFetchTeamCaptainInProgress: false,
        captain: action.payload
      };
    case FETCH_TEAM_CAPTAIN_ERROR:
      return {
        ...state,
        isFetchTeamCaptainInProgress: false,
        captain: initialState.captain
      };

    case FETCH_DONATION_LIST_BY_TEAM_START:
      return {
        ...state,
        isFetchDonationListByTeamInProgress: true
      };
    case FETCH_DONATION_LIST_BY_TEAM_SUCCESS:
      return {
        ...state,
        isFetchDonationListByTeamInProgress: false,
        lastDonations: action.payload
      };
    case FETCH_DONATION_LIST_BY_TEAM_ERROR:
      return {
        ...state,
        isFetchDonationListByTeamInProgress: false,
        lastDonations: initialState.lastDonations
      };

    case CLEAR_TEAM:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
}

import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import HeadMetas from '../../common/HeadMetasContainer';
import CreateInformationForm from '../components/CreateInformationFormContainer';
import CreatePersonalizationForm from '../components/CreatePersonalizationFormContainer';
import CreateConfirmationPanel from '../components/CreateConfirmationPanelContainer';

import {
  CAMPAIGN_CREATE_WIZARD_INFORMATION,
  CAMPAIGN_CREATE_WIZARD_PERSONALIZATION,
  CAMPAIGN_CREATE_WIZARD_CONFIRMATION
} from '../../ui/uiUtils';

import CampaignCreatePage_en from './locales/CampaignCreatePage_en.json';
import CampaignCreatePage_fr from './locales/CampaignCreatePage_fr.json';

import './CampaignCreatePage.less';

class CampaignCreatePage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CampaignCreatePage', CampaignCreatePage_en);
    i18next.addResourceBundle('fr', 'CampaignCreatePage', CampaignCreatePage_fr);
  }

  componentDidMount() {
    const { t, history, locale, currentStep, platformId, checkAuthorization } = this.props;

    if (currentStep) {
      if (currentStep === CAMPAIGN_CREATE_WIZARD_INFORMATION) {
        if (
          this.props.location.pathname.includes(t('URL:create-a-campaign/description')) ||
          this.props.location.pathname.includes(t('URL:create-a-campaign/confirmation'))
        ) {
          history.replace(`/${locale}/${t('URL:create-a-campaign')}`);
        }
      } else if (currentStep === CAMPAIGN_CREATE_WIZARD_PERSONALIZATION) {
        if (
          this.props.location.pathname.includes(t('URL:create-a-campaign')) ||
          this.props.location.pathname.includes(t('URL:create-a-campaign/confirmation'))
        ) {
          history.replace(`/${locale}/${t('URL:create-a-campaign/description')}`);
        }
      }
    }

    if (
      !checkAuthorization({ platform: platformId }, [
        [() => (Number(this.props.campaignEnabled) ? true : false)],
        ['roles.platform.OWNER'],
        ['roles.platform.SUPERADMIN']
      ])
    ) {
      this.props.deactivateCreationMode();
      history.replace(`/${locale}/`);
    } else {
      this.props.activateCreationMode();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      t,
      campaignEnabled,
      history,
      locale,
      location,
      currentStep,
      deactivateCreationMode,
      platformId,
      checkAuthorization
    } = this.props;

    if (
      !checkAuthorization({ platform: platformId }, [
        [() => (Number(campaignEnabled) ? true : false)],
        ['roles.platform.OWNER'],
        ['roles.platform.SUPERADMIN']
      ])
    ) {
      deactivateCreationMode();
      history.replace(`/${locale}/`);
    }

    if (prevProps.location.pathname !== location.pathname) {
      if (currentStep) {
        if (currentStep === CAMPAIGN_CREATE_WIZARD_INFORMATION) {
          if (
            location.pathname.includes(t('URL:create-a-campaign/description')) ||
            location.pathname.includes(t('URL:create-a-campaign/confirmation'))
          ) {
            history.replace(`/${locale}/${t('URL:create-a-campaign')}`);
          }
        } else if (currentStep === CAMPAIGN_CREATE_WIZARD_PERSONALIZATION) {
          if (
            location.pathname.includes(t('URL:create-a-campaign')) ||
            location.pathname.includes(t('URL:create-a-campaign/confirmation'))
          ) {
            history.replace(`/${locale}/${t('URL:create-a-campaign/description')}`);
          }
        }
      }
    }

    this.pathname = history.location.pathname;
  }

  componentWillUnmount() {
    const {
      t,
      locale,
      history,
      clearCampaign,
      deactivateCreationMode,
      changeCampaignCreateWizardStep
    } = this.props;

    const nextLocation = (history && history.location && history.location.pathname) || null;

    if (
      nextLocation !== `/${locale}/${t('URL:create-a-campaign')}` &&
      nextLocation !== `/${locale}/${t('URL:create-a-campaign/description')}` &&
      nextLocation !== `/${locale}/${t('URL:create-a-campaign/confirmation')}`
    ) {
      clearCampaign();
      deactivateCreationMode();
    } else if (
      this.pathname === `/${locale}/${t('URL:create-a-campaign/confirmation')}` &&
      nextLocation === `/${locale}/${t('URL:create-a-campaign')}`
    ) {
      clearCampaign();
      changeCampaignCreateWizardStep(CAMPAIGN_CREATE_WIZARD_INFORMATION);
    }
  }

  handleTabChange = activeKey => {
    const { history, t, locale } = this.props;

    if (activeKey === CAMPAIGN_CREATE_WIZARD_INFORMATION) {
      history.push(`/${locale}/${t('URL:create-a-campaign')}`);
    } else if (activeKey === CAMPAIGN_CREATE_WIZARD_PERSONALIZATION) {
      history.push(`/${locale}/${t('URL:create-a-campaign/description')}`);
    } else if (activeKey === CAMPAIGN_CREATE_WIZARD_CONFIRMATION) {
      history.push(`/${locale}/${t('URL:create-a-campaign/confirmation')}`);
    }
  };

  render() {
    const { t, currentStep, locale } = this.props;
    const { TabPane } = Tabs;

    return (
      <Layout>
        <HeadMetas />
        <div className="CampaignCreatePage">
          <div className="CampaignCreatePage__TitleDivider FundkyWrap">
            <FontAwesomeIcon icon={['fal', 'flag']} className="CampaignCreatePage__TitleIcon" />
            <h1>{t('title')}</h1>
          </div>
          <Tabs
            className="FillActive"
            activeKey={currentStep}
            onChange={this.handleTabChange}
            onTabClick={key => this.props.changeCampaignCreateWizardStep(key)}
          >
            <TabPane
              tab={
                <span>
                  {t('tab1')}{' '}
                  {currentStep !== CAMPAIGN_CREATE_WIZARD_INFORMATION ? (
                    <FontAwesomeIcon icon={['fas', 'check']} />
                  ) : null}
                </span>
              }
              disabled={currentStep === CAMPAIGN_CREATE_WIZARD_CONFIRMATION}
              key={CAMPAIGN_CREATE_WIZARD_INFORMATION}
            >
              <Route
                exact
                path={`/${locale}/${t('URL:create-a-campaign')}`}
                render={props => (
                  <CreateInformationForm
                    changeCampaignCreateWizardStep={this.props.changeCampaignCreateWizardStep}
                    history={this.props.history}
                    locale={this.props.locale}
                  />
                )}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  {t('tab2')}{' '}
                  {currentStep === CAMPAIGN_CREATE_WIZARD_CONFIRMATION ? (
                    <FontAwesomeIcon icon={['fas', 'check']} />
                  ) : null}
                </span>
              }
              disabled={
                currentStep === CAMPAIGN_CREATE_WIZARD_CONFIRMATION ||
                (currentStep === CAMPAIGN_CREATE_WIZARD_INFORMATION &&
                  !this.props.campaignInformation)
              }
              key={CAMPAIGN_CREATE_WIZARD_PERSONALIZATION}
            >
              <Route
                exact
                path={`/${locale}/${t('URL:create-a-campaign/description')}`}
                render={props => (
                  <CreatePersonalizationForm
                    changeCampaignCreateWizardStep={this.props.changeCampaignCreateWizardStep}
                    history={this.props.history}
                    locale={this.props.locale}
                  />
                )}
              />
            </TabPane>
            <TabPane
              tab={<span>{t('tab3')}</span>}
              disabled={currentStep !== CAMPAIGN_CREATE_WIZARD_CONFIRMATION}
              key={CAMPAIGN_CREATE_WIZARD_CONFIRMATION}
            >
              <Route
                exact
                path={`/${locale}/${t('URL:create-a-campaign/confirmation')}`}
                render={props => <CreateConfirmationPanel match={this.props.match.path} />}
              />
            </TabPane>
          </Tabs>
        </div>
      </Layout>
    );
  }
}

CampaignCreatePage.propTypes = {
  currentStep: PropTypes.oneOf([
    CAMPAIGN_CREATE_WIZARD_INFORMATION,
    CAMPAIGN_CREATE_WIZARD_PERSONALIZATION,
    CAMPAIGN_CREATE_WIZARD_CONFIRMATION
  ]).isRequired,
  changeCampaignCreateWizardStep: PropTypes.func.isRequired,
  clearCampaign: PropTypes.func.isRequired,
  campaignInformation: PropTypes.object
};

export default translate('CampaignCreatePage')(CampaignCreatePage);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Form } from "antd";

import RecoveryForm from './RecoveryForm';
import { openRegisterModal, openLoginModal } from "../../ui/uiActions";
import { recover } from '../sessionActions';

const mapStateToProps = state => ({
  browser: state.session.browser,
  isRecoveryInProgress: state.ui.isRecoveryInProgress,
  isRecoverySuccess: state.ui.isRecoverySuccess,
  connectionModalForced: state.ui.connectionModalForced,
  connectionModalOrigin: state.ui.connectionModalOrigin,
  platform: state.platform && state.platform.platform,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openRegisterModal,
  openLoginModal,
  recover
}, dispatch);

const mapPropsToFields = props => ({
  platformId: Form.createFormField({ value: props.platform && props.platform.id })
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create({ mapPropsToFields })
)(RecoveryForm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'antd';
import { Link } from "react-router-dom";

/*
  To see a list of the usable props, see here https://ant.design/components/button/
*/

export default class ButtonLink extends Component {
  render() {
    const { to, children, className, type, size, shape, loading, disabled } = this.props;

    // CLASSES
    // Hijack the classes of Antd's Button component
    const defaultClass = 'ant-btn';
    const typeClass = type !== 'default' ? `${defaultClass}-${type}` : null;
    const sizeClass = 
      size === 'large' ? `${defaultClass}-lg` :
      size === 'small' ? `${defaultClass}-sm` :
      null;
    const shapeClass = shape ? `${defaultClass}-${shape}` : null;
    const loadingClass = loading ? `${defaultClass}-loading` : null;

    return (
      <Link disabled={disabled} to={to} className={classnames(defaultClass, typeClass, sizeClass, shapeClass, loadingClass, className)}>
        {loading && <Icon type="loading" />}
        <span>{children}</span>
      </Link>
    );
  }
}

ButtonLink.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]).isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

ButtonLink.defaultProps = {
  type: 'default',
  size: 'default',
  shape: null,
  loading: false,
  disabled: false,
}

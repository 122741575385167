import { compose } from 'redux';
import { connect } from 'react-redux';
import dataFetcher from "../../parts/chore/dataFetcher";

import DonationChoicePage from './DonationChoicePage';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  locale: state.session.language
});

export default compose(
  connect(mapStateToProps),
  dataFetcher
)(DonationChoicePage);

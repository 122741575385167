import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEmpty } from 'lodash';
import { Skeleton } from 'antd';

import HelpText from '../../../../common/HelpText';
import AvatarThumbnail from '../../../../common/AvatarThumbnailContainer';

import { getCdnUrl } from 'Parts/common/environment';
import { getLanguageNameByKey } from 'Parts/common/langUtils';

import './CurrentCampaignDropdown.less';

import '../../../../assets/images/default_participant_picture_300x300.jpg';

// We need a true React component to "absorb" the props injected by Ant Dropdown component
// React will print a warning if we declare HTML tag directly
const DummyMenu = props => props.children;
const cdn = getCdnUrl();

class CurrentCampaignDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = { visible: false };
  }

  handleEntityLinkClick = () => {
    // When clicking on a entity link, we stay on the same route so React reuse that component
    // and the menu stays open.
    // ---> We need to close the menu manually
    this.setState({ visible: false });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  getBannerUrl = (campaign, locale) => {
    const lang = getLanguageNameByKey(locale, false);
    let banners, path, key;

    if ((path = get(campaign, 'banner.banner'))) {
      return `${cdn}${path}`;
    }
    // get public banner
    else if ((path = get(campaign, 'media.banner.banner'))) {
      return `${cdn}${path}`;
    }
    // get admin banner according the language page
    else if ((path = get(campaign, `media.banner[${lang}].banner`))) {
      return `${cdn}${path}`;
    }
    // get the default banner
    else if (!isEmpty((banners = get(campaign, 'media.banner')))) {
      for (key in banners) {
        if (banners[key].default) {
          return `${cdn}${banners[key].banner}`;
        }
      }
    }
    return null;
  };

  render() {
    const {
      campaign,
      locale,
      entities,
      path,
      subtitle,
      dropdownLabel,
      additionalLinks,
      title
    } = this.props;

    const entityLinks = entities.map((entity, index) => {
      let url = path.replace(':campaignID', entity.id);
      return (
        <Link key={entity.id} to={url} title={entity.name} onClick={this.handleEntityLinkClick}>
          <AvatarThumbnail entity={entity} primaryContent={entity.name} />
        </Link>
      );
    });

    const menu = (
      <DummyMenu>
        <div className="CurrentCampaignDropdown__Menu">
          <HelpText>{dropdownLabel}</HelpText>
          <div className="CurrentCampaignDropdown__EntityLinks">{entityLinks}</div>
          <div className="CurrentCampaignDropdown__AdditionalLinks">{additionalLinks}</div>
        </div>
      </DummyMenu>
    );

    const campaignBanner = this.getBannerUrl(campaign, locale);

    const avatarWrapStyle = {
      backgroundImage: `url(${campaignBanner ||
        '/assets/default_participant_picture_300x300.jpg'})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    };

    return (
      <Dropdown
        overlay={menu}
        onVisibleChange={this.handleVisibleChange}
        visible={this.state.visible}
        trigger={['click']}
      >
        <div className="CurrentCampaignDropdown">
          <Skeleton
            active
            loading={!title}
            title={false}
            paragraph={{ rows: 2 }}
            avatar={{ size: 50, shape: 'circle' }}
          >
            <div className="current-campaign-avatar-wrap" style={avatarWrapStyle}></div>
            <div className="current-campaign-text" title={title}>
              <strong>{title}</strong>
              {subtitle && <HelpText className="current-campaign-subtitle">{subtitle}</HelpText>}
            </div>
            <FontAwesomeIcon icon={['fas', 'caret-down']} size="lg" />
          </Skeleton>
        </div>
      </Dropdown>
    );
  }
}

CurrentCampaignDropdown.defaultProps = {
  className: null,
  title: ''
};

CurrentCampaignDropdown.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  entities: PropTypes.array.isRequired,
  getEntityLinkUrl: PropTypes.func.isRequired,
  additionalLinks: PropTypes.node.isRequired,
  dropdownLabel: PropTypes.string.isRequired
};

export default CurrentCampaignDropdown;

import React from 'react';
import PropTypes from 'prop-types';

import './AdminTitleBar.less';

class AdminTitleBar extends React.Component {

  render() {
    const upperTitle = this.props.upperTitle
      ? <span className="AdminTitleBar--upperTitle">{this.props.upperTitle}</span>
      : null;

    const subTitle = this.props.subTitle
      ? <span className="AdminTitleBar--subTitle">{this.props.subTitle}</span>
      : null;

    const name = this.props.name
      ? <h2 className="AdminTitleBar--name">{this.props.name}</h2>
      : null;

    const subName = this.props.subName
      ? <span className="AdminTitleBar--subname">{this.props.subName}</span>
      : null;

    const buttons = this.props.buttons
      ? this.props.buttons
        .filter((button) => button)
        .map((button, index) => {
          return <span key={index}>{button}</span>
        })
      : null;

    return (
      <div className={`AdminTitleBar ${(this.props.name) ? 'small-padding-bottom' : ''}`}>
        <div className="AdminTitleBar--title">
          {upperTitle}
          <h1>{this.props.title}</h1>
          {subTitle}
          {name}
          {subName}
        </div>
        <div className="AdminTitleBar--buttons">
          {buttons}
        </div>
      </div>
    );
  }
}

AdminTitleBar.defaultProps = {
  buttons: [],
  title: '',
}

AdminTitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  upperTitle: PropTypes.string,
  subTitle: PropTypes.string,
  name: PropTypes.string,
  subName: PropTypes.string,
  buttons: PropTypes.array
};

export default AdminTitleBar;

import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import dataFetcher from "../../../parts/chore/dataFetcher";

import { searchTransactionsInCampaign, clearSearch } from '../../../search/searchActions';
import { reportTransactionsInCampaign } from '../../../report/reportActions';
import { checkAuthorization } from 'Parts/common/authorizationUtils';

import CampaignsTransactionsListPage from './CampaignsTransactionsListPage';

const mapStateToProps = state => ({
  campaign: state.campaign.campaign,
  campaignId: state.campaign.campaign.id,
  campaignAlias: state.campaign.campaign.alias,
  campaignStatusId: state.campaign.campaign.statusId,
  platformAlias: state.platform.platform.alias,
  platformId: state.platform.platform.id,
  platform: state.platform.platform,
  transactions: state.search.transactions.results,
  pagination: state.search.transactions.pagination,
  filters: state.search.transactions.filterBy,
  search: state.search.transactions.search,
  orderBy: state.search.transactions.orderBy,
  locale: state.session.language,
  report: state.report.transactions
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchTransactionsInCampaign,
  reportTransactionsInCampaign,
  clearSearch,
  checkAuthorization
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(CampaignsTransactionsListPage);

import React from 'react';
import { Button, Input, Layout, Tabs } from 'antd';
import { get } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { getDefaultLanguageKey } from 'Parts/common/langUtils';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';
import SurveysPage_en from './locales/SurveysPage_en.json';
import SurveysPage_fr from './locales/SurveysPage_fr.json';
import InformationTab from './tabs/Information';
import FieldsTab from './tabs/Fields';
// import HistoryTab from './tabs/History';
import ResultsTab from './tabs/Results';
import '../SurveysListPage.less';

class SurveyPage extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'SurveyPage', SurveysPage_en);
    i18next.addResourceBundle('fr', 'SurveyPage', SurveysPage_fr);

    this.state = {
      defaultTitle: {
        en: 'Survey for Ticket C',
        fr: 'Sondage pour le ticket C',
      },
      defaultDescription: {
        en: 'Get involved! Check out these featured campaigns and help them reach their goal.',
        fr: 'Découvrez les campagnes vedettes. Impliquez-vous pour les aider à atteindre leur objectif.',
      },
      create_survey: false,
      changeTab: 'information',
      disableSubmit: true,
      contentLang:getDefaultLanguageKey(get(this.props, 'languages')) || this.props.language_id,
      campaignlang:"",
      disableSubmitButton: true,
      selectedItems: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
      accessibility: 0,
      campaign: [
        {
          id: 1,
          text: 'Donation_form',
          name: 'campaign_donation_form',
          fieldId: 'campaignsDonationForm',
          completed: false,
        },
        {
          id: 2,
          text: 'Participant_form',
          name: 'campaign_participant_form',
          fieldId: 'campaignsParticipantForm',
          completed: false,
        },
        {
          id: 3,
          text: 'Purchase_tickets_form',
          name: 'campaign_purchase_tickets_form',
          fieldId: 'campaignsPurchaseTicketsform',
          completed: false,
        },
      ],
      surveyName: '',
      inatialValyuOfSurvey: '',
      organizationCheck: false,
      platformCheck: false,
      formEditingNotice: false,
      surveysInformation: this.props.surveysInformation,
      surveyDetails: this.props.surveyDetails,
      surveysFields: this.props.surveysFields, // Fiels list in main screen
      fields: this.props.fields, // Modal fiels
      selectedFields: [], // Modal fields selected
      temporaryFields: [],
      editingFieldIndex: null,
      temporaryDataFields: { label: '', is_required: 0, display_type: '', field_name: '' , survey_field_order: 0},
      surveyCampaign: [],
      surveyCampaignTicket: [],
      addingSurveyInfo: this.props.addingSurveyInfo,
      is_survey: true,
      language_id: this.props.locale,
      fieldLabelMaxLengthError: false,
      changeTabName: this.props.location.pathname.split('/')[5],
    };
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.addingSurveyInfo !== prevProps.addingSurveyInfo) {
      this.props.history.replace(
        `/${this.state.language_id}/d/survey/${this.props.addingSurveyInfo.surveyId}/information`
      );
    }
    if (this.props.surveyDetails !== prevProps.surveyDetails) {
      this.setState({ surveyDetails: this.props.surveyDetails });
      this.setState({ inatialValyuOfSurvey: this.props.surveyDetails });

      if (this.props.match.params.id) {
        await this.props.fetchCampaignInformations(this.props.platform.id, this.state.language_id);
        const surveyCampaignTemp = await this.props.campaignInfo.filter((obj) =>
          this.props.surveyDetails.campaigns_selection.includes(obj.id)
        );

        await this.props.fetchCampaignTicketInformations(
          surveyCampaignTemp.map((d) => d.id).join(','),
          this.state.language_id
        );

        this.setState({
          surveyName: this.props.surveyDetails.name,
          organizationCheck: this.props.surveyDetails.organization === 1 ? true : false,
          platformCheck: this.props.surveyDetails.platform_donation_form === 1 ? true : false,
          accessibility: this.props.surveyDetails.accessibility,
          surveyCampaign: surveyCampaignTemp,
          campaign: [
            {
              id: 1,
              text: 'Donation_form',
              name: 'campaign_donation_form',
              fieldId: 'campaignsDonationForm',
              completed: this.props.surveyDetails.campaign_donation_form === 1 ? true : false,
            },
            {
              id: 2,
              text: 'Participant_form',
              name: 'campaign_participant_form',
              fieldId: 'campaignsParticipantForm',
              completed: this.props.surveyDetails.campaign_participant_form === 1 ? true : false,
            },
            {
              id: 3,
              text: 'Purchase_tickets_form',
              name: 'campaign_purchase_tickets_form',
              fieldId: 'campaignsPurchaseTicketsform',
              completed:
                this.props.surveyDetails.campaign_purchase_tickets_form === 1 ? true : false,
            },
          ],
        });
      }
    }

    if (
      this.props.campaignInfo !== prevProps.campaignInfo
    ) {
      const surveyCampaignTemp = await this.props.campaignInfo.filter((obj) =>
        this.props.surveyDetails.campaigns_selection.includes(obj.id)
      ); 
      this.setState({
        surveyCampaign: surveyCampaignTemp,
        campaignlang: this.props.surveyDetails.language_id,
      });
    }

    if (prevProps.campaignTicketInfo !== this.props.campaignTicketInfo) {
      if (this.props.match.params.id) {
        const surveyCampaignTemp = this.props.surveyDetails.tickets_selection
          .map((d) => {
            const obj = this.props.campaignTicketInfo.find(
              (item) => item.id.toString() === d.toString()
            );
            return { ...obj, id: obj ? obj.id.toString() : d };
          })
          .filter((d) => d.ticketName);
        this.setState({ surveyCampaignTicket: surveyCampaignTemp });
      }
    }

    if (this.props.surveysFields !== prevProps.surveysFields) {
      this.setState({
        surveysFields: this.props.surveysFields,
      });
      if (this.state.changeTab === 'fields') {
        this.startEditingField(0);
      }
    }

    if (this.props.fields !== prevProps.fields) {
      this.setState({ fields: this.props.fields });
    }
  };

  componentWillUnmount() {
    this.props.removeCampaignTicketInformations();
  }

  componentDidMount = async () => {
    if (this.props.user.id) {
      await this.props.fetchCampaignInformations(this.props.platform.id, this.state.language_id);
    }
    if (this.props.match.params.id) {
      await this.props.fetchSurveyDetails(this.props.match.params.id, this.state.language_id);
      await this.props.fetchSurveyResultDetails(this.props.match.params.id, this.state.language_id);
    }
  };

  handleChangeSelect = async (e, event, name) => {
    if (e) {
      if (name === 'campaign') {
        const checkExists = this.state.surveyCampaign.some(
          (d) => d.id.toString() === event.key.toString()
        );
        if (checkExists) {
          this.state.surveyCampaign.filter((d) => d.id.toString() !== event.key.toString());
        } else {
          this.state.surveyCampaign.push({ name: e, id: event.key });
        }
        this.setState({ surveyCampaign: this.state.surveyCampaign });
        await this.props.fetchCampaignTicketInformations(
          this.state.surveyCampaign.map((d) => d.id).join(','), this.state.language_id
        );
      } else if (name === 'campaignTicket') {
        // if (!this.state.surveyCampaignTicket.includes(e)) {
        //   this.state.surveyCampaignTicket.push(e);
        // }
        const checkExists = this.state.surveyCampaignTicket.some(
          (d) => d.id.toString() === event.key.toString()
        );
        if (checkExists) {
          this.state.surveyCampaignTicket.filter((d) => d.id.toString() !== event.key.toString());
        } else {
          this.state.surveyCampaignTicket.push({ ticketName: e, id: event.key });
        }
        this.setState({ surveyCampaignTicket: this.state.surveyCampaignTicket });
      }
    }
  };
  handleChangeDeselect = async (e, event, name) => {
    if (name === 'campaign') {
      const updatedCampaign = this.state.surveyCampaign.filter(
        (item) => item.name.toString() !== event.key.toString()
      );
      await this.props.fetchCampaignTicketInformations(updatedCampaign.map((d) => d.id).join(','), this.state.language_id);
      this.setState({ surveyCampaign: updatedCampaign });
    } else if (name === 'campaignTicket') {
      const updatedCampaign = this.state.surveyCampaignTicket.filter(
        (item) => item.id !== event.key
      );
      this.setState({ surveyCampaignTicket: updatedCampaign });
    }
  };

  handleCheckboxChange = (campaignId) => {
    this.setState((prevState) => {
      const updatedCampaign = prevState.campaign.map((item) => {
        if (item.id === campaignId) {
          return { ...item, completed: !item.completed };
        }
        return item;
      });

      return { campaign: updatedCampaign };
    });
  };

  changeTab = async (item) => {
    this.setState({ changeTab: item });
    if (item === 'fields') {
      await this.props.fetchSurveyFields(this.props.match.params.id, this.state.language_id);
      await this.props.fetchFields();
      this.startEditingField(0);
    }
  };

  handleContentLanguageChange = async(value) => {
    // TODO
    this.setState({ language_id: value });
    await this.props.fetchSurveyDetails(this.props.match.params.id, value);
    await this.props.fetchSurveyFields(this.props.match.params.id, value);
  };

  handleChange = (event) => {
    this.setState({ surveyName: event.target.value });
  };

  handleSingleCheckboxChange = (checkboxName) => {
    this.setState((prevState) => ({
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  handleClick = async (accessibility) => {
    let selectedIds = this.state.campaign.filter((item) => item.completed).map((item) => item.id);
    let InformationData = {
      name: this.state.surveyName,
      organization: this.state.organizationCheck ? 1 : 0,
      platform_donation_form: this.state.platformCheck ? 1 : 0,
      campaign_donation_form: selectedIds.includes(1) ? 1 : 0,
      campaign_participant_form: selectedIds.includes(2) ? 1 : 0,
      campaign_purchase_tickets_form: selectedIds.includes(3) ? 1 : 0,
      campaigns_selection:
        this.state.surveyCampaign.length > 0 ? this.state.surveyCampaign.map((d) => d.id) : [],
      tickets_selection:
        this.state.surveyCampaignTicket.length > 0
          ? this.state.surveyCampaignTicket.map((d) => d.id)
          : [],
      language_id: this.state.language_id === "fr" ? 2 : 1,
      accessibility: accessibility,
      user_id: this.props.user.id,
      platform_id: this.props.platform.id,
    };

    if (this.props.match.params.id) {
      await this.props.updateSurveyInformations(this.props.match.params.id, InformationData);
    } else {
      await this.props.addSurveyInformations(InformationData);
    }
  };

  selectFieldsOptions = (field_id, field_name) => {
    const fieldObj = {
      field_type_id: field_id,
      survey_id: this.props.match.params.id,
      is_required: 0,
      label: field_name,
    };
    const checkSelectedField = this.state.selectedFields.some((d) => d.field_type_id === field_id);
    this.setState({
      selectedFields: checkSelectedField
        ? this.state.selectedFields.filter((d) => d.field_type_id !== field_id)
        : [...this.state.selectedFields, fieldObj],
    });
  };

  addNewFields = async() => {
    await this.props.updateSurveyFields(this.props.match.params.id, this.state.surveysFields, this.state.language_id, false);
    this.setState({ formEditingNotice: true, selectedFields: [] });
  };

  addField = (index) => {
    const newSurveysFields = this.state.surveysFields.map((d, i) => {
      return {
        ...d,
        options: index === i ? (d.options === null ? [''] : [...d.options, '']) : d.options,
      };
    });

    this.setState({
      surveysFields: newSurveysFields,
    });
  };

  removeField = (fieldIndex, optionIndex) => {
    this.state.surveysFields[fieldIndex].options.splice(optionIndex, 1);
    this.setState({ surveysFields: this.state.surveysFields });
  };

  handleOptionsChange = (e, fieldIndex, optionIndex) => {
    const { value } = e.target;
    this.state.surveysFields.map((d, i) => {
      return {
        ...d,
        options: fieldIndex === i ? (d.options[optionIndex] = value) : d.options,
      };
    });
    this.setState({ surveysFields: this.state.surveysFields });
  };

  saveTemporaryFields = async () => {
    const { temporaryFields, selectedFields } = this.state;
    const updatedSelectedFields = [...selectedFields, ...temporaryFields];
    this.props.addSurveyFields(updatedSelectedFields, this.state.language_id);
    this.setState({
      selectedFields: updatedSelectedFields,
      temporaryFields: [], // Clear temporary fields after saving
    });
  };

  cancelTemporaryFields = () => {
    this.setState({
      temporaryFields: [], // Clear temporary fields on cancel
    });
  };

  handleFormEditingNoticeOk = async () => {
    this.saveTemporaryFields();
    this.setState({ formEditingNotice: false });
  };

  handleFormEditingNoticeCancel = async () => {
    this.cancelTemporaryFields();
    this.setState({ formEditingNotice: false });
  };

  handleSubmitFields = async () => {
    await this.props.updateSurveyFields(this.props.match.params.id, this.state.surveysFields, this.state.language_id);
  };

  startEditingField = (fieldIndex) => {
    this.setState({ editingFieldIndex: fieldIndex });
    if (this.state.surveysFields.length > 0) {
      const label = this.state.surveysFields[fieldIndex].label;
      const is_required = this.state.surveysFields[fieldIndex].is_required;
      const field_name = this.state.surveysFields[fieldIndex].field_name;
      const display_type = this.state.surveysFields[fieldIndex].display_type || '';
      const survey_field_order = this.state.surveysFields[fieldIndex].survey_field_order || 0;

      this.setState({
        temporaryDataFields: {
          label: label,
          is_required: is_required,
          display_type: display_type,
          field_name: field_name,
          survey_field_order: survey_field_order
        },
      });      
    }
  };

  handleLabelChange = (event) => {
    const { value } = event.target;
    if (value.length > 4000) {
      this.setState({
        fieldLabelMaxLengthError: true,  // Set the error state
      })}else{
        this.setState((prevState) => ({
          temporaryDataFields: {
            ...prevState.temporaryDataFields,
            label: value,
          },
          fieldLabelMaxLengthError: false,
        }));
      }
  };

  handleIsRequiredChange = (checked) => {
    this.setState((prevState) => ({
      temporaryDataFields: {
        ...prevState.temporaryDataFields,
        is_required: checked ? 1 : 0,
      },
    }));
  };

  handleApplyClick = () => {
    const { temporaryDataFields, surveysFields, editingFieldIndex } = this.state;

    // Find the index of the field you want to update (you need to determine this based on your logic)
    const fieldIndexToUpdate = editingFieldIndex; // Replace with your logic to find the index

    // Update the surveysFields array with the new is_required value
    const updatedSelectedFields = [...surveysFields];
    updatedSelectedFields[fieldIndexToUpdate].label = temporaryDataFields.label;
    updatedSelectedFields[fieldIndexToUpdate].is_required = temporaryDataFields.is_required;

    this.setState({
      surveysFields: updatedSelectedFields,
    });
  };

  handleDisplayFunction = (value, event) => {
    this.setState((prevState) => ({
      temporaryDataFields: {
        ...prevState.temporaryDataFields,
        display_type: event.key,
      },
    }));
  };

  renderFieldsOptions = (field, index) => {
    if (field.options) {
      return field.options.map((d, i) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '1rem',
            }}
          >
            <Input
              placeholder="Basic usage"
              style={{ width: '100%' }}
              value={d}
              onChange={(e) => this.handleOptionsChange(e, index, i)}
            />
            <Button onClick={() => this.removeField(index, i)} type="primary">
              -
            </Button>
          </div>
        );
      });
    }
    return null;
  };

  render() {
    const { props } = this,
      { t, locale } = props;

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: props => (
          <Can
            rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
            redirect={`/${locale}/d`}
          >
            <InformationTab
              {...this.props}
              {...this.state}
              handleChangeSelect={this.handleChangeSelect}
              handleChange={this.handleChange}
              handleSingleCheckboxChange={this.handleSingleCheckboxChange}
              handleClick={this.handleClick}
              handleContentLanguageChange={this.handleContentLanguageChange}
              handleCheckboxChange={this.handleCheckboxChange}
              handleDeselectChange={this.handleChangeDeselect}
            />
          </Can>
        )
      },
      {
        title: t('Field'),
        slug: 'field',
        container: props => (
          <Can
            rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
            redirect={`/${locale}/d`}
          >
            <FieldsTab
              {...this.props}
              {...this.state}
              handleContentLanguageChange={this.handleContentLanguageChange}
              handleSubmitFields={this.handleSubmitFields}
              startEditingField={this.startEditingField}
              renderFieldsOptions={this.renderFieldsOptions}
              addField={this.addField}
              handleLabelChange={this.handleLabelChange}
              handleDisplayFunction={this.handleDisplayFunction}
              handleApplyClick={this.handleApplyClick}
              handleFormEditingNoticeOk={this.handleFormEditingNoticeOk}
              handleFormEditingNoticeCancel={this.handleFormEditingNoticeCancel}
              handleIsRequiredChange={this.handleIsRequiredChange}
              selectFieldsOptions={this.selectFieldsOptions}
              addNewFields={this.addNewFields}
            />
          </Can>
        )
      },
      {
        title: t('History'),
        slug: 'result',
        container: props => (
          <Can
            rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
            redirect={`/${locale}/d`}
          >
            <ResultsTab 
              {...this.props}
              {...this.state}
            />
          </Can>
        )
      }
      //{title: t('Contributions'), slug: 'donations', container: (props) => <HistoryTab />}
    ];

    return (
      <Layout className="SurveysListPage">
        <ResponsiveTabs pages={tabPages} parentPage={this.props} is_survey={ this.state.is_survey } />
      </Layout>
    );
  }
}

export default translate('SurveyPage')(SurveyPage);

import { requestApiGet, requestApiPost } from 'Parts/common/requestUtils';
import { notification } from 'antd';
import i18next from 'i18next';
import { SAVE_PARTICIPANT_CREATE_SUCCESS } from '../../participant/participantActions';

export const FETCH_SURVEYS_PAGE_DETAILS_START = 'FETCH_SURVEYS_PAGE_DETAILS_START';
export const FETCH_SURVEYS_PAGE_DETAILS_SUCCESS = 'FETCH_SURVEYS_PAGE_DETAILS_SUCCESS';
export const FETCH_SURVEYS_PAGE_DETAILS_ERROR = 'FETCH_SURVEYS_PAGE_DETAILS_ERROR';

export const SUBMIT_SURVEY_START = 'SUBMIT_SURVEY_START';
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
export const SUBMIT_SURVEY_ERROR = 'SUBMIT_SURVEY_ERROR';

export const FETCH_SURVEY_START = 'FETCH_SURVEY_START';
export const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS';
export const FETCH_SURVEY_ERROR = 'FETCH_SURVEY_ERROR';

export const SUBMIT_SURVEY_STORE_SUCCESS = 'SUBMIT_SURVEY_STORE_SUCCESS';
export const SUBMIT_SURVEY_SUBMIT_SUCCESS = 'SUBMIT_SURVEY_SUBMIT_SUCCESS';

export const surveysDetailAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SURVEYS_PAGE_DETAILS_START });

    return requestApiGet(`/survey/${id}`)
      .then((result) => {
        return dispatch({ type: FETCH_SURVEYS_PAGE_DETAILS_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        return dispatch({
          type: FETCH_SURVEYS_PAGE_DETAILS_ERROR,
          payload: error,
        });
      });
  };
};

export const surveyAttemptAction = (data, local) => {
  return async (dispatch) => {
    dispatch({ type: SUBMIT_SURVEY_START });

    return requestApiPost(`/survey/attempt?lang_id=${local}`, data)
      .then((result) => {
        // notification.success({
        //   message: i18next.t('API_NOTIFICATIONS:success'),
        //   description: i18next.t('API_NOTIFICATIONS:survey-attempt'),
        // });
        dispatch({ type: SUBMIT_SURVEY_STORE_SUCCESS, payload: [] });
        dispatch({ type: SUBMIT_SURVEY_SUBMIT_SUCCESS, payload: [] });
        dispatch({ type: SUBMIT_SURVEY_SUCCESS, payload: result });
        return dispatch({ type: SAVE_PARTICIPANT_CREATE_SUCCESS, payload: '' });
      })
      .catch((error) => {
        // notification.success({
        //   message: i18next.t('API_NOTIFICATIONS:survey-failed'),
        // });
        return dispatch({
          type: SUBMIT_SURVEY_ERROR,
          payload: error,
        });
      });
  };
};

export const surveyAttemptStoreAction = (data) => {
  return async (dispatch) => {
    dispatch({ type: SUBMIT_SURVEY_STORE_SUCCESS, payload: data });
  };
};

export const surveyAttemptSubmitAction = (data) => {
  return async (dispatch) => {
    dispatch({ type: SUBMIT_SURVEY_SUBMIT_SUCCESS, payload: data });
  };
};

export const fetchAllSurveys = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SURVEY_START });

    return requestApiGet(`/survey`)
      .then((result) => {
        return dispatch({ type: FETCH_SURVEY_SUCCESS, payload: result.results });
      })
      .catch((error) => {
        return dispatch({
          type: FETCH_SURVEY_ERROR,
          payload: error,
        });
      });
  };
};
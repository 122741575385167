import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AdminHeader from './AdminHeader';
import { logout, setSessionLanguage } from "../../parts/session/sessionActions";

const mapStateToProps = state => ({
  user: state.session.user,
  locale: state.session.language,
  platform: state.platform.platform,
  isEditingForm: state.ui.isEditingForm
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  setSessionLanguage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { clearCampaign, changeCampaignCreateWizardStep } from "../../ui/uiActions";
import { activateCreationMode, deactivateCreationMode } from "../../parts/ui/uiActions";
import { checkAuthorization } from 'Parts/common/authorizationUtils';

import CampaignCreatePage from './CampaignCreatePage';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  currentStep: state.ui.campaignCreateWizardState,
  campaignInformation: state.campaign.campaignInformation,
  campaignEnabled: state.platform.platform.settings.campaign.creation.enabled,
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeCampaignCreateWizardStep,
  clearCampaign,
  activateCreationMode,
  deactivateCreationMode,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(CampaignCreatePage);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import dataFetcher from 'Parts/chore/dataFetcher';
import { withRouter } from 'react-router';
import { Form } from 'antd';

import { updatePlatformDescription } from '../../../platform/platformActions';
import PagesHomepageIntroTextForm from './PagesHomepageIntroTextForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  languages: state.platform.languages,
  locale: state.session.language,
  accentColors: state.platform.platform.settings.color,
  userRoleId: state.session.user.roleId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformDescription
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher,
  Form.create()
)(PagesHomepageIntroTextForm);

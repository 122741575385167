import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CreateConfirmationPanel from './CreateConfirmationPanel';

const mapStateToProps = state => ({
  platformAlias: state.platform.platform.alias,
  createdTeam: state.team.createdTeam,
  selectedCampaign: state.team.selectedCampaign,
  locale: state.session.language,
  isUserLogged: state.session.userTypeId === 2
});

export default compose(
  connect(mapStateToProps),
  withRouter,
)(CreateConfirmationPanel);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { fetchTeamCaptain } from '../teamActions';

import PublicCaptainQuote from './PublicCaptainQuote';

const mapStateToProps = state => ({
  description: state.team.team.description,
  message: state.team.team.description,
  teamId: state.team.team.id,
  captain: state.team.team.leader,
  locale: state.session.language,
  userId: state.session.userId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchTeamCaptain
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PublicCaptainQuote)

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Card, Select, Modal, Switch, Input, Button } from 'antd';
import { get } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDefaultLanguageKey } from 'Parts/common/langUtils';
import TextField from 'Parts/ui/components/fields/TextField';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import SettingsColumn from '../../../components/SettingsColumn';
import LanguageBoxForm from '../../../components/LanguageBoxFormContainer';
import SettingsBox from '../../../components/SettingsBox';
import SurveysPage_en from '../locales/SurveysPage_en.json';
import SurveysPage_fr from '../locales/SurveysPage_fr.json';
import '../../SurveysListPage.less';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class FieldsTab extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'FieldsTab', SurveysPage_en);
    i18next.addResourceBundle('fr', 'FieldsTab', SurveysPage_fr);

    this.state = {
      surveysFieldsArry : []
    }
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.fetchSurveyFields(this.props.match.params.id, this.props.locale);
      await this.props.fetchFields();
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.surveysFields !== prevProps.surveysFields) {
      this.setState({
        surveysFieldsArry: this.props.surveysFields
      });
    }
  }
  onDragEnd = async(result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let items = this.reorder(
      this.state.surveysFieldsArry,
      result.source.index,
      result.destination.index
    );
    items[result.destination.index].survey_field_order = result.destination.index;
    this.setState({
      surveysFieldsArry: items
    });
    await this.props.updateTempSurveyFields(items);
    this.props.startEditingField(result.destination.index);
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  

  render() {
    const {
      t,
      form,
      defaultLanguage,
      disabled,
      loading,
      contentLang,
      handleContentLanguageChange,
      handleSubmitFields,
      startEditingField,
      renderFieldsOptions,
      addField,
      handleFormEditingNoticeOk,
      surveysFields,
      formEditingNotice,
      handleFormEditingNoticeCancel,
      handleIsRequiredChange,
      selectFieldsOptions,
      locale,
      dropdownClassName,
      addNewFields,
      fields,
      temporaryDataFields,
      handleLabelChange,
      handleDisplayFunction,
      isDraft,
      handleApplyClick,
      editingFieldIndex,
      selectedFields,
      fieldLabelMaxLengthError
    } = this.props;

    const {
      surveysFieldsArry
    } = this.state;
    return (
      <>
        <Row>
          <Col xs={12} className="AdminTitleBar--title">
            <h2>{t('Fields')}</h2>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Link onClick={handleSubmitFields} className="ant-btn ant-btn-primary">
              {t('Publish_changes')}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="fields">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <div className="SurveyInfoInputs">
                      {surveysFieldsArry.map((field, index) => (
                        <Draggable key={field.id} draggableId={field.id} index={index}>
                          {(provided) => {
                            const label = field.label;
                            const type = field.field_type;
                            const isEditing = editingFieldIndex === index;
                            surveysFieldsArry[index].survey_field_order = index
                            if (type === 'date' || type === 'text' || type === 'textarea') {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  className={`SurveyInfoInputsItem ${isEditing ? 'active' : ''}`}
                                  onClick={() => startEditingField(index)}
                                >
                                  <div className="dragSpace">
                                    <FontAwesomeIcon icon={['fas', 'grip-vertical']} />
                                  </div>
                                  <div className="contentSpace">
                                    <Row>
                                      <Col span={12}>
                                        <label>
                                          {label}
                                          {field.is_required ? (
                                            <span style={{ color: '#EB3052', marginLeft: '0.5rem' }}>*</span>
                                          ) : null}
                                        </label>
                                      </Col>
                                      <Col span={12} style={{ textAlign: 'end' }}>
                                        <FontAwesomeIcon
                                          icon={['fal', 'trash-alt']}
                                          className="gear-icon"
                                          onClick={() => {
                                            this.props.deleteFieldsInformations(
                                              field.id,
                                              this.props.match.params.id,
                                              this.props.locale
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Input type={field.field_type} />
                                    <Input type="hidden" name='survey_field_order' value={field.survey_field_order} />
                                  </div>
                                </div>
                              );
                            } else if (type === 'dropdown') {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  className={`SurveyInfoInputsItem ${isEditing ? 'active' : ''}`}
                                  onClick={() => startEditingField(index)}
                                >
                                  <div className="dragSpace">
                                    <FontAwesomeIcon icon={['fas', 'grip-vertical']} className="gear-icon" />
                                  </div>
                                  <div className="contentSpace">
                                    <Row>
                                      <Col span={12}>
                                        <label>
                                          {label}
                                          {field.is_required ? (
                                            <span style={{ color: '#EB3052', marginLeft: '0.5rem' }}>*</span>
                                          ) : null}
                                        </label>
                                      </Col>
                                      <Col span={12} style={{ textAlign: 'end' }}>
                                        <Button
                                          style={{ marginRight: '1.5rem', padding: '0.15rem 0.5rem' }}
                                          onClick={() => addField(index)}
                                          type="secondary"
                                        >
                                          +
                                        </Button>
                                        <FontAwesomeIcon
                                          icon={['fal', 'trash-alt']}
                                          className="gear-icon"
                                          onClick={() => {
                                            this.props.deleteFieldsInformations(
                                              field.id,
                                              this.props.match.params.id,
                                              this.props.locale
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Input type="hidden" name='survey_field_order' value={field.survey_field_order} />
                                    </Row>
                                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                      {renderFieldsOptions(field, index)}
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (type === 'radio') {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  className={`SurveyInfoInputsItem ${isEditing ? 'active' : ''}`}
                                  onClick={() => startEditingField(index)}
                                >
                                  <div className="dragSpace">
                                    <FontAwesomeIcon icon={['fas', 'grip-vertical']} className="gear-icon" />
                                  </div>
                                  <div className="contentSpace">
                                    <Row>
                                      <Col span={12}>
                                        <label>
                                          {label}
                                          {field.is_required ? (
                                            <span style={{ color: '#EB3052', marginLeft: '0.5rem' }}>*</span>
                                          ) : null}
                                        </label>
                                      </Col>
                                      <Col span={12} style={{ textAlign: 'end' }}>
                                        <Button
                                          style={{ marginRight: '1.5rem', padding: '0.15rem 0.5rem' }}
                                          onClick={() => addField(index)}
                                          type="secondary"
                                        >
                                          +
                                        </Button>
                                        <FontAwesomeIcon
                                          icon={['fal', 'trash-alt']}
                                          className="gear-icon"
                                          onClick={() => {
                                            this.props.deleteFieldsInformations(
                                              field.id,
                                              this.props.match.params.id,
                                              this.props.locale
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Input type="hidden" name='survey_field_order' value={field.survey_field_order} />
                                    </Row>
                                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                      {renderFieldsOptions(field, index)}
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (type === 'checkbox') {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  className={`SurveyInfoInputsItem ${isEditing ? 'active' : ''}`}
                                  onClick={() => startEditingField(index)}
                                >
                                  <div className="dragSpace">
                                    <FontAwesomeIcon icon={['fas', 'grip-vertical']} className="gear-icon" />
                                  </div>
                                  <div className="contentSpace">
                                    <Row>
                                      <Col span={12}>
                                        <label>
                                          {label}
                                          {field.is_required ? (
                                            <span style={{ color: '#EB3052', marginLeft: '0.5rem' }}>*</span>
                                          ) : null}
                                        </label>
                                      </Col>
                                      <Col span={12} style={{ textAlign: 'end' }}>
                                        <Button
                                          style={{ marginRight: '1.5rem', padding: '0.15rem 0.5rem' }}
                                          onClick={() => addField(index)}
                                          type="secondary"
                                        >
                                          +
                                        </Button>
                                        <FontAwesomeIcon
                                          icon={['fal', 'trash-alt']}
                                          className="gear-icon"
                                          onClick={() => {
                                            this.props.deleteFieldsInformations(
                                              field.id,
                                              this.props.match.params.id,
                                              this.props.locale
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Input type="hidden" name='survey_field_order' value={field.survey_field_order} />
                                    </Row>
                                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                      {renderFieldsOptions(field, index)}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          }}
                        </Draggable>
                      ))}
                    </div>
                    <div
                      className="AdminTitleBar--buttons"
                      style={{ justifyContent: 'center', marginTop: '110px' }}
                    >
                      <Link className="ant-btn ant-btn-secondary" onClick={addNewFields}>
                        {t('Add_field')}
                      </Link>
                    </div>
                    <Modal
                      title={t('field-list')}
                      visible={formEditingNotice}
                      onOk={handleFormEditingNoticeOk}
                      onCancel={handleFormEditingNoticeCancel}
                    >
                      <Row gutter={[16, 16]}>
                        {fields.map((element) => {
                          let iconMapping = {
                            date: ['fal', 'calendar-alt'],
                            dropdown: ['fas', 'caret-down'],
                            text: ['fal', 'pencil-alt'],
                            radio: ['fal', 'list-alt'],
                            checkbox: ['fas', 'check'],
                            textarea: ['fas', 'question-circle'],
                          };
                          let iconName = iconMapping[element.type];
                          const isSelected = selectedFields.some((obj) => obj.field_type_id == element.id);
                          const name = locale === "fr" ? element.name_fr : element.name_en 
                          const message = locale === "fr" ? element.message_fr : element.message_en 
                          return (
                            <Col md={12} key={element.id}>
                              <Card
                                className={`dataFieldCard ${isSelected ? 'active' : ''}`}
                                bordered={false}
                                onClick={() => {
                                  selectFieldsOptions(element.id, name);
                                }}
                              >
                                <Row>
                                  <Col xs={6}>
                                    <FontAwesomeIcon icon={iconName} />
                                  </Col>
                                  <Col xs={18}>
                                    <h5 className="dataFieldCardTitle">{name}</h5>
                                    <p className="dataFieldCardText">{message}</p>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </Modal>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Col>
          <Col span={8}>
            <div className="SurveyInfoFormWrap">
              <SettingsColumn>
                <LanguageBoxForm
                  handleContentLanguageChange={handleContentLanguageChange}
                  defaultLanguage={getDefaultLanguageKey(get(this.props, 'languages')) || locale}
                />
                <SettingsBox title={t(`${temporaryDataFields.field_name}`)} className="AccessibilityBoxForm">
                  <Form
                    layout="vertical"
                    className="PagesHomepageFeaturedCampaignForm"
                    key={contentLang}
                  >
                    <label htmlFor="toggleSwitch">{t('Required_field')}</label>
                    <br />
                    <Switch
                      checked={temporaryDataFields.is_required === 1}
                      onChange={handleIsRequiredChange}
                    />
                    <TextField
                      form={form}
                      fieldId={'featured_label'}
                      label={t('Label')}
                      contentLang={contentLang}
                      initialValue={
                        temporaryDataFields.label !== '' ? temporaryDataFields.label : ''
                      }
                      onChange={handleLabelChange}
                      maxLength={4000}
                    />
                    {fieldLabelMaxLengthError && <p className="error-message">{t('Max_length_error_msg')}</p>}
                    {/* <div className="AdminContentLangSwitcher__wrap">
                      <label>{t('Display')}</label>
                      {form.getFieldDecorator('LangSwitcher', {
                        initialValue: defaultLanguage,
                      })(
                        <Select
                          suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
                          onChange={handleDisplayFunction}
                          disabled={disabled}
                          dropdownClassName={`AdminContentLangSwitcher__dropdown ${dropdownClassName}`}
                        >
                          <Select.Option key="full_row" value="Fullrow">
                            {t('Full_row')}
                          </Select.Option>
                          <Select.Option key="half_row" value="Halfrow">
                            {t('Half_row')}
                          </Select.Option>
                        </Select>
                      )}
                    </div> */}
                    <div className="SettingsBox__footer">
                      <FundkyButton
                        className="apply"
                        type="primary"
                        text={isDraft ? t('Publish') : t('Apply')}
                        loading={loading}
                        onClick={handleApplyClick}
                      />
                    </div>
                  </Form>
                </SettingsBox>
              </SettingsColumn>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

FieldsTab.propTypes = {
  isDraft: PropTypes.bool,
};

export default translate('FieldsTab', { withRef: true })(FieldsTab);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import dataFetcher from 'Parts/chore/dataFetcher';
import { withRouter } from 'react-router'
import { Form } from 'antd';
import PagesHomepageFeaturedCampaignForm from './PagesHomepageFeaturedCampaignForm';
import { updatePlatformFeaturedCampaigns } from '../../../platform/platformActions';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  languages: state.platform.languages,
  campaigns: state.search.campaigns.results,
  featuredCampaigns: state.platform.featuredCampaigns,
  featuredText: state.platform.platformSections && state.platform.platformSections.featured && state.platform.platformSections.featured.campaigns,
  locale: state.session.language,
  isUpdatePlatformSectionInProgress: state.platform.isUpdatePlatformSectionInProgress,
  isDeletePlatformSectionInProgress: state.platform.isDeletePlatformSectionInProgress,
  isUpdatePlatformFeaturedCampaignsInProgress: state.platform.isUpdatePlatformFeaturedCampaignsInProgress
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformFeaturedCampaigns
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher,
  Form.create()
)(PagesHomepageFeaturedCampaignForm);

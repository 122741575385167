import {
  compose,
  bindActionCreators
} from "redux";
import {
  connect
} from "react-redux";
import {
  withRouter
} from 'react-router-dom';

import {
  fetchCampaign,
  clearCampaign
} from '../campaign/campaignActions';

import RegisterDropdown from './RegisterDropdown';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  team: state.team.team,
  participants: state.participant.participants,
  campaign: state.campaign.campaign,
  locale: state.session.language,
  isUserLoggedIn: state.session.userTypeId === 2
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCampaign,
  clearCampaign
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(RegisterDropdown);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from "../../parts/chore/dataFetcher";

import PagesRefundPage from './PagesRefundPage';

const mapStateToProps = state => ({
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(PagesRefundPage)

import {
  FETCH_ORGANIZATION_START,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_ERROR,

  FETCH_ORGANIZATION_INFORMATION_START,
  FETCH_ORGANIZATION_INFORMATION_SUCCESS,
  FETCH_ORGANIZATION_INFORMATION_ERROR,

  FETCH_ORGANIZATION_SETTINGS_START,
  FETCH_ORGANIZATION_SETTINGS_SUCCESS,
  FETCH_ORGANIZATION_SETTINGS_ERROR,

  FETCH_ORGANIZATION_BILLING_START,
  FETCH_ORGANIZATION_BILLING_SUCCESS,
  FETCH_ORGANIZATION_BILLING_ERROR,

  CHECK_IS_BILLING_CONFIGURED
} from './organizationActions';

const initialState = {
  organization: {},
  isFetchOrganizationInProgress: false,
  isFetchOrganizationInformationInProgress: false,
  isFetchOrganizationSettingsInProgress: false,
  isFetchOrganizationBillingInProgress: false,
  isBillingConfigured: false
};

export default function organizationsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORGANIZATION_START:
      return { ...state, isFetchOrganizationInProgress: true };

    case FETCH_ORGANIZATION_SUCCESS:
      return { ...state, isFetchOrganizationInProgress: false,
        organization: { ...state.organization, ...action.payload }
      };

    case FETCH_ORGANIZATION_ERROR:
      return { ...state, isFetchOrganizationInProgress: false };

    case FETCH_ORGANIZATION_INFORMATION_START:
        return { ...state, isFetchOrganizationInformationInProgress: true };

    case FETCH_ORGANIZATION_INFORMATION_SUCCESS:
      return { ...state, isFetchOrganizationInformationInProgress: false,
        organization: {
          ...state.organization,
          information: {
            ...state.organization.information,
            ...action.payload
          }
        }
      };

    case FETCH_ORGANIZATION_INFORMATION_ERROR:
      return { ...state, isFetchOrganizationInformationInProgress: false };

    case FETCH_ORGANIZATION_SETTINGS_START:
        return { ...state, isFetchOrganizationSettingsInProgress: true };

    case FETCH_ORGANIZATION_SETTINGS_SUCCESS:
      return { ...state, isFetchOrganizationSettingsInProgress: false,
        organization: {
          ...state.organization,
          settings: {
            ...state.organization.settings,
            ...action.payload
          }
        }
      };

    case FETCH_ORGANIZATION_SETTINGS_ERROR:
      return { ...state, isFetchOrganizationSettingsInProgress: false };

      case FETCH_ORGANIZATION_BILLING_START:
        return { ...state, isFetchOrganizationBillingInProgress: true };

    case FETCH_ORGANIZATION_BILLING_SUCCESS:
      return { ...state, isFetchOrganizationBillingInProgress: false,
        organization: {
          ...state.organization,
          billing: {
            ...state.organization.billing,
            ...action.payload
          }
        }
      };

    case FETCH_ORGANIZATION_BILLING_ERROR:
      return { ...state, isFetchOrganizationBillingInProgress: false };

    case CHECK_IS_BILLING_CONFIGURED:
      return { ...state, isBillingConfigured: action.isBillingConfigured };

    default:
      return state;
  }
}

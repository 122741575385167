import {
  UI_DONATION_ORGANIZATION_SELECT,
  UI_DONATION_CAMPAIGN_SELECT,
  UI_DONATION_PARTICIPANT_SELECT,
  UI_DONATION_TEAM_SELECT,
  UI_DONATION_CLEAR,
  UI_STORE_DONATION_INFORMATION,
  UI_STORE_USER_INFORMATION,
  SAVE_DONATION_CHECKOUT_SUCCESS,
  SAVE_DONATION_CHECKOUT_DECLINED,
  FETCH_TRANSACTION_BY_ID_START,
  FETCH_TRANSACTION_BY_ID_SUCCESS,
  FETCH_TRANSACTION_BY_ID_ERROR,
  CLEAR_TRANSACTION,
  FETCH_TRANSACTION_LOGS_SUCCESS,
  FETCH_TRANSACTION_LOGS_ERROR,
  FETCH_SURVEBY_PLATFORMBYID_START,
  FETCH_SURVEBY_PLATFORMBYID_SUCCESS,
  FETCH_SURVEBY_PLATFORMBYID_ERROR
} from './donationActions';

import {
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR
} from 'Parts/common/verificationUtils';

const initialState = {
  selectedOrganization: null,
  selectedCampaign: null,
  selectedTeam: null,
  selectedParticipant: null,
  donationInformation: null,
  checkoutInformation: null,
  guestStatus: 'guest',
  transaction: null,
  fetchTransactionFailed: false,
  fetchSurveByPlatformById: false,
  logs: {},
  surveyId:null
};

export default function donationReducer(state = initialState, action) {
  switch (action.type) {
    case UI_DONATION_ORGANIZATION_SELECT:
      return {
        ...state,
        selectedOrganization: action.payload,
        selectedCampaign: initialState.selectedCampaign,
        selectedTeam: initialState.selectedTeam,
        selectedParticipant: initialState.selectedParticipant
      };

    case UI_DONATION_CAMPAIGN_SELECT:
      return {
        ...state,
        selectedOrganization: initialState.selectedOrganization,
        selectedCampaign: action.payload,
        selectedTeam: initialState.selectedTeam,
        selectedParticipant: initialState.selectedParticipant
      };

    case UI_DONATION_TEAM_SELECT:
      return {
        ...state,
        selectedOrganization: initialState.selectedOrganization,
        selectedCampaign: initialState.selectedCampaign,
        selectedTeam: action.payload,
        selectedParticipant: initialState.selectedParticipant
      };

    case UI_DONATION_PARTICIPANT_SELECT:
      return {
        ...state,
        selectedOrganization: initialState.selectedOrganization,
        selectedCampaign: initialState.selectedCampaign,
        selectedTeam: initialState.selectedTeam,
        selectedParticipant: action.payload
      };

    case UI_DONATION_CLEAR:
      return initialState;

    case UI_STORE_DONATION_INFORMATION:
    case UI_STORE_USER_INFORMATION:
      return {
        ...state,
        donationInformation: {
          ...state.donationInformation,
          ...action.payload
        }
      };

    case SAVE_DONATION_CHECKOUT_SUCCESS:
    case SAVE_DONATION_CHECKOUT_DECLINED:
      return { ...state, checkoutInformation: action.payload };

    case VERIFY_EMAIL_START:
      return { ...state, guestStatus: 'guest' };

    case VERIFY_EMAIL_SUCCESS:
      return { ...state, guestStatus: action.payload.available ? 'guest' : 'member' };

    case FETCH_TRANSACTION_BY_ID_START:
      return { ...state, fetchTransactionFailed: false };

    case FETCH_TRANSACTION_BY_ID_SUCCESS:
      return { ...state, transaction: action.payload };

    case FETCH_TRANSACTION_BY_ID_ERROR:
      return { ...state, transaction: initialState.transaction, fetchTransactionFailed: true };

    case FETCH_SURVEBY_PLATFORMBYID_START:
      return { ...state, fetchSurveByPlatformById: false };

    case FETCH_SURVEBY_PLATFORMBYID_SUCCESS:
      return { ...state, surveyId: action.payload , fetchSurveByPlatformById :true};

    case FETCH_SURVEBY_PLATFORMBYID_ERROR:
      return { ...state, fetchSurveByPlatformById: false };

    case CLEAR_TRANSACTION:
      return {
        ...state,
        transaction: initialState.transaction,
        logs: initialState.logs,
        fetchTransactionFailed: false
      };

    case FETCH_TRANSACTION_LOGS_SUCCESS:
      return { ...state, logs: action.payload };

    case FETCH_TRANSACTION_LOGS_ERROR:
      return { ...state, logs: initialState.logs };

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { getPlatformUrl } from 'Parts/common/environment';
import moment from 'moment';

const SitemapCampaigns = ({ platform, campaigns, teams, participants }) => {
  const languages = ['en', 'fr'];
  const platformUrl = getPlatformUrl(platform.alias);
  const campaignsList = campaigns
    ? campaigns.map((campaign, index) => {
        const campaignModifiedOn = campaign.modifiedOn || campaign.addedOn;
        let lastMod = moment(campaignModifiedOn).format('YYYY/MM/DD');
        return (
          <React.Fragment key={`campaign_${index}`}>
            {languages.map(language => {
              return (
                <url key={`campaign_${index}_${language}`}>
                  <loc>{`${platformUrl}/${language}/${campaign.alias}`}</loc>
                  <lastmod>{lastMod}</lastmod>
                </url>
              );
            })}
          </React.Fragment>
        );
      })
    : null;

  const teamsList = teams
    ? teams.map((team, index) => {
        let lastMod = moment(team.updatedOn || team.addedOn).format('YYYY/MM/DD');
        return (
          <React.Fragment key={`team_${index}`}>
            {languages.map(language => {
              return (
                <url key={`team_${index}_${language}`}>
                  <loc>{`${platformUrl}/${language}/${team.campaignAlias}/${team.alias}`}</loc>
                  <lastmod>{lastMod}</lastmod>
                </url>
              );
            })}
          </React.Fragment>
        );
      })
    : null;

  const participantsList = participants
    ? participants.map((participant, index) => {
        let lastMod = moment(participant.updatedOn || participant.addedOn).format('YYYY/MM/DD');

        if (participant.teamAlias) {
          return (
            <React.Fragment key={`participant_${index}`}>
              {languages.map(language => {
                return (
                  <url key={`participant_${index}_${language}`}>
                    <loc>{`${platformUrl}/${language}/${participant.campaignAlias}/${participant.teamAlias}/${participant.member.alias}`}</loc>
                    <lastmod>{lastMod}</lastmod>
                  </url>
                );
              })}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={`participant_${index}`}>
              {languages.map(language => {
                let individualUrl =
                  language === 'en' ? 'individual-participant' : 'participant-individuel';
                return (
                  <url key={`participant_${index}_${language}`}>
                    <loc>{`${platformUrl}/${language}/${participant.campaignAlias}/${individualUrl}/${participant.member.alias}`}</loc>
                    <lastmod>{lastMod}</lastmod>
                  </url>
                );
              })}
            </React.Fragment>
          );
        }
      })
    : null;

  return (
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      {campaignsList}
      {teamsList}
      {participantsList}
    </urlset>
  );
};

SitemapCampaigns.defaultProps = {
  platform: {},
  campaigns: [],
  teams: [],
  participants: []
};

SitemapCampaigns.propTypes = {
  platform: PropTypes.object.isRequired,
  campaigns: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired
};

export default SitemapCampaigns;

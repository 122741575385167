import { bindActionCreators, compose } from "redux";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { selectCampaign } from '../teamActions';
import { searchCampaigns, clearSearch } from '../../search/searchActions';

import SelectCampaignPanel from './SelectCampaignPanel';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  campaigns: state.search.campaigns.results,
  pagination: state.search.campaigns.pagination,
  filters: state.search.campaigns.filters,
  search: state.search.campaigns.search,
  orderBy: state.search.campaigns.orderBy,
  locale: state.session.language,
  memberId: state.session.userId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectCampaign,
  searchCampaigns,
  clearSearch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(SelectCampaignPanel)

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { has, get } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import { getCdnUrl, getPlatformUrl } from 'Parts/common/environment.js';
import { getLanguages } from 'Parts/common/langUtils';

import HeadMetas_en from './locales/HeadMetas_en.json';
import HeadMetas_fr from './locales/HeadMetas_fr.json';

import '../assets/images/default_media_1200x630.jpg';

const cdnUrl = getCdnUrl();

/**
 * Component that renders the HeadMetas.
 * The component MUST be added outside of the <Helmet></Helmet> tags since Helmet doesn't allow components as childrens.
 */

class HeadMetas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareImageUrl: null,
      shareImageSize: null
    };

    i18next.addResourceBundle('en', 'HeadMetas', HeadMetas_en);
    i18next.addResourceBundle('fr', 'HeadMetas', HeadMetas_fr);
  }

  componentDidMount() {
    const pageType = this.getPageType();
    const sharedImage = this.getSharedImage(pageType);

    this.getImageDimensions(sharedImage, pageType);
  }

  getPageType = () => {
    const { match, locale, t } = this.props;

    const pageType =
      match.params.participantAlias && match.params.teamAlias
        ? 'participant'
        : match.params.participantAlias
        ? 'participant-solo'
        : match.params.teamAlias
        ? 'team'
        : match.params.campaignAlias
        ? 'campaign'
        : match.path === `/${locale}` ||
          match.path === `/${locale}/${t('URL:login')}` ||
          match.path === `/${locale}/${t('URL:create-an-account')}` ||
          match.path === `/${locale}/${t('URL:create-an-account/confirmation')}` ||
          match.path === `/${locale}/${t('URL:account-recovery')}` ||
          match.path === `/${locale}/${t('URL:reset-password')}`
        ? 'home'
        : match.path.includes(t('URL:create-a-campaign'))
        ? 'create-a-campaign'
        : match.path.includes(t('URL:create-a-team'))
        ? 'create-a-team'
        : match.path.includes(t('URL:create-a-participant'))
        ? 'create-a-participant'
        : 'static';

    return pageType;
  };

  getSharedImage = pageType => {
    const { platform, campaign, participant } = this.props;

    const platformUrl = getPlatformUrl(platform.alias);
    const platformShare = get(platform, 'media.thumbnail.thumbnail')
      ? `${cdnUrl}${platform.media.thumbnail.thumbnail}`
      : get(platform, 'media.logo.logo')
      ? `${cdnUrl}${platform.media.logo.logo}`
      : `${platformUrl}/assets/default_media_1200x630.jpg`;

    switch (pageType) {
      // Participants
      case 'participant':
      case 'participant-solo':
        const participantImage = participant.avatar
          ? cdnUrl + participant.avatar
          : `${platformUrl}/assets/default_participant_picture_300x300.jpg`;

        return participantImage;

      // Team & Campaign
      case 'team':
      case 'campaign':
        const campaignImage = has(campaign, 'media.banner.banner')
          ? cdnUrl + campaign.media.banner.banner
          : platformShare;

        return campaignImage;

      // Other pages
      case 'home':
      case 'static':
      case 'create-a-campaign':
      case 'create-a-team':
      case 'create-a-participant':
        return platformShare;
    }
  };

  getImageDimensions = (url, pageType) => {
    var img = new Image();
    img.addEventListener('load', e => {
      if (e.path) {
        this.setState({
          shareImageSize: {
            [pageType]: {
              width: e.path[0].naturalWidth,
              height: e.path[0].naturalHeight
            }
          }
        });
      }
    });
    img.src = url;
  };

  render() {
    const { t, locale, orgazination, platform, campaign, team, participant, title } = this.props;

    const { shareImageSize } = this.state;

    const pageType = this.getPageType();
    const sharedImage = this.getSharedImage(pageType);

    let titleContent, ogTitleContent, ogTypeContent, ogDescriptionContent, ogUrlContent;

    const platformUrl = getPlatformUrl(platform.alias);
    const baseUrlContent = `${platformUrl}/${locale}`;

    switch (pageType) {
      case 'participant':
        titleContent = participant.member && participant.member.name ? participant.member.name : '';
        ogTitleContent = titleContent;
        ogTypeContent = 'article';
        ogDescriptionContent = t('participant.og-description');
        ogUrlContent = `${baseUrlContent}/${campaign.alias}/${team.alias}/${(participant.member &&
          participant.member.alias) ||
          ''}`;
        break;

      case 'participant-solo':
        titleContent = participant.member && participant.member.name ? participant.member.name : '';
        ogTitleContent = titleContent;
        ogTypeContent = 'article';
        ogDescriptionContent = t('participant.og-description');
        ogUrlContent = `${baseUrlContent}/${campaign.alias}/${t('individual-participant')}/${(participant.member &&
          participant.member.alias) ||
          ''}`;
        break;

      case 'team':
        titleContent = team.name ? team.name : '';
        ogTitleContent = titleContent;
        ogTypeContent = 'article';
        ogDescriptionContent = t('team.og-description');
        ogUrlContent = `${baseUrlContent}/${campaign.alias}/${team.alias}`;
        break;

      case 'campaign':
        titleContent = campaign.name ? campaign.name : '';
        ogTitleContent = titleContent;
        ogTypeContent = 'article';
        ogDescriptionContent = t('campaign.og-description');
        ogUrlContent = `${baseUrlContent}/${campaign.alias}`;
        break;

      case 'home':
        titleContent = '';
        ogTitleContent = titleContent;
        ogTypeContent = 'website';
        ogDescriptionContent = `${t('home.og-description')} ${orgazination.name}!`;
        ogUrlContent = `${baseUrlContent}`;
        break;

      case 'static':
        titleContent = title !== '' ? title : '';
        ogTitleContent = titleContent;
        ogTypeContent = 'website';
        ogDescriptionContent = `${t('home.og-description')} ${orgazination.name}!`;
        ogUrlContent = `${baseUrlContent}`;
        break;

      case 'create-a-campaign':
        titleContent = `${t('create-campaign.title')}`;
        ogTitleContent = titleContent;
        ogTypeContent = 'website';
        ogDescriptionContent = `${t('create-campaign.og-description')} ${orgazination.name} ${t(
          'create-campaign.og-description-2'
        )}`;
        ogUrlContent = `${baseUrlContent}`;
        break;

      case 'create-a-team':
        titleContent = `${t('create-team.title')}`;
        ogTitleContent = titleContent;
        ogTypeContent = 'website';
        ogDescriptionContent = `${t('create-team.og-description')} ${orgazination.name}!`;
        ogUrlContent = `${baseUrlContent}`;
        break;

      case 'create-a-participant':
        titleContent = `${t('create-participant.title')}`;
        ogTitleContent = titleContent;
        ogTypeContent = 'website';
        ogDescriptionContent = `${t('create-participant.og-description')} ${orgazination.name}!`;
        ogUrlContent = `${baseUrlContent}`;
        break;
    }

    // Display plateform name in all titles
    if (titleContent == '') {
      titleContent = platform.name;
    } else {
      titleContent = titleContent + ' | ' + platform.name;
    }

    const titleTag = <title>{titleContent}</title>;

    const ogTypeTag = <meta property="og:type" content={ogTypeContent} />;

    const ogTitleTag = <meta property="og:title" content={ogTitleContent} />;

    const ogDescriptionTag = (
      <meta property="og:description" name="description" content={ogDescriptionContent} />
    );

    const ogImageTag = sharedImage ? <meta property="og:image" content={sharedImage} /> : null;

    const ogImageWidthTag = get(shareImageSize, `${pageType}.width`, null) ? (
      <meta property="og:image:width" content={shareImageSize[pageType].width} />
    ) : null;

    const ogImageHeightTag = get(shareImageSize, `${pageType}.height`, null) ? (
      <meta property="og:image:height" content={shareImageSize[pageType].height} />
    ) : null;

    const ogUrlTag = <meta property="og:url" content={ogUrlContent} />;

    const supportedLanguages = getLanguages();

    const ogLocalTags = supportedLanguages
      .map(lang => {
        if (lang.key === locale)
          return <meta key={lang.key} property="og:locale" content={lang.locale} />;
        else return <meta key={lang.key} property="og:locale:alternate" content={lang.locale} />;
      })
      .join();

    const ogSiteNameTag = <meta property="og:site_name" content="Fundky" />;

    return (
      <Helmet>
        <meta charSet="utf-8" />
        {titleTag}
        <meta property="fb:app_id" content="390960288116565" />
        {ogTypeTag}
        {ogTitleTag}
        {ogDescriptionTag}
        {ogImageTag}
        {ogImageWidthTag}
        {ogImageHeightTag}
        {ogUrlTag}
        {ogLocalTags}
        {ogSiteNameTag}
      </Helmet>
    );
  }
}

HeadMetas.defaultProps = {
  title: ''
};

HeadMetas.propTypes = {
  title: PropTypes.string
};

export default translate('HeadMetas')(HeadMetas);

import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import jsonMarkup from 'json-markup';
import { translate } from 'react-i18next';
import { diff } from 'deep-diff';
import ReactHtmlParser from 'react-html-parser';

import { simpleColumn, dateColumn } from './columnUtils';

import './HistoryTable.less';

class HistoryTable extends React.Component {
  constructor(props) {
    super(props);
    if (props.loadLogs) {
      props.loadLogs();
    }
  }

  formatDiff = (differences = []) => {
    const { t } = this.props;
    const modifications = [];

    differences.forEach(function(difference) {
      if (difference && difference.path)
        switch (difference.kind) {
          case 'E':
            modifications.push(
              <span>
                <b>{difference.path.join('.')}</b>: {difference.lhs} =>{' '}
                {difference.rhs}
                <br />
              </span>
            );
          case 'N':
            modifications.push(
              <span>
                <b>{difference.path.join('.')}</b> {t('LOGS:added')}
                <br />
              </span>
            );
            break;
          case 'D':
            modifications.push(
              <span>
                <b>{difference.path.join('.')}</b> {t('LOGS:removed')}
                <br />
              </span>
            );
            break;
          case 'A':
            modifications.push(
              <span>
                <b>{difference.path.join('.')}</b> {t('LOGS:modified')}
                <br />
              </span>
            );
            break;
        };
    });
    return modifications;
  };

  getDescription = (text, logEntry) => {
    const
      { props } = this,
      { t } = props;

    const logger = logEntry.session.name || (
        logEntry.scope.member && logEntry.scope.member.name
      ) || (
        logEntry.scope.member &&
        logEntry.scope.member.id &&
        `${t('LOGS:Member#')}${logEntry.scope.member.id}`
      ) ||
      null;

    const target = {
      organization: (
        logEntry.scope.organization && (
          // Name in scope
          logEntry.scope.organization.name || (
            logEntry.scope.organization.id && (
              (// Name in log.after
                logEntry.log &&
                logEntry.log.after &&
                logEntry.log.after.name
              ) ||
              // #id
              `#${logEntry.scope.organization.id}`
            )
          )
        )
      ) || null,
      platform: (
        logEntry.scope.platform && (
          // Name in scope
          logEntry.scope.platform.name || (
            logEntry.scope.platform.id && (
              (// Name in log.after
                logEntry.log &&
                logEntry.log.after &&
                logEntry.log.after.name
              ) ||
              // #id
              `#${logEntry.scope.platform.id}`
            )
          )
        )
      ) || null,
      campaign: (
        logEntry.scope.campaign && (
          // Name in scope
          logEntry.scope.campaign.name || (
            logEntry.scope.campaign.id && (
              (// Name in log.after
                logEntry.log &&
                logEntry.log.after &&
                logEntry.log.after.name
              ) ||
              // #id
              `#${logEntry.scope.campaign.id}`
            )
          )
        )
      ) || null,
      team: (
        logEntry.scope.team && (
          // Name in scope
          logEntry.scope.team.name || (
            logEntry.scope.team.id && (
              (// Name in log.after
                logEntry.log &&
                logEntry.log.after &&
                logEntry.log.after.name
              ) ||
              // #id
              `#${logEntry.scope.team.id}`
            )
          )
        )
      ) || null,
      participant: (
        logEntry.scope.participant && (
          // Name in scope
          logEntry.scope.participant.name || (
            logEntry.scope.participant.id && (
              (// Name in log.after
                logEntry.log &&
                logEntry.log.after &&
                logEntry.log.after.member &&
                logEntry.log.after.member[0] &&
                logEntry.log.after.member[0].name
              ) ||
              // #id
              `#${logEntry.scope.participant.id}`
            )
          )
        )
      ) || null,
      member: (
        logEntry.scope.member && (
          logEntry.scope.member.name ||
          logEntry.scope.member.id
        )
      ) || null
    };

    const before = logEntry.log && logEntry.log.before || null;
    const after = logEntry.log && logEntry.log.after || null;
    const differences = (before && after) && diff(before, after) || null;
    const comparative = differences && this.formatDiff(differences) || null;

    switch (logEntry.event) {
      // MEMBER //
      case 'FK-MEM-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-created')}
            {t('LOGS:member')} <b>{target.member}</b>.
          </p>
        );
      case 'FK-MEM-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:member')} <b>{target.member}</b>.
            <br />
            {comparative}
          </p>
        );
      case 'FK-MEM-LOGIN':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:logged-in')}
            {t('LOGS:platform')} <b>{target.platform}</b>.
          </p>
        );
      case 'FK-MEM-PRF-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:profile')}
            {t('LOGS:of')}
            {t('LOGS:member')} <b>{target.member}</b>.
            <br />
            {comparative}
          </p>
        );
      case 'FK-MEM-PRF-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:profile')}
            {t('LOGS:of')}
            {t('LOGS:member')} <b>{target.member}</b>.
          </p>
        );

      // ORGANIZATION //
      case 'FK-ORG-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-created')}
            {t('LOGS:organization')} <b>{target.organization}</b>.
          </p>
        );
      case 'FK-ORG-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:organization')} <b>{target.organization}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-ORG-INF-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:information')}
            {t('LOGS:of')}
            {t('LOGS:organization')} <b>{target.organization}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-ORG-INV-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-invited')}
            <b>{logEntry.log.email}</b>
            {t('LOGS:be-an-owner')}
            {t('LOGS:organization')} <b>{target.organization}</b>.
          </p>
        );
      case 'FK-ORG-INV-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:invitation')}
            {t('LOGS:sent-to')}
            <b>{logEntry.log.email}</b>
            {t('LOGS:be-an-owner')}
            {t('LOGS:organization')} <b>{target.organization}</b>.
          </p>
        );
      case 'FK-ORG-INV-ACCEPT':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-accepted')}
            {t('LOGS:invitation')}
            {t('LOGS:be-an-owner')}
            {t('LOGS:organization')} <b>{target.organization}</b>.
          </p>
        );
      case 'FK-ORG-INV-REJECT':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-rejected')}
            {t('LOGS:invitation')}
            {t('LOGS:be-an-owner')}
            {t('LOGS:organization')} <b>{target.organization}</b>.
          </p>
        );

      // PLATFORM //
      case 'FK-PLA-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-created')}
            {t('LOGS:platform')} <b>{target.platform}</b>.
          </p>
        );
      case 'FK-PLA-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-STG-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:settings')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-DSC-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:description')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-DSC-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:description')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-LNG-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:languages')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-LNG-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:languages')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-CNT-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:content')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-CNT-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:content')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-SEC-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:section')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-SEC-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:section')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-MDA-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:media')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-MDA-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:media')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLA-FEA-CAM-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:featured-campaigns')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}.</b>
            <br />
            {comparative}
          </p>
        );

      // CAMPAIGN //
      case 'FK-CAM-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-created')}
            {t('LOGS:campaign')} <b>{target.campaign}</b>
            {t('LOGS:in')}
            {t('LOGS:platform')} <b>{target.platform}</b>.
          </p>
        );
      case 'FK-CAM-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-STG-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:settings')}
            {t('LOGS:of')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-DSC-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:description')}
            {t('LOGS:of')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-DSC-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:description')}
            {t('LOGS:of')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-NOT-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:note')}
            {t('LOGS:of')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-NOT-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:note')}
            {t('LOGS:of')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-MDA-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:media')}
            {t('LOGS:of')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-MDA-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:media')}
            {t('LOGS:of')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-INV-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-invited')}
            <b>{logEntry.log.email}</b>
            {t('LOGS:be-a-manager')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
          </p>
        );
      case 'FK-CAM-INV-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:invitation')}
            {t('LOGS:sent-to')}
            <b>{logEntry.log.email}</b>
            {t('LOGS:be-a-manager')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-INV-ACCEPT':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-accepted')}
            {t('LOGS:invitation')}
            {t('LOGS:be-a-manager')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );
      case 'FK-CAM-INV-REJECT':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-rejected')}
            {t('LOGS:invitation')}
            {t('LOGS:be-a-manager')}
            {t('LOGS:campaign')} <b>{target.campaign}.</b>
            <br />
            {comparative}
          </p>
        );

      // TEAM //
      case 'FK-TEA-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-created')}
            {t('LOGS:team')} <b>{target.team}</b>
            {t('LOGS:in')}
            {t('LOGS:campaign')} <b>{target.campaign}</b>
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}</b>.
          </p>
        );
      case 'FK-TEA-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:team')} <b>{target.team}</b>.
            <br />
            {comparative}
          </p>
        );
      case 'FK-TEA-DSC-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:description')}
            {t('LOGS:of')}
            {t('LOGS:team')} <b>{target.team}</b>.
            <br />
            {comparative}
          </p>
        );
      case 'FK-TEA-DSC-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:description')}
            {t('LOGS:of')}
            {t('LOGS:team')} <b>{target.team}</b>.
            <br />
            {comparative}
          </p>
        );
      case 'FK-TEA-LEA-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-invited')}
            <b>{logEntry.log.email}</b>
            {t('LOGS:be-a-captain')}
            {t('LOGS:team')} <b>{target.team}</b>.
          </p>
        );
      case 'FK-TEA-LEA-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:invitation')}
            {t('LOGS:sent-to')}
            <b>{logEntry.log.email}</b>
            {t('LOGS:be-a-captain')}
            {t('LOGS:team')} <b>{target.team}</b>.
          </p>
        );
      case 'FK-TEA-LEA-ACCEPT':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-accepted')}
            {t('LOGS:be-a-captain')}
            {t('LOGS:team')} <b>{target.team}</b>.
          </p>
        );
      case 'FK-TEA-LEA-REJECT':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-rejected')}
            {t('LOGS:be-a-captain')}
            {t('LOGS:team')} <b>{target.team}</b>.
          </p>
        );

      // PARTICIPANT //
      case 'FK-PAR-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-created')}
            {t('LOGS:participant')} <b>{target.participant}</b>
            {target.team ? (
              <span>
                {t('LOGS:in')}
                {t('LOGS:team')} <b>{target.team}</b>
                {t('LOGS:of')}
              </span>
            ) : (
              t('LOGS:in')
            )}
            {t('LOGS:campaign')} <b>{target.campaign}</b>
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}</b>.
          </p>
        );
      case 'FK-PAR-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:participant')} <b>{target.participant}</b>.
            <br />
            {comparative}
          </p>
        );
      case 'FK-PAR-STG-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:settings')}
            {t('LOGS:of')}
            {t('LOGS:participant')} <b>{target.participant}</b>.
            <br />
            {comparative}
          </p>
        );
      case 'FK-PAR-DSC-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:description')}
            {t('LOGS:of')}
            {t('LOGS:participant')} <b>{target.participant}</b>.
            <br />
            {comparative}
          </p>
        );
      case 'FK-PAR-DSC-DELETE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-deleted')}
            {t('LOGS:description')}
            {t('LOGS:of')}
            {t('LOGS:participant')} <b>{target.participant}</b>.
            <br />
            {comparative}
          </p>
        );

      // DONATION //
      case 'FK-DON-CREATE':
        return (
          <p>
            <b>{`${logEntry.log.firstName} ${logEntry.log.lastName}`} </b>
            {t('LOGS:has-donated')}
            <b>
              {t('LOGS:en$')}
              {logEntry.log.amount}
              {t('LOGS:fr$')}
            </b>
            {t('LOGS:to')}
            {t(`LOGS:${logEntry.log.target.type}`)}{' '}
            <b>{logEntry.log.target.name}</b>.
          </p>
        );
      case 'FK-DON-UPDATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:donation')}
            {t('LOGS:to')}
            {t(`LOGS:${logEntry.log.after.target.type}`)} <b>{logEntry.log.after.target.name}</b>.
            <br />
            {comparative}
          </p>
        );
      case 'FK-DON-OFFLINE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-created')}
            {t('LOGS:donation')}
            {t('LOGS:to')}
            {t(`LOGS:${logEntry.log.target.type}`)} <b>{logEntry.log.target.name}</b>.
            {' (' + t('LOGS:offline') + ')'}
          </p>
        );

      // PLUGIN //
      case 'FK-PLU-TXR-CREATE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-created')}
            {t('LOGS:donation-receipt')} <b>{logEntry.log.receiptNumber}</b>.
          </p>
        );
      case 'FK-PLU-TXR-CANCEL':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-cancelled')}
            {t('LOGS:donation-receipt')} <b>{logEntry.log.receiptNumber}</b>.
          </p>
        );
      case 'FK-PLU-TXR-DOWNLOAD':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-downloaded')}
            {t('LOGS:donation-receipt')} <b>{logEntry.log.receiptNumber}</b>.
          </p>
        );
      case 'FK-PLU-TXR-SEND':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-sent')}
            {t('LOGS:donation-receipt')} <b>{logEntry.log.receiptNumber}</b>.
          </p>
        );
      case 'FK-PLU-TXR-PLA-UPDATE':
        return (logger &&
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:donation-receipt-settings')}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}</b>.
            <br />
            {comparative}
          </p> ||
          <p>
            {t('LOGS:donation-receipt-settings').charAt(0).toUpperCase() + t('LOGS:donation-receipt-settings').slice(1)}
            {t('LOGS:of')}
            {t('LOGS:platform')} <b>{target.platform}</b>
            {t('LOGS:been-updated-fp')}
            <br />
            {comparative}
          </p>
        );
      case 'FK-PLU-TXR-CAM-UPDATE':
        return (logger &&
          <p>
            <b>{logger}</b>
            {t('LOGS:has-updated')}
            {t('LOGS:donation-receipt-settings')}
            {t('LOGS:of')}
            {t('LOGS:campaign')} <b>{target.campaign}</b>.
            <br />
            {comparative}
          </p> ||
          <p>
            {t('LOGS:donation-receipt-settings').charAt(0).toUpperCase() + t('LOGS:donation-receipt-settings').slice(1)}
            {t('LOGS:of')}
            {t('LOGS:campaign')} <b>{target.campaign}</b>
            {t('LOGS:been-updated-fp')}
            <br />
            {comparative}
          </p>
        );

      // TRANSACTION //
      case 'FK-TRN-REVIEW':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-sent')}
            {t('LOGS:transaction-review')}.
          </p>
        );

      // TOOL //
      case 'FK-TOOL-FBK-SCRAPE':
        return (
          <p>
            <b>{logger}</b>
            {t('LOGS:has-sent')}
            {t('LOGS:facebook-scrape-call')}.
          </p>
        );

      default:
        return (
          <p>
            <b>{logEntry.event}</b>
          </p>
        );
    }
  };

  getExpendedContent = logEntry => {
    const { t } = this.props;

    return logEntry.event.indexOf('-UPDATE') != -1
      ? <div className="diff-boxes">
          <div>
            <h5>{t('LOGS:Before')}</h5>
            {ReactHtmlParser(jsonMarkup(logEntry.log.before))}
          </div>
          <div>
            <h5>{t('LOGS:After')}</h5>
            {ReactHtmlParser(jsonMarkup(logEntry.log.after))}
          </div>
        </div>
      : <div>{ReactHtmlParser(jsonMarkup(logEntry.log))}</div>;
  };

  render() {
    const { props } = this,
      { logs } = props,
      { results } = logs;

    const mappedLogs = results && results.map(log => ({ ...log, key: log._id })) || [];

    return (
      <Table
        className="HistoryTable"
        showHeader={false}
        pagination={false}
        size="small"
        dataSource={mappedLogs}
        expandRowByClick
        expandedRowRender={logEntry => this.getExpendedContent(logEntry)}
        columns={[
          dateColumn('Date', 'addedOn', null, 'LLL'),
          simpleColumn('Description', 'event', this.getDescription)
        ]}
      />
    );
  }
}

HistoryTable.propTypes = {
  logs: PropTypes.object.isRequired
};

export default translate('HistoryTable')(HistoryTable);

import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pickBy, get, has } from 'lodash';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';

import FakeField from 'Parts/ui/components/fields/FakeField';
import CheckboxField from 'Parts/ui/components/fields/CheckboxField';
import DatePickerField from 'Parts/ui/components/fields/DatePickerField';
import ParticipantsLimitField from '../../components/fields/ParticipantsLimitField';
import TeamsLimitField from '../../components/fields/TeamsLimitField';
import CancelAndSaveButtons from '../../../components/CancelAndSaveButtons';

import CampaignSettingsPageFormEN from './locales/CampaignSettingsPageForm_en.json';
import CampaignSettingsPageFormFR from './locales/CampaignSettingsPageForm_fr.json';

import './CampaignSettingsPageForm.less';

class CampaignSettingsPageForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CampaignSettingsPageForm', CampaignSettingsPageFormEN);
    i18next.addResourceBundle('fr', 'CampaignSettingsPageForm', CampaignSettingsPageFormFR);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.form.resetFields();
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    const { validateFields, isFieldTouched } = this.props.form;

    // WE NEED TO LOOK IF participants_limit and teams_limit ARE EMPTY AND CHANGE IT TO 0 (UNLIMITED)
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));
        this.props.updateCampaign(updatedValues, this.props.campaign.id);
      }
    });
  };

  render() {
    const { t, campaign, campaignSettings, form } = this.props;
    const participantsLimit = get(campaign, 'settings.participant.limit', '0');
    const teamsLimit = get(campaign, 'settings.team.limit', '0');
    const disableRegistration = has(campaign, 'settings.registration.disabledOn')
      ? moment(campaign.settings.registration.disabledOn, 'YYYY-MM-DD HH:mm:ss')
      : null;
    const participantsPreauth =
      has(campaign, 'settings.participant.creation.preauth') &&
      Number(campaign.settings.participant.creation.preauth);
    const isCampaignInactive =
      campaign &&
      (campaign.statusId === 3 ||
        campaign.statusId === 4 ||
        campaign.statusId === 5 ||
        (campaignSettings &&
          campaignSettings.closureOn &&
          isDateBeforeCurrentDate(campaignSettings.closureOn, true)));

    return (
      <Form className="CampaignSettingsPageForm" onSubmit={this.handleSubmit}>
        <FakeField
          data={t(campaign.type)}
          label={t('Campaign type')}
          className="campaign-type"
          message={(campaign.typeId === 4 && t('no-registration')) || null}
        />
        {campaign.typeId !== 4 && (
          <ParticipantsLimitField
            form={form}
            data={participantsLimit}
            campaignTypeId={campaign.typeId}
            disabled={isCampaignInactive}
          />
        )}
        <TeamsLimitField
          form={form}
          data={teamsLimit}
          campaignTypeId={campaign.typeId}
          disabled={isCampaignInactive}
        />

        {campaign.typeId !== 1 && campaign.typeId !== 4 && campaign.behaviorId === 1 && (
          <CheckboxField
            fieldId="participants_preauth"
            form={form}
            initialValue={participantsPreauth}
            checkboxText={t('accept-participants-label')}
            notice={t('accept-participants-description')}
            disabled={isCampaignInactive}
          />
        )}

        {campaign.typeId !== 4 && (
          <DatePickerField
            form={form}
            fieldId="disable_registration"
            initialValue={disableRegistration}
            showTime={true}
            label={t('disable-registrations-label')}
            allowClear={true}
            placeholder={t('disable-registrations-placeholder')}
            tooltip={t('disable-registrations-tooltip')}
            disabled={isCampaignInactive}
          />
        )}

        {!isCampaignInactive && campaign.typeId !== 4 && <CancelAndSaveButtons /> // no fields are displayed (for now) for standalone campaign (typeId 4)
        }
      </Form>
    );
  }
}

CampaignSettingsPageForm.propTypes = {
  form: PropTypes.object.isRequired
};

export default translate('CampaignSettingsPageForm')(CampaignSettingsPageForm);

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { pickBy, get } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import {
  getLanguageNameByKey,
  getDefaultLanguageKey,
  getLanguageIdByKey
} from 'Parts/common/langUtils';

import SettingsColumn from '../../components/SettingsColumn';
import LanguageBoxForm from '../../components/LanguageBoxFormContainer';
import Wysiwyg, { wrapText } from 'Parts/ui/components/fields/Wysiwyg';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';

import './PagesRefundPageForm.less';

import PagesRefundPageForm_en from './locales/PagesRefundPageForm_en.json';
import PagesRefundPageForm_fr from './locales/PagesRefundPageForm_fr.json';

class PagesRefundPageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang: getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale
    };

    this.changeHasHappened = false;

    this.WysiwygRef = React.createRef();

    i18next.addResourceBundle('en', 'PagesRefundPageForm', PagesRefundPageForm_en);
    i18next.addResourceBundle('fr', 'PagesRefundPageForm', PagesRefundPageForm_fr);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.changeHasHappened = false;

    const { props } = this,
      { form, platform, updatePlatformContent } = props,
      { validateFields, isFieldTouched } = form,
      { contentLang } = this.state;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        /*remove unchanged value of touched fields*/
        if (updatedValues.refund_policy === this.props.content) {
          delete updatedValues.refund_policy;
        }

        const values =
          updatedValues.refund_policy === '<p><br></p>' ? null : updatedValues.refund_policy;

        // Delete platform content
        if (values === null) {
          this.props.deletePlatformContent(
            this.props.platform.id,
            1,
            getLanguageIdByKey(this.state.contentLang)
          );
        } else {
          // update platform content
          updatePlatformContent(platform.id, values, contentLang, 1);
        }
      }
    });
  };

  handleCancel = e => {
    this.changeHasHappened = false;
    this.WysiwygRef.current.getWrappedInstance('Wysiwyg').resetEditorState();
  };

  handleContentLanguageChange = value => {
    this.setState(
      {
        contentLang: value
      },
      () => (this.changeHasHappened = false)
    );
  };

  checkForChange = value => {
    if (this.props.form.isFieldTouched('refund_policy')) this.changeHasHappened = true;
  };

  getRefundPolicy = lang => {
    const lng = getLanguageNameByKey(lang, false);
    const defaultContent = i18next.options.resources[lang].DEFAULT_REFUND_POLICY;
    const defaultValue = `<p>${defaultContent['no-refund']}</p><p>${defaultContent['more-info']}</p>`;

    return get(this.props, `content[${lng}].content`, defaultValue);
  };

  render() {
    const {
      props,
      state,
      handleSubmit,
      WysiwygRef,
      handleCancel,
      getRefundPolicy,
      handleContentLanguageChange
    } = this;
    const { t, form, isFetchPlatformContentInProgress, userRoleId } = props;
    const { contentLang } = state;

    return (
      <div className="AdminFormWrap">
        <Form className="PagesRefundPageForm" onSubmit={handleSubmit}>
          {!isFetchPlatformContentInProgress && (
            <Wysiwyg
              ref={WysiwygRef}
              fieldId="refund_policy"
              label={t('description')}
              form={form}
              initialValue={getRefundPolicy(contentLang)}
              contentLang={contentLang}
              counterWithTag={true}
              allowSizes={false}
              allowImages={false}
              allowVideos={false}
              allowHTML={userRoleId === 1}
              required={false}
              accentColors={this.props.accentColors}
              onChange={this.checkForChange}
            />
          )}
          <CancelAndSaveButtons onClickCancel={handleCancel} />
        </Form>
        <SettingsColumn>
          <LanguageBoxForm
            handleContentLanguageChange={handleContentLanguageChange}
            changeHasHappened={this.changeHasHappened}
            defaultLanguage={
              getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale
            }
          />
        </SettingsColumn>
      </div>
    );
  }
}

PagesRefundPageForm.propTypes = {
  form: PropTypes.object.isRequired
};

export default translate('PagesRefundPageForm')(PagesRefundPageForm);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { get } from 'lodash';

import dataFetcher from 'Parts/chore/dataFetcher';
import { searchMemberTransactions, searchParticipations, clearSearch } from '../../search/searchActions';

import MyContributionsListPage from './MyContributionsListPage';

const mapStateToProps = state => ({
  platformId: get(state, 'platform.platform.id'),
  memberId: get(state, 'session.user.id'),
  locale: get(state, 'session.language'),

  transactions: get(state, 'search.transactions.results', []),
  participations: get(state, 'search.participations.results', []),
  pagination: get(state, 'search.transactions.pagination') || get(state, 'search.participations.pagination'),
  filters: get(state, 'search.transactions.filters') || get(state, 'search.participations.filters'),
  search: get(state, 'search.transactions.search') || get(state, 'search.participations.search'),
  orderBy: get(state, 'search.transactions.orderBy') || get(state, 'search.participations.orderBy'),

  platform: get(state, 'platform.platform'),
  lastCampaigns: get(state, 'campaign.campaigns')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchMemberTransactions,
  searchParticipations,
  clearSearch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(MyContributionsListPage);

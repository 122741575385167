import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import QuickLinks from './components/QuickLinksContainer';
import Can from 'Parts/session/components/CanContainer';

import adminSidebarDashboardEN from './locales/AdminSidebarDashboard_en.json';
import adminSidebarDashboardFR from './locales/AdminSidebarDashboard_fr.json';

class AdminSidebarDashboard extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AdminSidebarDashboard', adminSidebarDashboardEN);
    i18next.addResourceBundle('fr', 'AdminSidebarDashboard', adminSidebarDashboardFR);
  }

  render() {
    const { t, locale, campaignEnabled, isCampaignOwner } = this.props;

    // We need a combination of the react-router NavLink and the Antd Button... let's use NavLink and put the Antd classes on it.
    return (
      // Default Admin Sider
      <div className="DashboardSider">
        <span className="AdminSider__GroupTitle">{t('General')}</span>
        <NavLink
          to={`/${locale}/d`}
          exact
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
          <FontAwesomeIcon icon={['fal', 'tachometer-alt']} fixedWidth /> {t('Dashboard')}
        </NavLink>
        <Can
          rules={[
            [() => (Number(campaignEnabled) || isCampaignOwner ? true : false)],
            ['roles.platform.OWNER'],
            ['roles.platform.SUPERADMIN']
          ]}
        >
          <NavLink
            to={`/${locale}/d/campaigns/list`}
            className="AdminSider__GroupItem ant-btn ant-btn-lg"
            activeClassName="AdminSider__GroupItem--active"
          >
            <FontAwesomeIcon icon={['fal', 'flag']} fixedWidth /> {t('Campaigns')}
          </NavLink>
        </Can>
        <Can
          rules={[
            ['roles.platform.OWNER'],
            ['roles.platform.SUPERADMIN'],
            ['roles.platform.ADMINISTRATOR']
          ]}
        >
          <NavLink
            to={`/${locale}/d/members/list`}
            className="AdminSider__GroupItem ant-btn ant-btn-lg"
            activeClassName="AdminSider__GroupItem--active"
          >
            <FontAwesomeIcon icon={['fal', 'id-card']} fixedWidth /> {t('Members')}
          </NavLink>
        </Can>
        <Can
          rules={[
            ['roles.platform.OWNER'],
            ['roles.platform.SUPERADMIN'],
            ['roles.platform.ADMINISTRATOR']
          ]}
        >
          <NavLink
            to={`/${locale}/d/pages/homepage/main-slider`}
            className="AdminSider__GroupItem ant-btn ant-btn-lg"
            activeClassName="AdminSider__GroupItem--active"
          >
            <FontAwesomeIcon icon={['fal', 'copy']} fixedWidth /> {t('Pages')}
          </NavLink>
        </Can>
        <Can
          rules={[
            ['roles.platform.OWNER'],
            ['roles.platform.SUPERADMIN'],
            ['roles.platform.ADMINISTRATOR']
          ]}
        >
          <NavLink
            to={`/${locale}/d/settings/general`}
            className="AdminSider__GroupItem ant-btn ant-btn-lg"
            activeClassName="AdminSider__GroupItem--active"
          >
            <FontAwesomeIcon icon={['fal', 'cog']} fixedWidth /> {t('Settings')}
          </NavLink>
        </Can>
        <Can
          rules={[
            ['roles.platform.OWNER'],
            ['roles.platform.SUPERADMIN'],
            ['roles.platform.ADMINISTRATOR']
          ]}
        >
          <NavLink
            to={`/${locale}/d/surveys`}
            className="AdminSider__GroupItem ant-btn ant-btn-lg"
            activeClassName="AdminSider__GroupItem--active"
          >
            <FontAwesomeIcon icon={["fal", "id-card"]} fixedWidth /> {t('Surveys')}
          </NavLink>
        </Can>
        <Can rules={['permissions.platform.plugin']}>
          <NavLink
            to={`/${locale}/d/plugins`}
            className="AdminSider__GroupItem ant-btn ant-btn-lg"
            activeClassName="AdminSider__GroupItem--active"
          >
            <FontAwesomeIcon icon={['fal', 'cubes']} fixedWidth /> {t('Plugins')}
          </NavLink>
        </Can>

        {/* Shortcuts */}
        <QuickLinks locale={locale} />
      </div>
    );
  }
}

export default translate('AdminSidebarDashboard')(AdminSidebarDashboard);

import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import ParticipationTypeField from './fields/ParticipationTypeField';
import TeamField from './fields/TeamField';
import SubmitButton from 'Parts/common/SubmitButton';
import AvatarThumbnail from '../../common/AvatarThumbnailContainer';
import HelpText from '../../common/HelpText';
import { PARTICIPANT_CREATE_WIZARD_CAMPAIGN, TEAM_CREATE_WIZARD_CAMPAIGN } from '../../ui/uiUtils';
import { CAMPAIGN_TEMPLATE_TEAMS, CAMPAIGN_TEMPLATE_MIXED } from '../../campaign/campaignUtils';

import CreateInformationForm_en from './locales/CreateInformationForm_en.json';
import CreateInformationForm_fr from './locales/CreateInformationForm_fr.json';

import './CreateInformationForm.less';

class CreateInformationForm extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'CreateInformationForm-participant', CreateInformationForm_en);
    i18next.addResourceBundle('fr', 'CreateInformationForm-participant', CreateInformationForm_fr);
  }

  componentDidMount() {
    const { template } = this.props.selectedCampaign;
    if (template === CAMPAIGN_TEMPLATE_TEAMS || template === CAMPAIGN_TEMPLATE_MIXED) {
      this.props.fetchTeams(this.props.selectedCampaign.id, false, { showChildrenCount: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCampaign.id !== this.props.selectedCampaign.id) {
      this.props.form.resetFields();

      const { template } = this.props.selectedCampaign;
      if (template === CAMPAIGN_TEMPLATE_TEAMS || template === CAMPAIGN_TEMPLATE_MIXED) {
        this.props.fetchTeams(this.props.selectedCampaign.id, false, { showChildrenCount: true });
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    this.props.form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        const is_survey = this.props.surveyId && this.props.surveyId.id ? true : false;
        if (is_survey) {
          this.props.participantInfoAdd(values, this.props.selectedCampaign.id);
        } else {
          this.props.createParticipant(values, this.props.selectedCampaign.id, is_survey);
        }
      }
    });
  };

  handleCampaignClick = () => {
    this.props.changeParticipantCreateWizardStep(PARTICIPANT_CREATE_WIZARD_CAMPAIGN);
  };

  dataLayer = event => {
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'custom_click',
        eventAction: 'click',
        eventCategory: 'button',
        eventLabel: 'create_participant',
        eventValue: 'submit'
      });
    }
  };

  render() {
    const { t, locale, preSelectedTeam } = this.props;
    const selectedTeam = preSelectedTeam || null;
    const changeCampaignButton = !this.props.disableCampaignTab ? (
      <a className="CreateInformationForm__ChangeCampaignBtn" onClick={this.handleCampaignClick}>
        <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
      </a>
    ) : null;

    const campaignInfo = this.props.selectedCampaign ? (
      <div className="CreateInformationForm__Divider CreateInformationForm__Divider--title">
        <AvatarThumbnail
          entity={this.props.selectedCampaign}
          primaryContent={this.props.selectedCampaign.name}
          secondaryContent={<HelpText>{t('campaign-label')}</HelpText>}
          className="AvatarThumbnail--title"
          avatarSize={127}
        />
        {changeCampaignButton}
      </div>
    ) : null;

    let teamSubtitle =
      this.props.selectedCampaign.typeId === CAMPAIGN_TEMPLATE_TEAMS ||
      this.props.selectedCampaign.typeId === CAMPAIGN_TEMPLATE_MIXED ? (
        <h2>{t('subtitle')}</h2>
      ) : null;

    let teamHelp = null;
    if (
      this.props.selectedCampaign.typeId === CAMPAIGN_TEMPLATE_TEAMS ||
      this.props.selectedCampaign.typeId === CAMPAIGN_TEMPLATE_MIXED
    ) {
      teamHelp =
        this.props.selectedCampaign.typeId === CAMPAIGN_TEMPLATE_MIXED ? (
          <p>{t('help1')}</p>
        ) : (
          <p>{t('help2')}</p>
        );
    }

    const participantTypeField =
      this.props.selectedCampaign.typeId === CAMPAIGN_TEMPLATE_MIXED ? (
        <ParticipationTypeField
          className="CreateInformationForm__Field CreateInformationForm__Field--NoFeedbackIcon"
          form={this.props.form}
          campaign={this.props.selectedCampaign}
          value={selectedTeam ? 'team' : null}
        />
      ) : null;

    const field = (
      <TeamField
        className="CreateInformationForm__Field"
        form={this.props.form}
        teams={this.props.teams}
        limitParticipants={get(this.props, 'selectedCampaign.settings.participant.limit', 0)}
        //disabled={!!this.props.preSelectedTeam}
        campaign={this.props.selectedCampaign}
        preSelectedTeam={this.props.preSelectedTeam}
        locale={locale}
      />
    );

    const teamField =
      field && this.props.selectedCampaign.typeId === CAMPAIGN_TEMPLATE_MIXED ? (
        <Collapse
          isOpened={
            !this.props.selectedCampaign.typeId === CAMPAIGN_TEMPLATE_MIXED ||
            this.props.form.getFieldValue('participationType') === 'team'
          }
        >
          {field}
        </Collapse>
      ) : this.props.selectedCampaign.typeId === CAMPAIGN_TEMPLATE_TEAMS ? (
        field
      ) : null;

    return (
      <div className="FundkyWrap">
        <Form className="CreateInformationForm" onSubmit={this.handleSubmit}>
          {campaignInfo}
          {teamSubtitle}
          {teamHelp}
          {participantTypeField}
          {teamField}
          <p>{t('notice')}</p>
          <p>{t('by-going-foward')}</p>
          <div className="CreateInformationForm__SubmitWrapper">
            <SubmitButton
              loading={this.props.isCreateParticipantInProgress}
              dataLayer={this.dataLayer}
            >
              {t('action')}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}

CreateInformationForm.defaultProps = {
  selectedCampaign: {},
  disableCampaignTab: false,
  preSelectedTeam: null
};

CreateInformationForm.propTypes = {
  form: PropTypes.object.isRequired,
  changeParticipantCreateWizardStep: PropTypes.func.isRequired,
  createParticipant: PropTypes.func.isRequired,
  selectedCampaign: PropTypes.object,
  disableCampaignTab: PropTypes.bool,
  isCreateParticipantInProgress: PropTypes.bool.isRequired,
  teams: PropTypes.array.isRequired,
  preSelectedTeam: PropTypes.object
};

export default translate('CreateInformationForm-participant')(CreateInformationForm);

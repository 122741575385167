import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AuthRoute from './AuthRoute';
import { forceLoginModal } from "../ui/uiActions";

const mapStateToProps = state => ({
    userTypeId: state.session.userTypeId,
    connectionModalState: state.ui.connectionModalState,
    displaySessionTimeoutMessage: state.session.displaySessionTimeoutMessage
});

const mapDispatchToProps = dispatch => bindActionCreators({
  forceLoginModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);

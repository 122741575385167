import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import CampaignTypeDiagram_en from './locales/CampaignTypeDiagram_en.json';
import CampaignTypeDiagram_fr from './locales/CampaignTypeDiagram_fr.json';

import './CampaignTypeDiagram.less';

class CampaignTypeDiagram extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CampaignTypeDiagram', CampaignTypeDiagram_en);
    i18next.addResourceBundle('fr', 'CampaignTypeDiagram', CampaignTypeDiagram_fr);
  }

  componentDidMount() {
    //this.displayLevels();
  }

  displayLevels = () => {
    this.displayLevel(1);
  }

  displayLevel = lvl => {
    let
      className = `.CampaignTypeDiagram .level-${lvl}`,
      delay = 0;
    switch(lvl) {
      case 1: delay = .1; break;
      case 2: delay = .8; break;
      case 3: delay = .8; break;
    }
    delay = delay * 1000;
    setTimeout(() => {
      const level = document.querySelectorAll(className);
      level.forEach(l => {
        l.classList.add('show');
      });
      if(lvl < 3) this.displayLevel(lvl + 1);
    }, delay);
  }

  resetLevels = () => {
    const levels = document.querySelectorAll('.CampaignTypeDiagram .level-1, .CampaignTypeDiagram .level-2, .CampaignTypeDiagram .level-3');
    levels.forEach(l => {
      l.classList.remove('show');
    })
  }

  render() {
    const
      { props } = this,
      { t } = props;

    return (
      <div className="CampaignTypeDiagram">
        <div className="row">
          {/* SINGLE CAMPAIGN PAGE */}
          <div className="column align-center w-33 level-1">
            <h3 className="text-center">{t('single-campaign-page')}</h3>
            <FontAwesomeIcon icon={['fal', 'flag']} className="h4Icon" />
            <h4>{t('campaign')}</h4>
            <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
          </div>
          <div className="column align-center or level-1">{t('or')}</div>
          {/* SINGLE PARTICIPANTS */}
          <div className="column align-center w-33 level-1">
            <h3 className="title text-center">{t('single-participants')}</h3>
            <FontAwesomeIcon icon={['fal', 'flag']} className="h4Icon" />
            <h4>{t('campaign')}</h4>
            <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
            <div className="row justify-center align-center py-1 level-2">
              <div className="column align-center w-33">
                <FontAwesomeIcon icon={['fal', 'long-arrow-down']} className="arrow left" />
              </div>
              <div className="column align-center w-33">
                <FontAwesomeIcon icon={['fal', 'long-arrow-down']} className="arrow right" />
              </div>
            </div>
            <div className="row level-2">
              <div className="column align-center w-50">
                <FontAwesomeIcon icon={['fal', 'user']} className="h4Icon" />
                <h4>{t('participant')}</h4>
                <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
              </div>
              <div className="column align-center w-50">
                <FontAwesomeIcon icon={['fal', 'user']} className="h4Icon" />
                <h4>{t('participant')}</h4>
                <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
              </div>
            </div>
          </div>
          <div className="column align-center or level-1">{t('or')}</div>
          {/* TEAMS WITH PARTICIPANTS */}
          <div className="column align-center w-33 level-1">
            <h3 className="title text-center">{t('teams-with-participants')}</h3>
            <FontAwesomeIcon icon={['fal', 'flag']} className="h4Icon" />
            <h4>{t('campaign')}</h4>
            <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
            <div className="row justify-center align-center py-1 level-2">
              <div className="column align-center w-33">
                <FontAwesomeIcon icon={['fal', 'long-arrow-down']} className="arrow" />
              </div>
            </div>
            <div className="row justify-center level-2">
              <div className="column align-center">
                <FontAwesomeIcon icon={['fal', 'users']} className="h4Icon" />
                <h4>{t('team')}</h4>
                <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
                <div className="row justify-center align-center py-1 level-3" >
                  <div className="column align-center w-33">
                    <FontAwesomeIcon icon={['fal', 'long-arrow-down']} className="arrow left" />
                  </div>
                  <div className="column align-center w-33">
                    <FontAwesomeIcon icon={['fal', 'long-arrow-down']} className="arrow right" />
                  </div>
                </div>
                <div className="row justify-center level-3">
                  <div className="column align-center w-50">
                    <FontAwesomeIcon icon={['fal', 'user']} className="h4Icon" />
                    <h4>{t('participant')}</h4>
                    <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
                  </div>
                  <div className="column align-center w-50">
                    <FontAwesomeIcon icon={['fal', 'user']} className="h4Icon" />
                    <h4>{t('participant')}</h4>
                    <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column align-center or level-1">{t('or')}</div>
          {/* TEAMS AND SINGLE PARTICIPANTS */}
          <div className="column align-center w-33 level-1">
            <h3 className="title text-center">{t('teams-single-participants')}</h3>
            <FontAwesomeIcon icon={['fal', 'flag']} className="h4Icon" />
            <h4>{t('campaign')}</h4>
            <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
            <div className="row justify-center align-center py-1 level-2">
              <div className="column align-center w-33">
                <FontAwesomeIcon icon={['fal', 'long-arrow-down']} className="arrow left" />
              </div>
              <div className="column align-center w-33">
                <FontAwesomeIcon icon={['fal', 'long-arrow-down']} className="arrow right" />
              </div>
            </div>
            <div className="row justify-center level-2">
              <div className="column align-center">
                <FontAwesomeIcon icon={['fal', 'user']} className="h4Icon" />
                <h4>{t('participant')}</h4>
                <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
              </div>
              <div className="column align-center">
                <FontAwesomeIcon icon={['fal', 'users']} className="h4Icon" />
                <h4>{t('team')}</h4>
                <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
                <div className="row justify-center align-center py-1 level-3" >
                  <div className="column align-center w-33">
                    <FontAwesomeIcon icon={['fal', 'long-arrow-down']} className="arrow left" />
                  </div>
                  <div className="column align-center w-33">
                    <FontAwesomeIcon icon={['fal', 'long-arrow-down']} className="arrow right" />
                  </div>
                </div>
                <div className="row justify-center level-3">
                  <div className="column align-center w-50">
                    <FontAwesomeIcon icon={['fal', 'user']} className="h4Icon" />
                    <h4>{t('participant')}</h4>
                    <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
                  </div>
                  <div className="column align-center w-50">
                    <FontAwesomeIcon icon={['fal', 'user']} className="h4Icon" />
                    <h4>{t('participant')}</h4>
                    <p>{t('donations')} <FontAwesomeIcon icon={['fal', 'usd-circle']} className="dollarIcon" /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>{t('notice')}</p>
        <p><em>{t('need-help')}<a href={t('help-center-url')} target="_blank">{t('help-center')}</a></em></p>
      </div>
    );
  }
}

export default translate('CampaignTypeDiagram', { withRef: true })(CampaignTypeDiagram);

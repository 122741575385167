import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './StaticBreadcrumbs.less';

class StaticBreadcrumbs extends React.Component {
  render() {

    const chevron = <FontAwesomeIcon icon={["far", "chevron-right"]} />;
    const list = this.props.path.map( (page, index) => {
        return (
          <li key={page.name}>
            <NavLink to={page.url}>
              {page.name}
            </NavLink>
            {index < this.props.path.length - 1 && chevron}
          </li>
        );
      }
    );

    return (
      <div className="StaticBreadcrumbs">
        <ul>
          {list}
        </ul>
      </div>
    );
  }
}

StaticBreadcrumbs.defaultProps = {
  path: [],
};

StaticBreadcrumbs.propTypes = {
  path: PropTypes.array,
};

export default StaticBreadcrumbs;

import {
  bindActionCreators
} from "redux";
import {
  connect
} from "react-redux";

import {
  searchTeamsInCampaign
} from '../../../../search/searchActions';
import {
  checkAuthorization
} from 'Parts/common/authorizationUtils';

import ParticipantTeamField from './ParticipantTeamField';

const mapStateToProps = state => ({
  locale: state.session.language,
  teams: state.search.teams,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchTeamsInCampaign,
  checkAuthorization
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantTeamField);

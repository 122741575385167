import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import LinkButton from 'Parts/common/LinkButton';

import DonationChoicePage_en from './locales/DonationChoicePage_en.json';
import DonationChoicePage_fr from './locales/DonationChoicePage_fr.json';

import './DonationChoicePage.less';
import FundkyCTA from 'Parts/ui/components/FundkyCTA';
import HeadMetas from '../../common/HeadMetasContainer';

class DonationChoicePage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'DonationChoicePage', DonationChoicePage_en);
    i18next.addResourceBundle('fr', 'DonationChoicePage', DonationChoicePage_fr);
  }

  render() {
    const { t, locale } = this.props;

    return (
      <div className="DonationChoicePage">
        <div className="FundkyWrap">
          <HeadMetas title={t('make-a-donation-choices.title')} />
          <h1>{t('who-do-you-want-to-encourage')}</h1>
          <p>{t('subtitle')}</p>
          <div className="DonationChoicePage__CallToActions">
            <FundkyCTA
              className="DonationChoicePage__CallToAction"
              icon={['fal', 'building']}
              title={t('the-organization')}
              description={t('organization-subtitle')}
              cta={
                <LinkButton to={`/${locale}/${t('URL:organization/give')}`} size="large">
                  {t('give')}
                </LinkButton>
              }
            />
            <span>{t('or')}</span>
            <FundkyCTA
              className="DonationChoicePage__CallToAction DonationChoicePage__CallToAction--Primary"
              icon={['fal', 'gift']}
              title={t('a-participant')}
              description={t('participant-subtitle')}
              cta={
                <LinkButton
                  to={{
                    pathname: `/${locale}/${t('URL:give')}/${t('URL.participants')}`,
                    state: { from: 'donation' }
                  }}
                  type="primary"
                  size="large"
                >
                  {t('search')}
                </LinkButton>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default translate('DonationChoicePage')(DonationChoicePage);

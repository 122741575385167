import React from 'react';
import PropTypes from 'prop-types';
import { Form, Alert } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import FirstNameField from '../../ui/components/fields/FirstNameField';
import LastNameField from '../../ui/components/fields/LastNameField';
import EmailField from '../../ui/components/fields/EmailField';
import PasswordField from '../../ui/components/fields/PasswordField';
import OptInFundkyField from '../../ui/components/fields/OptInFundkyField';
import OptInPlatformField from '../../ui/components/fields/OptInPlatformField';
import TermsAndConditionsField from './fields/TermsAndConditionsField';
import SubmitButton from '../../common/SubmitButton';
import PlatformField from './fields/PlatformField';
import { getErrorMessage } from '../../common/requestUtils';
import { CONNECTION_MODAL_REGISTER, CONNECTION_MODAL_CLOSED } from '../../ui/uiUtils';

import RegisterForm_en from './locales/RegisterForm_en.json';
import RegisterForm_fr from './locales/RegisterForm_fr.json';

import './RegisterForm.less';

class RegisterForm extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'RegisterForm', RegisterForm_en);
    i18next.addResourceBundle('fr', 'RegisterForm', RegisterForm_fr);

    this.state = {
      errorCode: null,
      alert: null
    }

    this.token = null;
  }

  componentDidUpdate(prevProps) {
    const { location, t, connectionModalState} = this.props;
     if (
      prevProps.connectionModalState === CONNECTION_MODAL_REGISTER &&
      prevProps.connectionModalState !== connectionModalState &&
      connectionModalState === CONNECTION_MODAL_CLOSED
    ) {
      const badge = document.getElementsByClassName("grecaptcha-badge")[0];
      if (
        badge && badge.classList && badge.classList.contains("show") &&
        location.pathname.indexOf(`/${t('URL:give/payment')}`) === -1 &&
        location.pathname.indexOf(`/${t('URL:ticket-purchase/details')}`) === -1
      ) {
        badge.classList.remove("show");
      }
    }
  }

  getReCaptchaToken = async action => {
    return this.token = await this.props.googleReCaptchaProps.executeRecaptcha(action)
  };

  handleSwitchToLogin = event => {
    event.preventDefault();
    this.reset();
    this.props.openLoginModal();
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // Set reCAPTCHA token
        this.getReCaptchaToken('registration').then(()=>{
          values.reCAPTCHA = this.token || null;
          this.props.register(values, this.props.platform ? this.props.platform.id : undefined)
            .then((msg) => {
              if(msg && msg.error) {
                this.setState({
                  errorCode: msg.error.code,
                  alert: msg.error.code && getErrorMessage(msg.error.code, this.props.t) || msg.error
                });
              } else
              if(this.state.alert) {
                this.setState({
                  errorCode: null,
                  alert: null
                });
              }
            });
        });
      }
    });
  };

  reset = () => {
    this.props.form.resetFields();
    this.setState({
      errorCode: null,
      alert: null
    });
    const fundkyOptin = document.getElementById('fundkyOptin');
    const platformOptin = document.getElementById('platformOptin');
    if(fundkyOptin) fundkyOptin.parentElement.classList.remove("ant-checkbox-checked");
    if(platformOptin) platformOptin.parentElement.classList.remove("ant-checkbox-checked");
  };

  render() {
    const
      { props, state, handleSubmit, handleSwitchToLogin } = this,
      { t, form, className, platform, organization } = props,
      { alert, errorCode } = state,
      platformField = platform ? <PlatformField className="ConnectionForm__HiddenField" form={form} /> : null,
      optInPlatformField = platform ? (
          <OptInPlatformField
            className="ConnectionForm__CompactField"
            form={form}
            platform={platform}
            organization={organization}
          />
        ) : null,
      error = alert ? (
          <Alert
            message=""
            description={(
              <span>
                <b>{alert}</b>
                {errorCode === "FK-MEM-1000" && (
                  <React.Fragment>
                    <br/>{t('choose-other-email')}
                  </React.Fragment>
                )}
              </span>
            )}
            type="error"
            showIcon
          />
        ) : null;

    return (
      <Form
        className={`ConnectionForm ${className}`}
        onSubmit={handleSubmit}
      >
        {error}
        <h1>{t('title')}</h1>
        <div className="ConnectionForm__Divider">
          <FirstNameField
            className="ConnectionForm__Field"
            form={form}
            label={false}
            placeholder={true}
          />
          <LastNameField
            className="ConnectionForm__Field"
            form={form}
            label={false}
            placeholder={true}
          />
        </div>
        <EmailField
          className="ConnectionForm__Field"
          form={form}
          fieldId={'register_email'}
          label={false}
          placeholder={true}
        />
        <PasswordField
          className="ConnectionForm__Field"
          form={form}
          newPassword
          label={false}
          placeholder={true} />
        {optInPlatformField}
        <OptInFundkyField
          className="ConnectionForm__CompactField"
          form={form}
        />
        {platformField}
        <TermsAndConditionsField
          className="ConnectionForm__CompactField"
          form={form}
        />
        <SubmitButton className="ConnectionForm__Field">
          {t('action')}
        </SubmitButton>

        <p className="ConnectionForm__EndNote">
          {t('already-account')}<br/>
          <a href="/" onClick={handleSwitchToLogin}>{t('log-now')}</a>
        </p>
      </Form>
    );
  }

}

RegisterForm.defaultProps = {
  className: '',
}

RegisterForm.propTypes = {
  className: PropTypes.string,
  openLoginModal: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  platform: PropTypes.object
};

export default translate('RegisterForm', { withRef: true })(RegisterForm);

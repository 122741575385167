import { bindActionCreators, compose } from "redux";
import { Form } from 'antd';
import { connect } from "react-redux";

import { updateCampaign } from "../../../../campaign/campaignActions";

import CampaignSettingsAdvancedPageForm from './CampaignSettingsAdvancedPageForm';

const mapStateToProps = state => ({
  campaign: state.campaign.campaign,
  campaignSettings: state.campaign.campaign.settings,
  isUpdateCampaignInProgress: state.campaign.isUpdateCampaignInProgress
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateCampaign
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(CampaignSettingsAdvancedPageForm);

import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { pickBy, get, isEmpty, isEqual } from 'lodash';
import i18next from 'i18next';
import { translate } from 'react-i18next';

import SettingsColumn from '../../../components/SettingsColumn';
import LanguageBoxForm from '../../../components/LanguageBoxFormContainer';
import TeamCustomUrlField from '../../components/fields/TeamCustomUrlField';
import TeamCaptainField from '../../components/fields/TeamCaptainField';
import Wysiwyg, { wrapText } from 'Parts/ui/components/fields/Wysiwyg';
import CancelAndSaveButtons from '../../../components/CancelAndSaveButtons';
import FakeField from 'Parts/ui/components/fields/FakeField';
import AvatarThumbnail from '../../../../common/AvatarThumbnailContainer';
import NameField from 'Parts/ui/components/fields/NameField';

import { getLanguageNameByKey, getDefaultLanguageKey } from 'Parts/common/langUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';
import { facebookScrape } from 'Parts/common/requestUtils';
import { getPlatformUrl } from 'Parts/common/environment';

import CampaignsTeamsPageFormEN from './locales/CampaignsTeamsPageForm_en.json';
import CampaignsTeamsPageFormFR from './locales/CampaignsTeamsPageForm_fr.json';

import './CampaignsTeamsPageForm.less';

class CampaignsTeamsPageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang:
        getDefaultLanguageKey(get(this.props, 'team.description')) ||
        getDefaultLanguageKey(get(this.props, 'languages')) ||
        this.props.locale,
      disabledUnilangualFields: false
    };

    this.changeHasHappened = false;

    this.descriptionSize = this.getFontSizeFitted(this.getDescription(props.locale));

    this.WysiwygRef = React.createRef();

    i18next.addResourceBundle('en', 'CampaignsTeamsPageForm', CampaignsTeamsPageFormEN);
    i18next.addResourceBundle('fr', 'CampaignsTeamsPageForm', CampaignsTeamsPageFormFR);
  }

  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.props.form.validateFields({ force: true });
    }

    if (
      isEmpty(get(prevProps, 'team.description')) &&
      !isEqual(get(prevProps, 'team.description'), get(this.props, 'team.description'))
    ) {
      this.handleContentLanguageChange(
        getDefaultLanguageKey(get(this.props, 'team.description')) ||
          getDefaultLanguageKey(get(this.props, 'languages')) ||
          this.props.locale
      );
      this.props.form.resetFields();
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    this.changeHasHappened = false;

    const { validateFields, isFieldTouched } = this.props.form;

    // WE NEED TO LOOK IF participants_limit and teams_limit ARE EMPTY AND CHANGE IT TO 0 (UNLIMITED)
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (Object.keys(updatedValues) && Object.keys(updatedValues).length > 0) {
          const values = {
            ...updatedValues
          };

          const {
            match,
            history,
            locale,
            team,
            platform,
            campaign,
            userId,
            platformAlias,
            participants,
            checkAuthorization
          } = this.props;
          const { leader } = team;

          this.props.updateTeam(values, team.id, this.state.contentLang).then(() => {
            if (
              !checkAuthorization({ platfrom: platform.id, campaign: campaign.id }, [
                ['roles.platform.SUPERADMIN'],
                ['roles.platform.OWNER'],
                ['roles.campaign.OWNER']
              ]) &&
              updatedValues.team_captain &&
              updatedValues.team_captain !== leader.id &&
              leader.memberId === userId
            ) {
              const participantUrl =
                match.url.indexOf('/d/contributions/teams/') > -1
                  ? `/${locale}/d/contributions/participations/${leader.id}`
                  : `/${locale}/d/campaigns/${match.params.campaignID}/participants/${leader.id}`;

              history.replace(participantUrl);
            }
          });

          if (updatedValues.team_url) {
            const platformUrl = getPlatformUrl(platformAlias),
              campaignUrl = campaign.alias,
              teamUrl = updatedValues.team_url || team.alias;

            const urlToScrape = [
              `${platformUrl}/en/${campaignUrl}/${teamUrl}`,
              `${platformUrl}/fr/${campaignUrl}/${teamUrl}`
            ];

            participants.forEach(participant => {
              urlToScrape.push(
                `${platformUrl}/en/${campaignUrl}/${teamUrl}/${participant.member.alias}`,
                `${platformUrl}/fr/${campaignUrl}/${teamUrl}/${participant.member.alias}`
              );
            });

            if (urlToScrape.length) {
              facebookScrape(urlToScrape);
            }
          }
        }
      }
    });
  };

  checkForChange = () => {
    const description = this.getDescription(this.state.contentLang);
    if (
      !this.changeHasHappened &&
      this.props.form.getFieldValue('captain_message') != description
    ) {
      this.changeHasHappened = true;
    }
  };

  handleContentLanguageChange = value => {
    const defaultLangKey =
      getDefaultLanguageKey(get(this.props, 'team.description')) ||
      getDefaultLanguageKey(get(this.props, 'languages')) ||
      this.props.locale;

    this.setState(
      {
        contentLang: value,
        disabledUnilangualFields: defaultLangKey != value
      },
      () => (this.changeHasHappened = false)
    );
  };

  getDescription = lang => {
    const { team } = this.props;
    const lng = getLanguageNameByKey(lang, false);
    return get(team, `description[${lng}].description`, '');
  };

  handleCancel = () => {
    this.changeHasHappened = false;
    this.props.form.resetFields();
    this.WysiwygRef.current.getWrappedInstance('Wysiwyg').resetEditorState();
  };

  getFontSizeFitted = text => {
    // Remove TAG for count
    const regex = /(<([^>]+)>)/gi;
    const result = text.replace(regex, '');

    if (result.length < 25) {
      return 'smallQuote'; //36;
    } else if (result.length < 70) {
      return 'mediumQuote'; //24;
    } else {
      return 'largeQuote'; //18;
    }
  };

  handleWysiwygChange = value => {
    const descriptionSize = this.getFontSizeFitted(value);
    if (this.descriptionSize !== descriptionSize) {
      this.descriptionSize = descriptionSize;
      document.querySelector('.Wysiwyg').classList.remove('smallQuote');
      document.querySelector('.Wysiwyg').classList.remove('mediumQuote');
      document.querySelector('.Wysiwyg').classList.remove('largeQuote');
      document.querySelector('.Wysiwyg').classList.add(descriptionSize);
    }

    if (this.props.form.isFieldTouched('captain_message')) this.changeHasHappened = true;
  };

  render() {
    const {
        props,
        state,
        WysiwygRef,
        handleSubmit,
        handleCancel,
        getDescription,
        handleContentLanguageChange,
        handleWysiwygChange
      } = this,
      { t, team, participants, locale, form, platform, campaign, checkAuthorization, userRoleId } = props,
      { contentLang } = state,
      teamGoal =
        (team &&
          !isEmpty(team) &&
          moneyFormat(team.settings.goal ? team.settings.goal : 0, locale)) ||
        null,
      message = getDescription(contentLang),
      ownerOrHigher = checkAuthorization({ platform: platform.id }, [
        ['roles.platform.OWNER'],
        ['roles.platform.SUPERADMIN']
      ]);

    const TeamCaptainAvatar =
      (team && !isEmpty(team) && (
        <AvatarThumbnail
          className="CampaignsTeamsPageForm__Captain"
          entity={get(team, 'leader', {})}
          primaryContent={t('captain')}
          secondaryContent={get(team, 'leader.member.name', null)}
          editable={ownerOrHigher && get(team, 'leader.member[0].role') !== 'SUPERADMIN'}
          url={`/${locale}/d/members/${get(team, 'leader.member.id', '')}/information`}
          target="_blank"
        />
      )) ||
      null;

    const isCampaignInactive =
      campaign &&
      (campaign.statusId === 3 || //REJECTED
      campaign.statusId === 4 || //CLOSED
        campaign.statusId === 5); //INACTIVE

    return (
      <div className="AdminFormWrap">
        <Form className="CampaignsTeamsPageForm" onSubmit={handleSubmit}>
          {team && !isEmpty(team) &&
          <div className="inputs">
            <FakeField
              data={TeamCaptainAvatar}
              label={t('Actual-Captain')}
            />
            <TeamCaptainField
              form={form}
              data={team.leader}
              choices={participants}
              disabled={isCampaignInactive || this.state.disabledUnilangualFields}
            />
            <FakeField
              data={teamGoal}
              label={t('Goal')}
              className="team-goal"
            />
            <NameField
              form={form}
              label={true}
              initialValue={team.name}
              identity='team'
              fieldId='team_name'
              disabled={this.state.disabledUnilangualFields}
            />
            <TeamCustomUrlField
              form={form}
              data={team.alias}
              disabled={isCampaignInactive || this.state.disabledUnilangualFields}
            />
            <Wysiwyg
              className={this.descriptionSize}
              ref={WysiwygRef}
              fieldId="captain_message"
              label={t('captain-message')}
              tooltip={t('captain-message-tooltip')}
              form={form}
              initialValue={message}
              maxlength={200}
              counterWithTag={false}
              required={false}
              disabled={isCampaignInactive}
              allowHeadings={false}
              allowAlignments={false}
              allowLinks={false}
              allowLists={false}
              allowSizes={false}
              allowImages={false}
              allowVideos={false}
              allowHTML={userRoleId === 1}
              accentColors={this.props.accentColors}
              onChange={handleWysiwygChange}
              contentLang={contentLang}
            />
          </div>
          }

          {!isCampaignInactive &&
            <CancelAndSaveButtons onClickCancel={handleCancel}  />
          }
        </Form>
        <SettingsColumn>
          <LanguageBoxForm
            handleContentLanguageChange={handleContentLanguageChange}
            changeHasHappened={this.changeHasHappened}
            defaultLanguage={
              getDefaultLanguageKey(get(this.props, 'team.description')) ||
              getDefaultLanguageKey(get(this.props, 'languages')) ||
              this.props.locale
            }
            disabled={isCampaignInactive}
          />
        </SettingsColumn>
      </div>
    );
  }
}

CampaignsTeamsPageForm.defaultProps = {
  participantsLimit: 0,
  teamsLimit: 0
};

CampaignsTeamsPageForm.propTypes = {
  form: PropTypes.object.isRequired
};

export default translate('CampaignsTeamsPageForm')(CampaignsTeamsPageForm);

import {
  connect
} from "react-redux";

import HistoryTable from 'Parts/common/HistoryTable';

const mapStateToProps = state => ({
  logs: state.participant.logs
});

export default connect(mapStateToProps)(HistoryTable);

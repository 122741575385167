import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import dataFetcher from "../../parts/chore/dataFetcher";

import TeamsPage from '../campaigns/teams/CampaignsTeamsPage';

const mapStateToProps = state => ({
  campaign: state.campaign.campaign,
  team: state.team.team,
  locale: state.session.language,
  participants: state.participant.participants.results,
  pagination: state.participant.participants.pagination
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  dataFetcher
)(TeamsPage);

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getTeamById } from '../../team/teamSelectors';

import CreateConfirmationPanel from './CreateConfirmationPanel';

const mapStateToProps = state => ({
  platformAlias: state.platform.platform.alias,
  selectedCampaign: state.participant.selectedCampaign,
  selectedTeam: getTeamById(state, state.participant && state.participant.createdParticipant && state.participant.createdParticipant.teamId) || null,
  currentUser: state.session.user,
  createdParticipant: state.participant.createdParticipant,
  locale: state.session.language,
  isUserLogged: state.session.userTypeId === 2
});

export default compose(
  connect(mapStateToProps),
  withRouter,
)(CreateConfirmationPanel);

import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import noop from 'lodash/noop';
import classnames from 'classnames';
import i18next from 'i18next';
import { translate } from 'react-i18next';

import resultTableEN from './locales/ResultTable_en.json';
import resultTableFR from './locales/ResultTable_fr.json';

import './ResultTable.less';

class ResultTable extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'ResultTable', resultTableEN);
    i18next.addResourceBundle('fr', 'ResultTable', resultTableFR);
  }

  render() {
    const { t } = this.props;
    return (
      <Table
        className={classnames('ResultTable', {
          'ResultTable--ClickableRow': this.props.onRowClick !== noop
        })}
        dataSource={this.props.dataSource}
        rowKey="id"
        showHeader={this.props.showHeader}
        pagination={this.props.pagination}
        scroll={this.props.scroll || { x: true }}
        columns={this.props.columns}
        onRow={item => ({
          onClick: () => this.props.onRowClick(item)
        })}
        onChange={this.props.onChange}
        locale={{ emptyText: t('No results') }}
        loading={this.props.loading}
        expandedRowRender={this.props.expandedRowRender}
      />
    );
  }
}

ResultTable.defaultProps = {
  onRowClick: noop,
  onChange: noop,
  pagination: false,
  showHeader: false,
  scroll: {},
  loading: false,
  expandedRowRender: null
};

ResultTable.propTypes = {
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }),
    PropTypes.bool
  ]),
  onRowClick: PropTypes.func,
  onChange: PropTypes.func,
  showHeader: PropTypes.bool,
  loading: PropTypes.bool
};

export default translate('ResultTable')(ResultTable);

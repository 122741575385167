import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import FundkyModal from 'Parts/ui/components/FundkyModal';

import { getLanguagesKeys } from 'Parts/common/langUtils';

import adminContentLangSwitcherEN from './locales/AdminContentLangSwitcher_en.json';
import adminContentLangSwitcherFR from './locales/AdminContentLangSwitcher_fr.json';

import './AdminContentLangSwitcher.less';

class AdminContentLangSwitcher extends React.Component {
  constructor(props) {
    super(props);

    const { defaultLanguage } = this.props;
    this.state = {
      selected_lang: defaultLanguage,
      current_lang: defaultLanguage
    };

    i18next.addResourceBundle('en', 'AdminContentLangSwitcher', adminContentLangSwitcherEN);
    i18next.addResourceBundle('fr', 'AdminContentLangSwitcher', adminContentLangSwitcherFR);

    this.switchLangModalRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.forceLanguage !== this.props.forceLanguage &&
      this.state.selected_lang !== this.props.forceLanguage
    ) {
      this.setState({ selected_lang: this.props.forceLanguage }, () =>
        this.props.form.setFieldsValue({ LangSwitcher: this.props.forceLanguage })
      );
    }
  }

  openSwitchLangModal = value => {
    if (this.props.changeHasHappened) {
      this.switchLangModalRef.current.getWrappedInstance('FundkyModal').showModal();
      this.setState({
        selected_lang: value
      });
    } else {
      this.setState({
        current_lang: value,
        selected_lang: value
      });
      this.props.handleChange(value);
    }
  };

  acceptSwitchLangModal = () => {
    this.props.handleChange(this.state.selected_lang);
    this.setState({
      current_lang: this.state.selected_lang
    });
    this.switchLangModalRef.current.getWrappedInstance('FundkyModal').hideModal();
  };

  closeSwitchLangModal = e => {
    const { defaultLanguage } = this.props;
    this.props.form.setFieldsValue({
      LangSwitcher: this.state.current_lang
    });
  };

  render() {
    const { t, defaultLanguage, disabled, form } = this.props;
    const Option = Select.Option;

    const options = getLanguagesKeys()
      .sort((a, b) => t('LANGUAGES:' + a).localeCompare(t('LANGUAGES:' + b)))
      .map(key => (
        <Option key={key} value={key}>
          {t('LANGUAGES:' + key)}
          {key === defaultLanguage && t('LANGUAGES:default')}
        </Option>
      ));

    const switchLangModal = (
      <FundkyModal
        ref={this.switchLangModalRef}
        displayModal={false}
        title={t('SwitchLanguageConfirm_title')}
        acceptAction={this.acceptSwitchLangModal}
        cancelAction={this.closeSwitchLangModal}
      >
        <p>{t('SwitchLanguageConfirm_desc')}</p>
      </FundkyModal>
    );

    return (
      <div className="AdminContentLangSwitcher__wrap">
        {switchLangModal}
        <label>{t('Language')}</label>
        {form.getFieldDecorator('LangSwitcher', { initialValue: defaultLanguage })(
          <Select
            suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
            onChange={this.openSwitchLangModal}
            disabled={disabled}
            style={{ width: '200px' }}
            dropdownClassName={`AdminContentLangSwitcher__dropdown ${this.props.dropdownClassName}`}
          >
            {options}
          </Select>
        )}
      </div>
    );
  }
}

AdminContentLangSwitcher.defaultProps = {
  defaultLanguage: '',
  disabled: false,
  dropdownClassName: ''
};

AdminContentLangSwitcher.propTypes = {
  defaultLanguage: PropTypes.string,
  disabled: PropTypes.bool
};

export default translate('AdminContentLangSwitcher')(AdminContentLangSwitcher);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import dataFetcher from 'Parts/chore/dataFetcher';
import { fetchTeams } from '../../team/teamActions';
import { get } from 'lodash';
import { fetchCampaign } from '../../campaign/campaignActions';
import MyContributionsParticipationsPage from './MyContributionsParticipationsPage';

const mapStateToProps = state => ({
  campaign: state.campaign.campaign,
  teams: state.search.teams,
  participant: state.participant.participant,
  participantName: get(state.participant.participant, 'member.name', ''),
  locale: state.session.language,
  userId: state.session.userId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchTeams,
  fetchCampaign
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(MyContributionsParticipationsPage);

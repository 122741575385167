/*
 * This file contains Antd columns definition factories.
 *
 * The declarative syntax in JSX doesn't allow extension and/or definition reuse from a table to another
 * because Antd Table component only read the props passed to the Column component,
 * without rendering it (even without instancied it).
 * See https://github.com/ant-design/ant-design/blob/7fa05996957ef0eded21d810d71364d294ea947d/components/table/util.tsx#L49-L67
 * See also https://github.com/ant-design/ant-design/issues/7139
 */
import React from 'react';
import { Popconfirm, Button } from 'antd';
import classnames from "classnames";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguageDateFormatByKey } from './langUtils';

function classFromProperty(property) {
  if (property !== null)
  return property.replace(/\./g, '-');
}

export function simpleColumn(title, property, render, emphasis = false) {
  const className = classnames('Column', { 'Column--Emphasis': emphasis }, { [`${classFromProperty(property)}`]: property });

  return {
    key: title.toLowerCase().replace(/ /g,''),
    dataIndex: property,
    title,
    className,
    render
  }
}

export function dateColumn(title, property, render, format = null, emphasis = false, language, _default = null) {
  const
    className = classnames('Column', { 'Column--Emphasis': emphasis }, { [`${classFromProperty(property)}`]: property }),
    _format = format || language && getLanguageDateFormatByKey(language) || 'LL';
  let innerRender = null;

  if (render) {
    innerRender = text => text
      ? render(moment(text).format(_format))
      : _default || render(moment(text).format(_format));
  } else {
    innerRender = text => text
      ? moment(text).format(_format)
      : _default || moment(text).format(_format);
  }

  return {
    key: title.toLowerCase().replace(/ /g,''),
    dataIndex: property,
    title,
    className,
    render: innerRender
  }
}

export function validationColumn(title, property, render, format = null, emphasis = false, language, isLoading = () => false, disabled = () => null) {
  const className = classnames('Column', { 'Column--Emphasis': emphasis }, { [`${classFromProperty(property)}`]: property });
  const _format = format || language && getLanguageDateFormatByKey(language) || 'LL';
  const innerRender = entity => {
    if (entity[property]) {
      return moment(entity[property]).format(_format);
    } else {
      return render(entity);
    }
  };

  return {
    key: title.toLowerCase().replace(/ /g,''),
    title,
    className,
    render: innerRender
  }
}

export function actionColumn(title, type, onClick, render, isLoading = () => false, disabled = () => null) {
  let onCell = null;
  let key = type;

  if(typeof(type) !== 'string') {
    key = title.toLowerCase();
  }

  if (onClick) {
    onCell = item => ({
      onClick: event => {
        // We usually don't have to trigger the row click handler when a cell click handler is defined
        event.stopPropagation();
        onClick(item);
      }
    })
  }

  function innerRender(text, record) {
    let button = null;

    if(typeof(type) === 'string') {
      button = <Button icon={type} shape="circle" loading={isLoading(record)} disabled={disabled(record)} />;
    } else {
      button = <Button shape="circle" loading={isLoading(record)} disabled={disabled(record)}><FontAwesomeIcon icon={type} size="lg" /></Button>;
    }

    const innerMarkup = render ? render(text, record) : button;

    return innerMarkup;
  }

  return {
    key: key,
    title,
    className: 'Column Column--ButtonIcon',
    render: innerRender,
    width: 50,
    onCell,
  }
}

/***
 *title => title of the column
 *actions => array of actions [{action}]
 *action.function => onClick function
 *action.icon => array ['fal', 'icon']
 *isLoading => function(record) => return bool
 */
export function actionsColumn(title, actions, isLoading = () => false) {
  const innerRender = (record) => {
    const buttons =  actions.map((action, index) => {
      return (
        <Button key={index} shape="circle" onClick={() => action.function(record)} loading={isLoading(record)}>
          <FontAwesomeIcon icon={action.icon} size="lg" />
        </Button>
      );
    });

    return (
      <div className="buttons">
        {buttons}
      </div>
    );
  }

  const width = actions.length > 2 ? 120 : 60 * actions.length;

  return {
    key: title.toLowerCase(),
    title,
    className: 'Column Column--ButtonsIcon',
    render: innerRender,
    width: width
  }
}

export function actionConfirmColumn(title, type, confirmTitle, onConfirm, render, isLoading = () => false) {
  let key = type;

  if(typeof(type) !== 'string') {
    key = title.toLowerCase();
  }

  function innerRender(text, record) {
    let button = null;

    if(typeof(type) === 'string') {
      button = <Button icon={type} shape="circle" loading={isLoading(record)} />;
    } else {
      button = <Button shape="circle" loading={isLoading(record)}><FontAwesomeIcon icon={type} size="lg" /></Button>;
    }

    const innerMarkup = render ? render(text, record) : button;

    return (
      <Popconfirm title={confirmTitle} onConfirm={e => onConfirm(e, record)}>
        {innerMarkup}
      </Popconfirm>
    )
  }

  return {
    key: key,
    title,
    className: 'Column Column--ButtonIcon',
    render: innerRender,
    width: 50,
    onCell: () => ({
      onClick: event => {
        event.stopPropagation();
      }
    })
  }
}

import {
  bindActionCreators,
  compose
} from "redux";
import {
  connect
} from "react-redux";
import {
  withRouter
} from 'react-router'
import {
  Form
} from 'antd';
import {
  get
} from 'lodash';

import dataFetcher from 'Parts/chore/dataFetcher';
import {
  updatePlatformContent,
  deletePlatformContent
} from '../../../platform/platformActions';

import PagesGuidelinesForm from './PagesGuidelinesForm';

const mapStateToProps = state => ({
  locale: state.session.language,
  platform: state.platform.platform,
  languages: state.platform.languages,
  contentGuidelines: get(state, 'platform.content.campaign.thirdPartyGuidelines'),
  accentColors: state.platform.platform.settings.color,
  userRoleId: get(state, 'session.user.roleId')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePlatformContent,
  deletePlatformContent
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher,
  Form.create(),
)(PagesGuidelinesForm);

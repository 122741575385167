import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import adminSidebarPagesEN from './locales/AdminSidebarPages_en.json';
import adminSidebarPagesFR from './locales/AdminSidebarPages_fr.json';

class AdminSidebarPages extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AdminSidebarPages', adminSidebarPagesEN);
    i18next.addResourceBundle('fr', 'AdminSidebarPages', adminSidebarPagesFR);
  }

  render() {

    const { t } = this.props;

    // We need a combination of the react-router NavLink and the Antd Button... let's use NavLink and put the Antd classes on it.
    return (
      <div className="PagesSider">
        <NavLink
          to={`/${this.props.locale}/d`}
          className="AdminSider__GroupItem AdminSider__GroupTitle ant-btn ant-btn-lg"
        >
        <FontAwesomeIcon icon={["fal", "long-arrow-left"]} fixedWidth /> <span>{t('Pages')}</span>
        </NavLink>

        <NavLink
          to={`/${this.props.locale}/d/pages/homepage/main-slider`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
          exact={false}
        >
          <FontAwesomeIcon icon={["fal", "desktop"]} fixedWidth /> {t('Homepage')}
        </NavLink>
        {Number(this.props.campaignEnabled) ?
          (
            <NavLink
              to={`/${this.props.locale}/d/pages/guidelines`}
              className="AdminSider__GroupItem ant-btn ant-btn-lg"
              activeClassName="AdminSider__GroupItem--active"
            >
              <FontAwesomeIcon icon={["fal", "book"]} fixedWidth /> {t('Guidelines')}
            </NavLink>
          ) : null
        }
        <NavLink
          to={`/${this.props.locale}/d/pages/refund-policy`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
          <FontAwesomeIcon icon={["fal", "money-check-edit-alt"]} fixedWidth /> {t('Refund policy')}
        </NavLink>
        <NavLink
          to={`/${this.props.locale}/d/pages/donation-confirmation-email`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
          <FontAwesomeIcon icon={["fal", "envelope"]} fixedWidth /> {t('Donation confirmation email')}
        </NavLink>
      </div>
    );
  }
}

export default translate('AdminSidebarPages')(AdminSidebarPages);

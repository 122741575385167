import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FundkyTooltip from '../FundkyTooltip';

import './SelectField.less';

const Option = Select.Option;

class SelectField extends React.Component {

  render() {
    const
      { props } = this,
      {
        t, form, fieldId, initialValue, optionsArray, className, label, hasFeedback,
        tooltip, notice, required, requiredMessage, overModal, overHeader, colon,
        ...rest
      } = props,
      { getFieldDecorator } = form;

    let labelProperties = tooltip ? {
      label:(<span>{label+":"}<FundkyTooltip title={tooltip} /></span>),
      colon:false,
    } : {
      label:label,
    };

    const options = optionsArray.map(option => {
      return (<Option key={option.value} value={option.value} disabled={option.disabled || null}>{option.text}</Option>);
    });

    return (
      <React.Fragment>
        <Form.Item
          className={classnames( 'SelectField', className )}
          hasFeedback={hasFeedback}
          {...labelProperties}
          colon={colon}
        >
          {getFieldDecorator(
            fieldId,
            {
              initialValue:
                initialValue ||
                initialValue === 0 ?
                  initialValue
                  : undefined,
              rules: [
                {
                  required: required,
                  message: requiredMessage
                }
              ],
              onChange: this.props.onChange
            }
          )(
            <Select
              dropdownClassName={classnames("SelectField__Dropdown", { overModal: overModal }, { overHeader: overHeader })}
              suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
              {...rest}
              hasFeedback={required || null}
            >
              {options}
            </Select>
          )}

        </Form.Item>
        {notice &&
          <span className="notice ant-form-item">
            <div className="ant-form-item-label"></div>
            <div className="ant-form-item-control-wrapper">
              {notice}
            </div>
          </span>
        }
      </React.Fragment>
    );
  }

}

SelectField.defaultProps = {
  initialValue: '',
  label: null,
  hasFeedback: false,
  tooltip: null,
  optionsArray: [],
  notice: null,
  required: false,
  overModal: false,
  overHeader: false,
  onChange: () => {},
  colon: true
};

SelectField.propTypes = {
  form: PropTypes.object.isRequired,
  fieldId: PropTypes.string.isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  optionsArray: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  required: PropTypes.bool,
  requiredMessage: (props, propName) => {
    if (
      props['required'] === true &&
      (
        props[propName] === undefined ||
        typeof(props[propName]) !== 'string'
      )
    ) {
      return new Error('Please provide a requiredMessage string.');
    }
  },
  overModal: PropTypes.bool,
  overHeader: PropTypes.bool,
  onChange: PropTypes.func
};

export default SelectField;

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { connectSearchBox } from 'react-instantsearch-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlgoliaCustomSearchBox_en from './locales/AlgoliaCustomSearchBox_en.json';
import AlgoliaCustomSearchBox_fr from './locales/AlgoliaCustomSearchBox_fr.json';

i18next.addResourceBundle('en', 'AlgoliaCustomSearchBox', AlgoliaCustomSearchBox_en);
i18next.addResourceBundle('fr', 'AlgoliaCustomSearchBox', AlgoliaCustomSearchBox_fr);

const SearchBox = ({ currentRefinement, isSearchStalled, refine, handleFilterBarIconClick, filterBarLogoClass, t }) => {

  return (
      <form noValidate action="" role="search">
        <input
          type="search"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
          placeholder={t('Search_Here')}
        />
        <a className="btnAlg search">
          <FontAwesomeIcon icon={["fa", "search"]} className="" />
        </a>
        <a className={`btnAlg filter ${filterBarLogoClass}`} onClick={handleFilterBarIconClick}>
          <FontAwesomeIcon icon={["fa", "filter"]} className="" />
        </a>
        {/*<button onClick={() => refine('')}>Reset query</button>*/}
        {/*{isSearchStalled ? 'My search is stalled' : ''}*/}
      </form>
    )
}

export default translate('AlgoliaCustomSearchBox')(connectSearchBox(SearchBox));
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import classnames from 'classnames';
import { Form, DatePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import {
  isDateBeforeCurrentDate,
  isDateAfterCurrentDate,
  getDateValue,
} from '../../../common/dateUtils';
import FundkyTooltip from '../FundkyTooltip';

import './DatePickerField.less';

class DatePickerField extends React.Component {
  render() {
    const {
      t,
      locale,
      form,
      fieldId,
      className,
      initialValue,
      fieldOptions,
      disabled,
      showTime,
      dateFormat,
      label,
      placeholder,
      colon,
      hasFeedback,
      required,
      requiredMessage,
      notice,
      tooltip,
      past,
      future,
      present,
      allowClear
    } = this.props;
    const { getFieldDecorator } = form;

    const input = (
      <DatePicker
        disabled={disabled}
        showTime={showTime}
        format={dateFormat}
        placeholder={placeholder}
        allowClear={allowClear}
        suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
      />
    );

    const requiredValidator = required
      ? [
          {
            type: 'object',
            required: required,
            message: requiredMessage || t('FIELDS-VALIDATION:required-message')
          }
        ]
      : [];

    const pastValidator = past
      ? [
          {
            validator: (rule, value, callback) => {
              return value === null ||
                past === true && isDateBeforeCurrentDate(getDateValue(value), true) ||
                typeof past === 'object' && past.diff(value) >= 0 ||
                typeof past === 'string' && moment(past).diff(value) >= 0
                  ? callback()
                  : callback(true);
            },
            message: t('FIELDS-VALIDATION:date.past', {
              time: past === true
                ? t('FIELDS-VALIDATION:date.now')
                : typeof past === 'object'
                  ? getDateValue(past)
                  : getDateValue(moment(past))
            })
          }
        ]
      : [];

    const futureValidator = future
      ? [
          {
            validator: (rule, value, callback) => {
              return value === null ||
                future === true && isDateAfterCurrentDate(getDateValue(value), true) ||
                typeof future === 'object' && future.diff(value) <= 0 ||
                typeof future === 'string' && monent(future).diff(value) <= 0
                  ? callback()
                  : callback(true);
            },
            message: t('FIELDS-VALIDATION:date.future', {
              time: future === true
                ? t('FIELDS-VALIDATION:date.now')
                : typeof future === 'object'
                  ? getDateValue(future)
                  : getDateValue(moment(future))
            })
          }
        ]
      : [];

    const rules = {
      rules: [...requiredValidator, ...pastValidator, ...futureValidator]
    };

    const options = {
      initialValue: initialValue,
      ...rules,
      ...fieldOptions
    };

    return (
      <>
        <Form.Item
          className={classnames('DatePickerField', className)}
          hasFeedback={hasFeedback}
          label={
            label && (
              <span>
                {label}
                {tooltip && ' :'}
                {tooltip && <FundkyTooltip title={tooltip} />}
              </span>
            )
          }
          colon={tooltip ? false : true}
        >
          {getFieldDecorator(fieldId, options)(input)}
        </Form.Item>
        {(notice && !label) && (
          <p className="notice">{notice}</p>
        ) ||
        (notice && label) && (
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label transactions-suggestions-label-wrap">
            </div>
            <div className="ant-col ant-form-item-control-wrapper transactions-suggestions-wrap">
              <p className="notice">{notice}</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

DatePickerField.defaultProps = {
  fieldId: 'datePicker',
  fieldOptions: {},
  disabled: false,
  initialValue: null,
  hasFeedback: false,
  required: false,
  requiredMessage: null,
  dateFormat: 'YYYY-MM-DD HH:mm:ss',
  label: false,
  colon: false,
  showTime: false,
  notice: null,
  allowClear: false,
  tooltip: null,
  past: false,
  future: false,
  present: false
};

DatePickerField.propTypes = {
  form: PropTypes.object.isRequired,
  fieldId: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fieldOptions: PropTypes.object,
  className: PropTypes.string,
  hasFeedback: PropTypes.bool,
  disabled: PropTypes.bool,
  colon: PropTypes.bool,
  showTime: PropTypes.bool
};

export default translate('DatePickerField')(DatePickerField);

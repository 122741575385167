import {isEmpty} from 'lodash';

const supportedLanguages = [
  {id: 1, key: 'en', name: 'English', locale: 'en_US', dateFormat: 'MMM D, YYYY', timeFormat: 'HH:mm:ss', moneyFormat: ['$',',','.',null]},
  {id: 2, key: 'fr', name: 'French',  locale: 'fr_CA', dateFormat: 'D MMM YYYY',  timeFormat: 'HH:mm:ss', moneyFormat: [null,' ',',',' $']}
];

export function isLanguageKeySupported(key) {
  const find = supportedLanguages.filter(lang => lang.key === key);
  return find.length > 0;
}

export function getLanguages() {
  return supportedLanguages;
}

export function getLanguagesKeys() {
  return supportedLanguages.map(lang => {
    return lang.key;
  })
}

export function getLanguagesNames(capitalize = true, uppercase = false) {
  return supportedLanguages.map(lang => {
    return capitalize && uppercase ?
      lang.name.toUpperCase()
      : capitalize ?
        lang.name
        : lang.name.toLowerCase();
  });
}

export function getLanguageByKey(key) {
  let language = null;
  for(let k in supportedLanguages) {
    if(key === supportedLanguages[k].key) {
      language = supportedLanguages[k].key;
      break;
    }
  }
  return language;
}

export function getLanguageKeyById(id, capitalize = false) {
  let foundId = false;

  for (let i = 0; i < supportedLanguages.length; i++) {
    if (foundId)
      break;

    if (supportedLanguages[i].id === id) {
      foundId = true;

      return capitalize ?
        supportedLanguages[i].key.toUpperCase()
        : supportedLanguages[i].key;
    }
  }
}

export function getLanguageKeyByName(name, capitalize = false) {
  let foundId = false;
  for (let i = 0; i < supportedLanguages.length; i++) {
    if (foundId)
      break;

    if (supportedLanguages[i].name.toLowerCase() === name.toLowerCase()) {
      foundId = true;

      return capitalize
        ? supportedLanguages[i].key.toUpperCase()
        : supportedLanguages[i].key;
    }
  }
  if (!foundId) {
    return null;
  }
}

export function getLanguageByName(name) {
  let foundId = false;
  for (let i = 0; i < supportedLanguages.length; i++) {
    if (foundId)
      break;

    if (supportedLanguages[i].name.toLowerCase() === name.toLowerCase()) {
      foundId = true;

      return supportedLanguages[i];
    }
  }
  if (!foundId) {
    return null;
  }
}

export function getLanguageNameByKey(key, capitalize = true, uppercase = false) {
  let foundId = false;

  for (let i = 0; i < supportedLanguages.length; i++) {
    if (foundId)
      break;

    if (supportedLanguages[i].key === key) {
      foundId = true;

      return capitalize && uppercase ?
        supportedLanguages[i].name.toUpperCase()
        : capitalize ?
          supportedLanguages[i].name
          : supportedLanguages[i].name.toLowerCase();
    }
  }
}

export function getLanguageNameById(id, capitalize = true, uppercase = false) {
  let foundId = false;

  for (let i = 0; i < supportedLanguages.length; i++) {
    if (foundId)
      break;

    if (supportedLanguages[i].id === id) {
      foundId = true;

      return capitalize && uppercase ?
        supportedLanguages[i].name.toUpperCase()
        : capitalize ?
          supportedLanguages[i].name
          : supportedLanguages[i].name.toLowerCase();
    }
  }
}

export function getLanguageIdByKey(key) {
  let foundId = false;

  for (let i = 0; i < supportedLanguages.length; i++) {
    if (foundId)
      break;

    if (supportedLanguages[i].key === key) {
      foundId = true;
      return supportedLanguages[i].id;
    }
  }
}

export function getLanguageIdByName(name) {
  let foundId = false;
  for (let i = 0; i < supportedLanguages.length; i++) {
    if (foundId)
      break;

    if (supportedLanguages[i].name.toLowerCase() === name.toLowerCase()) {
      foundId = true;

      return supportedLanguages[i].id;
    }
  }
}

export function getLocaleByKey(key) {
  let foundLocale = false;

  for (let i = 0; i < supportedLanguages.length; i++) {
    if (foundLocale)
      break;

    if (supportedLanguages[i].key === key) {
      foundLocale = true;
      return supportedLanguages[i].locale;
    }
  }
}

export function getLanguageDateFormatByKey(key, time = false) {
  let foundDateFormat = false;

  for (let i = 0; i < supportedLanguages.length; i++) {
    if (foundDateFormat)
      break;

    if (supportedLanguages[i].key === key) {
      foundDateFormat = true;
      var returnedValue = supportedLanguages[i].dateFormat || 'LL'

      if (time) {
        returnedValue += ' ' + supportedLanguages[i].timeFormat;
      }

      return returnedValue;
    }
  }

  return 'LL';
}

export function getOtherLanguagesId(id) {
  return supportedLanguages.filter(lang => lang.id !== id).map(lang => {
    return lang.id
  });
}

export function getOtherLanguagesKey(key) {
  return supportedLanguages.filter(lang => lang.key !== key).map(lang => {
    return lang.key
  });
}

export function getLanguageMoneyFormatByKey(key) {
  if(isLanguageKeySupported(key)) {
    return supportedLanguages.filter(lang => lang.key === key).map(lang => {
      return lang.moneyFormat;
    })[0];
  } else {
    return supportedLanguages[0].moneyFormat;
  }
}

export function getDefaultLanguageId(languages) {
  if(!isEmpty(languages)) {
    for(let language in languages) {
      if(languages[language].default)
        return getLanguageIdByName(language);
    }
  }

  return null;
}

export function getDefaultLanguageKey(languages) {
  if (!isEmpty(languages)) {
    for (let language in languages) {
      if (languages[language].default)
        return getLanguageKeyByName(language);
    }
  }

  return null;
}

export function getPlatformLanguages(languages) {
  if (!isEmpty(languages)) {
    const langs = [];
    for (let language in languages) {
      langs.push(getLanguageByName(language));
    }

    return langs;
  }

  return [];
}

export function isLanguageSupportedByPlatform(languageId, languages) {
  if(!isEmpty(languages)) {
    const languageName = getLanguageNameById(languageId, false);
    return languages[languageName] ? true : false
  }

  return null;
}

import commonUiReducer, { initialState as commonInitialState } from 'Parts/ui/uiReducer';

import {
  CAMPAIGN_CREATE_WIZARD_INFORMATION,
  CAMPAIGN_CREATE_WIZARD_PERSONALIZATION,
  CAMPAIGN_CREATE_WIZARD_CONFIRMATION,
  TEAM_CREATE_WIZARD_CAMPAIGN,
  TEAM_CREATE_WIZARD_INFORMATION,
  TEAM_CREATE_WIZARD_CONFIRMATION,
  PARTICIPANT_CREATE_WIZARD_CAMPAIGN,
  PARTICIPANT_CREATE_WIZARD_INFORMATION,
  PARTICIPANT_CREATE_WIZARD_CONFIRMATION,
  DONATION_CREATE_WIZARD_DONATION,
  DONATION_CREATE_WIZARD_INFORMATION,
  DONATION_CREATE_WIZARD_PAYMENT,
  DONATION_CREATE_WIZARD_CONFIRMATION,
  PARTICIPANT_CREATE_SURVEY_ATTEMPT
} from './uiUtils';

import {
  UI_CAMPAIGN_WIZARD_STEP_CHANGE,
  UI_CAMPAIGN_DETAIL_CLEAR,
  UI_TEAM_WIZARD_STEP_CHANGE,
  UI_PARTICIPANT_WIZARD_STEP_CHANGE,
  UI_DONATION_WIZARD_STEP_CHANGE,
  UI_START_EDITING_FORM,
  UI_STOP_EDITING_FORM
} from './uiActions';

import {
  UPDATE_PLATFORM_START,
  UPDATE_PLATFORM_SUCCESS,
  UPDATE_PLATFORM_ERROR
} from '../platform/platformActions';

import {
  SAVE_CAMPAIGN_CREATE_SUCCESS,
  SAVE_CAMPAIGN_CREATE_ERROR,
  SAVE_CAMPAIGN_CREATE_START,
  UI_STORE_CAMPAIGN_INFORMATION
} from '../campaign/campaignActions';

import {
  UI_TEAM_CAMPAIGN_SELECT,
  UI_TEAM_CAMPAIGN_CLEAR,
  SAVE_TEAM_CREATE_START,
  SAVE_TEAM_CREATE_SUCCESS,
  SAVE_TEAM_CREATE_ERROR
} from '../team/teamActions';

import {
  UI_PARTICIPANT_CAMPAIGN_SELECT,
  UI_PARTICIPANT_CAMPAIGN_CLEAR,
  SAVE_PARTICIPANT_CREATE_START,
  SAVE_PARTICIPANT_CREATE_SUCCESS,
  SAVE_PARTICIPANT_CREATE_ERROR
} from '../participant/participantActions';

import { UI_PARTICIPANT_INFORMATION_SELECT } from "../participant/participantActions";

import {
  SAVE_DONATION_CREATE_START,
  FETCH_CREDIT_CARD_ID_START,
  FETCH_CREDIT_CARD_ID_ERROR,
  SAVE_DONATION_CHECKOUT_ERROR,
  SAVE_DONATION_CHECKOUT_START,
  SAVE_DONATION_CHECKOUT_SUCCESS,
  SAVE_DONATION_CHECKOUT_DECLINED,
  SAVE_DONATION_CREATE_OFFLINE_START,
  SAVE_DONATION_CREATE_OFFLINE_SUCCESS,
  SAVE_DONATION_CREATE_OFFLINE_ERROR,
  UI_STORE_DONATION_INFORMATION,
  UI_STORE_USER_INFORMATION,
  UI_DONATION_CLEAR,
  CANCEL_DONATION
} from '../donation/donationActions';

const initialState = {
  ...commonInitialState,
  campaignCreateWizardState: CAMPAIGN_CREATE_WIZARD_INFORMATION,
  isCreateCampaignInProgress: false,
  teamCreateWizardState: TEAM_CREATE_WIZARD_CAMPAIGN,
  isCreateTeamInProgress: false,
  participantCreateWizardState: PARTICIPANT_CREATE_WIZARD_CAMPAIGN,
  isCreateParticipantInProgress: false,
  donationCreateWizardState: DONATION_CREATE_WIZARD_DONATION,
  isTransactionInProgress: null,
  isDonationInProgress: false,
  isUpdatePlatformInProgress: false
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_CAMPAIGN_CREATE_START:
      return {
        ...state,
        isCreateCampaignInProgress: true
      };

    case SAVE_CAMPAIGN_CREATE_ERROR:
      return {
        ...state,
        isCreateCampaignInProgress: false
      };

    case SAVE_CAMPAIGN_CREATE_SUCCESS:
      return {
        ...state,
        isCreateCampaignInProgress: false,
        campaignCreateWizardState: CAMPAIGN_CREATE_WIZARD_CONFIRMATION
      };

    case UI_CAMPAIGN_WIZARD_STEP_CHANGE:
      return {
        ...state,
        campaignCreateWizardState: action.payload
      };

    case UI_STORE_CAMPAIGN_INFORMATION:
      return {
        ...state,
        campaignCreateWizardState: CAMPAIGN_CREATE_WIZARD_PERSONALIZATION
      };

    case UI_CAMPAIGN_DETAIL_CLEAR:
      return {
        ...state,
        campaignCreateWizardState: initialState.campaignCreateWizardState
      };

    case SAVE_TEAM_CREATE_START:
      return {
        ...state,
        isCreateTeamInProgress: true
      };

    case SAVE_TEAM_CREATE_ERROR:
      return {
        ...state,
        isCreateTeamInProgress: false
      };

    case SAVE_TEAM_CREATE_SUCCESS:
      return {
        ...state,
        isCreateTeamInProgress: false,
        teamCreateWizardState: TEAM_CREATE_WIZARD_CONFIRMATION
      };

    case UI_TEAM_WIZARD_STEP_CHANGE:
      return {
        ...state,
        teamCreateWizardState: action.payload
      };

    case UI_TEAM_CAMPAIGN_SELECT:
      return {
        ...state,
        teamCreateWizardState: TEAM_CREATE_WIZARD_INFORMATION
      };

    case UI_TEAM_CAMPAIGN_CLEAR:
      return {
        ...state,
        teamCreateWizardState: initialState.teamCreateWizardState
      };

    case SAVE_PARTICIPANT_CREATE_START:
      return {
        ...state,
        isCreateParticipantInProgress: true
      };

    case SAVE_PARTICIPANT_CREATE_ERROR:
      return {
        ...state,
        isCreateParticipantInProgress: false
      };

    case SAVE_PARTICIPANT_CREATE_SUCCESS:
      return {
        ...state,
        isCreateParticipantInProgress: false,
        participantCreateWizardState: PARTICIPANT_CREATE_WIZARD_CONFIRMATION
      };

    case UI_PARTICIPANT_WIZARD_STEP_CHANGE:
      return {
        ...state,
        participantCreateWizardState: action.payload
      };

    case UI_PARTICIPANT_CAMPAIGN_SELECT:
      return {
        ...state,
        participantCreateWizardState: PARTICIPANT_CREATE_WIZARD_INFORMATION
      };

    case UI_PARTICIPANT_INFORMATION_SELECT:
      return {
        ...state,
        participantCreateWizardState: PARTICIPANT_CREATE_SURVEY_ATTEMPT
      };

    case UI_PARTICIPANT_CAMPAIGN_CLEAR:
      return {
        ...state,
        participantCreateWizardState: PARTICIPANT_CREATE_WIZARD_CAMPAIGN
      };

    case UI_DONATION_WIZARD_STEP_CHANGE:
      return {
        ...state,
        donationCreateWizardState: action.payload
      };

    case UI_STORE_DONATION_INFORMATION:
      return {
        ...state,
        donationCreateWizardState: DONATION_CREATE_WIZARD_INFORMATION
      };

    case UI_STORE_USER_INFORMATION:
      return {
        ...state,
        donationCreateWizardState: DONATION_CREATE_WIZARD_PAYMENT
      };

    case "PURCHASE_TICKET_PROCESS_START":
    case SAVE_DONATION_CREATE_START:
      return {
        ...state,
        isTransactionInProgress: 1
      };

    case FETCH_CREDIT_CARD_ID_START:
      return {
        ...state,
        isTransactionInProgress: 33
      };

    case "PURCHASE_TICKET_START":
    case SAVE_DONATION_CHECKOUT_START:
      return {
        ...state,
        isTransactionInProgress: 66
      };

    case "CANCEL_PURCHASE":
    case "PURCHASE_TICKET_SUCCESS":
    case "PURCHASE_TICKET_DECLINED":
      return {
        ...state,
        isTransactionInProgress: null
      };

    case SAVE_DONATION_CREATE_OFFLINE_START:
      return {
        ...state,
        isDonationInProgress: true
      };

    case SAVE_DONATION_CREATE_OFFLINE_SUCCESS:
    case SAVE_DONATION_CREATE_OFFLINE_ERROR:
      return {
        ...state,
        isDonationInProgress: false
      };

    case CANCEL_DONATION:
    case SAVE_DONATION_CHECKOUT_SUCCESS:
    case SAVE_DONATION_CHECKOUT_DECLINED:
    //case SAVE_DONATION_CREATE_ERROR:
    case FETCH_CREDIT_CARD_ID_ERROR:
    case SAVE_DONATION_CHECKOUT_ERROR:
      return {
        ...state,
        isTransactionInProgress: null,
        donationCreateWizardState: DONATION_CREATE_WIZARD_CONFIRMATION,
        donationInProgress: null
      };

    case UI_DONATION_CLEAR:
      return {
        ...state,
        donationCreateWizardState: DONATION_CREATE_WIZARD_DONATION
      };

    case UPDATE_PLATFORM_START:
      return {
        ...state,
        isUpdatePlatformInProgress: true
      };

    case UPDATE_PLATFORM_SUCCESS:
    case UPDATE_PLATFORM_ERROR:
      return {
        ...state,
        isUpdatePlatformInProgress: false
      };

    case UI_START_EDITING_FORM:
      return {
        ...state,
        isEditingForm: true
      };

    case UI_STOP_EDITING_FORM:
      return {
        ...state,
        isEditingForm: false
      };

    default:
      return commonUiReducer(state, action);
  }
}

import React from 'react';
import { Upload } from 'antd';

class SlidesWallField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      previewVisible: false,
      previewImage: '',
      defaultFileList: props.slides,
      fileList: props.slides
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.slides !== prevProps.slides) {
      let updatedSlides = this.props.slides;
      this.setState({fileList: updatedSlides});
    }
  }

  render() {
    const { action, handlePreview, onRemove, uploadButton } = this.props;
    return (
      <div className="slides-wall">
        <Upload
          action={action || ""}
          listType="picture-card"
          defaultFileList={this.state.defaultFileList}
          fileList={this.state.fileList}
          onPreview={handlePreview}
          onRemove={onRemove}
          openFileDialogOnClick={false}
        >
          {uploadButton}
        </Upload>
      </div>
    );
  }

}

export default SlidesWallField;

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { storeUserInformation } from "../donationActions";
import { updateMember, updateMemberProfile } from '../../member/memberActions';
import { updateSessionState, logout } from 'Parts/session/sessionActions';
import { verifyEmail } from "../../parts/common/verificationUtils";
import { openLoginModal } from "../../parts/ui/uiActions";
import dataFetcher from "../../parts/chore/dataFetcher";

import CreateInformationFormUser from './CreateInformationFormUser';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  platformId: state.platform.platform.id,
  organization: state.organization.organization,
  countries: state.geo.countries,
  provinces: state.geo.provinces,
  infos: state.donation.donationInformation,
  user: state.session.user,
  userTypeId: state.session.userTypeId,
  locale: state.session.language,
  surveyId:state.donation.surveyId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  storeUserInformation,
  updateMember,
  updateMemberProfile,
  updateSessionState,
  logout,
  verifyEmail,
  openLoginModal
}, dispatch);


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create(),
  dataFetcher
)(CreateInformationFormUser);

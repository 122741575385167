import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import TeamsLimitFieldEN from './locales/TeamsLimitField_en.json';
import TeamsLimitFieldFR from './locales/TeamsLimitField_fr.json';
import { Form, InputNumber } from 'antd';
import FundkyTooltip from 'Parts/ui/components/FundkyTooltip';

class TeamsLimitField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TeamsLimitField', TeamsLimitFieldEN);
    i18next.addResourceBundle('fr', 'TeamsLimitField', TeamsLimitFieldFR);
  }

  render() {
    const { t, data, campaignTypeId, disabled } = this.props;
    const { getFieldDecorator } = this.props.form;

    if (campaignTypeId === 1 || campaignTypeId === 4) return null;

    let inputValue = data === '0' ? '' : data;

    const input = <InputNumber min={0} disabled={disabled} />;
    const fieldId = 'teams_limit';
    const fieldOptions = {
      rules: [
        {
          pattern: /^[0-9]+$/,
          message: t('invalid')
        }
      ],
      initialValue: inputValue
    };

    return (
      <Form.Item
        label={
          <span>
            {t('Teams Limit Label')}
            <FundkyTooltip title={t('Field Tooltip')} />
          </span>
        }
        colon={false}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }
}

TeamsLimitField.defaultProps = {
  data: '0',
  disabled: false
};

TeamsLimitField.propTypes = {
  data: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default translate('TeamsLimitField')(TeamsLimitField);

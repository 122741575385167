import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-remarkable';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import { getPlatformUrl } from './../../parts/common/environment';
import { facebookScrape } from 'Parts/common/requestUtils';
import LinkButton from 'Parts/common/LinkButton';
import FundkyButton from 'Parts/ui/components/FundkyButton';

import CreateConfirmationPanel_en from './locales/CreateConfirmationPanel_en.json';
import CreateConfirmationPanel_fr from './locales/CreateConfirmationPanel_fr.json';

import './CreateConfirmationPanel.less';

class CreateConfirmationPanel extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'CreateConfirmationPanel-campaign', CreateConfirmationPanel_en);
    i18next.addResourceBundle('fr', 'CreateConfirmationPanel-campaign', CreateConfirmationPanel_fr);
  }

  handleFbShare = (event, url) => {
    FB.ui(
      {
        method: 'share',
        href: url,
        hashtag: this.props.t('facebook.hashtags')
      },
      function(response) {}
    );
  };

  componentDidMount() {
    const { locale, createdCampaign, platform } = this.props;
    const platformUrl = getPlatformUrl(platform.alias);
    facebookScrape([`${platformUrl}/${locale}/${createdCampaign.alias}`]);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUserLogged !== prevProps.isUserLogged && !this.props.isUserLogged) {
      this.props.history.push(`/${this.props.locale}`);
    }
  }

  render() {
    const { t, locale, createdCampaign, platform, userEmail } = this.props;
    const platformUrl = getPlatformUrl(platform.alias);

    if (!this.props.isUserLogged) {
      this.props.history.push(`/${this.props.locale}`);
    }

    if (!createdCampaign) {
      return null;
    } else {
      return (
        <div className="FundkyWrap">
          <div className="CreateConfirmationPanel">
            <FontAwesomeIcon
              icon={['fal', 'check-circle']}
              className="CreateConfirmationPanel__SuccessIcon"
            />
            <h2>{t('title')}</h2>
            {platform &&
            platform.settings &&
            platform.settings.campaign &&
            platform.settings.campaign.creation &&
            platform.settings.campaign.creation.preauth === '1' ? (
              <React.Fragment>
                <p>{t('authorized.desc')}</p>
                <h3>{t('authorized.subtitle')}</h3>
                <ul>
                  <li>{t('authorized.li1')}</li>
                  <li>{t('authorized.li2')}</li>
                  <li>{t('authorized.li3')}</li>
                </ul>
                <div className="CreateConfirmationPanel__Divider CreateConfirmationPanel__Divider--CTA">
                  {// TODO: Transfer this button in the Event Plugin
                  createdCampaign.behaviorId === 2
                    ? <>
                      <LinkButton
                        to={`/${locale}/d/campaigns/${createdCampaign.id}/tickets`}
                        size="large"
                        type="primary"
                      >
                        {t('authorized.tickets')}
                      </LinkButton>
                      <LinkButton
                        to={`/${locale}/${createdCampaign.alias}`}
                        size="large"
                      >
                        {t('authorized.campaign')}
                      </LinkButton>
                      </>
                    : <LinkButton
                        to={`/${locale}/${createdCampaign.alias}`}
                        size="large"
                        type="primary"
                      >
                        {t('authorized.campaign')}
                      </LinkButton>
                  }
                  <FundkyButton
                    className="CreateConfirmationPanel--inviteBtn"
                    icon={['fab', 'facebook-f']}
                    iconPosition="before"
                    text={t('authorized.invite')}
                    action={{
                      func: this.handleFbShare,
                      params: [`${platformUrl}/${locale}/${createdCampaign.alias}`]
                    }}
                    size="large"
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h3>{t('unauthorized.subtitle')}</h3>
                <Markdown>{t('unauthorized.desc', { userEmail: userEmail })}</Markdown>
                <p>
                  {t('unauthorized.meanwhile')}
                  <Link to={`/${locale}/d/campaigns/${createdCampaign.id}`}>
                    {t('unauthorized.personalize-your-campain')}
                  </Link>
                </p>
                <p>{t('unauthorized.thank-you')}</p>
                <div className="CreateConfirmationPanel__Divider CreateConfirmationPanel__Divider--CTA">
                  <LinkButton
                    to={`/${locale}/d/campaigns/${createdCampaign.id}`}
                    size="large"
                    type="primary"
                  >
                    {t('unauthorized.personalize-my-campain')}
                  </LinkButton>
                </div>
                <p>
                  <em>
                    {t('unauthorized.notice')}
                    <Link
                      to={`/${locale}/${t('URL:contact-us')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('unauthorized.click-here')}
                    </Link>
                  </em>
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    }
  }
}

export default translate('CreateConfirmationPanel-campaign')(CreateConfirmationPanel);

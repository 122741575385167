import React from 'react';
import PropTypes from 'prop-types';
import PublicPanel from './PublicPanel';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';
import PublicDonationQuote from './PublicDonationQuote';

import PublicDonationPanel_en from './locales/PublicDonationPanel_en.json';
import PublicDonationPanel_fr from './locales/PublicDonationPanel_fr.json';

import './PublicDonationPanel.less';

class PublicDonationPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicDonationPanel', PublicDonationPanel_en);
    i18next.addResourceBundle('fr', 'PublicDonationPanel', PublicDonationPanel_fr);
  }

  isDonationDisabled = campaign => {
    if (
      campaign &&
      campaign.settings &&
      campaign.settings.donation &&
      campaign.settings.donation.disabledOn
    ) {
      if (isDateBeforeCurrentDate(campaign.settings.donation.disabledOn, true)) return true;
    }
    return false;
  };

  render() {
    const { t, locale, campaign, team, participant, type, isClosed } = this.props;
    var firstDonorUrl = '';

    if (!this.props.donations.length && !isClosed) {
      if (!this.props.isBillingConfigured) {
        firstDonorUrl = <span>{t('spread-me')}</span>;
      } else
      if (!this.isDonationDisabled(campaign)) {
        switch (type) {
          case 'campaign':
            firstDonorUrl = (
              <span>
                {t('first-donor1')}
                <Link to={{ pathname: `/${locale}/${campaign.alias}/${t('URL:give')}` }}>
                  {t('first-donor2')}
                </Link>
                {t('first-donor3')}
              </span>
            );
            break;

          case 'team':
            firstDonorUrl = (
              <span>
                {t('first-donor1')}
                <Link
                  to={{
                    pathname: `/${locale}/${team.campaignAlias}/${team.alias}/${t('URL:give')}`
                  }}
                >
                  {t('first-donor2')}
                </Link>
                {t('first-donor3')}
              </span>
            );
            break;

          case 'participant':
            if (participant.teamAlias) {
              firstDonorUrl = (
                <span>
                  {t('first-donor1')}
                  <Link
                    to={{
                      pathname: `/${locale}/${participant.campaignAlias}/${participant.teamAlias}/${
                        participant.member.alias
                      }/${t('URL:give')}`
                    }}
                  >
                    {t('first-donor2')}
                  </Link>
                  {t('first-donor3')}
                </span>
              );
            } else {
              firstDonorUrl = (
                <span>
                  {t('first-donor1')}
                  <Link
                    to={{
                      pathname: `/${locale}/${participant.campaignAlias}/${t(
                        'URL:individual-participant'
                      )}/${participant.member.alias}/${t('URL:give')}`
                    }}
                  >
                    {t('first-donor2')}
                  </Link>
                  {t('first-donor3')}
                </span>
              );
            }

            break;

          default:
            break;
        }
      } else {
        firstDonorUrl = <span>{t('no-donor')}</span>;
      }
    } else {
      firstDonorUrl = null;
    }

    const donations = this.props.donations.length
      ? this.props.donations.map(donation => (
          <PublicDonationQuote
            key={donation.id}
            className="DonationSection__DonationQuote"
            donation={donation}
            locale={locale}
            type={type}
          />
        ))
      : firstDonorUrl;

    return (
      <PublicPanel className="PublicDonationPanel" title={t('donors')} id="donors">
        {donations}
      </PublicPanel>
    );
  }
}

PublicDonationPanel.defaultProps = {
  campaign: {},
  team: {},
  participant: {},
  isClosed: false
};

PublicDonationPanel.propTypes = {
  donations: PropTypes.array.isRequired,
  isClosed: PropTypes.bool.isRequired
};

export default translate('PublicDonationPanel')(PublicDonationPanel);

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

import {
  clearMemberAdminPermissions,
  fetchMemberOrganizationRoles,
  fetchMemberPlatformRoles,
  isCampaignOwner
} from '../parts/session/sessionActions';
import { fetchPlatform, fetchPlatformMedia } from '../platform/platformActions';
import { forceCloseConnectionModal } from 'Parts/ui/uiActions';

import AdminLayout from './AdminLayout';

const mapStateToProps = state => {
  return {
    organizationId: state.organization.organization.id,
    platform: state.platform.platform,
    campaigns: state.campaign.campaigns,
    campaign: state.campaign.campaign,
    locale: state.session.language,
    user: state.session.user,
    permissions: state.session.permissions,
    roles: state.session.roles,
    campaignEnabled: state.platform.platform.settings.campaign.creation.enabled,
    userId: state.session.userId,
    _isCampaignOwner: get(state, 'session.isCampaignOwner')
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  clearMemberAdminPermissions,
  forceCloseConnectionModal,
  fetchPlatform,
  fetchPlatformMedia,
  fetchMemberOrganizationRoles,
  fetchMemberPlatformRoles,
  isCampaignOwner
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AdminLayout);

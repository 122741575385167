import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import dataFetcher from 'Parts/chore/dataFetcher';

import ThirdPartyGuidelinesPage from './ThirdPartyGuidelinesPage';

const mapStateToProps = state => ({
  locale: state.session.language,
  contentGuidelines: (state.platform.content && state.platform.content.campaign) && state.platform.content.campaign.thirdPartyGuidelines || null,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  dataFetcher,
)(ThirdPartyGuidelinesPage);

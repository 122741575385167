import {
  FETCH_MEMBER_ID_SUCCESS,
  FETCH_MEMBER_ID_ERROR,
  UI_MEMBER_SELECT,
  UI_MEMBER_CLEAR,
  CLEAR_MEMBER
} from './memberActions';

const initialState = {
  member: {}
};

export default function memberReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBER_ID_SUCCESS:
      return { ...state, member: action.payload };

    case FETCH_MEMBER_ID_ERROR:
      return { ...state, member: {}};

    case UI_MEMBER_SELECT:
      return { ...state};

    case UI_MEMBER_CLEAR:
      return { ...state, member: {}};

    case CLEAR_MEMBER:
      return { ...state, member: initialState.member };

    default:
      return state;
  }
}

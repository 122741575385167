import { createStore, combineReducers, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import isBrowser from 'is-in-browser';
import thunkMiddleware from 'redux-thunk';
import compact from 'lodash/compact';
import serverLoggerMiddleware from './serverLoggerMiddleware';
import browserLoggerMiddleware from './browserLoggerMiddleware';
import errorMiddleware from './errorMiddlerware';
import uberReducerLogout from './uberReducerLogout';

export const stores = [];


//export default function configureStore(rootReducer, initialState, history, uber = null) {
export default function configureStore(rootReducer, initialState, history, uber = null) {
  if (process.env.NODE_ENV === 'development' && isBrowser) {
    console.log('Init Redux store with', initialState);
  }

  let loggerMiddleware = null;
  if (process.env.NODE_ENV === 'development') {
    loggerMiddleware = isBrowser ? browserLoggerMiddleware : serverLoggerMiddleware;
  }

  let middlewares = [
    errorMiddleware,
    thunkMiddleware,
    // after the thunk middleware to not log the thunks
    loggerMiddleware,
    // after the logger because the middleware "swallows" the routing actions
    routerMiddleware(history)
  ];

  // create the initial store using the initial reducers that passed in
  const store = createStore(
    uber
      ? uberReducerLogout(
          createReducer(rootReducer),
          uber.logoutActionType,
          uber.whiteList
        )
      : createReducer(rootReducer),
    initialState,
    applyMiddleware(...compact(middlewares))
  );

  // create a namespace that will later be filled with new reducers
  store.asyncReducers = {};

  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(
      store.uber
        ? uberReducerLogout(
            createReducer(rootReducer, store.asyncReducers),
            store.uber.logoutActionType,
            store.uber.whiteList
          )
        : createReducer(rootReducer, store.asyncReducers)
    );
    return store;
  }

  store.removeAsyncReducers = name => {
    return store.injectReducer(name, (state = {}) => state);
  };
  if (uber) {
    stores[0] = store;
    return stores[0];
  } else {
    return store;
  }
}

function createReducer(rootReducer, asyncReducers) {
  return combineReducers({
    ...rootReducer,
    ...asyncReducers
  })
}

import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import PublicCarousel from '../common/PublicCarousel';
import PublicPanel from '../common/PublicPanel';
import AvatarThumbnail from '../common/AvatarThumbnailContainer';

import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';

import PublicParticipantPanel_en from './locales/PublicParticipantPanel_en.json';
import PublicParticipantPanel_fr from './locales/PublicParticipantPanel_fr.json';

import './PublicParticipantPanel.less';

const PARTICIPANTS_PER_COLUMN = 4;

class PublicParticipantPanel extends React.Component {
  constructor(props) {
    super(props);

    this.columnItemCount = 0;

    i18next.addResourceBundle('en', 'PublicParticipantPanel', PublicParticipantPanel_en);
    i18next.addResourceBundle('fr', 'PublicParticipantPanel', PublicParticipantPanel_fr);
  }

  componentDidUpdate() {
    const list = window.document.querySelector('.PublicParticipantPanel__Carousel .slider-list');

    if (list) {
      let maxHeight = this.columnItemCount * 60;
      list.style.height = maxHeight + 'px';
    }
  }

  componentDidMount() {
    const list = window.document.querySelector('.PublicParticipantPanel__Carousel .slider-list');

    if (list) {
      let maxHeight = this.columnItemCount * 60;
      list.style.height = maxHeight + 'px';
    }
  }

  render() {
    const { props, sortParticipants } = this,
      { t, locale, campaign, team, participants, full, isClosed, slidesToShow, platform } = props;

    this.columnItemCount = 1;

    const sortedParticipants = participants.sort((a, b) =>
      Number(get(b, 'collected', 0).toString().replace('NaN', 0)) - Number(get(a, 'collected', 0).toString().replace('NaN', 0)) ||
      a.member.firstName.toLowerCase().localeCompare(b.member.firstName.toLowerCase()) ||
      a.member.lastName.toLowerCase().localeCompare(b.member.lastName.toLowerCase())
    );

    const participantBlocks = chunk(sortedParticipants, PARTICIPANTS_PER_COLUMN).map(
      (participantChunk, i) => {
        const winnerIcon =
          i < 3 ? (
            i === 0 ? (
              <FontAwesomeIcon icon={['fas', 'trophy-alt']} />
            ) : (
              <FontAwesomeIcon icon={['fas', 'trophy']} />
            )
          ) : null;

        const participantItems = participantChunk.map(participant => {
          let captainIcon = null;
          if (team && team.leader) {
            captainIcon =
              team.leaderId == participant.id ? <FontAwesomeIcon icon={['fas', 'star']} /> : null;
          }

          const participantUrl = participant.teamId
            ? `/${locale}/${participant.campaignAlias}/${participant.teamAlias}/${participant.member.alias}`
            : `/${locale}/${participant.campaignAlias}/${t('URL:individual-participant')}/${
                participant.member.alias
              }`;

          return (
            <Link to={participantUrl} key={participant.id}>
              <AvatarThumbnail
                className="PublicParticipantPanel__Participant"
                entity={participant}
                primaryContent={
                  <span>
                    {participant.member.name} {captainIcon}
                  </span>
                }
                secondaryContent={moneyFormat(Number(get(participant, 'collected', 0).toString().replace('NaN', 0)), locale)}
              />
            </Link>
          );
        });

        if (this.columnItemCount < PARTICIPANTS_PER_COLUMN) {
          this.columnItemCount = participantItems.length;
          if (this.columnItemCount > PARTICIPANTS_PER_COLUMN) {
            this.columnItemCount = PARTICIPANTS_PER_COLUMN;
          }
        }

        return (
          <div className="PublicParticipantPanel__Page" key={i}>
            {participantItems}
          </div>
        );
      }
    );

    const height = this.columnItemCount * 60;

    const carousel =
      participantBlocks.length > 0 ? (
        <PublicCarousel
          className="PublicParticipantPanel__Carousel"
          slidesToShow={slidesToShow}
          sideControls={participantBlocks.length > slidesToShow ? true : false}
          initialSlideHeight={height}
          delayInit
        >
          {participantBlocks}
        </PublicCarousel>
      ) : null;

    const noParticipantMessage = <span>{t('first-participant')}</span>;

    const isRegistrationDisabled =
      campaign &&
      campaign.settings &&
      campaign.settings.registration &&
      campaign.settings.registration.disabledOn
        ? isDateBeforeCurrentDate(campaign.settings.registration.disabledOn, true)
        : false;

    const showFooter = isClosed || isRegistrationDisabled ? false : true;

    return (
      <PublicPanel
        className="PublicParticipantPanel"
        title={t('title')}
        id="participants"
        searchable
        searchPlaceholder={t('search')}
        footer={showFooter}
        count={participants.length}
        limit={get(campaign, 'settings.participant.limit', 0)}
        full={full}
        campaign={campaign}
        platform={platform}
      >
        {carousel || noParticipantMessage}
      </PublicPanel>
    );
  }
}

PublicParticipantPanel.propTypes = {
  campaign: PropTypes.shape({
    alias: PropTypes.string.isRequired
  }).isRequired,
  team: PropTypes.shape({
    alias: PropTypes.string
  }),
  participants: PropTypes.array.isRequired,
  slidesToShow: PropTypes.number.isRequired,
  isClosed: PropTypes.bool.isRequired
};

export default translate('PublicParticipantPanel')(PublicParticipantPanel);

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import PreapprovedCampaignThirdPartyField_en from './locales/PreapprovedCampaignThirdPartyField_en.json';
import PreapprovedCampaignThirdPartyField_fr from './locales/PreapprovedCampaignThirdPartyField_fr.json';
import { Form, Switch } from 'antd';

class PreapprovedCampaignThirdPartyField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PreapprovedCampaignThirdPartyField', PreapprovedCampaignThirdPartyField_en);
    i18next.addResourceBundle('fr', 'PreapprovedCampaignThirdPartyField', PreapprovedCampaignThirdPartyField_fr);
  }

  render() {
    const { t, form, fieldId, className, checked } = this.props;
    const { getFieldDecorator } = form;
    const Input = <Switch />;
    const fieldOptions = {
      valuePropName: 'checked',
      initialValue: checked
    };

    return (
      <span className="wrap-field">
        <Form.Item
          className={className}
          label={(
              <span>
                {t('Field Label')}
              </span>
            )}
        >
          {getFieldDecorator(fieldId, fieldOptions)(Input)}
        </Form.Item>
        <p><em>{t('Field Description')}</em></p>
      </span>
    );
  }

}

PreapprovedCampaignThirdPartyField.defaultProps = {
  fieldId: 'preapprovedCampaign',
  checked: false,
  className: 'PreapprovedCampaign__Field'
};

PreapprovedCampaignThirdPartyField.propTypes = {
  fieldId: PropTypes.string,
  form: PropTypes.object.isRequired,
  checked: PropTypes.bool
};

export default translate('PreapprovedCampaignThirdPartyField')(PreapprovedCampaignThirdPartyField);

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import searchBarEN from './locales/SearchBar_en.json';
import searchBarFR from './locales/SearchBar_fr.json';
import { Form, Button } from 'antd';
import { Collapse } from 'react-collapse';
import classnames from 'classnames';
import throttle from 'lodash/throttle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminTitleBar from '../common/AdminTitleBar';

import SearchFilter from './filters/SearchFilter';

import './SearchBar.less';

class SearchBar extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'SearchBar', searchBarEN);
    i18next.addResourceBundle('fr', 'SearchBar', searchBarFR);

    this.state = {
      isFilterBarOpen: false
    };
    this.search = throttle(this.search.bind(this), 1000);
    this.handleFilterBarIconClick = this.handleFilterBarIconClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleFilterBarIconClick() {
    this.setState({ isFilterBarOpen: !this.state.isFilterBarOpen });
  }

  handleSearch(e) {
    e.preventDefault();
    // let searchFilter = {};
    this.props.form.validateFields((err, values) => {
      if (!err){
        if (values.creationDate){
          values.start = moment.utc(values.creationDate[0].hour(0).minute(0).second(0).millisecond(0)).format("YYYY-MM-DD HH:mm:ss Z");
          values.end = moment.utc(values.creationDate[1].hour(23).minute(59).second(59).millisecond(999)).format("YYYY-MM-DD HH:mm:ss Z");
        }
        Object.keys(values).forEach((key) => (values[key] === undefined || key === 'creationDate' ) && delete values[key]);
      }
      this.search(values);
    });
  }

  handleChange(e) {
    // We can't throttle the event handler directly because the throttle function "holds" the execution of the underlying function
    // It results a React error because we manipulate the event asynchronously.
    // So we read the event, and then call the throttled function
    const { value } = e.target;
    const filters = {
      ...this.props.form.getFieldsValue(),
      search : value
    };

    this.search(filters);
  }

  search(filters){
    this.props.onSearch(filters);
  }

  handleReset() {
    this.props.form.resetFields();
  }

  render() {
    const { t, extraContent } = this.props;

    const rootElementClassName = classnames('SearchBar', { 'SearchBar--compact': this.props.compact });

    const openFilterBarIconClassName = classnames({
      'SearchBar__ToggleFilterBar': true,
      'SearchBar__ToggleFilterBar--open': this.state.isFilterBarOpen
    });

    const openFilterBarIcon = this.props.filters.length > 0 ? (
      <FontAwesomeIcon
        className={openFilterBarIconClassName}
        icon={["fas", "filter"]}
        onClick={this.handleFilterBarIconClick}
      />
    ) : null;

    const titleBlock = !this.props.compact && this.props.title ? (
      <div className="SearchBar__Divider">
        <AdminTitleBar title={this.props.title} subName={this.props.subName} buttons={[...this.props.buttons, this.props.reportButton, this.props.createButton]} />
      </div>
    ) : null;

    var filtersWithPropsForm = this.props.filters.map((FilterComponent, i) => <FilterComponent form={this.props.form} key={i}/>);

    const collapse = this.props.filters.length > 0 ? (
      <Collapse isOpened={this.state.isFilterBarOpen}>
        <div className="SearchBar__FilterBar">
          <h2>{t('Additional filters')}</h2>
            { filtersWithPropsForm }
          <div className="SearchBar__FilterBarControls">
            <Button onClick={this.handleReset}>
              {t('Reset')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('Search')} <FontAwesomeIcon icon={["fas", "search"]} />
            </Button>
          </div>
        </div>
      </Collapse>
    ) : null;

    return (
      <div className={rootElementClassName}>
        {titleBlock}
        {extraContent!=null &&(
        <div className="extraContent">{extraContent}</div>
        )}
        <Form onSubmit={this.handleSearch}>
          <SearchFilter
            form={this.props.form}
            title={this.props.title}
            placeholder={this.props.placeholder || this.props.title}
            compact={this.props.compact}
            openFilterBarIcon={ openFilterBarIcon }
            onChange={this.handleChange}
          />
          {collapse}
        </Form>
      </div>
    );
  }

 }

 SearchBar.defaultProps = {
  compact: false,
  createButton: null,
  reportButton: null,
  placeholder: null,
  filters:[],
  subName: null,
  buttons: [],
  extraContent: null
 };

 SearchBar.propTypes = {
  compact: PropTypes.bool,
  title: PropTypes.string,
  createButton: PropTypes.element,
  filters: PropTypes.array,
  placeholder: PropTypes.string,
  buttons: PropTypes.array,
  extraContent: PropTypes.element
 };

export default translate('SearchBar')(SearchBar);

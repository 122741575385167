import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SubmitButton extends React.Component {

  handleOnClick = (event) => {
    this.props.dataLayer();

    if (this.props.overwriteSubmit) {
      this.props.overwriteSubmit(event);
    }
  }

  render() {
    const faIcon =
    (this.props.icon && this.props.iconStyle ) ?
      <FontAwesomeIcon icon={[this.props.iconStyle, this.props.icon]} />
    : null

    return (
      <Form.Item className={this.props.className}>
        <Button className="btn-submit" size="large" type="primary" htmlType={this.props.htmlType} loading={this.props.loading} onClick={this.handleOnClick} disabled={this.props.disabled}>
          {this.props.children}
          {faIcon}
        </Button>
      </Form.Item>
    );
  }

}

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  iconStyle: PropTypes.string,
  icon: PropTypes.string,
  htmlType: PropTypes.string,
  disabled: PropTypes.bool,
  dataLayer: PropTypes.func,
};

SubmitButton.defaultProps = {
  loading: false,
  className: null,
  htmlType: 'submit',
  disabled: false,
  overwriteSubmit: null,
  dataLayer: () => {},
};

export default SubmitButton;

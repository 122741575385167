import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import AmountField_en from './locales/AmountField_en.json';
import AmountField_fr from './locales/AmountField_fr.json';

import AmountOptions from './AmountOptions';

class AmountField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AmountField', AmountField_en);
    i18next.addResourceBundle('fr', 'AmountField', AmountField_fr);

    this.state = {
      selected: null
    };
  }

  render() {
    const { locale, form, className, t } = this.props;
    const { getFieldDecorator } = form;

    const input = (
      <AmountOptions locale={locale} savedValue={this.props.savedValue} amounts={this.props.amounts} custom={this.props.custom} />
    );
    const fieldId = 'amount';
    const fieldOptions = {
      rules: [
        {
          required: true,
          message: t('require')
        },
        {
          validator: (rule, value, cb) => (value >= 5 && value <= 10000 ? cb() : cb(true)),
          message: t('minMax')
        }
      ],
    };

    return (
      <Form.Item
        className={className}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

AmountField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  savedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

export default translate('AmountField')(AmountField);

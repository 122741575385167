import { compose, bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import dataFetcher from "../parts/chore/dataFetcher";

import { clearCampaign } from '../campaign/campaignActions';

import CheckCampaignRoute from './CheckCampaignRoute';

const mapStateToProps = state => {
  return ({
    campaign: state.campaign.campaign,
    isFetchCampaignInProgress: state.campaign.isFetchCampaignInProgress
  });
};

const mapDispatchToProps = dispatch => bindActionCreators({
  clearCampaign
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(CheckCampaignRoute);

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import AdminTitleBar from 'Parts/common/AdminTitleBar';
import PagesRefundPageFormContainer from './components/PagesRefundPageFormContainer';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import PagesRefundPage_en from './locales/PagesRefundPage_en.json';
import PagesRefundPage_fr from './locales/PagesRefundPage_fr.json';

class PagesRefundPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PagesRefundPage', PagesRefundPage_en);
    i18next.addResourceBundle('fr', 'PagesRefundPage', PagesRefundPage_fr);
  }

  render() {
    const { props } = this;
    const { t, locale } = props;

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: () => (
          <Can
            rules={[
              ['roles.platform.OWNER', 'permissions.platform.platform.update'],
              ['roles.platform.SUPERADMIN', 'permissions.platform.platform.update']
            ]}
            redirect={`/${locale}/d`}
          >
            <PagesRefundPageFormContainer {...props} />
          </Can>
        )
      }
    ];

    return (
      <div className="PagesRefundPage">
        <Helmet>
          <title>{t('refund-policy-title')}</title>
        </Helmet>
        <AdminTitleBar title={t('refund-policy-title')} />
        <ResponsiveTabs pages={tabPages} parentPage={props} defaultTabHasNoSlug />
      </div>
    );
  }
}

export default translate('PagesRefundPage')(PagesRefundPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Upload, Modal, Button } from 'antd';
import loadImage from 'blueimp-load-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { getSessionId } from '../../../session/sessionUtils';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import ReactCrop from 'react-image-crop';
import FundkyTooltip from '../FundkyTooltip';

import { getCdnUrl } from '../../../common/environment';

import UploadCropField_EN from './locales/UploadCropField_en.json';
import UploadCropField_FR from './locales/UploadCropField_fr.json';

import './UploadCropField.less';

/* ***************************************************************** */
// The default settings of this component are for the campaign banners.
/* ***************************************************************** */

class UploadCropField extends React.Component {
  constructor(props) {
    super(props);

    let initialValueUrl;

    if (props.initialValue) {
      if (
        props.initialValue.indexOf('http://') === -1 &&
        props.initialValue.indexOf('https://') === -1
      ) {
        initialValueUrl = getCdnUrl() + props.initialValue;
      } else {
        initialValueUrl = props.initialValue;
      }
    }

    const aspect = props.aspectRatio ? this.props.aspectRatio : null;
    const imageExtension =
      props.initialValue &&
      this.getImageExtensionFromString(props.initialValue);

    this.state = {
      fileList: props.initialValue
        ? [
            {
              uid: '-1',
              name: `default.${imageExtension}`,
              status: 'done',
              url: initialValueUrl ? initialValueUrl : null
            }
          ]
        : null,
      imageUrl: initialValueUrl ? initialValueUrl : null,
      loading: false,
      removeImage: false,
      crop: {
        aspect,
        x: 0,
        y: 0,
        width: props.minWidth,
        height: props.minHeight
      },
      cropComplete: props.initialValue ? true : false,
      croppedImageUrl: null,
      showCrop: false,
      uploadErrors: null
    };

    i18next.addResourceBundle('en', 'UploadCropField', UploadCropField_EN);
    i18next.addResourceBundle('fr', 'UploadCropField', UploadCropField_FR);
  }

  componentDidUpdate() {
    if (
      !this.state.imageUrl &&
      this.props.initialValue &&
      !this.state.removeImage
    ) {
      let initialValueUrl;
      if (
        this.props.initialValue.indexOf('http://') === -1 &&
        this.props.initialValue.indexOf('https://') === -1
      ) {
        initialValueUrl = getCdnUrl() + this.props.initialValue;
      } else {
        initialValueUrl = this.props.initialValue;
      }

      const imageExtension = this.getImageExtensionFromString(
        this.props.initialValue
      );

      this.setState({
        fileList: [
          {
            uid: '-1',
            name: `default.${imageExtension}`,
            status: 'done',
            url: initialValueUrl
          }
        ],
        imageUrl: initialValueUrl
      });
    }
  }

  getBase64 = (img, callback) => {
    if (img) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
  };

  // VALIDATE BEFORE UPLOAD TO UPLOADER
  beforeUpload = file => {
    // Reset uploadErrors
    this.setState({
      uploadErrors: null
    });

    // Test filetype - JPG || PNG
    if (!/^image\/(jpe?g|png)$/i.test(file.type)) {
      this.setState({
        uploadErrors: [{ type: 'fileType' }]
      });
      return false;
    }
    // Test filesize
    if (file.size > this.props.maxSize && this.props.maxSize > 0) {
      this.setState({
        uploadErrors: [{ type: 'size' }]
      });
      return false;
    }
  };

  // Triggers when changing image in Upload Component
  handleChange = ({ file }) => {
    // Reset states
    this.setState({
      cropComplete: false,
      croppedImageUrl: null,
      imageUrl: null
    });

    // Return if file is still uploading
    if (file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }

    // If file is loaded and no uploadErrors
    if (file && this.state.uploadErrors === null) {
      // Generate thumbnail
      loadImage(
        file.originFileObj,
        img => {
          if (this.validateSize(img.width, img.height)) {
            // Validate sizes
            this.setState({
              loading: false,
              imageUrl: img.toDataURL(file.type),
              showCrop: true
            });
          } else {
            this.setState({
              fileList: [],
              loading: false
            });
          }
        },
        { orientation: true }
      );
    }

    this.props.onChange({ target: { value: true, id: this.props.fieldId } });
  };

  // Validate the image size before finishing upload
  validateSize = (width, height) => {
    const { minWidth, minHeight, maxWidth, maxHeight } = this.props;

    // Validate min size
    if ((minWidth && width < minWidth) || (minHeight && height < minHeight)) {
      this.setState(
        { uploadErrors: [{ type: 'min' }] },
        () => this.props.form.validateFields([this.props.fieldId], { force: true })
      );
      return false;
    }

    // Validate max size
    if ((maxWidth && width > maxWidth) || (maxHeight && height > maxHeight)) {
      this.setState(
        { uploadErrors: [{ type: 'max' }] },
        () => this.props.form.validateFields([this.props.fieldId], { force: true })
      );
      return false;
    }

    return true;
  };

  // Get image extension from string
  getImageExtensionFromString = string => {
    const subString = string.substring(0, 16);
    return subString.includes('png') ? 'png' : 'jpg';
  };

  // Triggers when image is loaded
  onImageLoaded = image => {
    this.imageRef = image;

    // Image settings (aspect ratio, min sizes, max sizes)
    const aspect = this.props.aspectRatio ? this.props.aspectRatio : null;
    const minWidth = (this.props.minWidth * image.width) / image.naturalWidth;
    const minHeight = this.props.minHeight
      ? (this.props.minHeight * image.height) / image.naturalHeight
      : ((this.props.minWidth / this.props.aspectRatio) * image.height) /
        image.naturalHeight;
    const maxWidth = this.props.maxWidth
      ? (this.props.maxWidth * image.width) / image.naturalWidth
      : null;
    const maxHeight = this.props.maxHeight
      ? (this.props.maxHeight * image.height) / image.naturalHeight
      : null;

    // Save in state and update crop
    this.setState({
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      crop: {
        aspect,
        x: 0,
        y: 0,
        width: minWidth,
        height: minHeight
      }
    });
  };

  // Triggers when crop selection dragging stops
  onCropComplete = crop => {
    // Verify if crop is outside of image and replace at position 0.0 if happens
    if (Math.sign(crop.x) === -1 || Math.sign(crop.y) === -1) {
      const aspect = this.props.aspectRatio ? this.props.aspectRatio : null;
      this.setState({
        crop: {
          aspect,
          x: 0,
          y: 0,
          width: crop.width,
          height: crop.height
        }
      });
    } else {
      this.makeClientCrop(crop);
    }
  };

  // Triggers when dragging crop selection
  onCropChange = crop => {
    this.setState({ crop });
  };

  // Create the crop
  async makeClientCrop(crop) {
    const imageSrc = this.imageRef.src;
    let imageExtension = this.getImageExtensionFromString(imageSrc);

    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(
        this.imageRef,
        crop,
        `newFile.${imageExtension}`
      );
      this.setState({ croppedImageUrl });
    }
  }

  // Create canvas and transform canvas to blob
  getCroppedImg(image, crop, fileName) {
    // Create canvas
    const canvas = document.createElement('canvas');

    // Get image scale
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Calculate scaled sizes and set canvas to scaled sizes
    const scaledWidth = crop.width * scaleX;
    const scaledHeight = crop.height * scaleY;
    canvas.width = scaledWidth;
    canvas.height = scaledHeight;

    // Draw image from canvas
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      scaledWidth,
      scaledHeight,
      0,
      0,
      scaledWidth,
      scaledHeight
    );

    // Get file extension and transform canvas to base64
    const fileExt = fileName.includes('.png') ? 'png' : 'jpeg';
    return canvas.toDataURL(`image/${fileExt}`, 1);
  }

  // Triggers when clicking the button to confirm the crop
  handleCropConfirm = () => {
    this.cropConfirmPropFunc(this.state.croppedImageUrl);
  };

  // Generate the thumbnail image from the upload using a promise
  thumbImage = file => {
    const _URL =
      typeof window !== 'undefined' ? window.URL || window.webkitURL : null;

    return new Promise(function(resolve, reject) {
      let img = new Image();

      img.onload = function() {
        let width = this.naturalWidth,
          height = this.naturalHeight;

        _URL.revokeObjectURL(file.originFileObj);

        resolve({ file, width, height });
      };

      img.onerror = function() {
        _URL.revokeObjectURL(file.originFileObj);
        reject(file);
      };

      img.src = _URL.createObjectURL(file.originFileObj);
    });
  };

  // Triggers when crop is confirmed and calls handleCropConfirm prop function
  cropConfirmPropFunc = imageUrl => {
    this.setState(
      {
        imageUrl,
        cropComplete: true,
        showCrop: false,
        removeImage: false
      },
      () => {
        this.props.handleCropConfirm(imageUrl);
        this.props.form.validateFields([this.props.fieldId], { force: true });
      }
    );
  };

  // Calculate height from aspect ratio
  calcHeightFromAspect = (width, heightType = 'minHeight') => {
    const { aspectRatio } = this.props;
    if (aspectRatio) {
      return Math.round((width / aspectRatio) * 1);
    } else {
      if (this.props[heightType]) {
        return this.props[heightType];
      }
    }
  };

  // Func to display the upload errors
  displayUploadErrors = () => {
    const { t } = this.props;
    const errors = this.state.uploadErrors.map((error, index) => {
      switch (error.type) {
        // Min dimensions
        case 'min':
          let minHeight = this.calcHeightFromAspect(
            this.props.minWidth,
            'minHeight'
          );
          return (
            <span key={index} className="Upload__Error">
              {t(`error.min`, {
                width: this.props.minWidth,
                height: minHeight
              })}
            </span>
          );

        // Max dimensions
        case 'max':
          let maxHeight = this.calcHeightFromAspect(
            this.props.maxWidth,
            'maxHeight'
          );
          return (
            <span key={index} className="Upload__Error">
              {t('error.max', {
                width: this.props.maxWidth,
                height: maxHeight
              })}
            </span>
          );

        // File type
        case 'fileType':
          return (
            <span key={index} className="Upload__Error">
              {t(`error.fileType`, { fileType: this.props.fileType })}
            </span>
          );

        // Max sizes
        case 'size':
          return (
            <span key={index} className="Upload__Error">
              {t('error.size', { size: this.props.maxSize / 1024 / 1024 })}
            </span>
          );
      }
    });
    return errors;
  };

  // Reset field and states
  resetField = () => {
    let initialValueUrl;

    if (this.props.initialValue) {
      if (
        this.props.initialValue.indexOf('http://') === -1 &&
        this.props.initialValue.indexOf('https://') === -1
      ) {
        initialValueUrl = getCdnUrl() + this.props.initialValue;
      } else {
        initialValueUrl = this.props.initialValue;
      }
    }

    const aspect = this.props.aspectRatio ? this.props.aspectRatio : null;
    const imageExtension =
      (this.props.initialValue &&
        this.getImageExtensionFromString(this.props.initialValue)) ||
      'jpg';

    this.setState({
      fileList: this.props.initialValue
        ? [
            {
              uid: '-1',
              name: `default.${imageExtension}`,
              status: 'done',
              url: initialValueUrl ? initialValueUrl : null
            }
          ]
        : null,
      imageUrl: initialValueUrl ? initialValueUrl : null,
      loading: false,
      removeImage: false,
      crop: {
        aspect,
        x: 0,
        y: 0,
        width: this.props.minWidth,
        height: this.props.minHeight
      },
      cropComplete: this.props.initialValue ? true : false,
      croppedImageUrl: null,
      showCrop: false,
      uploadErrors: null
    });
  };

  // Fake request (needed by antd uploader)
  dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  // Handle delete
  handleClickDelete = e => {
    const { form, fieldId, required, handleDelete } = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      imageUrl: null,
      cropComplete: false,
      removeImage: true
    });
    form.setFieldsValue({ [fieldId]: null });

    if (required) {
      form.validateFields([fieldId]);
    }

    handleDelete(fieldId);
  };

  render() {
    const {
      t,
      form,
      fieldId,
      className,
      hasFeedback,
      disabled,
      erasable,
      handleDelete,
      tooltip,
      colon,
      label
    } = this.props;
    const {
      imageUrl,
      showCrop,
      cropComplete,
      fileList,
      uploadErrors
    } = this.state;
    const { getFieldDecorator } = form;

    const sessionId = getSessionId();
    const headers = { 'session-id': sessionId };

    const recommendedHeight = this.props.recommendedHeight
      ? this.props.recommendedHeight
      : this.calcHeightFromAspect(
          this.props.recommendedWidth || this.props.minWidth,
          this.props.recommendedWidth ? 'recommendedWidth' : 'minHeight'
        );

    const uploadButton = (
      <div className="Upload__Button">
        {this.props.icon && (
          <FontAwesomeIcon
            icon={this.props.icon}
            className="UploadButton__Icon"
          />
        )}
        <h3>
          {this.props.uploadTitle ? this.props.uploadTitle : t('upload-title')}
        </h3>
        <p>
          {t('recommended-dimensions', {
            width: this.props.recommendedWidth || this.props.minWidth,
            height: recommendedHeight
          })}
          <br />
          {t('upload-dimensions', {
            width: this.props.minWidth,
            height: this.calcHeightFromAspect(this.props.minWidth, 'minHeight')
          })}
          <br />
          {this.props.fileType},{' '}
          {t('upload-size', { size: this.props.maxSize / 1024 / 1024 })}
        </p>
      </div>
    );

    const image = imageUrl ? (
      <React.Fragment>
        {erasable && handleDelete && (
          <span
            className="UploadCropField__delete"
            onClick={this.handleClickDelete}
          >
            <FontAwesomeIcon icon={['fal', 'trash-alt']} />
          </span>
        )}
        <img src={imageUrl} className="Upload__Preview" alt="Upload Preview" />
      </React.Fragment>
    ) : null;

    const field = (
      <Upload
        name="media"
        className="Upload"
        listType="picture-card"
        headers={headers}
        customRequest={this.dummyRequest}
        className="UploadCrop__Field"
        showUploadList={false}
        beforeUpload={this.beforeUpload}
        onChange={this.handleChange}
        accept={this.props.fileType}
        disabled={disabled}
      >
        {image || uploadButton}
      </Upload>
    );

    let labelProperties = tooltip
      ? {
          label: (
            <span>
              {label + ':'}
              <FundkyTooltip title={tooltip} />
            </span>
          ),
          colon: false
        }
      : {
          label: label,
          colon: colon
        };

    const options = {
      rules: [
        {
          required: this.props.required || false,
          message: this.props.requiredMessage || t(`required`)
        },
        {
          validator: (rule, value, cb) => uploadErrors ? cb(true) : cb(),
          message: ""// message added by displayErrors()
        }
      ],
      initialValue: fileList
    };

    const displayErrors = uploadErrors !== null
      ? this.displayUploadErrors()
      : null;

    return (
      <Form.Item
        className={classnames('UploadCropField', className)}
        {...labelProperties}
        hasFeedback={hasFeedback}
      >
        {getFieldDecorator(fieldId, options)(field)}
        {displayErrors}
        {imageUrl && !cropComplete && showCrop && (
          <Modal
            title={t('crop-modal-title')}
            visible={true}
            closable={false}
            footer={[
              null,
              <Button
                key="submit"
                type="primary"
                onClick={this.handleCropConfirm}
              >
                {t('crop-confirm')}
              </Button>
            ]}
          >
            <div className="Crop__Wrap">
              <ReactCrop
                src={imageUrl}
                crop={this.state.crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                minWidth={this.state.minWidth}
                minHeight={this.state.minHeight}
                maxWidth={this.state.maxWidth}
                maxHeight={this.state.maxHeight}
                keepSelection={true}
              />
            </div>
          </Modal>
        )}
      </Form.Item>
    );
  }
}

UploadCropField.defaultProps = {
  maxSize: 3145728, // 3mb (maxSize / 1024 / 1024)
  aspectRatio: 2.33,
  minWidth: 400,
  maxWidth: null,
  recommendedWidth: 1024,
  fileType: 'JPG, PNG',
  label: null,
  tooltip: false,
  colon: true,
  hasFeedback: false,
  required: false,
  requiredMessage: null,
  disabled: false,
  erasable: false,
  onChange: () => {}
};

UploadCropField.propTypes = {
  form: PropTypes.object.isRequired,
  fieldId: PropTypes.string.isRequired,
  fileType: PropTypes.string,
  handleCropConfirm: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  label: PropTypes.string,
  minWidth: PropTypes.number,
  disabled: PropTypes.bool
};

export default translate('UploadCropField', { withRef: true })(UploadCropField);

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { Form } from 'antd';

import dataFetcher from 'Parts/chore/dataFetcher';
import { searchPlugins } from '../../../search/searchActions';
import SurveyPage from './SurveyPage';
import {
  addSurveyInformations,
  updateSurveyInformations,
  fetchSurveyFields,
  addSurveyFields,
  fetchFields,
  fetchSurveyDetails,
  updateSurveyFields,
  deleteFieldsInformations,
  fetchCampaignInformations,
  fetchCampaignTicketInformations,
  removeCampaignTicketInformations,
  fetchSurveyResultDetails,
  updateTempSurveyFields
} from '../SurveyPage/SurveyPageActions';

const mapStateToProps = (state) => ({
  platform: get(state, 'platform.platform'),
  locale: get(state, 'session.language'),
  plugins: get(state, 'search.plugins.results', []),
  surveysFields: state.surveyPage.surveysFields,
  fields: state.surveyPage.fields,
  error: state.surveyPage.surveysFieldsUpdateError,
  surveyDetails: state.surveyPage.surveyDetails,
  resultDetails: state.surveyPage.resultDetails,
  campaignInfo: state.surveyPage.campaignInfo,
  campaignTicketInfo: state.surveyPage.campaignTicketInfo,
  user: state.session.user,
  addingSurveyInfo:state.surveyPage.addingSurveyInfo
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchPlugins,
      addSurveyInformations,
      fetchSurveyFields,
      addSurveyFields,
      fetchFields,
      updateSurveyFields,
      fetchSurveyDetails,
      updateSurveyInformations,
      deleteFieldsInformations,
      fetchCampaignInformations,
      fetchCampaignTicketInformations,
      removeCampaignTicketInformations,
      fetchSurveyResultDetails,
      updateTempSurveyFields
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher,
  Form.create()
)(SurveyPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import PublicTitleDropdown from './PublicTitleDropdownContainer';
import PublicCampaignBandMenu from './PublicCampaignBandMenuContainer';
import HelpText from './HelpText';

import { getCdnUrl } from 'Parts/common/environment';

import PublicCampaignBand_en from './locales/PublicCampaignBand_en.json';
import PublicCampaignBand_fr from './locales/PublicCampaignBand_fr.json';

import './PublicCampaignBand.less';

class PublicCampaignBand extends React.Component {
  constructor(props) {
    super(props);

    this.menu = <PublicCampaignBandMenu campaign={props.campaign} locale={props.locale} />;

    i18next.addResourceBundle('en', 'PublicCampaignBand', PublicCampaignBand_en);
    i18next.addResourceBundle('fr', 'PublicCampaignBand', PublicCampaignBand_fr);
  }

  render() {
    const { t, locale, campaign } = this.props;

    const campaignBanner = get(campaign, 'media.banner.banner', null);

    return (
      <React.Fragment>
        <div className="PublicCampaignBand">
          <div className="PublicCampaignBand__Container">
            <div className="FundkyWrap">
              <Link
                className="PublicCampaignBand__MenuLink"
                to={`/${locale}/${this.props.campaign.alias}`}
              >
                <FontAwesomeIcon
                  className="PublicCampaignBand__BackIcon"
                  icon={['fal', 'arrow-left']}
                  size="lg"
                />
              </Link>
              <div
                className="PublicCampaignBand__Avatar"
                style={{
                  backgroundImage: `url(${getCdnUrl()}${campaignBanner})`,
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              />
              <div className="PublicCampaignBand__Campaigns">
                <HelpText>{t('campaign-label')}</HelpText>
                <PublicTitleDropdown
                  helpLabel={t('last-campaigns-label')}
                  title={this.props.campaign.name}
                  entities={this.props.lastCampaigns.filter(
                    campaign => campaign.id !== this.props.campaign.id
                  )}
                  getEntityLinkUrl={entity => `/${locale}/${entity.alias}`}
                  additionalLinks={
                    <Link to={`/${locale}/${t('URL:create-a-campaign')}`}>
                      <FontAwesomeIcon icon={['fal', 'arrow-right']} size="lg" />
                      {t('create-campaign')}
                    </Link>
                  }
                />
              </div>
              <Dropdown overlay={this.menu} placement="bottomRight" trigger={['click']}>
                <Button className="PublicCampaignBand__AboutButton" type="default" size="large">
                  <span>{t('about')}</span>
                  <FontAwesomeIcon
                    className="PublicCampaignBand__EllipsisIcon"
                    icon={['far', 'ellipsis-v']}
                    size="lg"
                  />
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="PublicCampaignBand__WaveBorder"></div>
      </React.Fragment>
    );
  }
}

PublicCampaignBand.propTypes = {
  campaign: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  lastCampaigns: PropTypes.array.isRequired,
  locale: PropTypes.oneOf(['fr', 'en']).isRequired
};

export default translate('PublicCampaignBand')(PublicCampaignBand);

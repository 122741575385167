import { compose } from "redux";
import { connect } from "react-redux";
import { get } from 'lodash';

import SocialSharing from './SocialSharing';

const mapStateToProps = state => ({
  organizationName: state.organization.organization.name,
  platformAlias: state.platform.platform.alias,
  campaignName: state.campaign.campaign.name,
  teamName: state.team.team.name,
  participantName: get(state.participant.participant, 'member.name', ''),
  //checkoutTarget: state.donation && state.donation.checkoutInformation && state.donation.checkoutInformation.target || null
});

export default compose(
  connect(mapStateToProps),
)(SocialSharing);

import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty, pickBy, get, isEqual } from 'lodash';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import moment from 'moment';

import SettingsColumn from '../../../components/SettingsColumn';
import LanguageBoxForm from '../../../components/LanguageBoxFormContainer';
import ParticipantPageUrlField from '../../components/fields/ParticipantPageUrlField';
import ParticipantTeamField from '../../components/fields/ParticipantTeamFieldContainer';
import MoneyField from 'Parts/ui/components/fields/MoneyField';
import UploadCropField from 'Parts/ui/components/fields/UploadCropField';
import Wysiwyg, { wrapText } from 'Parts/ui/components/fields/Wysiwyg';
import CancelAndSaveButtons from '../../../components/CancelAndSaveButtons';
import FakeField from 'Parts/ui/components/fields/FakeField';

import { getLanguageNameByKey, getDefaultLanguageKey } from 'Parts/common/langUtils';
import { getCdnUrl } from 'Parts/common/environment';
import { facebookScrape } from 'Parts/common/requestUtils';
import { getPlatformUrl } from 'Parts/common/environment';
import { moneyFormat } from 'Parts/common/moneyFormat';

import CampaignsParticipantsPageForm_en from './locales/CampaignsParticipantsPageForm_en.json';
import CampaignsParticipantsPageForm_fr from './locales/CampaignsParticipantsPageForm_fr.json';

import './CampaignsParticipantsPageForm.less';

class CampaignsParticipantsPageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang:
        getDefaultLanguageKey(get(this.props, 'participant.description')) ||
        getDefaultLanguageKey(get(this.props, 'languages')) ||
        this.props.locale,
      avatar: null,
      removeImage: false,
      disabledUnilangualFields: false
    };

    this.changeHasHappened = false;

    this.WysiwygRef = React.createRef();
    this.UploadCropFieldRef = React.createRef();

    i18next.addResourceBundle(
      'en',
      'CampaignsParticipantsPageForm',
      CampaignsParticipantsPageForm_en
    );
    i18next.addResourceBundle(
      'fr',
      'CampaignsParticipantsPageForm',
      CampaignsParticipantsPageForm_fr
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.props.form.validateFields({ force: true });
    }

    if (
      isEmpty(get(prevProps, 'participant.description')) &&
      !isEqual(
        get(prevProps, 'participant.description'),
        get(this.props, 'participant.description')
      )
    ) {
      this.handleContentLanguageChange(
        getDefaultLanguageKey(get(this.props, 'participant.description')) ||
          getDefaultLanguageKey(get(this.props, 'languages')) ||
          this.props.locale
      );
      this.props.form.resetFields();
    }

    if (
      this.props.participant.campaignId &&
      (isEmpty(this.props.campaign) ||
        this.props.campaign.id !== this.props.participant.campaignId) &&
      !this.loading &&
      !this.search
    ) {
      this.loading = true;
      this.props.clearCampaign();
      this.props
        .fetchCampaign(this.props.participant.campaignId, false, null, { showSettings: true })
        .then(() => {
          this.loading = false;
        });
      this.search = true;
      this.props.clearSearch();
      this.props.searchTeamsInCampaign(this.props.participant.campaignId, null, null, {
          pageResults: 99999
        })
        .then(() => {
          this.search = false;
        });
    } else
    if (
      get(this.props, 'participant.campaignId') &&
      get(this.props, 'campaign.id', null) === get(this.props, 'participant.campaignId') &&
      isEmpty(get(this.props, 'teams', {})) &&
      !this.search
    ) {
      this.search = true;
      this.props.clearSearch();
      this.props.searchTeamsInCampaign(this.props.participant.campaignId, null, null, {
          pageResults: 99999
        })
        .then(() => {
          this.search = false;
        });
    }
  }

  handleDelete = () => {
    this.setState({
      avatar: null,
      removeImage: true
    });
  };

  handleCropComplete = avatar => {
    this.setState({
      avatar: avatar,
      removeImage: null
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.changeHasHappened = false;

    const { props, state } = this,
      { form, platformAlias, campaign, teams, updateParticipant } = props,
      { removeImage, avatar } = state,
      { validateFields, isFieldTouched } = form;
    const participant =
      props.participant && !isEmpty(props.participant) ? props.participant : props.participation;

    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        const defaultLang = get(
          participant,
          `description[${getLanguageNameByKey(this.state.contentLang, false)}].default`,
          0
        )
          ? true
          : false;

        let file, values;
        if (Object.keys(updatedValues) && Object.keys(updatedValues).length > 0) {
          file = avatar ? { file: avatar } : removeImage ? { file: null } : null;
          values = {
            ...updatedValues,
            ...(updatedValues.participant_description ? { default: defaultLang } : {}),
            ...file
          };

          updateParticipant(values, participant.id, this.state.contentLang);
        } else if (removeImage) {
          file = { file: null };
          values = { ...file };

          updateParticipant(values, participant.id, this.state.contentLang);
        }

        if (get(updatedValues, 'participant_team') || file !== null) {
          const platformUrl = getPlatformUrl(platformAlias),
            campaignUrl = participant.campaignAlias;

          let teamUrl = '';
          const urlToScrape = [];

          if (campaign.typeId == 2 || campaign.typeId == 3) {
            if (updatedValues.participant_team) {
              let currentTeamArray = teams.results.find(
                team => team.id === updatedValues.participant_team
              );
              teamUrl = currentTeamArray.alias;
            } else {
              teamUrl = participant.teamAlias;
            }
            if (teamUrl) {
              urlToScrape.push(
                `${platformUrl}/en/${campaignUrl}/${teamUrl}/${participant.member.alias}`,
                `${platformUrl}/fr/${campaignUrl}/${teamUrl}/${participant.member.alias}`
              );
            } else {
              urlToScrape.push(
                `${platformUrl}/en/${campaignUrl}/individual-participant/${participant.member.alias}`,
                `${platformUrl}/fr/${campaignUrl}/participant-individuel/${participant.member.alias}`
              );
            }
          } else {
            urlToScrape.push(
              `${platformUrl}/en/${campaignUrl}/individual-participant/${participant.member.alias}`,
              `${platformUrl}/fr/${campaignUrl}/participant-individuel/${participant.member.alias}`
            );
          }

          if (urlToScrape.length) {
            facebookScrape(urlToScrape);
          }
        }
      }
    });
  };

  handleContentLanguageChange = (value, _default) => {
    const defaultLangKey =
      getDefaultLanguageKey(get(this.props, 'participant.description')) ||
      getDefaultLanguageKey(get(this.props, 'languages')) ||
      this.props.locale;
    this.setState(
      {
        contentLang: value,
        disabledUnilangualFields: defaultLangKey != value
      },
      () => (this.changeHasHappened = false)
    );
  };

  checkForChange = value => {
    if (this.props.form.isFieldTouched('participant_description')) this.changeHasHappened = true;
  };

  handleCancel = () => {
    this.changeHasHappened = false;
    this.props.form.resetFields();
    this.WysiwygRef.current.getWrappedInstance('WysiwygRef').resetEditorState();
    this.UploadCropFieldRef.current.getWrappedInstance('UploadCropField').resetField();
    this.setState({
      avatar: null,
      removeImage: false
    });
  };

  getMessage = lang => {
    const lng = getLanguageNameByKey(lang, false);
    const participant =
      this.props.participant && !isEmpty(this.props.participant)
        ? this.props.participant
        : this.props.participation;
    return get(participant, `description[${lng}].description`, '');
  };

  render() {
    const {
        props,
        state,
        WysiwygRef,
        handleDelete,
        handleCropComplete,
        handleSubmit,
        handleCancel,
        handleContentLanguageChange,
        getMessage
      } = this,
      {
        t,
        form,
        platformAlias,
        campaign,
        teams,
        locale,
        match,
        location,
        platform,
        checkAuthorization,
        userRoleId
      } = props,
      { contentLang } = state;

    const participant =
      props.participant && !isEmpty(props.participant) ? props.participant : props.participation;

    const isAdmin = checkAuthorization({ platform: platform.id, campaign: campaign.id }, [
      ['roles.campaign.OWNER'],
      ['roles.campaign.SUPERADMIN'],
      ['roles.platform.OWNER']
    ]);
    const currentTeam =
      participant && teams && teams.results && teams.results.length
        ? teams.results.filter(team => team.id === participant.teamId)
        : {};
    const goal = (participant && participant.settings && participant.settings.goal) || 0;
    const message = getMessage(contentLang);

    // If campaign is closed/declined or if participant is declined
    const disableFields = get(campaign, 'statusId', 0) > 1 || get(participant, 'statusId', 0) === 3;
    const isCurrentParticipantTeamLeader =
      Array.isArray(currentTeam) &&
      currentTeam.length > 0 &&
      currentTeam[0].leaderId === participant.id;
    const isFromParticipations = location.pathname.indexOf('/d/contributions/participations/') >= 0;
    const team =
      !isEmpty(currentTeam[0]) &&
      participant &&
      campaign.typeId &&
      (campaign.typeId == 2 || campaign.typeId == 3) ? (
        <ParticipantTeamField
          form={form}
          data={currentTeam[0]}
          campaignId={participant.campaignId}
          campaignTypeId={campaign.typeId}
          choices={teams.results}
          admin={isAdmin}
          // Disabled ißf coming from participations or if you aren't an admin or if campaign/participant === closed || declined
          disabled={
            isFromParticipations || !isAdmin || disableFields || this.state.disabledUnilangualFields
          }
          isCaptain={isCurrentParticipantTeamLeader}
          match={match}
        />
      ) : null;

    const allowCustom = get(campaign, 'settings.participant.custom.goal', null) == 1 ? true : false;
    const collectedPercent =
      (participant && parseInt(Math.round(((Number(get(participant, 'collected', 0).toString().replace('NaN', 0)) * 100) / goal) * 100)) / 100) ||
      0;
    const waitParticipant =
      !participant || get(match, 'params.participantID', null) != participant.id;

    return (
      <div className="AdminFormWrap">
        <Form className="CampaignsParticipantsPageForm" onSubmit={handleSubmit}>
          {participant && !waitParticipant && (
            <div className="inputs">
              <ParticipantPageUrlField
                data={get(participant, 'member.alias', '')}
                locale={locale}
                platformAlias={platformAlias}
                campaignAlias={participant.campaignAlias}
                teamAlias={participant.teamAlias}
                disabled={disableFields}
              />
              {team}
              <FakeField
                data={t(participant.status)}
                label={t('Status')}
                className="participant-status"
              />
              <FakeField
                data={moment(participant.addedOn).format('LLLL')}
                label={t('Registration date')}
                className="registration-date"
              />
              <MoneyField
                form={form}
                fieldId="participant_goal"
                displayAsText={!allowCustom}
                initialValue={goal}
                locale={locale}
                width={200}
                label={t('goal-label')}
                required={true}
                requiredMessage={t('goal-required')}
                wholeNumber={true}
                wholeNumberMessage={t('goal-whole-number')}
                minimum={5}
                minimumMessage={t('goal-minimum', { min: 5 })}
                invalidMessage={t('goal-invalid')}
                disabled={disableFields || this.state.disabledUnilangualFields}
              />
              <FakeField
                data={`${moneyFormat(Number(get(participant, 'collected', 0).toString().replace('NaN', 0)), locale)}  (${collectedPercent}%)`}
                label={t('Amount raised')}
                className="amount-collected"
              />
              <UploadCropField
                ref={this.UploadCropFieldRef}
                form={form}
                fieldId="participant_avatar"
                initialValue={participant.avatar ? getCdnUrl() + participant.avatar : null}
                label={t('avatar')}
                icon={['fal', 'image']}
                uploadTitle={t('profile-picture')}
                aspectRatio={1}
                minWidth={400}
                recommendedWidth={400}
                handleCropConfirm={handleCropComplete}
                handleDelete={handleDelete}
                disabled={disableFields || this.state.disabledUnilangualFields}
                erasable={true}
              />
              <Wysiwyg
                form={form}
                fieldId="participant_description"
                ref={WysiwygRef}
                label={t('description.label')}
                tooltip={t('description.tooltip')}
                initialValue={wrapText(message)}
                maxlength={1000}
                counterWithTag={false}
                required={false}
                accentColors={this.props.accentColors}
                allowHeadings={false}
                allowAlignments={false}
                allowLinks={false}
                allowLists={false}
                allowHTML={userRoleId === 1}
                disabled={disableFields}
                onChange={this.checkForChange}
                contentLang={contentLang}
              />
            </div>
          )}

          {!disableFields && <CancelAndSaveButtons onClickCancel={handleCancel} />}
        </Form>
        <SettingsColumn>
          <LanguageBoxForm
            handleContentLanguageChange={handleContentLanguageChange}
            changeHasHappened={this.changeHasHappened}
            defaultLanguage={
              getDefaultLanguageKey(get(this.props, 'participant.description')) ||
              getDefaultLanguageKey(get(this.props, 'languages')) ||
              this.props.locale
            }
            disabled={disableFields}
          />
        </SettingsColumn>
      </div>
    );
  }
}

CampaignsParticipantsPageForm.defaultProps = {
  teams: []
};

CampaignsParticipantsPageForm.propTypes = {
  form: PropTypes.object.isRequired
};

export default translate('CampaignsParticipantsPageForm')(CampaignsParticipantsPageForm);

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { get } from 'lodash';

import HeadMetas from '../../common/HeadMetasContainer';
import PublicCarousel from '../../common/PublicCarousel';
import PresentationSection from '../components/PresentationSection';
import CampaignSection from '../components/CampaignSectionContainer';
import FeaturedCampaignsSection from '../components/FeaturedCampaignsSectionContainer';
import DonationSection from '../components/DonationSectionContainer';

import { getCdnUrl } from 'Parts/common/environment';

import { CONNECTION_MODAL_RESET, CONNECTION_MODAL_CLOSED } from 'Parts/ui/uiUtils';

import './HomePage.less';

import '../../assets/images/dummy_banner.png';

class HomePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.modal = props.location.pathname.split('/')[2];
  }

  componentDidMount() {
    if (
      this.modal &&
      this.modal !== this.props.t('URL:registration-confirm') &&
      this.props.userTypeId > 1
    ) {
      this.props.history.push(`/${this.props.locale}`);
    } else if (this.modal) {
      switch (this.modal) {
        case this.props.t('URL:login'):
          if (this.props.connectionModalState === CONNECTION_MODAL_CLOSED) {
            let message = null;
            if (this.props.history.location.search != '') {
              let query = new URLSearchParams(this.props.history.location.search);
              if (query.get('error') === 'twitch') {
                message = {
                  title: this.props.t('SESSION:LOGIN.twitch-check-email.title'),
                  description: this.props.t('SESSION:LOGIN.twitch-check-email.description'),
                  type: 'info'
                };
              } else
              if (query.get('error') === 'access_denied') {
                message = {
                  title: this.props.t('SESSION:LOGIN.twitch-access-denied.title'),
                  description: this.props.t('SESSION:LOGIN.twitch-access-denied.description'),
                  type: 'error'
                };
              } else
              if (query.get('error') === 'FK-MEM-1002') {
                message = {
                  title: this.props.t('SESSION:LOGIN.twitch-link-fail.title'),
                  description: this.props.t('SESSION:LOGIN.twitch-link-fail.description'),
                  type: 'error'
                };
              } else
              if (query.get('error') === 'FK-TWT-1000' || query.get('error') === 'FK-TWT-1001' || query.get('error') === 'FK-TWT-1002' || query.get('error')) {
                message = {
                  title: this.props.t('SESSION:LOGIN.twitch-error.title'),
                  description: this.props.t('SESSION:LOGIN.twitch-error.description'),
                  type: 'error',
                  only: true
                };
              }
            }
            this.props.openLoginModal(true, message);
          }
          break;
        case this.props.t('URL:create-an-account'):
          if (this.props.connectionModalState === CONNECTION_MODAL_CLOSED) {
            this.props.openRegisterModal(true);
          }
          break;
        case this.props.t('URL:account-recovery'):
          if (this.props.connectionModalState === CONNECTION_MODAL_CLOSED) {
            this.props.openRecoveryModal(true);
          }
          break;
        case this.props.t('URL:reset-password'):
          if (this.props.connectionModalState !== CONNECTION_MODAL_RESET) {
            this.props.openResetModal(true);
          }
          break;
        case this.props.t('URL:registration-confirm'):
          if (this.props.connectionModalState !== CONNECTION_MODAL_CLOSED) {
            this.props.openConfirmationModal(true);
          }
          break;
      }
    }

    const query = window.location.search
    if(query){
      const params = new URLSearchParams(query)
      const scrollTarget = params.get("scroll")
      if(scrollTarget){
        const target = document.getElementById(scrollTarget);
        if(target){
          const targetPosition = target.getBoundingClientRect().top - 90; 
          window.scrollTo({ 
            top: targetPosition + window.pageYOffset, 
          });
        }
       
      }
    }
  }

  render() {
    const { locale, platform } = this.props;
    const cdnUrl = getCdnUrl();
    const banners = get(platform, 'media.banners', []);

    let key = 0;
    let carouselOptions;

    if (banners.length > 0) {
      if (banners.length === 1) {
        carouselOptions = {
          dragging: false,
          withoutControls: true
        };
      } else {
        carouselOptions = {
          autoplay: true,
          autoplayInterval: 5000,
          centerControls: true,
          wrapAround: true
        };
      }
    }

    const carousel =
      banners.length > 0 ? (
        <PublicCarousel className="HomePage__Carousel" {...carouselOptions}>
          {banners.map(banner => {
            return banner.link ? (
              <a key={++key} href={banner.link} target="_blank" rel="noopener noreferrer">
                <img alt={banner.alt} title={banner.alt} src={`${cdnUrl}${banner.banner}`} />
              </a>
            ) : (
              <img
                key={++key}
                alt={banner.alt}
                title={banner.alt}
                src={`${cdnUrl}${banner.banner}`}
              />
            );
          })}
        </PublicCarousel>
      ) : null;

    return (
      <div className="HomePage">
        <HeadMetas />
        {carousel}
        <PresentationSection
          locale={locale}
          platform={this.props.platform}
          campaigns={this.props.campaigns || []}
          isBillingConfigured={this.props.isBillingConfigured}
        />
        {this.props.featuredCampaigns && this.props.featuredCampaigns.length > 0 ? (
          <FeaturedCampaignsSection isBillingConfigured={this.props.isBillingConfigured} />
        ) : this.props.lastCampaigns && this.props.lastCampaigns.length > 0 ? (
          <CampaignSection isBillingConfigured={this.props.isBillingConfigured} />
        ) : null}
        <DonationSection type="platform" isBillingConfigured={this.props.isBillingConfigured} />
      </div>
    );
  }
}

HomePage.defaultProps = {
  banners: null,
  isBillingConfigured: false
};

HomePage.propTypes = {
  banners: PropTypes.object
};

export default translate()(HomePage);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input } from 'antd';
// import includes from 'lodash/includes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { PROVINCE_MANDATORY_COUNTRIES } from '../../../geo/geoUtils';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import ProvinceField_en from './locales/ProvinceField_en.json';
import ProvinceField_fr from './locales/ProvinceField_fr.json';

const { Option } = Select;

class ProvinceField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      country: props.form.getFieldValue('country'),
    }

    i18next.addResourceBundle('en', 'ProvinceField', ProvinceField_en);
    i18next.addResourceBundle('fr', 'ProvinceField', ProvinceField_fr);
  }

  render() {
    const { t, locale, form, className } = this.props;
    const { getFieldDecorator } = form;

    // We can only translate in EN or FR so we put english by default
    const lng = locale === 'en' || locale === 'fr' ? locale : 'en';

    const country = this.props.form.getFieldValue('country');
    const provinces = this.props.provinces && this.props.provinces[country] ? this.props.provinces[country][lng] : [];

    const placeholder = (
      this.props.placeholder === true && this.props.label ?
        t('placeholder') :
        this.props.placeholder === true && country === "CA" ?
          t('province') :
          this.props.placeholder === true && country === "US" ?
            t('state') :
            this.props.placeholder === true ?
              t('state-province-region') :
              this.props.placeholder || null
    );

    const requiredMessage = (
      this.props.requiredMessage ?
        this.props.requiredMessage :
        country === "CA" ?
          (
            this.props.identity === 'user' ?
              t('required-province') :
              this.props.identity === 'company' ?
                t('required_company-province') :
                this.props.identity === 'organization' ?
                  t('required_organization-province') : null
          ) :
          country === "US" ?
            (
              this.props.identity === 'user' ?
                t('required-state') :
                this.props.identity === 'company' ?
                  t('required_company-state') :
                  this.props.identity === 'organization' ?
                    t('required_organization-state') : null
            ) :
            (
              this.props.identity === 'user' ?
                t('required-state-province-region') :
                this.props.identity === 'company' ?
                  t('required_company-state-province-region') :
                  this.props.identity === 'organization' ?
                    t('required_organization-state-province-region') : null
            )
    );

    const input = (
      country === "CA" || country === "US" ? (
        <Select
          showSearch
          optionFilterProp="children"
          placeholder={placeholder}
          className="ProvinceField-Select"
          disabled={this.props.disabled}
          autoComplete="new-password"
          suffixIcon={ country === "CA" || country === "US" ? (<FontAwesomeIcon icon={['fas', 'caret-down']} />) : false}
          dropdownClassName={this.props.dropdownClassName}
        >
          {provinces.sort((a, b) => a.localeCompare(b)).map(province => <Option key={province} value={province}>{province}</Option>)}
        </Select>
      ) : (
        <Input placeholder={!this.props.label ? placeholder : null} disabled={this.props.disabled} autoComplete="new-password" />
      )
    );

    const fieldId = 'province';
    const fieldOptions = {
      rules: [
          {
            required: true,//this.props.required, // || includes(PROVINCE_MANDATORY_COUNTRIES, country)
            message: requiredMessage
          }
        ],
      initialValue: this.props.initialValue || undefined,
      onChange: this.props.onChange || undefined
    };

    const label = (
      this.props.label === true && country === "CA" ?
        t('province') :
        this.props.label === true && country === "US" ?
          t('state') :
          this.props.label === true ?
            t('state-province-region') :
            this.props.label || null
    );

    return (
      <Form.Item
        className={`ProvinceField ${className}`}
        hasFeedback
        label={label}
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

ProvinceField.defaultProps = {
  label: true,
  placeholder: true,
  requiredMessage: null,
  required: false,
  initialValue: undefined,
  identity: 'user',
  disabled: false,
  dropdownClassName: null,
}

ProvinceField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  provinces: PropTypes.object.isRequired,
  identity: PropTypes.oneOf(['user', 'company', 'organization']),
};

export default translate('ProvinceField')(ProvinceField);

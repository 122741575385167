import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Divider, Button, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import PublicThermometer from '../../common/PublicThermometer';
import PublicCarousel from '../../common/PublicCarousel';
import { isDateAfterCurrentDate } from 'Parts/common/dateUtils';

import FeaturedCampaignsSection_en from './locales/FeaturedCampaignsSection_en.json';
import FeaturedCampaignsSection_fr from './locales/FeaturedCampaignsSection_fr.json';

import './CampaignSection.less';

class FeaturedCampaignsSection extends React.Component {

  constructor(props) {
    super(props);

    // [REMAX]
    // - Hide "Contribute" button
    this.exceptions_REMAX = [
      //{environment: "development", organizationId: 40, platformId: 66, campaignId: 405},
      //{environment: "preproduction", organizationId: 26, platformId: 27, campaignId: 386},
      {environment: "production", organizationId: 75, platformId: 128, campaignId: 608},
      {environment: "production", organizationId: 75, platformId: 128, campaignId: 609},
      {environment: "production", organizationId: 75, platformId: 128, campaignId: 610},
    ];

    this.state = {
      participationAvailability: this.getParticipationAvailability(this.props.campaigns),
      isException_REMAX: this.checkExceptions(this.exceptions_REMAX, this.props.platform)
    };

    i18next.addResourceBundle('en', 'FeaturedCampaignsSection', FeaturedCampaignsSection_en);
    i18next.addResourceBundle('fr', 'FeaturedCampaignsSection', FeaturedCampaignsSection_fr);
  }

  componentDidMount() {
    this.setState({
	  participationAvailability: this.getParticipationAvailability(this.props.campaigns),
      isException_REMAX: this.checkExceptions(this.exceptions_REMAX, this.props.platform)
    });
  }

  componentDidUpdate(prevProps) {
	if (get(prevProps,'campaigns.length', 0) !== get(this.props, 'campaigns.length', 0)) {
      this.setState({
        participationAvailability: this.getParticipationAvailability(this.props.campaigns)
	  });
    }

    if (prevProps.platform.id !== this.props.platform.id) {
      this.setState({
        isException_REMAX: this.checkExceptions(this.exceptions_REMAX, this.props.platform)
      });
    }
  }


  getParticipationAvailability = (campaigns) => {
    if(get(campaigns, 'length', 0)) {
      let filteredCampaigns = campaigns.filter(campaign => {
        let available = false;
        if (
          campaign.statusId !== 4 &&
          campaign.typeId !== 4 &&
          !campaign.participating &&
          (
            !get(campaign, 'settings.registration.disabledOn') ||
            get(campaign, 'settings.registration.disabledOn') &&
            isDateAfterCurrentDate(campaign.settings.registration.disabledOn, true)
          )
        ) {
          const { typeId, settings, childrenCount } = campaign;
          const participantLimit = parseInt(get(settings, 'participant.limit', 0)) || null;
          const participantCount = get(childrenCount, 'participants') || null;
          const teamLimit = parseInt(get(settings, 'team.limit', 0)) || null;
          const teamCount = get(childrenCount, 'teams') || null;

          switch (typeId) {
            case 1: //SOLO
              if (
                participantCount &&
                participantLimit &&
                participantCount < participantLimit
              ) {
                available = true;
              }
              break;
            case 2: //TEAM
              if (
                teamLimit &&
                participantLimit &&
                participantCount &&
                participantCount < participantLimit * teamLimit
              ) {
                available = true;
              }
              break;
            case 3: //MIXED
              available = true;
              break;
          }
        }
        return available;
      });

      if(filteredCampaigns.length) {
        return true;
      }
    }
    return false;
  };

  checkExceptions = (exceptions, entity) => {
    let environment = ENVIRONMENT;
    let organizationId = entity.organization.id;
    let platformId = entity.id;

    return exceptions.filter(exc => {
      return exc.environment === environment &&
        exc.organizationId === organizationId &&
        exc.platformId === platformId
    }).length ? true : false;
  };

  render() {
    const { t, locale, featuredCampaigns, featuredTitle, featuredDescription, slidesToShow } = this.props;
    const { participationAvailability } = this.state;

    const thermometers = featuredCampaigns.map(featuredCampaign => {
      const settingsThermometer = get(featuredCampaign, 'settings.thermometer.behavior');
      const showThermometer = settingsThermometer === 'all' || settingsThermometer === 'parent';
      return (
        <Link key={featuredCampaign.id} to={`/${locale}/${featuredCampaign.alias}`} title={featuredCampaign.name}>
          <PublicThermometer className="CampaignSection__Thermometer" locale={locale} entity={featuredCampaign} showThermometer={showThermometer} isBillingConfigured={this.props.isBillingConfigured} />
        </Link>
      )
    });

    const carousel = thermometers.length > 0 ? (
      <PublicCarousel
        className={classnames('CampaignSection__Carousel', { OneCampaign: thermometers.length === 1 })}
        slidesToShow={slidesToShow}
        initialSlideWidth={200}
        sideControls
        delayInit //This is to fix the next arrow issue (SSR)
        cellAlign={thermometers.length === 1 || slidesToShow === 1 ? 'center' : 'left'}
      >
        {thermometers}
      </PublicCarousel>
    ) : null;

    const contribute = thermometers.length > 0 ? (
      <Menu className="menu-contribute">
        {this.props.isBillingConfigured &&
          <Menu.Item>
            <Link to={{pathname: `/${locale}/${t('URL:give')}`, state: {campaign: this.props.campaign}}}>
              {t('make-donation')}
            </Link>
          </Menu.Item> ||
          null
        }
        {participationAvailability &&
          <Menu.Item>
            <Link to={{pathname: `/${locale}/${t('URL:participant-registration')}`, state: {campaign: this.props.campaign}}}>
              {t('register')}
            </Link>
          </Menu.Item> ||
          null
        }
        <Menu.Item>
          <Link to={{pathname: `/${locale}/${t('URL:search/campaigns')}`}}>
            {t('view-campaigns')}
          </Link>
        </Menu.Item>
      </Menu>
    ) : null;

    const dropdownContribute = !this.state.isException_REMAX && thermometers.length > 0 ? (
      <Dropdown overlay={contribute} trigger={['click']}>
        <Button>{t('contribute')}</Button>
      </Dropdown>
    ) : null;

    return (
     <div id="myCampaigns" className="CampaignSection">
        <div className="FundkyWrap">
          <div className="CampaignSection__Information">
            <h2>{featuredTitle || t('featured-campaigns')}</h2>
            <Divider />
            <p>
              {featuredDescription || t('featured-campaigns-description')}
            </p>
            {dropdownContribute}
          </div>
          {carousel}
        </div>
      </div>
    );
  }

}

PropTypes.propTypes = {
  slidesToShow: PropTypes.number.isRequired,
  lastCampaigns: PropTypes.array.isRequired
};

export default translate('FeaturedCampaignsSection')(FeaturedCampaignsSection);

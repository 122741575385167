import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import classnames from 'classnames';

import FundkyTooltip from '../FundkyTooltip';

import TextAreaField_en from './locales/TextAreaField_en.json';
import TextAreaField_fr from './locales/TextAreaField_fr.json';

import './TextAreaField.less';

class TextAreaField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TextAreaField', TextAreaField_en);
    i18next.addResourceBundle('fr', 'TextAreaField', TextAreaField_fr);

    let length = props.initialValue ? props.initialValue.length : 0;
    let lengthPercent = length && props.maxlength ? length / props.maxlength * 100 : 0;

    this.state = {
      lengthPercent: lengthPercent,
      radius: (
        lengthPercent >= 100 ?
          'radius2' :
          lengthPercent > 0 ?
            'radius3' : 'radius4'
      )
    };
  }

  componentDidUpdate(prevProps) {
    // Reset form if initialValue/contentLang changes
    if (prevProps.initialValue !== this.props.initialValue || prevProps.contentLang !== this.props.contentLang) {
      this.props.form.resetFields([this.props.fieldId]);
      this.handleCounterReset();
    }
  }

  handleCounterReset() {
    if (this.props.maxlength) {
      let fieldValue = this.props.form.getFieldValue(this.props.fieldId);
      let length = fieldValue ? fieldValue.length : null;
      let lengthPercent = length && this.props.maxlength ? length / this.props.maxlength * 100 : 0;
      this.setState({
        lengthPercent: lengthPercent,
        radius: (
          lengthPercent >= 100 ?
            'radius2' :
            lengthPercent > 0 ?
              'radius3' : 'radius4'
        ),
      });
    }
  }

  handleTextAreaChange = e => {
    const value = e.target.value;

    if (this.props.maxlength) {
      let length = value.length;
      let lengthPercent = length && this.props.maxlength ? length / this.props.maxlength * 100 : 0;

      this.setState({
        lengthPercent: lengthPercent,
        radius: (
          lengthPercent >= 100 ?
            'radius2' :
            lengthPercent > 0 ?
              'radius3' : 'radius4'
        ),
      });
    }
  }

  resetTextAreaField = () => {
    this.props.form.resetFields(this.props.fieldId);
    this.setState({
      lengthPercent: 0,
      radius: 'radius4',
    });
  };

  getFailedChars = value => {
    if (!value) value = this.props.form.getFieldValue(this.props.fieldId);
    return value && value.replace(/[a-zA-Z\u0020-\u0039\u00C0-\u024F\u2018\u2019\u2013\u00ab\u00bb\u00a9\u00b0\u00b1\u00ae\u2015\u221e\u00a1\u00bf\u2014\u201c\u201d\u00ab\u00bb\u2014\u2122\u00a2~:;_='@?{}\r\n]+/g, '') || '';
  };

  formatFailedChars = chars => {
    const arr = chars.split('');
    chars = arr.filter((char, index) => arr.indexOf(char) === index).join(' ');
    return chars;
  }

  render() {
    const { t, form, fieldId, initialValue, label, tooltip, placeholder, identity, maxlength, autoSize, required, requiredMessage, className, hasFeedback, colon, onChange } = this.props;
    const { getFieldDecorator } = form;

    const fieldPlaceholder = (
      placeholder === true ?
        t('comment') :
        placeholder || null
    );

    const input = <Input.TextArea className={this.state.radius} autoSize={autoSize} placeholder={fieldPlaceholder} maxLength={maxlength} disabled={this.props.disabled} />;
    const maximum = new RegExp(`^([\\s\\S.]){0,${maxlength || 255}}$`);
    const fieldOptions = {
      initialValue: initialValue,
      validateTrigger: ['onChange', 'onKeyUp'],
      rules: [
        {
          required: required || false,
          message: requiredMessage || t('required')
        },
        {
          validator: (rule, value, callback) => this.getFailedChars(value).length ? callback(true) : callback(),
          message: t('validation', { chars: this.formatFailedChars(this.getFailedChars()) })
        },
        {
          pattern: maximum,
          message: t('maximum', { max: maxlength || 255 })
        }
      ],
      onChange: (e) => {
        if (onChange) onChange(e);
        this.handleTextAreaChange(e);
        return;
      },
    };

    const counterBar = maxlength ?
      <div className="TextAreaField__CounterBarContainer">
        <div className="TextAreaField__CounterBar" style={{ width: this.state.lengthPercent + '%' }}>
        </div>
      </div>
      : null;

    const labelText = (
      label && label !== true ?
        label :
        label === true ? (
          identity === 'message' ?
            t('message') :
            identity === 'campaign' ?
              t('campaign') :
              identity === 'team' ?
                t('team') :
                identity === 'participant' ?
                  t('participant') : null
        ) : null
    );

    const tooltipText = (labelText ?
      (tooltip && tooltip !== true) ?
        tooltip :
        tooltip === true ? (
          identity === 'message' ?
            t('message-tooltip') :
            identity === 'campaign' ?
              t('campaign-tooltip') :
              identity === 'team' ?
                t('team-tooltip') :
                identity === 'participant' ?
                  t('participant-tooltip') : null
        ) : null
      : null);

    const fieldLabel = label && tooltip ?
      (
        <span>
          {labelText || null}
          {
            tooltip ? (
              <FundkyTooltip title={tooltipText} />
            ) : null
          }
        </span>
      ) : (
        labelText || null
      );

    return (
      <Form.Item
        className={classnames('TextAreaField', className)}
        label={fieldLabel}
        hasFeedback={hasFeedback}
        colon={colon}
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
        {counterBar}
      </Form.Item>
    );
  }

}

TextAreaField.defaultProps = {
  label: false,
  placeholder: true,
  maxlength: null,
  hasFeedback: false,
  required: false,
  initialValue: undefined,
  fieldId: 'message',
  autoSize: ({
    minRows: 4,
  }),
  colon: true,
  identity: 'message',
  tooltip: false,
  disabled: false,
};

TextAreaField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  maxlength: PropTypes.number,
  hasFeedback: PropTypes.bool,
  identity: PropTypes.oneOf(['message', 'donation', 'campaign', 'team', 'participant']),
};

export default translate('TextAreaField', { withRef: true })(TextAreaField);
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { clearReport } from '../../report/reportActions';

import CsvReportButton from './CsvReportButton';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearReport
 }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CsvReportButton);

import plugins from './plugins.json';

// export const getPlatformPluginsList = (platform = null) => {
//   return platform.plugins || {};
// };

// export const selectPlugin = (name = null) => {
//   return plugins[name] || null;
// };

// export const findPlugin = (list = null, selected = null) => {
//   if (list && selected) {
//     return get(list, `${selected}.enabled`, false)
//     // for (let plugin of list) {
//     //   if (plugin.id === selected.id) {
//     //     return plugin;
//     //   }
//     // }
//   }
//   return false;
// };

export const getPluginName = (pluginId, locale) => {
  let pluginInfo = {};
  for (let key in plugins) {
    if (plugins[key].id === pluginId) {
      pluginInfo = plugins[key];
      break;
    }
  }
  return pluginInfo[locale] || pluginInfo.name || null;
};

export const getPluginNameByPathname = (pathname, locale) => {
  let pluginInfo = {};
  for (let key in plugins) {
    if (plugins[key].pathName === pathname) {
      pluginInfo = plugins[key];
      break;
    }
  }
  return pluginInfo[locale] || pluginInfo.name || null;
};

export const getPluginPermissionsnameByPathname = pathname => {
  let pluginInfo = {};
  for (let key in plugins) {
    if (plugins[key].pathName === pathname) {
      pluginInfo = plugins[key];
      break;
    }
  }

  return pluginInfo.permissionsName || null;
};

export const getPluginIcon = plugin => {
  let pluginInfo = {};
  for (let key in plugins) {
    if (plugins[key].id === plugin.id) {
      pluginInfo = plugins[key];
      break;
    }
  }
  return pluginInfo.icon || null;
};

export const getPluginIconByPathname = pathname => {
  let pluginInfo = {};
  for (let key in plugins) {
    if (plugins[key].pathName === pathname) {
      pluginInfo = plugins[key];
      break;
    }
  }
  return pluginInfo.icon || null;
};

export const getPluginPathName = plugin => {
  const pluginId = plugin.id;
  let pluginInfo = {};
  for (let key in plugins) {
    if (plugins[key].id === pluginId) {
      pluginInfo = plugins[key];
      break;
    }
  }
  return pluginInfo.pathName || null;
};

export const getPluginComponentNameByPluginPathName = pluginPathName => {
  let pluginComponentName = null;
  for (let componentName in plugins) {
    if (plugins[componentName].pathName === pluginPathName) {
      pluginComponentName = componentName;
      break;
    }
  }
  return pluginComponentName;
};

export const getPluginKeyByPluginPathName = pluginPathName => {
  let pluginKey = null;
  for (let key in plugins) {
    if (plugins[key].pathName === pluginPathName) {
      pluginKey = key;
      break;
    }
  }
  return pluginKey;
};

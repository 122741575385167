import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from "../../parts/chore/dataFetcher";

import ParticipantsPage from '../campaigns/participants/CampaignsParticipantsPage';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  participant: state.participant.participant,
  locale: state.session.language,
  organization: state.organization.organization,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  dataFetcher
)(ParticipantsPage);

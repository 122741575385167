import React from 'react';
import { connectMenu } from 'react-instantsearch-dom';
import { Select } from 'antd';

const { Option } = Select;

const AlgoliaMenuSelect = ({ items, currentRefinement, refine, translations }) => (
  <Select
    className="AlgoliaMenuSelect"
    value={currentRefinement || ''}
    onChange={value => refine(value)}
  >
    <Option value="">{translations.seeAllOption}</Option>
    {items.map(item => (
      <Option
        key={item.label}
        value={item.isRefined ? currentRefinement : item.value}
      >
        {item.label}
      </Option>
    ))}
  </Select>
);

export default connectMenu(AlgoliaMenuSelect);
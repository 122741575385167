import React from 'react';
import { translate } from 'react-i18next';
import { has, get, isEmpty } from 'lodash';
import { Tooltip } from 'antd';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { moneyFormat } from 'Parts/common/moneyFormat';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';
import SelectField from 'Parts/ui/components/fields/SelectField';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import { getLanguageKeyById } from 'Parts/common/langUtils';

import campaignsTeamsRegistrationPageTableEN from './locales/CampaignsTeamsRegistrationPageTable_en.json';
import campaignsTeamsRegistrationPageTableFR from './locales/CampaignsTeamsRegistrationPageTable_fr.json';

import './CampaignsTeamsRegistrationPageTable.less';

class CampaignsTeamsRegistrationPageTable extends React.Component {
  constructor(props) {
    super(props);

    // GET Participant limit set in the campaign
    if (isEmpty(props.campaign)) {
      props.fetchCampaign(props.team.campaignId, true);
    }

    props.participants.total = props.pagination.totalResults;

    i18next.addResourceBundle(
      'en',
      'CampaignsTeamsRegistrationPageTable',
      campaignsTeamsRegistrationPageTableEN
    );
    i18next.addResourceBundle(
      'fr',
      'CampaignsTeamsRegistrationPageTable',
      campaignsTeamsRegistrationPageTableFR
    );

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    this.declineModalRef = React.createRef();
    this.currentCampaignID = this.props.match.params.campaignID;
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchParticipantsInTeam(
      this.props.teamId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' }
    );
  };

  handleViewHandler = participant => {
    const campaignID = this.props.match.params.campaignID,
      actionURL = campaignID
        ? `/${this.props.locale}/d/campaigns/${campaignID}/participants/${participant.id}`
        : `/${this.props.locale}/d/participants/${participant.id}`;
    return this.props.history.push(actionURL);
  };

  getStatus = participant => {
    switch (participant.statusId) {
      case 1:
      case 3:
        return this.props.t(participant.status);
      case 2:
        const { props, handleSelectChange } = this,
          { t, form, campaign, team } = props,
          limit =
            campaign &&
            campaign.settings &&
            campaign.settings.participant &&
            parseInt(campaign.settings.participant.limit),
          count = team && team.childrenCount && team.childrenCount.participants,
          teamFull = limit && count && count >= limit,
          options = teamFull
            ? [
                { value: '2', text: t('PENDING') },
                { value: '3', text: t('REJECTED') }
              ]
            : [
                { value: '2', text: t('PENDING') },
                { value: '1', text: t('ACTIVE') },
                { value: '3', text: t('REJECTED') }
              ];

        return (
          <React.Fragment>
            {teamFull ? (
              <Tooltip placement="topLeft" title={t('team-full')}>
                <SelectField
                  form={form}
                  className={`status-${participant.id}`}
                  fieldId={`status-${participant.id}`}
                  initialValue="2"
                  optionsArray={options}
                  onChange={handleSelectChange}
                />
              </Tooltip>
            ) : (
              <SelectField
                form={this.props.form}
                className={`status-${participant.id}`}
                fieldId={`status-${participant.id}`}
                initialValue="2"
                optionsArray={options}
                onChange={this.handleSelectChange}
              />
            )}
          </React.Fragment>
        );
    }
  };

  handleSelectChange = (value, option) => {
    const participantId = option._owner.stateNode.props.className.replace('status-', '');
    if (value == 1) {
      this.props.updateParticipant({ statusId: parseInt(value) }, participantId).then(() => {
        const { props, state } = this,
          { team, fetchTeam, searchParticipantsInTeam } = props,
          { pager } = state,
          pagination = {
            totalResults: pager.total || 0,
            pageNumber: pager.current || 1,
            pageResults: pager.pageSize || 10
          };

        fetchTeam(team.id, null, false, { showChildrenCount: true });
        searchParticipantsInTeam(
          team.id,
          null,
          null,
          { ...pagination },
          { orderBy: 'addedOn[desc]' },
          true
        );
      });
    } else if (value == 3) {
      this.participantId = participantId;
      this.props.fetchParticipant(participantId, true);
      this.declineModalRef.current.getWrappedInstance('FundkyModal').showModal();
    }
  };

  handleConfirmDeclineModal = e => {
    e.preventDefault();
    const { validateFields } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields(['reasonSelect'], { force: true }, (err, allValues) => {
      if (!err) {
        const { props, state, resetDeclineModal, participantId } = this,
          { t, team, searchParticipantsInTeam, updateParticipant } = props,
          { pager } = state,
          pagination = {
            totalResults: pager.total || 0,
            pageNumber: pager.current || 1,
            pageResults: pager.pageSize || 10
          },
          participantLang = this.props.declinedParticipantLang
            ? getLanguageKeyById(this.props.declinedParticipantLang)
            : 'en',
          reason = this.props.i18n.options.resources[participantLang]
            .CampaignsTeamsRegistrationPageTable.declining[allValues.reasonSelect];

        updateParticipant({ statusId: 3, rejectReason: reason }, participantId).then(() => {
          this.declineModalRef.current.getWrappedInstance('FundkyModal').hideModal();
          resetDeclineModal();
          searchParticipantsInTeam(
            team.id,
            null,
            null,
            { ...pagination },
            { orderBy: 'addedOn[desc]' },
            true
          );
        });
      }
    });
  };

  resetDeclineModal = () => {
    this.props.form.resetFields(['reasonSelect', `status-${this.participantId}`]);
  };

  isDisabled = participant => {
    if (participant.statusId !== 1) {
      return true;
    }
    return false;
  };

  isEditDisabled = participant => {
    return participant.statusId === 3;
  };

  getActiveCount = participants => {
    return participants && participants.filter(participant => participant.statusId === 1).length || 0;
  };

  render() {
    const {
        props,
        state,
        handlePaginationChange,
        isDisabled,
        isEditDisabled,
        getActiveCount,
        getStatus
      } = this,
      { t, locale, form, participants, pagination, campaign } = props,
      { pager } = state;

    const action = [
      actionColumn(
        t('Public Page'),
        null,
        () => {},
        participant => {
          const disabled =
            get(campaign, 'statusId', null) === 2 ||
            get(campaign, 'statusId', null) === 3 ||
            get(campaign, 'statusId', null) === 5 ||
            isDisabled(participant)
              ? { disabled: true }
              : {};
          return (
            <a
              className="ant-btn ant-btn-circle"
              href={`/${locale}/${participant.campaignAlias}/${participant.teamAlias}/${participant.member.alias}`}
              target="_blank"
              {...disabled}
            >
              <FontAwesomeIcon icon={['fal', 'external-link']} />
            </a>
          );
        }
      ),
      ...(this.props.location.pathname.indexOf('/d/contributions/teams/') > -1
        ? []
        : [
            actionColumn(
              t('Edit'),
              ['fal', 'edit'],
              this.handleViewHandler,
              null,
              () => false,
              isEditDisabled
            )
          ])
    ];

    return (
      <div className="CampaignsTeamsRegistrationPageTable">
        {/* <h2 className="table-title">{t('Registrations')}</h2> */}

        <div className="actions">
          {/*<Button type="primary"><FontAwesomeIcon icon={["fal", "plus"]} /> {t('Add a participant')}</Button>
          <Button type="default"><FontAwesomeIcon icon={["fal", "download"]} /> {t('Report')}</Button>*/}
        </div>

        <PaginationTop
          totalLabel={total => t('participantsTotal', { count: getActiveCount(participants) })}
          pager={pager}
          onChange={handlePaginationChange}
        />
        <ResultTable
          dataSource={participants}
          pagination={pager}
          onChange={handlePaginationChange}
          //onRowClick={this.handleRowClick}
          showHeader={true}
          columns={[
            simpleColumn(t('Name'), 'member.name', null, true),
            simpleColumn(t('Amount raised'), 'collected', text => moneyFormat(Number(text.toString().replace('NaN', 0)), locale), true),
            dateColumn(t('Registered on'), 'addedOn', null, null, false, locale),
            simpleColumn(t('Status'), null, participant => getStatus(participant), true),
            ...action
          ]}
        />
        <FundkyModal
          ref={this.declineModalRef}
          displayModal={false}
          title={t('declining.title')}
          maskClosable={false}
          acceptAction={this.handleConfirmDeclineModal}
          cancelAction={this.resetDeclineModal}
        >
          <SelectField
            form={form}
            fieldId={'reasonSelect'}
            label={t('declining.label')}
            initialValue="reason1"
            optionsArray={[
              { value: 'reason1', text: t('declining.reason1') },
              { value: 'reason2', text: t('declining.reason2') },
              { value: 'reason3', text: t('declining.reason3') },
              { value: 'reason4', text: t('declining.reason4') }
            ]}
            style={{ width: '100%' }}
            dropdownClassName="overModal"
          />
          <p>
            <em>{t('declining.notice')}</em>
          </p>
        </FundkyModal>
      </div>
    );
  }
}

CampaignsTeamsRegistrationPageTable.defaultProps = {
  participants: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('CampaignsTeamsRegistrationPageTable')(
  CampaignsTeamsRegistrationPageTable
);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import OptInPlatformField_en from './locales/OptInPlatformField_en.json';
import OptInPlatformField_fr from './locales/OptInPlatformField_fr.json';

class OptInPlatformField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'OptInPlatformField', OptInPlatformField_en);
    i18next.addResourceBundle('fr', 'OptInPlatformField', OptInPlatformField_fr);
  }

  render() {
    const { t, form, className, platform, organization, fieldId, initialValue } = this.props;
    const { getFieldDecorator } = form;

    const referent = (platform && platform.settings && platform.settings.communication) && platform.settings.communication.referent || '';
    let referentName = platform && platform.name || '';
    if(referent === 'organization') {
      referentName = organization && organization.name || '';
    }

    const input = (
      <Checkbox>
        {t('label', {referent: referentName})}
      </Checkbox>
    );

    return (
      <Form.Item className={className}>
        {getFieldDecorator(fieldId, {
          initialValue: initialValue,
          valuePropName: 'checked',
        })(input)}
      </Form.Item>
    );
  }

}

OptInPlatformField.defaultProps = {
  fieldId: 'platformOptin',
  initialValue: false,
};

OptInPlatformField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  platform: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default translate('OptInPlatformField')(OptInPlatformField);

import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { has, get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import CampaignsParticipantsPageForm from './components/CampaignsParticipantsPageFormContainer';
import CampaignsParticipantsHistoryPage from './components/CampaignsParticipantsHistoryPageContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import AdminBackButtonBar from 'Parts/common/AdminBackButtonBar';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import campaignsParticipantsPageEN from './locales/CampaignsParticipantsPage_en.json';
import campaignsParticipantsPageFR from './locales/CampaignsParticipantsPage_fr.json';

import './CampaignsParticipantsPage.less';

class CampaignsParticipantsPage extends React.Component {
  constructor(props) {
    super(props);

    this.campaignID = get(props, 'match.params.campaignID', null);

    this.from = props.location.state && props.location.state.from;

    i18next.addResourceBundle('en', 'CampaignsParticipantsPage', campaignsParticipantsPageEN);
    i18next.addResourceBundle('fr', 'CampaignsParticipantsPage', campaignsParticipantsPageFR);
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { state } = this.props.location;
    if (state && state.from) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.pathname.indexOf(':participantID')) {
        const participantsListUrl = this.props.location.pathname.replace(
          '/participants/:participantID',
          ''
        );
        this.props.history.replace(participantsListUrl);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearParticipant();
  }

  render() {
    const { props, campaignID } = this,
      { t, match, campaign, participant, locale, platformId, checkAuthorization } = props;

    const campaignUrlPart = get(participant, 'campaignAlias', '');
    const teamUrlPart = get(participant, 'teamAlias') || t('URL:individual-participant');
    const participantUrlPart = get(participant, 'member.alias', '');
    const currentParticipantUrl = `/${locale}/${campaignUrlPart}/${teamUrlPart}/${participantUrlPart}`;

    const participantListUrl =
      has(props, 'location.state.originIsAll') && !campaignID
        ? `/${locale}/d/participants/list`
        : `/${locale}/d/campaigns/${campaignID}/participants/list`;

    const historyTabPermissions =
      checkAuthorization({ platform: platformId }, ['permissions.platform.participant.logs']) ||
      false;

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: () => (
          <Can
            rules={[
              ['permissions.platform.participant.update'],
              ['permissions.campaign.participant.update']
            ]}
            campaignId={get(match, 'params.campaignID', null)}
            redirect={`/${locale}/d`}
          >
            <CampaignsParticipantsPageForm {...props} />
          </Can>
        )
      },
      /*{title: t('Funds raised'), slug: 'donations', container: (props) => <CampaignsTeamsRegistrationPageTable {...this.props} />},*/
      {
        title: t('LOGS:History'),
        slug: 'history',
        visible: historyTabPermissions,
        container: () => (
          <Can
            rules={[
              ['permissions.platform.participant.logs'],
              ['permissions.campaign.participant.logs']
            ]}
            campaignId={get(match, 'params.campaignID', null)}
            redirect={`/${locale}/d`}
          >
            <CampaignsParticipantsHistoryPage {...props} />
          </Can>
        )
      }
    ];

    const disabled =
      get(campaign, 'statusId', null) === 2 ||
      get(campaign, 'statusId', null) === 3 ||
      get(campaign, 'statusId', null) === 5;
    const tooltipVisibility = !disabled ? { visible: false } : {};
    const tooltipMessage =
      get(campaign, 'statusId', null) === 2 ? t('under-review') : t('inactive');
    const linkDisabled = disabled ? { disabled: true } : {};

    return (
      <div className="CampaignsParticipantsPage">
        <Helmet>
          <title>{t('Participant')}</title>
        </Helmet>
        <AdminBackButtonBar href={participantListUrl} text={t('Back to search')} />
        <AdminTitleBar
          upperTitle={t('Participant')}
          title={get(participant, 'member.name', '')}
          buttons={[
            <Tooltip placement="top" title={tooltipMessage} {...tooltipVisibility}>
              <div>
                <span style={{ visibility: 'hidden' }}>-</span>
                {/*Ugly fix so that tooltip shows up even if button is disabled*/}
                <Link
                  to={currentParticipantUrl}
                  className="ant-btn btn-view-page ant-btn-default"
                  target="_blank"
                  rel="noopener noreferrer"
                  {...linkDisabled}
                >
                  <span>{t('View profile')}</span>
                  <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                </Link>
              </div>
            </Tooltip>
          ]}
        />
        <ResponsiveTabs pages={tabPages} parentPage={props} defaultTabHasNoSlug />
      </div>
    );
  }
}

export default translate('CampaignsParticipantsPage')(CampaignsParticipantsPage);

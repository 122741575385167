import { connect } from "react-redux";

import ParticipationTypeOptions from './ParticipationTypeOptions';

const mapStateToProps = state => ({
  campaign: state.participant.selectedCampaign,
  teams: state.team.teams
});

export default connect(mapStateToProps)(ParticipationTypeOptions);

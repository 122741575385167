import { compose } from "redux";
import { connect } from "react-redux";

import dataFetcher from 'Parts/chore/dataFetcher';
import AdminSidebarPlugins from './AdminSidebarPlugins';

const mapStateToProps = state => ({
  locale: state.session.language,
  plugins: state.platform.platform.plugins
});

export default compose(
  connect(mapStateToProps),
  dataFetcher
)(AdminSidebarPlugins);

import React from 'react';
import { Form, Switch } from 'antd';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import designSettingsFormEN from './locales/DesignSettingsForm_en.json';
import designSettingsFormFR from './locales/DesignSettingsForm_fr.json';
import { pickBy, get } from 'lodash';
import {
  getLanguageNameByKey,
  getLanguageIdByKey,
  getDefaultLanguageKey
} from 'Parts/common/langUtils';

import LangSwitcher from '../../components/AdminContentLangSwitcher';
import UploadCropField from 'Parts/ui/components/fields/UploadCropField.jsx';
import TextField from 'Parts/ui/components/fields/TextField.jsx';
import ColorPickerField from 'Parts/ui/components/fields/ColorPickerField.jsx';
import { urlFieldValidator } from 'Parts/ui/validations/fieldValidations';
import { getCdnUrl } from 'Parts/common/environment';

import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';

import './DesignSettingsForm.less';
import CheckboxField from 'Parts/ui/components/fields/CheckboxField.jsx';

const cdnUrl = getCdnUrl();

class DesignSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang: getDefaultLanguageKey(get(props, 'languages')) || props.locale,
      platformLogo: undefined,
      is_show_total_amount: this.props.platform.is_show_total_amount
    };

    this.changeHasHappened = false;

    this.UploadCropField = React.createRef();
    this.LogoLinkField = React.createRef();

    i18next.addResourceBundle('en', 'DesignSettingsForm', designSettingsFormEN);
    i18next.addResourceBundle('fr', 'DesignSettingsForm', designSettingsFormFR);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.changeHasHappened = false;

    const { validateFields, isFieldTouched } = this.props.form;

    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (allValues.primary_color != this.props.colorPrimary) {
          updatedValues.primary_color = allValues.primary_color;
        }

        if (allValues.secondary_color != this.props.colorSecondary) {
          updatedValues.secondary_color = allValues.secondary_color;
        }

        const languageName = getLanguageNameByKey(this.state.contentLang, false);
        const { platformLogo } = this.state;

        if (updatedValues.platform_logo) {
          delete updatedValues.platform_logo;
        }

        if (platformLogo !== undefined || updatedValues.platform_logo_url !== undefined) {
          const { platform_logo_url } = updatedValues;
          const logo = [
            {
              languageId: getLanguageIdByKey(this.state.contentLang),
              logo: platformLogo || undefined,
              link: platform_logo_url === '' ? null : platform_logo_url,
              logoId: get(this.props, `platformLogo.[${languageName}].logoId`, undefined)
            }
          ];

          if (platformLogo || updatedValues.platform_logo_url !== undefined) {
            this.props.updatePlatformMedia(this.props.platformId, 'logo', logo);
          } else {
            this.props.deletePlatformMedia(this.props.platformId, 'logo', logo.languageId);
          }
        }

        if (updatedValues.primary_color || updatedValues.secondary_color) {
          const colors = {
            color: {
              primary: updatedValues.primary_color || undefined,
              secondary: updatedValues.secondary_color || undefined
            }
          };

          this.props.updatePlatformSettings(this.props.platformId, colors);
        }
        this.props.updatePlatform(this.props.platformId, {is_show_total_amount: this.state.is_show_total_amount});
      }
    });
  };

  handleContentLanguageChange = value => {
    this.setState({ contentLang: value }, () => {
      this.forceLogo();
    });
  };

  handleCropConfirm = platformLogo => {
    this.setState({
      platformLogo
    });
    this.changeHasHappened = true;
  };

  handleLogoDelete = () => {
    this.setState({
      platformLogo: null
    });

    this.changeHasHappened = true;
  };

  forceLogo = () => {
    this.UploadCropField.current.getWrappedInstance('UploadCropField').resetField();
    this.LogoLinkField.current.resetField();
    this.changeHasHappened = false;
  };

  getLogoInformation = (type = 'logo') => {
    const lng = getLanguageNameByKey(this.state.contentLang, false);
    return get(this.props, `platformLogo[${lng}].${type}`, null);
  };

  checkForChange = value => {
    if (this.props.form.isFieldTouched('platform_logo_url')) this.changeHasHappened = true;
  };

  handleClickCancel = e => {
    this.props.form.resetFields();
    this.changeHasHappened = false;
  };

  handleIsRequiredChange = (event) => {
    this.setState({ is_show_total_amount: event === true ? 1 : 0 });
  };

  render() {
    const { t, form, locale } = this.props;

    const logo = this.getLogoInformation();
    const logoLink = this.getLogoInformation('link');

    const colorPrimary = this.props.colorPrimary || '#EA3152';
    const colorSecondary = this.props.colorSecondary || '#64BFC5';

    return (
      <Form className="DesignSettingsForm" onSubmit={this.handleSubmit}>
        <div className="inputs">
          <LangSwitcher
            defaultLanguage={
              getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale
            }
            handleChange={this.handleContentLanguageChange}
            changeHasHappened={this.changeHasHappened}
            form={form}
          />

          <UploadCropField
            ref={this.UploadCropField}
            fieldId="platform_logo"
            uploadTitle="Logo"
            form={form}
            label={t('logo-label')}
            tooltip={t('logo-tooltip')}
            aspectRatio={false}
            minWidth={126}
            minHeight={30}
            recommendedWidth={320}
            recommendedHeight={60}
            handleCropConfirm={this.handleCropConfirm}
            initialValue={logo}
            erasable
            handleDelete={this.handleLogoDelete}
            required={true}
            requiredMessage={t('logo-required')}
          />

          <TextField
            ref={this.LogoLinkField}
            fieldId="platform_logo_url"
            form={form}
            initialValue={logoLink}
            label={t('logo-url-label')}
            tooltip={t('logo-url-tooltip')}
            placeholder="https://www.example.com"
            onChange={this.checkForChange}
            fieldOptions={{
              rules: [...urlFieldValidator(false)]
            }}
          />

          {/* {showDuplicateLogoField &&
            <CheckboxField
              fieldId="duplicate_logo"
              form={form}
              label={t('duplicate-logo-label')}
              tooltip={t('duplicate-logo-tooltip')}
            />
          } */}

          <div className="swatches ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>{t('Colors')}</label>
            </div>
            <div className="ant-col ant-form-item-control-wrapper">
              <label className="small">{t('Accent')}</label>

              <ColorPickerField form={form} fieldId="primary_color" initialValue={colorPrimary} />

              <ColorPickerField
                form={form}
                fieldId="secondary_color"
                initialValue={colorSecondary}
              />
            </div>
          </div>
          <div className="swatches ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>{t('Show_total_amount_on_home_page')}</label>
            </div>
            <div className="ant-col ant-form-item-control-wrapper">
              <Switch
                checked={this.state.is_show_total_amount === 1}
                onChange={this.handleIsRequiredChange}
              />
            </div>
          </div>
        </div>

        <CancelAndSaveButtons onCancel={this.handleClickCancel} />
      </Form>
    );
  }
}

// DesignSettingsForm.propTypes = {
//   form: PropTypes.object.isRequired,
// };

export default translate('DesignSettingsForm')(DesignSettingsForm);

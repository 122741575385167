import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { createParticipant, participantInfoAdd } from '../participantActions';
import { changeParticipantCreateWizardStep } from '../../ui/uiActions';
import { fetchTeams } from '../../team/teamActions';
import { fetchPlatformIdBySurveyList } from "../../donation/donationActions";
import CreateInformationForm from './CreateInformationForm';

const mapStateToProps = state => ({
  isCreateParticipantInProgress: state.ui.isCreateParticipantInProgress,
  selectedCampaign: state.participant.selectedCampaign,
  surveyId: state.donation.surveyId,
  platformId: state.session.permissionsIds.platform,
  teams: state.team.teams,
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPlatformIdBySurveyList,
  changeParticipantCreateWizardStep,
  createParticipant,
  participantInfoAdd,
  fetchTeams
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(CreateInformationForm);

import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import CurrentCampaignDropdown from './components/CurrentCampaignDropdown';
import { isPluginActive } from '../../../plugin/pluginActions';

import adminSidebarCampaignsEN from './locales/AdminSidebarCampaigns_en.json';
import adminSidebarCampaignsFR from './locales/AdminSidebarCampaigns_fr.json';

class AdminSidebarCampaigns extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AdminSidebarCampaigns', adminSidebarCampaignsEN);
    i18next.addResourceBundle('fr', 'AdminSidebarCampaigns', adminSidebarCampaignsFR);
  }

  render() {
    const
      { props } = this,
      { t, match, locale, campaign, campaigns, platform } = props;
    const matchPath = match.path;
    const campaignID = match.params.campaignID;
    const campaignTypeId = campaign.typeId;
    const campaignBehaviorId = campaign.behaviorId;

    const _campaign = campaign || {};
    const _campaigns = (campaigns && campaigns.filter(c => c.id !== campaignID)) || [];

    // We need a combination of the react-router NavLink and the Antd Button... let's use NavLink and put the Antd classes on it.
    return (
      <div className="CampaignsSider">
        <CurrentCampaignDropdown
          campaign={_campaign}
          locale={locale}
          dropdownLabel={t('last-campaigns-label')}
          title={_campaign.name || ''}
          subtitle={t('Campaign')}
          entities={_campaigns}
          getEntityLinkUrl={entity => `/${locale}/${_campaign.alias || ''}`}
          path={match.path}
          additionalLinks={
            <Link to={`/${locale}/${t('URL:create-a-campaign')}`}>
              <FontAwesomeIcon icon={['fal', 'arrow-right']} />
              {t('create-campaign')}
            </Link>
          }
        />

        <Link
          to={`/${locale}/d/campaigns/list`}
          className="AdminSider__GroupItem AdminSider__GroupTitle ant-btn ant-btn-lg"
        >
          <FontAwesomeIcon icon={['fal', 'long-arrow-left']} fixedWidth />{' '}
          <span>{t('Campaign')}</span>
        </Link>

        <NavLink
          exact
          to={`/${locale}/d/campaigns/${campaignID}`}
          className={`AdminSider__GroupItem ant-btn ant-btn-lg ${matchPath ===
            `/${locale}/d/campaigns/:campaignID/history` && 'AdminSider__GroupItem--active'}`}
          activeClassName="AdminSider__GroupItem--active"
        >
          <FontAwesomeIcon icon={['fal', 'desktop']} fixedWidth /> {t('About')}
        </NavLink>
        {campaignBehaviorId === 1 || !isPluginActive(platform, 'Event')
          ? <>
              {campaignTypeId && (campaignTypeId === 2 || campaignTypeId === 3) && (
                <NavLink
                  to={`/${locale}/d/campaigns/${campaignID}/teams/list`}
                  className={`AdminSider__GroupItem ant-btn ant-btn-lg ${
                    matchPath.includes(`/${locale}/d/campaigns/:campaignID/teams/`) &&
                    'AdminSider__GroupItem--active' || ''}`
                  }
                  activeClassName="AdminSider__GroupItem--active"
                >
                  <FontAwesomeIcon icon={['fal', 'users']} fixedWidth /> {t('Teams')}
                </NavLink>
              )}
              {campaignTypeId && campaignTypeId !== 4 && (
                <NavLink
                  to={`/${locale}/d/campaigns/${campaignID}/participants/list`}
                  className={`AdminSider__GroupItem ant-btn ant-btn-lg ${
                    matchPath.includes(`/${locale}/d/campaigns/:campaignID/participants/`) &&
                    'AdminSider__GroupItem--active' || ''}`
                  }
                  activeClassName="AdminSider__GroupItem--active"
                >
                  <FontAwesomeIcon icon={['fal', 'user']} fixedWidth /> {t('Participants')}
                </NavLink>
              )}
            </>
          : campaignBehaviorId === 2 && isPluginActive(platform, 'Event')
            ? <>
                <NavLink
                  to={`/${locale}/d/campaigns/${campaignID}/attendees/checkin`}
                  className={`AdminSider__GroupItem ant-btn ant-btn-lg ${
                    matchPath.includes(`/${locale}/d/campaigns/:campaignID/attendees/checkin`) &&
                    'AdminSider__GroupItem--active' || ''}`
                  }
                  activeClassName="AdminSider__GroupItem--active"
                >
                  <FontAwesomeIcon icon={['fal', 'users']} fixedWidth /> {t('Attendees')}
                </NavLink>
                <NavLink
                  to={`/${locale}/d/campaigns/${campaignID}/communications`}
                  className={`AdminSider__GroupItem ant-btn ant-btn-lg ${
                    matchPath.includes(`/${locale}/d/campaigns/:campaignID/communications`) &&
                    'AdminSider__GroupItem--active' || ''}`
                  }
                  activeClassName="AdminSider__GroupItem--active"
                >
                  <FontAwesomeIcon icon={['fal', 'envelope']} fixedWidth /> {t('Communications')}
                </NavLink>
              </>
            : null
        }
        {campaignBehaviorId === 2
          ? <NavLink
              to={`/${locale}/d/campaigns/${campaignID}/transactions/list/tickets`}
              className={`AdminSider__GroupItem ant-btn ant-btn-lg ${
                matchPath.includes(`/${locale}/d/campaigns/:campaignID/transactions/`) &&
                'AdminSider__GroupItem--active' || ''}`}
              activeClassName="AdminSider__GroupItem--active"
            >
              <FontAwesomeIcon icon={['fal', 'usd-circle']} fixedWidth /> {t('Transactions')}
            </NavLink>
          : <NavLink
              to={`/${locale}/d/campaigns/${campaignID}/transactions/list/donations`}
              className={`AdminSider__GroupItem ant-btn ant-btn-lg ${
                matchPath.includes(`/${locale}/d/campaigns/:campaignID/transactions/`) &&
                'AdminSider__GroupItem--active' || ''}`}
              activeClassName="AdminSider__GroupItem--active"
            >
              <FontAwesomeIcon icon={['fal', 'usd-circle']} fixedWidth /> {t('Transactions')}
            </NavLink>

        }
        <NavLink
          to={`/${locale}/d/campaigns/${campaignID}/settings/registration`}
          className="AdminSider__GroupItem ant-btn ant-btn-lg"
          activeClassName="AdminSider__GroupItem--active"
        >
          <FontAwesomeIcon icon={['fal', 'cogs']} fixedWidth /> {t('Settings')}
        </NavLink>
      </div>
    );
  }
}

export default translate('AdminSidebarCampaigns')(AdminSidebarCampaigns);

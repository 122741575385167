import React from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, Dropdown, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import classnames from 'classnames';
import {
  CAMPAIGN_TEMPLATE_PARTICIPANTS,
  CAMPAIGN_TEMPLATE_SOLO,
  CAMPAIGN_TEMPLATE_TEAMS,
  CAMPAIGN_TEMPLATE_MIXED
} from '../campaign/campaignUtils';

import RegisterDropdown_en from './locales/RegisterDropdown_en.json';
import RegisterDropdown_fr from './locales/RegisterDropdown_fr.json';

import './RegisterDropdown.less';

class RegisterDropdown extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'RegisterDropdown', RegisterDropdown_en);
    i18next.addResourceBundle('fr', 'RegisterDropdown', RegisterDropdown_fr);
  }

  // (campaignAlias, false, platformId, { showChildrenCount: true, showDescription: true, showMedia: true, showSettings: true }))

  componentDidUpdate(prevProps) {
    if (
      prevProps.isUserLoggedIn !== this.props.isUserLoggedIn &&
      this.props.match.params.campaignAlias
    ) {
      this.props.fetchCampaign(
        this.props.match.params.campaignAlias,
        false,
        this.props.platformId,
        { showChildrenCount: true, showDescription: true, showMedia: true, showSettings: true }
      );
    }
  }

  render() {
    const { props } = this;
    const { t, locale, campaign, team, size, match, subLabel, label, DropdownPlacement } = props;

    const className = classnames('RegisterDropdown', props.className);

    // Get page type
    const pageType = match.params.participantAlias
      ? 'participant'
      : match.params.teamAlias
      ? 'team'
      : match.params.campaignAlias
      ? 'campaign'
      : 'other';

    // IS USER PARTICIPATING
    var inCampaign = false;
    var inTeam = false;
    var participateIn = null;

    if (pageType !== 'other') {
      inCampaign =
        campaign && Object.keys(campaign).length > 0 ? { state: { campaign: campaign } } : null;
      inTeam =
        campaign && Object.keys(campaign).length > 0 && team && Object.keys(team).length > 0
          ? { state: { campaign: campaign, team: team } }
          : null;
      participateIn = inTeam || inCampaign;
    }

    // LIMITS
    const participantsLimit =
      (campaign.settings && campaign.settings.participant && campaign.settings.participant.limit) ||
      0;
    const participantsCount = (campaign.childrenCount && campaign.childrenCount.participants) || 0;
    const teamsLimit =
      (campaign.settings && campaign.settings.team && campaign.settings.team.limit) || 0;
    const teamsCount = (campaign.childrenCount && campaign.childrenCount.teams) || 0;

    const createTeamMenuItem =
      pageType !== 'other' ? (
        campaign.typeId !== CAMPAIGN_TEMPLATE_PARTICIPANTS &&
        campaign.typeId !== CAMPAIGN_TEMPLATE_SOLO ? (
          campaign.participating ? (
            <Menu.Item id="create-a-team">
              <Tooltip
                placement="top"
                title={t('already-in')}
                getPopupContainer={() => document.getElementById('create-a-team')}
              >
                <div>
                  <Link
                    disabled
                    to={{ pathname: `/${locale}/${t('URL:create-a-team')}`, ...participateIn }}
                  >
                    {this.props.subLabel[0] || t('create-a-team')}
                  </Link>
                </div>
              </Tooltip>
            </Menu.Item>
          ) : teamsLimit == 0 || teamsLimit > teamsCount ? (
            <Menu.Item>
              <Link to={{ pathname: `/${locale}/${t('URL:create-a-team')}`, ...participateIn }}>
                {this.props.subLabel[0] || t('create-a-team')}
              </Link>
            </Menu.Item>
          ) : (
            <Menu.Item id="create-a-team">
              <Tooltip
                placement="top"
                title={t('full')}
                getPopupContainer={() => document.getElementById('create-a-team')}
              >
                <div>
                  <Link
                    disabled
                    to={{ pathname: `/${locale}/${t('URL:create-a-team')}`, ...participateIn }}
                  >
                    {subLabel[0] || t('create-a-team')}
                  </Link>
                </div>
              </Tooltip>
            </Menu.Item>
          )
        ) : null
      ) : (
        <Menu.Item>
          <Link to={{ pathname: `/${locale}/${t('URL:create-a-team')}` }}>
            {subLabel[0] || t('create-a-team')}
          </Link>
        </Menu.Item>
      );

    const registerMenuItem =
      pageType === 'other' ? (
        <Menu.Item>
          <Link to={{ pathname: `/${locale}/${t('URL:participant-registration')}` }}>
            {subLabel[1] || t('create-a-participant')}
          </Link>
        </Menu.Item>
      ) : campaign.typeId !== CAMPAIGN_TEMPLATE_SOLO ? (
        campaign.participating ? (
          <Menu.Item id="create-a-participant">
            <Tooltip
              placement="top"
              title={t('already-in')}
              getPopupContainer={() => document.getElementById('create-a-participant')}
            >
              <div>
                <Link
                  disabled
                  to={{
                    pathname: `/${locale}/${t('URL:participant-registration')}`,
                    ...participateIn
                  }}
                >
                  {subLabel[1] || t('create-a-participant')}
                </Link>
              </div>
            </Tooltip>
          </Menu.Item>
        ) : campaign.typeId === CAMPAIGN_TEMPLATE_MIXED ||
          participantsLimit == 0 ||
          (campaign.typeId === CAMPAIGN_TEMPLATE_PARTICIPANTS &&
            participantsLimit > participantsCount) ||
          (campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS &&
            (teamsLimit == 0 || participantsLimit * teamsLimit > participantsCount)) ? (
          <Menu.Item>
            <Link
              to={{ pathname: `/${locale}/${t('URL:participant-registration')}`, ...participateIn }}
            >
              {subLabel[1] || t('create-a-participant')}
            </Link>
          </Menu.Item>
        ) : (
          <Menu.Item id="create-a-participant">
            <Tooltip
              placement="top"
              title={t('full')}
              getPopupContainer={() => document.getElementById('create-a-participant')}
            >
              <div>
                <Link
                  disabled
                  to={{
                    pathname: `/${locale}/${t('URL:participant-registration')}`,
                    ...participateIn
                  }}
                >
                  {subLabel[1] || t('create-a-participant')}
                </Link>
              </div>
            </Tooltip>
          </Menu.Item>
        )
      ) : null;

    const register = (
      <Menu className="RegisterDropdown_menu">
        {createTeamMenuItem}
        {registerMenuItem}
      </Menu>
    );

    const disabled = campaign.participating ? true : null;
    const disabledObj = disabled ? { disabled } : {};
    const sizeClass = size === 'large' ? ' ant-btn-lg' : size === 'small' ? ' ant-btn-sm' : '';
    const dropdownRegister = disabled ? (
      <a {...disabledObj} className={`ant-btn${sizeClass}`}>
        {label || t('register')}
      </a>
    ) : (
      <Dropdown
        overlay={register}
        trigger={['click']}
        className={className}
        {...disabledObj}
        placement={DropdownPlacement}
        getPopupContainer={trigger => trigger.parentNode}
      >
        <Button size={size} {...disabledObj}>
          {label || t('register')}
        </Button>
      </Dropdown>
    );
    const tooltipVisibility = !disabled ? { visible: false } : {};

    return (
      <Tooltip placement="top" title={t('already-in')} {...tooltipVisibility}>
        <div>{dropdownRegister}</div>
      </Tooltip>
    );
  }
}

RegisterDropdown.defaultProps = {
  children: '',
  className: '',
  locale: '',
  size: 'large',
  count: null,
  limit: 0,
  full: '',
  label: null,
  subLabel: [null, null],
  DropdownPlacement: 'bottomLeft'
};

RegisterDropdown.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  locale: PropTypes.string.isRequired,
  size: PropTypes.string,
  DropdownPlacement: PropTypes.string
};

export default translate('RegisterDropdown')(RegisterDropdown);

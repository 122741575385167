import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import { checkAuthorization } from 'Parts/common/authorizationUtils';
import { clearParticipant } from '../../../participant/participantActions';

import CampaignsParticipantsPage from './CampaignsParticipantsPage';

const mapStateToProps = state => ({
  campaign: state.campaign.campaign,
  participant: state.participant.participant,
  teams: state.team.teams.results,
  locale: state.session.language,
  platformId: state.platform.platform.id
});

const mapDispatchToProps = dispatch => bindActionCreators({
  checkAuthorization,
  clearParticipant
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(CampaignsParticipantsPage);

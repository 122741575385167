import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import NameField from 'Parts/ui/components/fields/NameField';
import SubmitButton from 'Parts/common/SubmitButton';
import AvatarThumbnail from '../../common/AvatarThumbnailContainer';
import HelpText from '../../common/HelpText';
import { TEAM_CREATE_WIZARD_CAMPAIGN } from '../../ui/uiUtils';

import CreateInformationForm_en from './locales/CreateInformationForm_en.json';
import CreateInformationForm_fr from './locales/CreateInformationForm_fr.json';

import './CreateInformationForm.less';

class CreateInformationForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CreateInformationForm-team', CreateInformationForm_en);
    i18next.addResourceBundle('fr', 'CreateInformationForm-team', CreateInformationForm_fr);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.teamCreateWizardState !== this.props.teamCreateWizardState) {
      document.getElementById('main').scrollTo(0, 0);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.createTeam(values, this.props.selectedCampaign.id);
      }
    });
  };

  handleCampaignClick = () => {
    this.props.changeTeamCreateWizardStep(TEAM_CREATE_WIZARD_CAMPAIGN);
  };

  dataLayer = event => {
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'custom_click',
        eventAction: 'click',
        eventCategory: 'button',
        eventLabel: 'create_team',
        eventValue: 'submit'
      });
    }
  };

  render() {
    const { t } = this.props;

    const changeCampaignButton = !this.props.disableCampaignTab ? (
      <a className="CreateInformationForm__ChangeCampaignBtn" onClick={this.handleCampaignClick}>
        <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
      </a>
    ) : null;

    const campaignInfo = this.props.selectedCampaign ? (
      <div className="CreateInformationForm__Divider CreateInformationForm__Divider--title">
        <AvatarThumbnail
          entity={this.props.selectedCampaign}
          primaryContent={this.props.selectedCampaign.name}
          secondaryContent={<HelpText>Campaign:</HelpText>}
          className="AvatarThumbnail--title"
          avatarSize={127}
        />
        {changeCampaignButton}
      </div>
    ) : null;

    return (
      <div className="FundkyWrap">
        <Form className="CreateInformationForm" onSubmit={this.handleSubmit}>
          {campaignInfo}
          <h2>{t('title')}</h2>
          <p>{t('desc')}</p>
          <NameField
            className="CreateInformationForm__Field"
            form={this.props.form}
            fieldId="name"
            maxlength={75}
            counter={true}
            required={true}
            placeholder={true}
            label={false}
            identity="team"
          />
          <p>{t('notice')}</p>
          <p>{t('by-going-foward')}</p>
          <div className="CreateInformationForm__SubmitWrapper">
            <SubmitButton loading={this.props.isCreateTeamInProgress} dataLayer={this.dataLayer}>
              {t('action')}
            </SubmitButton>
          </div>
        </Form>
      </div>
    );
  }
}

CreateInformationForm.defaultProps = {
  selectedCampaign: null,
  disableCampaignTab: false
};

CreateInformationForm.propTypes = {
  form: PropTypes.object.isRequired,
  changeTeamCreateWizardStep: PropTypes.func.isRequired,
  createTeam: PropTypes.func.isRequired,
  selectedCampaign: PropTypes.object,
  disableCampaignTab: PropTypes.bool,
  isCreateTeamInProgress: PropTypes.bool.isRequired
};

export default translate('CreateInformationForm-team')(CreateInformationForm);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, InputNumber } from 'antd';
import FundkyTooltip from '../FundkyTooltip';

class NumberField extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.contentLang !== this.props.contentLang) {
      this.props.form.resetFields([this.props.fieldId]);
    }
  }

  resetField = () => {
    this.props.form.resetFields([this.props.fieldId]);
  };

  render() {
    const {
      form,
      fieldId,
      className,
      initialValue,
      fieldOptions,
      label,
      hasFeedback,
      placeholder,
      tooltip,
      disabled,
      onChange,
      contentLang,
      notice,
      colon,
      ...rest
    } = this.props;
    const { getFieldDecorator } = form;

    const input = (
      <InputNumber disabled={disabled} placeholder={placeholder} {...rest} />
    );

    const options = {
      initialValue: initialValue,
      ...fieldOptions,
      onChange: event => {
        if (onChange) onChange(event);
        return;
      }
    };

    let labelProperties = tooltip
      ? {
          label: (
            <span>
              {label + ':'}
              <FundkyTooltip title={tooltip} />
            </span>
          ),
          colon: false
        }
      : {
          label: label,
          colon: colon
        };

    return (
      <>
        <Form.Item
          className={classnames('NumberField', className)}
          hasFeedback={hasFeedback}
          {...labelProperties}
        >
          {getFieldDecorator(fieldId, options)(input)}
        </Form.Item>
        {(notice && !label) && (
          <p className="notice">{notice}</p>
        ) ||
        (notice && label) && (
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label transactions-suggestions-label-wrap">
            </div>
            <div className="ant-col ant-form-item-control-wrapper transactions-suggestions-wrap">
              <p className="notice">{notice}</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

NumberField.defaultProps = {
  fieldId: 'numberField',
  fieldOptions: {},
  contentLang: null,
  disabled: false,
  initialValue: '',
  hasFeedback: false,
  tooltip: null,
  placeholder: null,
  notice: null,
  colon: true
};

NumberField.propTypes = {
  form: PropTypes.object.isRequired,
  fieldId: PropTypes.string.isRequired,
  fieldOptions: PropTypes.object,
  className: PropTypes.string,
  maxlength: PropTypes.number,
  hasFeedback: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  colon: PropTypes.bool
};

export default NumberField;

import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Can from 'Parts/session/components/CanContainer';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, actionColumn } from 'Parts/common/columnUtils';
import { getPluginName, getPluginPathName } from '../../plugin/pluginSelectors.js';

import PluginsListPage_en from './locales/PluginsListPage_en.json';
import PluginsListPage_fr from './locales/PluginsListPage_fr.json';

class PluginsListPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PluginsListPage', PluginsListPage_en);
    i18next.addResourceBundle('fr', 'PluginsListPage', PluginsListPage_fr);

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: this.cleanPlugins(props.plugins).length || 0, //props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      },
      plugins: this.cleanPlugins(props.plugins)
    };

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.plugins != this.props.plugins) {
      this.setState({
        plugins: this.cleanPlugins(this.props.plugins),
        pager: {
          ...DEFAULT_PAGER,
          total: this.cleanPlugins(this.props.plugins).length || 0, //this.props.pagination.totalResults || 0,
          current: this.props.pagination.pageNumber || 1,
          pageSize: this.props.pagination.pageResults || 10
        }
      });
    }

    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchPlugins(
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  handleViewHandler = plugin => {
    const currentUrl = this.props.location.pathname;
    const pluginPathName = getPluginPathName(plugin);
    const pluginUrl = `${currentUrl}/${pluginPathName}`;
    return pluginPathName ? this.props.history.push(pluginUrl) : null;
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchPlugins(
      filters.search,
      filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  // Only display active plugins and remove children
  cleanPlugins = plugins => {
    let _plugins = plugins;
    const platformPluginIds = this.props.platform.plugins
      ? Object.keys(this.props.platform.plugins).map(key => this.props.platform.plugins[key].id)
      : [];
    _plugins = _plugins.filter(plugin => platformPluginIds.indexOf(plugin.id) > -1);
    //let extracted = []
    _plugins.forEach(plugin => {
      // if(plugin.children && plugin.children.length > 0) {
      //   plugin.children.forEach(subplugin => {
      //     extracted.push(subplugin);
      //   });
      // }
      delete plugin.children;
    });
    // _plugins = [..._plugins, ...extracted];
    // _plugins.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
    return _plugins;
  };

  render() {
    const { props, state, handleSearch, handlePaginationChange, handleViewHandler } = this,
      { t, locale } = props,
      { pager, plugins } = state;

    // Pagination in API Development
    return (
      <Layout className="PluginsListPage">
        <Helmet>
          <title>{t('Plugins')}</title>
        </Helmet>
        <SearchBar title={t('Plugins')} placeholder={t('Search')} onSearch={handleSearch} />
        <Can
          rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
          redirect={`/${locale}/d`}
        >
          <div className="AdminList">
            <PaginationTop
              totalLabel={() => t('pluginsTotal', { count: plugins.length })}
              pager={pager}
              onChange={handlePaginationChange}
            />
            <ResultTable
              dataSource={plugins || []}
              pagination={pager}
              onChange={handlePaginationChange}
              showHeader={true}
              columns={[
                simpleColumn(t('Name'), null, plugin => getPluginName(plugin.id, locale), true),
                actionColumn(t('Edit'), ['fal', 'edit'], handleViewHandler)
              ]}
            />
          </div>
        </Can>
      </Layout>
    );
  }
}

PluginsListPage.propTypes = {
  searchPlugins: PropTypes.func.isRequired
};

PluginsListPage.defaultProps = {
  plugins: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('PluginsListPage')(PluginsListPage);

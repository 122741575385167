import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Form, Select } from 'antd';
import { isEmpty, get } from 'lodash';

import teamCaptainFieldEN from './locales/TeamCaptainField_en.json';
import teamCaptainFieldFR from './locales/TeamCaptainField_fr.json';

class TeamCaptainField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TeamCaptainField', teamCaptainFieldEN);
    i18next.addResourceBundle('fr', 'TeamCaptainField', teamCaptainFieldFR);
  }

  render() {
    const { t, choices, data, disabled } = this.props;
    const { getFieldDecorator } = this.props.form;
    const Option = Select.Option;

    const participantsChoices = !isEmpty(choices)
      ? choices.map(choice => {
          return (
            <Option key={choice.id} value={choice.id}>{get(choice, 'member.name', '')}</Option>
          );
        })
      : null;

    return (
      <Form.Item
        label={t('Team captain')}
      >
      {getFieldDecorator('team_captain',
      { initialValue: data.id }
      )(
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder={t('Select a captain')}
          optionFilterProp="children"
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          disabled={disabled}
        >
          {participantsChoices}
        </Select>
      )}
      </Form.Item>
    );
  }

}

TeamCaptainField.defaultProps = {
  data: '',
  choices: undefined,
  disabled: false,
};

export default translate('TeamCaptainField')(TeamCaptainField);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './FundkyButton.less';

const defaultClass = 'FundkyButton';

class FundkyButton extends React.Component {
  handleClick = event => {
    if (this.props.action) {
      this.props.action.func(event, ...(this.props.action.params || []));
    } else if (this.props.link) {
      // TODO  and don't forget to add rel="noopener noreferrer" on target _blank
    }

    this.props.onClick(event);
  };

  render() {
    const {
      className,
      type,
      text,
      icon,
      size,
      loading,
      link,
      disabled,
      iconPosition,
      submit,
      onLightGrey
    } = this.props;

    // Button classes
    const buttonClasses = classnames(
      defaultClass,
      `${defaultClass}__Type--${type}`,
      `${defaultClass}__Size--${size}`,
      { onLightGrey: onLightGrey },
      className
    );

    // Icon classes
    const iconClasses = classnames(
      `${defaultClass}--icon`,
      { [`${className}--icon`]: className },
      iconPosition
    );

    const _icon = icon
      ? <span className={`${defaultClass}--iconContainer`}>
          <FontAwesomeIcon className={iconClasses} icon={icon} />
        </span>
      : null;

    return !link ? (
      <Button
        className={buttonClasses}
        type={type === 'primary' ? type : null}
        loading={loading}
        onClick={this.handleClick}
        disabled={
          type === 'disabled' || type === 'text-disabled'
            ? true
            : disabled || false
        }
        htmlType={submit ? 'submit' : 'button'}
      >
        {iconPosition === 'before' && _icon}
        {text}
        {iconPosition === 'after' && _icon}
      </Button>
    ) : (
      <a
        className={buttonClasses}
        type={type === 'primary' ? type : null}
        onClick={this.handleClick}
        disabled={
          type === 'disabled' || type === 'text-disabled'
            ? true
            : disabled || false
        }
        href={link.url}
        target={link.target}
        rel="noopener noreferrer"
      >
        {iconPosition === 'before' && _icon}
        {text}
        {iconPosition === 'after' && _icon}
      </a>
    );
  }
}

// NB:
// onClick props added to eventualy replace action props
// action pros was created to pass params with the function.
// <FundkyButton action={{ func: this.handleOnClick, params: [param1, param2, ...] }} />
// It's possible to do the same thing like this:
// <FundkyButton onClick={e => this.handleOnClick(e, param1, param2, ...)} />

FundkyButton.defaultProps = {
  className: null,
  type: 'default',
  text: null,
  icon: null,
  iconPosition: 'after',
  size: 'medium',
  loading: false,
  action: null,
  link: null,
  onLightGrey: null,
  submit: null,
  onClick: () => {}
};

FundkyButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'default',
    'cancel',
    'disabled',
    'text',
    'text-cancel',
    'text-primary',
    'text-disabled'
  ]),
  text: PropTypes.string,
  icon: PropTypes.array, //['far', 'plus']
  iconPosition: PropTypes.oneOf(['before', 'after']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  loading: PropTypes.bool,
  action: PropTypes.shape({
    func: PropTypes.func,
    params: PropTypes.array
  }), // {func: () => {}, params: []}
  link: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string
  }), // {url: 'http://', target: '_blank'}
  submit: PropTypes.bool,
  onLightGrey: PropTypes.bool,
  onClick: PropTypes.func
};

export default FundkyButton;

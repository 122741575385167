import {
  FETCH_CAMPAIGN_LIST_ONGOING_START,
  FETCH_CAMPAIGN_LIST_ONGOING_SUCCESS,
  FETCH_CAMPAIGN_LIST_ONGOING_ERROR,

  FETCH_CAMPAIGN_START,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_ERROR,

  FETCH_CAMPAIGN_DESCRIPTION_START,
  FETCH_CAMPAIGN_DESCRIPTION_SUCCESS,
  FETCH_CAMPAIGN_DESCRIPTION_ERROR,

  FETCH_CAMPAIGN_MEDIA_START,
  FETCH_CAMPAIGN_MEDIA_SUCCESS,
  FETCH_CAMPAIGN_MEDIA_ERROR,

  FETCH_CAMPAIGN_SETTINGS_START,
  FETCH_CAMPAIGN_SETTINGS_SUCCESS,
  FETCH_CAMPAIGN_SETTINGS_ERROR,

  FETCH_CAMPAIGN_LOGS_START,
  FETCH_CAMPAIGN_LOGS_SUCCESS,
  FETCH_CAMPAIGN_LOGS_ERROR,

  SAVE_CAMPAIGN_CREATE_START,
  SAVE_CAMPAIGN_CREATE_SUCCESS,
  SAVE_CAMPAIGN_CREATE_ERROR,

  UPDATE_CAMPAIGN_START,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_ERROR,

  UI_STORE_CAMPAIGN_INFORMATION,

  CLEAR_CAMPAIGN,

  FETCH_CAMPAIGN_NOTE_START,
  FETCH_CAMPAIGN_NOTE_SUCCESS,
  FETCH_CAMPAIGN_NOTE_ERROR,

  UPDATE_CAMPAIGN_NOTE_START,
  UPDATE_CAMPAIGN_NOTE_SUCCESS,
  UPDATE_CAMPAIGN_NOTE_ERROR,

  DELETE_CAMPAIGN_NOTE_START,
  DELETE_CAMPAIGN_NOTE_SUCCESS,
  DELETE_CAMPAIGN_NOTE_ERROR,
} from './campaignActions';

import {
  FETCH_DONATION_LIST_BY_CAMPAIGN_SUCCESS,
  FETCH_DONATION_LIST_BY_CAMPAIGN_ERROR
} from "../donation/donationActions";

import {
  UI_CAMPAIGN_DETAIL_CLEAR
} from '../ui/uiActions';

const initialState = {
  campaigns: [],
  campaignInformation: null,
  campaign: {
    description: {},
    media: {},
    settings: {}
  },
  createdCampaign: null,
  lastDonations: [],
  logs: {},
  notes: {},
  isFetchCampaignListInProgress: false,
  isFetchCampaignInProgress: false,
  isFetchCampaignDescriptionInProgress: false,
  isFetchCampaignMediaInProgress: false,
  isFetchCampaignSettingsInProgress: false,
  isFetchCampaignLogsInProgress: false,
  isCreateCampaignInProgress: false,
  isUpdateCampaignInProgress: false,
  isFetchCampaignNoteInProgress: false,
  isUpdateCampaignNoteInProgress: false,
  isDeleteCampaignNoteInProgress: false,
};

export default function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN_LIST_ONGOING_START:
      return {
        ...state,
        isFetchCampaignListInProgress: true
      };
    case FETCH_CAMPAIGN_LIST_ONGOING_SUCCESS:
      return {
        ...state,
        isFetchCampaignListInProgress: false,
        campaigns: action.payload
      };
    case FETCH_CAMPAIGN_LIST_ONGOING_ERROR:
      return {
        ...state,
        isFetchCampaignListInProgress: false,
        campaigns: initialState.campaigns
      };

    case FETCH_CAMPAIGN_START:
      return {
        ...state,
        isFetchCampaignInProgress: true
      };
    case FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetchCampaignInProgress: false,
        campaign: {
          ...state.campaign,
          ...action.payload
        }
      };
    case FETCH_CAMPAIGN_ERROR:
      return {
        ...state,
        isFetchCampaignInProgress: false,
        campaign: initialState.campaign
      };

    case FETCH_CAMPAIGN_DESCRIPTION_START:
      return {
        ...state,
        isFetchCampaignDescriptionInProgress: true
      };
    case FETCH_CAMPAIGN_DESCRIPTION_SUCCESS:
      return {
        ...state,
        isFetchCampaignDescriptionInProgress: false,
        campaign: {
          ...state.campaign,
          description: action.payload
        }
      };
    case FETCH_CAMPAIGN_DESCRIPTION_ERROR:
      return {
        ...state,
        isFetchCampaignDescriptionInProgress: false,
        campaign: {
          ...state.campaign,
          description: initialState.campaign.description
        }
      };

    case FETCH_CAMPAIGN_MEDIA_START:
      return {
        ...state,
        isFetchCampaignMediaInProgress: true
      };
    case FETCH_CAMPAIGN_MEDIA_SUCCESS:
      return {
        ...state,
        isFetchCampaignMediaInProgress: false,
        campaign: {
          ...state.campaign,
          media: action.payload
        }
      };
    case FETCH_CAMPAIGN_MEDIA_ERROR:
      return {
        ...state,
        isFetchCampaignMediaInProgress: false,
        campaign: {
          ...state.campaign,
          media: initialState.campaign.media
        }
      };

    case FETCH_CAMPAIGN_SETTINGS_START:
      return {
        ...state,
        isFetchCampaignSettingsInProgress: true
      };
    case FETCH_CAMPAIGN_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetchCampaignSettingsInProgress: false,
        campaign: {
          ...state.campaign,
          settings: action.payload
        }
      };
    case FETCH_CAMPAIGN_SETTINGS_ERROR:
      return {
        ...state,
        isFetchCampaignSettingsInProgress: false,
        campaign: {
          ...state.campaign,
          settings: initialState.campaign.settings
        }
      };

    case FETCH_CAMPAIGN_LOGS_START:
      return {
        ...state,
        isFetchCampaignLogsInProgress: true
      };
    case FETCH_CAMPAIGN_LOGS_SUCCESS:
      return {
        ...state,
        isFetchCampaignLogsInProgress: false,
        logs: action.payload
      };
    case FETCH_CAMPAIGN_LOGS_ERROR:
      return {
        ...state,
        isFetchCampaignLogsInProgress: false,
        logs: initialState.logs
      };

    case SAVE_CAMPAIGN_CREATE_START:
      return {
        ...state,
        isCreateCampaignInProgress: true
      };
    case SAVE_CAMPAIGN_CREATE_SUCCESS:
      return {
        ...state,
        isCreateCampaignInProgress: false,
        createdCampaign: action.payload
      };
    case SAVE_CAMPAIGN_CREATE_ERROR:
      return {
        ...state,
        isCreateCampaignInProgress: false,
        createdCampaign: initialState.createdCampaign
      };

    case UPDATE_CAMPAIGN_START:
      return {
        ...state,
        isUpdateCampaignInProgress: true
      };
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isUpdateCampaignInProgress: false
      };
    case UPDATE_CAMPAIGN_ERROR:
      return {
        ...state,
        isUpdateCampaignInProgress: false
      };

    case UI_STORE_CAMPAIGN_INFORMATION:
      return {
        ...state,
        campaignInformation: {
          ...state.campaignInformation,
          ...action.payload
        }
      };

    case UI_CAMPAIGN_DETAIL_CLEAR:
      return {
        ...state,
        campaignInformation: initialState.campaignInformation
      };

    case FETCH_DONATION_LIST_BY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        lastDonations: action.payload };

    case FETCH_DONATION_LIST_BY_CAMPAIGN_ERROR:
      return {
        ...state,
        lastDonations: initialState.lastDonations
      };

    case CLEAR_CAMPAIGN:
      return {
        ...state,
        ...initialState
      };

    case FETCH_CAMPAIGN_NOTE_START:
      return {
        ...state,
        isFetchCampaignNoteInProgress: true,
      };
    case FETCH_CAMPAIGN_NOTE_SUCCESS:
      return {
        ...state,
        isFetchCampaignNoteInProgress: false,
        notes: action.payload
      };
    case FETCH_CAMPAIGN_NOTE_ERROR:
      return {
        ...state,
        isFetchCampaignNoteInProgress: false,
        notes: initialState.notes
      };

    case UPDATE_CAMPAIGN_NOTE_START:
      return {
        ...state,
        isUpdateCampaignNoteInProgress: true,
      };
    case UPDATE_CAMPAIGN_NOTE_SUCCESS:
    case UPDATE_CAMPAIGN_NOTE_ERROR:
      return {
        ...state,
        isUpdateCampaignNoteInProgress: false,
      };

    case DELETE_CAMPAIGN_NOTE_START:
      return {
        ...state,
        isDeleteCampaignNoteInProgress: true,
      };
    case DELETE_CAMPAIGN_NOTE_SUCCESS:
    case DELETE_CAMPAIGN_NOTE_ERROR:
      return {
        ...state,
        isDeleteCampaignNoteInProgress: false,
      };

    default:
      return state;
  }
}

import React from 'react';
import { Form, Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import Markdown from 'react-remarkable';
import { get } from 'lodash';

import { isPluginActive } from '../../plugin/pluginActions';
import Plugin from '../../plugin/Plugin';
import { getLanguageIdByKey } from "Parts/common/langUtils";

import SubmitButton from 'Parts/common/SubmitButton';
import TitleField from 'Parts/ui/components/fields/TitleField';
import FirstNameField from 'Parts/ui/components/fields/FirstNameField';
import LastNameField from 'Parts/ui/components/fields/LastNameField';
import EmailField from 'Parts/ui/components/fields/EmailField';
import PostalCodeField from 'Parts/ui/components/fields/PostalCodeField';
import CountryField from 'Parts/ui/components/fields/CountryFieldContainer';
import LanguageField from './fields/LanguageField';
import NewsletterField from './fields/NewsletterField';
import TermsField from './fields/TermsField';
import OrganizationNameField from 'Parts/ui/components/fields/NameField';
import OrganizationTitleField from 'Parts/ui/components/fields/NameField';
//import WinkTooltip from '../../common/WinkTooltip';
import WinkNotification from '../../common/WinkNotification';

import {
  DONATION_CREATE_WIZARD_DONATION,
  DONATION_CREATE_WIZARD_CONFIRMATION,
  DONATION_CREATE_SURVEY,
  DONATION_CREATE_WIZARD_PAYMENT
} from '../../ui/uiUtils';

import CreateInformationForm_en from './locales/CreateInformationForm_en.json';
import CreateInformationForm_fr from './locales/CreateInformationForm_fr.json';

import './CreateInformationForm.less';

class CreateInformationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      country:
        props.infos && props.infos.country
          ? props.infos.country
          : props.user &&
            props.user.profile &&
            props.user.profile.general &&
            props.user.profile.general.country
          ? props.user.profile.general.country
          : null
    };

    this.monthly = props.infos.frequency === 'monthly';

    this.isVerifyingEmail = false;

    i18next.addResourceBundle('en', 'CreateInformationForm', CreateInformationForm_en);
    i18next.addResourceBundle('fr', 'CreateInformationForm', CreateInformationForm_fr);
  }

  handleSubmit = e => {
    e.preventDefault();
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    this.props.form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        this.props.surveyId ? this.props.handleTabChange(DONATION_CREATE_SURVEY) : this.props.handleTabChange(DONATION_CREATE_WIZARD_PAYMENT) 
        this.props.storeUserInformation(values);
      }
    });
  };

  handleBackClick = e => {
    e.preventDefault();
    this.props.handleTabChange(DONATION_CREATE_WIZARD_DONATION);
  };

  handleEmailBlur = e => {
    if (!this.props.user.profile) {
      if (!this.isVerifyingEmail) {
        this.props.form.validateFields(['email'], err => {
          if (!err) {
            this.props.verifyEmail(e.target.value);
            this.isVerifyingEmail = true;
            setTimeout(() => {
              this.isVerifyingEmail = false;
            }, 1000);
          }
        });
      }
    }
  };

  handleCancelClick = e => {
    e.preventDefault();
    this.props.cancelTransaction();
    this.props.handleTabChange(DONATION_CREATE_WIZARD_CONFIRMATION, true);
  };

  handleWinkCTA_login = e => {
    e.preventDefault();
    this.props.openLoginModal();
  };

  handleWinkCTA_register = e => {
    e.preventDefault();
    this.props.openRegisterModal();
  };

  onChangeHandler = value => {
    this.setState({
      country: value
    });
  };

  render() {
    const { t, infos, locale, platform, user, userTypeId, form, provinces } = this.props;
    const acceptedTermsConditions = get(infos, 'acceptedTermsConditions', undefined);
    const bulletinOptin = get(infos, 'bulletinOptin', undefined);
    const country = get(infos, 'country') || get(user, 'profile.general.country', undefined);
    const postalCode = get(infos, 'postalCode') || get(user, 'profile.general.postalCode', undefined);
    const email = get(infos, 'email') || get(user, 'email', undefined);
    const firstname = get(infos, 'firstname') || get(user, 'profile.general.firstName', undefined);;
    const gender = get(infos, 'gender') || get(user, 'honorificId', undefined);
    const lastname = get(infos, 'lastname') || get(user, 'profile.general.lastName', undefined);
    const defaultLocale = get(infos, 'locale') || userTypeId == 2  && get(user, 'languageId') || getLanguageIdByKey(locale) || undefined;

    const address = get(infos, 'address') || get(user, 'profile.general.address', null);
    const unit = get(infos, 'unit') || get(user, 'profile.general.unit', null);
    const city = get(infos, 'city') || get(user, 'profile.general.city', null);
    const province = get(infos, 'province') || get(user, 'profile.general.province', null);

    const organizationname = get(infos, 'organizationName', undefined);
    const organizationtitle = get(infos, 'organizationTitle', undefined);

    const organization = infos.type == 2 ? true : false;

    const OrganizationField = organization ? (
      <div className="CreateInformationForm__Divider OrgNameTitle">
        <OrganizationNameField
          className="CreateInformationForm__Field"
          form={form}
          initialValue={organizationname}
          fieldId="organizationName"
          label={false}
          placeholder={true}
          required={true}
          identity="company"
          maxlength={75}
        />
        <OrganizationTitleField
          className="CreateInformationForm__Field"
          form={form}
          initialValue={organizationtitle}
          fieldId="organizationTitle"
          label={false}
          placeholder={true}
          required={true}
          identity="job"
          maxlength={75}
        />
      </div>
    ) : null;

    const TaxReceiptInformationFields = isPluginActive(platform, 'TaxReceipt') ? (
      <Plugin
        name="TaxReceipt"
        component="PublicInformationFields"
        {...{
          form,
          provinces,
          locale,
          organization,
          savedValues: {
            province,
            address,
            unit,
            city
          }
        }}
      />
    ) : null;

    const infoTitle = this.monthly && t('Log in or create your  account') || t('Fundky account');

    const infoDesc = this.monthly &&
    (
      <React.Fragment>
        <p>{t('monthly-donations')}</p>
        <Button className="WinkTooltip__CTA" onClick={this.handleWinkCTA_login}>
          {t('Connection')}
        </Button>
        <Button className="WinkTooltip__CTA" onClick={this.handleWinkCTA_register}>
          {t('Create account')}
        </Button>
      </React.Fragment>
    ) ||
    (
      <React.Fragment>
        <Markdown container="span">{t('get connected')}</Markdown>
        <Button className="WinkTooltip__CTA" onClick={this.handleWinkCTA_login}>
          {t('Connection')}
        </Button>
      </React.Fragment>
    );

    return (
      <div className="FundkyWrap">
        <Form className="CreateInformationForm" onSubmit={this.handleSubmit}>
          <h2>{t('title')}</h2>
          {this.props.userTypeId === 1 && (
            <WinkNotification title={infoTitle} description={infoDesc} closable={false} />
          )}
          {!this.monthly &&
          <>
          <p>{t('enter-info')}</p>
          <div className="CreateInformationForm__Divider">
            <TitleField
              className=""
              form={form}
              initialValue={gender || undefined}
              fieldFormat="radio"
              label={false}
            />
          </div>
          <div className="CreateInformationForm__Divider FirstNameLastName">
            <FirstNameField
              className="CreateInformationForm__Field"
              form={form}
              initialValue={firstname || undefined}
              label={false}
              placeholder={true}
            />
            <LastNameField
              className="CreateInformationForm__Field"
              form={form}
              initialValue={lastname || undefined}
              label={false}
              placeholder={true}
            />
          </div>
          <div className="CreateInformationForm__Divider EmailLanguage">
            <EmailField
              className="CreateInformationForm__Field"
              form={form}
              initialValue={email || undefined}
              onBlur={this.handleEmailBlur}
              label={false}
              placeholder={true}
            />
            <LanguageField
              className="CreateInformationForm__Field"
              form={form}
              savedValue={defaultLocale}
            />
          </div>
          {OrganizationField}
          <div className="CreateInformationForm__Divider ZipCodeCountry">
            <CountryField
              className="CreateInformationForm__Field"
              form={form}
              initialValue={country || undefined}
              label={false}
              placeholder={true}
              onChangeHandler={this.onChangeHandler}
              required={true}
              identity={organization ? 'company' : 'user'}
            />
            <PostalCodeField
              className="CreateInformationForm__Field"
              form={form}
              initialValue={postalCode || undefined}
              label={false}
              placeholder={true}
              required={true}
              country={this.state.country}
              identity={organization ? 'company' : 'user'}
            />
          </div>
          {TaxReceiptInformationFields}
          <div className="CreateInformationForm__Checkboxes">
            <NewsletterField
              className="CreateInformationForm__Field CreateInformationForm__CompactField"
              form={form}
              savedValue={bulletinOptin || undefined}
              platform={this.props.platform}
              organization={this.props.organization}
            />
            <TermsField
              className="CreateInformationForm__Field CreateInformationForm__CompactField"
              form={form}
              savedValue={acceptedTermsConditions || undefined}
            />
          </div>
          <div className="CreateInformationForm__SubmitWrapper">
            <Button className="Back-btn" onClick={this.handleBackClick}>
              {t('back')}
            </Button>
            <SubmitButton>{t('next')}</SubmitButton>
          </div>
          <div className="CreateInformationForm__CancelWrapper">
            <Popconfirm
              placement="topLeft"
              getPopupContainer={() => document.getElementById('popContainer2')}
              title={t('DonationPage:cancelText')}
              onConfirm={this.handleCancelClick}
              okText={t('DonationPage:yes')}
              cancelText={t('DonationPage:no')}
            >
              <span id="popContainer2">{t('cancel')}</span>
            </Popconfirm>
          </div>
          {/*this.props.userTypeId === 1 &&
          <WinkTooltip
            title={t('Fundky account')}
            details={t('get connected')}
            elements={
              <Button className="WinkTooltip__CTA" onClick={this.handleWinkCTA}>
                {t('Connection')}
              </Button>
            }
            display={true}
          />
          */}
          </>}
        </Form>
      </div>
    );
  }
}

CreateInformationForm.propTypes = {
  form: PropTypes.object.isRequired,
  storeUserInformation: PropTypes.func.isRequired,
  history: PropTypes.object
};

export default translate('CreateInformationForm')(CreateInformationForm);

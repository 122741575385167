import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Can from 'Parts/session/components/CanContainer';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';

import participantsListPageEN from './locales/ParticipantsListPage_en.json';
import participantsListPageFR from './locales/ParticipantsListPage_fr.json';

class ParticipantsListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle('en', 'ParticipantsListPage', participantsListPageEN);
    i18next.addResourceBundle('fr', 'ParticipantsListPage', participantsListPageFR);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchParticipants(
      this.props.platformId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  handleViewHandler = participant => {
    const { history, locale } = this.props;
    return history.push(
      `/${locale}/d/campaigns/${participant.campaignId}/participants/${participant.id}`,
      { originIsAll: true }
    );
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchParticipants(
      this.props.platformId,
      filters.search,
      filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  render() {
    const { props, state, handleSearch, handleViewHandler, handlePaginationChange } = this,
      { t, participants, locale, pagination, reportParticipants } = props,
      { pager } = state;

    return (
      <Layout className="ParticipantsListPage">
        <Helmet>
          <title>{t('Participants')}</title>
        </Helmet>
        <SearchBar title={t('Participants')} placeholder={t('Search')} onSearch={handleSearch} />
        <Can rules={['permissions.platform.participant.get']} redirect={`/${locale}/d`}>
          <div className="AdminList">
            <PaginationTop
              totalLabel={total => t('participantsTotal', { count: pagination.totalResults })}
              pager={pager}
              onChange={handlePaginationChange}
            />
            <ResultTable
              dataSource={participants || []}
              pagination={pager}
              onChange={handlePaginationChange}
              showHeader={true}
              scroll={{ x: true }}
              columns={[
                //simpleColumn(t('Participant ID'), 'id', null, true),
                simpleColumn(t('Full name'), 'member.name', null, true),
                simpleColumn(t('Email address'), 'member.email', null, true),
                dateColumn(t('Registered on'), 'addedOn', null, null, false, locale),
                actionColumn(t('Edit'), ['fal', 'edit'], handleViewHandler)
              ]}
            />
          </div>
        </Can>
      </Layout>
    );
  }
}

ParticipantsListPage.propTypes = {
  searchParticipants: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired
};

ParticipantsListPage.defaultProps = {
  participants: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('ParticipantsListPage')(ParticipantsListPage);

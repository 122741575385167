import React from 'react';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { translate } from 'react-i18next';

import { readTwitchConnectCode, clearTwitchConnectCode } from 'Parts/common/cookieUtils';
import { twitchConnect, setSessionLanguage } from 'Parts/session/sessionActions';

class TwitchConnectRedirect extends React.PureComponent {
  constructor(props) {
    super(props);

    const { search } = props.location;
    const query = new URLSearchParams(search);
    const state = query.get('state') && decodeURI(query.get('state')) || null;

    this.state = {};
    this.state.error = query.get('error') || null;
    this.state.error_description = query.get('error_description') || null;
    this.state.redirect = state && state.split('?validation=')[0] && decodeURI(state.split('?validation=')[0]) || null;
    this.state.cookiedCode = readTwitchConnectCode() || null;
    this.state.validation = state && state.split('?validation=')[1] || null;
    this.state.code = query.get('code') || null;
    this.state.scope = query.get('scope') || null;
    this.state.locale = this.state.redirect && this.state.redirect.split('/')[1] || 'fr';
    this.state.login = this.state.locale === 'fr'
        ? '/fr/connexion'
        : '/en/login';
  }

  componentDidMount() {
    this.props.i18n.changeLanguage(this.state.locale);
    this.props.setSessionLanguage(this.state.locale).then(() => {
        document.getElementsByTagName('html')[0].lang = this.state.locale;

        if (
            this.state.cookiedCode &&
            this.state.validation &&
            this.state.cookiedCode === this.state.validation &&
            this.state.code && this.state.scope
        ) {
            this.state.connect = this.props.twitchConnect(this.state.code, this.state.scope, this.props.organizationId, this.props.platformId)
                .then((res) => {
                    clearTwitchConnectCode();
                    if (res.type && res.type == 'AUTH_TWITCH_CHECK_EMAIL') {
                        this.props.history.replace(this.state.login + "?error=twitch");
                    } else
                    if (res.type && res.type == 'AUTH_TWITCH_CONNECT_ERROR') {
                        this.props.history.replace(this.state.login + "?error=" + (res.payload && res.payload.code || res.error && res.error.code || res.type));
                    } else {
                        this.props.history.replace(this.state.redirect);
                    }
                })
                .catch((error) => {
                    console.log("componentDidMount error: ", error);
                    console.error(error);
                    clearTwitchConnectCode();
                    this.props.history.replace('/');
                });
        } else
        if (this.state.error && this.state.error_description) {
            clearTwitchConnectCode();
            this.props.history.replace(this.state.login + '?error=' + this.state.error + '&error_description=' + this.state.error_description);
        } else {
            clearTwitchConnectCode();
            this.props.history.replace('/');
        }
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
    platformId: state.platform.platform.id,
    organizationId: state.organization.organization.id,
    locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
    twitchConnect,
    setSessionLanguage
}, dispatch);


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    translate()
)(TwitchConnectRedirect);


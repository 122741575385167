import {
  connect
} from "react-redux";
import {
  bindActionCreators,
  compose
} from "redux";

import dataFetcher from "../parts/chore/dataFetcher";

import {
  clearCampaign
} from '../campaign/campaignActions';

import CheckTeamRoute from './CheckTeamRoute';

const mapStateToProps = state => {
  return ({
    team: state.team.team,
    isFetchTeamInProgress: state.team.isFetchTeamInProgress,
    isFetchCampaignInProgress: state.campaign.isFetchCampaignInProgress
  });
};

const mapDispatchToProps = dispatch => bindActionCreators({
  clearCampaign
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(CheckTeamRoute);

import {
  bindActionCreators,
  compose
} from "redux";
import {
  connect
} from "react-redux";
import {
  withRouter
} from 'react-router';
import {
  get
} from 'lodash';

import {
  checkAuthorization
} from '../../common/authorizationUtils';
// import {
//   clearMemberPermissions,
//   clearMemberAdminPermissions,
//   clearMemberRoles
// } from "../sessionActions";

import Can from './Can';

const mapStateToProps = state => ({
  permissions: get(state, 'session.permissions', {}),
  roles: get(state, 'session.roles', {}),
  _organizationId: get(state, 'organizition.organizition.id', null),
  _platformId: get(state, 'platform.platform.id', null),
  _campaignId: get(state, 'campaign.campaign.id', null),
  _teamId: get(state, 'team.team.id', null),
  _participantMemberId: get(state, 'participant.participant.memberId', null),
  _memberId: get(state, 'member.member.id', null),
  _userId: get(state, 'session.userId', null),
  isFetchMemberCampaignPermissionsInProgress: get(state,
    'session.isFetchMemberCampaignPermissionsInProgress', false),
  isFetchMemberCampaignRolesInProgress: get(state,
    'session.isFetchMemberCampaignRolesInProgress', false),
  isFetchMemberOrganizationPermissionsInProgress: get(state,
    'session.isFetchMemberOrganizationPermissionsInProgress', false),
  isFetchMemberOrganizationRolesInProgress: get(state,
    'session.isFetchMemberOrganizationRolesInProgress', false),
  isFetchMemberPermissionsInProgress: get(state, 'session.isFetchMemberPermissionsInProgress',
    false),
  isFetchMemberPlatformPermissionsInProgress: get(state,
    'session.isFetchMemberPlatformPermissionsInProgress', false),
  isFetchMemberPlatformRolesInProgress: get(state,
    'session.isFetchMemberPlatformRolesInProgress', false),
  isFetchMemberRolesInProgress: get(state, 'session.isFetchMemberRolesInProgress', false),
  isFetchMemberTeamPermissionsInProgress: get(state,
    'session.isFetchMemberTeamPermissionsInProgress', false),
  isFetchMemberTeamRolesInProgress: get(state, 'session.isFetchMemberTeamRolesInProgress',
    false)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  // clearMemberPermissions,
  // clearMemberAdminPermissions,
  // clearMemberRoles,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Can);

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import TransactionsPageFormContainer from '../../transactions/components/TransactionsPageFormContainer';
import TransactionsHistoryPageContainer from '../../transactions/components/TransactionsHistoryPageContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import AdminBackButtonBar from 'Parts/common/AdminBackButtonBar';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import { isPluginActive } from '../../../plugin/pluginActions';
import Plugin from '../../../plugin/Plugin';

import campaignsTransactionsPageEN from './locales/CampaignsTransactionsPage_en.json';
import campaignsTransactionsPageFR from './locales/CampaignsTransactionsPage_fr.json';

class CampaignsTransactionsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      historyTabPermissions: null,
      displayDonationReceiptPane: null
    };

    this.from = props.location.state && props.location.state.originIsAll;
    this.fromUrl = props.location.state && props.location.state.fromUrl;

    i18next.addResourceBundle('en', 'CampaignsTransactionsPage', campaignsTransactionsPageEN);
    i18next.addResourceBundle('fr', 'CampaignsTransactionsPage', campaignsTransactionsPageFR);
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { state } = this.props.location;
    if (state && (state.from || state.fromUrl)) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }

    this.setState({
      historyTabPermissions: this.props.checkAuthorization({ campaign: this.props.campaign.id }, [
        'permissions.campaign.transaction.logs'
      ]),
      displayDonationReceiptPane: this.props.checkAuthorization(
        { platform: this.props.platform.id, campaign: this.props.campaign.id },
        [
          [
            'roles.platform.OWNER',
            'permissions.campaign.plugin.taxReceipt.cancel',
            'permissions.campaign.plugin.taxReceipt.create',
            'permissions.campaign.plugin.taxReceipt.download',
            'permissions.campaign.plugin.taxReceipt.send',
            () => this.props.organization.typeId === 2
          ],
          [
            'roles.platform.SUPERADMIN',
            'permissions.campaign.plugin.taxReceipt.cancel',
            'permissions.campaign.plugin.taxReceipt.create',
            'permissions.campaign.plugin.taxReceipt.download',
            'permissions.campaign.plugin.taxReceipt.send',
            () => this.props.organization.typeId === 2
          ]
        ]
      )
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.campaignID !== prevProps.match.campaignID) {
      if (this.props.location.pathname.indexOf(':transactionID')) {
        const transactionsListUrl = this.props.location.pathname.replace(':transactionID', 'list');
        this.props.history.replace(transactionsListUrl);
      }
    }

    if (this.state.historyTabPermissions === null) {
      const authorization = this.props.checkAuthorization({ campaign: this.props.campaign.id }, [
        'permissions.campaign.transaction.logs'
      ]);
      if (typeof authorization === 'boolean') {
        this.setState({
          historyTabPermissions: authorization
        });
      }
    }

    if (this.state.displayDonationReceiptPane === null) {
      const authorization = this.props.checkAuthorization(
        { platform: this.props.platform.id, campaign: this.props.campaign.id },
        [
          [
            () => (isPluginActive(this.props.platform, 'TaxReceipt') ? true : false),
            () => this.props.organization.typeId === 2,
            'roles.platform.OWNER',
            'permissions.campaign.plugin.taxReceipt.cancel',
            'permissions.campaign.plugin.taxReceipt.create',
            'permissions.campaign.plugin.taxReceipt.download',
            'permissions.campaign.plugin.taxReceipt.send'
          ],
          [
            () => (isPluginActive(this.props.platform, 'TaxReceipt') ? true : false),
            () => this.props.organization.typeId === 2,
            'roles.platform.SUPERADMIN',
            'permissions.campaign.plugin.taxReceipt.cancel',
            'permissions.campaign.plugin.taxReceipt.create',
            'permissions.campaign.plugin.taxReceipt.download',
            'permissions.campaign.plugin.taxReceipt.send'
          ]
        ]
      );
      if (typeof authorization === 'boolean') {
        this.setState({
          displayDonationReceiptPane: authorization
        });
      }
    }
  }

  render() {
    const {
      t,
      match,
      locale,
      transaction,
      platform,
      campaign,
      permissions,
      location,
      checkAuthorization,
      dispatch
    } = this.props;
    const transactionID = transaction ? transaction.referenceId : '';

    const transactionsListUrl = this.from
      ? `/${locale}/d/transactions/list`
      : this.fromUrl
        ? `/${locale}/d/transactions/filter${this.fromUrl}`
        : `/${locale}/d/campaigns/${this.props.match.params.campaignID}/transactions/list/${transaction && transaction.typeId === 3 ? 'tickets' : 'donations'}`;

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: props => (
          <Can
            rules={['permissions.campaign.transaction.get']}
            campaignId={get(match, 'params.campaignID', null)}
            redirect={`/${locale}/d`}
          >
            <TransactionsPageFormContainer {...this.props} />
          </Can>
        )
      },
      {
        title: t('DonationReceipt'),
        slug: 'donationreceipt',
        visible: this.state.displayDonationReceiptPane,
        container: props => (
          <Can
            campaignId={campaign.id}
            platformId={platform.id}
            redirect={`/${locale}/d`}
            rules={[
              [
                () => (isPluginActive(platform, 'TaxReceipt') ? true : false),
                () => this.props.organization.typeId === 2,
                'roles.platform.OWNER',
                'permissions.campaign.plugin.taxReceipt.cancel',
                'permissions.campaign.plugin.taxReceipt.create',
                'permissions.campaign.plugin.taxReceipt.download',
                'permissions.campaign.plugin.taxReceipt.send'
              ],
              [
                () => (isPluginActive(platform, 'TaxReceipt') ? true : false),
                () => this.props.organization.typeId === 2,
                'roles.platform.SUPERADMIN',
                'permissions.campaign.plugin.taxReceipt.cancel',
                'permissions.campaign.plugin.taxReceipt.create',
                'permissions.campaign.plugin.taxReceipt.download',
                'permissions.campaign.plugin.taxReceipt.send'
              ]
            ]}
          >
            <Plugin
              name="TaxReceipt"
              component="TaxReceiptTabRoute"
              {...{
                // Props
                locale: locale,
                transaction: transaction,
                campaignId: campaign.id,
                permissions: permissions,
                dispatch: dispatch
              }}
            />
          </Can>
        )
      },
      {
        title: t('LOGS:History'),
        slug: 'history',
        visible: this.state.historyTabPermissions,
        container: props => (
          <Can
            rules={['permissions.campaign.transaction.logs']}
            campaignId={get(match, 'params.campaignID', null)}
            redirect={`/${locale}/d`}
          >
            <TransactionsHistoryPageContainer {...this.props} />
          </Can>
        )
      }
    ];

    return (
      <Layout className="CampaignsTransactionsPage">
        <Helmet>
          <title>{t('Transaction')}</title>
        </Helmet>
        <AdminBackButtonBar href={transactionsListUrl} text={t('Back to search')} />
        <AdminTitleBar
          title={t('Transaction')}
          subName={`${t('Transaction ID')} ${transactionID}`}
        />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} defaultTabHasNoSlug />
      </Layout>
    );
  }
}

export default translate('CampaignsTransactionsPage')(CampaignsTransactionsPage);

import React from 'react';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';

import AdminTitleBar from 'Parts/common/AdminTitleBar';
import DesignSettingsForm from './components/DesignSettingsFormContainer';
import Can from 'Parts/session/components/CanContainer';

import designSettingsPageEN from './locales/DesignSettingsPage_en.json';
import designSettingsPageFR from './locales/DesignSettingsPage_fr.json';

class DesignSettingsPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'DesignSettingsPage', designSettingsPageEN);
    i18next.addResourceBundle('fr', 'DesignSettingsPage', designSettingsPageFR);
  }

  render() {
    const { t, locale } = this.props;

    return (
      <Layout className="DesignSettingsPage">
        <Helmet>
          <title>{t('Design')}</title>
        </Helmet>

        <AdminTitleBar title={t('Design')} />

        <div className="AdminPanel">
          <Can rules={['permissions.platform.platform.update']} redirect={`/${locale}/d`}>
            <DesignSettingsForm />
          </Can>
        </div>
      </Layout>
    );
  }
}

export default translate('DesignSettingsPage')(DesignSettingsPage);

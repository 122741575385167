import { connect } from "react-redux";
import { compose } from "redux";

import AvatarThumbnail from './AvatarThumbnail';

const mapStateToProps = state => {
  return ({
    locale: state.session.language
  });
};

export default compose(
  connect(mapStateToProps)
)(AvatarThumbnail);

import React from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import AdminTitleBar from 'Parts/common/AdminTitleBar';
import InformationForm from './components/InformationFormContainer';
import DonationsList from './components/DonationsListContainer';
import ParticipationsList from './components/ParticipationsListContainer';
import AdminBackButtonBar from 'Parts/common/AdminBackButtonBar.jsx';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import MembersPageEN from './locales/MembersPage_en.json';
import MembersPageFR from './locales/MembersPage_fr.json';

class MembersPage extends React.Component {
  constructor(props) {
    super(props);

    this.from = props.location.state && props.location.state.from;

    i18next.addResourceBundle('en', 'MembersPage', MembersPageEN);
    i18next.addResourceBundle('fr', 'MembersPage', MembersPageFR);
  }

  componentDidMount() {
    // Erase the location state (the info has been saved in the constructor).
    const { state } = this.props.location;
    if (state && state.from) {
      this.props.history.replace({ ...this.props.location, state: {} });
    }
  }

  componentWillUnmount() {
    this.props.clearMember();
  }

  render() {
    const { t, locale, member } = this.props;
    const subname = t('member-since') + ' ' + moment(member.addedOn).format('LL');

    const membersListUrl = `/${locale}/d/members/list`;

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: props => (
          <Can
            rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
            redirect={`/${locale}/d`}
          >
            <InformationForm {...this.props} />
          </Can>
        )
      },
      {
        title: t('Donations'),
        slug: 'donations',
        container: props => (
          <Can
            rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
            redirect={`/${locale}/d`}
          >
            <DonationsList {...this.props} />
          </Can>
        )
      },
      {
        title: t('Participations'),
        slug: 'participations',
        container: props => (
          <Can
            rules={[['roles.platform.OWNER'], ['roles.platform.SUPERADMIN']]}
            redirect={`/${locale}/d`}
          >
            <ParticipationsList {...this.props} />
          </Can>
        )
      }
      //{title: t('Contributions'), slug: 'donations', container: (props) => <ContributionsForm {...this.props} />}
    ];

    return (
      <div className="MembersPage">
        <Helmet>
          <title>{t('member')}</title>
        </Helmet>
        <AdminBackButtonBar href={membersListUrl} text={t('Back to search')} />
        <AdminTitleBar title={member.name} subTitle={subname} />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} />
      </div>
    );
  }
}

export default translate('MembersPage')(MembersPage);

import React from 'react';
import { Link } from 'react-router-dom';
import PublicCarousel from '../../common/PublicCarousel';
import PublicPanel from '../../common/PublicPanel';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import PublicPartnerPanel_en from './locales/PublicPartnerPanel_en.json';
import PublicPartnerPanel_fr from './locales/PublicPartnerPanel_fr.json';

class PublicPartnerPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicPartnerPanel', PublicPartnerPanel_en);
    i18next.addResourceBundle('fr', 'PublicPartnerPanel', PublicPartnerPanel_fr);
  }

  render() {
    const { t } = this.props;
    const partnerBlocks = [
      <Link to="" key="">
        <img height="98" src="http://logok.org/wp-content/uploads/2014/08/WWF_logo1-880x704.png" />
      </Link>,
      <Link to="" key="">
        <img
          height="98"
          src="https://vignette.wikia.nocookie.net/logopedia/images/d/d2/Fanta_logo_global.svg/revision/latest/scale-to-width-down/200?cb=20161127132814"
        />
      </Link>,
      <Link to="" key="">
        <img
          height="98"
          src="http://cdn2.bigcommerce.com/server1500/ac84d/products/1269/images/2876/Nike_Logo__97043.1340418202.380.380.jpg?c=2"
        />
      </Link>,
      <Link to="" key="">
        <img
          height="98"
          src="https://cdn.vectorlogosupply.com/logos/large/2x/skype-3-png-transparent-logo.png"
        />
      </Link>,
      <Link to="" key="">
        <img
          height="98"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Olympic_flag.svg/660px-Olympic_flag.svg.png"
        />
      </Link>,
      <Link to="" key="">
        <img height="98" src="https://unity3d.com/files/images/ogimg.jpg?1" />
      </Link>
    ];

    return (
      <PublicPanel className="PublicPartnerPanel" title={t('partners')}>
        <PublicCarousel
          className="PublicPartnerPanel__Carousel"
          slidesToShow={5}
          sideControls
          initialSlideHeight={98}
        >
          {partnerBlocks}
        </PublicCarousel>
      </PublicPanel>
    );
  }
}

PublicPartnerPanel.propTypes = {};

export default translate('PublicPartnerPanel')(PublicPartnerPanel);

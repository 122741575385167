import React from 'react';
import { Form } from 'antd';
import { Collapse } from 'react-collapse';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

import MoneyField from 'Parts/ui/components/fields/MoneyField';
import SelectField from 'Parts/ui/components/fields/SelectField';
import DatePickerField from 'Parts/ui/components/fields/DatePickerField';
import PostalCodeField from 'Parts/ui/components/fields/PostalCodeField';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';
import TextField from 'Parts/ui/components/fields/TextField';
import CountryField from 'Parts/ui/components/fields/CountryFieldContainer';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import AddRecipientModalContent from './AddRecipientModalContentContainer';
import Can from 'Parts/session/components/CanContainer';
import Plugin from '../../../plugin/Plugin';

import { isPluginActive } from '../../../plugin/pluginActions';
import { getLanguageIdByKey } from 'Parts/common/langUtils';
import {
  simpleFieldValidator,
  nameFieldValidator,
  emailFieldValidator
} from 'Parts/ui/validations/fieldValidations';

import AddTransactionsPageForm_en from './locales/AddTransactionsPageForm_en.json';
import AddTransactionsPageForm_fr from './locales/AddTransactionsPageForm_fr.json';

import './TransactionsPageForm.less';

class AddTransactionsPageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCorpoInfo:
        props.transaction && props.transaction.donation && props.transaction.donation.typeId === 2
          ? true
          : false,
      country:
        props.transaction && props.transaction.donation && props.transaction.donation.country
          ? props.transaction.donation.country
          : null,
      administrator: null,
      campaignOwner: null,
      owner: props.userId === props.transaction.userId,
      requestUpdateModalVisible: false,
      target: null
    };

    this.campaignId = get(props, 'match.params.campaignID', null);

    this.refAddRecipientModal = React.createRef();
    this.refAddRecipientModalContent = React.createRef();

    i18next.addResourceBundle('en', 'AddTransactionsPageForm', AddTransactionsPageForm_en);
    i18next.addResourceBundle('fr', 'AddTransactionsPageForm', AddTransactionsPageForm_fr);
  }

  componentDidMount() {
    this.setState({
      administrator: this.props.checkAuthorization({ platform: this.props.platform.id }, [
        ['roles.platform.OWNER'],
        ['roles.platform.SUPERADMIN']
      ]),
      campaignOwner: this.props.checkAuthorization({ campaign: this.props.campaign.id }, [
        'roles.campaign.OWNER'
      ])
    });
  }

  componentDidUpdate(prevProps) {
    const { props, state } = this,
      { transaction, checkAuthorization, platform, campaign } = props,
      { administrator, campaignOwner } = state;

    if (transaction !== prevProps.transaction) {
      this.setState({
        showCorpoInfo: get(transaction, 'donation.typeId', 0) === 2 ? true : false,
        country: get(transaction, 'donation.country', null)
      });
    }

    let authorization;
    if (administrator === null) {
      authorization = checkAuthorization({ platform: platform.id }, [
        ['roles.platform.OWNER'],
        ['roles.platform.SUPERADMIN']
      ]);
      if (typeof authorization === 'boolean') {
        this.setState({
          administrator: authorization
        });
      }
    }

    if (campaignOwner === null) {
      authorization = checkAuthorization({ campaign: campaign.id }, ['roles.campaign.OWNER']);
      if (typeof authorization === 'boolean') {
        this.setState({
          campaignOwner: authorization
        });
      }
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    const { props } = this,
      { form, createOfflineDonation, history, locale } = props,
      { validateFields } = form;

    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const values = {
          ...allValues,
          transaction_date: allValues['transaction_date'].format('YYYY-MM-DD HH:mm:ss'),
          anonymous_donation: allValues.anonymous_donation ? true : false
        };

        for (let key in values) {
          if (values[key] === '') values[key] = undefined;
        }

        createOfflineDonation(values).then(response => {
          if (get(response, 'payload.donation.target.type') === 'platform') {
            history.push(`/${locale}/d/transactions/${get(response, 'payload.transaction.id')}`);
          } else {
            history.push(
              `/${locale}/d/campaigns/${get(response, 'payload.donation.target.path.campaign.id')}/transactions/${get(response, 'payload.transaction.id')}`,
              { originIsAll: true }
            );
          }
        });
      }
    });
  };

  handleChange = value => {
    if (this.state.showCorpoInfo && value !== 2) {
      this.setState({
        showCorpoInfo: false
      });
      this.props.form.setFieldsValue({
        organizationName: '',
        organizationTitle: ''
      });
    } else if (!this.state.showCorpoInfo && value === 2) {
      this.setState({
        showCorpoInfo: true
      });
    }
  };

  onCountryChange = value => {
    this.setState({
      country: value
    });
  };

  onClickCancel = () => {
    this.props.form.resetFields();
  };

  handleDisplayAddRecipientModal = e => {
    e.preventDefault();
    this.refAddRecipientModal.current.getWrappedInstance('FundkyModal').showModal();
  };

  onAcceptModal = e => {
    this.refAddRecipientModalContent.refs.wrappedInstance.handleSubmit();
  };

  handleSelectRecipient = recipient => {
    this.props.form.setFieldsValue({
      recipient: recipient
    });
  };

  onCancelModal = () => {
    this.refAddRecipientModalContent.refs.wrappedInstance.handleReset();
  };

  closeModal = () => {
    this.refAddRecipientModal.current.getWrappedInstance('FundkyModal').handleCancel(null);
  };

  onAcceptRequestUpdateModal = () => {
    const { transaction, form } = this.props;
    const { getFieldValue, validateFields } = form;

    validateFields(['request_update'], { force: true }, (err, allValues) => {
      if (!err) {
        this.props
          .requestTransactionUpdate(transaction.userId, transaction.id, allValues.request_update)
          .then(this.setState({ requestUpdateModalVisible: false }));
      }
    });
  };

  onCancelRequestUpdateModal = () => {
    this.setState({ requestUpdateModalVisible: false });
  };

  getRecipientName = values => {
    const { t } = this.props;

    if (values) {
      if (values.platform) {
        return get(values, 'platform.name')
          ? `${t('platform')} ${get(values, 'platform.name')}`
          : null;
      } else if (values.campaign) {
        return get(values, 'campaign.name')
          ? `${t('campaign')} ${get(values, 'campaign.name')}`
          : null;
      } else if (values.team) {
        return get(values, 'team.name') ? `${t('team')} ${get(values, 'team.name')}` : null;
      } else if (values.participant) {
        return get(values, 'participant.member.name')
          ? `${t('participant')} ${get(values, 'participant.member.name')}`
          : null;
      }
    }

    return null;
  };

  render() {
    const { props, state, getRecipientName, campaignId } = this,
      { t, locale, form, provinces, isDonationInProgress, campaign, platform } = props,
      { showCorpoInfo } = state;

    const recipient =
      form.getFieldValue('recipient') ||
      (campaignId && campaign && { campaign: campaign }) ||
      undefined;
    const recipientName = getRecipientName(recipient);

    const paymentMethodOptions = [
      { value: 1, text: t('card') },
      { value: 3, text: t('cash') },
      { value: 2, text: t('cheque') }
    ];

    const anonymousOptions = [
      { value: 0, text: t('No') },
      { value: 1, text: t('Yes') }
    ];

    const transactionTypeOptions = [
      { value: 1, text: t('Personal') },
      { value: 2, text: t('Corporate') },
      { value: 3, text: t('Combined') }
    ];

    const titleOptions = [
      { value: 1, text: t('Mr') },
      { value: 2, text: t('Ms') },
      { value: 3, text: t('Miss') },
      { value: 4, text: t('Mx') }
    ];

    const languageOptions = [
      { value: 1, text: t('English') },
      { value: 2, text: t('French') }
    ];

    const TaxReceiptInformationFields =
      (isPluginActive(platform, 'TaxReceipt') && this.props.organization.typeId === 2 && (
        <Plugin
          name="TaxReceipt"
          component="AdminInformationFields"
          {...{
            // Props
            form: form,
            provinces: provinces,
            locale: locale,
            organization: showCorpoInfo
          }}
        />
      )) ||
      null;

    return (
      <Form className="AddTransactionsPageForm" onSubmit={this.handleSubmit}>
        <div className="inputs">
          <h3>{t('Transaction details')}</h3>
          <DatePickerField
            form={form}
            fieldId="transaction_date"
            initialValue={moment()}
            showTime={true}
            label={t('Transaction date')}
            allowClear={false}
            required={true}
            requiredMessage={t('transaction-date-required')}
            hasFeedback
            past
          />
          <SelectField
            form={form}
            label={t('Payment method')}
            fieldId={'payment_method'}
            optionsArray={paymentMethodOptions}
            style={{ width: 200 }}
            required={true}
            requiredMessage={t('payment-method-required')}
          />
          <MoneyField
            form={form}
            fieldId="transaction_amount"
            label={t('Transaction Amount')}
            required={true}
            locale={locale}
            maximum={9999999}
            minimum={0.01}
            initialValue={0.01}
          />

          <h3>{t('Donation details')}</h3>
          <SelectField
            form={form}
            label={t('Anonymous donation')}
            fieldId={'anonymous_donation'}
            optionsArray={anonymousOptions}
            style={{ width: 100 }}
            disabled={this.state.administrator || this.state.owner ? false : true}
            initialValue={0}
          />
          <Form.Item label={t('Recipient')} hasFeedback>
            {form.getFieldDecorator('recipient', {
              rules: [
                {
                  required: true,
                  message: t('recipient-required')
                }
              ],
              initialValue: recipient
            })(
              recipientName ? (
                <span id="recipient">
                  {`${recipientName} `}
                  <Can
                    rules={[
                      ['roles.platform.OWNER', () => get(campaign, 'typeId', null) !== 4],
                      ['roles.platform.SUPERADMIN', () => get(campaign, 'typeId', null) !== 4]
                    ]}
                  >
                    <a
                      className="transaction-recipient-edit-icon"
                      onClick={this.handleDisplayAddRecipientModal}
                    >
                      <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
                    </a>
                  </Can>
                </span>
              ) : (
                <a
                  id="recipient"
                  className="transaction-recipient-btn"
                  onClick={this.handleDisplayAddRecipientModal}
                >
                  {t('select-recipient')}
                </a>
              )
            )}
          </Form.Item>
          <FundkyModal
            ref={this.refAddRecipientModal}
            displayModal={false}
            titleIcon={['fal', 'user-edit']}
            title={t('add-recipient-modal-title')}
            acceptLabel={t('add-recipient-modal-confirm')}
            acceptIcon={['fal', 'save']}
            acceptAction={this.onAcceptModal}
            cancelAction={this.onCancelModal}
          >
            <AddRecipientModalContent
              wrappedComponentRef={inst => (this.refAddRecipientModalContent = inst)}
              //target={{}}
              closeModal={this.closeModal}
              handleSubmit={this.handleSelectRecipient}
              campaignId={this.campaignId}
            />
          </FundkyModal>

          <h3>{t('Donor information')}</h3>
          <SelectField
            form={form}
            label={t('Donation type')}
            fieldId={'donation_type'}
            optionsArray={transactionTypeOptions}
            style={{ width: 200 }}
            onChange={this.handleChange}
            disabled={!this.state.administrator}
            required={true}
            requiredMessage={t('donation-type-required')}
          />
          <SelectField
            form={form}
            label={t('Title')}
            fieldId={'title'}
            optionsArray={titleOptions}
            style={{ width: 100 }}
            disabled={!this.state.administrator}
            required={true}
            requiredMessage={t('title-required')}
          />
          <TextField
            form={form}
            label={t('FIELDS:first-name')}
            fieldId={'first_name'}
            maxlength={75}
            counter={true}
            hasFeedback
            fieldOptions={{
              rules: [...nameFieldValidator(true, 75)]
            }}
            disabled={!this.state.administrator}
          />
          <TextField
            form={form}
            label={t('FIELDS:last-name')}
            fieldId={'last_name'}
            maxlength={75}
            counter={true}
            hasFeedback
            fieldOptions={{
              rules: [...nameFieldValidator(true, 75)]
            }}
            disabled={!this.state.administrator}
          />
          <TextField
            form={form}
            label={t('Email Address')}
            fieldId={'email_address'}
            hasFeedback
            fieldOptions={{
              rules: [...emailFieldValidator()]
            }}
            disabled={!this.state.administrator}
          />
          <SelectField
            form={form}
            label={t('Preferred language')}
            fieldId={'languageId'}
            optionsArray={languageOptions}
            style={{ width: 200 }}
            disabled={!this.state.administrator}
            initialValue={getLanguageIdByKey(locale)}
          />
          <Collapse isOpened={showCorpoInfo || false}>
            <TextField
              form={form}
              label={t('Business Name')}
              fieldId={'business_name'}
              hasFeedback
              fieldOptions={{
                rules: [...simpleFieldValidator(showCorpoInfo)]
              }}
              disabled={!this.state.administrator}
            />
            <TextField
              form={form}
              label={t('Job title')}
              fieldId={'job_title'}
              hasFeedback
              fieldOptions={{
                rules: [...simpleFieldValidator(showCorpoInfo)]
              }}
              disabled={!this.state.administrator}
            />
          </Collapse>
          {this.state.administrator && (
            <React.Fragment>
              <CountryField
                className="TransactionsPageForm__Field"
                form={form}
                label={true}
                placeholder={true}
                onChangeHandler={this.onCountryChange}
                required={true}
                identity={showCorpoInfo ? 'company' : 'user'}
              />
              {TaxReceiptInformationFields}
              <PostalCodeField
                className="CreateInformationForm__Field"
                form={form}
                label={true}
                placeholder={false}
                required={true}
                country={this.state.country}
                identity={showCorpoInfo ? 'company' : 'user'}
              />
            </React.Fragment>
          )}
        </div>

        <CancelAndSaveButtons
          onClickCancel={this.onClickCancel}
          isSavingInProgress={isDonationInProgress}
        />
      </Form>
    );
  }
}

AddTransactionsPageForm.defaultProps = {
  transaction: {
    amount: undefined,
    donation: {
      target: undefined,
      message: undefined,
      anonymous: undefined,
      typeId: undefined,
      organizationName: undefined,
      organizationTitle: undefined,
      honorificId: undefined,
      firstName: undefined,
      lastName: undefined,
      postalCode: undefined,
      country: undefined,
      email: undefined,
      languageId: undefined
    }
  }
};

AddTransactionsPageForm.propTypes = {
  form: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
};

export default translate('AddTransactionsPageForm')(AddTransactionsPageForm);

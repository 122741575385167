import React, { Fragment } from 'react';
import { Layout, Button } from 'antd';
import { has } from 'lodash';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { Helmet } from 'react-helmet';

import WinkNotification from '../common/WinkNotification';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import Can from 'Parts/session/components/CanContainer';
import Plugin from '../plugin/Plugin';

import {
  saveResentActivationEmail,
  readResentActivationEmail,
  clearResentActivationEmail
} from 'Parts/common/cookieUtils';
import { isPluginActive } from '../plugin/pluginActions';

import dashboardPageEN from './locales/DashboardPage_en.json';
import dashboardPageFR from './locales/DashboardPage_fr.json';

import './DashboardPage.less';

const ACTIVE = 1;

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resentEmail: this.props.user.statusId === ACTIVE ? false : readResentActivationEmail()
    };

    if (this.props.user.statusId === ACTIVE) {
      clearResentActivationEmail();
    }

    i18next.addResourceBundle('en', 'DashboardPage', dashboardPageEN);
    i18next.addResourceBundle('fr', 'DashboardPage', dashboardPageFR);
  }

  componentDidMount() {
    if (this.props.user.statusId === ACTIVE && readResentActivationEmail()) {
      this.setState({ resentEmail: false }, clearResentActivationEmail());
    }
  }

  resendRegistrationEmail = e => {
    e.preventDefault();
    this.props
      .sendRegistrationEmail()
      .then(saveResentActivationEmail(), this.setState({ resentEmail: true }));
  };

  render() {
    const { t, locale, user, platform, isBillingConfigured } = this.props;
    const { roleId } = user;

    const isAdmin = this.props.checkAuthorization({ platform: platform.id }, [
      ['roles.platform.SUPERADMIN'],
      ['roles.platform.OWNER']
    ]);

    const billingConfigurationNotification =
      !isBillingConfigured && isAdmin ? (
        <WinkNotification
          title={t('member-inactive-title')}
          description={t('Please set up your bank account with Stripe. You will have access to a ton of new features!')}
          closable={false}
        />
      ) : null;


    const TaxReceiptNotification =
      (true || this.props.organization.typeId === 2 && isPluginActive(platform, 'TaxReceipt') && (
        <Plugin
          name="TaxReceipt"
          component="DashboardNotification"
          {...{
            // Props
            locale: locale,
            roleId: roleId
          }}
        />
      )) ||
      null;

    const memberInactiveCta =
      user.statusId !== ACTIVE ? (
        <Fragment>
          {!this.state.resentEmail
            ? t('member-inactive-description')
            : t('member-inactive-description-resent')}{' '}
          {!this.state.resentEmail ? (
            <a onClick={this.resendRegistrationEmail}>{t('member-inactive-cta')}</a>
          ) : null}
        </Fragment>
      ) : null;

    const memberInactiveNotification =
      user.statusId !== ACTIVE ? (
        <WinkNotification
          title={t('member-inactive-title')}
          description={memberInactiveCta}
          closable={false}
        />
      ) : null;

    const campaignEnabled =
      has(this.props.platform, 'settings.campaign.creation.enabled') &&
      Number(this.props.platform.settings.campaign.creation.enabled)
        ? true
        : false;

    return (
      <Layout className="DashboardPage">
        <Helmet>
          <title>{t('Dashboard')}</title>
        </Helmet>
        {billingConfigurationNotification}
        {TaxReceiptNotification}
        {memberInactiveNotification}
        <AdminTitleBar title={t('Dashboard')} />
        <div className="DashboardPage__Content">
          <p>
            {t('Hi')} {user.firstName}!
          </p>
          <p>{t('Welcome to your dashboard')}</p>
          <p>
            {t('You are now a member of the platform')} <strong>{platform.name}</strong>,{' '}
            {t('congratulations')}
          </p>
          <p>{t('There are many ways you can contribute')}</p>

          <div className="DashboardPage__Content_Actions">
            {isBillingConfigured &&
              <Button>
                <Link to={`/${locale}/${t('URL:give')}`}>{t('Make a donation')}</Link>
              </Button> ||
              null
            }
            {campaignEnabled ||
            (this.props.lastCampaigns && this.props.lastCampaigns.length > 0) ? (
              <React.Fragment>
                <Can
                  rules={[
                    [() => campaignEnabled],
                    ['roles.platform.OWNER'],
                    ['roles.platform.SUPERADMIN']
                  ]}
                >
                  <Button>
                    <Link to={`/${locale}/${t('URL:create-a-campaign')}`}>
                      {t('Create a campaign')}
                    </Link>
                  </Button>
                </Can>
                <Button>
                  <Link to={`/${locale}/${t('URL:participant-registration')}`}>
                    {t('Become a participant')}
                  </Link>
                </Button>
              </React.Fragment>
            ) : null}
          </div>

          <p>
            {t('Questions')}{' '}
            <a href="https://fundky.tawk.help/" target="_blank">
              {t('Fundky help center')}
            </a>
          </p>
          <p>{t('Thank you')}</p>
        </div>
      </Layout>
    );
  }
}

export default translate('DashboardPage')(DashboardPage);

import React from "react";
import PropTypes from "prop-types";

class DataFetchingProvider extends React.Component {

  constructor(props) {
    super(props);

    // Intentionally NOT in state: we don't want to trigger a new render right after the first render.
    this.firstRender = true;
  }

  getChildContext() {
    return {
      fetchMap: this.props.fetchMap,
      firstRender: this.firstRender
    }
  }

  componentDidMount() {
    this.firstRender = false;
  }

  render() {
    return this.props.children;
  }

}

DataFetchingProvider.childContextTypes = {
  fetchMap: PropTypes.array,
  firstRender: PropTypes.bool
};

DataFetchingProvider.propTypes = {
  fetchMap: PropTypes.array.isRequired
};

export default DataFetchingProvider;

import React from 'react';
import { Layout, Collapse } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import AdminTitleBar from 'Parts/common/AdminTitleBar';
import PlatformSettingsForm from './components/PlatformSettingsFormContainer';
import SocialMediasSettingsForm from './components/SocialMediasSettingsFormContainer';
import LanguagesSettingsForm from './components/LanguagesSettingsFormContainer';
import Can from 'Parts/session/components/CanContainer';

import generalSettingsPageEN from './locales/GeneralSettingsPage_en.json';
import generalSettingsPageFR from './locales/GeneralSettingsPage_fr.json';

import './GeneralSettingsPage.less';

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'SettingsPage', generalSettingsPageEN);
    i18next.addResourceBundle('fr', 'SettingsPage', generalSettingsPageFR);
  }

  render() {
    const { t, locale } = this.props;
    const Panel = Collapse.Panel;

    return (
      <Layout className="SettingsPage">
        <Helmet>
          <title>{t('General Settings')}</title>
        </Helmet>
        <AdminTitleBar title={t('General Settings')} />
        <div className="AdminPanel">
          <Can rules={['permissions.platform.platform.update']} redirect={`/${locale}/d`}>
            <Collapse accordion bordered={false} defaultActiveKey={['1']}>
              <Panel header={t('Platform')} key="1">
                <PlatformSettingsForm />
              </Panel>
              <Panel header={t('Languages')} key="2">
                <LanguagesSettingsForm />
              </Panel>
              <Panel header={t('Socials')} key="3">
                <SocialMediasSettingsForm />
              </Panel>
            </Collapse>
          </Can>
        </div>
      </Layout>
    );
  }
}

export default translate('SettingsPage')(SettingsPage);

export const fr_ca = {
  "URL": {
    "organization": "organisation",
    "organization/give": "organisation/donner",
    "organization/give/information": "organisation/donner/informations",
    "organization/give/survey": "organisation/donner/infdonner/tentativeormations",
    "organization/give/payment": "organisation/donner/paiement",
    "organization/give/confirmation": "organisation/donner/confirmation",
    "organization/give/confirmation/success": "organisation/donner/confirmation/succes",
    "organization/give/confirmation/failed": "organisation/donner/confirmation/echouee",
    "organization/give/confirmation/cancelled": "organisation/donner/confirmation/annulee",
    "organization/give/confirmation/error": "organisation/donner/confirmation/erreur",

    "give" : "donner",
    "give/information" : "donner/informations",
    "give/survey" : "donner/tentative",
    "give/payment" : "donner/paiement",
    "give/confirmation" : "donner/confirmation",
    "give/confirmation/success" : "donner/confirmation/succes",
    "give/confirmation/failed" : "donner/confirmation/echouee",
    "give/confirmation/cancelled" : "donner/confirmation/annulee",
    "give/confirmation/error" : "donner/confirmation/erreur",
    "give/confirmation/pending" : "donner/confirmation/en-attente",
    "give/campaigns" : "donner/campagnes",
    "give/teams" : "donner/equipes",
    "give/participants" : "donner/participants",

    "login": "connexion",
    "create-an-account": "creer-un-compte",
    "create-an-account/confirmation": "creer-un-compte/confirmation",

    "account-recovery": "reinitialisation-mot-de-passe",
    "reset-password": "reinitialiser-mot-passe",

    "registration-confirm": "confirmation-inscription",

    "create-a-campaign": "creer-une-campagne",
    "create-a-campaign/description": "creer-une-campagne/description",
    "create-a-campaign/confirmation": "creer-une-campagne/confirmation",

    "create-a-team": "creer-une-equipe",
    "create-a-team/confirmation": "creer-une-equipe/confirmation",

    "create-a-team/choose-campaign": "creer-une-equipe/choix-campagne",
    "create-a-team/information": "creer-une-equipe/informations",

    "participant-registration": "inscription-participant",
    "participant-registration/confirmation": "inscription-participant/confirmation",

    "participant-registration/choose-campaign": "inscription-participant/choix-campagne",
    "participant-registration/choose-team": "inscription-participant/choix-equipe",
    "participant-registration/information": "inscription-participant/informations",
    "individual-participant": "participant-individuel",

    "cookie-policy": "https://info.fundky.com/fr/politique-cookies/",
    "privacy-policy": "https://info.fundky.com/fr/politique-confidentialite/",
    "terms-of-use": "https://info.fundky.com/fr/termes-conditions/",

    "search": "recherche",
    "search/campaigns": "recherche/campagnes",
    "search/teams": "recherche/equipes",
    "search/participants": "recherche/participants",
    "refund-policy": "politique-remboursement",
    "organization-guidelines": "directives-organisation",
    "contact-us": "contactez-nous",

    "404": "404",
    "error": "erreur",
    "maintenance": "maintenance",
    "dashboard": "d",

    // "buy-ticket-chooseticket": "achat-billet-choisirbillet",
    // "buy-ticket-promocode": "achat-billet-codepromo",
    // "buy-ticket-details": "achat-billet-details",
    // "payment": "paiement",
    // "ticket-confirmation": "billet-confirmation",
    // "ticket-confirmation/success": "billet-confirmation/succes",
    // "ticket-confirmation/failed": "billet-confirmation/echec",
    // "ticket-confirmation/cancelled": "billet-confirmation/annuler",
    // "ticket-confirmation/pending": "billet-confirmation/en-attente",

    "ticket-purchase": "achat-de-billet",
    "ticket-purchase/choose": "achat-de-billet/choisir",
    "ticket-purchase/promo-code": "achat-de-billet/code-promo",
    "ticket-purchase/details": "achat-de-billet/details",
    "ticket-purchase/survey": "achat-de-billet/enquête",
    "ticket-purchase/payment": "achat-de-billet/paiement",
    "ticket-purchase/confirmation": "achat-de-billet/confirmation",
    "ticket-purchase/confirmation/success": "achat-de-billet/confirmation/succes",
    "ticket-purchase/confirmation/failed": "achat-de-billet/confirmation/echouee",
    "ticket-purchase/confirmation/cancelled": "achat-de-billet/confirmation/annulee",
    "ticket-purchase/confirmation/error": "achat-de-billet/confirmation/erreur",
    "ticket-purchase/confirmation/pending": "achat-de-billet/confirmation/en-attente",
  },
  "FIELDS": {
    "first-name": "Prénom",
    "last-name": "Nom",
    "user-name": "Nom d'utilisateur",
    "email": "Adresse courriel",
  },
  "FIELDS-VALIDATION": {
    "required-message": "Ce champ est requis.",
    "name": {
      "required": "Veuillez saisir votre nom.",
      "required_3": "Veuillez saisir le nom.",
      "validation": "Vous avez utilisé des caractères non autorisés.",
      "maximum": "De 2 à {{max}} caractères maximum."
    },
    "firstname": {
      "required": "Veuillez saisir votre prénom.",
      "required_3": "Veuillez saisir le prénom.",
      "validation": "Vous avez utilisé des caractères non autorisés.",
      "maximum": "De 2 à {{max}} caractères maximum."
    },
    "lastname": {
      "required": "Veuillez saisir votre nom de famille.",
      "required_3": "Veuillez saisir le nom de famille.",
      "validation": "Vous avez utilisé des caractères non autorisés.",
      "maximum": "De 2 à {{max}} caractères maximum."
    },
    "email": {
      "required": "Veuillez saisir votre adresse courriel.",
      "required_3": "Veuillez saisir l'adresse courriel.",
      "validation": "Cette adresse courriel doit être valide."
    },
    "username": {
      "required": "Veuillez saisir votre nom d'utilisateur.",
      "required_3": "Veuillez saisir le nom d'utilisateur.",
      "validation": "Vous avez utilisé des caractères non autorisés.",
      "maximum": "De 2 à {{max}} caractères maximum."
    },
    "custom": {
      "required": "Veuillez saisir du contenu.",
      "validation": "Vous avez utilisé des caractères non autorisés.",
      "minimum": "Minimum de {{min}} caractères.",
      "minimum-maximum": "De {{min}} à {{max}} caractères maximum.",
      "equal": "Exactement {{equal}} caractères.",
      "maximum": "Maximum de {{max}} caractères."
    },
    "url": {
      "required": "S'il vous plaît entrer l'URL.",
      "validation": "L'URL doit être valide."
    },
    "date": {
      "past": "Veuillez sélectionner une date avant {{time}}.",
      "future": "Veuillez sélectionner une date après {{time}}.",
      "now": "maintenant"
    }
  },
  SESSION: {
    LOGIN: {
      'twitch-check-email': {
        title: "Action requise",
        description: "Veuillez consulter vos courriels pour lier votre compte Twitch à votre compte Fundky."
      },
      'twitch-error': {
        title: "Erreur de connexion à Twitch",
        description: "Veuillez vous assurer de valider votre compte Twitch avec une adresse courriel avant de vous connecter sur Fundky."
      },
      'twitch-access-denied': {
        title: "Accès refusé à Twitch",
        description: "Vous devez authoriser la connexion pour vous connecter avec Twitch. Veuillez réessayer."
      },
      'twitch-link-fail': {
        title: "Échec de la liaison au compte Twitch",
        description: "Le jeton est invalide. Veuillez réessayer."
      }
    },
    REGISTER: {
      CONFIRMATION: {
        title: "Félicitations !",
        msg: "Votre compte Fundky a été créé avec succès !",
        action: "Voir mon tableau de bord",
        close: "Fermer",
        closeWindow: "Fermer cette fenêtre",
        continue: "Poursuivre"
      }
    },
    REGISTRATION: {
      CONFIRM: {
        title: "Félicitations !",
        msg: "Votre compte Fundky a été créé avec succès !",
        action: "Continuer",
        "title-error": "Échec de l'activation!",
        "action-error": "Fermer",
      }
    },
    EXPIRATION: {
      KICKEDOUT: {
        message: "Pour des raisons de sécurité, vous avez été déconnecté de votre session en raison d’inactivité.",
      },
      WARNING: {
        message: "Vous allez être automatiquement déconnecté dans deux minutes.",
        connect: "Restez connecté",
        logout: "Déconnexion"
      }
    }
  },
  ERROR: {
    '1000': "Le serveur est temporairement incapable de répondre à votre demande en raison de problèmes d'immobilisation ou de capacité de maintenance. Veuillez réessayer plus tard.",
    '1001': "Ce serveur n'a pas compris votre demande.",
    '1002': "Votre nom d'utilisateur ou votre mot de passe est invalide.",
    '1003': "L'accès est refusé en raison de références invalides.",
    '1004': "Malheureusement, la ressource n'est pas trouvée.",
    '1006': "Ce serveur n'a pas pu comprendre vos données.",
    '1101': 'Votre courrier électronique existe. Veuillez modifier le courrier électronique.',
    '1104': 'Vos Alias existent. Veuillez modifier Alias.',
    '1110': 'Votre compte est bloqué pendant ',
    '1111': 'Vous avez les bons authentifiant mais votre compte est bloqué pendant ',
    '1120': "Ce serveur n'a pas pu comprendre vos données.",
    '2000': "Ce serveur n'a pas pu trouver la Clé d'activation que vous avez.",
    '2001': "Votre organisation existe. Veuillez modifier l'organisation.",
    sessionExpired: 'Votre connexion a expiré. Vous devez vous connecter à nouveau.',
    emailValidation: {
      notConform: 'Veuillez inscrire un Courriel valide. (abcd@efgh.ca)',
      same: 'Les deux courriels ne sont pas identiques'
    },
    passwordValidation: {
      notConform: 'Veuillez inscrire un mot de passe valide. (8 caractères minimum avec un caractère spécial)',
      same: 'Les deux mot de passe ne sont pas identiques.'
    },
    aliasValidation: {
      noAccent: "Attention l'alias ne peux contenir d'accent.",
      notConform: "Veuillez inscrire un alias valide. (pas d'accent s'il vous plait)."
    },
    acceptedTermsConditions: "Vous devez accepter les termes et conditions, s'il vous plait.",
    permissionOptinConditions: "Vous devez avoir le droit, par l'organisation, de l'inscrire sur Fundky.",
    dirty: 'Vous utilisez un language grossier ou un mot grossier',

    "200": "Succès",
    "201": "Créé",
    "204": "Aucun contenu",

    "400": "Requête erronée",
    "401": "Non autorisé",
    "403": "Interdit",
    "404": "Introuvable",
    "409": "Conflit",
    "422": "Entité non traitable",

    "500": "Erreur Interne du Serveur",
    "502": "Vos modifications ne peuvent être sauvegardées pour le moment. Veuillez réessayer plus tard.",

    "FK-JOI-SCH-1000": "Veuillez contacter l'administrateur.",

    "FK-MEM-1000": "Cette adresse courriel est déjà associée à un autre compte Fundky Connect. Veuillez inscrire une nouvelle adresse courriel.",
    "FK-MEM-1100": "Jeton du membre invalide.",
    "FK-MEM-1200": "Le membre a un rôle d'organisation.",
    "FK-MEM-1201": "Le membre a un rôle de plateforme.",
    "FK-MEM-1202": "Le membre a un rôle dans la campagne.",
    "FK-MEM-1203": "Le membre a un rôle d'équipe.",
    "FK-MEM-1204": "Le mot de passe a déjà été utilisé. Veuillez en choisir un nouveau.",

    "FK-CAPTCHA-1000": "La vérification reCAPTCHA a échouée.",

    "FK-ORG-1000": "Nom de l'organisation indisponible.",
    "FK-ORG-1001": "Jeton d'invitation d'organisation invalide.",

    "FK-PLA-1000": "Alias de plateforme indisponible.",

    "FK-CAM-1000": "Alias de campagne indisponible.",
    "FK-CAM-1001": "Création de campagne désactivée.",

    "FK-CAM-EVT-TKT-1001": "Billets déjà vendus ou réservés! Veuillez modifier la quantité ou réessayer plus tard.",
    "FK-CAM-EVT-TKT-1002": "Oups! Tous les billets ont été vendus!",

    "FK-TEA-1000": "Team alias not available.",
    "FK-TEA-1001": "Alias d'équipe indisponible.",
    "FK-TEA-1002": "Limite d'équipe atteinte.",

    "FK-PAR-1000": "Le participant participe.",
    "FK-PAR-1001": "Le participant ne participe pas.",
    "FK-PAR-1002": "Le participant est capitaine.",
    "FK-PAR-1003": "Création du participant désactivée",
    "FK-PAR-1004": "Limite de participants atteinte.",

    "FK-DON-1000": "Ce destinataire n’accepte plus de dons.",

    "FK-PLU-1000": "Le module est désactivé.",

    "FK-PLU-TXR-1000": "Le module de reçus de dons existe.",
    "FK-PLU-TXR-1001": "Le module de reçus de dons n'existe pas.",
    "FK-PLU-TXR-1002": "Le module de reçus de dons est en cours de traitement.",
    "FK-PLU-TXR-1003": "Des configurations du module de reçus de dons sont manquantes.",
    "FK-PLU-TXR-1004": "Le module de reçus de dons est désactivé.",
    "FK-PLU-TXR-1005": "Les informations du donateur ne sont pas complètes. Veuillez les compléter et réessayer.",
    "FK-PLU-TXR-1006": "Logo manquant pour émettre un reçu de don.",
  },
  agreementPage: {
    title: 'TERMES ET CONDITIONS D’UTILISATION',
    about: 'fundky.com (le « Site ») est un service Web et mobile visant à permettre à des individus,\
				des petits groupes, ou à des organismes de pouvoir s’impliquer dans une activité de financement \
				et à recueillir des dons.\
  				Le Site est une division de Technologies ODE inc.',
    terms: '<ol>\
				  	<li>Pour créer des activités de financement, chaque individu ou organisme doit devenir membre du Site\
 						et respecter en tout temps les présentes conditions d’utilisation \
 						du Site qui peuvent être modifiées en tout temps par ODE Technologie.\
 					</li>\
				  	<li>ODE Technologie se réserve le droit de refuser toute inscription ou activité, notamment si l’activité \
				  		ne semble pas légitime, si son contenu est offensant ou viole les droits d’un tiers ou pour \
				  		tout autre motif jugé raisonnable par ODE Technologie. \
				  	</li>\
				  	<li>Vous devez préserver votre mot de passe strictement confidentiel.\
				  	</li>\
				  	<li>En mettant en ligne ou en fournissant à ODE Technologie tout contenu, vous garantissez détenir \
				  		tous les droits y étant reliés, incluant notamment tout droit d’auteur ou droit à l’image. \
				  		De plus, ce faisant, vous accordez automatiquement à ODE Technologie, sans restriction de quelque nature \
				  		que ce soit et pour le monde entier, un droit d’utilisation du contenu pour les fins \
				  		du Site et pour sa promotion, libre de toute \
				  		redevance et renonce à l’exercice de toute réclamation ou recours contre ODE Technologie ou autre entité \
				  		juridique contrôlée par ou liée à ODE Technologie, leurs employés, agents et représentants relativement à \
				  		l’utilisation qui pourrait être faite de ce contenu par quiconque.\
				  	</li>\
				  	<li>ODE Technologie se réserve le droit de suspendre ou de fermer, de manière temporaire ou permanente, \
				  		tout compte d’un membre qui n’agirait pas conformément aux présents termes et conditions ou \
				  		de restreindre son accès au Site, sans préavis.\
				  	</li>\
				</ol>'
  },
  confirmRegisterPage: {
    title: 'Confirmation de la création de votre compte',
    title2: 'Inscription à la plateforme',
    about: 'Bravo! Vous êtes maintenant un membre Fundky. Souhaitez vous toujours vous inscrire sur la platform ',
    link: "Revenir à la page d'accueil",
    editPersonalDetails: 'Remplir le reste de vos informations personnelles',
    organisation: 'Créer votre organisation',
    platform: 'Créer votre platforme',
    btn: "S'inscrire"
  },
  dashboardPage: {
    title: 'Bienvenue ',
    about: '<p> <b> VOUS ETES CONNECTE DANS LA PAGE DU JOURNAL DE BORD </b><br/>'
  },
  editPasswordPage: {
    title: 'Modification du mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirm: 'confirmation du mot de passe',
    btn: 'Mise à jour',
    success: {
      title: 'Bravo',
      msg: 'Vous avez modifié votre mot de passe.'
    }
  },
  editEmailPage: {
    title: 'Modification du courriel',
    oldEmail: 'Ancien courriel',
    newEmail: {
      name: 'Nouveau courriel',
      message: 'Ce Courriel sera aussi demandé quand vous vous connectez'
    },
    confirm: 'confirmation du courriel',
    btn: 'Mise à jour',
    success: {
      title: 'Bravo',
      msg: 'Vous avez modifié votre courriel.'
    }
  },
  forgotPasswordPage: {
    title: 'Perte du mot de passe',
    email: {
      name: 'Courriel',
      message: 'Ce Courriel est le meme que celui pour vous connecter'
    },
    btn: "Envois d'un courriel",
    success: {
      title: 'Perte du mot de passe',
      msg: 'Un courriel vous a été envoyé pour modifier votre mot de passe.'
    }
  },
  footer: {
    about: '©2017 Tous Droits Réservés.'
  },
  header: {
    give: 'Faire un don',
    createanaccount: "S'inscrire",
    createacampaign: "Création d'une campagne",
    search: 'Recherche',
    contactus: 'Contact',
    login: 'Connection',
    editpersonaldetails: 'Modification des Détails personnels',
    editpassword: 'Modification du Mot de Passe',
    editemail: 'Modification du Courriel',
    settings: 'Paramètres',
    contribute: 'Contribuer',
    search: 'Recherche',
    logout: 'Déconnection',
    lng: 'EN'
  },
  home: {
    title: 'Bienvenue sur ',
    about: 'Vous pouvez vous connecter ou vous inscrire pour participer au evenements: ',
    connected: "Vous êtes connecté avec l'alias :",
    link: 'Connectez Vous',
    welcome: 'Bienvenue '
  },
  loading: 'Action en cours...',
  loginPage: {
    title: 'Connexion',
    email: 'Courriel',
    password: 'Mot de passe',
    btn: 'Connexion',
    forgot: 'Mot de passe oublié',
    or: 'ou',
    register: "S'enregistrer maintenant",
    success: {
      title: 'Bravo',
      msg: 'Vous êtes connecté. Vous allez être redirigé vers le Tableau de Bord.'
    }
  },
  listOrganisationPage: {
    id: 'Id',
    name: 'Nom',
    email: 'Courriel',
    city: 'Ville',
    created: 'Crée le',
    edit: 'Modifier les Informations',
    owner: 'Propietaire',
    deleted: 'Supprimé',
    btn: 'Créé une organisation',
    popconfirm: 'êtes vous sûr de vouloir supprimer?',
    success: 'Génial! Vous avez supprimé cette organisation: '
  },
  listPlatformPage: {
    id: 'Id',
    name: 'Nom',
    email: 'Courriel',
    created: 'Crée le',
    edit: 'Modifier les Informations',
    deleted: 'Supprimé',
    btn: 'Créé une plateforme',
    popconfirm: 'êtes vous sûr de vouloir supprimer?',
    success: 'Génial! Vous avez supprimé cette plateforme: ',
    website: 'Site web',
    admin: 'Administration'
  },
  listUserPage: {
    id: 'Id',
    lastname: 'Nom',
    firstname: 'Prénom',
    email: 'Courriel',
    deleted: 'Supprimé',
    confirmed: 'Confirmé',
    locked: 'Bloqué',
    edit: 'Modifié',
    created: 'Crée le',
    supermin: 'Super Administrateur',
    ghost: 'ghost',
    popconfirm: 'êtes vous sûr de vouloir supprimer?',
    success: {
      deleteUser: 'Génial! Vous avez supprimé cette utilsateur: ',
      editRole: 'Génial! Vous avez changé le role de cette utilsateur: '
    }
  },
  maintenance: {
    title: 'Nous serons bientôt de retour!',
    about: 'Désolé pour le désagrément mais nous effectuons une certaine maintenance en ce moment. Nous serons de retour en ligne sous peu!',
    signature: "l'équipe"
  },
  notFoundPage: {
    title: '404',
    about: "Oups! la page n'existe pas",
    link: 'Revenir au tableau de bord'
  },
  registerPage: {
    title: 'Inscription',
    firstname: 'Prénom',
    lastname: 'Nom',
    email: {
      name: 'Courriel',
      message: 'Ce courriel sera aussi demandé quand vous vous connectez'
    },
    password: 'mot de passe',
    confirm: 'confirmation du mot de passe',
    captcha: 'Captcha',
    bulletinOptin: "Je souscris à l'info lettre",
    acceptTerms: "<span>J'ai lu et j'accepte <a href='/agreement'>les termes et conditions</a></span>",
    btn: "S'enregistrer",
    success: {
      title: 'Bravo',
      msg: 'Vous êtes enregistré. Vous allez être redirigé vers la page de connection.'
    }
  },
  search: {
    firstname: 'Prénom',
    lastname: 'Nom',
    owner: 'Propriétaire',
    organisation: 'Organisation',
    organisationLabel: "Type d'Organisation",
    platform: 'Platforme',
    email: 'Courriel',
    createdDate: 'Date de création',
    btn: 'Recherche',
    reset: 'réinitialiser',
    emailOwner: 'Courriel du propriétaire',
    owner: 'Nom du propriétaire',
    organisationType: ['OBNL', 'OSBL / Entreprise'],
    roleLabel: "Role de l'Utilisateur",
    roleType: [
      'Super-administrateur',
      'Propriétaire',
      'Collaborateur',
      'Membre',
      'Éditeur',
      'Comptable',
      'Modérateur'
    ]
  },
  settingsPage: {
    sb1: 'Paramètres généraux',
    sb2: 'Paramètres des campagnes',
    indexing: 'Indexation de la plateforme',
    plateformName: 'Nom de la plateforme',
    plateformAddress: "L'adresse de la plateforme",
    trackingGoogle: 'Suivi de Google',
    restricted: 'Accès restreint',
    mainEmail: 'Courriel principale',
    languages: 'Langues',
    degaultLanguage: 'Langage par défaut',
    socialNetworks: 'Réseaux sociaux',
    creatingCampaign: 'Création de campagnes pour les membres',
    preAuthorization: 'Pré-autorisation des campagnes à leur création',
    btn: 'Sauvegarde',
    cancel: 'Annulation',
    oldEmail: 'Ancien courriel',
    email: {
      name: 'Courriel',
      message: 'Seulement les adresses courriels Google peuvent être lié à Google Analytics'
    },
    warning: {
      title: 'Attention',
      text: 'l’ancien sous-domaine redeviendra disponible pour les autres utilisateurs. \
					En confirmant, la plateforme deviendra en accès restreint le temps du changement. \
					Un courriel sera envoyé aux propriétaires et administrateurs pour les aviser du changement d’adresse et les invitera à mettre à jour les liens externes \
					menant vers leur plateforme. Dans le cas contraire, l’ancien URL affichera une 404.'
    },
    lngDefault: 'Langue par Défaut',
    lngAvailable: 'Langues disponibles',
    lngPlatform: 'Langues installées',
    lang: {
      en: 'Anglais',
      fr: 'Français',
      es: 'Espagnol'
    }
  },
  "DEFAULT_REFUND_POLICY": {
    "no-refund": "{{organization}} n'effectue pas de remboursements.",
    "more-info": "Pour plus de renseignements sur votre don ou votre inscription, veuillez communiquer avec nous."
  },
  "API_NOTIFICATIONS": {
    "session-expired": "La session a expiré",
    "session-expired-description": "Votre session a expiré. Veuillez vous connecter pour continuer.",
    "success": "Succès!",
    "creation-failed": "La création a échoué",
    "modifications-saved": "Vos modifications ont été sauvegardées avec succès!",
    "modifications-failed": "La mise à jour a échoué",
    "donation-saved": "Votre don a été sauvegardée avec succès!",
    "donation-failed": "Don échoué",
    "invalid-request": 'Requête invalide',
    "request-sent": "Demande envoyée",
    "request-failed": "La requête a echoué",
    "registration-failed": "Échec de l'enregistrement",
    "login-success": "Connexion réussie",
    "login-failed": "La connexion a échoué",
    "logout-success": "Vous avez été déconnecté(e)",
    "check-email-password": "Vérifiez votre courriel et votre mot de passe",
    "recover-not-found": "Nous n'avons pas trouvé votre compte Fundky. Veuillez vérifier vos informations et tenter de nouveau.",
    "campaign-creation-failed": "La campagne n'a pas pu être créée, veuillez vérifier les champs avant de continuer.",
    "transaction-approved": "La transaction est approuvée.",
    "error": "Erreur",
    "transaction-failed": "La transaction a échoué.",
    "survey-success":"Enquête créée avec succès",
    "survey_update-success":"Enquête mise à jour avec succès",  
    "survey-failed" :"Veuillez ajouter tous les échecs",
    "survey-delete" :"Suppression réussie de l'enquête",
    "field-success" : "Champ ajouté avec succès",
    "publish-success":"Champ Publier avec succès",
    "survey-attempt" : "Tentative d'enquête réussie"
  },
  "ROLES": {
    "1": "Superadmin",
    "2": "Membre",
    "3": "Administrateur",
    "4": "Propriétaire",
    "5": "Collaborateur",
    "6": "Éditeur",
    "7": "Comptable",
    "8": "Modérateur",
  },
  "LANGUAGES": {
    "default": " (par défaut)",
    "en": "Anglais",
    "fr": "Français",
    "1": "Anglais",
    "2": "Français",
  },
  "TRANSACTION": {
    "yes" : "Oui",
    "no" : "Non",
    "cancelText" : "Êtes-vous certain de vouloir annuler cette transaction?",
  },
  "MESSAGE": {
    "noJS-text": "Ce site ne peut pas fonctionner correctement si JavaScript est désactivé.",
    "noJS-link": "Veuillez l’activer pour continuer.",
    "noJS-url": "https://fundky.tawk.help/"
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Collapse } from 'react-collapse';
import { get } from 'lodash';

import { isPluginActive } from '../../../plugin/pluginActions';

import MonthlyField_en from './locales/MonthlyField_en.json';
import MonthlyField_fr from './locales/MonthlyField_fr.json';

import './MonthlyField.less';

class MonthlyField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        showWhen: false
    };

    i18next.addResourceBundle('en', 'MonthlyField', MonthlyField_en);
    i18next.addResourceBundle('fr', 'MonthlyField', MonthlyField_fr);
  }

  handleFrequencyChange = e => {
    const value = e.target.value;
    const show = value === 'monthly'
    this.setState({
        showWhen: show
    });
    if (!show) {
        this.props.form.setFieldsValue({ when: undefined });
    }

    this.props.onChange(e);
  };

  render() {
    const { t, form, className } = this.props;
    const { getFieldDecorator } = form;

    const frequencyOptions = [
        { label: t('Every month'), value: 'monthly' },
        { label: t('Just once'), value: 'once' }
    ];

    const whenOptions = [
        { label: t('1st of the month'), value: 1 },
        { label: t('15th of the month'), value: 15 }
    ];

    const input1 = <Radio.Group
        options={frequencyOptions}
        onChange={this.handleFrequencyChange}
        optionType="button"
        buttonStyle="solid"
    />;
    const fieldId1 = 'frequency';
    const fieldOptions1 = {
      initialValue: null,
      rules: [
        {
          required: true,
          message: t('requiredMessageFrequency')
        }
      ],
    };

    const input2 = <Radio.Group
        options={whenOptions}
        optionType="button"
        buttonStyle="solid"
    />;
    const fieldId2 = 'when';
    const fieldOptions2 = {
      initialValue: null,
      rules: [
        {
          required: this.state.showWhen,
          message: t('requiredMessageWhen')
        }
      ],
    };

    return (
      <div className="MonthlyField">
        <Form.Item
          className={className}
          label={t('How often would you like to donate?')}
          colon={false}
          hasFeedback
        >
            {getFieldDecorator(fieldId1, fieldOptions1)(input1)}
            <Collapse isOpened={
                this.state.showWhen &&
                isPluginActive(this.props.platform, 'TaxReceipt') &&
                get(this.props, 'platform.statusId') === 1 &&
                get(this.props, 'organization.typeId') === 2 &&
                this.props.donationType !== 3
            }>
                <div className="MonthlyField__Message">{t('taxReceiptMessage')}</div>
            </Collapse>
        </Form.Item>
        <Collapse isOpened={this.state.showWhen}>
            <Form.Item
                className={className}
                label={t('When would you like the direct debit to be proceeded?')}
                colon={false}
                hasFeedback
            >
                {getFieldDecorator(fieldId2, fieldOptions2)(input2)}
            </Form.Item>
        </Collapse>
      </div>
    );
  }

}

MonthlyField.propTypes = {
  form: PropTypes.object.isRequired,
  //className: PropTypes.string.isRequired,
};

export default translate('MonthlyField')(MonthlyField);

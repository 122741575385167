import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CreateConfirmationPanel from './CreateConfirmationPanel';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  campaign: state.campaign,
  createdCampaign: state.campaign.createdCampaign,
  locale: state.session.language,
  userEmail: state.session.user && state.session.user.email,
  isUserLogged: state.session.userTypeId === 2
});

export default compose(
  connect(mapStateToProps),
  withRouter,
)(CreateConfirmationPanel);

export function getDonationTarget(state) {
  if (state.donation.selectedOrganization) {
    return { targetType: 'organisation', targetId: state.platform.platform.id, entity: state.donation.selectedOrganization };
  } else if (state.donation.selectedCampaign) {
    return { targetType: 'campaign', targetId: state.donation.selectedCampaign.id, entity: state.donation.selectedCampaign };
  } else if (state.donation.selectedTeam) {
    return { targetType: 'team', targetId: state.donation.selectedTeam.id, entity: state.donation.selectedTeam };
  } else if (state.donation.selectedParticipant) {
    return { targetType: 'participant', targetId: state.donation.selectedParticipant.id, entity: state.donation.selectedParticipant };
  }
}

export function getLastDonations(state, ownProps) {
  if (ownProps.type) {
    switch (ownProps.type) {
      case 'platform' :
        return state.platform.lastDonations;
      case 'campaign' :
        return state.campaign.lastDonations;
      case 'team' :
        return state.team.lastDonations;
      case 'participant' :
        return state.participant.lastDonations;
      default :
        return [];
    }
  }
  else
  {
    console.warn("MISSING DONATIONS TYPE");
    return [];
  }
}

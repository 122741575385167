import React from 'react';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';

import PublicPageClosedEN from './locales/PublicPageClose_en.json';
import PublicPageClosedFR from './locales/PublicPageClose_fr.json';

import './PublicPageClosed.less';

const closedStatus = 4;

class PublicPageClosed extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PublicPageClosed', PublicPageClosedEN);
    i18next.addResourceBundle('fr', 'PublicPageClosed', PublicPageClosedFR);
  }

  render() {
    const { t, campaign, location } = this.props;

    // If campaign is closed
    if (campaign && campaign.statusId === closedStatus) {
      switch (location) {
        case 'Banner':
          return (
            <div className="PublicPageClosed__Banner">
              <FontAwesomeIcon icon={['fal', 'award']} />
              <div className="PublicPageClosed--Content">
                <h3>{t('public-closed-banner-h3')}</h3>
                <p className="PublicPageClosed__Description">
                  {t('public-closed-banner-description')}
                </p>
              </div>
            </div>
          );

        case 'Thermo':
          return (
            <div className="PublicPageClosed__Thermo">
              <h3>{t('public-closed-thermo-h3')}</h3>
              <p className="PublicPageClosed__Description">
                {t('public-closed-thermo-description')}
              </p>
            </div>
          );

        case 'Panel':
          return (
            <div className="PublicPageClosed__Panel">
              <p className="PublicPageClosed__Title">{t('public-closed-panel-h3')}</p>
              <p className="PublicPageClosed__Description">
                {t('public-closed-panel-description')}
              </p>
            </div>
          );
      }
    }

    // If registrations are disabled
    if (
      campaign &&
      campaign.settings &&
      campaign.settings.registration &&
      campaign.settings.registration.disabledOn &&
      isDateBeforeCurrentDate(campaign.settings.registration.disabledOn, true) &&
      location === 'Panel'
    ) {
      return (
        <div className="PublicPageClosed__Panel">
          <p className="PublicPageClosed__Title">{t('public-closed-panel-h3')}</p>
          <p className="PublicPageClosed__Description">{t('public-closed-panel-description')}</p>
        </div>
      );
    }

    // If donations are disabled
    if (
      campaign &&
      campaign.settings &&
      campaign.settings.donation &&
      campaign.settings.donation.disabledOn &&
      isDateBeforeCurrentDate(campaign.settings.donation.disabledOn, true) &&
      location === 'Thermo'
    ) {
      return (
        <div className="PublicPageClosed__Thermo">
          <h3>{t('public-closed-thermo-h3')}</h3>
          <p className="PublicPageClosed__Description">{t('public-closed-thermo-description')}</p>
        </div>
      );
    }

    return null;
  }
}

PublicPageClosed.propTypes = {
  location: PropTypes.oneOf(['Banner', 'Thermo', 'Panel']).isRequired,
  campaign: PropTypes.object.isRequired
};

export default translate('PublicPageClosed')(PublicPageClosed);

import qs from 'qs';
import { notification } from 'antd';
import i18next from 'i18next';
import isBrowser from 'is-in-browser';
import 'isomorphic-fetch';
import { isEmpty } from 'lodash'

import { saveSessionId, readSessionId, saveSessionDate, readSessionDate } from './cookieUtils';
import { getApiUrl } from './environment';
import { getSessionId, /*getSessionIp,*/ checkSessionExpiration, checkSessionId } from '../session/sessionUtils';
import { object } from 'prop-types';

const API_URL = getApiUrl();

/**
 * Check the HTTP status of a response returned by fetch.
 * Throw an Error for the corresponding status, because fetch doesn't do that
 * @see https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
 * @param response: fetch response
 * @returns {*}
 */
function checkHttpStatus(response) {
  if (!response.ok) {
    if (typeof response === 'object' && !response.body) {
      const code = response.code || response.status;
      const description = response.message || response.statusText;
      const message = `${response.url} returned ${response.status}: ${response.statusText} (code: ${code}, description: ${description})`;
      const error = new Error(message);
      error.status = response.status;
      error.statusText = response.statusText;
      error.code = code;
      error.description = description;
      throw error;
    } else {
      return response.json()
        .then(errorBody => {
          const code = errorBody.code || errorBody.status;
          const description = errorBody.message || errorBody.statusText;
          const message = `${response.url} returned ${response.status}: ${response.statusText} (code: ${code}, description: ${description})`;
          const error = new Error(message);
          error.status = errorBody.status;
          error.statusText = errorBody.statusText;
          error.code = code;
          error.description = description;
          // TODO use getDescriptionError to format messages
          //console.error(error, 'error');
          throw error;
        });
    }
  }

  return response;
}

function setSessionTime(response) {
  // Save session if we get it from the response
  if(response.headers.get('session-id') !== null) {
    saveSessionId(response.headers.get('session-id'));
    saveSessionDate();
    checkSessionId(response.headers.get('session-id'));
  }
  return response;
}

function parseBody(response) {
  if(
    response.headers.get('Content-Type') === 'application/json' &&
    response.toString().length > 0
  ) {
    return response.json();
  } else
  if(
    response.headers.get('Content-Type') === 'application/octet-stream' &&
    response.toString().length > 0
  ) {
    return response.blob();
  }

  return {};
}

function catchFetchError(err) {
  if(isBrowser) {
    if(typeof err !== 'object') {
      notification.error({message: i18next.t('API_NOTIFICATIONS:request-failed'), description: err});
      throw new Error(err);
    } else {
      throw err;
      //window.location.reload();
    }
  } else {
    throw err;
  }
}

export function requestApiDelete(relativeUrl, body = null , headers = null) {
  checkSessionExpiration();
  const options = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    }
  };

  const sessionId = readSessionId() || getSessionId();
  if (sessionId) options.headers = { ...options.headers, 'session-id': sessionId };

  if(body) {
    options.body = JSON.stringify(body);
  }

  return fetch(`${API_URL}${relativeUrl}`, options)
    .then(setSessionTime)
    .then(checkHttpStatus)
    .then(parseBody)
    .catch(catchFetchError);
}

export function requestApiGet(relativeUrl, params, headers = null) {
  checkSessionExpiration();
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    }
  };

  const queryStringValue = params && !isEmpty(params) ? `?${qs.stringify(params)}` : '';

  const sessionId = readSessionId() || getSessionId();
  if (sessionId) options.headers = { ...options.headers, 'session-id': sessionId };

  return fetch(`${API_URL}${relativeUrl}${queryStringValue}`, options)
    .then(setSessionTime)
    .then(checkHttpStatus)
    .then(parseBody)
    .catch(catchFetchError);
}

export function requestApiPost(relativeUrl, body, headers = null) {
  checkSessionExpiration();
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    body: JSON.stringify(body)
  };

  const sessionId = readSessionId() || getSessionId();
  if (sessionId) options.headers = { ...options.headers, 'session-id': sessionId };

  return fetch(API_URL + relativeUrl, options)
    .then(setSessionTime)
    .then(checkHttpStatus)
    .then(parseBody)
    .catch(catchFetchError);
}

export function requestApiPut(relativeUrl, body, headers = null) {
  checkSessionExpiration();
  const options = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    body: JSON.stringify(body)
  };

  const sessionId = readSessionId() || getSessionId();
  if (sessionId) options.headers = { ...options.headers, 'session-id': sessionId };

  return fetch(API_URL + relativeUrl, options)
    .then(setSessionTime)
    .then(checkHttpStatus)
    .then(parseBody)
    .catch(catchFetchError);
}

export function requestPublicGet(url) {
  return fetch(url)
    .then(checkHttpStatus)
    .then(res => res.json())
    .catch(catchFetchError);
}

export function requestPublicPost(url, body, headers = null) {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
      ...headers
    }
  };

  return fetch(url, options)
    .then(checkHttpStatus)
    .then(res => res.json())
    .catch(catchFetchError);
}

export function getErrorMessage(code, t = null, defaultMessage = '') {
  if(t) {
    const message = t(`ERROR:${code}`);
    return message === code ? t(defaultMessage) : message;
  } else {
    const message = i18next.t(`ERROR:${code}`);
    return message === code ? i18next.t(defaultMessage) : message;
  }
}

export function facebookScrape(urls = []) {
  requestApiPost('/tools/facebook/scrape', urls);
    // .then(response => { console.info(response, 'response') })
    // .catch(error => {
    //   console.error(error, 'error');
    // });
}

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import ParticipantPublicPage from '../participant/pages/ParticipantPublicPageContainer';
import Error404Page from './Error404Page';
import LoadingPage from './LoadingPage';

class CheckParticipantRoute extends React.Component {
  componentWillUnmount() {
    this.props.clearCampaign();
  }

  render() {
    const {
      participant,
      isFetchParticipantInProgress,
      isFetchTeamInProgress,
      isFetchCampaignInProgress,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          if (
            !isFetchParticipantInProgress &&
            !isFetchTeamInProgress &&
            !isFetchCampaignInProgress
          ) {
            if (participant.id) {
              return <ParticipantPublicPage {...props} />;
            } else {
              return <Error404Page />;
            }
          } else {
            return <LoadingPage />;
          }
        }}
      />
    );
  }
}

CheckParticipantRoute.defaultProps = {
  participant: null
};

CheckParticipantRoute.propTypes = {
  participant: PropTypes.object
};

export default CheckParticipantRoute;

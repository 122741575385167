import { connect } from "react-redux";

import ProvinceField from './ProvinceField';

const mapStateToProps = state => ({
  provinces: state.geo.provinces,
  locale: state.session.language
});

export default connect(mapStateToProps)(ProvinceField);

import campaign from '../campaign/campaignReducer';
import donation from '../donation/donationReducer';
import geo from 'Parts/geo/geoReducer';
import member from '../member/memberReducer';
import organization from '../organization/organizationReducer';
import participant from '../participant/participantReducer';
import platform from '../platform/platformReducer';
import algolia from '../algolia/algoliaReducer';
import plugin from '../plugin/pluginReducer';
import report from '../report/reportReducer';
import search from '../search/searchReducer';
import session from 'Parts/session/sessionReducer';
import team from '../team/teamReducer';
import ui from '../ui/uiReducer';
import surveysInformation from '../dashboard/surveys/SurveysListPageReducer';
import surveyPage from '../dashboard/surveys/SurveyPage/SurveyPageReducer';
import surveyAttempt from '../donation/components/SurveyAttemptReducer';

export default {
  campaign,
  donation,
  geo,
  member,
  organization,
  participant,
  platform,
  algolia,
  plugin,
  report,
  search,
  session,
  team,
  ui,
  surveysInformation,
  surveyPage,
  surveyAttempt,
};

import { connect } from "react-redux";

import AddTransactionsPage from './AddTransactionsPage';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  locale: state.session.language,
});

export default connect(mapStateToProps)(AddTransactionsPage);

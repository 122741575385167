import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './HelpText.less';

class HelpText extends React.Component {
  render() {
    const className = classnames('HelpText', this.props.className);
    return (
      <span className={className}>{this.props.children}</span>
    );
  }
}

HelpText.defaultProps = {
  children: '',
  className: null
};

HelpText.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string
};

export default HelpText;

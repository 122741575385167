import React from 'react';
import { Layout } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from "react-helmet";

import AdminTitleBar from '../../parts/common/AdminTitleBar';
import PagesDonationConfirmationEmailForm from './components/PagesDonationConfirmationEmailFormContainer';
import Can from '../../parts/session/components/CanContainer';
import ResponsiveTabs from '../../parts/ui/components/ResponsiveTabs';

import PagesDonationConfirmationEmail_en from './locales/PagesDonationConfirmationEmail_en.json';
import PagesDonationConfirmationEmail_fr from './locales/PagesDonationConfirmationEmail_fr.json';

class PagesDonationConfirmationEmail extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PagesDonationConfirmationEmail', PagesDonationConfirmationEmail_en);
    i18next.addResourceBundle('fr', 'PagesDonationConfirmationEmail', PagesDonationConfirmationEmail_fr);
  }

  render() {
    const { t, locale } = this.props;

    const tabPages = [
      {
        title: t('organization-note'),
        slug: 'note',
        container: () => (
          <Can rules={[
            ['roles.platform.OWNER', 'permissions.platform.platform.update'],
            ['roles.platform.SUPERADMIN', 'permissions.platform.platform.update']
          ]} redirect={`/${locale}/d`}>
            <PagesDonationConfirmationEmailForm />
          </Can>
        )
      }
    ];

    return (
      <Layout className="PagesDonationConfirmationEmail">
        <Helmet>
          <title>{t('donation-confirmation-email-title')}</title>
        </Helmet>
        <AdminTitleBar title={t('donation-confirmation-email-title')} />
        <ResponsiveTabs pages={tabPages} parentPage={this.props} defaultTabHasNoSlug/>
      </Layout>
    );
  }

}

export default translate('PagesDonationConfirmationEmail')(PagesDonationConfirmationEmail);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import NameField_en from './locales/NameField_en.json';
import NameField_fr from './locales/NameField_fr.json';

import './NameField.less';

class NameField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      length: (props.initialValue && props.initialValue.length) || 0,
      lengthCount:
        (props.initialValue &&
          props.maxlength &&
          props.maxlength - props.initialValue.length) ||
        props.maxlength
    };

    i18next.addResourceBundle('en', 'NameField', NameField_en);
    i18next.addResourceBundle('fr', 'NameField', NameField_fr);
  }

  handlerCounter = event => {
    if (this.props.counter && this.props.maxlength) {
      this.setState({
        length: event.target.value.length,
        lengthCount: this.props.maxlength - event.target.value.length
      });
    }
  };

  render() {
    const { props, state, handlerCounter } = this,
      {
        t,
        form,
        className,
        disabled,
        initialValue,
        placeholder,
        identity,
        requiredMessage,
        validationMessage,
        counter,
        required,
        fieldId,
        maxlength,
        label
      } = props,
      { lengthCount } = state,
      { getFieldDecorator } = form;

    const _placeholder =
      placeholder !== true
        ? placeholder || null
        : identity === 'user'
        ? t('name')
        : identity === 'team'
        ? t('team-placeholder')
        : identity === 'campaign'
        ? t('campaign-placeholder')
        : identity === 'platform'
        ? t('platform')
        : identity === 'organization'
        ? t('organization')
        : identity === 'company'
        ? t('company')
        : identity === 'job'
        ? t('job')
        : null;

    const _requiredMessage = requiredMessage
      ? requiredMessage
      : identity === 'user'
      ? t('required')
      : identity === 'team'
      ? t('required_team')
      : identity === 'campaign'
      ? t('required_campaign')
      : identity === 'platform'
      ? t('required_platform')
      : identity === 'organization'
      ? t('required_organization')
      : identity === 'company'
      ? t('required_company')
      : identity === 'job'
      ? t('required_job')
      : null;

    const input = (
      <Input
        className={counter ? 'counterOn' : null}
        placeholder={_placeholder}
        maxLength={maxlength}
        onInput={counter ? handlerCounter : null}
        onChange={this.props.onChange}
        disabled={disabled}
      />
    );

    const fieldOptions = {
      rules: [
        {
          // Required validation
          required: required,
          message: _requiredMessage
        },
        {
          // Characters validation
          pattern: /^[a-zA-Z0-9\u00C0-\u017F ,-\\'!]+$/,
          message: validationMessage || t('validation')
        },
        {
          // Length validation
          pattern: new RegExp(`^.{2,${maxlength}}$`), // /^.{2,128}$/,
          message: t('maximum', { max: maxlength })
        }
      ],
      initialValue: initialValue || undefined
    };

    const _label =
      label !== true
        ? label || null
        : identity === 'user'
        ? t('name')
        : identity === 'team'
        ? t('team')
        : identity === 'campaign'
        ? t('campaign')
        : identity === 'platform'
        ? t('platform')
        : identity === 'organization'
        ? t('organization')
        : identity === 'company'
        ? t('company')
        : identity === 'job'
        ? t('job')
        : null;

    const _counter = counter ? (
      <span className="NameField_Counter">{lengthCount}</span>
    ) : null;

    return (
      <Form.Item
        className={`NameField ${className}`}
        hasFeedback
        label={_label}
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
        {_counter}
      </Form.Item>
    );
  }
}

NameField.defaultProps = {
  className: '',
  required: true,
  requiredMessage: null,
  fieldId: 'name',
  placeholder: false,
  label: false,
  initialValue: undefined,
  identity: 'user',
  maxlength: 128,
  counter: false,
  disabled: false
};

NameField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  identity: PropTypes.oneOf([
    'user',
    'team',
    'campaign',
    'platform',
    'organization',
    'company',
    'job'
  ]),
  disabled: PropTypes.bool
};

export default translate('NameField')(NameField);

import React from 'react';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import SettingsBox from '../../../components/SettingsBox';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import SelectField from 'Parts/ui/components/fields/SelectField';
import FakeField from 'Parts/ui/components/fields/FakeField';
import Can from 'Parts/session/components/CanContainer';

import AccessibilityBoxForm_en from './locales/AccessibilityBoxForm_en.json';
import AccessibilityBoxForm_fr from './locales/AccessibilityBoxForm_fr.json';

class AccessibilityBoxForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deactivateModal: false,
      disableSubmitButton: false
    };

    this.deactivateModalRef = React.createRef();

    i18next.addResourceBundle('en', 'AccessibilityBoxForm', AccessibilityBoxForm_en);
    i18next.addResourceBundle('fr', 'AccessibilityBoxForm', AccessibilityBoxForm_fr);
  }

  handleSaveDraft = e => {};

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      disableSubmitButton: true
    });

    const { validateFields, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (Object.keys(updatedValues) && Object.keys(updatedValues).length > 0) {
          const values = {
            ...updatedValues
          };

          this.props.updateCampaign(values, this.props.campaign.id);
        }
      }
    });
  };

  handleOpenDeactivateConfirmModal = e => {
    e.preventDefault();
    this.deactivateModalRef.current.getWrappedInstance('FundkyModal').showModal();
    this.setState({ deactivateModal: true });
  };

  handleDeactivateAcceptModal = () => {
    this.props.updateCampaign({ statusId: 5 }, this.props.campaign.id).then(() => {
      this.deactivateModalRef.current.getWrappedInstance('FundkyModal').hideModal();
      this.setState({ deactivateModal: false });
    });
  };

  handleDeactivateDeclineModal = () => {
    this.deactivateModalRef.current.getWrappedInstance('FundkyModal').hideModal();
    this.setState({ deactivateModal: false });
  };

  checkforChange = value => {
    // if (this.state.disableSubmitButton != false && value != this.props.visibility) {
    //   this.setState({
    //     disableSubmitButton: false
    //   });
    // } else if (this.state.disableSubmitButton != true) {
    //   this.setState({
    //     disableSubmitButton: true
    //   });
    // }
  };

  render() {
    const {
        props,
        state,
        handleSaveDraft,
        //handleSubmit,
        handleOpenDeactivateConfirmModal,
        handleDeactivateAcceptModal,
        handleDeactivateDeclineModal
      } = this,
      { t, form, status, visibility, disabled, campaign, isDraft, handleSubmit, loading } = props,
      { disableSubmitButton } = state;

    const deactivateModal = (
      <FundkyModal
        ref={this.deactivateModalRef}
        displayModal={false}
        title={t('deactivate.title')}
        acceptAction={handleDeactivateAcceptModal}
        declineAction={handleDeactivateDeclineModal}
      >
        <p>{t('deactivate.description')}</p>
      </FundkyModal>
    );

    return (
      <SettingsBox title={t('title')} className="AccessibilityBoxForm">
        <FakeField data={status} label={t('status-label')} />
        {!isDraft && (
          <SelectField
            form={form}
            fieldId="visibility"
            label={t('campaign-visibility-label')}
            tooltip={t('campaign-visibility-tooltip')}
            initialValue={visibility}
            optionsArray={[
              { value: 1, text: t('public') },
              { value: 0, text: t('private') }
            ]}
            style={{ width: '150px' }}
            disabled={disabled}
            onChange={this.checkforChange}
          />
        )}
        <div className="SettingsBox__footer">
          <Can
            rules={[
              [() => (campaign.statusId ? campaign.statusId === 1 : null), 'roles.platform.OWNER'],
              [
                () => (campaign.statusId ? campaign.statusId === 1 : null),
                'roles.platform.SUPERADMIN'
              ]
            ]}
          >
            <FundkyButton
              type="text-primary"
              text={t('deactivate-campaign-button')}
              action={{ func: handleOpenDeactivateConfirmModal }}
              disabled={disabled}
            />
            {deactivateModal}
          </Can>
          {isDraft && (
            <FundkyButton
              className="save-draft"
              type="secondary"
              text={t('save-draft')}
              //action={{ func: handleSaveDraft }}
              action={{ func: handleSubmit, params: [true] }}
              disabled={disableSubmitButton}
              loading={loading}
            />
          )}
          <FundkyButton
            className="apply"
            type="primary"
            text={isDraft ? t('publish') : t('apply')}
            action={{ func: handleSubmit, params: [false, isDraft ? true : false] }}
            disabled={disableSubmitButton}
            loading={loading}
          />
        </div>
      </SettingsBox>
    );
  }
}

AccessibilityBoxForm.propTypes = {
  disabled: PropTypes.bool,
  isDraft: PropTypes.bool,
  loading: PropTypes.bool
};

AccessibilityBoxForm.defaultProps = {
  disabled: false,
  loading: false
};

export default translate('AccessibilityBoxForm')(AccessibilityBoxForm);

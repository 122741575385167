import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { get } from 'lodash';

import { selectOrganization, selectCampaign, selectTeam, selectParticipant, clearDonation, cancelTransaction ,fetchPlatformIdBySurveyList} from '../donationActions';
import dataFetcher from "../../parts/chore/dataFetcher";
import { changeDonationCreateWizardStep } from "../../ui/uiActions";
import { activateCreationMode, deactivateCreationMode } from "../../parts/ui/uiActions";

import { fetchAllSurveys } from "../components/SurveyAttemptAction";

import DonationPage from './DonationPage';

const mapStateToProps = state => ({
  checkout: get(state, 'donation.checkoutInformation'),
  donationInformation: get(state, 'donation.donationInformation'),
  selectedOrganization: get(state, 'donation.selectedOrganization'),
  selectedCampaign: get(state, 'donation.selectedCampaign'),
  selectedTeam: get(state, 'donation.selectedTeam'),
  selectedParticipant: get(state, 'donation.selectedParticipant'),
  currentStep: get(state, 'ui.donationCreateWizardState'),
  locale: get(state, 'session.language'),
  platform: get(state, 'platform.platform'),
  organization: get(state, 'organization.organization'),
  campaign: get(state, 'campaign.campaign'),
  team: get(state, 'team.team'),
  participant: get(state, 'participant.participant'),
  userTypeId: get(state, 'session.userTypeId'),
  donationCreateWizardState: get(state, 'ui.donationCreateWizardState'),
  lastCampaigns: get(state, 'campaign.campaigns'),
  donationDisabledOn: get(state.campaign, 'campaign.settings.donation.disabledOn', null),
  allSurveys: state.surveyAttempt.allSurveys,
  // platformId:state.session.permissionsIds.platform,
  platformId: state.platform.platform.id,
  surveyId:state.donation.surveyId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPlatformIdBySurveyList,
  selectOrganization,
  selectCampaign,
  selectTeam,
  selectParticipant,
  clearDonation,
  cancelTransaction,
  changeDonationCreateWizardStep,
  activateCreationMode,
  deactivateCreationMode,
  fetchAllSurveys
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(DonationPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import SettingsBox from './SettingsBox';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import LangSwitcher from './AdminContentLangSwitcher';
import CheckboxField from 'Parts/ui/components/fields/CheckboxField';
import Can from 'Parts/session/components/CanContainer';

import LanguageBoxForm_en from './locales/LanguageBoxForm_en.json';
import LanguageBoxForm_fr from './locales/LanguageBoxForm_fr.json';

class LanguageBoxForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: props.defaultLanguage,
      setDefaultLang: props.defaultLanguage
    };

    i18next.addResourceBundle('en', 'LanguageBoxForm', LanguageBoxForm_en);
    i18next.addResourceBundle('fr', 'LanguageBoxForm', LanguageBoxForm_fr);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultLanguage !== this.props.defaultLanguage) {
      this.props.form.setFieldsValue({
        LangSwitcher: this.props.defaultLanguage
      });
      this.handleChange(this.props.defaultLanguage);
    }
  }

  handleChange = value => {
    const { props, state } = this,
      { form, handleContentLanguageChange } = props,
      { setDefaultLang } = state;

    // if(value === setDefaultLang) {
    //   form.setFieldsValue({
    //     defaultLanguage: true
    //   });
    // } else {
    //   form.setFieldsValue({
    //     defaultLanguage: false
    //   });
    // }

    this.setState({
      lang: value
    });

    handleContentLanguageChange(value);
  };

  // handleCheck = e => {
  //   const value = e.target.checked;
  //   const { lang, setDefaultLang } = this.state;

  //   if(value && lang !== setDefaultLang) {
  //     this.setState({
  //       setDefaultLang: lang
  //     });
  //     this.props.handleContentDefaultLanguageChange(lang);
  //   }
  // };

  render() {
    const {
        props,
        state,
        handleChange
        //handleSubmit, handleCheck
      } = this,
      { t, disabled, form, defaultLanguage, fromCampaign, changeHasHappened } = props,
      { lang, setDefaultLang } = state;

    return (
      <SettingsBox title={t('title')}>
        <Form className="LanguageBoxForm">
          <LangSwitcher
            form={form}
            defaultLanguage={defaultLanguage}
            handleChange={handleChange}
            disabled={disabled}
            changeHasHappened={changeHasHappened}
          />
          {/* <CheckboxField
            fieldId="defaultLanguage"
            form={form}
            checkboxText={t('set-default-lang')}
            initialValue={true}
            onChange={handleCheck}
            disabled={lang === setDefaultLang || disabled}
          /> */}
          {/* <div className="SettingsBox__footer">
            <FundkyButton type="primary" text={t('apply')} action={{func: handleSubmit}} />
          </div> */}
        </Form>
      </SettingsBox>
    );
  }
}

LanguageBoxForm.propTypes = {
  handleContentLanguageChange: PropTypes.func.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

LanguageBoxForm.defaultProps = {
  disabled: false
};

export default translate('LanguageBoxForm')(LanguageBoxForm);

import React from 'react';
import { Col, Form, Row, Select } from 'antd';
import { get } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDefaultLanguageKey } from 'Parts/common/langUtils';
import TextField from '../../../../parts/ui/components/fields/TextField';
import CheckboxField from '../../../../parts/ui/components/fields/CheckboxField';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import FakeField from 'Parts/ui/components/fields/FakeField';
import SettingsColumn from '../../../components/SettingsColumn';
import LanguageBoxForm from '../../../components/LanguageBoxFormContainer';
import SettingsBox from '../../../components/SettingsBox';
import SurveysPage_en from '../locales/SurveysPage_en.json';
import SurveysPage_fr from '../locales/SurveysPage_fr.json';
import '../../SurveysListPage.less';

class InformationTab extends React.Component {
  constructor(props) {
    super(props);
    i18next.addResourceBundle('en', 'InformationTab', SurveysPage_en);
    i18next.addResourceBundle('fr', 'InformationTab', SurveysPage_fr);
  }

  customFilter = (inputValue, option) => {
    return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  };

  render() {
    const {
      t,
      form,
      defaultLanguage,
      disabled,
      loading,
      contentLang,
      campaignlang,
      handleChange,
      handleCheckboxChange,
      surveyName,
      organizationCheck,
      handleSingleCheckboxChange,
      platformCheck,
      campaign,
      dropdownClassName,
      handleContentLanguageChange,
      locale,
      surveyDetails,
      match,
      handleClick,
      campaignInfo,
      campaignTicketInfo,
      handleChangeSelect,
      surveyCampaignTicket,
      surveyCampaign,
      handleDeselectChange,
    } = this.props;
    return (
      <>
        <div className="SurveyInfoFormWrap">
          <Row>
            <Col xs={16}>
              <p style={{ color: "#FF0000", fontSize: "14px"}}>{t("Information_Important_text")}</p>
              <Form
                layout="vertical"
                className="PagesHomepageFeaturedCampaignForm"
                key={contentLang}
              >
                <h3 className="panel-title">{t('General')}</h3>
                <div className="inputs">
                  <TextField
                    form={form}
                    fieldId={'survey_name'}
                    label={t('Survey_name') + ':'}
                    contentLang={contentLang}
                    initialValue={surveyName || ''}
                    value={surveyName}
                    onChange={handleChange}
                  />

                  <label>{t('Available_on') + ':'}</label>
                  {/* <h3>{t('Organization')}</h3>

                  <CheckboxField
                    checkboxText={'Donation form'}
                    form={form}
                    fieldId="organizationDonationForm"
                    initialValue={organizationCheck}
                    checked={organizationCheck}
                    onChange={() => handleSingleCheckboxChange('organizationCheck')}
                  /> */}
                  <h3>{t('Platform')}</h3>
                  <CheckboxField
                    checkboxText={t('Donation_form')}
                    form={form}
                    fieldId="platformDonationForm"
                    initialValue={platformCheck}
                    checked={platformCheck}
                    onChange={() => handleSingleCheckboxChange('platformCheck')}
                  />
                  <h3>{t('Campaigns')}</h3>

                  {campaign.map((campaign) => (
                    <CheckboxField
                      checkboxText={t(campaign.text)}
                      name={campaign.name}
                      form={form}
                      initialValue={campaign.completed}
                      fieldId={campaign.fieldId}
                      checked={campaign.completed}
                      onChange={() => handleCheckboxChange(campaign.id)}
                    />
                  ))}
                  <div className="AdminContentLangSwitcher__wrap">
                    <label>{t('Campaigns_selection')}</label>
                    <Select
                      mode="multiple"
                      suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
                      onSelect={(e, event) => handleChangeSelect(e, event, 'campaign')}
                      onDeselect={(e, event) => handleDeselectChange(e, event, 'campaign')}
                      disabled={disabled}
                      value={surveyCampaign.map((d) => d.name)}
                      dropdownClassName={`AdminContentLangSwitcher__dropdown ${this.props.dropdownClassName}`}
                      placeholder={t("Choose_one_or_multiple_campaigns")}
                      filterOption={this.customFilter}
                    >
                      {campaignInfo &&
                        campaignInfo.map((key) => (
                          <Option key={key.id} value={key.id}>
                            {key.name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div className="AdminContentLangSwitcher__wrap">
                    <label>{t('Tickets_selection')}</label>
                    <Select
                      mode="multiple"
                      suffixIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
                      onSelect={(e, event) => handleChangeSelect(e, event, 'campaignTicket')}
                      onDeselect={(e, event) => handleDeselectChange(e, event, 'campaignTicket')}
                      value={surveyCampaignTicket.map((d) => d.ticketName)}
                      disabled={disabled}
                      dropdownClassName={`AdminContentLangSwitcher__dropdown ${this.props.dropdownClassName}`}
                      placeholder={t("Choose_one_or_multiple_tickets")}
                    >
                      {campaignTicketInfo !== null &&
                        campaignTicketInfo !== undefined &&
                        (campaignTicketInfo || []).map((key) => (
                          <Option key={key.id} value={key.ticketName}>
                            {key.ticketName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              </Form>
            </Col>
            <Col xs={8}>
              <SettingsColumn>
                <LanguageBoxForm
                  handleContentLanguageChange={handleContentLanguageChange}
                  changeHasHappened={this.changeHasHappened}
                  defaultLanguage={campaignlang === 1 ? "en" : "fr"}
                />
                <SettingsBox title={t('Accessibility')} className="AccessibilityBoxForm">
                  <Row>
                    <Col xs={8}>{t('Status')}</Col>
                    <Col xs={16}>{match.params.id && surveyDetails.accessibility === 1 ? t('Published') : t('Draft')}</Col>
                  </Row>
                  <div className="SettingsBox__footer">
                    {match.params.id && surveyDetails.accessibility !== 0  && 
                    <FundkyButton
                      className="ArchiveSurvey"
                      type="none"
                      text={t('Archive_survey')}
                      loading={loading}
                      onClick={() => handleClick(2)}
                    />}
                  {!match.params.id &&  
                  <FundkyButton
                    className="save-draft"
                    type="secondary"
                    text={t('Save_draft')}
                    loading={loading}
                    onClick={() => handleClick(0)}
                  />} 
                   {(match.params.id && surveyDetails.accessibility === 0 ) &&  
                    <FundkyButton
                      className="save-draft"
                      type="secondary"
                      text={t('Save_draft')}
                      loading={loading}
                      onClick={() => handleClick(0)}
                    />}  
                 
                    <FundkyButton
                      className="apply"
                      type="primary"
                      onClick={() => handleClick(1)}
                      text={t('Publish')}
                      loading={loading}
                    />
                  </div>
                </SettingsBox>
              </SettingsColumn>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default translate('InformationTab', { withRef: true })(InformationTab);

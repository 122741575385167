import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_CANCEL,
  AUTH_REGISTER_SUCCESS,
  AUTH_CONNECT_START,
  AUTH_CONNECT_SUCCESS,
  AUTH_CONNECT_ERROR,
  AUTH_TWITCH_CONNECT_SUCCESS,
  AUTH_TWITCH_CONNECT_ERROR,
  AUTH_TWITCH_LINK_SUCCESS,
  AUTH_TWITCH_LINK_ERROR,
  AUTH_LOGOUT_START,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_ERROR,
  DETECT_BROWSER,
  AUTH_CHECK_RECOVERY_KEY_START,
  AUTH_CHECK_RECOVERY_KEY_SUCCESS,
  AUTH_CHECK_RECOVERY_KEY_ERROR,
  AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_START,
  AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_SUCCESS,
  AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_ERROR,
  SEND_REGISTRATION_EMAIL_START,
  SEND_REGISTRATION_EMAIL_SUCCESS,
  SEND_REGISTRATION_EMAIL_ERROR,
  SET_LOCALE_SESSION_LANGUAGE,
  SET_SESSION_LANGUAGE_START,
  SET_SESSION_LANGUAGE_SUCCESS,
  SET_SESSION_LANGUAGE_ERROR,
  UPDATE_SESSION_STATE_SUCCESS,
  FETCH_MEMBER_PERMISSIONS_START,
  FETCH_MEMBER_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_PERMISSIONS_ERROR,
  FETCH_MEMBER_ORGANIZATION_PERMISSIONS_START,
  FETCH_MEMBER_ORGANIZATION_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_ORGANIZATION_PERMISSIONS_ERROR,
  FETCH_MEMBER_PLATFORM_PERMISSIONS_START,
  FETCH_MEMBER_PLATFORM_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_PLATFORM_PERMISSIONS_ERROR,
  FETCH_MEMBER_CAMPAIGN_PERMISSIONS_START,
  FETCH_MEMBER_CAMPAIGN_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_CAMPAIGN_PERMISSIONS_ERROR,
  FETCH_MEMBER_TEAM_PERMISSIONS_START,
  FETCH_MEMBER_TEAM_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_TEAM_PERMISSIONS_ERROR,
  CLEAR_MEMBER_PERMISSIONS,
  CLEAR_MEMBER_ADMIN_PERMISSIONS,
  FETCH_MEMBER_ROLES_START,
  FETCH_MEMBER_ROLES_SUCCESS,
  FETCH_MEMBER_ROLES_ERROR,
  FETCH_MEMBER_ORGANIZATION_ROLES_START,
  FETCH_MEMBER_ORGANIZATION_ROLES_SUCCESS,
  FETCH_MEMBER_ORGANIZATION_ROLES_ERROR,
  FETCH_MEMBER_PLATFORM_ROLES_START,
  FETCH_MEMBER_PLATFORM_ROLES_SUCCESS,
  FETCH_MEMBER_PLATFORM_ROLES_ERROR,
  FETCH_MEMBER_CAMPAIGN_ROLES_START,
  FETCH_MEMBER_CAMPAIGN_ROLES_SUCCESS,
  FETCH_MEMBER_CAMPAIGN_ROLES_ERROR,
  FETCH_MEMBER_TEAM_ROLES_START,
  FETCH_MEMBER_TEAM_ROLES_SUCCESS,
  FETCH_MEMBER_TEAM_ROLES_ERROR,
  CLEAR_MEMBER_ROLES,
  UPDATE_LOCATION_HISTORY,
  UPDATE_SESSION_TIMEOUT_START,
  UPDATE_SESSION_TIMEOUT_END,
  UPDATE_SESSION_TIMEOUT,
  CLEAR_SESSION_TIMEOUT,
  SHOW_SESSION_TIMEOUT_WARNING,
  HIDE_SESSION_TIMEOUT_WARNING,
  SHOW_SESSION_TIMEOUT_MESSAGE,
  HIDE_SESSION_TIMEOUT_MESSAGE,

  SAVE_IP,
  CLEAR_IP,
  SAVE_ID,

  IS_CAMPAIGN_OWNER_START,
  IS_CAMPAIGN_OWNER_SUCCESS,
  IS_CAMPAIGN_OWNER_ERROR
} from './sessionActions';

const initialState = {
  browser: null,
  sessionTimeout: null,
  user: null,
  connectionStatus: "guest",
  isCheckRecoveryKeyInProgress: false,
  isCheckRecoveryKeyFail: false,
  recoveryKey: null,
  isCheckRegistrationKeyInProgress: false,
  checkRegistrationKeyFail: false,
  registrationKey: null,
  language: null,
  languageId: null,
  permissions: {
    global: null,
    organization: null,
    platform: null,
    campaign: null,
    team: null
  },
  permissionsIds: {
    organization: null,
    platform: null,
    campaign: null,
    team: null
  },
  roles: {
    global: null,
    organization: null,
    platform: null,
    campaign: null,
    team: null
  },
  rolesIds: {
    organization: null,
    platform: null,
    campaign: null,
    team: null
  },
  userTypeId: 1,
  userType: "GUEST",
  history: [],
  ip: null,
  token: null,

  displaySessionTimeoutWarning: null,
  displaySessionTimeoutMessage: null,

  isCampaignOwner: false,
  isCampaignOwnerInProgress: false,

  isLoggingOutInProgress: false,
  isConnectInProgress: false,

  isUpdateSessionTimeOutInProgress: false,

  isFetchMemberPermissionsInProgress: false,
  isFetchMemberOrganizationPermissionsInProgress: false,
  isFetchMemberPlatformPermissionsInProgress: false,
  isFetchMemberCampaignPermissionsInProgress: false,
  isFetchMemberTeamPermissionsInProgress: false,

  isFetchMemberRolesInProgress: false,
  isFetchMemberOrganizationRolesInProgress: false,
  isFetchMemberPlatformRolesInProgress: false,
  isFetchMemberCampaignRolesInProgress: false,
  isFetchMemberTeamRolesInProgress: false,
};

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_CONNECT_START:
      return {
        ...state,
        isConnectInProgress: true
      }

    case AUTH_CONNECT_SUCCESS:
    case UPDATE_SESSION_STATE_SUCCESS:
      let ip = state.ip;
      const retour = {
        ...state,
        ...action.payload,
        language: action.payload.language == "ENGLISH" ? "en" : "fr",
        connectionStatus: action.payload.userTypeId === 1 ? "guest" : "connected",
        isConnectInProgress: false,
        ip: ip
      };

      return retour;
      // return {
      //   ...state,
      //   ...action.payload,
      //   language: action.payload.language == "ENGLISH" ? "en" : "fr",
      //   connectionStatus: action.payload.userTypeId === 1 ? "guest" : "connected",
      //   isConnectInProgress: false
      // };

    case AUTH_CONNECT_ERROR:
      return {
        ...state,
        isConnectInProgress: false
      };

    case AUTH_LOGIN_SUCCESS:
    case AUTH_TWITCH_CONNECT_SUCCESS:
    case AUTH_TWITCH_LINK_SUCCESS:
      return {
        ...state,
        user: action.payload,
        userTypeId: 2,
        userType: "MEMBER",
        connectionStatus: "connected"
      };

    case AUTH_LOGIN_ERROR:
    case AUTH_TWITCH_CONNECT_ERROR:
    case AUTH_TWITCH_LINK_ERROR:
      return {
        ...state,
        connectionStatus: "fail"
      };

    case AUTH_LOGOUT_START:
      return {
        ...state,
        isLoggingOutInProgress: true,
      };

    case AUTH_LOGOUT_ERROR:
      return {
        ...state,
        isLoggingOutInProgress: false,
      };

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        isCampaignOwner: false,
        connectionStatus: "guest",
        userTypeId: 1,
        userType: "GUEST",
        isCheckRecoveryKeyFail: false,
        isLoggingOutInProgress: false,
        permissions: {
          global: null,
          organization: null,
          platform: null,
          campaign: null,
          team: null
        },
        roles: {
          global: null,
          organization: null,
          platform: null,
          campaign: null,
          team: null
        },
      };

    case AUTH_LOGIN_CANCEL:
      return {
        ...state,
        connectionStatus: state.userTypeId === 1 ? "guest" : "connected",
        isCheckRecoveryKeyFail: false,
        isLoggingOutInProgress: false
      };

    case AUTH_REGISTER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        connectionStatus: "connected",
        userTypeId: 2,
        userType: "MEMBER",
      };

    case DETECT_BROWSER:
      return { ...state, browser: action.payload };

    case AUTH_CHECK_RECOVERY_KEY_START:
      return {
        ...state,
        isCheckRecoveryKeyInProgress: true
      };

    case AUTH_CHECK_RECOVERY_KEY_ERROR:
      return {
        ...state,
        isCheckRecoveryKeyInProgress: false,
        isCheckRecoveryKeyFail: true
      };

    case AUTH_CHECK_RECOVERY_KEY_SUCCESS:
      return {
        ...state,
        recoveryKey: action.payload,
        isCheckRecoveryKeyInProgress: false
      };

    case AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_START:
      return {
        ...state,
        isCheckRegistrationKeyInProgress: true,
        checkRegistrationKeyFail: false,
        registrationKey: null
      };

    case AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_ERROR:
      return {
        ...state,
        isCheckRegistrationKeyInProgress: false,
        checkRegistrationKeyFail: action.error
      };

    case AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_SUCCESS:
      return {
        ...state,
        registrationKey: action.payload,
        isCheckRegistrationKeyInProgress: false,
        checkRegistrationKeyFail: null
      };

    case SET_LOCALE_SESSION_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };

    case SET_SESSION_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageId: action.payload.languageId,
        language: action.payload.language
      };

    case FETCH_MEMBER_PERMISSIONS_START:
      return {
        ...state,
        isFetchMemberPermissionsInProgress: true
      };
    case FETCH_MEMBER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchMemberPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          global: action.payload
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchMemberPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          global: null
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          user: parseInt(action.userId) || null
        }
      };

    case FETCH_MEMBER_ORGANIZATION_PERMISSIONS_START:
      return {
        ...state,
        isFetchMemberOrganizationPermissionsInProgress: true
      };
    case FETCH_MEMBER_ORGANIZATION_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchMemberOrganizationPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          organization: action.payload,
          user: parseInt(action.userId) || null
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          organization: parseInt(action.id),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_ORGANIZATION_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchMemberOrganizationPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          organization: {}
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          organization: parseInt(action.id) || null,
          user: parseInt(action.userId) || null
        }
      };

    case FETCH_MEMBER_PLATFORM_PERMISSIONS_START:
      return {
        ...state,
        isFetchMemberPlatformPermissionsInProgress: true
      };
    case FETCH_MEMBER_PLATFORM_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchMemberPlatformPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          platform: action.payload
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          platform: parseInt(action.id),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_PLATFORM_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchMemberPlatformPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          platform: {}
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          platform: parseInt(action.id) || null,
          user: parseInt(action.userId) || null
        }
      };

    case FETCH_MEMBER_CAMPAIGN_PERMISSIONS_START:
      return {
        ...state,
        isFetchMemberCampaignPermissionsInProgress: true
      };
    case FETCH_MEMBER_CAMPAIGN_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchMemberCampaignPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          campaign: action.payload
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          campaign: parseInt(action.id),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_CAMPAIGN_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchMemberCampaignPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          campaign: {}
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          campaign: parseInt(action.id) || null,
          user: parseInt(action.userId) || null
        }
      };

    case FETCH_MEMBER_TEAM_PERMISSIONS_START:
      return {
        ...state,
        isFetchMemberTeamPermissionsInProgress: true
      };
    case FETCH_MEMBER_TEAM_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isFetchMemberTeamPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          team: action.payload
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          team: parseInt(action.id),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_TEAM_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchMemberTeamPermissionsInProgress: false,
        permissions: {
          ...(state.permissions || {}),
          team: {}
        },
        permissionsIds: {
          ...(state.permissionsIds || {}),
          team: parseInt(action.id) || null,
          user: parseInt(action.userId) || null
        }
      };

    case CLEAR_MEMBER_PERMISSIONS:
      return {
        ...state,
        permissions: {
          global: null,
          organization: null,
          platform: null,
          campaign: null,
          team: null
        },
        permissionsIds: {
          organization: null,
          platform: null,
          campaign: null,
          team: null
        }
      };

    case CLEAR_MEMBER_ADMIN_PERMISSIONS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          organization: null,
          platform: null,
          campaign: null,
          team: null
        },
        permissionsIds: {
          organization: null,
          platform: null,
          campaign: null,
          team: null
        }
      };

    case FETCH_MEMBER_ROLES_START:
      return {
        ...state,
        isFetchMemberRolesInProgress: true
      };
    case FETCH_MEMBER_ROLES_SUCCESS:
      return {
        ...state,
        isFetchMemberRolesInProgress: false,
        roles: {
          ...state.roles,
          global: action.payload
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_ROLES_ERROR:
      return {
        ...state,
        isFetchMemberRolesInProgress: false,
        roles: {
          ...state.roles,
          global: null
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          user: parseInt(action.userId) || null
        }
      };

    case FETCH_MEMBER_ORGANIZATION_ROLES_START:
      return {
        ...state,
        isFetchMemberOrganizationRolesInProgress: true
      };
    case FETCH_MEMBER_ORGANIZATION_ROLES_SUCCESS:
      return {
        ...state,
        isFetchMemberOrganizationRolesInProgress: false,
        roles: {
          ...state.roles,
          organization: action.payload
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          organization: parseInt(action.id),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_ORGANIZATION_ROLES_ERROR:
      return {
        ...state,
        isFetchMemberOrganizationRolesInProgress: false,
        roles: {
          ...state.roles,
          organization: {}
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          organization: parseInt(action.id) || null,
          user: parseInt(action.userId) || null
        }
      };

    case FETCH_MEMBER_PLATFORM_ROLES_START:
      return {
        ...state,
        isFetchMemberPlatformRolesInProgress: true
      };
    case FETCH_MEMBER_PLATFORM_ROLES_SUCCESS:
      return {
        ...state,
        isFetchMemberPlatformRolesInProgress: false,
        roles: {
          ...state.roles,
          platform: action.payload
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          platform: parseInt(action.id),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_PLATFORM_ROLES_ERROR:
      return {
        ...state,
        isFetchMemberPlatformRolesInProgress: false,
        roles: {
          ...state.roles,
          platform: {}
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          platform: parseInt(action.id) || null,
          user: parseInt(action.userId) || null
        }
      };

    case FETCH_MEMBER_CAMPAIGN_ROLES_START:
      return {
        ...state,
        isFetchMemberCampaignRolesInProgress: true
      };
    case FETCH_MEMBER_CAMPAIGN_ROLES_SUCCESS:
      return {
        ...state,
        isFetchMemberCampaignRolesInProgress: false,
        roles: {
          ...state.roles,
          campaign: action.payload
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          campaign: parseInt(action.id),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_CAMPAIGN_ROLES_ERROR:
      return {
        ...state,
        isFetchMemberCampaignRolesInProgress: false,
        roles: {
          ...state.roles,
          campaign: {}
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          campaign: parseInt(action.id) || null,
          user: parseInt(action.userId) || null
        }
      };

    case FETCH_MEMBER_TEAM_ROLES_START:
      return {
        ...state,
        isFetchMemberTeamRolesInProgress: true
      };
    case FETCH_MEMBER_TEAM_ROLES_SUCCESS:
      return {
        ...state,
        isFetchMemberTeamRolesInProgress: false,
        roles: {
          ...state.roles,
          team: action.payload
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          team: parseInt(action.id),
          user: parseInt(action.userId) || null
        }
      };
    case FETCH_MEMBER_TEAM_ROLES_ERROR:
      return {
        ...state,
        isFetchMemberTeamRolesInProgress: false,
        roles: {
          ...state.roles,
          team: {}
        },
        rolesIds: {
          ...(state.rolesIds || {}),
          team: parseInt(action.id) || null,
          user: parseInt(action.userId) || null
        }
      };

    case CLEAR_MEMBER_ROLES:
      return {
        ...state,
        roles: {
          global: null,
          organization: null,
          platform: null,
          campaign: null,
          team: null
        },
        rolesIds: {
          organization: null,
          platform: null,
          campaign: null,
          team: null
        }
      };

    case UPDATE_LOCATION_HISTORY:
      return {
        ...state,
        history: [
          ...action.payload,
          ...state.history
        ]
      };

    case UPDATE_SESSION_TIMEOUT_START:
      return {
        ...state,
        isUpdateSessionTimeOutInProgress: true
      };

    case UPDATE_SESSION_TIMEOUT_END:
      return {
        ...state,
        isUpdateSessionTimeOutInProgress: false
      };

    case UPDATE_SESSION_TIMEOUT:
      return {
        ...state,
        sessionTimeout: action.payload,
        isUpdateSessionTimeOutInProgress: false
      };

    case CLEAR_SESSION_TIMEOUT:
      return {
        ...state,
        sessionTimeout: initialState.sessionTimeout
      };

    case SHOW_SESSION_TIMEOUT_WARNING:
      return {
        ...state,
        displaySessionTimeoutWarning: true
      };

    case HIDE_SESSION_TIMEOUT_WARNING:
      return {
        ...state,
        displaySessionTimeoutWarning: initialState.displaySessionTimeoutWarning
      };

    case SHOW_SESSION_TIMEOUT_MESSAGE:
      return {
        ...state,
        displaySessionTimeoutMessage: true
      };

    case HIDE_SESSION_TIMEOUT_MESSAGE:
      return {
        ...state,
        displaySessionTimeoutMessage: initialState.displaySessionTimeoutMessage
      };

    case SAVE_IP:
      return {
        ...state,
        ip: action.payload
      };

    case CLEAR_IP:
      return {
        ...state,
        ip: initialState.ip
      };

    case SAVE_ID:
      return {
        ...state,
        token: action.payload
      };

    case IS_CAMPAIGN_OWNER_START:
      return {
        ...state,
        isCampaignOwnerInProgress: true
      };
    case IS_CAMPAIGN_OWNER_SUCCESS:
    case IS_CAMPAIGN_OWNER_ERROR:
      return {
        ...state,
        isCampaignOwner: action.payload,
        isCampaignOwnerInProgress: false
      };

    default:
      return state;
  }
}

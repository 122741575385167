import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import AddressField_en from './locales/AddressField_en.json';
import AddressField_fr from './locales/AddressField_fr.json';

class AddressField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AddressField', AddressField_en);
    i18next.addResourceBundle('fr', 'AddressField', AddressField_fr);
  }

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;

    const placeholder = (
      this.props.placeholder === true ?
        t('address') :
        this.props.placeholder || null
    );

    const requiredMessage = (
      this.props.requiredMessage ?
        this.props.requiredMessage :
        this.props.identity === "user" ?
          t('required') :
          this.props.identity === "company" ?
            t('required_company') :
            this.props.identity === "organization" ?
              t('required_organization') : null
    );

    const input = <Input placeholder={placeholder} />;
    const fieldId = 'address';
    const fieldOptions = {
      rules: [
        {
          required: this.props.required,
          message: requiredMessage
        },
        {
          pattern: /^[a-zA-Z0-9\u00C0-\u017F ,-\\']+$/,
          message: this.props.validationMessage || t('validation')
        },
        {
          pattern: /^.{5,128}$/,
          message: t('maximum')
        }
      ],
      initialValue: this.props.initialValue
    };

    const label = (
      this.props.label === true ?
        t('address') :
        this.props.label || null
    );

    return (
      <Form.Item
        className={this.props.className}
        label={label}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

AddressField.defaultProps = {
  label: true,
  placeholder: false,
  required: true,
  requiredMessage: null,
  validationMessage: null,
  initialValue: undefined,
  identity: 'user',
}

AddressField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  identity: PropTypes.oneOf(['user', 'company', 'organization']),
};

export default translate('AddressField')(AddressField);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import PostalCodeField_en from './locales/PostalCodeField_en.json';
import PostalCodeField_fr from './locales/PostalCodeField_fr.json';

class PostalCodeField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      zipCodeValue: '',
      value: props.initialValue,
    }

    i18next.addResourceBundle('en', 'PostalCodeField', PostalCodeField_en);
    i18next.addResourceBundle('fr', 'PostalCodeField', PostalCodeField_fr);

    this.onZipCodeChange = this.onZipCodeChange.bind(this);
  }

  normalizeAll = (value) => {
    if (value && value.length)
      return value.toUpperCase();
  };

  // To use or not the zip+4
  beforeMaskedValueChange = (newState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value && this.state.value && value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection
    };
  }

  onZipCodeChange = (event) => {
    this.setState({
      value: event.target.value
    });
  }

  render() {
    const { t, country, className } = this.props;
    const { getFieldDecorator } = this.props.form;

    const placeholder = this.props.placeholder === true && this.props.label && country === 'CA'
      ? t('placeholder-CA')
      : this.props.placeholder === true && this.props.label && country === 'US'
        ? t('placeholder-US')
        : this.props.placeholder === true && !this.props.label && country === 'US'
          ? t('zip-code')
          : this.props.placeholder === true && !this.props.label
            ? t('postal-code')
            : this.props.placeholder || null;

    const input = country === 'CA'
      ? <InputMask
          placeholder={placeholder}
          mask="a9a 9a9"
          maskChar={null}
          className="ant-input"
          disabled={this.props.disabled}
        />
      : country === 'US'
        ? <InputMask
            placeholder={placeholder}
            mask="99999-9999"
            maskChar={null}
            className="ant-input"
            value={this.state.zipCodeValue}
            onChange={this.onZipCodeChange}
            beforeMaskedValueChange={this.beforeMaskedValueChange}
            disabled={this.props.disabled}
          />
        : <Input placeholder={placeholder} disabled={this.props.disabled}/>;

    const pattern = country === 'CA'
      ? {
          pattern: /^([A-Z]{1}\d{1}[A-Z]{1}\s?\d{1}[A-Z]{1}\d{1})$/,
          message: this.props.validationMessage || t('validation')
        }
      : country === 'US'
        ? {
            pattern: /^(\d{5}(?:[-\s]\d{4})?)$/,
            message: this.props.validationMessage || t('validation')
          }
        : {
            pattern: /^.{2,12}$/,
            message: this.props.validationMessage || t('validation')
          };

    const requiredMessage = this.props.requiredMessage
      ? this.props.requiredMessage
      : this.props.identity === "user" && this.props.country === "US"
        ? t('required-zipcode')
        : this.props.identity === "company" && this.props.country === "US"
          ? t('required_company-zipcode')
          : this.props.identity === "organization" && this.props.country === "US"
            ? t('required_organization-zipcode')
            : this.props.identity === "organization"
              ? t('required_organization')
              : this.props.identity === "company"
                ? t('required_company')
                : this.props.identity === "user"
                  ? t('required')
                  : null;

    const fieldId = 'postalCode';
    const fieldOptions = {
      rules: [
          {
            required: this.props.required,
            message: requiredMessage
          },
          pattern
        ],
      initialValue: this.props.initialValue || undefined,
      normalize: this.normalizeAll
    };

    const label = this.props.label === true && country === 'US'
      ? t('zip-code')
      : this.props.label === true
        ? t('postal-code')
        : this.props.label || null;

    return (
      <Form.Item
        className={classnames( 'PostalCode', className )}
        hasFeedback
        label={label}
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

PostalCodeField.defaultProps = {
  required: true,
  initialValue: undefined,
  requiredMessage: null,
  validationMessage: null,
  country: null,
  identity: "user", // "company", "organization",
  className: '',
  disabled: false
}

PostalCodeField.propTypes = {
  required: PropTypes.bool,
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  initialValue: PropTypes.string,
  identity: PropTypes.string,
};

export default translate('PostalCodeField')(PostalCodeField);

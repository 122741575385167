import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { Layout, Collapse } from 'antd';

import AdminTitleBar from 'Parts/common/AdminTitleBar';
import CampaignSettingsContainer from './components/CampaignSettingsFormContainer';
import Can from 'Parts/session/components/CanContainer';

import CampaignSettingsPageEN from './locales/CampaignSettingsPage_en.json';
import CampaignSettingsPageFR from './locales/CampaignSettingsPage_fr.json';

import './CampaignSettingsPage.less';

class CampaignSettingsPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CampaignSettingsPage', CampaignSettingsPageEN);
    i18next.addResourceBundle('fr', 'CampaignSettingsPage', CampaignSettingsPageFR);
  }

  render() {
    const { t, locale } = this.props;
    const Panel = Collapse.Panel;

    return (
      <Layout className="CampaignSettingsPage">
        <Helmet>
          <title>{t('Campaign Settings')}</title>
        </Helmet>
        <AdminTitleBar title={t('Campaign Settings')} />

        <div className="AdminPanel">
          <Can rules={['permissions.platform.platform.update']} redirect={`/${locale}/d`}>
            <Collapse accordion bordered={false} defaultActiveKey={['1']}>
              <Panel header={t('Third-party campaigns')} key="1">
                <CampaignSettingsContainer />
              </Panel>
            </Collapse>
          </Can>
        </div>
      </Layout>
    );
  }
}

export default translate('CampaignSettingsPage')(CampaignSettingsPage);

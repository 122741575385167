import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import TypeField_en from './locales/TypeField_en.json';
import TypeField_fr from './locales/TypeField_fr.json';

import TypeOptions from './TypeOptions';

class TypeField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TypeField-campaign', TypeField_en);
    i18next.addResourceBundle('fr', 'TypeField-campaign', TypeField_fr);

    this.state = {
      selected: null
    };
  }

  render() {
    const { t, form, className, initialValue, onChange } = this.props;
    const { getFieldDecorator } = form;

    const input = <TypeOptions onChange={onChange} />;
    const fieldId = 'template';
    const fieldOptions = {
      initialValue: initialValue,
      rules: [{ required: true, message: t('require') }]
    };

    return (
      <Form.Item className={className} hasFeedback>
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }
}

TypeField.defaultProps = {
  initialValue: null,
  onChange: () => {}
};

TypeField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default translate('TypeField-campaign')(TypeField);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { get } from 'lodash';

import dataFetcher from 'Parts/chore/dataFetcher';
import { searchPlatformMembersParticipations, clearSearch } from '../../../search/searchActions';

import ParticipationsList from './ParticipationsList';

const mapStateToProps = state => ({
  platformId: get(state, 'platform.platform.id'),
  platform: get(state, 'platform.platform'),
  memberId: get(state, 'member.member.id'),
  participations: get(state, 'search.memberParticipations.results', []),
  pagination: get(state, 'search.memberParticipations.pagination', {}),
  filters: get(state, 'search.memberParticipations.filterBy'),
  search: get(state, 'search.memberParticipations.search'),
  orderBy: get(state, 'search.memberParticipations.orderBy'),
  locale: get(state, 'session.language'),
  organization: get(state, 'organization.organization')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchPlatformMembersParticipations,
  clearSearch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(ParticipationsList);

import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Select } from 'antd';
import { PAGESIZE_OPTIONS } from './searchUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import PaginationTop_en from './locales/PaginationTop_en.json';
import PaginationTop_fr from './locales/PaginationTop_fr.json';

import './PaginationTop.less';

const { Option } = Select;

function getCurrentPage(firstIndex, pageSize) {
  return Math.floor(firstIndex / pageSize) + 1;
}

function getTotalPage(total, pageSize) {
  // Math.max handles the edge-case "total = 0"
  return Math.floor(Math.max(total - 1, 0) / pageSize) + 1;
}

class PaginationTop extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PaginationTop', PaginationTop_en);
    i18next.addResourceBundle('fr', 'PaginationTop', PaginationTop_fr);
  }

  handlePageSizeChange = pageSize => {
    const pager = {
      ...this.props.pager,
      pageSize,
      current: 1
    };

    this.props.onChange(pager);
  }

  handlePaginationChange = (current, pageSize) => {
    const pager = {
      ...this.props.pager,
      current,
      pageSize
    };

    this.props.onChange(pager);
  }

  itemRender = (current, type, originalElement) => {
    const { t } = this.props;
    if (type === 'prev') {
      return <a><FontAwesomeIcon icon={["fas", "chevron-left"]} size="xs" /> {t('previous')}</a>;
    } else if (type === 'next') {
      return <a>{t('next')} <FontAwesomeIcon icon={["fas", "chevron-right"]} size="xs" /></a>;
    }
    return originalElement;
  }

  render() {
    const { t } = this.props;

    const options = this.props.pageSizeOptions || PAGESIZE_OPTIONS;

    // We use a custom Select because the default Antd sizeChanger can't be placed wherever we want
    const sizeChanger = this.props.showSizeChanger ? (
      <Select defaultValue={this.props.pager.pageSize} onChange={this.handlePageSizeChange}>
        {options.map(pageSize => <Option className="PaginationTop__SelectOption" key={pageSize} value={pageSize}>{`${pageSize} ${t('results')}`}</Option>)}
      </Select>
    ) : null;

    return (
      <div className="PaginationTop">
        <Pagination
          total={this.props.pager.total}
          showTotal={(total, range) => (
            <div className="PaginationTop__TotalInfo">
              <span>{this.props.totalLabel(total)}</span>
              <span>{t('page')} {getCurrentPage(range[0], this.props.pager.pageSize)} {t('of')} {getTotalPage(total, this.props.pager.pageSize)}</span>
            </div>
          )}
          itemRender={this.itemRender}
          pageSize={this.props.pager.pageSize}
          onChange={this.handlePaginationChange}
          current={this.props.pager.current}
        />
        {this.props.children}
        {sizeChanger}
      </div>
    );
  }

}

PaginationTop.defaultProps = {
  onChange: () => {},
  showSizeChanger: true
};

PaginationTop.propTypes = {
  totalLabel: PropTypes.func.isRequired,
  pager: PropTypes.shape({
    current: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  onChange: PropTypes.func,
  children: PropTypes.element,
  showSizeChanger: PropTypes.bool
};

export default translate('PaginationTop')(PaginationTop);

import {
  FETCH_COUNTRY_LIST_SUCCESS,
  FETCH_PROVINCE_LIST_SUCCESS
} from './geoActions';

const initialState = {
  countries: [],
  provinces: {}
};

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countries: action.payload
      };

    case FETCH_PROVINCE_LIST_SUCCESS:
      return {
        ...state,
        provinces: action.payload
      };

    default:
      return state;
  }
}

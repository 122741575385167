import React from 'react';
import { Form } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { pickBy, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';
import { facebookScrape } from 'Parts/common/requestUtils';
import { nameFieldValidator, emailFieldValidator } from 'Parts/ui/validations/fieldValidations';
import TextField from 'Parts/ui/components/fields/TextField';
import SelectField from 'Parts/ui/components/fields/SelectField';
import AddressForm from '../../members/components/AddressFormContainer';
import InformationFormEN from '../..//members/components/locales/InformationForm_en.json';
import InformationFormFR from '../..//members/components/locales/InformationForm_fr.json';
import FakeField from 'Parts/ui/components/fields/FakeField';
import { getRoleIdFromRoles } from 'Parts/session/sessionUtils';

import './InformationForm.less';

class InformationForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'InformationForm', InformationFormEN);
    i18next.addResourceBundle('fr', 'InformationForm', InformationFormFR);
  }

  handleSubmit = e => {
    e.preventDefault();

    const { validateFields, isFieldTouched } = this.props.form;

    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (updatedValues.email === this.props.user.email) {
          delete updatedValues.email;
        }

        const values = { ...updatedValues };

        if (!isEmpty(values)) {
          this.props.updateMember(values, this.props.user.id).then(() => {
            this.props.updateSessionState();
          });
        }

        //   const platformUrl = getPlatformUrl(this.props.platformAlias);
        //   const campaignUrl = this.props.participant.campaignAlias;

        //   if (this.props.participant.teamAlias) {

        //     let teamUrl = this.props.participant.teamAlias;

        //     this.scrapeCampaignsTeamsParticipants(`${platformUrl}/en/${campaignUrl}/${teamUrl}/${this.props.participant.member.alias}`);
        //     this.scrapeCampaignsTeamsParticipants(`${platformUrl}/fr/${campaignUrl}/${teamUrl}/${this.props.participant.member.alias}`);

        //   } else {
        //     this.scrapeCampaignsTeamsParticipants(`${platformUrl}/en/${campaignUrl}/individual-participant/${this.props.participant.member.alias}`);
        //     this.scrapeCampaignsTeamsParticipants(`${platformUrl}/fr/${campaignUrl}/participant-individuel/${this.props.participant.member.alias}`);
        //   }
      }
    });
  };

  render() {
    const { t, user, memberRoleId, roles } = this.props;

    const preferredLanguage = [
      { value: 1, text: t('english') },
      { value: 2, text: t('french') }
    ];

    // const optinChoices = [
    //   {value: 1, text: t('yes')},
    //   {value: 2, text: t('no')}
    // ];

    const titleOptions = [
      { value: 1, text: t('mr') },
      { value: 2, text: t('mrs') },
      { value: 3, text: t('ms') },
      { value: 4, text: t('mx') }
    ];

    const platformRole = getRoleIdFromRoles(roles.platform);

    return (
      <div className="InformationFormContainer">
        <h3>{t('basic-information')}</h3>
        <Form className="InformationForm" layout="horizontal">
          <FakeField
            data={t(`ROLES:${platformRole > memberRoleId ? platformRole : memberRoleId}`)}
            label={t('role')}
            className="member-role"
          />
          <SelectField
            form={this.props.form}
            label={t('title')}
            fieldId={'honorificId'}
            optionsArray={titleOptions}
            initialValue={user.honorificId}
            style={{ width: 100 }}
          />
          <TextField
            form={this.props.form}
            label={t('FIELDS:first-name')}
            fieldId={'firstName'}
            initialValue={user.firstName}
            maxlength={75}
            counter={true}
            fieldOptions={{
              rules: [...nameFieldValidator(true, 75)]
            }}
          />
          <TextField
            form={this.props.form}
            label={t('FIELDS:last-name')}
            fieldId={'lastName'}
            initialValue={user.lastName}
            maxlength={75}
            counter={true}
            fieldOptions={{
              rules: [...nameFieldValidator(true, 75)]
            }}
          />
          <SelectField
            form={this.props.form}
            label={t('preferred-language')}
            fieldId={'languageId'}
            optionsArray={preferredLanguage}
            initialValue={user.languageId}
          />

          {/* NO OPTIN YET (AT LEAST NOT SAVED IN MEMBER) */}
          {/* <SelectField
            form={this.props.form}
            label={t('optin-platform')}
            fieldId={'optin_platform'}
            optionsArray={optinChoices}
            initialValue={member.languageId}
          />
          <SelectField
            form={this.props.form}
            label={t('optin-fundky')}
            fieldId={'optin_fundky'}
            optionsArray={optinChoices}
            initialValue={member.languageId}
          /> */}
          <TextField
            form={this.props.form}
            label={t('email')}
            fieldId={'email'}
            initialValue={user.email}
            fieldOptions={{
              rules: [...emailFieldValidator()]
            }}
          />

          <CancelAndSaveButtons onClickSave={this.handleSubmit} />
        </Form>

        <AddressForm user={user} origin="profile" />
      </div>
    );
  }
}

InformationForm.propTypes = {
  form: PropTypes.object.isRequired
};
export default translate('InformationForm')(InformationForm);

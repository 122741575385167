import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import TermsField_en from './locales/TermsField_en.json';
import TermsField_fr from './locales/TermsField_fr.json';

class TermsField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TermsField', TermsField_en);
    i18next.addResourceBundle('fr', 'TermsField', TermsField_fr);
  }

  render() {
    const { t, form, className, savedValue } = this.props;
    const { getFieldDecorator } = form;

    const initialValue = savedValue ? savedValue : undefined;

    const input = <Checkbox>{t('label-part1')}<a href={t('URL:terms-of-use')} target="_blank" rel="noopener noreferrer"><strong>{t('label-part2')}</strong></a>{t('label-part3')}<Link to={`/${i18next.language}/${t('URL:refund-policy')}`} target="_blank" rel="noopener noreferrer"><strong>{t('label-part4')}</strong></Link>{t('label-part5')}</Checkbox>;
    const fieldId = 'acceptedTermsConditions';
    const fieldOptions = {
      valuePropName: 'checked',
      rules: [
        {
          validator: (rule, value, cb) => (value === true ? cb() : cb(true)),
          message: t('require')
        }
      ],
      initialValue: initialValue
    };

    return (
      <Form.Item className={className} hasFeedback>
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

TermsField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default translate('TermsField')(TermsField);

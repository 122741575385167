import i18n from 'i18next';
import { merge } from 'lodash';

import { fr_ca as fr_ca_parts } from 'Parts/locales/fr_ca.js';
import { en_ca as en_ca_parts } from 'Parts/locales/en_ca.js';
import { fr_ca } from '../locales/fr_ca.js';
import { en_ca } from '../locales/en_ca.js';

let lng = typeof window !== 'undefined' ? window.location.pathname.split('/')[1] : 'en';

i18n.init({
  lng: lng,
  whitelist: ['en', 'fr'],
  fallbackLng: 'en',
  resources: {
    en: merge(en_ca_parts, en_ca),
    fr: merge(fr_ca_parts, fr_ca)
  },
  debug: false,

  interpolation: {
    escapeValue: false // not needed for react!!
  }
});

export default i18n;

import React from 'react';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { Button } from 'antd';
import { get } from 'lodash';

import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Plugin from '../../../plugin/Plugin';

import { simpleColumn, dateColumn } from 'Parts/common/columnUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isPluginActive } from '../../../plugin/pluginActions';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';

import DonationsListEN from './locales/DonationsList_en.json';
import DonationsListFR from './locales/DonationsList_fr.json';

class DonationsList extends React.Component {
  constructor(props) {
    super(props);

    props.transactions.total = props.pagination.totalResults;

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    i18next.addResourceBundle('en', 'DonationsList', DonationsListEN);
    i18next.addResourceBundle('fr', 'DonationsList', DonationsListFR);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchPlatformMembersTransactions(
      this.props.platformId,
      this.props.memberId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      false
    );
  };

  view = entity => {
    return this.props.history.push( entity.campaignId
        ?   `/${this.props.locale}/d/campaigns/${entity.campaignId}/transactions/${entity.id}`
        :   `/${this.props.locale}/d/transactions/${entity.id}`
    );
  };

  render() {
    const { t, transactions, pagination, locale, platform } = this.props;
    const { pager } = this.state;

    return (
      <div className="AdminList">
        <PaginationTop
          totalLabel={total => t('doanationsTotal', { count: pagination.totalResults || 0 })}
          pager={pager}
          onChange={this.handlePaginationChange}
        />
        <ResultTable
          dataSource={transactions}
          pagination={pager}
          onChange={this.handlePaginationChange}
          showHeader={true}
          columns={[
            simpleColumn(t('Recipient'), 'scope.name', null, true),
            //simpleColumn(t('Amount'), 'amount', text => moneyFormat(text, locale)),
            simpleColumn(
              t('Amount'),
              null,
              transaction => {
                const sub = get(transaction, 'subscription.amount', null);
                return moneyFormat(sub || transaction.amount, locale) + (sub ? '/m' : '');
              },
              true
            ),
            dateColumn(t('Created on'), 'addedOn', null, null, false, locale),
            simpleColumn(t('Edit'), null, transactions => {
              let buttons = [
                <Button
                  key={`${transactions.id}-1`}
                  shape="circle"
                  onClick={() => {
                    this.view(transactions);
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'edit']} size="lg" />
                </Button>
              ];

            //   if (
            //     get(transactions, 'plugins.taxReceipt[0].id') &&
            //     isPluginActive(this.props.platform, 'TaxReceipt') &&
            //     this.props.organization.typeId === 2
            //   ) {
            //     buttons.push(
            //       <span key={`${transactions.id}-2`}>
            //         <Plugin
            //           name="TaxReceipt"
            //           component="TaxReceiptButton"
            //           {...{
            //             // Props
            //             transaction: transactions,
            //             margin: "0"
            //           }}
            //         />
            //       </span>
            //     );
            //   }

              return <div className="buttons">{buttons}</div>;
            })
          ]}
        />
      </div>
    );
  }
}

DonationsList.defaultProps = {
  transactions: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('DonationsList')(DonationsList);

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get } from 'lodash';

import PaginationTop from 'Parts/search/PaginationTop';
import ResultThumbnailList from 'Parts/search/ResultThumbnailList';
import {
  DEFAULT_COMPACT_PAGER_NO_ADD_SEARCH_PAGE,
  SEARCHPAGESIZE_OPTIONS
} from 'Parts/search/searchUtils';
import AvatarThumbnail from '../../common/AvatarThumbnail';

import SearchCampaignPanel_en from './locales/SearchCampaignPanel_en.json';
import SearchCampaignPanel_fr from './locales/SearchCampaignPanel_fr.json';

class SearchCampaignPanel extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'SearchCampaignPanel', SearchCampaignPanel_en);
    i18next.addResourceBundle('fr', 'SearchCampaignPanel', SearchCampaignPanel_fr);

    this.state = {
      pager: {
        ...DEFAULT_COMPACT_PAGER_NO_ADD_SEARCH_PAGE,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 12
      }
    };

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
    this.getLinkTo = this.getLinkTo.bind(this);
    this.handleOnChangePaginationBottom = this.handleOnChangePaginationBottom.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (
      prevProps.pagination.pageNumber !== this.props.pagination.pageNumber ||
      prevProps.pagination.pageResults !== this.props.pagination.pageResults ||
      prevProps.pagination.totalResults !== this.props.pagination.totalResults
    ) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 12
      };

      this.setState({ pager });
    }
  }

  handlePaginationChange(pager) {
    this.setState({ pager });
    const pagination = {
      pageNumber: pager.current,
      pageResults: pager.pageSize
    };
    this.props.searchCampaigns(
      this.props.platformId,
      this.props.campaigns.search,
      this.props.campaigns.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      false,
      false,
      this.props.from === 'donation'
    );
  }

  handleThumbnailClick(campaign) {
    //return this.props.selectCampaign(campaign);
  }

  getLinkTo(campaign) {
    const { t, locale } = this.props;
    const { alias } = campaign;

    if (this.props.from === 'donation') {
      return { pathname: `/${locale}/${alias}/${t('URL:give')}` };
    } else {
      return `/${locale}/${alias}`;
    }
  }

  handleOnChangePaginationBottom(page, pageSize) {
    this.setState({
      pager: {
        ...this.state.pager,
        current: page,
        pageSize: pageSize
      }
    });
    const pagination = {
      pageNumber: page,
      pageResults: pageSize
    };
    this.props.searchCampaigns(
      this.props.platformId,
      this.props.campaigns.search,
      this.props.campaigns.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      false,
      false,
      this.props.from === 'donation'
    );
  }

  render() {
    const { t, from } = this.props;

    return (
      <div className="SearchCampaignPanel">
        <PaginationTop
          totalLabel={total => t('campaigns', { count: total })}
          pager={this.state.pager}
          onChange={this.handlePaginationChange}
          pageSizeOptions={SEARCHPAGESIZE_OPTIONS}
        />
        <ResultThumbnailList
          rowKey="id"
          dataSource={this.props.campaigns || []}
          onThumbnailClick={this.handleThumbnailClick}
          renderRecord={campaign =>
            from !== 'donation' ||
            (from === 'donation' && get(campaign, 'actions.canReceiveDonations', false)) ? (
              <Link to={this.getLinkTo(campaign)}>
                <Card bordered={false}>
                  <AvatarThumbnail
                    entity={campaign}
                    primaryContent={campaign.name}
                    avatarSize={58}
                  />
                </Card>
              </Link>
            ) : (
              <Tooltip placement="bottom" title={t('SearchPage:donation-disabled')}>
                <Card bordered={false} className="card-disabled">
                  <AvatarThumbnail
                    entity={campaign}
                    primaryContent={campaign.name}
                    avatarSize={58}
                  />
                </Card>
              </Tooltip>
            )
          }
          pagination={this.state.pager}
          onChange={this.handleOnChangePaginationBottom}
        />
      </div>
    );
  }
}

SearchCampaignPanel.defaultProps = {
  campaigns: [],
  pagination: {
    totalResults: 0
  },
  from: null
};

SearchCampaignPanel.propTypes = {
  campaigns: PropTypes.array,
  pagination: PropTypes.object,
  from: PropTypes.string
};

export default translate('SearchCampaignPanel')(SearchCampaignPanel);

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SearchField extends React.Component {

  render() {
    const { getFieldDecorator } = this.props.form;
    const prefix = <FontAwesomeIcon className="SearchBar__InputPrefixIcon" icon={["fas", "search"]} />
    const input = <Input
      size="large"
      prefix={prefix}
      placeholder={this.props.compact && this.props.title ? this.props.title : this.props.placeholder}
      suffix={this.props.openFilterBarIcon}
      onChange={this.props.onChange}
    />;
    const fieldId = 'search';
    const fieldOptions = {};
    return (
      <Form.Item>
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

SearchField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  compact: PropTypes.bool,
  title: PropTypes.string,
  openFilterBarIcon:PropTypes.element,
  onChange:PropTypes.func.isRequired
};

export default SearchField;

import React from 'react';
import { Form } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { pickBy, get } from 'lodash';
import { getLanguageNameByKey, getDefaultLanguageKey } from 'Parts/common/langUtils';

import SettingsColumn from '../../components/SettingsColumn';
import LanguageBoxForm from '../../components/LanguageBoxFormContainer';
import FeaturedCampaigns from './fields/FeaturedCampaigns';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';
import TextField from 'Parts/ui/components/fields/TextField';
import TextAreaField from 'Parts/ui/components/fields/TextAreaField';

import PagesHomepageFeaturedCampaignFormEN from './locales/PagesHomepageFeaturedCampaignForm_en.json';
import PagesHomepageFeaturedCampaignFormFR from './locales/PagesHomepageFeaturedCampaignForm_fr.json';

import './PagesHomepageFeaturedCampaignForm.less';

class PagesHomepageFeaturedCampaignForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disableSubmit: true,
      disabledUnilangualFields: false,
      contentLang: getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale,
      defaultTitle: {
        en: 'Featured campaigns',
        fr: 'Campagnes vedettes'
      },
      defaultDescription: {
        en: 'Get involved! Check out these featured campaigns and help them reach their goal.',
        fr:
          'Découvrez les campagnes vedettes. Impliquez-vous pour les aider à atteindre leur objectif.'
      }
    };

    this.changeHasHappened = false;

    i18next.addResourceBundle(
      'en',
      'PagesHomepageFeaturedCampaignForm',
      PagesHomepageFeaturedCampaignFormEN
    );
    i18next.addResourceBundle(
      'fr',
      'PagesHomepageFeaturedCampaignForm',
      PagesHomepageFeaturedCampaignFormFR
    );
  }

  handleFeaturedOnChange = () => {
    if (this.state.disableSubmit) {
      this.setState({
        disableSubmit: false
      });
    }
  };

  checkForChangeTitle = value => {
    const { defaultTitle } = this.state;
    const title =
      this.getContent(this.state.contentLang, 'title') || defaultTitle[this.state.contentLang];
    if (!this.changeHasHappened && value != title) {
      this.changeHasHappened = true;
    }
  };

  checkForChangeDesc = value => {
    const { defaultDescription } = this.state;
    const description =
      this.getContent(this.state.contentLang) || defaultDescription[this.state.contentLang];
    if (!this.changeHasHappened && value != description) {
      this.changeHasHappened = true;
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    this.changeHasHappened = false;

    const { validateFields, getFieldValue, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        const featuredValuesWithWeight = allValues.featured_campaigns
          ? allValues.featured_campaigns
              .map((featuredValue, index) => {
                return {
                  campaignId: featuredValue,
                  weight: (allValues.featured_campaigns.length - index) * 100
                };
              })
          : null;

        const sectionValues =
          typeof updatedValues.featured_title !== 'undefined' ||
          typeof updatedValues.featured_description !== 'undefined'
            ? {
                title:
                  updatedValues.featured_title === ''
                    ? ''
                    : updatedValues.featured_title || undefined,
                description:
                  updatedValues.featured_description === ''
                    ? ''
                    : updatedValues.featured_description || undefined
              }
            : null;

        this.props
          .updatePlatformFeaturedCampaigns(
            this.props.platformId,
            featuredValuesWithWeight,
            sectionValues,
            this.state.contentLang
          )
          .then(() => {
            this.setState({
              disableSubmit: true
            });
          });
      }
    });
  };

  getContent = (lang, type = 'description') => {
    const lng = getLanguageNameByKey(lang, false);
    return get(this.props, `featuredText.[${lng}].${type}`, null);
  };

  handleContentLanguageChange = value => {
    this.changeHasHappened = false;
    const defaultLangKey = getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale;
    this.setState(
      {
        contentLang: value,
        disabledUnilangualFields: defaultLangKey != value
      },
      () => {
        this.forceTexts(value);
      }
    );
  };

  forceTexts = lang => {
    const { defaultTitle, defaultDescription } = this.state;
    const title = this.getContent(lang, 'title') || defaultTitle[lang];
    const description = this.getContent(lang) || defaultDescription[lang];
    this.props.form.setFieldsValue({
      featured_title: title,
      featured_description: description
    });
    this.changeHasHappened = false;
  };

  render() {
    const {
        props,
        state,
        handleSubmit,
        handleContentLanguageChange,
        handleFeaturedOnChange,
        checkForChangeTitle,
        checkForChangeDesc
      } = this,
      {
        t,
        form,
        campaigns,
        featuredCampaigns,
        isUpdatePlatformSectionInProgress,
        isDeletePlatformSectionInProgress,
        isUpdatePlatformFeaturedCampaignsInProgress
      } = props,
      { disableSubmit, contentLang, defaultTitle, defaultDescription } = state;

    const selectedItems = featuredCampaigns.length
      ? featuredCampaigns.map(featuredCampaign => {
          return featuredCampaign.id;
        })
      : [];
    const title = this.getContent(contentLang, 'title');
    const description = this.getContent(contentLang);

    return (
      <div className="PagesHomepageFeaturedCampaignPanel AdminFormWrap">
        <Form
          layout="vertical"
          className="PagesHomepageFeaturedCampaignForm"
          onSubmit={handleSubmit}
          key={contentLang}
        >
          <h3 className="panel-title">{t('Featured campaign')}</h3>
          <div className="inputs">
            <TextField
              form={form}
              fieldId={'featured_title'}
              label={t('field-title-label')}
              contentLang={contentLang}
              initialValue={title || defaultTitle[contentLang]}
              maxlength={128}
              counter
              onChange={() => {
                handleFeaturedOnChange();
                checkForChangeTitle();
              }}
            />

            <TextAreaField
              form={form}
              fieldId={'featured_description'}
              label={t('field-description-label')}
              contentLang={contentLang}
              placeholder={false}
              initialValue={description || defaultDescription[contentLang]}
              onChange={() => {
                handleFeaturedOnChange();
                checkForChangeDesc();
              }}
              maxlength={255}
            />

            <FeaturedCampaigns
              form={form}
              fieldId="featured_campaigns"
              campaigns={campaigns}
              handleFeaturedOnChange={handleFeaturedOnChange}
              selectedItems={selectedItems}
              disabled={this.state.disabledUnilangualFields}
            />
          </div>
          <CancelAndSaveButtons
            disabled={
              disableSubmit ||
              isDeletePlatformSectionInProgress ||
              isUpdatePlatformSectionInProgress ||
              isUpdatePlatformFeaturedCampaignsInProgress
            }
          />
        </Form>
        <SettingsColumn>
          <LanguageBoxForm
            handleContentLanguageChange={handleContentLanguageChange}
            changeHasHappened={this.changeHasHappened}
            defaultLanguage={
              getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale
            }
          />
        </SettingsColumn>
      </div>
    );
  }
}

export default translate('PagesHomepageFeaturedCampaignForm')(PagesHomepageFeaturedCampaignForm);

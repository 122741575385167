import React from 'react';
import PropTypes from 'prop-types';

import './StaticTitle.less';

class StaticTitle extends React.Component {
  render() {
    return (
      <div className="StaticTitle">
        <h1>{this.props.title}</h1>
        <hr/>
      </div>
    );
  }
}

StaticTitle.defaultProps = {
  title: '',
};

StaticTitle.propTypes = {
  title: PropTypes.string,
};

export default StaticTitle;

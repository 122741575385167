import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import i18next from 'i18next';
import { translate } from 'react-i18next';

import MoneyField from 'Parts/ui/components/fields/MoneyField';

import transactionsSuggestedAmountsFieldsEN from './locales/TransactionsSuggestedAmountsField_en.json';
import transactionsSuggestedAmountsFieldsFR from './locales/TransactionsSuggestedAmountsField_fr.json';

import './TransactionsSuggestedAmountsFields.less';

class TransactionsSuggestedAmountsField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allowCustomAmount: props.allowCustomAmount
    };

    i18next.addResourceBundle(
      'en',
      'TransactionsSuggestedAmountsField',
      transactionsSuggestedAmountsFieldsEN
    );
    i18next.addResourceBundle(
      'fr',
      'TransactionsSuggestedAmountsField',
      transactionsSuggestedAmountsFieldsFR
    );
  }

  onChange = e => {
    this.setState({
      allowCustomAmount: e.target.checked
    });
  };

  render() {
    const { t, data, locale, disabled, form } = this.props;
    const { allowCustomAmount } = this.state;
    const { getFieldDecorator } = this.props.form;

    const markup = data.map((amount, index) => (
      <MoneyField
        form={form}
        key={`suggested_amount_${index}`}
        fieldId={`suggested_amount_${index}`}
        initialValue={amount}
        locale={locale}
        width={100}
        label={null}
        required={true}
        wholeNumber={true}
        minimum={5}
        disabled={disabled}
        validationInside={true}
      />
    ));

    return (
      <React.Fragment>
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label transactions-suggestions-label-wrap">
            <label className="transactions-suggestions-label"><span>{t('Suggested Amounts Label')}</span></label>
          </div>
          <div className="ant-col ant-form-item-control-wrapper transactions-suggestions-wrap">
            {markup}
          </div>
        </div>
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label transactions-suggestions-label-wrap">
          </div>
          <div className="ant-col ant-form-item-control-wrapper transactions-suggestions-wrap">
            <p>{t('Suggested Amounts Description')}</p>
          </div>
        </div>


        <Form.Item
          label=" "
          colon={false}
        >
          {getFieldDecorator('chk_donors_custom_amount', {
            valuePropName: 'checked',
            initialValue: allowCustomAmount
          })(
            <Checkbox onChange={this.onChange} disabled={disabled}>
              {t('Allow donors to give a custom amount')}
            </Checkbox>
          )}
        </Form.Item>
        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label transactions-suggestions-label-wrap">
          </div>
          <div className="ant-col ant-form-item-control-wrapper transactions-suggestions-wrap">
            <p className="transactions-suggestions-description">{t('Suggested Amounts Checkbox Description')}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TransactionsSuggestedAmountsField.defaultProps = {
  disabled: false
};

export default translate('TransactionsSuggestedAmountsField')(TransactionsSuggestedAmountsField);

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';

import dataFetcher from "../../parts/chore/dataFetcher";
import {
  searchPlugins,
  clearSearch
} from '../../search/searchActions';
import PluginsListPage from './PluginsListPage';

const mapStateToProps = state => ({
  platform: get(state, 'platform.platform'),
  locale: get(state, 'session.language'),
  plugins: get(state, 'search.plugins.results', []),
  pagination: get(state, 'search.plugins.pagination', {}),
  filters: get(state, 'search.plugins.filters'),
  search: get(state, 'search.plugins.search'),
  orderBy: get(state, 'search.plugins.orderBy')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchPlugins,
  clearSearch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(PluginsListPage);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { get } from 'lodash';

import dataFetcher from 'Parts/chore/dataFetcher';
import { searchParticipations, clearSearch } from '../../../search/searchActions';

import MyContributionsListParticipations from './MyContributionsListParticipations';

const mapStateToProps = state => ({
  platformId: get(state, 'platform.platform.id'),
  memberId: get(state, 'session.user.id'),
  participations: get(state, 'search.participations.results', []),
  pagination: get(state, 'search.participations.pagination', {}),
  filters: get(state, 'search.participations.filters'),
  search: get(state, 'search.participations.search'),
  orderBy: get(state, 'search.participations.orderBy'),
  locale: get(state, 'session.language')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchParticipations,
  clearSearch
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(MyContributionsListParticipations);

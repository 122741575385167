import React, { useEffect } from 'react';
import { connectStateResults } from 'react-instantsearch-dom';

const AlgoliaCustomSearchResults = ({ searching, searchResults, getSearchParameters }) => {
  // Return the search results state to the parent component
  // Used with algoliaHelper to search and generate csv.
  useEffect(() => {
    if (!searching && searchResults) {
      getSearchParameters(searchResults);
    }
  }, [searching]);

  return null;
};

export default connectStateResults(AlgoliaCustomSearchResults);

import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import dataFetcher from "../../parts/chore/dataFetcher";
import { searchTeams, clearSearch } from '../../search/searchActions';

import TeamsListPage from './TeamsListPage';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  platformAlias: state.platform.platform.alias,
  teams: state.search.teams.results,
  pagination: state.search.teams.pagination,
  filters: state.search.teams.filters,
  search: state.search.teams.search,
  orderBy: state.search.teams.orderBy,
  locale: state.session.language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchTeams,
  clearSearch
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(TeamsListPage);

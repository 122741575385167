import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import dataFetcher from "../../parts/chore/dataFetcher";
import { clearMember } from '../../member/memberActions';

import MembersPage from './MembersPage';

const mapStateToProps = state => ({
  member: state.member.member,
  platformId: state.platform.platform.id,
  platformAlias: state.platform.platform.alias,
  locale: state.session.language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearMember
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(MembersPage);

import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { checkAuthorization } from '../../common/authorizationUtils';

class Can extends React.Component {
  /* ---->
  This component is used to display or not some elements, or to redirect according to the conditions provided.
  The conditions are based on permissions, roles, custom rules and the identity of the user regarding the information to display.
  As long the state.authorized is NULL or FALSE, the children will not be displayed.
  If the state.authorized is FALSE and props.redirect contain a path, the page will be redirected.
  More details are commented in the checkAuthorization() function '../../common/authorizationUtils'.

  INTEGRATION EXAMPLE :
  <Can
    rules={[["permissions.campagain.campaign.get", "permissions.campagain.campaign.update"],[["roles.platform.OWNER"],[["roles.platform.SUPERADMIN"]]]]}
    me={true || false}
    redirect="/en/d"
    * organizationId={id}
    * plaformId={id}
    * campaignId={id}
    * teamId={id}
    * participantMemberId={id}
    * memberId={id}
    * userId={id}
  (>{children}</Can> || />) **

   * : Those id's set in the component tag have priority
       over id's set in mapStateToProps of the Can container.
       To use only to force specific id not loaded.
  ** : Can be closed without children.
  <---- */

  constructor(props) {
    super(props);
    this.counter = 0;

    this.checkAuthorization = props.dispatch
      ? checkAuthorization.bind(props.dispatch)
      : props.checkAuthorization;

    this.state = {
      authorized: null
    };


    Promise.all([
      this.checkAuthorization(this.getIds(props), props.rules, props.me)
    ])
      .then(auth => {
        if (auth[0] === true || auth[0] === false)
          this.updateAutorization(auth[0]);
      });
  }

  componentDidUpdate(prevProps) {
    const
      { props, state, updateAutorization } = this,
      {
        permissions, roles, rules, me,
        _organizationId, _platformId, _campaignId, _teamId, _participantMemberId, _memberId, _userId,
         organizationId,  platformId,  campaignId,  teamId,  participantMemberId,  memberId,  userId,
        isFetchMemberCampaignPermissionsInProgress,
        isFetchMemberCampaignRolesInProgress,
        isFetchMemberOrganizationPermissionsInProgress,
        isFetchMemberOrganizationRolesInProgress,
        isFetchMemberPermissionsInProgress,
        isFetchMemberPlatformPermissionsInProgress,
        isFetchMemberPlatformRolesInProgress,
        isFetchMemberRolesInProgress,
        isFetchMemberTeamPermissionsInProgress,
        isFetchMemberTeamRolesInProgress
      } = props,
      { authorized } = state;

    let checkFunction = false;
    if((""+rules).startsWith("function () {")) {
      checkFunction = true;
    } else {
      rules.map(rule => {
        if ((""+rule).startsWith("function () {")) {
          checkFunction = true;
        }
      });
    }

    // Check updated data
    if (
      (authorized !== true && authorized !== false || checkFunction) && (
        rules.toString() !== prevProps.rules.toString() ||
        !isEqual(permissions, prevProps.permissions) ||
        !isEqual(roles, prevProps.roles) ||
        organizationId !== prevProps.organizationId ||
        _organizationId !== prevProps._organizationId ||
        platformId !== prevProps.platformId ||
        _platformId !== prevProps._platformId ||
        campaignId !== prevProps.campaignId ||
        _campaignId !== prevProps._campaignId ||
        teamId !== prevProps.teamId ||
        _teamId !== prevProps._teamId ||
        participantMemberId !== prevProps.participantMemberId ||
        _participantMemberId !== prevProps._participantMemberId ||
        memberId !== prevProps.memberId ||
        _memberId !== prevProps._memberId ||
        userId !== prevProps.userId ||
        _userId !== prevProps._userId ||
        isFetchMemberCampaignPermissionsInProgress === false && prevProps.isFetchMemberCampaignPermissionsInProgress === true ||
        isFetchMemberCampaignRolesInProgress === false && prevProps.isFetchMemberCampaignRolesInProgress === true ||
        isFetchMemberOrganizationPermissionsInProgress === false && prevProps.isFetchMemberOrganizationPermissionsInProgress === true ||
        isFetchMemberOrganizationRolesInProgress === false && prevProps.isFetchMemberOrganizationRolesInProgress === true ||
        isFetchMemberPermissionsInProgress === false && prevProps.isFetchMemberPermissionsInProgress === true ||
        isFetchMemberPlatformPermissionsInProgress === false && prevProps.isFetchMemberPlatformPermissionsInProgress === true ||
        isFetchMemberPlatformRolesInProgress === false && prevProps.isFetchMemberPlatformRolesInProgress === true ||
        isFetchMemberRolesInProgress === false && prevProps.isFetchMemberRolesInProgress === true ||
        isFetchMemberTeamPermissionsInProgress === false && prevProps.isFetchMemberTeamPermissionsInProgress === true ||
        isFetchMemberTeamRolesInProgress === false && prevProps.isFetchMemberTeamRolesInProgress === true
      ) ||
      !isEqual(permissions, prevProps.permissions) ||
      !isEqual(roles, prevProps.roles)
    ) {
      Promise.all([
        this.checkAuthorization(this.getIds(props), rules, me)
      ])
        .then(auth => {
          updateAutorization(auth[0]);
        });
    }
  }

  // --> Clear data on Unmount not ready yet
  // componentWillUnmount() {
  //   const
  //     { props } = this,
  //     { clearMemberRoles, clearMemberPermissions } = props;
  //
  //   clearMemberRoles();
  //   clearMemberPermissions();
  // }

  updateAutorization = (_authorized, updateState = true) => {
    const
      { props, state } = this,
      { history, redirect } = props,
      { authorized } = state;

    if (
      redirect && (
        authorized === false ||
        _authorized === false
      )
    ) {
      history.replace(redirect);
    } else
    if (
      updateState &&
      authorized !== _authorized &&
      typeof _authorized === 'boolean' &&
      _authorized !== null
    ) {
      this.setState({
        authorized: _authorized
      });
    }
  }

  getIds = props => {
    return {
      organization: props.organizationId || props._organizationId,
      platform: props.platformId || props._platformId,
      campaign: props.campaignId || props._campaignId,
      team: props.teamId || props._teamId,
      member: props.memberId || props._participantMemberId || props._memberId || null,
      user: props.userId || props._userId
    };
  }

  render() {
    const
      { props, state } = this,
      { children } = props,
      { authorized } = state;

    return (
      <React.Fragment>
        {authorized === true && children}
      </React.Fragment>
    );
  }
}

Can.propTypes = {
  rules: PropTypes.array,
  redirect: PropTypes.string
};

Can.defaultProps = {
  children: null,
  dispatch: null,
  rules: [],
  me: null,
  organizationId: null,
  platformId: null,
  campaignId: null,
  teamId: null,
  participantMemberId: null,
  memberId: null,
  userId: null,
  // --> IDs from the mapStateToProps
  _organizationId: null,
  _platformId: null,
  _campaignId: null,
  _teamId: null,
  _participantMemberId: null,
  _memberId: null,
  _userId: null
  // <--
}

export default Can;

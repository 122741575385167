import React from 'react';
import { Form, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { pickBy, debounce, get } from 'lodash';

import GoalField from './fields/GoalField';
import NameField from 'Parts/ui/components/fields/NameField';
import TypeField from './fields/TypeField';
import IndividualParticipantField from './fields/IndividualParticipantField';
import SubmitButton from 'Parts/common/SubmitButton';
import { CAMPAIGN_TEMPLATE_TEAMS } from '../campaignUtils';
import { CAMPAIGN_CREATE_WIZARD_CONFIRMATION } from '../../ui/uiUtils';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import CampaignTypeDiagram from './CampaignTypeDiagram';
import Plugin from '../../plugin/Plugin';
import { isPluginActive } from '../../plugin/pluginActions';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import Can from 'Parts/session/components/CanContainer';

import CreateInformationForm_en from './locales/CreateInformationForm_en.json';
import CreateInformationForm_fr from './locales/CreateInformationForm_fr.json';

import './CreateInformationForm.less';

class CreateInformationForm extends React.Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
    this.diagramRef = React.createRef();

    this.state = {
      canDraft: props.storedInformation &&
        props.storedInformation.goal &&
        props.storedInformation.name &&
        props.storedInformation.template
          ? true
          : false,
      lastAction: null,
      isLoggedIn: props.userTypeId === 1
        ? false
        : true
    };

    i18next.addResourceBundle('en', 'CreateInformationForm', CreateInformationForm_en);
    i18next.addResourceBundle('fr', 'CreateInformationForm', CreateInformationForm_fr);

    this.emitChangeDebounced = debounce(this.emitChange, 250);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.locale !== prevProps.locale) {
      this.props.form.validateFields({ force: true });
    }

    // If user logs in
    if (this.props.userTypeId !== prevProps.userTypeId) {
      this.setState({ isLoggedIn: this.props.userTypeId === 2 ? true : false });
    }

    // After user logs in - trigger submit dynamically
    if (this.state.isLoggedIn !== prevState.isLoggedIn && this.state.isLoggedIn) {
      if (this.state.lastAction === 'draft') {
        document.querySelector('.btn-draft').click();
      }
    }
  }

  handleCancelClick = e => {
    e.preventDefault();
    this.props.history.push(`/${this.props.locale}`);
  };

  handleGuestDraft = e => {
    e.preventDefault();
    const { t } = this.props;
    const customAlert = {
      title: t('notification-not-logged-in-title'),
      description: t('notification-not-logged-in-description')
    };

    this.setState({ lastAction: 'draft' });

    this.props.openLoginModal(false, customAlert);
  };

  handleSaveDraft = e => {
    e.preventDefault();

    const { form, storedInformation } = this.props;
    const { isFieldTouched } = form;
    this.props.form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        const updatedValues = pickBy(values, (value, key) => isFieldTouched(key));

        const draftValues = {
          draft: true,
          ...storedInformation,
          ...updatedValues
        };

        this.props.createCampaign(draftValues, this.props.platform.id).then(result => {
          if (!result.error) {
            this.props.clearCampaign();
            this.props.deactivateCreationMode();
            this.props.history.push(`/${this.props.locale}/d/campaigns/${result.payload.id}`);
          }
        });
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { locale, t } = this.props;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    this.props.form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        this.props.storeCampaignInformation(values);
        this.props.history.push(`/${locale}/${t('URL:create-a-campaign/description')}`);
      }
    });
  };

  handleLearnMore = e => {
    e.preventDefault();
    this.modalRef.current.getWrappedInstance('FundkyModal').showModal();
  };

  handleOpenModal = () => {
    if (this.diagramRef.current)
      this.diagramRef.current.getWrappedInstance('CampaignTypeDiagram').displayLevels();
  };

  handleCloseModal = () => {
    if (this.diagramRef.current)
      this.diagramRef.current.getWrappedInstance('CampaignTypeDiagram').resetLevels();
  };

  handleOnChange = value => {
    this.emitChangeDebounced((value && value.target) || value);
  };

  emitChange = target => {
    const value = (target && target.value) || target;
    if (value) {
      const errors = (target && target.id && this.props.form.getFieldError(target.id)) || null;
      const template = this.props.form.getFieldValue('template');
      this.setState({
        canDraft: !errors && template ? true : false
      });
    }
  };

  render() {
    const {
        props,
        state,
        handleSubmit,
        handleOpenModal,
        handleCloseModal,
        handleOnChange,
        handleLearnMore,
        handleCancelClick,
        handleGuestDraft,
        handleSaveDraft
      } = this,
      { t, form, locale, storedInformation, platform } = props,
      { isLoggedIn, canDraft } = state;

    return (
      <div className="FundkyWrap">
        <Form className="CreateInformationForm" onSubmit={handleSubmit}>
          <h2>{t('about-campaign')}</h2>
          <p>{t('configure-your-campaign')}</p>
          <GoalField
            className="CreateInformationForm__Field CreateInformationForm__GoalField CreateInformationForm__Field--Short"
            locale={locale}
            form={form}
            initialValue={(storedInformation && storedInformation.goal) || null}
            onChange={handleOnChange}
          />
          <NameField
            className="CreateInformationForm__Field CreateInformationForm__NameField"
            maxlength={75}
            counter={true}
            form={form}
            fieldId="name"
            required={true}
            placeholder={true}
            label={false}
            identity="campaign"
            initialValue={(storedInformation && storedInformation.name) || null}
            onChange={handleOnChange}
          />

          <Can
            rules={[
              ['roles.platform.OWNER'],
              ['roles.platform.SUPERADMIN']
            ]}
          >
          {isPluginActive(platform, 'Event') && (
            <Plugin
              name="Event"
              component="CampaignCreationBehaviorField"
              // resolve={() =>
              //   import(/* webpackChunkName: "Event" */ '../../plugin/plugins/Event/Event')
              // }
              alignment="left"
              size="2x"
              margin="0px 0px 24px 0"
              {...{
                // Props
                form: form,
                initialValue: (storedInformation && storedInformation.campaign_behavior) || null
              }}
            />
          )}
          </Can>

          <p>
            {t('people-register')} <a onClick={handleLearnMore}>{t('learn-more')}</a>
          </p>
          <FundkyModal
            ref={this.modalRef}
            className="CampaignTypeDiagramModal"
            displayModal={false}
            displayActions={false}
            openAction={handleOpenModal}
            cancelAction={handleCloseModal}
          >
            <CampaignTypeDiagram ref={this.diagramRef} />
          </FundkyModal>
          <TypeField
            className="CreateInformationForm__Field CreateInformationForm__Field--NoFeedbackIcon"
            form={form}
            initialValue={(storedInformation && storedInformation.template) || null}
            onChange={handleOnChange}
            isLoggedIn={isLoggedIn}
          />
          <Collapse isOpened={form.getFieldValue('template') === CAMPAIGN_TEMPLATE_TEAMS}>
            <IndividualParticipantField
              className="CreateInformationForm__Field CreateInformationForm__CompactField"
              form={form}
              initialValue={(storedInformation && storedInformation.individualParticipant) || null}
            />
          </Collapse>
          <div className="CreatePersonalizationForm__SubmitWrapper SubmitWrapper">
            <SubmitButton>{t('next')}</SubmitButton>
            <FundkyButton
              className="btn-draft"
              type="secondary"
              size="large"
              text={
                this.props.form.getFieldValue('campaign_behavior')
                  ? t('save-draft-tickets')
                  : t('save-draft')
              }
              action={!isLoggedIn ? { func: handleGuestDraft } : { func: handleSaveDraft }}
              disabled={!canDraft}
            />
          </div>
          <div className="CreateInformationForm__CancelWrapper">
            <Popconfirm
              placement="topLeft"
              getPopupContainer={() => document.getElementById('popContainer')}
              title={t('CampaignCreatePage:cancelText')}
              onConfirm={handleCancelClick}
              okText={t('CampaignCreatePage:yes')}
              cancelText={t('CampaignCreatePage:no')}
            >
              <span id="popContainer">{t('cancel')}</span>
            </Popconfirm>
          </div>
        </Form>
      </div>
    );
  }
}

CreateInformationForm.propTypes = {
  form: PropTypes.object.isRequired,
  storeCampaignInformation: PropTypes.func.isRequired
};

export default translate('CreateInformationForm')(CreateInformationForm);

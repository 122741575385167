import React from 'react';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import SocialMediasField_en from './locales/SocialMediasField_en.json';
import SocialMediasField_fr from './locales/SocialMediasField_fr.json';

import { Form } from 'antd';
import FundkyTooltip from 'Parts/ui/components/FundkyTooltip';

class SocialMediasField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'SocialMediasField', SocialMediasField_en);
    i18next.addResourceBundle('fr', 'SocialMediasField', SocialMediasField_fr);
  }

  render() {
    const { t } = this.props;

    return (
      <Form.Item
        colon={false}
        label={
          <span>
            {t('Your-socials')}
            <FundkyTooltip title={t('ttSocials')} />
          </span>
        }
      >
        {this.props.inputs}
      </Form.Item>
    );
  }
}

export default translate('SocialMediasField')(SocialMediasField);

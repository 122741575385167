import { replace, goBack } from 'react-router-redux';
import i18next from 'i18next';
import { get } from 'lodash';

import {
  CONNECTION_MODAL_REGISTER,
  CONNECTION_MODAL_RECOVERY,
  CONNECTION_MODAL_RESET,
  CONNECTION_MODAL_LOGIN,
  CONNECTION_MODAL_REGISTRATION_CONFIRMATION,
  CONNECTION_MODAL_CLOSED,
  CREATION_MODE_ON,
  CREATION_MODE_OFF,
} from './uiUtils';
import { AUTH_LOGIN_CANCEL } from '../session/sessionActions';

export const UI_CONNECTION_MODAL_STATE = 'UI_CONNECTION_MODAL_STATE';

export function forceLoginModal(origin, kickedOut = false) {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge.classList && !badge.classList.contains("show")) {
    badge.classList.add("show");
  }
  return {
    type: UI_CONNECTION_MODAL_STATE,
    payload: {
      state: CONNECTION_MODAL_LOGIN,
      forced: true,
      kickedOut: kickedOut,
      origin
    }
  };
}

export function openLoginModal(forced = false, customAlert = null, from = null) {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge.classList && !badge.classList.contains("show")) {
    badge.classList.add("show");
  }
  return {
    type: UI_CONNECTION_MODAL_STATE,
    payload: {
      state: CONNECTION_MODAL_LOGIN,
      forced: forced,
      customAlert: customAlert,
      from: from
    }
  };
}

export function openRegisterModal(forced = false) {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge.classList && !badge.classList.contains("show")) {
    badge.classList.add("show");
  }
  return {
    type: UI_CONNECTION_MODAL_STATE,
    payload: {
      state: CONNECTION_MODAL_REGISTER,
      forced: forced
    }
  };
}

export function openRecoveryModal(forced = false) {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge.classList && badge.classList.contains("show")) {
    badge.classList.remove("show");
  }
  return {
    type: UI_CONNECTION_MODAL_STATE,
    payload: {
      state: CONNECTION_MODAL_RECOVERY,
      forced: forced
    }
  };
}

export function openResetModal(forced = false) {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge.classList && badge.classList.contains("show")) {
    badge.classList.remove("show");
  }
  return {
    type: UI_CONNECTION_MODAL_STATE,
    payload: {
      state: CONNECTION_MODAL_RESET,
      forced: forced
    }
  };
}

export function openConfirmationModal(forced = false) {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  if (badge && badge.classList && badge.classList.contains("show")) {
    badge.classList.remove("show");
  }
  return {
    type: UI_CONNECTION_MODAL_STATE,
    payload: {
      state: CONNECTION_MODAL_REGISTRATION_CONFIRMATION,
      forced: forced
    }
  };
}

export function closeConnectionModal(forced, currentLocation, lastLocation) {
  const badge = document.getElementsByClassName("grecaptcha-badge")[0];
  badge.classList.remove("show");
  return dispatch => {
    const
      lng = get(i18next, 'language', 'en'),
      URL = get(i18next, `options.resources[${lng}].URL`, {}),
      l = `/${lng}/`;

    if (currentLocation && currentLocation.pathname.indexOf(`/${URL['give/payment']}`) > -1 && badge && badge.classList) {
      badge.classList.add("show");
    }

    if (forced) {
      if (
        currentLocation && currentLocation.pathname &&
        lastLocation && lastLocation.pathname &&
        lastLocation.pathname.indexOf(`${l}${URL['dashboard']}/`) < 0 &&
        lastLocation.pathname !== currentLocation.pathname
      ) {
        dispatch(goBack());
      } else {
        dispatch(replace(`/${lng}`));
      }
    } else {
      // Exception for direct link to connexion modal.
      if(
        !lastLocation &&
        (
          currentLocation &&
          (
            currentLocation.pathname.indexOf(`${l}${URL['login']}`) > -1 ||
            currentLocation.pathname.indexOf(`${l}${URL['create-an-account']}`) > -1 ||
            currentLocation.pathname.indexOf(`${l}${URL['account-recovery']}`) > -1 ||
            currentLocation.pathname.indexOf(`${l}${URL['reset-password']}`) > -1 ||
            currentLocation.pathname.indexOf(`${l}${URL['registration-confirm']}`) > -1
          )
        )
      ) {
        dispatch(replace(`/${lng}`));
      }
    }

    dispatch({ type: AUTH_LOGIN_CANCEL});
    dispatch({
      type: UI_CONNECTION_MODAL_STATE,
      payload: {
        state: CONNECTION_MODAL_CLOSED,
        forced: false,
        kickedOut: false
      }
    });
  };
}

export function forceCloseConnectionModal() {
  return dispatch => {
    dispatch({ type: AUTH_LOGIN_CANCEL});
    dispatch({
      type: UI_CONNECTION_MODAL_STATE,
      payload: {
        state: CONNECTION_MODAL_CLOSED,
        forced: false,
        kickedOut: false
      }
    });
  };
}

export function activateCreationMode() {
  return dispatch => {
    return dispatch({ type: CREATION_MODE_ON });
  };
}

export function deactivateCreationMode() {
  return dispatch => {
    return dispatch({ type: CREATION_MODE_OFF });
  };
}

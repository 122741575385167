import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";

import { getLastCampaigns } from "../../campaign/campaignSelectors";
import { fetchPlatformContent } from '../../platform/platformActions';

import PublicFooter from './PublicFooter';

const mapStateToProps = state => ({
  lastCampaigns: getLastCampaigns(state),
  locale: state.session.language,
  organization: state.organization.organization,
  platform: state.platform.platform,
  content: state.platform.content,
  isBillingConfigured: get(state, 'organization.isBillingConfigured'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPlatformContent
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PublicFooter);

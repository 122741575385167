import React from 'react';
import { Form } from 'antd';
import { pickBy, get, isEmpty } from 'lodash';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import TextField from 'Parts/ui/components/fields/TextField';
import CheckboxField from 'Parts/ui/components/fields/CheckboxField';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';
import SelectField from 'Parts/ui/components/fields/SelectField';
import FakeField from 'Parts/ui/components/fields/FakeField';

import PlatformSettingsForm_en from './locales/PlatformSettingsForm_en.json';
import PlatformSettingsForm_fr from './locales/PlatformSettingsForm_fr.json';

import './PlatformSettingsForm.less';

class PlatformSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'PlatformSettingsForm', PlatformSettingsForm_en);
    i18next.addResourceBundle('fr', 'PlatformSettingsForm', PlatformSettingsForm_fr);
  }

  handleSubmit = event => {
    event.preventDefault();

    const { props } = this,
      { t, form, platform, updatePlatformSettings } = props,
      { validateFields, isFieldTouched } = form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        // Delete unchanged values
        if (
          updatedValues &&
          updatedValues['communicationName'] &&
          platform &&
          platform.settings &&
          platform.settings.communication &&
          platform.settings.communication.referent &&
          updatedValues['communicationName'] === platform.settings.communication.referent
        ) {
          delete updatedValues['communicationName'];
        }

        const indexation = updatedValues.hasOwnProperty('platformIndexation')
          ? {
              indexation: !updatedValues.platformIndexation
            }
          : null;

        const referent = updatedValues && updatedValues['communicationName']
          ? {
              communication: {
                referent: updatedValues['communicationName']
              }
            }
          : null;

        const tracking = updatedValues && updatedValues['platformGTM'] ||
          updatedValues && !updatedValues['platformGTM'] && get(this.props, 'platform.settings.tracking.gtm', null)
            ? {
                tracking: {
                  gtm: updatedValues['platformGTM'] || null
                }
              }
            : null

        // Assign values
        let values = {};
        if(indexation || referent || tracking) {
          values = {
            ...indexation,
            ...referent,
            ...tracking
          }
        }

        // Assign messages
        const messages = {
          success: {
            message: t('success.message'),
            description: t('success.description')
          },
          error: {
            message: t('error.message')
          }
        };

        // Send to API if object not empty
        if (!isEmpty(values)) {
          updatePlatformSettings(platform.id, values, messages);
        }
      }
    });
  };

  handleCancel = e => {
    this.props.form.resetFields();
  };

  render() {
    const
      { props, handleSubmit, handleCancel } = this,
      { t, form, platform, organization } = props;

    const platformIndexation = get(platform, 'settings.indexation', null);
    const platformGTM = get(platform, 'settings.tracking.gtm', null);
    const referent = get(platform, 'settings.communication.referent', null);

    const communicationsOptions = [
      { value: 'platform', text: get(props, 'platform.name', '') },
      { value: 'organization', text: get(props, 'organization.name', '') }
    ];

    return (
      <Form className="PlatformSettingsForm" onSubmit={handleSubmit}>
        <div className="inputs">
          {/* <TextField
            form={form}
            fieldId={'platformName'}
            className="PlatformName__Field"
            initialValue={null}
            label={t('platformName-label')}
            tooltip={t('platformName-tooltip')}
          />
          <TextField
            form={form}
            fieldId={'platformAddress'}
            className="PlatformAddress__Field"
            initialValue={null}
            label={t('platformAddress-label')}
            tooltip={t('platformAddress-tooltip')}
          /> */}
          <CheckboxField
            form={form}
            fieldId={'platformIndexation'}
            className="PlatformIndexation__Field"
            initialValue={platformIndexation == '0' ? true : false}
            checkboxText={t('platformIndexation-checkbox-label')}
            label={t('platformIndexation-label')}
            tooltip={t('platformIndexation-tooltip')}
            notice={<em>{t('platformIndexation-notice')}</em>}
          />
          <SelectField
            form={this.props.form}
            className="PlatformCommunicationName__Field"
            label={t('communications-name-label')}
            fieldId={'communicationName'}
            optionsArray={communicationsOptions}
            initialValue={referent || 'platform'}
            notice={<em>{t('communications-name-notice')}</em>}
          />
          <TextField
            form={form}
            fieldId={'platformGTM'}
            className="PlatformGTM__Field"
            initialValue={platformGTM}
            label={t('platformGTM-label')}
            placeholder="ex.: GTM-XXXXXX"
            fieldOptions={{
              rules: [
                {
                  pattern: /^GTM-[A-Z0-9]{5,10}$/,
                  message: t('platformGTM-invalid')
                }
              ]
            }}
            uppercase
          />
        </div>

        <CancelAndSaveButtons onClickCancel={handleCancel} />
      </Form>
    );
  }
}

// PlatformSettingsForm.propTypes = {
//   form: PropTypes.object.isRequired,
// };

export default translate('PlatformSettingsForm')(PlatformSettingsForm);

import React from 'react';
import PropTypes from 'prop-types';
import HeadMetas from '../../common/HeadMetasContainer';
import { Layout, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';

import {
  TEAM_CREATE_WIZARD_CAMPAIGN,
  TEAM_CREATE_WIZARD_INFORMATION,
  TEAM_CREATE_WIZARD_CONFIRMATION
} from '../../ui/uiUtils';

import SelectCampaignPanel from '../components/SelectCampaignPanelContainer';
import CreateInformationForm from '../components/CreateInformationFormContainer';
import CreateConfirmationPanel from '../components/CreateConfirmationPanelContainer';

import TeamCreatePage_en from './locales/TeamCreatePage_en.json';
import TeamCreatePage_fr from './locales/TeamCreatePage_fr.json';

import './TeamCreatePage.less';

const { TabPane } = Tabs;

class TeamCreatePage extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'TeamCreatePage', TeamCreatePage_en);
    i18next.addResourceBundle('fr', 'TeamCreatePage', TeamCreatePage_fr);

    this.state = {
      disableCampaignTab: !!(props.location.state && props.location.state.campaign),
      preSelectedTeam: props.location.state && props.location.state.team
    }
  }

  componentDidMount() {
    this.props.activateCreationMode();
    
    const { state } = this.props.location;
    if (state && state.campaign) {
      this.props.selectCampaign(state.campaign);
      this.props.history.replace({ ...this.props.location, state: {} });
    }
  }

  componentWillUnmount() {
    const { clearSelectedCampaign, deactivateCreationMode } = this.props;
    clearSelectedCampaign();
    deactivateCreationMode();
  }

  render() {
    const { t } = this.props;
    let currentStep;
    if (this.props.currentStep === TEAM_CREATE_WIZARD_CAMPAIGN && this.state.disableCampaignTab) {
      currentStep = TEAM_CREATE_WIZARD_INFORMATION;
    } else {
      currentStep = this.props.currentStep;
    }

    return (
      <Layout className="TeamCreatePage">
        <HeadMetas />
        <div className="TeamCreatePage__TitleDivider FundkyWrap">
          <span className="fa-layers TeamCreatePage__TitleIcon">
            <FontAwesomeIcon icon={["fal", "users"]} transform="left-1"  />
            <FontAwesomeIcon icon={["far", "plus"]} transform="shrink-10 right-12.5 up-.5" />
          </span>
          <h1>{t('title')}</h1>
        </div>
        <Tabs className="FillActive" activeKey={currentStep} onTabClick={key => this.props.changeTeamCreateWizardStep(key)}>
          <TabPane
            tab={<span>{t('tab1')} {currentStep !== TEAM_CREATE_WIZARD_CAMPAIGN && <FontAwesomeIcon icon={["fas", "check"]} />}</span>}
            disabled={this.state.disableCampaignTab || currentStep === TEAM_CREATE_WIZARD_CONFIRMATION}
            key={TEAM_CREATE_WIZARD_CAMPAIGN}
          >
            <SelectCampaignPanel />
          </TabPane>
          <TabPane
            tab={<span>{t('tab2')} {currentStep === TEAM_CREATE_WIZARD_CONFIRMATION && <FontAwesomeIcon icon={["fas", "check"]} />}</span>}
            disabled={currentStep !== TEAM_CREATE_WIZARD_INFORMATION}
            key={TEAM_CREATE_WIZARD_INFORMATION}
          >
            <CreateInformationForm disableCampaignTab={this.state.disableCampaignTab} />
          </TabPane>
          <TabPane
            tab={<span>{t('tab3')}</span>}
            disabled={currentStep !== TEAM_CREATE_WIZARD_CONFIRMATION}
            key={TEAM_CREATE_WIZARD_CONFIRMATION}
          >
            <CreateConfirmationPanel />
          </TabPane>
        </Tabs>
      </Layout>
    );
  }

}

TeamCreatePage.propTypes = {
  currentStep: PropTypes.oneOf([
    TEAM_CREATE_WIZARD_CAMPAIGN,
    TEAM_CREATE_WIZARD_INFORMATION,
    TEAM_CREATE_WIZARD_CONFIRMATION
  ]).isRequired,
  changeTeamCreateWizardStep: PropTypes.func.isRequired,
  selectCampaign: PropTypes.func.isRequired
};

export default translate('TeamCreatePage')(TeamCreatePage);

import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Button, Alert } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

import SearchBar from 'Parts/search/SearchBarContainer';
import PaginationTop from 'Parts/search/PaginationTop';
import ResultTable from 'Parts/search/ResultTable';
import Can from 'Parts/session/components/CanContainer';

import { DEFAULT_PAGER } from 'Parts/search/searchUtils';
import { simpleColumn, dateColumn, actionColumn } from 'Parts/common/columnUtils';
import { moneyFormat } from 'Parts/common/moneyFormat';

import transactionsSettlementPageEN from './locales/TransactionsSettlementPage_en.json';
import transactionsSettlementPageFR from './locales/TransactionsSettlementPage_fr.json';

import './TransactionsSettlementPage.less';
class TransactionsSettlementPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pager: {
        ...DEFAULT_PAGER,
        total: props.pagination.totalResults || 0,
        current: props.pagination.pageNumber || 1,
        pageSize: props.pagination.pageResults || 10
      }
    };

    this.isStripe = get(props, 'billing.stripe.chargesEnabled', false); //get(props, 'platform.actions.canReceiveSubscriptions', false);

    i18next.addResourceBundle('en', 'TransactionsSettlementPage', transactionsSettlementPageEN);
    i18next.addResourceBundle('fr', 'TransactionsSettlementPage', transactionsSettlementPageFR);
  }

  componentDidUpdate(prevProps) {
    // Update the pager with the new total
    let pager;

    if (prevProps.pagination.pageNumber !== this.props.pagination.pageNumber) {
      pager = {
        ...this.state.pager,
        total: this.props.pagination.totalResults || 0,
        current: this.props.pagination.pageNumber || 1,
        pageSize: this.props.pagination.pageResults || 10
      };

      this.setState({ pager });
    }
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handlePaginationChange = pager => {
    this.setState({ pager });
    const pagination = {
      pageResults: pager.pageSize,
      pageNumber: pager.current
    };
    this.props.searchSettlementReports(
      this.props.organizationId,
      this.props.search,
      this.props.filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    );
  };

  handleSearch = filters => {
    const pagination = {
      pageResults: this.state.pager.pageSize
    };
    this.props.searchSettlementReports(
      this.props.organizationId,
      filters.search,
      filters,
      pagination,
      { orderBy: 'addedOn[desc]' },
      true
    )
      .then(result => {
        let pager;
        const { totalResults, pageNumber, pageResults } = result.payload.pagination;

        pager = {
          ...this.state.pager,
          total: totalResults,
          current: pageNumber,
          pageSize: pageResults
        };

        this.setState({ pager });
      });
  };

  handleDownloadReport = report => {
    this.props
      .downloadSettlementReport(this.props.organizationId, report.withdrawalId)
      .then(result => {
        const { t } = this.props;
        let data = new Blob([result], { type: 'text/csv;charset=utf-8;' });
        let url = URL.createObjectURL(data);
        let downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = `${t('settlement-report-filename')}_${report.withdrawalId}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  };

  render() {
    const { t, locale, pagination, settlementReports } = this.props;
    const { pager } = this.state;

    // Add id to remove the key error
    const formatedSettlementReports = settlementReports.map(report => {
      let o = Object.assign({}, report);
      o.id = report.withdrawalId;
      return o;
    });

    const searchBarContent = <p>{t('settlement-help')}</p>;

    const stripeMessage = this.isStripe ? (
      <Alert
        message={t('Stripe')}
        description={(
          <span>
            {t('stripe-message')}
            <a href="https://dashboard.stripe.com/login" target="_blank">{t('Stripe account')}</a>.
          </span>
        )}
        type="info"
        showIcon
      />
    ) : null;

    return (
      <Layout className="TransactionsSettlementPage">
        <Helmet>
          <title>{t('settlements-title')}</title>
        </Helmet>
        <SearchBar
          title={t('settlements-title')}
          placeholder={t('search')}
          onSearch={this.handleSearch}
          extraContent={searchBarContent}
        />
        <Can rules={['permissions.platform.transaction.get']} redirect={`/${locale}/d`}>
          <div className="AdminList">
            {stripeMessage}
            <PaginationTop
              totalLabel={total => t('reportsTotal', { count: pagination.totalResults })}
              pager={pager}
              onChange={this.handlePaginationChange}
            />
            <ResultTable
              dataSource={formatedSettlementReports}
              pagination={pager}
              onChange={this.handlePaginationChange}
              showHeader={true}
              scroll={{ x: true }}
              columns={[
                simpleColumn(t('settlement-id'), 'withdrawalId', null, true),
                simpleColumn(t('amount'), 'amount', text => moneyFormat(text, locale), true),
                simpleColumn(t('currency'), 'currency', null, true),
                simpleColumn(t('status'), null, settlementReport => {
                  return t(settlementReport.status);
                }),
                dateColumn(t('date'), 'addedOn', null, null, false, locale),
                simpleColumn(t('download-report'), null, settlementReport => {
                  return (
                    <div className="buttons">
                      <Button
                        shape="circle"
                        onClick={() => {
                          this.handleDownloadReport(settlementReport);
                        }}
                        disabled={settlementReport.statusId !== 3}
                      >
                        <FontAwesomeIcon icon={['fal', 'file-download']} size="lg" />
                      </Button>
                    </div>
                  );
                })
              ]}
            />
          </div>
        </Can>
      </Layout>
    );
  }
}

TransactionsSettlementPage.propTypes = {
  searchSettlementReports: PropTypes.func.isRequired,
  settlementReports: PropTypes.array.isRequired
};

TransactionsSettlementPage.defaultProps = {
  settlementReports: [],
  pagination: {
    totalResults: 0
  }
};

export default translate('TransactionsSettlementPage')(TransactionsSettlementPage);

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import dataFetcher from 'Parts/chore/dataFetcher';
import SurveyAttempt from './CreateSurveyAttemptForm';
import { surveysDetailAction, surveyAttemptAction, surveyAttemptStoreAction, surveyAttemptSubmitAction } from '../../donation/components/SurveyAttemptAction';
import { fetchSurveyFields } from '../../dashboard/surveys/SurveyPage/SurveyPageActions';
import { selectCampaign } from "../participantActions";
import { changeParticipantCreateWizardStep } from '../../ui/uiActions';
import { TICKETS_SALE_WIZARD_DETAILS } from '../../plugin/plugins/Event/eventUtils';
import { purchaseTickets } from '../../plugin/plugins/Event/eventActions';
import { createParticipant } from '../participantActions';
import { get } from 'lodash';

const mapStateToProps = (state) => ({
  surveysPageDateils: state.surveyAttempt.surveysPageDateils,
  surveysPageDateilsError: state.surveyAttempt.surveysPageDateilsError,
  surveysAttempt: state.surveyAttempt.surveysAttempt,
  surveysAttemptError: state.surveyAttempt.surveysAttemptError,
  surveysFields: state.surveyPage.surveysFields,
  surveyId: state.donation.surveyId,
  locale: state.session.language,
  user: state.session.user,
  campaign: state.campaign.campaign,
  selectedCampaign: state.participant.selectedCampaign,
  ticketSelection: get(state, 'Event.ticketSelection', null),
  back: TICKETS_SALE_WIZARD_DETAILS,
  storeSurveyAttempt: state.surveyAttempt.storeSurveyAttempt,
  surveySubmitData: state.surveyAttempt.surveySubmitData,
  participantDataSave: state.participant.participantDataSave
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      selectCampaign,
      surveysDetailAction,
      surveyAttemptAction,
      fetchSurveyFields,
      surveyAttemptStoreAction,
      surveyAttemptSubmitAction,
      changeParticipantCreateWizardStep,
      purchaseTickets,
      createParticipant,
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(SurveyAttempt);

import React from 'react';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import './FundkyCTA.less';

class FundkyCTA extends React.Component {
  render() {
    const { icon, iconSize, title, titleTag, description, cta, className } = this.props;
    const defaultClass = 'FundkyCTA';
    
    const iconMarkup = icon ? <FontAwesomeIcon className={`${defaultClass}__Icon`} icon={icon} size={iconSize}/> : null;
    const TitleTagName = titleTag;
    const titleMarkup = <TitleTagName className={`${defaultClass}__Title`}>{title}</TitleTagName>;
    const descriptionMarkup = description ? <p className={`${defaultClass}__Description`}>{description}</p> : null;
    const ctaMarkup = cta ? cta : null;

    return (
      <div className={classnames(defaultClass, className)}>
        {iconMarkup}
        {titleMarkup}
        {descriptionMarkup}
        {ctaMarkup}
      </div>
    );
  }
}

FundkyCTA.defaultProps = {
  icon: null,
  iconSize: '2x',
  title: 'title',
  titleTag: 'h3',
  description: null,
  className: null,
}

FundkyCTA.propTypes = {
  icon: PropTypes.array,
  iconSize: PropTypes.oneOf([ 'xs', 'sm', 'lg', '2x', '3x', '5x', '7x', '10x']),
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  description: PropTypes.string,
  cta: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default FundkyCTA;
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import SelectField from 'Parts/ui/components/fields/SelectField';
import AvatarThumbnail from '../../common/AvatarThumbnailContainer';
import HelpText from '../../common/HelpText';

import {
  DONATION_CREATE_SURVEY,
  DONATION_CREATE_WIZARD_DONATION,
  DONATION_CREATE_WIZARD_INFORMATION,
  DONATION_CREATE_WIZARD_PAYMENT
} from '../../ui/uiUtils';

import ChangeRecipientModalContent_en from './locales/ChangeRecipientModalContent_en.json';
import ChangeRecipientModalContent_fr from './locales/ChangeRecipientModalContent_fr.json';

import './ChangeRecipientModalContent.less';

class ChangeRecipientModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.fetchData(props.entity, props.type);

    i18next.addResourceBundle('en', 'ChangeRecipientModalContent', ChangeRecipientModalContent_en);
    i18next.addResourceBundle('fr', 'ChangeRecipientModalContent', ChangeRecipientModalContent_fr);
  }

  fetchData = (entity, type) => {
    const recipientId = entity.id,
      campaignId = entity.campaignId,
      teamId = entity.teamId;

    switch (type) {
      case 'organization':
        this.props.searchCampaigns(this.props.platform.id);
        this.props.searchParticipants(this.props.platform.id);
        break;
      case 'campaign':
        this.props.searchTeamsInCampaign(recipientId);
        this.props.searchParticipantsInCampaign(recipientId);
        break;
      case 'team':
        this.props.fetchCampaign(campaignId);
        this.props.searchParticipantsInTeam(recipientId);
        break;
      case 'participant':
        this.props.fetchCampaign(campaignId);
        this.props.fetchTeam(teamId);
        break;
    }
  };

  getStep = () => {
    const { t } = this.props;
    switch (this.props.donationCreateWizardState) {
      case DONATION_CREATE_SURVEY:
        return `/${t('URL:give/survey')}`;
      case DONATION_CREATE_WIZARD_INFORMATION:
        return `/${t('URL:give/information')}`;
      case DONATION_CREATE_WIZARD_PAYMENT:
        return `/${t('URL:give/payment')}`;
      case DONATION_CREATE_WIZARD_DONATION:
      default:
        return `/${t('URL:give')}`;
    }
  };

  handleCampaignSelect = value => {
    let recipient = '';
    const step = this.getStep();

    if (typeof value !== 'object') {
      this.props.fetchCampaign(value).then(campaign => {
        this.props.selectCampaign(campaign.payload);
        this.fetchData(campaign.payload, 'campaign');
        recipient = `/${campaign.payload.alias}`;
        this.props.history.push(`/${this.props.locale}${recipient}${step}`);
      });
    } else {
      this.props.selectCampaign(this.props.campaign);
      this.fetchData(this.props.campaign, 'campaign');
      recipient = `/${this.props.campaign.alias}`;
      this.props.history.push(`/${this.props.locale}${recipient}${step}`);
    }

    this.props.handleHideModal();
  };

  handleTeamSelect = value => {
    let recipient = '';
    const step = this.getStep();

    if (typeof value !== 'object') {
      this.props.fetchTeam(value).then(team => {
        this.props.selectTeam(team.payload);
        this.fetchData(team.payload, 'team');
        recipient = `/${team.payload.campaignAlias}/${team.payload.alias}`;
        this.props.history.push(`/${this.props.locale}${recipient}${step}`);
      });
    } else {
      this.props.selectTeam(this.props.team);
      this.fetchData(this.props.team, 'team');
      recipient = `/${this.props.team.campaignAlias}/${this.props.team.alias}`;
      this.props.history.push(`/${this.props.locale}${recipient}${step}`);
    }

    this.props.handleHideModal();
  };

  handleParticipantSelect = value => {
    let recipient = '';
    const step = this.getStep();

    this.props.fetchParticipant(value, false, null, { showMember: true }).then(participant => {
      this.props.selectParticipant(participant.payload);
      this.fetchData(participant.payload, 'participant');
      recipient = `/${participant.payload.campaignAlias}/${participant.payload.teamAlias ||
        this.props.t('URL:individual-participant')}/${participant.payload.member.alias}`;
      this.props.history.push(`/${this.props.locale}${recipient}${step}`);
    });

    this.props.handleHideModal();
  };

  handleSearchNew = event => {
    this.props.history.push(`/${this.props.locale}/${this.props.t('URL:give')}`);
  };

  compareName = (a, b) => {
    return a.name.localeCompare(b.name);
  };

  compareParticipantName = (a, b) => {
    return a.member.name.localeCompare(b.member.name);
  };

  filterOption = (input, option) =>
    option.props.children.props.primaryContent.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  render() {
    const {
      handleCampaignSelect,
      handleTeamSelect,
      handleParticipantSelect,
      handleSearchNew,
      compareName,
      compareParticipantName,
      filterOption,
      props
    } = this;
    const { t, entity, type, participants, team, teams, campaign, campaigns } = props;

    const mappedParticipants =
      participants && participants.results
        ? participants.results.sort(compareParticipantName).map((participant, index) => {
            return {
              value: participant.id,
              text: (
                <AvatarThumbnail entity={participant} primaryContent={participant.member.name} />
              )
            };
          })
        : [];

    const mappedTeams =
      teams && teams.results
        ? teams.results.sort(compareName).map((team, index) => {
            return {
              value: team.id,
              text: <AvatarThumbnail entity={team} primaryContent={team.name} />
            };
          })
        : [];

    const mappedCampaigns =
      campaigns && campaigns.results
        ? campaigns.results.sort(compareName).map((campaign, index) => {
            return {
              value: campaign.id,
              text: <AvatarThumbnail entity={campaign} primaryContent={campaign.name} />
            };
          })
        : [];

    const firstChoice =
      (type === 'organization' || type === 'campaign') && mappedParticipants.length ? (
        <SelectField
          className="ChangeRecipientModalContent--select"
          form={this.props.form}
          fieldId={'participantId'}
          optionsArray={mappedParticipants}
          style={{ width: '100%' }}
          placeholder={t(`participant-${type}-placeholder`)}
          onSelect={handleParticipantSelect}
          showSearch
          filterOption={filterOption}
          dropdownClassName="overModal"
        />
      ) : type === 'team' ? (
        <SelectField
          className="ChangeRecipientModalContent--select"
          form={this.props.form}
          fieldId={'participantId'}
          optionsArray={mappedParticipants}
          style={{ width: '100%' }}
          placeholder={t(`participant-${type}-placeholder`)}
          onSelect={handleParticipantSelect}
          showSearch
          filterOption={filterOption}
          dropdownClassName="overModal"
        />
      ) : type === 'participant' && team.id ? (
        <div className="ChangeRecipientModalContent--clickableAvatar" onClick={handleTeamSelect}>
          <AvatarThumbnail
            className="ChangeRecipientModalContent__AvatarThumbnail"
            entity={team}
            primaryContent={<h1>{team.name}</h1>}
            secondaryContent={<HelpText>{t('team')}</HelpText>}
          />
        </div>
      ) : null;

    const secondChoice =
      type === 'organization' && mappedCampaigns.length ? (
        <SelectField
          className="ChangeRecipientModalContent--select"
          form={this.props.form}
          fieldId={'campaignId'}
          optionsArray={mappedCampaigns}
          style={{ width: '100%' }}
          placeholder={t(`campaign-${type}-placeholder`)}
          onSelect={handleCampaignSelect}
          showSearch
          filterOption={filterOption}
          dropdownClassName="overModal"
        />
      ) : type === 'campaign' && mappedTeams.length ? (
        <SelectField
          className="ChangeRecipientModalContent--select"
          form={this.props.form}
          fieldId={'teamId'}
          optionsArray={mappedTeams}
          style={{ width: '100%' }}
          placeholder={t(`team-${type}-placeholder`)}
          onSelect={handleTeamSelect}
          showSearch
          filterOption={filterOption}
          dropdownClassName="overModal"
        />
      ) : type === 'team' || type === 'participant' ? (
        <div
          className="ChangeRecipientModalContent--clickableAvatar"
          onClick={handleCampaignSelect}
        >
          <AvatarThumbnail
            className="ChangeRecipientModalContent__AvatarThumbnail"
            entity={campaign}
            primaryContent={<h1>{campaign.name}</h1>}
            secondaryContent={<HelpText>{t('campaign')}</HelpText>}
          />
        </div>
      ) : null;

    const or = <span className="ChangeRecipientModalContent__Or">{t('or')}</span>;

    return (
      <React.Fragment>
        <label className="ChangeRecipientModalContent--label">{t('actual-recipient')}</label>
        <AvatarThumbnail
          className="ChangeRecipientModalContent__AvatarThumbnail"
          entity={entity}
          primaryContent={<h1>{entity.name || entity.member.name}</h1>}
          secondaryContent={<HelpText>{t(type)}</HelpText>}
        />
        <label className="ChangeRecipientModalContent--label">{t('new-recipient')}</label>
        {firstChoice}
        {firstChoice && or}
        {secondChoice}
        {secondChoice && or}
        <FundkyButton
          className="ChangeRecipientModalContent__SearchNewButton"
          type="secondary"
          text={t('search-new-recipient')}
          action={{ func: handleSearchNew }}
        />
      </React.Fragment>
    );
  }
}

ChangeRecipientModalContent.defaultProps = {
  entity: {},
  type: '',
  participants: null,
  teams: null,
  campaigns: null,
  team: null,
  campaign: null,
  visible: false
};

ChangeRecipientModalContent.propTypes = {
  entity: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleHideModal: PropTypes.func.isRequired
};

export default translate('ChangeRecipientModalContent')(ChangeRecipientModalContent);

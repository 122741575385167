import { bindActionCreators, compose } from "redux";
import { Form } from 'antd';
import { connect } from "react-redux";
import dataFetcher from "../../../parts/chore/dataFetcher";

import { updateTransaction, requestTransactionUpdate } from "../../../donation/donationActions";
import { checkAuthorization } from 'Parts/common/authorizationUtils';

import TransactionsPageForm from './TransactionsPageForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  transaction: state.donation.transaction,
  organization: state.organization.organization,
  locale: state.session.language,
  provinces: state.geo.provinces,
  userId: state.session.userId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateTransaction,
  requestTransactionUpdate,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create(),
  dataFetcher
)(TransactionsPageForm);

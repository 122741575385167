import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import SocialMediaField_en from './locales/SocialMediaField_en.json';
import SocialMediaField_fr from './locales/SocialMediaField_fr.json';

import { Form, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SocialMediaField.less';

class SocialMediaField extends React.Component {

  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'SocialMediaField', SocialMediaField_en);
    i18next.addResourceBundle('fr', 'SocialMediaField', SocialMediaField_fr);

    this.pattern = /^(https?):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
    this.handleOnChange = this.handleOnChange.bind(this);
    this.updateIconColor = this.updateIconColor.bind(this);

    switch(this.props.className) {
      case "facebook" : this.valideLinkPattern = /(facebook\.com){1}/; break;
      case "instagram" : this.valideLinkPattern = /(instagram\.com){1}/; break;
      case "linkedin" : this.valideLinkPattern = /(linkedin\.com){1}/; break;
      case "pinterest" : this.valideLinkPattern = /(pinterest\.(com|ca)){1}/; break;
      case "twitter" : this.valideLinkPattern = /(twitter\.com){1}/; break;
      default: this.valideLinkPattern = /.*/; break;
    }

    this.state = {
      empty: !props.value || props.value === "" ? " empty" : null
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.value !== prevProps.value) {
      this.setState({
        empty: !this.props.value || this.props.value === "" ? " empty" : null
      });
    }
  }

  handleOnChange(event) {
    if(this.state.empty && event.currentTarget.value.match(this.pattern)) {
      this.setState({
        empty: null
      });
    } else
    if(!this.state.empty && !event.currentTarget.value.match(this.pattern)) {
      this.setState({
        empty: " empty"
      });
    }
  }

  updateIconColor(value) {
    this.setState({
      empty: !value || value === "" ? " empty" : null
    });
  }

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    const input = <Input placeholder={this.props.label} />;
    const fieldId = this.props.className;
    const fieldOptions = {
      rules: [
        {
          pattern: this.pattern,
          message: t('invalid', {social: this.props.className})
        },
        {
          pattern: this.valideLinkPattern,
          message: t('wong-link', {social: this.props.className.charAt(0).toUpperCase() + this.props.className.slice(1)})
        }
      ],
      initialValue: this.props.value,
      onChange: this.handleOnChange
    };

    return (
      <Form.Item
        className={`SocialMediaField SocialMediaField--${this.props.className}${this.state.empty}`}
        hasFeedback
        label={(
            <span className={`${this.props.className}-color`}>
              <FontAwesomeIcon icon={["fab", this.props.icon]} />
            </span>
          )}
        colon={false}
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      </Form.Item>
    );
  }

}

SocialMediaField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default translate('SocialMediaField', { withRef: true })(SocialMediaField);

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import dataFetcher from "../../parts/chore/dataFetcher";

import { searchTeams } from '../searchActions';

import SearchTeamPanel from './SearchTeamPanel';

const mapStateToProps = state => ({
  platformId: state.platform.platform.id,
  teams: state.search.teams.results,
  pagination: state.search.teams.pagination,
  filters: state.search.teams.filters,
  search: state.search.teams.search,
  orderBy: state.search.teams.orderBy,
  locale: state.session.language
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchTeams
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher
)(SearchTeamPanel)

import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { pickBy, get, isEqual } from 'lodash';
import i18next from 'i18next';
import { translate } from 'react-i18next';

import SettingsColumn from '../../components/SettingsColumn';
import LanguageBoxForm from '../../components/LanguageBoxFormContainer';
import Wysiwyg, { wrapText } from 'Parts/ui/components/fields/Wysiwyg';
import CancelAndSaveButtons from '../../components/CancelAndSaveButtons';
import TextField from 'Parts/ui/components/fields/TextField';

import {
  getLanguageNameByKey,
  getLanguageIdByKey,
  getDefaultLanguageKey
} from 'Parts/common/langUtils';

import PagesHomepageIntroTextFormEN from './locales/PagesHomepageIntroTextForm_en.json';
import PagesHomepageIntroTextFormFR from './locales/PagesHomepageIntroTextForm_fr.json';

class PagesHomepageIntroTextForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLang: getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale
    };

    this.changeHasHappened = false;

    this.WysiwygRef = React.createRef();

    this.initialDescription = this.getContent(this.state.contentLang);

    i18next.addResourceBundle('en', 'PagesHomepageIntroTextForm', PagesHomepageIntroTextFormEN);
    i18next.addResourceBundle('fr', 'PagesHomepageIntroTextForm', PagesHomepageIntroTextFormFR);
  }

  handleSubmit = event => {
    event.preventDefault();
    this.changeHasHappened = false;

    const { validateFields, isFieldTouched } = this.props.form;
    // The force option is required because we have dynamic rules
    // See https://github.com/react-component/form/issues/130
    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        if (Object.keys(updatedValues) && Object.keys(updatedValues).length > 0) {
          if (
            updatedValues.home_title !== undefined ||
            updatedValues.home_description !== undefined
          ) {
            const languageId = getLanguageIdByKey(this.state.contentLang);

            let description =
              typeof updatedValues.home_description === 'string'
                ? wrapText(updatedValues.home_description)
                    .replace(/<\/?[^>]+(>|$)/g, '')
                    .trim() === ''
                  ? { description: null }
                  : { description: wrapText(updatedValues.home_description) }
                : null;
            let title =
              typeof updatedValues.home_title === 'string'
                ? updatedValues.home_title === ''
                  ? { title: null }
                  : { title: updatedValues.home_title }
                : null;

            if (
              !description &&
              title &&
              !title.title &&
              wrapText(allValues.home_description)
                .replace(/<\/?[^>]+(>|$)/g, '')
                .trim() === ''
            ) {
              description = { description: null };
            }

            if (!title && description && !description.description && allValues.home_title === '') {
              title = { title: null };
            }

            if (title || description)
              this.props.updatePlatformDescription(this.props.platform.id, [
                {
                  languageId: languageId,
                  ...title,
                  ...description
                }
              ]);
          }
        }
      }
    });
  };

  handleCancel = event => {
    this.changeHasHappened = false;
    this.props.form.resetFields();
    this.WysiwygRef.current.getWrappedInstance('Wysiwyg').resetEditorState();
  };

  handleContentLanguageChange = value => {
    const defaultLangKey = getDefaultLanguageKey(get(this.props, 'languages')) || this.props.locale;
    this.setState(
      {
        contentLang: value,
        disabledUnilangualFields: defaultLangKey != value
      },
      () => (this.changeHasHappened = false)
    );
  };

  checkForChangeTitle = value => {
    if (this.props.form.isFieldTouched('home_title')) this.changeHasHappened = true;
  };

  checkForChangeDesc = value => {
    if (this.props.form.isFieldTouched('home_description')) this.changeHasHappened = true;
  };

  getContent = (lang, type = 'description') => {
    const lng = getLanguageNameByKey(lang, false);
    let content = get(this.props, `platform.description[${lng}].${type}`, '') || '';

    // Fallback for descriptions created with the old old textarea (not using a wysiwyg)
    if (type === 'description' && !content.startsWith('<') && !content.endsWith('>')) {
      content = '<p>' + content + '</p>';
    }

    return content;
  };

  render() {
    const {
        props,
        state,
        WysiwygRef,
        handleSubmit,
        handleCancel,
        getContent,
        handleContentLanguageChange
      } = this,
      { t, form, locale, userRoleId } = props,
      { contentLang } = state;

    return (
      <div className="PagesHomepageIntroTextPanel AdminFormWrap">
        <Form layout="horizontal" className="PagesHomepageIntroTextForm" onSubmit={handleSubmit}>
          <div className="inputs">
            <TextField
              form={form}
              fieldId="home_title"
              initialValue={getContent(contentLang, 'title')}
              label={t('title')}
              onChange={this.checkForChangeTitle}
              contentLang={contentLang}
            />
            <Wysiwyg
              ref={WysiwygRef}
              form={form}
              fieldId="home_description"
              label={t('description')}
              initialValue={getContent(contentLang)}
              contentLang={contentLang}
              allowSizes={false}
              allowImages={false}
              allowVideos={false}
              allowHTML={userRoleId === 1}
              accentColors={this.props.accentColors}
              onChange={this.checkForChangeDesc}
              required={false}
            />
          </div>
          <CancelAndSaveButtons onClickCancel={handleCancel} />
        </Form>
        <SettingsColumn>
          <LanguageBoxForm
            handleContentLanguageChange={handleContentLanguageChange}
            changeHasHappened={this.changeHasHappened}
            defaultLanguage={getDefaultLanguageKey(get(this.props, 'languages')) || locale}
          />
        </SettingsColumn>
      </div>
    );
  }
}

PagesHomepageIntroTextForm.propTypes = {
  form: PropTypes.object.isRequired
};

export default translate('PagesHomepageIntroTextForm')(PagesHomepageIntroTextForm);

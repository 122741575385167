export const getTextLength = (text, textOnly = false) => {
  if (!text || typeof text !== 'string')
    return 0;

  let chars = text;
  if(textOnly) {
    // Remove last newline or cariage return added by the WYSIWYG
    chars = chars.replace(/[\n\r]$/m, '');
    // Remove tags
    chars = chars.replace(/(<([^>]+)>)/ig, '');
  }
  return chars.length;
};

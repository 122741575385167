import {
  CONNECTION_MODAL_CLOSED,
  CONNECTION_MODAL_CONFIRM,
  CONNECTION_MODAL_REGISTRATION_CONFIRMATION,
  CREATION_MODE_ON,
  CREATION_MODE_OFF,
} from './uiUtils';

import {
  UI_CONNECTION_MODAL_STATE,
} from './uiActions';

import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_START,
  AUTH_LOGIN_CANCEL,
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_START,
  AUTH_RECOVERY_ERROR,
  AUTH_RECOVERY_SUCCESS,
  AUTH_RECOVERY_START,
  AUTH_RESET_ERROR,
  AUTH_RESET_SUCCESS,
  AUTH_RESET_START,
  AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_START,
  AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_SUCCESS,
  AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_ERROR,
  FETCH_MEMBER_PERMISSIONS_START,
  FETCH_MEMBER_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_PERMISSIONS_ERROR,
  FETCH_MEMBER_ORGANIZATION_PERMISSIONS_START,
  FETCH_MEMBER_ORGANIZATION_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_ORGANIZATION_PERMISSIONS_ERROR,
  FETCH_MEMBER_PLATFORM_PERMISSIONS_START,
  FETCH_MEMBER_PLATFORM_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_PLATFORM_PERMISSIONS_ERROR,
  FETCH_MEMBER_CAMPAIGN_PERMISSIONS_START,
  FETCH_MEMBER_CAMPAIGN_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_CAMPAIGN_PERMISSIONS_ERROR,
  FETCH_MEMBER_TEAM_PERMISSIONS_START,
  FETCH_MEMBER_TEAM_PERMISSIONS_SUCCESS,
  FETCH_MEMBER_TEAM_PERMISSIONS_ERROR,
} from '../session/sessionActions';

export const initialState = {
  connectionModalState: CONNECTION_MODAL_CLOSED,
  connectionModalForced: false,
  customAlert: null,
  from: null,
  connectionModalOrigin: null,
  isLoginInProgress: false,
  isRegisterInProgress: false,
  isRecoveryInProgress: false,
  isRecoverySuccess: false,
  isResetInProgress: false,
  isResetSuccess: false,
  isCreateOrganizationInProgress: false,
  isCreatePlatformInProgress: false,
  isFetchPermissionsInProgress: false,
  isFetchPermissionsOrganizationInProgress: false,
  isFetchPermissionsPlatformInProgress: false,
  isFetchPermissionsCampaignInProgress: false,
  isFetchPermissionsTeamInProgress: false,
  creationMode: false,
  kickedOut: false
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case CREATION_MODE_ON:
      return {...state, creationMode: true };

    case CREATION_MODE_OFF:
      return {...state, creationMode: false };

    case UI_CONNECTION_MODAL_STATE:
      return {
        ...state,
        connectionModalState: action.payload.state,
        connectionModalForced: action.payload.forced,
        customAlert: action.payload.customAlert,
        from: action.payload.from,
        connectionModalOrigin: action.payload.origin,
        kickedOut: action.payload.kickedOut || false
      };

    case AUTH_LOGIN_START:
      return {
        ...state,
        isLoginInProgress: true
      };

    case AUTH_LOGIN_ERROR:
      return {
        ...state,
        isLoginInProgress: false
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        connectionModalState: CONNECTION_MODAL_CLOSED,
        connectionModalForced: false,
        connectionModalOrigin: null,
        customAlert: null,
        from: null,
        isLoginInProgress: false
      };

    case AUTH_REGISTER_START:
      return {
        ...state,
        isRegisterInProgress: true
      };

    case AUTH_REGISTER_ERROR:
      return {
        ...state,
        isRegisterInProgress: false
      };

    case AUTH_REGISTER_SUCCESS:
      return {
        ...state,
        connectionModalState: CONNECTION_MODAL_CONFIRM,
        connectionModalForced: false,
        connectionModalOrigin: null,
        isRegisterInProgress: false
      };

    case AUTH_RECOVERY_START:
      return {
        ...state,
        isRecoveryInProgress: true
      };

    case AUTH_RECOVERY_ERROR:
      return {
        ...state,
        isRecoveryInProgress: false
      };

    case AUTH_RECOVERY_SUCCESS:
      return {
        ...state,
        // connectionModalState: CONNECTION_MODAL_CLOSED,
        // connectionModalForced: false,
        // connectionModalOrigin: null,
        isRecoverySuccess: true,
        isRecoveryInProgress: false
      };

    case AUTH_RESET_START:
      return {
        ...state,
        isResetInProgress: true
      };

    case AUTH_RESET_ERROR:
      return {
        ...state,
        isResetInProgress: false
      };

    case AUTH_RESET_SUCCESS:
      return {
        ...state,
        //connectionModalState: CONNECTION_MODAL_CLOSED,
        //connectionModalForced: false,
        //connectionModalOrigin: null,
        isResetSuccess: true,
        isResetInProgress: false
      };

    case AUTH_LOGIN_CANCEL:
      return {
        ...state,
        isRecoverySuccess: false,
        isResetSuccess: false
      };

    case AUTH_CHECK_REGISTRATION_CONFIRMATION_KEY_SUCCESS:
      return {
        ...state,
        connectionModalState: CONNECTION_MODAL_REGISTRATION_CONFIRMATION,
        connectionModalForced: false,
        connectionModalOrigin: null,
      };

    case FETCH_MEMBER_PERMISSIONS_START:
      return {
        ...state,
        isFetchPermissionsInProgress: true,
      };

    case FETCH_MEMBER_PERMISSIONS_SUCCESS:
    case FETCH_MEMBER_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchPermissionsInProgress: false,
      };

    case FETCH_MEMBER_ORGANIZATION_PERMISSIONS_START:
      return {
        ...state,
        isFetchPermissionsOrganizationInProgress: true,
      };

    case FETCH_MEMBER_ORGANIZATION_PERMISSIONS_SUCCESS:
    case FETCH_MEMBER_ORGANIZATION_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchPermissionsOrganizationInProgress: true,
      };

    case FETCH_MEMBER_PLATFORM_PERMISSIONS_START:
      return {
        ...state,
        isFetchPermissionsPlatformInProgress: true,
      };

    case FETCH_MEMBER_PLATFORM_PERMISSIONS_SUCCESS:
    case FETCH_MEMBER_PLATFORM_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchPermissionsPlatformInProgress: false,
      };

    case FETCH_MEMBER_CAMPAIGN_PERMISSIONS_START:
      return {
        ...state,
        isFetchPermissionsCampaignInProgress: true,
      };

    case FETCH_MEMBER_CAMPAIGN_PERMISSIONS_SUCCESS:
    case FETCH_MEMBER_CAMPAIGN_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchPermissionsCampaignInProgress: false,
      };

    case FETCH_MEMBER_TEAM_PERMISSIONS_START:
      return {
        ...state,
        isFetchPermissionsTeamInProgress: true,
      };

    case FETCH_MEMBER_TEAM_PERMISSIONS_SUCCESS:
    case FETCH_MEMBER_TEAM_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchPermissionsTeamInProgress: false,
      };

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { getPlatformUrl } from 'Parts/common/environment';
import moment from 'moment';

const SitemapPages = ({
  platform,
  platform: {
    settings: { campaign, donation }
  },
  campaigns
}) => {
  const PagesList = pages => {
    const platformUrl = getPlatformUrl(platform.alias);

    const pagesList = pages.map(page => (
      <url>
        <loc>{platformUrl + page.path}</loc>
        <lastmod>{page.lastmod}</lastmod>
      </url>
    ));

    return <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">{pagesList}</urlset>;
  };

  // Check if AT LEAST ONE campaign allows team/participant creation
  const canCreateFn = () => {
    let canCreateTeam,
      canCreateParticipant = false;

    if (campaigns.length) {
      campaigns.map(campaign => {
        switch (campaign.typeId) {
          case 1: //SOLO
            canCreateParticipant = !canCreateParticipant ? true : canCreateParticipant;
            break;

          case 2: // TEAM
            canCreateTeam = !canCreateTeam ? true : canCreateTeam;
            break;

          case 3: // MIXED
            canCreateTeam = !canCreateTeam ? true : canCreateTeam;
            canCreateParticipant = !canCreateParticipant ? true : canCreateParticipant;
            break;
        }
      });
    }

    return { teams: canCreateTeam, participants: canCreateParticipant };
  };

  const canCreate = canCreateFn();
  const lastModPage = moment(platform.updatedOn).format('YYYY/MM/DD');

  const teamCreation = canCreate.teams
    ? [
        { title: 'create-a-team', path: '/en/create-a-team', lastmod: lastModPage },
        { title: 'creer-une-equipe', path: '/fr/creer-une-equipe', lastmod: lastModPage }
      ]
    : [];

  const participantCreation = canCreate.participants
    ? [
        {
          title: 'create-a-participant',
          path: '/en/participant-registration',
          lastmod: lastModPage
        },
        { title: 'creer-un-participant', path: '/fr/inscription-participant', lastmod: lastModPage }
      ]
    : [];

  const generalDonation = donation.enabled
    ? [
        { title: 'general-donation', path: '/en/organization/give', lastmod: lastModPage },
        { title: 'don-general', path: '/fr/organisation/donner', lastmod: lastModPage }
      ]
    : [];

  const campaignCreation = campaign.creation.enabled
    ? [
        { title: 'create-a-campaign', path: '/en/create-a-campaign', lastmod: lastModPage },
        { title: 'creer-une-campagne', path: '/fr/creer-une-campagne', lastmod: lastModPage }
      ]
    : [];

  const pages = [
    { title: 'homepage', path: '/en', lastmod: lastModPage },
    { title: 'accueil', path: '/fr', lastmod: lastModPage },
    { title: 'contact-us', path: '/en/contact-us', lastmod: lastModPage },
    { title: 'contactez-nous', path: '/fr/contactez-nous', lastmod: lastModPage },
    { title: 'refund-policy', path: '/en/refund-policy', lastmod: lastModPage },
    {
      title: 'politique-de-remboursement',
      path: '/fr/politique-remboursement',
      lastmod: lastModPage
    },
    ...generalDonation,
    ...campaignCreation,
    ...teamCreation,
    ...participantCreation
  ];

  return PagesList(pages);
};

SitemapPages.defaultProps = {
  platform: {}
};

SitemapPages.propTypes = {
  platform: PropTypes.object.isRequired,
  campaigns: PropTypes.array.isRequired
};

export default SitemapPages;

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import AdminTitleBar from 'Parts/common/AdminTitleBar';
import CampaignSettingsPageFormContainer from './components/CampaignSettingsPageFormContainer';
import Can from 'Parts/session/components/CanContainer';

import campaignSettingsPageEN from './locales/CampaignSettingsPage_en.json';
import campaignSettingsPageFR from './locales/CampaignSettingsPage_fr.json';

class CampaignSettingsPage extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'CampaignSettingsPage', campaignSettingsPageEN);
    i18next.addResourceBundle('fr', 'CampaignSettingsPage', campaignSettingsPageFR);
  }

  render() {
    const { t, locale, match } = this.props;

    return (
      <Layout className="CampaignSettingsPage">
        <Helmet>
          <title>{t('Registrations settings')}</title>
        </Helmet>
        <AdminTitleBar title={t('Registrations settings')} />
        <div className="AdminPanel">
          <Can
            rules={['permissions.campaign.campaign.update']}
            campaignId={get(match, 'params.campaignID', null)}
            redirect={`/${locale}/d`}
          >
            <CampaignSettingsPageFormContainer {...this.props} />
          </Can>
        </div>
      </Layout>
    );
  }
}

export default translate('CampaignSettingsPage')(CampaignSettingsPage);

import React from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import ParticipantsLimitFieldEN from './locales/ParticipantsLimitField_en.json';
import ParticipantsLimitFieldFR from './locales/ParticipantsLimitField_fr.json';
import { Form, InputNumber } from 'antd';
import FundkyTooltip from 'Parts/ui/components/FundkyTooltip';

class ParticipantsLimitField extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'ParticipantsLimitField', ParticipantsLimitFieldEN);
    i18next.addResourceBundle('fr', 'ParticipantsLimitField', ParticipantsLimitFieldFR);
  }

  render() {
    const { t, data, campaignTypeId, disabled } = this.props;
    const { getFieldDecorator } = this.props.form;

    let inputValue = data === '0' ? '' : data;
    const suffix =
      campaignTypeId === 3 || campaignTypeId === 2 ? (
        <span className="participants-limit-suffix">{t('per team')}</span>
      ) : null;

    const input = <InputNumber min={0} disabled={disabled} />;
    const fieldId = 'participants_limit';
    const fieldOptions = {
      rules: [
        {
          pattern: /^[0-9]+$/,
          message: t('invalid')
        }
      ],
      initialValue: inputValue
    };

    return (
      <Form.Item
        label={
          <span>
            {t('Participants Limit Label')}
            <FundkyTooltip title={t('Field Tooltip')} />
          </span>
        }
        colon={false}
        hasFeedback
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
        {suffix}
      </Form.Item>
    );
  }
}

ParticipantsLimitField.defaultProps = {
  data: '0',
  disabled: false
};

ParticipantsLimitField.propTypes = {
  data: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default translate('ParticipantsLimitField')(ParticipantsLimitField);

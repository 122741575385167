'use strict'

import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import reactCSS from 'reactcss';
import { Form, Input } from 'antd';
import { SketchPicker } from 'react-color';

import './ColorPickerField.less';

class ColorPickerField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      color: props.initialValue,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker2 })
    document.getElementById("scrollable-content").setAttribute("style", "overflow:hidden;");
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    document.getElementById("scrollable-content").removeAttribute("style");
  };

  handleChange = (color) => {
    const { form, fieldId } = this.props;
    this.setState({ color: color.hex })
    form.setFieldsValue({[fieldId]: color.hex})
  };

  componentDidUpdate(prevProps) {
    const { initialValue, form, fieldId } = this.props;

    if ( prevProps.initialValue !== initialValue ) {
        this.setState({ color: initialValue }, form.setFieldsValue({[fieldId]: initialValue}));
    }
  }

  render() {

    const { t, form, fieldId, initialValue, required, className, label } = this.props;
    const { getFieldDecorator } = form;

    const fieldOptions = {
      rules: [
        {
          required: required,
          message: t('required')
        }
      ],
      initialValue: initialValue
    };

    const styles = reactCSS({
      'default': {
        color: {
          backgroundColor: `${ this.state.color }`,
        },
      },
    });

    //const input = <Input type="color" className="ColorPickerField__Input"/>;
    const input = <Input type="hidden" className="ColorPickerField__Input"/>;

    return (
      <Form.Item
        className={classnames('ColorPickerField', className)}
        label={label}
      >
        {getFieldDecorator(fieldId, fieldOptions)(input)}
      
        <button type="button" className="trigger" style={ styles.color } onClick={ this.handleClick }></button>
        { this.state.displayColorPicker ? <div>
          <div className="cover" onClick={ this.handleClose }/>
          <div className="colorPickerWrapper">
            <div className="swatch" style={ styles.color }></div>
            <SketchPicker color={ this.state.color } onChange={ this.handleChange } disableAlpha ={true} presetColors={[]}  />
            <button type="button" className="ant-btn" onClick={ this.handleClose }>OK</button>
          </div>
        </div> : null }
      </Form.Item>
    )
  }
}

ColorPickerField.defaultProps = {
  required: false,
  fieldId: 'color',
  label: false,
  placeholder: false,
  initialValue: undefined,
}

ColorPickerField.propTypes = {
  form: PropTypes.object.isRequired,
}

export default translate('ColorPickerField')(ColorPickerField);
import {
  bindActionCreators,
  compose
} from 'redux';
import {
  connect
} from 'react-redux';
import {
  Form
} from 'antd';

import {
  storeCampaignInformation,
  createCampaign,
  clearCampaign,
} from '../campaignActions';

import {
  openLoginModal
} from "../../parts/ui/uiActions";

import {
  deactivateCreationMode
} from 'Parts/ui/uiActions';

import CreateInformationForm from './CreateInformationForm';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  isCreateOrganizationInProgress: state.ui.isCreateOrganizationInProgress,
  locale: state.session.language,
  storedInformation: state.campaign.campaignInformation,
  userTypeId: state.session.userTypeId,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  storeCampaignInformation,
  createCampaign,
  clearCampaign,
  deactivateCreationMode,
  openLoginModal
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(CreateInformationForm);

import React from 'react';
import i18next from 'i18next';
import { translate } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import AddTransactionsPageForm from './components/AddTransactionsPageFormContainer';
import AdminTitleBar from 'Parts/common/AdminTitleBar';
import AdminBackButtonBar from 'Parts/common/AdminBackButtonBar';
import ResponsiveTabs from 'Parts/ui/components/ResponsiveTabs';
import Can from 'Parts/session/components/CanContainer';

import AddTransactionsPage_en from './locales/AddTransactionsPage_en.json';
import AddTransactionsPage_fr from './locales/AddTransactionsPage_fr.json';

class AddTransactionsPage extends React.Component {
  constructor(props) {
    super(props);

    const { match } = props;

    this.campaignID = get(match, 'params.campaignID', null);

    i18next.addResourceBundle('en', 'AddTransactionsPage', AddTransactionsPage_en);
    i18next.addResourceBundle('fr', 'AddTransactionsPage', AddTransactionsPage_fr);
  }

  render() {
    const { props, campaignID } = this,
      { t, locale } = props;

    const transactionsListUrl = campaignID
      ? `/${locale}/d/campaigns/${campaignID}/transactions/list/donations`
      : `/${locale}/d/transactions/list`;

    const tabPages = [
      {
        title: t('Information'),
        slug: 'information',
        container: () => (
          <Can rules={['permissions.platform.donation.offline']} redirect={`/${locale}/d`}>
            <AddTransactionsPageForm {...props} />
          </Can>
        )
      }
    ];

    return (
      <div className="AddTransactionsPage">
        <Helmet>
          <title>{t('add-transaction')}</title>
        </Helmet>
        <AdminBackButtonBar href={transactionsListUrl} text={t('Back to search')} />
        <AdminTitleBar title={t('add-transaction')} />
        <ResponsiveTabs pages={tabPages} parentPage={props} defaultTabHasNoSlug />
      </div>
    );
  }
}

export default translate('AddTransactionsPage')(AddTransactionsPage);

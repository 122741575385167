import i18next from 'i18next';
import { notification } from 'antd';
import { isEmpty, get } from 'lodash';

import {
  requestApiGet,
  requestApiPut
} from '../parts/common/requestUtils';
import { getSessionIp } from '../parts/session/sessionUtils';

export const FETCH_ORGANIZATION_START = 'FETCH_ORGANIZATION_START';
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const FETCH_ORGANIZATION_ERROR = 'FETCH_ORGANIZATION_ERROR';

export const FETCH_ORGANIZATION_INFORMATION_START = 'FETCH_ORGANIZATION_INFORMATION_START';
export const FETCH_ORGANIZATION_INFORMATION_SUCCESS = 'FETCH_ORGANIZATION_INFORMATION_SUCCESS';
export const FETCH_ORGANIZATION_INFORMATION_ERROR = 'FETCH_ORGANIZATION_INFORMATION_ERROR';

export const FETCH_ORGANIZATION_SETTINGS_START = 'FETCH_ORGANIZATION_SETTINGS_START';
export const FETCH_ORGANIZATION_SETTINGS_SUCCESS = 'FETCH_ORGANIZATION_SETTINGS_SUCCESS';
export const FETCH_ORGANIZATION_SETTINGS_ERROR = 'FETCH_ORGANIZATION_SETTINGS_ERROR';

export const UPDATE_ORGANIZATION_START = 'UPDATE_ORGANIZATION_START';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';

export const FETCH_ORGANIZATION_BILLING_START = 'FETCH_ORGANIZATION_BILLING_START';
export const FETCH_ORGANIZATION_BILLING_SUCCESS = 'FETCH_ORGANIZATION_BILLING_SUCCESS';
export const FETCH_ORGANIZATION_BILLING_ERROR = 'FETCH_ORGANIZATION_BILLING_ERROR';
export const CHECK_IS_BILLING_CONFIGURED = "CHECK_IS_BILLING_CONFIGURED";

export function fetchOrganization(id, params = null) {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATION_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/organizations/${id}`,
      params,
      headers
    )
      .then(organization => {
        return dispatch({ type: FETCH_ORGANIZATION_SUCCESS, payload: organization });
      })
      .catch(error => {
        dispatch({ type: FETCH_ORGANIZATION_ERROR, error });
      });
  };
}

export function fetchOrganizationInformation(id) {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATION_INFORMATION_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/organizations/${id}/profiles`,
      null,
      headers
    )
      .then(organization => {
        return dispatch({ type: FETCH_ORGANIZATION_INFORMATION_SUCCESS, payload: organization });
      })
      .catch(error => {
        dispatch({ type: FETCH_ORGANIZATION_INFORMATION_ERROR, error });
      });
  };
}

export function fetchOrganizationSettings(id) {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATION_SETTINGS_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/organizations/${id}/information`,
      null,
      headers
    )
      .then(organization => {
        return dispatch({ type: FETCH_ORGANIZATION_SETTINGS_SUCCESS, payload: organization });
      })
      .catch(error => {
        dispatch({ type: FETCH_ORGANIZATION_SETTINGS_ERROR, error });
      });
  };
}

export function updateOrganization(formValues, organizationId) {
  return dispatch => {
    dispatch({ type: UPDATE_ORGANIZATION_START });

    const values = {
      profileTypeId: 1,
      registerNumber: formValues.registerNumber || undefined,
      address: formValues.address || undefined,
      city: formValues.city || undefined,
      postalCode: formValues.postalCode || undefined,
      country: formValues.country || undefined,
      province: formValues.province || undefined
    };

    Object.keys(values).forEach(key => values[key] === undefined && delete values[key]);

    const headers = {
      ...dispatch(getSessionIp())
    };

    return Promise.all([
      ...(formValues.name
        ? [requestApiPut(
            `/organizations/${organizationId}`,
            { name: formValues.name },
            headers
          )]
        : []
      ),
      ...(!isEmpty(values)
        ? [requestApiPut(
            `/organizations/${organizationId}/profiles`,
            values,
            headers
          )]
        : []
      )
    ])
      .then(() => {
        dispatch(fetchOrganization(organizationId, { showProfiles: true, showPlugins: true }));
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({ type: UPDATE_ORGANIZATION_SUCCESS });
      })
      .catch(error => {
        const description = error.description || error;
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: description
        });
        return dispatch({ type: UPDATE_ORGANIZATION_ERROR, error: error });
      });
  };
}

export function downloadSettlementReport(organizationId, withdrawalId) {
  return dispatch => {
    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/organizations/${organizationId}/reports/withdrawals/${withdrawalId}/download`,
      null,
      headers
    )
      .then(settlementReport => {
        return settlementReport;
      })
      .catch(error => {
        // dispatch({ type: FETCH_ORGANIZATION_ERROR, error })
      });
  };
}

export function fetchOrganizationBilling(id) {
  return dispatch => {
    dispatch({ type: FETCH_ORGANIZATION_BILLING_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/organizations/${id}/billing`,
      null,
      headers
    )
      .then(billing => {
        let isBillingConfigured =
          get(billing, 'wepay.chargesEnabled') ||
          get(billing, 'stripe.chargesEnabled') ||
          //get(billing, 'paypal.chargesEnabled') ||
          false;

        dispatch({ type: CHECK_IS_BILLING_CONFIGURED, isBillingConfigured: isBillingConfigured });
        return dispatch({ type: FETCH_ORGANIZATION_BILLING_SUCCESS, payload: billing });
      })
      .catch(error => {
        return dispatch({ type: FETCH_ORGANIZATION_BILLING_ERROR, error });
      });
  };
}

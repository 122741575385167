import { requestApiGet, requestApiDelete } from 'Parts/common/requestUtils';
import { notification } from 'antd';
import i18next from 'i18next';

export const FETCH_SURVEYS_LIST_START = 'FETCH_SURVEYS_LIST_START';
export const FETCH_SURVEYS_LIST_SUCCESS = 'FETCH_SURVEYS_LIST_SUCCESS';
export const FETCH_SURVEYS_LIST_ERROR = 'FETCH_SURVEYS_LIST_ERROR';

export const DELETE_SURVEY_START = 'DELETE_SURVEY_START';
export const DELETE_SURVEYS_SUCCESS = 'DELETE_SURVEYS_SUCCESS';
export const DELETE_SURVEYS_ERROR = 'DELETE_SURVEYS_ERROR';

export const fetchSurveysInformations = (
  locale,
  user_id,
  platform_id,
  _search = null,
  filters = {},
  pagination = {},
  orderBy = null,
  authentication = false,
  showChildrenCount = false,
  showActions = false
) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SURVEYS_LIST_START });

    const pageNumber =
      (pagination && pagination.current) || (pagination && pagination.pageNumber) || 1;
    const pageResults =
      (pagination && pagination.pageSize) || (pagination && pagination.pageResults) || 10;

    orderBy =
      orderBy && (has(orderBy, 'orderBy') || typeof orderBy === 'string')
        ? typeof orderBy === 'string'
          ? { orderBy: orderBy }
          : orderBy
        : null;

    const search = _search || (filters && filters.search) || undefined;
    if (filters) delete filters.search;

    let formatedFilters = formatFilters(filters);

    const params = {
      user_id,
      platform_id,
      lang_id: locale,
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      ...(showChildrenCount ? { showChildrenCount: true } : {}),
      ...(showActions ? { showActions: true } : {}),
    };
    requestApiGet(`/survey`, params)
      .then((response) => {
        return dispatch({ type: FETCH_SURVEYS_LIST_SUCCESS, payload: response });
      })
      .catch((error) => {
        return dispatch({
          type: FETCH_SURVEYS_LIST_ERROR,
          error,
        });
      });
  };
};

export const deleteSurveyInformations = (id, user_id, platform_id, locale, pagination) => {
  return (dispatch) => {
    dispatch({ type: DELETE_SURVEY_START });

    requestApiDelete(`/survey/${id}`)
      .then((response) => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:survey-delete'),
        });
        dispatch({ type: DELETE_SURVEYS_SUCCESS, payload: response.data });
        dispatch(fetchSurveysInformations(locale, user_id, platform_id, null, {}, pagination));
      })
      .catch((error) => {
        return dispatch({
          type: DELETE_SURVEYS_ERROR,
          error,
        });
      });
  };
};

const formatFilters = (filters) => {
  let formatedFilters = [];
  for (let key in filters) {
    if (filters[key] === null || filters[key] === 'NULL' || filters[key] === undefined) continue;

    let filter = Array.isArray(filters[key]) ? filters[key].join('|') : filters[key];

    formatedFilters.push(`${key}[${filter}]`);
  }
  return formatedFilters.length > 0 ? formatedFilters.join(',') : undefined;
};

import { bindActionCreators, compose } from "redux";
import { Form } from 'antd';
import { connect } from "react-redux";
import dataFetcher from "../../../parts/chore/dataFetcher";

import { updateMember } from './../../../member/memberActions';
import { updateSessionState } from 'Parts/session/sessionActions';
import { checkAuthorization } from 'Parts/common/authorizationUtils';

import InformationForm from './InformationForm';

const mapStateToProps = state => ({
  member: state.member.member,
  platformAlias: state.platform.platform.alias,
  platformId: state.platform.platform.id,
  plugins: state.platform.platform.plugins,
  userId: state.session.userId
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateMember,
  updateSessionState,
  checkAuthorization
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  dataFetcher,
  Form.create()
)(InformationForm);

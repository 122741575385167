import i18next from 'i18next';
import { getLanguageKeyById } from '../common/langUtils';

export function getLocale(state, forcedPath = null) {
  const
    { session , platform } = state,
    { languageId } = session,
    _platform = platform && platform.languages || null; /* Headquater do not have platform */
  var platformLang = null
  
  if ( _platform ) {
    Object.keys(_platform).forEach(key => {
      if (_platform[key].default) {
        platformLang = getLanguageKeyById(_platform[key].languageId)
      }
    });
  }

  let urlLng = typeof window !== 'undefined' ? (window.location.pathname).split("/")[1] : forcedPath ? forcedPath.split("/")[1] : null;

  if (urlLng && urlLng.match(/^(en|fr)$/)) { // if url contains language
    return urlLng;
  } else if (languageId) { // if session.languageId
    return getLanguageKeyById(languageId);
  } else if (i18next.language) {
    return i18next.language;
  } else if (platformLang) {
    return platformLang;
  } else {
    return 'en';
  }
}

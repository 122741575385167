import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoadingPage = ({ alignment, size, margin }) => {
  const alignItemsValue = alignment === 'left'
    ? 'flex-start'
    : 'center'
      ? 'center'
      : 'right'
        ? 'flex-end'
        : null;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: alignItemsValue,
        flexDirection: 'column',
        margin: margin
      }}
    >
      <FontAwesomeIcon icon={['fal', 'spinner']} spin size={size} />
    </div>
  );
};

LoadingPage.defaultProps = {
  alignment: 'center',
  size: '6x',
  margin: '200px 0'
};

LoadingPage.propTypes = {
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  size: PropTypes.string,
  margin: PropTypes.string
};

export default LoadingPage;

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'antd';
import LinkButton from '../../common/LinkButton';
import { translate } from 'react-i18next';

import './ConfirmationPanel.less';

class ConfirmationPanel extends React.Component {
  handleClick = event => {
    event.preventDefault();
    this.props.forceCloseConnectionModal();
  };

  render() {
    const { t, locale, className } = this.props;

    return (
      <div className={`ConfirmationPanel ${className}`}>
        <div className="ConfirmationPanel__TitleWrapper">
          <Icon type="check-circle-o" />
          <h1>{t('SESSION:REGISTER.CONFIRMATION.title')}</h1>
        </div>
        <p>{t('SESSION:REGISTER.CONFIRMATION.msg')}</p>
        {!this.props.creationMode ? (
          <>
            <LinkButton
              size="large"
              type="primary"
              to={`/${locale}/${t('URL:dashboard')}`}
            >
              {t('SESSION:REGISTER.CONFIRMATION.action')}
            </LinkButton>
            <div className="closeWindow" onClick={this.handleClick}>
              {t('SESSION:REGISTER.CONFIRMATION.continue')}
            </div>
          </>
        ) : (
          <Button onClick={this.handleClick} size="large" type="primary">
            {t('SESSION:REGISTER.CONFIRMATION.close')}
          </Button>
        )}
      </div>
    );
  }
}

ConfirmationPanel.defaultProps = {
  className: ''
};

ConfirmationPanel.propTypes = {
  className: PropTypes.string
};

export default translate()(ConfirmationPanel);

import React from 'react';
import classnames from 'classnames';
import { connectRefinementList } from 'react-instantsearch-dom';
import './AlgoliaMultiRefinementList.less';

const RefinementList = ({ items, refine }) => {
  return (
    <ul className="CustomRefinementList">
      {items.map(item => (
        <li
          key={item.label}
          className={classnames('CustomRefinementList__item', { selected: item.isRefined })}
        >
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              refine(item.value);
            }}
          >
            {item.label} <span className="item-count">({item.count})</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default connectRefinementList(RefinementList);

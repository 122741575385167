import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class PlatformSocialMedia extends React.Component {
  render() {
    const {
      containerTag,
      platformSocial,
      title,
      titleTag,
      icons,
      iconSize,
      iconBackground,
      iconColor,
      className
    } = this.props; // TO DO -> Add props to change Icons

    if (platformSocial === null) {
      return null;
    }

    // SOCIAL BACKGROUND COLOR
    const backgroundIcon = !iconBackground
      ? {
          facebook: '',
          linkedin: '',
          twitter: '',
          pinterest: '',
          instagram: ''
        }
      : {
          facebook: 'facebook-bg',
          linkedin: 'linkedin-bg',
          twitter: 'twitter-bg',
          pinterest: 'pinterest-bg',
          instagram: 'instagram-bg'
        };

    // SOCIAL ICON COLOR
    const colorIcon = !iconColor
      ? {
          facebook: '',
          linkedin: '',
          twitter: '',
          pinterest: '',
          instagram: ''
        }
      : {
          facebook: 'facebook-color',
          linkedin: 'linkedin-color',
          twitter: 'twitter-color',
          pinterest: 'pinterest-color',
          instagram: 'instagram-color'
        };

    // ICONS MARKUP
    const facebookSocial =
      platformSocial !== null && platformSocial.facebook ? (
        <li>
          <a
            href={platformSocial.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className={backgroundIcon.facebook}
          >
            <FontAwesomeIcon
              className={colorIcon.facebook}
              icon={['fab', icons.facebook]}
              size={iconSize}
            />
          </a>
        </li>
      ) : null;

    const linkedinSocial =
      platformSocial !== null && platformSocial.linkedin ? (
        <li>
          <a
            href={platformSocial.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className={backgroundIcon.linkedin}
          >
            <FontAwesomeIcon
              className={colorIcon.linkedin}
              icon={['fab', icons.linkedin]}
              size={iconSize}
            />
          </a>
        </li>
      ) : null;

    const twitterSocial =
      platformSocial !== null && platformSocial.twitter ? (
        <li>
          <a
            href={platformSocial.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className={backgroundIcon.twitter}
          >
            <FontAwesomeIcon
              className={colorIcon.twitter}
              icon={['fab', icons.twitter]}
              size={iconSize}
            />
          </a>
        </li>
      ) : null;

    const pinterestSocial =
      platformSocial !== null && platformSocial.pinterest ? (
        <li>
          <a
            href={platformSocial.pinterest}
            target="_blank"
            rel="noopener noreferrer"
            className={backgroundIcon.pinterest}
          >
            <FontAwesomeIcon
              className={colorIcon.pinterest}
              icon={['fab', icons.pinterest]}
              size={iconSize}
            />
          </a>
        </li>
      ) : null;

    const instagramSocial =
      platformSocial !== null && platformSocial.instagram ? (
        this.props.from && this.props.from === 'ContactPage' ? (
          <li>
            <a
              href={platformSocial.instagram}
              target="_blank"
              rel="noopener noreferrer"
              className={backgroundIcon.instagram + ' PublicFooter--instagram'}
            >
              <FontAwesomeIcon
                className={colorIcon.instagram}
                icon={['fab', icons.instagram]}
                size={iconSize}
              />
            </a>
          </li>
        ) : (
          <li>
            <a
              href={platformSocial.instagram}
              target="_blank"
              rel="noopener noreferrer"
              className={backgroundIcon.instagram + ' fa-layers PublicFooter--instagram'}
            >
              <FontAwesomeIcon
                className={colorIcon.instagram + '--square'}
                icon={['fas', icons.square]}
                size={iconSize}
              />
              <FontAwesomeIcon
                className={colorIcon.instagram + '--icon'}
                icon={['fab', icons.instagram]}
                transform="shrink-4"
                size={iconSize}
              />
            </a>
          </li>
        )
      ) : null;

    const CustomTag = title !== null && `${titleTag}`;

    const socialTitle = title !== null ? <CustomTag>{title}</CustomTag> : null;

    const socialClass =
      className !== '' ? `PlatformSocialMedia__${className}` : 'PlatformSocialMedia';

    const Tag = containerTag;

    const platformSocialMedia =
      platformSocial !== null ? (
        <Tag className={socialClass}>
          {socialTitle}
          <ul>
            {facebookSocial}
            {linkedinSocial}
            {twitterSocial}
            {pinterestSocial}
            {instagramSocial}
          </ul>
        </Tag>
      ) : null;

    return <React.Fragment>{platformSocialMedia}</React.Fragment>;
  }
}

PlatformSocialMedia.propTypes = {
  icons: PropTypes.object,
  containerTag: PropTypes.oneOf(['div', 'li', 'span'])
};

PlatformSocialMedia.defaultProps = {
  containerTag: 'div',
  platformSocial: null,
  title: null,
  titleTag: 'h4',
  className: '',
  iconSize: '1x',
  iconBackground: false,
  iconColor: false,
  icons: {
    facebook: 'facebook-square',
    linkedin: 'linkedin',
    twitter: 'twitter-square',
    pinterest: 'pinterest-square',
    instagram: 'instagram',
    square: 'square'
  }
};

export default PlatformSocialMedia;

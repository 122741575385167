export const en_ca = {
  "URL": {
    "organization": "organization",
    "organization/give": "organization/give",
    "organization/give/information": "organization/give/information",
    "organization/give/survey": "organization/give/survey",
    "organization/give/payment": "organization/give/payment",
    "organization/give/confirmation": "organization/give/confirmation",
    "organization/give/confirmation/success": "organization/give/confirmation/success",
    "organization/give/confirmation/failed": "organization/give/confirmation/failed",
    "organization/give/confirmation/cancelled": "organization/give/confirmation/cancelled",
    "organization/give/confirmation/error": "organization/give/confirmation/error",

    "give" : "give",
    "give/information" : "give/information",
    "give/survey" : "give/survey",
    "give/payment" : "give/payment",
    "give/confirmation" : "give/confirmation",
    "give/confirmation/success" : "give/confirmation/success",
    "give/confirmation/failed" : "give/confirmation/failed",
    "give/confirmation/cancelled" : "give/confirmation/cancelled",
    "give/confirmation/error" : "give/confirmation/error",
    "give/confirmation/pending" : "give/confirmation/pending",
    "give/campaigns" : "give/campaigns",
    "give/teams" : "give/teams",
    "give/participants" : "give/participants",

    "login": "login",
    "create-an-account": "create-an-account",
    "create-an-account/confirmation": "create-an-account/confirmation",

    "account-recovery": "account-recovery",
    "reset-password": "reset-password",

    "registration-confirm": "registration-confirm",

    "create-a-campaign": "create-a-campaign",
    "create-a-campaign/description": "create-a-campaign/description",
    "create-a-campaign/confirmation": "create-a-campaign/confirmation",

    "create-a-team": "create-a-team",
    "create-a-team/confirmation": "create-a-team/confirmation",

    "create-a-team/choose-campaign": "create-a-team/choose-campaign",
    "create-a-team/information": "create-a-team/information",

    "participant-registration": "participant-registration",
    "participant-registration/confirmation": "participant-registration/confirmation",

    "participant-registration/choose-campaign": "participant-registration/choose-campaign",
    "participant-registration/choose-team": "participant-registration/choose-team",
    "participant-registration/information": "participant-registration/information",
    "individual-participant": "individual-participant",

    "cookie-policy": "https://info.fundky.com/en/cookie-policy/",
    "privacy-policy": "https://info.fundky.com/en/privacy-policy",
    "terms-of-use": "https://info.fundky.com/en/terms-of-use/",

    "search": "search",
    "search/campaigns": "search/campaigns",
    "search/teams": "search/teams",
    "search/participants": "search/participants",
    "refund-policy": "refund-policy",
    "organization-guidelines": "organization-guidelines",
    "contact-us": "contact-us",

    "404": "404",
    "error": "error",
    "maintenance": "maintenance",
    "dashboard": "d",

    // "buy-ticket-chooseticket": "buy-ticket-chooseticket",
    // "buy-ticket-promocode": "buy-ticket-promocode",
    // "buy-ticket-details": "buy-ticket-details",
    // "payment": "payment",
    // "ticket-confirmation": "ticket-confirmation",
    // "ticket-confirmation/success": "ticket-confirmation/success",
    // "ticket-confirmation/failed": "ticket-confirmation/failed",
    // "ticket-confirmation/cancelled": "ticket-confirmation/cancelled",
    // "ticket-confirmation/pending": "ticket-confirmation/pending",

    "ticket-purchase": "ticket-purchase",
    "ticket-purchase/choose": "ticket-purchase/choose",
    "ticket-purchase/promo-code": "ticket-purchase/promo-code",
    "ticket-purchase/details": "ticket-purchase/details",
    "ticket-purchase/survey": "ticket-purchase/survey",
    "ticket-purchase/payment": "ticket-purchase/payment",
    "ticket-purchase/confirmation": "ticket-purchase/confirmation",
    "ticket-purchase/confirmation/success": "ticket-purchase/confirmation/success",
    "ticket-purchase/confirmation/failed": "ticket-purchase/confirmation/failed",
    "ticket-purchase/confirmation/cancelled": "ticket-purchase/confirmation/cancelled",
    "ticket-purchase/confirmation/error": "ticket-purchase/confirmation/error",
    "ticket-purchase/confirmation/pending": "ticket-purchase/confirmation/pending",
  },
  "FIELDS": {
    "first-name": "First name",
    "last-name": "Last name",
    "user-name": "User name",
    "email": "Email address"
  },
  "FIELDS-VALIDATION": {
    "required-message": "This field is required.",
    "name": {
      "required": "Please input your name.",
      "required_3": "Please input the name.",
      "validation": "You used unauthorized characters.",
      "maximum": "2 to {{max}} characters maximum."
    },
    "firstname": {
      "required": "Please input your first name.",
      "required_3": "Please input the first name.",
      "validation": "You used unauthorized characters.",
      "maximum": "2 to {{max}} characters maximum."
    },
    "lastname": {
      "required": "Please input your last name.",
      "required_3": "Please input the last name.",
      "validation": "You used unauthorized characters.",
      "maximum": "2 to {{max}} characters maximum."
    },
    "email": {
      "required": "Please input your email address.",
      "required_3": "Please input the email address.",
      "validation": "The email address must be valid."
    },
    "username": {
      "required": "Please input your user name.",
      "required_3": "Please input the user name.",
      "validation": "You used unauthorized characters.",
      "maximum": "2 to {{max}} characters maximum."
    },
    "custom": {
      "required": "Please input content.",
      "validation": "You used unauthorized characters.",
      "minimum": "{{min}} characters minimum.",
      "minimum-maximum": "{{min}} to {{max}} characters maximum.",
      "equal": "{{equal}} characters exactly.",
      "maximum": "{{max}} characters maximum."
    },
    "url": {
      "required": "Please input the url.",
      "validation": "The URL must be valid."
    },
    "date": {
      "past": "Please select a date before {{time}}.",
      "future": "Please select a date after {{time}}.",
      "now": "now"
    }
  },
  SESSION: {
    LOGIN: {
      'twitch-check-email': {
        title: "Action required",
        description: "Please, check your email to link your Twitch account to your Fundky account."
      },
      'twitch-error': {
        title: "Twitch connection error",
        description: "Please be sure to validate your Twitch account with an email address before logging into Fundky."
      },
      'twitch-access-denied': {
        title: "Twitch access denied",
        description: "You must authorize the connection to connect with Twitch. Please try again."
      },
      'twitch-link-fail': {
        title: "Fail to link to Twitch account",
        description: "The token is invalid. Please try again."
      }
    },
    REGISTER: {
      CONFIRMATION: {
        title: "Success!",
        msg: "Congrats, your Fundky account has been created!",
        action: "View my dashboard",
        close: "Close",
        closeWindow: "Close this window",
        continue: "Continue"
      }
    },
    REGISTRATION: {
      CONFIRM: {
        title: "Success!",
        msg: "Congrats, your Fundky account has been created!",
        action: "Continue",
        "title-error": "Activation fail!",
        "action-error": "Close",
      }
    },
    EXPIRATION: {
      KICKEDOUT: {
        message: "For security purposes, we have logged you out due to infrequent activity.",
      },
      WARNING: {
        message: "You’ll be automatically logged out in two minutes.",
        connect: "Stay logged in",
        logout: "Log out now"
      }
    }
  },
  ERROR: {
    '1000': 'The server is temporarily unable to service your request due to maintenance downtime or capacity problems. Please try again later.',
    '1001': 'This server could not understand your request.',
    '1002': 'Your username or your password is invalid.',
    '1003': 'Access is denied due to invalid credentials.',
    '1004': 'Unfortunately, the ressource is not found.',
    '1006': 'This server could not understand your data.',
    '1101': 'Your Email exist. Please change Email.',
    '1104': 'Your Alias exist. Please change Alias.',
    '1110': 'Your account is blocked for ',
    '1111': 'You have the correct authenticator but your account is blocked during ',
    '1120': 'This server could not understand your data.',
    '2000': 'This server could not find the Activation Key you have.',
    '2001': 'Your Organisation exist. Please change Organisation.',
    sessionExpired: 'Your connection has expired. You must login again.',
    emailValidation: {
      notConform: 'Please enter a valid email address (abcd@efgh.ca).',
      same: 'Both e-mails are not identical'
    },
    passwordValidation: {
      notConform: 'Please enter a valid password (8 minimum characters with special characters).',
      same: 'Both passwords are not the same'
    },
    aliasValidation: {
      noAccent: 'Caution the alias can not contain accent',
      notConform: 'Please enter a valid alias (no Accent please).'
    },
    acceptedTermsConditions: 'You must accept the therms and conditions, please.',
    permissionOptinConditions: 'You must have the right, by the organization, to register it on Fundky.',
    dirty: 'You use foul language or a rude word',

    "200": "Success",
    "201": "Created",
    "204": "No content",

    "400": "Bad request",
    "401": "Unauthorized",
    "403": "Forbidden",
    "404": "Not found",
    "409": "Conflict",
    "422": "Unprocessable entity",

    "500": "Internal server error",
    "502": "Your changes cannot be saved at the moment. Please try again later.",

    "FK-JOI-SCH-1000": "Contact the administrator.",

    "FK-MEM-1000": "This email address is already associated with another Fundky Connect account. Please enter a new email address.",
    "FK-MEM-1100": "Member token not valid.",
    "FK-MEM-1200": "Member has organization role.",
    "FK-MEM-1201": "Member has platform role.",
    "FK-MEM-1202": "Member has campaign role.",
    "FK-MEM-1203": "Member has team role.",
    "FK-MEM-1204": "Password already used. Choose a new one.",

    "FK-CAPTCHA-1000": "Verification reCAPTCHA failed.",

    "FK-ORG-1000": "Organization name not available.",
    "FK-ORG-1001": "Organization invitation token not valid.",

    "FK-PLA-1000": "Platform alias not available.",

    "FK-CAM-1000": "Campaign alias not available.",
    "FK-CAM-1001": "Campaign creation disabled.",

    "FK-CAM-EVT-TKT-1001": "Tickets already sold / reserved ! Adjust your amount of tickets or try again later.",
    "FK-CAM-EVT-TKT-1002": "Oops! All tickets have been sold!",

    "FK-TEA-1000": "Team alias not available.",
    "FK-TEA-1001": "Team creation disabled.",
    "FK-TEA-1002": "Team limit reached.",

    "FK-PAR-1000": "Participant is participating.",
    "FK-PAR-1001": "Participant is not participating.",
    "FK-PAR-1002": "Participant is captain.",
    "FK-PAR-1003": "Participant creation disabled",
    "FK-PAR-1004": "Participant limit reached.",

    "FK-DON-1000": "This recipient doesn’t accept donations anymore.",

    "FK-PLU-1000": "Plugin is disabled.",

    "FK-PLU-TXR-1000": "Plugin donation receipt exists.",
    "FK-PLU-TXR-1001": "Plugin donation receipt doesn't exist.",
    "FK-PLU-TXR-1002": "Plugin donation receipt being processed.",
    "FK-PLU-TXR-1003": "Plugin donation receipt missing configuration.",
    "FK-PLU-TXR-1004": "Plugin donation receipt disabled.",
    "FK-PLU-TXR-1005": "The donor's information is not complete. Please fill in the missing fields and try again.",
    "FK-PLU-TXR-1006": "Missing logo to issue donation receipt.",
  },
  addOwnerPage: {
    title: 'Add owner',
    Email: 'Email',
    confirm: 'Email confirmation',
    btn: 'Send Email',
    success: {
      resend: 'You have resend an email to this person:  ',
      cancel: 'You have cancelled your request to this person: ',
      delete: "You have deleted the 'owner' rights of this person: ",
      add: "You have requested to add the 'owner' rights of this person: "
    },
    titleWaiting: 'Pending Request : ',
    titleValidate: 'Owner of the organization : ',
    created: 'Created the',
    resend: 'Resend',
    cancel: 'Cancel',
    name: 'Name',
    delete: 'Delete',
    popconfirm: {
      mail: 'Are you sure you want to RESEND an email to this person?',
      cancel: 'Are you sure you want to CANCEL your request to this person?',
      delete: "Are you sure you want to DELETE the 'owner' rights of this person?"
    }
  },
  agreementPage: {
    title: 'TERMS AND CONDITIONS OF USE',
    about: 'fundky.com (the « Site ») is a Web and mobile service designed to enable individuals, Small groups, \
			or agencies to be able to engage in a fundraising activity And to collect donations. \
			The Site is a division of ODE Technologies Inc.',
    terms: '<ol><Li> To create fundraising activities, each individual or organization must become a member of the Site \
				And at all times respect the present conditions of use of the Site which can be modified at any time by \
				ODE Technologie.</ Li>\
			<Li> ODE Technologie reserves the right to refuse any registration or activity, \
				in particular if the activity does not seem legitimate, \
				if its content is offensive or violates the rights of a third party or for any other reason deemed reasonable \
				by ODE Technologie.</ Li> \
			<Li> You must preserve your password strictly confidential.</ Li> \
			<Li> By putting online or by providing ODE Technology with any content, you guarantee that you have all \
				the rights related to it, including in particular any copyright or right to the image. In addition, in doing so, \
				you automatically grant ODE Technology, without any restriction whatsoever and for the whole world, \
				a right to use the content for the purposes of the Site and for its promotion, free of any royalty and waives \
				The exercise of any claim or remedy against ODE Technology or any other legal entity controlled by or related \
				to ODE Technology, their employees, agents and representatives in connection with the use that may be made of \
				such content by anyone.</ Li> \
			<Li> ODE Technologie reserves the right to suspend or close, either temporarily or permanently, \
				Any account of a member who does not act in accordance with these terms and conditions or \
				restrict access to the Site without notice.</ Li></ Ol>'
  },
  confirmAddOwnerPage: {
    title: 'Confirming the addition of an owner',
    about: "A request to become 'Owner' has been sent to you by : ",
    btn: {
      accept: 'Accept',
      reject: 'Reject'
    },
    platforms: 'Platforms',
    confirm: 'GREAT!',
    reject: 'Oh! SORRY!',
    link: 'Return to dashboard'
  },
  confirmOrganisationPage: {
    title: 'Confirmation of the creation of the organization',
    about: 'Thank you for creating your organization',
    link: 'Return to dashboard'
  },
  confirmRegisterPage: {
    title: 'Confirming your account creation',
    title2: 'Registration to the platform',
    about: 'Bravo! You are now a Fundky member. Do you still want to register on the platform ',
    link: 'Back to home page',
    btn: 'Check In'
  },
  createOrganisationPage: {
    title: 'Create Organization',
    designation: 'Organisation type',
    organisationType: ['OBNL', 'OSBL / Entreprise'],
    registerNumber: 'Register number',
    email: {
      name: 'Email',
      message: 'This email is used to contact this organisation'
    },
    name: 'Name',
    website: 'Website',
    address: 'Address',
    city: 'City',
    postalCode: 'Postal Code',
    country: 'Country',
    province: 'Province',
    phone: 'Phone',
    poste: 'Poste',
    permissionOptin: 'I have permission to register this organization on Fundky.',
    btn: 'Create',
    option: 'please select option',
    success: {
      title: 'Great',
      msg: "You're created Organisation. You're going to be redirected to the organisations page."
    }
  },
  createPlatformPage: {
    title: 'Creating a Platform',
    name: 'Platform Name',
    choiceOrga: 'Choosing an organization',
    createPlatform: 'Creating a Platform',
    confirm: 'Confirmation of creation',
    cancel: 'Cancel',
    prev: 'Back to previous step',
    createOrganisation: 'Creation of the organization',
    selectOrganisation: 'Organization Selection',
    webPlatform: 'Platform address',
    goForward: 'Go Forward',
    btn: 'Create',
    finish: 'Success of the creation of the platform:',
    success: 'Bravo! You have created a platform. You can redirect to your platform: ',
    platform: 'The website of the platform',
    admin: 'The administration of the platform'
  },
  dashboardPage: {
    title: 'Fundky Administration',
    about: `<p><b> YOU ARE CONNECTED IN THE LOGBOOK PAGE </b><br/>
			<ul>
				<li> Participants List </li>
				<li> organization List </li>
				<li> platform List </li>
			</ul>
		</p> `
  },
  editEmailPage: {
    title: 'Modifying e-mail',
    oldEmail: 'Old Email',
    newEmail: {
      name: 'New email',
      message: 'This email will also be requested when you log in'
    },
    confirm: 'Email confirmation',
    btn: 'Update',
    success: {
      title: 'Great',
      msg: 'You have changed your email.'
    }
  },
  editOrganisationPage: {
    title: 'Modify Organization',
    email: {
      name: 'Email',
      message: 'This email is used to contact this organisation'
    },
    name: 'Name',
    website: 'Website',
    address: 'Address',
    city: 'City',
    postalCode: 'Postal Code',
    country: 'Country',
    province: 'Province',
    phone: 'Phone',
    poste: 'Poste',
    btn: 'Update',
    success: {
      title: 'Great',
      msg: "You have changed your organization's information. You're going to be redirected to the organization's list."
    }
  },
  editPasswordPage: {
    title: 'Change password',
    newPassword: 'New password',
    confirm: 'Password confirmation',
    btn: 'Update',
    success: {
      title: 'Great',
      msg: 'You have changed your password.'
    }
  },
  editPersonalDetailsPage: {
    title: 'Personal Details',
    designation: 'Designation',
    firstname: 'Firstname',
    lastname: 'Lastname',
    supermin: 'You have a Super Administrator role',
    alias: 'Alias',
    address: 'Address',
    city: 'City',
    postalCode: 'Postal Code',
    country: 'Country',
    province: 'Province',
    phone: 'Phone',
    bulletinOptin: 'I subscribe to the letter info',
    btn: 'Update',
    gender: ['Mr.', 'Mrs.', 'Miss', 'Mx'],
    success: {
      title: 'Great',
      msg: 'You have changed your informations.'
    },
    locale: 'Language of correspondence',
    lng: ['English', 'French']
  },
  errorMsg: {},
  forgotPasswordPage: {
    title: 'Lost Password',
    email: {
      name: 'Email',
      message: 'This email is the same as the one to connect'
    },
    btn: 'Send an email',
    success: {
      title: 'Lost Password',
      msg: 'An email has been sent to you to change your password.'
    }
  },
  footer: {
    about: '© 2017 All Rights Reserved.'
  },
  header: {
    give: 'Make a donation',
    createanaccount: 'Register',
    createacampaign: 'Create a campaign',
    search: 'Search',
    contactus: 'Contact',
    login: 'Login',
    editpersonaldetails: 'Edit Personal Details',
    editpassword: 'Edit Password',
    editemail: 'Edit Email',
    settings: 'Settings',
    contribute: 'Contribute',
    search: 'Search',
    logout: 'Logout',
    lng: 'FR'
  },
  history: {
    created: 'Created',
    action: 'Action',
    actor: "administrator's name",
    target: "Target's name",
    targetType: 'Type',
    targetUpdate: 'Update of',
    ip: 'Public IP address',
    browser: "Browser's name",
    user: 'User',
    organisation: 'Organization',
    login: 'Login',
    Logout: 'Logout',
    Edit: 'Edit',
    sessionExpired: 'Session expired',
    personalDetails: 'Personal Details',
    unknown: 'Unknown',
    SendRequestOwner: "Send Request for 'owner' rights",
    ResendRequestOwner: "Resend Request for 'owner' rights",
    ValidateRequestOwner: "Validate Request for 'owner' rights"
  },
  home: {
    title: 'Welcome on ',
    about: '',
    connected: "You're logged in with this alias :",
    link: 'Connect You',
    welcome: 'Welcome '
  },
  loading: 'Action in progress...',
  loginPage: {
    title: 'Connexion',
    email: 'Email',
    password: 'Password',
    btn: 'Log In',
    forgot: 'Forgotten password',
    or: 'or',
    register: 'Register now',
    success: {
      title: 'Great',
      msg: "You're logged in. You're going to be redirected to the Dashboard."
    }
  },
  listOrganisationPage: {
    id: 'Id',
    name: 'Name',
    email: 'Email',
    city: 'City',
    created: 'Created',
    edit: 'Edit Info',
    owner: 'Owner',
    deleted: 'Deleted',
    btn: 'Create Organization',
    popconfirm: 'Are you sure you want to delete?',
    success: 'Great! You have deleted this organization: '
  },
  listPlatformPage: {
    id: 'Id',
    name: 'Name',
    created: 'Created',
    edit: 'Edit Info',
    deleted: 'Deleted',
    btn: 'Create Platform',
    popconfirm: 'Are you sure you want to delete?',
    success: 'Great! You have deleted this Platform: ',
    website: 'website',
    admin: 'Admin'
  },
  listUserPage: {
    id: 'Id',
    lastname: 'Lastname',
    firstname: 'Firstname',
    email: 'Email',
    deleted: 'Deleted',
    edit: 'edit',
    confirmed: 'Confirmed',
    locked: 'Locked',
    created: 'Created',
    supermin: 'Super Admin',
    ghost: 'ghost',
    popconfirm: 'Are you sure you want to delete?',
    success: {
      deleteUser: 'Great! You have deleted this user: ',
      editRole: 'Great! You have changed the role of this user: '
    }
  },
  maintenance: {
    title: "We'll be back soon!",
    about: "Sorry for the inconvenience but we're doing some maintenance at the moment. We'll be back online shortly!",
    signature: 'The Team'
  },
  notFoundPage: {
    title: '404',
    about: 'Oops, the page does not exist',
    link: 'Return to dashboard'
  },
  registerPage: {
    title: 'Registration',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: {
      name: 'Email',
      message: 'This email will also be requested when you log in'
    },
    password: 'Password',
    confirm: 'Password confirmation',
    captcha: 'Captcha',
    bulletinOptin: 'I subscribe to the letter info',
    acceptTerms: "<span> I have read and accept <a href='/agreement'> terms and conditions </a> </ span>",
    btn: 'Register',
    success: {
      title: 'Great',
      msg: "You're registered. You're going to be redirected to the connection page."
    }
  },
  search: {
    firstname: 'Firstname',
    lastname: 'Lastname',
    owner: 'Owner',
    organisation: 'Organization',
    organisationLabel: 'Type of Organization',
    platform: 'Platform',
    email: 'Email',
    createdDate: 'Created Date',
    btn: 'Search',
    reset: 'Reset',
    emailOwner: "Owner's Email",
    owner: "Owner's Name",
    organisationType: ['OBNL', 'OSBL / Entreprise'],
    roleLabel: 'User Role',
    roleType: [
      'Super Admin',
      'Owner',
      'Collaborater',
      'Member',
      'Editor',
      'Accounting',
      'Moderator'
    ]
  },
  settingsPage: {
    sb1: 'General parameters',
    sb2: 'Campaign settings',
    indexing: 'Indexation of the platform',
    plateformName: "Plateform's name",
    plateformAddress: "Plateform's address",
    trackingGoogle: 'Tracking Google',
    restricted: 'Restricted access',
    mainEmail: 'Main email',
    languages: 'Languages',
    degaultLanguage: 'Default language',
    socialNetworks: 'Social networks',
    creatingCampaign: 'Creating campaigns for members',
    preAuthorization: 'Pre-authorization of campaigns to their creation',
    btn: 'Backup',
    cancel: 'Cancel',
    oldEmail: 'Old Email',
    email: {
      name: 'Email',
      message: 'Only Google email addresses can be linked to Google Analytics'
    },
    warning: {
      title: 'Warning',
      text: 'the old subdomain will become available to other users. \
					By confirming, the platform will become restricted access time change. \
					An email will be sent to owners and administrators to notify them of the change of address and will invite them to update external links \
					leading to their platform. Otherwise, the old URL will display a 404.'
    },
    lngDefault: 'Default Language',
    lngAvailable: 'Available languages',
    lngPlatform: 'Installed languages',
    lang: {
      en: 'English',
      fr: 'French',
      es: 'Spanish'
    }
  },
  "DEFAULT_REFUND_POLICY": {
    "no-refund": "{{organization}} does not issue refunds.",
    "more-info": "For more information on your donation or registration to our campaign platform, please contact us."
  },
  "API_NOTIFICATIONS": {
    "session-expired": "Session expired",
    "session-expired-description": "Your session has expired. Please log in again.",
    "success": "Success!",
    "creation-failed": "Creation failed",
    "modifications-saved": "Your modifications have been saved successfully",
    "modifications-failed": "Update failed",
    "donation-saved": "Your donation has been saved successfully",
    "donation-failed": "Donation failed",
    "invalid-request": 'Invalid request',
    "request-sent": "Request sent",
    "request-failed": "Request failed",
    "registration-failed": "Registration failed",
    "login-success": "Login success",
    "login-failed": "Login failed",
    "logout-success": "You’ve been successfully logged out",
    "check-email-password": "Check your email and password",
    "recover-not-found": "We couldn't find your Fundky account. Please verify your information and try again.",
    "campaign-creation-failed": "The campaign cannot be created, make sure all fields are filled before submitting.",
    "transaction-approved": "Transaction approved",
    "error": "Error",
    "transaction-failed": "Transaction failed",
    "survey-success":"Survey Successfully Created",
    "survey_update-success" : "Survey Successfully Updated",
    "survey-failed" :"Please add all failed",
    "survey-delete" : "Survey Delete Successfully",
    "field-success" : "Field Add Successfully",
    "publish-success" :"Field Publish Successfully",
    "survey-attempt" : "Survey Attempt Successfully"

  },
  "ROLES": {
    "1": "Superadmin",
    "2": "Member",
    "3": "Administrator",
    "4": "Owner",
    "5": "Collaborator",
    "6": "Editor",
    "7": "Accountant",
    "8": "Moderator",
  },
  "LANGUAGES": {
    "default": " (default)",
    "en": "English",
    "fr": "French",
    "1": "English",
    "2": "French",
  },
  "TRANSACTION": {
    "yes" : "Yes",
    "no" : "No",
    "cancelText" : "Are you sure you want to cancel this transaction?",
  },
  "MESSAGE": {
    "noJS-text": "This site cannot work properly if JavaScript is deactivated.",
    "noJS-link": "Please activate it to continue.",
    "noJS-url": "https://fundky.tawk.help/"
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { Collapse } from 'react-collapse';

import FundkyButton from '../../ui/components/FundkyButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InfoPanel from './InfoPanel';
import RegisterForm from './RegisterFormContainer';
import RecoveryForm from './RecoveryFormContainer';
import ResetForm from './ResetFormContainer';
import LoginForm from './LoginFormContainer';
import ConfirmationPanel from './ConfirmationPanel';
import RegistrationConfirmationPanel from './RegistrationConfirmationPanelContainer';
import {
  CONNECTION_MODAL_LOGIN,
  CONNECTION_MODAL_REGISTER,
  CONNECTION_MODAL_RECOVERY,
  CONNECTION_MODAL_REGISTRATION_CONFIRMATION,
  CONNECTION_MODAL_RESET,
  CONNECTION_MODAL_CONFIRM,
  CONNECTION_MODAL_CLOSED
} from '../../ui/uiUtils';

import CssVariableApplicator from '../../ui/components/CssVariableApplicator';

import './ConnectionModal.less';

import '../../assets/images/logo_fundky_connect.png';

class ConnectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayInfo: false
    };

    this.down = false;

    // Default Fundky colors
    this.formatedColors = {
      '--primary-color': '#EB3052',
      '--secondary-color': '#64BFC5'
    };

    this.registerRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.connectionModalState === CONNECTION_MODAL_CLOSED &&
      this.props.connectionModalState !== CONNECTION_MODAL_CLOSED
    ) {
      this.setState({
        displayInfo: false
      });
    }
  }

  /****
   * We save mouseDown on Modal to prevent closing on outside draging release.
   *****/
  modalMouseDown = event => {
    this.down = true;
  };

  modalMouseUp = event => {
    this.down = false;
  };

  handleCancel = event => {
    const className = event.currentTarget.className;
    if (
      className.indexOf('FundkyModal--close') > -1 ||
      className.indexOf('ant-modal-close') > -1 ||
      (className.indexOf('ant-modal-wrap') > -1 && !this.down)
    ) {
      this.registerRef.refs.wrappedInstance.reset();
      this.props.closeConnectionModal(
        this.props.connectionModalForced,
        this.props.sessionHistory[0],
        this.props.sessionHistory[1]
      );
    }
    this.down = false;
  };

  handleToggleInfo = event => {
    this.setState({
      displayInfo: !this.state.displayInfo
    });
  };

  render() {
    const {
        props,
        state,
        formatedColors,
        modalMouseDown,
        modalMouseUp,
        handleCancel,
        handleToggleInfo
      } = this,
      {
        locale,
        connectionModalState,
        connectionModalForced,
        creationMode,
        forceCloseConnectionModal,
        customAlert,
        from
      } = props,
      { displayInfo } = state;

    return (
      <Modal
        className="ConnectionModal"
        visible={connectionModalState !== CONNECTION_MODAL_CLOSED}
        footer={null}
        onCancel={handleCancel}
        maskClosable={!connectionModalForced}
      >
        <div onMouseDown={modalMouseDown} onMouseUp={modalMouseUp}>
          <CssVariableApplicator variables={formatedColors}>
            <InfoPanel
              className={`ConnectionModal__Panel top ${
                displayInfo ? 'display' : ''
              }`}
              handleToggleInfo={handleToggleInfo}
            />
            <div className="ConnectionModal__Panel">
              <FundkyButton
                className="FundkyModal--close"
                type="text"
                icon={['fas', 'times']}
                size="large"
                action={{ func: handleCancel }}
              />
              <div className="ConnectionModal__Logo--container">
                <img
                  className="ConnectionModal__Logo"
                  src="/assets/logo_fundky_connect.png"
                  alt="Fundky Connect Logo"
                />
                <FontAwesomeIcon
                  className="ConnectionModal--question"
                  onClick={handleToggleInfo}
                  icon={['fas', 'question-circle']}
                />
              </div>
              <div className="ConnectionModal__Form--container">
                <Collapse
                  isOpened={
                    connectionModalState ===
                    CONNECTION_MODAL_REGISTRATION_CONFIRMATION
                  }
                >
                  <RegistrationConfirmationPanel
                    className={`ConnectionModal__Form${
                      connectionModalState ===
                      CONNECTION_MODAL_REGISTRATION_CONFIRMATION
                        ? ' active'
                        : ''
                    }`}
                    locale={locale}
                    creationMode={creationMode}
                    forceCloseConnectionModal={forceCloseConnectionModal}
                  />
                </Collapse>
                <Collapse
                  isOpened={connectionModalState === CONNECTION_MODAL_CONFIRM}
                >
                  <ConfirmationPanel
                    className={`ConnectionModal__Form${
                      connectionModalState === CONNECTION_MODAL_CONFIRM
                        ? ' active'
                        : ''
                    }`}
                    locale={locale}
                    creationMode={creationMode}
                    forceCloseConnectionModal={forceCloseConnectionModal}
                  />
                </Collapse>
                <Collapse
                  isOpened={connectionModalState === CONNECTION_MODAL_RESET}
                >
                  <ResetForm
                    className={`ConnectionModal__Form${
                      connectionModalState === CONNECTION_MODAL_RESET
                        ? ' active'
                        : ''
                    }`}
                    locale={locale}
                    creationMode={creationMode}
                    forceCloseConnectionModal={forceCloseConnectionModal}
                  />
                </Collapse>
                <Collapse
                  isOpened={connectionModalState === CONNECTION_MODAL_RECOVERY}
                >
                  <RecoveryForm
                    className={`ConnectionModal__Form${
                      connectionModalState === CONNECTION_MODAL_RECOVERY
                        ? ' active'
                        : ''
                    }`}
                    locale={locale}
                    creationMode={creationMode}
                    forceCloseConnectionModal={forceCloseConnectionModal}
                  />
                </Collapse>
                <Collapse
                  isOpened={connectionModalState === CONNECTION_MODAL_REGISTER}
                >
                  <RegisterForm
                    className={`ConnectionModal__Form${
                      connectionModalState === CONNECTION_MODAL_REGISTER
                        ? ' active'
                        : ''
                    }`}
                    locale={locale}
                    creationMode={creationMode}
                    forceCloseConnectionModal={forceCloseConnectionModal}
                    wrappedComponentRef={inst => (this.registerRef = inst)}
                  />
                </Collapse>
                <Collapse
                  isOpened={connectionModalState === CONNECTION_MODAL_LOGIN}
                >
                  <LoginForm
                    className={`ConnectionModal__Form${
                      connectionModalState === CONNECTION_MODAL_LOGIN
                        ? ' active'
                        : ''
                    }`}
                    locale={locale}
                    creationMode={creationMode}
                    forceCloseConnectionModal={forceCloseConnectionModal}
                    customAlert={customAlert}
                    from={from}
                  />
                </Collapse>
              </div>
            </div>
          </CssVariableApplicator>
        </div>
      </Modal>
    );
  }
}

ConnectionModal.propTypes = {
  connectionModalState: PropTypes.oneOf([
    CONNECTION_MODAL_LOGIN,
    CONNECTION_MODAL_REGISTER,
    CONNECTION_MODAL_RECOVERY,
    CONNECTION_MODAL_RESET,
    CONNECTION_MODAL_REGISTRATION_CONFIRMATION,
    CONNECTION_MODAL_CONFIRM,
    CONNECTION_MODAL_CLOSED
  ]).isRequired,
  connectionModalForced: PropTypes.bool.isRequired,
  closeConnectionModal: PropTypes.func.isRequired
};

export default ConnectionModal;
